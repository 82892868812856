import React, { Component } from "react";
import TaskList from './profiledata/TaskList'
import ProjectList from './reportdata/ProjectList'
import Inbox from './profiledata/Inbox'
class Report extends Component {
    constructor(props) {
        let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        super(props);
        this.state = {
           // projectfiltertype:'2',
            loggedInUserId:userdataforid.id
        }
    }
    handleProjectFilterChange(e) {
		this.setState({ projectfiltertype: e.target.value });
	}
    render() {                       
            return (
			 <div className="container-fluid TaskListPage"  ref="selfdiv">
                <div className="panel panel-primary ProfilePanel">                    
                    <div className="WrapBarTop11 panel-heading">
                        <div className="row MoPrologoLeft">
                            <div className="col-12 ProAssetsHead">
                                <h4 className="PageTopTitle">Reports</h4>
                                <select name="projectfiltertype" className="form-control" value={this.state.projectfiltertype} onChange={this.handleProjectFilterChange.bind(this)} >
									<option value=" ">Select Project </option>
									<option value="2">Active Projects without Tasks</option>
                                    <option value="3">Completed Tasks w/o Time</option>
                                    <option value="4">Employees by Time</option>
                                    <option value="5">Subscribers w/o Active Projects</option>
								</select>
                            </div>                         
                        </div>                        
                    </div>
                    <div className="panel-body">    
                        {                             
                           (this.state.projectfiltertype==='2' &&
                                <div className="project-list">  
                                    <ProjectList userid={this.state.loggedInUserId} /> 
                                </div>
                            )                       
                        }
                        {    
                            (this.state.projectfiltertype==='tasklist' &&
                                <div className="project-list">  
                                    <TaskList userid={this.state.loggedInUserId} /> 
                                </div>
                            )
                        }
                        {    
                            (this.state.projectfiltertype==='inbox' &&
                                <div className="project-list">  
                                    <Inbox userid={this.state.loggedInUserId} /> 
                                </div>
                            )
                        }                        
                     </div>
                </div>
            </div>
		);
    }
}
export default Report;
