import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Select from "react-select";
import ReactTable from "react-table";
import {PostData} from '../../services/PostData';
import "react-table/react-table.css";  
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
import { CSVLink, CSVDownload } from "react-csv";
//import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
class TimeLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            timeLogList: [],
            originalTimeLogList: [],
            taskteammembersucsmsg:'',
            filtered: [],
            select1: undefined,
            select2: undefined,
            isClearable: true,
            loading:false,
            csvfilename:'All Projects',
            monthfilter:'All Time',
            selectedOption1: {value:'',label:"All Project"},
            selectedOption2: {value:'',label:"All Time"}
            
        };
    }
    componentDidMount() {
        this.setState({loading:true});
        Promise.all([this.getOrgTimeLog()])
		.then(([orgtaskdata])  => {
            this.setState({
                timeLogList: orgtaskdata.data,
                orginaltotaltime: orgtaskdata.projecttotaltime,
                totaltime: orgtaskdata.projecttotaltime,
                originalTimeLogList: orgtaskdata.data,
                loading:false
            });
        });
    }
    getOrgTimeLog(){
        let data = {
            'orgid': this.props.orgid,           
        }
        return PostData('orgtimelog/',data).then((result) => {  
			return result;
		});
    }        
    onFilteredChangeCustom = (value, accessor) => {
        let selectedval=value;
        this.setState({ selectedOption2: {value:'',label:'All Time'} });
        let filtered = this.state.filtered;        
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {                
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length)  filtered.splice(i, 1)  
                    else filter["value"] = value;
                    insertNewFilter = 0;                    
                }
            });
        }
        if (insertNewFilter) {
            this.setState({csvfilename:value});
            filtered.push({ id: accessor, value: value });
        }
        else
        {
            this.setState({csvfilename:'All Projects'});
        }
        if(value)
        {
            this.setState({ selectedOption1: {value:selectedval,label:value} });
            let datata=this.state.originalTimeLogList;
            let newdata=[];
            if (datata.length) {  
                var projecttotaltime = '';  
                var projecthr = 0;
                var projectmin = 0; 
                var dmin = 0;       
                datata.forEach((data, i) => { 
                    if(value===data.project_name)
                    {                                      
                        newdata.push(data);                        
                        var project_time = data.totaladded_time;                    
                        if(project_time != undefined){
                            var time = project_time.split(':')
                            projecthr += parseInt(time[0]);
                            projectmin += parseInt(time[1]);
                            if(projectmin >=  60){
                                var dhr = parseInt(projectmin/60);
                                dmin = projectmin%60;
                                projectmin = dmin;
                            }
                            if(dhr){
                                projecthr = projecthr+dhr;
                            }
                        }  
                    }             
                });
                projecthr = (projecthr < 10 ? '0' : '') + projecthr
                projectmin = (projectmin < 10 ? '0' : '') + projectmin
                projecttotaltime = projecthr+':'+projectmin;
                this.setState({timeLogList:newdata});
                this.setState({totaltime:projecttotaltime})
            }    
        }
        else
        {
            this.setState({timeLogList:this.state.originalTimeLogList}); 
            this.setState({ selectedOption1: {value:'',label:'All Project'} });
        }
        this.setState({ filtered: filtered });
    };    
    onCustomFilteredChangeCustom = (value, accessor) => {        
        let filtered = this.state.filtered;
        console.log('value, accessor==',value, accessor);
        console.log('filtered==',filtered);
        let insertNewFilter = 1;
        this.setState({ selectedOption1: {value:'',label:'All Project'} });
        if(!value)
        {
            console.log('value==',value);
            if (filtered.length) {
                filtered.forEach((filter, i) => {
                    if (filter["id"] === accessor) {
                        if (value === "" || !value.length) filtered.splice(i, 1);
                        else filter["value"] = value;
                        insertNewFilter = 0;
                    }
                });
            }
            if (insertNewFilter) {
                filtered.push({ id: accessor, value: value });
            }
            this.setState({ filtered: filtered,timeLogList:this.state.originalTimeLogList,totaltime:this.state.orginaltotaltime});
            this.setState({ selectedOption2: {value:'',label:'All Time'} });
       }
        else
        {
            this.setState({ selectedOption2: {value:value,label:value} });
            let datata=this.state.originalTimeLogList;
            filtered=[];
            let d = new Date();
            let currentmonth = parseInt(d.getMonth()+1);            
            let currentyear = parseInt(d.getFullYear());
            let lastmonth=currentmonth-1;
            let lastyear=currentyear-1;
            if(currentmonth==0){
                currentmonth=1;
                lastmonth = 12;
                if(value=='Last Month')
                    currentyear=currentyear-1;
            }
            let month='';
            let year='';
            switch(value) {
                case 'This Month':
                    month=currentmonth;
                    year=currentyear;
                    break;
                case 'Last Month':
                    month=lastmonth;
                    year=currentyear;
                    break;
                case 'This Year':
                    year=currentyear;
                    break;
                case 'Last Year':
                    year=lastyear;
                    break;
            }       
            if (filtered.length) {
                filtered.forEach((filter, i) => {
                    if (filter["id"] === accessor) {
                        if (value === "" || !value.length) filtered.splice(i, 1);
                        else filter["value"] = value;
                        insertNewFilter = 0;
                    }
                });
            }
            let newdata=[];
            if (datata.length) {  
                var projecttotaltime = '';  
                var projecthr = 0;
                var projectmin = 0; 
                var dmin = 0;       
                datata.forEach((data, i) => {  
                    this.setState({monthfilter:value});                            
                    let due = new Date(data.due_date);
                    let dmonth = parseInt(due.getMonth());
                    let dyear = parseInt(due.getFullYear());
                    if(dmonth==0)
                    dmonth=1;                                                          
                    else
                    dmonth=dmonth+1
                    if(value==='This Year' || value==='Last Year')
                    {
                        if (parseInt(year)===parseInt(dyear)){
                            newdata.push(data);                                
                            var project_time = data.totaladded_time;                            
                            if(project_time != undefined){
                                var time = project_time.split(':')
                                projecthr += parseInt(time[0]);
                                projectmin += parseInt(time[1]);
                                if(projectmin >=  60){
                                    var dhr = parseInt(projectmin/60);
                                    dmin = projectmin%60;
                                    projectmin = dmin;
                                }
                                if(dhr){
                                    projecthr = projecthr+dhr;
                                }
                            }
                        }                                          
                    }
                    else
                    {
                        console.log('year==',year);
                        console.log('year==',year);
                        console.log('month==',month);
                        console.log('dmonth==',dmonth);
                        if (parseInt(year)===parseInt(dyear) && parseInt(month)===parseInt(dmonth)){
                        //if (parseInt(month)===parseInt(dmonth)){
                            newdata.push(data); 
                            var project_time = data.totaladded_time;                            
                            if(project_time != undefined){
                                var time = project_time.split(':')
                                projecthr += parseInt(time[0]);
                                projectmin += parseInt(time[1]);
                                if(projectmin >=  60){
                                    var dhr = parseInt(projectmin/60);
                                    dmin = projectmin%60;
                                    projectmin = dmin;
                                }
                                if(dhr){
                                    projecthr = projecthr+dhr;
                                }
                            }
                        }                 
                    }
                });
                projecthr = (projecthr < 10 ? '0' : '') + projecthr
                projectmin = (projectmin < 10 ? '0' : '') + projectmin
                projecttotaltime = projecthr+':'+projectmin;
                console.log('newdata===',newdata);  
                this.setState({timeLogList:newdata});
                this.setState({totaltime:projecttotaltime})
            }
            this.setState({ filtered: filtered });
        }       
    };
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
        const columns = [
            {
                Header: 'ID',
                accessor: '_id',
                show:false,
            },
            {
                Header: 'OrgId',
                accessor: 'org_id',
                show:false
            },
            {
                Header: 'Project',
                accessor: 'project_name',
                Cell: ({ row }) =>{
                    let userdatatocheckusertype = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;

                    if(userdatatocheckusertype.capabilities && userdatatocheckusertype.capabilities.administrator)
                    {
                        return (
                            <Link to={{ pathname: `/org/${row.org_id}/project/view/${row._id}` }}>{row.project_name}</Link>
                        )
                    }
                    else
                    {
                        return (
                            <Link to={{ pathname: `/subs/org/${row.org_id}/project/view/${row._id}` }}>{row.project_name}</Link>
                        )
                    }
                     
                },
                //Cell: ({ row }) => (<Link to={{ pathname: `/subs/org/${row.org_id}/project/view/${row._id}` }}>{row.project_name}</Link>),
                width: 250
            },
            {
                Header: 'Time',
                accessor: 'totaladded_time',
                //width: 100
            },
            {
                Header: 'Notes',
                accessor: 'description',
                width: 350,
                Cell: ({ row }) =>{ 
                    //console.log('row=====',row);
                    let description='';
                    if(row.description){
                        if (this.isJson(row.description))
                        {
                            const contentState = convertFromRaw(JSON.parse(row.description));
                            const editorState = EditorState.createWithContent(contentState);
                            const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
                            //console.log('block.text====',blocks);
                            description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
                        }
                        else
                        {
                            if(row.description==='[object Object]')
                            description= '';
                        }
                    }
                    return(<p>{description}</p>)
                    }
            },
            {
                Header: 'Status',
                accessor: 'status',  
                //width: 100              
            },
            {
                Header: 'Due date',
                accessor: 'due_date',
                Cell: ({ row }) =>{ 
                    return(<p>{dateFormat(row.due_date, "mm/dd/yyyy")}</p>)
                },
               // width: 100
            },
        ];

        
        const data=this.state.timeLogList;
        const totaltime = this.state.totaltime;
        const isClearable= this.state.isClearable;
        let d = new Date();
        //console.log('d==',d);
        let currentmonth = parseInt(d.getMonth());
        let currentyear = parseInt(d.getFullYear());
        //console.log('currentmonth',currentmonth);
        let lastmonth=currentmonth-1;
        let lastyear=currentyear-1;
        if(currentmonth==0){
            currentmonth=1;
            lastmonth = 12;
        }
        const options = [
            { value: 'This Month', label: 'This Month' },
            { value: 'Last Month', label: 'Last Month' },
            { value: 'This Year', label: 'This Year' },
            { value: 'Last Year', label: 'Last Year' }
        ]
        var desc = '';
        
        //const csvData = []
        const csvData = [
            ["Project", "Time", "Notes","Status","Due Date"],
        ];
          const ProjectTotalTime = ["Total Time: ", totaltime]
         
        var editortext = '';
        var itemsProcessed = 0;
        const filename = this.state.csvfilename; 
        const monthfilter = this.state.monthfilter;
        const newDate = new Date()
        const currentm = parseInt(newDate.getMonth() +1);
        const currentdate = newDate.getDate()+'/'+currentm+'/'+newDate.getFullYear();
        const currenttime = newDate.getHours()+':'+newDate.getMinutes()+':'+newDate.getSeconds();
        
        
            data.forEach((item, index, array) => {
            //asyncFunction(item, () => {
                var editordesc = item.description.toString();
               
                var pusheddata = Array();
                var description = '';
                if(editordesc){
                    if (this.isJson(editordesc)){
                    const contentState = convertFromRaw(JSON.parse(editordesc));
                    const editorState = EditorState.createWithContent(contentState);
                    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
                    //console.log('block.text====',blocks);
                    description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
                    }
                    pusheddata.push(item.project_name,item.totaladded_time,description,item.status,item.due_date);
                    csvData.push(pusheddata);
                    }
                    itemsProcessed++;
                
                    if(itemsProcessed === array.length) {
                        csvData.push(ProjectTotalTime);
                   
                        
                    }
            }); 
             
		return (
            
            <div className="container-fluid TaskListPage"  ref="selfdiv">
            <div className="export_timelog">
            <CSVLink data={csvData} filename={filename+' '+monthfilter+' '+currentdate+' at'+currenttime+'.csv'}>
            <img src={require('../../assets/images/excel-icon.png')} /></CSVLink>
            </div>            
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-md-7 col-lg-7">
                                <h4 class="PageTopTitle">Time Log</h4>
                            </div>
                            <div className="col-md-2 col-lg-2"></div>
                            <div className="col-md-3 col-lg-3">
                            </div>
                        </div>
                    </div>
                    <div className="panel-body timelog-table-listing">
                       <div className="row">
                           <div className="col-6">
                                <Select
                                    placeholder="All Projects"
                                    className="basic-single projectlist"
                                    classNamePrefix="select"
                                    isClearable={isClearable}
                                    options={this.uniqueOptions(this.state.timeLogList, "project_name").map((project_name, i) => {
                                    return { id: i, value: project_name, label: project_name };                      
                                    })}
                                    multi={true}
                                    onChange={entry => {
                                        if(!entry)
                                        {
                                            entry={id: '', value: '', label: ''};
                                        }
                                        this.setState({ select1: entry });
                                        this.onFilteredChangeCustom(
                                        entry.value,
                                        "project_name"
                                        );
                                    }}
                                    value={this.state.selectedOption1}
                                />                        
                        </div>
                        <div className="col-6">
                            <Select
                                placeholder="All Time"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={isClearable}
                                options={options}
                                multi={true}
                                onChange={entry => {
                                    if(!entry)
                                    {
                                        entry={id: '', value: '', label: ''};
                                    }
                                    this.setState({ select2: entry });
                                    this.onCustomFilteredChangeCustom(
                                    entry.value,
                                    "due_date"
                                    );
                                }}
                                value={this.state.selectedOption2}
                            />
                        </div>
                    </div>                   
                    {   
                        (data.length>0) && 
                        <ReactTable
                            data={data}
                            filtered={this.state.filtered}
                            minRows = {0}
                            pageSizeOptions = {[10,50,100]}
                            defaultPageSize = {10}                   
                            columns={columns}
                            className="-striped -highlight"
                        />                        
                    }                    
                    <div class="total_time">Total Time: {totaltime}</div> 
                </div>
                </div>
            </div>
		);
    }
}
export default TimeLogs;
