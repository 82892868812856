import React, { Component } from 'react';
import icon1 from '../assets/images/icon1.png';
import icon2 from '../assets/images/icon2.png';
import icon3 from '../assets/images/icon3.png';
import icon4 from '../assets/images/icon4.png';
import icon5 from '../assets/images/icon4-team.png';
class Vpma extends Component {

	constructor(){
        console.log('called in homeeeee');
	super();
	}
 	logoutHandler =(e) => {
		//sessionStorage.clear();
		//window.location.href = '/';
    }
    render() {
        return (
            <div>
                <div className="welcome-section">
                    <div className="container-fluid">
                     <h3>Welcome to Project Management System</h3>
                    </div>         
                </div>
                <div className="production-section">
                   <div className="container-fluid">
                    <h2>Video Production Management App</h2>                 
                     <ul>
                     <li>
                      <a href="/">
                       <img src={icon1} alt="image1"/>
                      </a>
                      <p>PRODUCTION CALENDER</p>
                     </li>                 
                     <li>
                      <a href="/">
                       <img src={icon2} alt="image2"/>
                      </a>
                      <p>PROJECTS</p>
                     </li>                 
                     <li>
                      <a href="/organization/list">
                       <img src={icon3} alt="image3"/>
                      </a>
                      <p>ORGANIZATION</p>
                     </li>                 
                     <li>
                      <a href="/">
                       <img src={icon4} alt="image4"/>
                      </a>
                      <p>INBOX</p>
                     </li>                 
                     <li>
                      <a href="/">
                       <img src={icon5} alt="image5"/>
                      </a>
                      <p>TASKS</p>
                     </li> 
                     </ul>               
                   </div>
                </div>                
            </div> 
        );
    }
} 
export default Vpma;