import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {PostData,GetData} from '../../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner'
import { Link  } from 'react-router-dom';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { Button, Collapse, Modal } from 'react-bootstrap';
class EditFile extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(){
		super();
		this.state = {
			fields: {},
			errors: {},
			folder_desc: EditorState.createEmpty(),
			folder_name:'',
			boxfolderid:'',
			loading: false,
			folderaddsuccs:'',
			downloadURL:'',
			setShow:false,
			showAddToProject:false,
			folderlist:[],
			originalfolderlist:[],
			rootfolderid:'',
			isInner:false,
			fileid:'',
			successmsg:'',
			errormsg:'',
			projectlist:[],
			selectedproject:'',
			boxfiledetails:'',
			breadcrumblist:[],
			showfile:false,
			boxprevfile:'',
			boxnextfile:'',
		};
		this.onChange = this.onChange.bind(this);
		console.log('calledddddddddddddddddddddddddd in component');
	};
	moveFolder(e){
		this.setState({ setShow: true });
	}
	addToProject(e){
		this.setState({ showAddToProject: true });
	}
	handleProjectChange(e){
		console.log('selectedproject==',e.target.value);
		this.setState({ selectedproject: e.target.value });
	}
	addAssetToProject(e){
		let filedetails = this.state.filedetails;
		//console.log('filedetails==',filedetails);
		if(this.state.selectedproject=='')
		{
			this.setState({
				errormsg:'Please select Project'
			})
		}
		else
		{
			this.setState({
				errormsg:''
			})
			let cfilename   = filedetails.name;
			// filedetails     = cfilename.split('.');
			var filenames            =cfilename.split(/\.(?=[^\.]+$)/);
			console.log('filenames================',filenames);
			//filename           = file[0]+'_'+date_ob_new+'.'+file[1];
			let filetype    = filenames[1];
			let filename    = filenames[0];
			let url			= this.state.downloadURL;
			let data = {
				'filename': filename,
				'fileid': this.props.match.params.folderid,
				'fileurl': url,
				'filetype': filetype,
				'project_id': this.state.selectedproject,
			}
			PostData('assetaddtoproject', data).then((result) => {
				console.log('result==',result);
				if (result.statuscode === 200) {
					this.setState({ successmsg: result.message });
					setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
				}
			});
		}

	}
	handleAddToProjectClose(e) {
		this.setState({
			showAddToProject: false,
		});
	}
	handleClose(e) {
		this.setState({
			setShow: false,
			boxfolderid:this.state.rootfolderid,
			isInner:false,
		});
		if(this.state.rootfolderid!='')
		this.getFolderListByFolderId(this.state.rootfolderid);
	}
	handleChangeOnFolderSelection(e){
		this.setState({
			fileid:e.target.value
		})
	}
	getFolderListById(folderid){
		this.setState({
			boxfolderid:folderid,
			isInner:true,
        });
		this.getFolderListByFolderId(folderid);
	}
	backToRootFolder(folderid){
        this.setState({
			boxfolderid:folderid,
			isInner:false,
        });
        this.getFolderListByFolderId(folderid);
    }
	onEditorStateChange: Function = (folder_desc) => {
		const rawState = JSON.stringify(convertToRaw(folder_desc.getCurrentContent()));
	    this.setState({
			folder_desc:rawState
	    });
	};
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	componentDidMount() {
        this.setState({loading:true});
        let data = {
            'fileid': this.props.match.params.folderid,
		}
		Promise.all([this.getFileData(data), this.getOrgData(),this.getProjectList(),this.getBreadCrumb(this.props.match.params.folderid)])
			.then(([filedata, orgdata, projectdata,breadcrumb]) => {
			console.log('calleddddddddddd in getBreadCrumb===',breadcrumb);
			if(filedata.statusCode===200)
            {
                console.log('responseJson==',filedata);
                this.setState({
					file_name:filedata.filedetails.filename,
					filedetails: filedata.file,
					boxfiledetails:filedata.filedetails,
                    downloadURL: filedata.downloadURL,
                    loading:false,
                    boxprevfile:filedata.boxprevdata,
					boxnextfile:filedata.boxnextdata
                });
			}
			if(orgdata.status==='success')
            {
                if(orgdata.data.boxfolderid)
                {
				   this.setState({boxfolderid:orgdata.data.boxfolderid,rootfolderid:orgdata.data.boxfolderid});
				   this.getFolderListByFolderId(orgdata.data.boxfolderid);
                }
			}
			if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data});
			}
			if(breadcrumb.statusCode===200)
            {
                this.setState({breadcrumblist:breadcrumb.data});
            }
		});
	}
	getFileData(data){
		return PostData('viewfile', data).then((result) => {
			return result;
		});
	}
	getOrgData(){
		return GetData('organization/'+this.props.match.params.orgid).then((result) => {
			return result;
		});
	}
	getProjectList(){
		console.log('calleddd=');
		return GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
			return result;
		});
	}
	getBreadCrumb(folderid){
		console.log('calleddd=');
		return GetData('getbreadcrumb/'+folderid).then((result) => {
			return result;
		});
	}
	getFolderListByFolderId(folderid)
    {
        this.setState({loading:true});
        let data = {
            'boxfolderid': folderid,
        }
        PostData('boxassets', data).then((result) => {
            if(result.statusCode===200)
            {
                console.log('responseJson==',result);
                this.setState({
                    folderlist: result.data,
                    originalfolderlist: result.data,
                    loading:false
                });
            }
        });
	}
	copyFile(e){
		let currentuserid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user.id;
		let data={
			orgid:this.props.match.params.orgid,
			userid:currentuserid,
			fileid:this.props.match.params.folderid,
			copydolderid:this.state.fileid
		}
		PostData('copyfile', data).then((result) => {
			console.log('result==',result);
			if (result.statusCode === 200) {
				this.setState({ successmsg:result.message });
				setTimeout(() => {
					this.setState({
						successmsg: '',
						setShow: false,
				 	});
				}, 3000);

			}
			else if(result.statusCode===409)
			{
				this.setState({ errormsg:result.message});
				setTimeout(() => {
					this.setState({
						errormsg: '',
				 	});
				}, 3000);
			}
		});
	}
	moveFile(e){
		let currentuserid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user.id;
		let data={
			orgid:this.props.match.params.orgid,
			userid:currentuserid,
			fileid:this.props.match.params.folderid,
			copydolderid:this.state.fileid
		}
		PostData('movefile', data).then((result) => {
			console.log('result==',result);
			if (result.statusCode === 200) {
				this.setState({ successmsg:result.message });
				setTimeout(() => {
					this.setState({
						successmsg: '',
						setShow: false,
				 	});
				}, 3000);

			}
			else if(result.statusCode===409)
			{
				this.setState({ errormsg:result.message});
				setTimeout(() => {
					this.setState({
						errormsg: '',
				 	});
				}, 3000);
			}
		});
	}
	editFileTitle() {
		this.setState({ showfile: true });
	}
	updateFileTitle(e) {
		let data = {
			'fileid': this.props.match.params.folderid,
			'file_name': this.state.file_name,
		}
		PostData('updatefiletitle', data).then((result) => {
			if (result.statusCode === 200) {
				this.setState({ showfile: false, filetitlesucsmsg: result.message });
				setTimeout(() => this.setState({ filetitlesucsmsg: '' }), 3000);	
			}
		});
	}
	callAssets(folderid,e){
		this.setState({loading:true});
        let data = {
            'fileid': folderid,
		}
		Promise.all([this.getFileData(data)])
			.then(([filedata]) => {
			
			if(filedata.statusCode===200)
            {
                console.log('responseJson==',filedata);
                this.setState({
					file_name:filedata.filedetails.filename,
					filedetails: filedata.file,
					boxfiledetails:filedata.filedetails,
                    downloadURL: filedata.downloadURL,
                    loading:false,
					boxprevfile:filedata.boxprevdata,
					boxnextfile:filedata.boxnextdata
                });
			}			
		});
	}
    render() {
		let filedetails = this.state.filedetails;
		let boxfiledetails=this.state.boxfiledetails;
		let boxprevfile = this.state.boxprevfile;
		let boxnextfile = this.state.boxnextfile;
		console.log('boxprevfile==',boxprevfile);
		console.log('boxnextfile==',boxnextfile);
		let prevfolderid= '';
		let nextfolderid= '';
		if(boxprevfile)
		{
			prevfolderid= boxprevfile.fileid;
		}
		if(boxnextfile)
		{
			nextfolderid= boxnextfile.fileid;
		}
		let fileview='';
		let newfilename='';
		let projects='';
		let breadcrumblistdata='';
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		if(filedetails && boxfiledetails)
		{
		boxfiledetails=boxfiledetails.mimetype;
		let cfilename   = filedetails.name;
		let filedes     = filedetails.description;
		filedetails     = cfilename.split('.');
		let filename    = filedetails[0];
		let url= this.state.downloadURL;
		let boxfiletype =boxfiledetails.split('/');
		let filemimetype= boxfiletype[1];
		fileview = <div className="row">
			<div className="col-10 assetsfiles">
				{  (() => {
					console.log('filemimetype===',filemimetype);
					if(filemimetype==='png' || filemimetype==='jpg' || filemimetype==='jpeg'){
					return <div>
						<img src={url}/>
						</div>
					}
					else if(filemimetype==='mov' || filemimetype==='MOV'){
						return <div className="videoasset">
							<video controls >
								<source src={url} />
								Sorry, your browser doesn't support embedded videos.
							</video>
						</div>					
					}
					else if(filemimetype==='mp4' || filemimetype==='avi' || filemimetype==='quicktime'){
						return <div className="videoasset">
							<video controls >
								<source src={url}
									type="video/mp4" />
								Sorry, your browser doesn't support embedded videos.
							</video>
						</div>					
					}

				})()
				}
				<a href={url} download>Download</a>
				<div>
					<a onClick={ (e) =>this.callAssets(prevfolderid,e) } className={`prevasset ${prevfolderid ? "" : "disableelement"}`}  >Prev</a>
					<a onClick={ (e) =>this.callAssets(nextfolderid,e) } className={`nextasset ${nextfolderid ? "" : "disableelement"}`} >Next</a>
				</div>
		 	</div>
			<div className="col-2">
				<p>{filedes}</p>
			</div>
		</div>
		newfilename=<div>
			{filename}
			</div>
		}
		let folderslist = this.state.folderlist.map((item, i) => {
		//const folderslist = this.state.folderlist.map(function(item){
			if(item.filetype==='folder')
			return <li className="folderlistitem">
					<a className="forcusror" onClick={this.getFolderListById.bind(this,item.fileid)}>{item.filename}</a>
					<input value={item.fileid} name="fileid" type="radio" onChange={this.handleChangeOnFolderSelection.bind(this)}
					/>
				</li>;
		});
		breadcrumblistdata = this.state.breadcrumblist.map((item, i) => {
			//const folderslist = this.state.folderlist.map(function(item){
				let usertype='';
				if (userdata.capabilities && !userdata.capabilities.administrator)
				usertype='/subs'
				if(item.filetype==='folder' && item.parentid==='')
				return <li>
						<Link to={`${usertype}/${this.props.match.params.orgid}/assets/`}>All Files > </Link>
				</li>;
				else if(i===0 && item.filetype==='folder')
				return <li>
						{item.filename}
					</li>;
				else if(item.filetype==='folder' && item.parentid!='')
				return <li>
						<Link to={
							 {
								pathname: `${usertype}/${this.props.match.params.orgid}/assets/`,
								folderid: `${item.fileid}`
							}}
							>{item.filename} > </Link>
				</li>;
				else if(item.filetype==='file' && item.parentid!='')
				return <li className="forediticon">
						{/* {item.filename} */}
						 {this.state.file_name} <span class="edit-project" onClick={(e) => this.editFileTitle(e)}> <img src={require('../../assets/images/edit-icon.png')} /></span>
				</li>;
				else
				return <li>
						<Link to={`${usertype}/${this.props.match.params.orgid}/assets/`}>{item.filename} </Link>
				</li>;
		}).reverse();
		projects = this.state.projectlist.map((item, i) => {
			return <option className="folderlistitem" value={item._id}>
				{item.project_name}
			</option>;
		});

        return (
        	<div className="container-fluid AddProjectPage MobPaddHide"  ref="selfdiv">
				<div className="panel panel-primary">
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}
								/>
							</div>
						:null
					}
					<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-3">
									{/* <h4 class="PageTopTitle">{newfilename}</h4> */}
									<div className="successmsg">{this.state.filetitlesucsmsg}</div>
									{/* <h4> {this.state.file_name} <span class="edit-project" onClick={(e) => this.editFileTitle(e)}> <img src={require('../../assets/images/edit-icon.png')} /></span></h4> */}
									<ul className="breadcrumblist">{breadcrumblistdata} </ul>
									{
										(this.state.showfile &&
											<div class="project-edit">
												<input type="text" defaultValue={this.state.file_name} onChange={this.onChange} className="form-control" name="file_name"/>
												<button type="button" onClick={(e) => this.updateFileTitle(e)} className="UpdateBtn btn btn-primary">Update</button>
											</div>
										)
									}
								</div>
								<div className="col-6">
									{/* <Link to={`/${this.props.match.params.orgid}/assets`}>Back To Assets</Link>     */}
									{/* <ul className="breadcrumblist">{breadcrumblistdata} </ul> */}
								</div>
								<div  className="col-3 text-right RightTopMenu">
									<Dropdown>
										<Dropdown.Toggle variant="success1" id="dropdown-basic">
											Action
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={this.moveFolder.bind(this)}>Move File</Dropdown.Item>
											<Dropdown.Item onClick={this.addToProject.bind(this)}>Add To Project</Dropdown.Item>
											<Dropdown.Item >Download</Dropdown.Item>
											<Dropdown.Item >Delete</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid">
						<div className="row FormDesign">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12">
								{fileview}
							</div>
						</div>
		        	</div>
		        </div>
				<Modal show={this.state.setShow}>
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}
								/>
							</div>
							: null
					}
					<Modal.Header class="SelectProducerHead">
						<Modal.Title>Select Folder</Modal.Title>
					</Modal.Header>
					<Modal.Body class="SelectProducerModel">
						<div className="succes-msg">{this.state.successmsg}</div>
						<div className="error-msg">{this.state.errormsg}</div>
						{(this.state.isInner &&
							<div>
								<a className="forcusror rootfolder" onClick={this.backToRootFolder.bind(this,this.state.rootfolderid)} >Back To Root Folder</a>
							</div>
						)

						}
						<ul className="folderlist">
							{folderslist}
						</ul>
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleClose.bind(this)}>
							X
						</Button>
						<Button variant="primary" className="cpy-btn" onClick={this.copyFile.bind(this)}>
							Copy
						</Button>
						<Button variant="primary" onClick={this.moveFile.bind(this)}>
							Move
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showAddToProject}>
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}
								/>
							</div>
							: null
					}
					<Modal.Header class="SelectProducerHead">
						<Modal.Title>Add To Project</Modal.Title>
					</Modal.Header>
					<Modal.Body class="SelectProducerModel">
						<div className="succes-msg">{this.state.successmsg}</div>
						<div className="error-msg">{this.state.errormsg}</div>
						<select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
							<option value=" ">Select Project</option>
							{projects}
							{/* <option value="Active">Active</option>
							<option value="On-hold">On-hold</option>
							<option value="Completed">Completed</option> */}
						</select>
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleAddToProjectClose.bind(this)}>
							X
						</Button>
						<Button variant="primary" onClick={this.addAssetToProject.bind(this)}>
							Add To Project
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		);
    }
}
export default EditFile;
