import React, { Component } from "react";
import {PostData,GetData} from '../services/PostData';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Client } from '@freshbooks/api';
import Pagination from "react-js-pagination";
import { SearchQueryBuilder } from '@freshbooks/api/dist/models/builders/SearchQueryBuilder'
import { PaginationQueryBuilder } from '@freshbooks/api/dist/models/builders/PaginationQueryBuilder';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
class FreshbookInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            itemPerPage:10,
            successmsg:'',
            errormsg:'',
            voiceovertext:'',
            selectedvoice:'',
            fields: {},
			errors: {},
            invoiceList:[],
            orgdata:'',
            filterinvoice: 'This Year'
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getInvoiceList(this.state.filterinvoice);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        this.getInvoiceList(event.target.value);
    }
    //this.state.filterinvoice //this.state.filterinvoice
    
    async getInvoiceList(filterInvoice){
       // console.log('filterinvoice=',filterInvoice);
        this.setState({
            startloader:true
        })
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
        let name=userdata.user.first_name+" "+userdata.user.last_name;
        let email=userdata.user.email;
        let fbtokendata = await this.getFBRefreshToken();
       // console.log('fbtokendata=',fbtokendata);
        //const clientId = '6040b7b1c1319a062a52c67ebd07b2fa45055fb28436c263a0e087bed3e044ae'; //local
        //const clientSecret = 'd2d8087d22550415b9ba58c520e9592eddbc7d9e6f88202ded5bd096530a4f38';//local
        const clientId = '7753dc0bda63ceced9768da4cb1e515e85dc1402519b9b151a51ec3840dde238'; //live
        const clientSecret = 'ef028fb38c88258dbdaed4dba1513713cefbb3a6bb4ede4f49a0541f4171c112';//live
        const freshBooksClient = new Client(clientId, {
            clientSecret,
            redirectUri: 'https://associationstudios.com/'
        })
       // const clientdata = await freshBooksClient.users.me();
        //console.log('clientdata=',clientdata);
        const tokenData = await freshBooksClient.refreshAccessToken(fbtokendata.data.refreshtoken);
        //console.log('refreshedTokenData=',tokenData);
        if(tokenData){
            let fbClient = new Client(clientId, { accessToken: tokenData.accessToken });
            const { data } = await fbClient.users.me();
            //console.log('tokenData.data=',data);
            let accountid = data.businessMemberships[0].business.accountId;
            //console.log('accountid=',accountid);
            this.setState({
              accountid: accountid,
              tokenData:tokenData,
              fbClient:fbClient
            })
            await this.updateFBRefreshToken(tokenData);
            let orgdata = await this.getOrgData();
            const paginator = new PaginationQueryBuilder()
            paginator.page(1).perPage(1000);
            const now = new Date();

            // Basic usage
            const currentYear = dateFormat(now, "yyyy");
            const currentDay = dateFormat(now, "yyyy-mm-dd");
            const lastYear = (currentYear-1);
            const currentYearFirstDay = currentYear+'-01-01'
            const lastYearFirstDay = lastYear+'-01-01';
            const lastYearLastDay = lastYear+'-12-31';

            let searchQueryBuilder;
            if(filterInvoice=='This Year') {
                searchQueryBuilder = new SearchQueryBuilder()
                .equals('customerid',orgdata.freshbook_profile_id)
                .between('date', { min: new Date(currentYearFirstDay), max: new Date(currentDay) });
            } else if(filterInvoice=='Last Year') {
                searchQueryBuilder = new SearchQueryBuilder()
                .equals('customerid',orgdata.freshbook_profile_id)
                .between('date', { min: new Date(lastYearFirstDay), max: new Date(lastYearLastDay) });
            } else {
                searchQueryBuilder = new SearchQueryBuilder()
                .equals('customerid',orgdata.freshbook_profile_id);
            }
            
            //const data1  = await fbClient.expenses.list(accountid,[searchQueryBuilder,paginator])
           // console.log('orgdata=',orgdata);
            const data1  = await fbClient.invoices.list(accountid,[searchQueryBuilder,paginator])
            //console.log('data1=',data1);
            //console.log('data1.data.invoices=',data1.data.invoices);
            const invoiceslistwithLink=[];
            const invlist = data1.data.invoices;
            for (const element of invlist) {
              //console.log('element=',element);
              let responseInvoiceShareLink = await fbClient.invoices.shareLink(element.accountId, element.invoiceId)
              const sharedlink = responseInvoiceShareLink.data.shareLink;
              //console.log('responseInvoiceShareLink=',sharedlink);
              element['sharelink'] = sharedlink;
              invoiceslistwithLink.push(element);
            }
            this.setState({
                startloader:false
            })
            this.setState({
                invoiceList: data1.data.invoices,
                originalinvoiceList: data1.data.invoices,
                orgdata: orgdata
            });

        }
    }

    async getFBRefreshToken(){
        let resultdata;
        await GetData('getfbrefreshtoken').then((result) => {
           // console.log('refresh token result=',result);
            if (result.statuscode === 200) {                
               // console.log('refresh token updated');
                resultdata= result;     
            }
        });
        return resultdata
    }
    async getOrgData(){
        let resultdata;
        await GetData('organization/'+this.props.match.params.orgid).then((result) => {     
       // console.log('check result in edit page===',result);      
       // console.log('check result in edit result.data.create_date===',result.data.create_date);    //result.data.create_date  
            resultdata= result.data; 
        });
        return resultdata;
    }
    
    updateFBRefreshToken(tokenData){
        const refreshtoken = tokenData.refreshToken;
        PostData('updatefbrefreshtoken', {refresh_token:refreshtoken}).then((result) => {
           // console.log('refresh token result=',result);
            if (result.statuscode === 200) {                
               // console.log('refresh token updated');               
            }
        });
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }
    
    render() {
        let orgdata=this.state.orgdata;
        let orglogo='';
        if(orgdata.orglogo)
            orglogo=orgdata.orglogo;
        else
        orglogo='';
        let imagesrc='';
        //console.log('orglogo====',orglogo,orgdata.orglogo,orgdata);
        if(orglogo && orglogo!='""')
        {
            //console.log('orglogo hereeeee====',orglogo);
            try{
                imagesrc=require('../assets/images/organization-logo/'+orglogo);
               // console.log('orglogo tryyyyy====');
            }
            catch
            {
               // console.log('orglogo catchhhh====');
                imagesrc='';
            }
        }
        else
        {
            imagesrc='';
        }
        let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        //console.log('this.state.activePage===',this.state.activePage);
        //console.log('this.state.itemPerPage===',this.state.itemPerPage);
       // console.log('this.state.indexOfLastTodo===',indexOfLastTodo);
        let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let listItems;

       // console.log('this.state.indexOfFirstTodo===',indexOfFirstTodo);
        //console.log('this.state.invoiceList===',this.state.invoiceList);
        if(this.state.invoiceList.length>0) {
           // console.log('this.state.this.state.invoiceList.length===',this.state.invoiceList.length);
        let renderedinvoiceList = this.state.invoiceList.slice(indexOfFirstTodo, indexOfLastTodo);

       // console.log('this.state.renderedinvoiceList===',renderedinvoiceList);

            listItems = renderedinvoiceList.map((item, index) => {
                //console.log('item===',item);
                const create_date = dateFormat(item.createDate, "mm-dd-yyyy");
               // console.log('item.dueDate===',create_date);
                return <tr key={index}>
                    <td class="tbl_org_name">                    
                        {create_date}
                    </td>
                    <td class="tbl_org_name">                    
                        {item.invoiceNumber}
                    </td>
                    <td class="tbl_org_name">                    
                        {item.description}
                    </td>
                    <td class="tbl_org_name">                    
                        ${item.amount.amount}
                    </td>
                    <td class="tbl_org_name">                    
                        <a className="forlink" target="_blank" href={item.sharelink}>{item.datePaid==undefined?<>Pay Now</>:<>Paid/Receipt</>}</a>
                        
                    </td>
                </tr>
            });
        }
        return (
			<div className="container-fluid ProjectList"  ref="selfdiv">
                {
                    this.state.startloader ?
                        <div className="loaderspinner">
                            <Loader
                                type="TailSpin"
                                color="#D10000"
                                height={100}
                                width={100}
                            />
                        </div>
                        : null
                }
                <div className="row MoPrologoLeft">
                    <div className="col-12 ProAssetsHead">
                        <img  className="mr-3" src={imagesrc}/>
                        <h4 class="PageTopTitle">{orgdata.organization_name}</h4>
                        <p>
                            {orgdata.street}<br/>
                            {orgdata.city},{orgdata.state} {orgdata.zip} <br/>
                            {orgdata.phone}
                        </p>
                    </div>                         
                </div>
                <div className="row">
                    <div className="col-12">
                       <div className="row">
                           <div className="col-9">
                            <h3>Invoices</h3>
                           </div>
                           <div className="col-3" style={{"text-align" : "right"}}>
                               <select name="filterinvoice" value={this.state.filterinvoice} onChange={this.handleChange}>
                                    <option value="This Year">This Year</option>
                                    <option value="Last Year">Last Year</option>
                                    <option value="All">All</option>
                              </select>
                           </div>
                       </div>
                       
                       <div className="msg">
                            <div className="errorsmsg">{this.state.errormsg}</div>
                            <div className="successmsg">{this.state.successmsg}</div>
                       </div>
                       <div className="row">
                            <div className="panel-body" style={{"width" : "100%"}}>
                                <table className="table table-striped ListTable">
                                    <thead>
                                        <tr>
                                            <th class="tbl_org_name"><b>Issue Date</b></th>
                                            <th class="tbl_org_street"><b>Invoice Number</b></th>
                                            <th class="tbl_org_city" style={{"width" : "45%"}}><b>Description</b></th>
                                            <th class="tbl_org_state"><b>Amount</b></th>
                                            <th class="tbl_org_zip"><b>Status</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {listItems}
                                    </tbody>
                                </table>
                            </div>
                            <div className="Mobrow" style={{"width" : "100%"}}>
                            
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={this.state.itemPerPage}
                                    totalItemsCount={this.state.invoiceList.length}
                                    pageRangeDisplayed={10}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            
                            </div>
                        </div><br/>
                    </div>
                </div> <br/><br/>
            </div>
		);
    }
}
export default FreshbookInvoice;
