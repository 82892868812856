import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
export default class ViewOrganization extends Component {
  constructor(props) {
    super(props);
    this.state={ 
                org_name: "", 
                street: " ",
                city: "", 
                state: " ",
                zip: " ",
                phone: " ",
                twitter: " ",
                linkedin: " "               
              };
  }
  componentDidMount() {
  	//console.log('this.props.match.params.id==',this.props.match.params.id);
      /*axios.get('http://localhost:4000/business/edit/'+this.props.match.params.id)
          .then(response => {
              this.setState({ 
                person_name: response.data.person_name, 
                business_name: response.data.business_name,
                business_gst_number: response.data.business_gst_number });
          })
          .catch(function (error) {
              console.log(error);
          })*/
   
          	this.setState({ 
                org_name: "test", 
                street: "test street",
                city: "test city", 
                state: "test state",
                zip: "test zip",
                phone: "test phone",
                twitter: "test twitter",
                linkedin: "test linkedin"
              });
    }


 
  render() {
    const project_id = this.props.match.params.id
    return (
            <div style={{ marginTop: 10 }}>
                <div className="page-header">
              <div className="pull-left">
              <h3>Update Organization</h3>
              </div>
              <div className="pull-right">
              <h3 className="text-right">
              <Link align="right" to={"/organization/" + project_id + "/poject/create"}>Create project</Link>             
              </h3>
              </div>
              <div className="clearfix"></div>
            </div>            
            <div className="form-horizontal">
                <div className="form-group">
                  <label>Organization: </label>
                  {this.state.org_name}
                </div>
                <div className="form-group">
                  <label>Street: </label>
                  {this.state.street}
                </div>
                <div className="form-group">
                  <label>City: </label>
                  {this.state.city}
                </div>
                <div className="form-group">
                  <label>State: </label>
                  {this.state.state}
                </div>
                 <div className="form-group">
                  <label>Zip Code: </label>
                  {this.state.zip}
                </div>
                 <div className="form-group">
                  <label>Phone: </label>
                  {this.state.phone}
                </div>
                 <div className="form-group">
                  <label>Twitter: </label>
                  {this.state.twitter}
                </div>
                 <div className="form-group">
                  <label>LinkedIn:</label>
                  {this.state.linkedin}
                </div>
            </div>
            
        </div>
    )
  }
}