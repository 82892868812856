import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PostData, GetData } from '../../services/PostData';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML,genKey, Entity, Modifier } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Dropdown } from 'react-bootstrap';
import { Button, Collapse, Modal } from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import { apiConfig } from "../../config/api"
import io from "socket.io-client";
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll'
import Draft from './projectboard/Draft';
import Video from './projectboard/Video';
import CompFile from './projectboard/CompFile';
import EditDraft from './projectboard/EditDraft';
import SaveTheDate from "./projectboard/SaveTheDate";
import SaveTheScript from "./projectboard/SaveTheScript";
import SaveVoiceOver from "./projectboard/SaveVoiceOver";
import FooterChatBox from "./projectboard/FooterChatBox";
import StoryBoard from "./projectboard/StoryBoard";
import CreateTask from "./projectboard/CreateTask";
import AddTaskTime from "./projectboard/AddTaskTime";
import EditTask from "./projectboard/EditTask";
import AddTimeForTask from "./projectboard/AddTimeForTask";
import UpdateTask from "./projectboard/UpdateTask";
import ProjectBoard from "./projectboard/ProjectBoard";
import DraftList from "./projectboard/DraftList";
import ProjectAssets from "./projectboard/ProjectAssets";
import AddAsset from "./projectboard/AddAsset";
import AddVideographyForm from "./projectboard/AddVideographyForm"; 
import ReplyVideographyForm from "./projectboard/ReplyVideographyForm";
import ReplyTravelAuthorizationForm from "./projectboard/ReplyTravelAuthorizationForm";
import Calendly from "./projectboard/Calendly";
import CalendlyIframe from "./projectboard/CalendlyIframe";
import axios from "axios";
import { useCalendlyEventListener,PopupModal, InlineWidget } from "react-calendly";
import TimeExpense from "./projectboard/TimeExpense";
import dateFormat from 'dateformat';
class ViewProject extends Component {
	state = {
		duedate: new Date(),
		tduedate: new Date(),
		editedtduedate: new Date(),
	};
	constructor() {
		super();
		let currentuserid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user.ID;
		this.state = {
			calendlyusers: {},
			users: {},
			project_id: '',
			scheduletype: '',
			loading: false,			
			Inloading: false,
			associatedusers: [],
			producers: [],
			ischecked: false,
			duedate: '',			
			projectboard: '',
			data: [],
			per: 1000,
			page: 1,
			total_pages: null,
			isData: true,
			response: false,
			endpoint: "http://localhost:8080",
			isreadytosend: true,
			fields: {},
			errors: {},
			values: [],			
			userdata: '',
			teammember: '',
			boarddisplaytype: '',
			tasks: [{ userid: "", teammember: "", assignment: "", tduedate: "" }],
			editedtaskid: '',
			editedtaskteammember: '',
			editedtaskassignment: '',
			editedtaskduedate: '',
			addTaskType: '',
			taskduedatesucsmsg: '',
			editedtaskstatus: '',
			tcomment: '',
			taskactivity: [],
			totalTime: '00:00',
			remainingtime: '00:00',
			editedprojectstatus: '',
			chatmessages: [],
			editorState: EditorState.createEmpty(),
			hasFocus: false,
			showproject: false,
			projecttitlesucsmsg: '',
			storyboardsuccessmessage: '',
			draftid: '',
			draftidcompleted:'',
			draftstatus: false,
			draftstatusloading: false,
			showMoretext:false,
			showText: false,
			chatattchedfilename:'',
			chatattchedfilebasename:'',
			progressstatus:0,
			uploadchattattchamentloader:false,
			editedboardid:'',
			editeddata:'',
			chatuploadincurrentpage:false,
			setConfirmMsgShow:false,
			editeddraftid:'',
			editeddraftvideoname: '',
			isChecked:false,
			markascompleteerror:'',
			setDraftCommentShow:false,
			commentdata:[],
			userid:currentuserid,
			selectedassigneeuserid:'',
			selectedassigneeteammember:'',
			ischatedit:false,
			editdescription:false,
			project_box_folderid:'',
			project_draft_box_folderid:'',
			customerdata:[],
			replydata:'',
			orgname:'',
			freshbook_profile_id:'',
			vimeo_folder_uri:'',
			assigneduser:'',
			selectedCalendlyCheckboxes:[],
			organizationName:''
		};		
		this.onChange = this.onChange.bind(this);
		this.caledlyAssocusersChanges = this.caledlyAssocusersChanges.bind(this);
		this.producerChanges = this.producerChanges.bind(this);			
		this.mesRef = React.createRef();
		let url = apiConfig.WEB_SERVICE_URL;
		url = url.replace('http://', '');
		url = url.replace('/api/', '');
		this.socket = io(url);
		this.socket.on('RECEIVE_MESSAGE', function (data) {
			addMessage(data);
		});
		let currentstate=this;
		const addMessage = data => {
			if (data.project_id === this.props.match.params.prjid) {
				//console.log("checkkkdattatat-=====", data);
				if(data.edited)
				{
					this.setState({
						editeddata: data,
						editedboardid:'',
					})
				}
				else
				{
					this.setState({
						data: [...this.state.data, data],
						scrolling: false,
						isData: true,
					})
					this.setState({ischatedit:false, chatmessages: [...this.state.chatmessages, data] });
				}				
			}
		};
	}
	getUserData(userid) {
		const data={
			userid:userid
		}
		return PostData('edituser',data).then((result) => {
			return result;
		});
	}
	addClendlyNotifyMessage = async(data) =>{
	//const addClendlyNotifyMessage = async (data) => {
	//async addClendlyNotifyMessage(data){
		await this.getUserData(202312118405).then((udata)=>{
	    	//console.log('udata=',udata);
	    	let dbuserdata = udata.data
	    	// userdatadb = await userdatadb.json();
		    // console.log('userdatadb=',userdatadb);
		    let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		    const userrole = userdata.role;
		    //console.log('userdata=',userdata);
		    //this.sendMessage(dbuserdata);
		    //if(this.props.assigneduser && (userrole=='associatio-subscriber' || userrole=='subscriber')) { //if(this.props.assigneduser && userrole=='associatio-subscriber') {
			    this.sendMessage(dbuserdata,data);
			//}
	    });
	}
	sendMessage(userdata,data){
		let currentuserdetails = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;		
		//console.log('currentuserdetails=',currentuserdetails);
		let assigneduser = this.state.assigneduser;
		//console.log('assigneduser==',assigneduser);
		//console.log('resource data==',data);
		const ev_member = data.event_memberships;
		const meet_start_time = data.start_time;
		const meet_zoom_link = data.location.join_url;
		const meet_date = dateFormat(meet_start_time, "mmm d, yyyy");
		const meet_time = dateFormat(meet_start_time, "h:MM:ss TT");
		//console.log('meet_date==',meet_date);
		//console.log('meet_time==',meet_time);
		//console.log('meet_zoom_link==',meet_zoom_link);
		const currentuserfname = currentuserdetails.first_name;
		const currentuseremail = currentuserdetails.email;

		var matches = ev_member.filter(function(element) {
		    return element['user_email'] === currentuseremail;
		});
		//console.log('matches.length ',matches); 
		//console.log('matches.length > 0',matches.length); 

		const formatedmemberdata =[];
		let taggeduserstring='';
		ev_member.forEach((member) => {
			const mebname = member.user_name;
			const mebnamearra = mebname.split(" ");
			//console.log('mebnamearra',mebnamearra);
			const memfname = mebnamearra[0];
			let changesObj = {
				"type": "MENTION",
			      "mutability": "IMMUTABLE",
			      "data": {
			        "text": memfname,
					"url": member.user_email,
					"value": memfname
			      }
			}
			taggeduserstring += '@'+memfname+ ' ';
			formatedmemberdata.push(changesObj);
		    //console.log(changesObj);
		});
		if(matches.length==0){
			let changesObj = {
				"type": "MENTION",
			      "mutability": "IMMUTABLE",
			      "data": {
			        "text": currentuserfname,
					"url": currentuseremail,
					"value": currentuserfname
			      }
			}
			taggeduserstring += '@'+currentuserfname+ ' ';
			formatedmemberdata.push(changesObj);
		}
		//console.log('taggeduserstring=',taggeduserstring);
		//console.log('formatedmemberdata=',formatedmemberdata);
		let concat_msg;
		if(meet_zoom_link && meet_zoom_link!=null && meet_zoom_link!='null' && meet_zoom_link!='NULL')
		{
			concat_msg=`To join, please use this link: ${meet_zoom_link}`;
		}
		const msg = `Hello ${taggeduserstring} a Zoom call has been scheduled for ${meet_date} at ${meet_time}. Please check your email for more details. ${concat_msg}`;
		const tname='@'+assigneduser.first_name;
		const tvalue=assigneduser.first_name;
		const turl=assigneduser.email;
		let exampleState1= {
		  blocks: [
		        {
		          key: genKey(), //Use the genKey function from draft
		          text: msg,
		          type: 'styled',
		          inlineStyleRanges: [],
		          data: {},
		          depth: 5,
		          entityRanges: [
		            { offset: 0, length: 30, key: 0 },
		            { offset: 31, length: 30, key: 1 },
		            { offset: 61, length: 30, key: 2 },
		            { offset: 91, length: 30, key: 3 },
		            { offset: 121, length: 30, key: 4 },
		            { offset: 151, length: 30, key: 5 },
		            { offset: 181, length: 30, key: 6 }
		          ]
		        }
		  ],
		  entityMap: formatedmemberdata
		};

		//const blocksFromHTML = convertFromHTML(msg);
		//console.log('blocksFromHTML===',blocksFromHTML.contentBlocks);
		//console.log('blocksFromHTML===',blocksFromHTML.contentBlocks[0]);
		//blocksFromHTML.contentBlocks[0];
		//let aa = JSON.parse(blocksFromHTML.contentBlocks[0]);
		//console.log('aa===',aa);




  		//const blocksFromHTML = convertFromHTML(msg);

  		//const blocksFromHTML1 = convertFromHTML(msg);
  //const state = ContentState.createFromBlockArray(
  //  blocksF//romHTML1.contentBlocks,
   // blocksFromHTML1.entityMap
  //);

//const es1 =  EditorState.createWithContent(state);
//const aaa = es1.getCurrentContent();



//const selectionState = es1.getSelection();
//const contentStateWithEntity  = aaa.createEntity('MENTION', 'IMMUTABLE', {"text":currentuserfname,"value":currentuserfname,"url":currentuseremail});
//const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
//const contentStateWithLink = Modifier.applyEntity(
//  contentStateWithEntity,
 // selectionState,
 // entityKey,
//);

//const newEditorState = EditorState.set(es1, {
  //currentContent: contentStateWithLink,
//});

//const newEditorState = EditorState.set(es1, { currentContent: contentStateWithEntity });

  		//console.log('es===',es1);
  		//console.log('aaa===',aaa);
  		//console.log('newEditorState===',newEditorState.getCurrentContent());
  		//console.log('convertFromRaw===',convertFromRaw(exampleState1));
    	const es =  EditorState.createWithContent(convertFromRaw(exampleState1));
		const editorJSON = JSON.stringify(convertToRaw(es.getCurrentContent()));
        if(true) {
			this.socket.emit('SEND_MESSAGE', {
				userdata: userdata,
				project_id: this.props.match.params.prjid,
				org_id:this.props.match.params.orgid,
				message: editorJSON,
				chatattachament: '',
				chatattchedfilename:'',
				chatattchedfilebasename:'',
				chatid:'',
				chatallusers:this.state.allusers
			})
	    }
	}
	showText(e){
		this.setState({
			showText:!this.state.showText,
			//showMoretext:!this.state.showMoretext
		});
		this.addClassForShowText();
	}
	editDescription(e){
		this.setState({
			showText:!this.state.showText,
			editdescription:!this.state.editdescription
		});
		this.addClassForShowText();
	}
	onEditorStateChange = (projectEditorState) => {		
		//const rawState = JSON.stringify(convertToRaw(projectEditorState.getCurrentContent()));
	    this.setState({
			projectEditorState:projectEditorState
	    });
	};
	updateDescription(e){
		let pdes=this.state.projectEditorState;
		const rawState = JSON.stringify(convertToRaw(pdes.getCurrentContent()));
		let data = {
			'project_id': this.props.match.params.prjid,
			'project_description': rawState,
		}
		
		//console.log('rawState===',rawState);
		PostData('updateprojectdescription', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ project_description: result.data.description,projectdessucsmsg:result.message });
				setTimeout(() => {
					this.setState({  
					projectdessucsmsg: '',
					showText:!this.state.showText,
				    editdescription:!this.state.editdescription
				 });
				 this.addClassForShowText();
				}, 3000);
					
			}
		});
	}
	addClassForShowText(e){
		this.setState({
			'showMoretext': true
		})
	}
	scrollToBottom = () => {
		scroll.scrollToBottom();
	};
	scrollToTop = () => {
		scroll.scrollToTop();
	};
	backToProjectBoard() {
		this.setState({ boarddisplaytype: 'projectboard' });
	}
	changeView(displaytype, e) {
		this.setState({ boarddisplaytype: displaytype });
	}
	isJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
	handleScroll = () => {
		var lastLi = document.querySelector("ul.container > li:last-child");
		if (lastLi) {
			var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
			var pageOffset = window.pageYOffset + window.innerHeight;
			if (pageOffset > lastLiOffset && this.state.isData) {
				this.loadMore();
			}
		}
	};
	loadMore = (projectid) => {
		if (projectid === undefined) {
			projectid = this.props.match.params.prjid;
		}
		const isreadytosend = this.state.isreadytosend;
		if (isreadytosend) {
			this.setState(
				prevState => ({
					page: 1,
					scrolling: true
				}),
				this.getProjectBoardData(projectid)
			);
			//console.log('caledddddddddddddddddd');
			//this.scrollToBottom();
		}
	};
	loadProjectBoard = (returndata) => {	
		//console.log('returndata==',returndata);	
		let remainingtime = this.getDifferenceTime(returndata);
		//console.log('remainingtime==',remainingtime);	
		this.setState({
			remainingtime:remainingtime
		});
		let projectid = this.props.match.params.prjid;
		const isreadytosend = this.state.isreadytosend;
		if (isreadytosend) {
			this.setState(
				prevState => ({
					page: 1,
					scrolling: true
				}),
			this.getProjectBoardData(projectid)
			);
			//console.log('caledddddddddddddddddd');
			//this.scrollToBottom();
		}
	};
	updateTime = (returndata) => {	
		//console.log('returndata==',returndata);	
		let remainingtime = this.getDifferenceTime(returndata);
		//console.log('remainingtime==',remainingtime);	
		this.setState({
			remainingtime:remainingtime
		});		
	};
	onClose = () =>{
		this.setState({
			isOpen:false
		});	
	}
	onPopUpClose = () =>{
		this.setState({
			calendlyusers: {},
			selectedCalendlyCheckboxes: []
		});	
	}
	componentDidMount() {		
		this.callProjectBoard(this.props.match.params.prjid);
		this.getPostProductionTeamMemberByPrjId(this.props.match.params.prjid);
	}
	getPostProductionTeamMemberByPrjId(projectid) {
		const url = 'getpostproductionteamMemberbyprjid/'+projectid;
		GetData(url).then((result) => {
			this.setState({ isreadytosend: true, Inloading: false, assigneduser:result.data });
			//console.log('data returnedddddddddd',result.data);
			
		});
	}
	componentWillReceiveProps(newProps){
		this.callProjectBoard(newProps.match.params.prjid);
	}
	callProjectBoard(projectid){
		//console.log('called in callProjectBoard',projectid);
		//this.addClick();
		const { per, page, data, endpoint } = this.state;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		//console.log("userdata==",userdata);
		this.setState({ loading: true, project_id: projectid, userdata: userdata });
		this.selectedCheckboxes = new Set();
		this.scrollListener = window.addEventListener("scroll", e => {
			//this.handleScroll(e);
		});
		this.loadMore(projectid);
		Promise.all([this.getProjectData(projectid), this.getOrgContactsData(), this.getOrgCustomerData()])
			.then(([project_detail, producers, customerdata]) => {
				let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
				//console.log('userdataforcheck==',userdataforcheck);
				let customerupdateddata;
				let freshbook_profile_id= customerdata.data.freshbook_profile_id;
				//console.log('freshbook_profile_id==',freshbook_profile_id);
				if (userdataforcheck.role && userdataforcheck.role==='administrator')
				{
					customerupdateddata =customerdata;
				} else {
					customerupdateddata=[];
				}
				//console.log('producers======',producers);
				this.setState({ loading: false, totalTime: project_detail.total_time, });
				let editorState = '';
				if (userdata.role==='administrator')
					var producersdata = producers.data;
					
				else
					var producersdata = producers.data.org_users;
				let customers;
				let orgname;
				if(customerdata && customerdata.data && customerdata.data.org_users.length>0)
				{
					customers =customerdata.data.org_users;
					orgname =customerdata.data.nick_name;
					this.setState({
						organizationName:customerdata.data.organization_name
					})
				}
				if (project_detail.associated_users) {
					for (const userid of project_detail.associated_users) {
						//console.log('associated_users==',project_detail.associated_users);
						this.selectedCheckboxes.add(parseInt(userid.ID));
					}
				}
				
				if (this.isJson(project_detail.data.description)) {
					const contentState = convertFromRaw(JSON.parse(project_detail.data.description));
					//const currentContent = this.state.editorState.getCurrentContent();
					//const currentContentLength = currentContent.getPlainText('').length
									
					editorState = EditorState.createWithContent(contentState);
					const contentState11 = editorState.getCurrentContent();
					const length =contentState11.getPlainText().length ;
					//const length = editorState.getCurrentContent().getPlainText('').length;
					//console.log('description====',length);
					this.setState({ descriptionlength: length });
				}
				else {
					if(project_detail.data.description==='[object Object]')
					{					
						editorState = EditorState.createEmpty();
					}
					else
					{
						const sampleMarkup = '<p>' + project_detail.data.description+ '</p>';
						const blocksFromHTML = convertFromHTML(sampleMarkup);
						const contentState = ContentState.createFromBlockArray(
							blocksFromHTML.contentBlocks,
							blocksFromHTML.entityMap
						);
						editorState = EditorState.createWithContent(contentState);
					}
				}
				
				//console.log("project_detail.data.totaladded_time==", project_detail.data.totaladded_time);
				//console.log("this.state.remainingtime==", this.state.remainingtime);
				let remainingtime = this.state.remainingtime;
				if (project_detail.data.totaladded_time) {
					remainingtime = this.getDifferenceTime(project_detail.data.totaladded_time);
				}
				//console.log("project_detail.remainingtime.remainingtime==", remainingtime);
				this.setState({
					project_name: project_detail.data.project_name,
					projectEditorState: editorState,
					duedate: project_detail.data.due_date?new Date(project_detail.data.due_date) : new Date() ,
					isduedate: project_detail.data.due_date,
					productionschedule: project_detail.data.productionschedule,
					producers: producersdata,//Before producers: producers.data 
					associatedusers: project_detail.associated_users,
					remainingtime: remainingtime,
					editedprojectstatus: project_detail.data.status,
					boarddisplaytype: 'projectboard',
					projectdata:project_detail.data,
					allusers:project_detail.associated_users,
					project_box_folderid:project_detail.data.project_box_folderid,
					project_draft_box_folderid:project_detail.data.draft_box_folderid,
					customerdata:customers,
					orgname:orgname,
					freshbook_profile_id:freshbook_profile_id,
					vimeo_folder_uri:project_detail.data.vimeo_folder_uri,
				});

			});
	}
	editProjectTitle() {
		this.setState({ showproject: true });
	}
	getDifferenceTime(added_time) {
		var total_time = this.state.totalTime;
		if (total_time)
			var total_time = total_time.toString();
		else
			var total_time = '0:0';
		var starttime = added_time.split(':');
		var endTime = total_time.split(':');
		let remainingtime = added_time
		return remainingtime;
	}
	getProjectData(projectid) {
		//console.log('this.state.project_id===',projectid);
		return GetData('project/' + projectid).then((result) => {
			return result;
		});
	}
	getOrgContactsData() {
		let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		//console.log('userdataforcheck==',userdataforcheck);
		if (userdataforcheck.role && userdataforcheck.role==='administrator')
			var action = 'orgcontacts/';
		else
			var action = 'orgprofile/';
		return GetData(action + this.props.match.params.orgid).then((result) => {
			return result;
		});
	}
	getOrgCustomerData() {
		var action = 'orgprofile/';
		return GetData(action + this.props.match.params.orgid).then((result) => {
			return result;
		});
	}
	getProjectBoardData(project_id) {
		//console.log('project_id==',project_id);
		this.setState({ scheduletype: '', isreadytosend: false, Inloading: true });
		const { per, page, data } = this.state;
		//console.log('pageeeeee=',page);
		const url = `projectboard/${project_id}?per_page=${per}&page=${page}`;
		GetData(url).then((result) => {
			this.setState({ isreadytosend: true, Inloading: false });
			if (result.data.length > 0) {
				//console.log('data returnedddddddddd');
				this.setState({
					//data: [...data, ...result.data],
					data: result.data,
					scrolling: false,
					isData: true,
				})
				this.scrollToBottom();
			}
			else {
				this.setState({
					isData: false,
				})
			}
		});
	}
	state = {
		startDate: new Date()
	};
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	//minut
	handleCheck(e) {
		this.setState({ scheduletype: e.currentTarget.dataset.id, boarddisplaytype: '' });
		this.scrollToTop();
	}				
	showTaskList(e) {
		this.setState({ boarddisplaytype: e.currentTarget.dataset.id, Inloading: true });
		Promise.all([this.taskList()])
		.then(([result]) => {
			this.setState({ taskdata: result.data, Inloading: false });
		});
	}
	taskList(){
		const url = `tasklist/${this.props.match.params.prjid}`;
		return GetData(url).then((result) => {
			return result;
		});
	}
	showDraftList(e) {
		this.setState({ boarddisplaytype: 'DraftList' });
		const url = `draftlist/${this.props.match.params.prjid}`;
		GetData(url).then((result) => {
			this.setState({ draftdata: result.data, Inloading: false });
		});
	}
	showAssetList(e) {
		this.setState({ boarddisplaytype: 'projectassets' });
		const url = `projectassets/${this.props.match.params.prjid}`;
		GetData(url).then((result) => {
			this.setState({ assetdata: result.data, Inloading: false });
		});
	}
	editTask(taskid) {
		//console.log('called edit',taskid);
		this.setState({ scheduletype: 'EditTask', editedtaskid: taskid, Inloading: true });
		const url = `edittask/${taskid}`;
		GetData(url).then((result) => {
			let etaskduedate = new Date(result.data.due_date);
			if (!result.data.due_date)
				etaskduedate = new Date();
			this.setState({ Inloading: false, editedtaskteammember: result.data.teammember, editedtaskassignment: result.data.assignment, editedtaskduedate: etaskduedate, editedtaskstatus: result.data.status, taskactivity: result.data.taskactivity, selectedassigneeuserid:result.data.user_id, selectedassigneeteammember:result.data.teammember });
		});
	}
	editChat(boardid) {
		const url = `editchat/${boardid}`;
		let currdate=Date.now();
		//console.log('currdate==',currdate);
		GetData(url).then((result) => {
			let contentState = convertFromRaw(JSON.parse(result.data.chatmessage));							
			let editorState = EditorState.createWithContent(contentState)
			this.setState({
				editorState:editorState,
				editedboardid:boardid,
				ischatedit:currdate,
			});
		});
	}
	removeEditChat(e){
		this.setState({
			editedboardid:''
		});
	}
	addTimeForTask(taskid){
		//console.log('back to==',taskid);
		this.setState({ scheduletype: 'AddTimeForTask',addTaskType:'Time', editedtaskid: taskid, Inloading: true });
		const url = `edittask/${taskid}`;
		GetData(url).then((result) => {
			if(result.statusCode===200)
			{
				this.setState({ boarddisplaytype:'UpdateTask', Inloading: false, taskactivity: result.data.taskactivity,selectedassigneeuserid:result.data.user_id, editedtaskassignment: result.data.assignment,selectedassigneeteammember:result.data.teammember});				
			}
		});
	}	
	showReplyRequest(item) {
		//console.log('item to==',item);
		let currdate=Date.now();
		this.setState({ setReplyVideographyShow: true,replyvideographyTime:currdate,replydata:item });
	}
	showReplyTravelAuthorization(item) {
		//console.log('item to==',item);
		let currdate=Date.now();
		this.setState({ setReplyTravelAuthorizationShow: true,replyTravelAuthorizationTime:currdate,replyTravelAuthorizationdata:item });
	}
	updateProjectTitle(e) {
		let data = {
			'project_id': this.props.match.params.prjid,
			'project_title': this.state.project_name,
		}
		PostData('updateprojecttitle', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ project_name: result.data.project_name, showproject: false, projecttitlesucsmsg: result.message });
				setTimeout(() => this.setState({ projecttitlesucsmsg: '' }), 3000);	
			}
		});
	}
	handleProjectStatusChange(e) {
		this.setState({ editedprojectstatus: e.target.value });
		let data = {
			'project_id': this.props.match.params.prjid,
			'status': e.target.value,
		}
		PostData('updatestatus', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ projectstatussucsmsg: result.message });
				setTimeout(() => this.setState({ projectstatussucsmsg: '' }), 3000);		
			}
		});
	}		
	setShow(e) {		
		//console.log('called in click function   ');
		this.setState({ setShow: true });
	}
	handleClose(e) {
		this.setState({ setShow: false });
	}
	setAddTimeShow(e) {
		let currdate=Date.now();
		Promise.all([this.taskList()])
			.then(([result]) => {			
			//console.log('tasklist==',result.data)
			this.setState({ taskdata:result.data, setAddTimeShow: true,isAddTaskTime:currdate });
		});
	}
	async scheduleACall(e) {
		let currdate=Date.now();
		this.setState({  setAddCalendlyShow: true,isAddCalendlyTime:currdate });
	}
	showCalendly = (eventtype) => {	
		//console.log('eventtype',eventtype)
		this.setState({ isOpen: true });
		this.setState({ scheduletype: 'Schedule A Call', boarddisplaytype: '',eventtype: eventtype });
		//this.scrollToTop();
	}
	handleCalendlyClose(e) {
		this.setState({ handleCalendlyShow: false });
	}
	setVideographyShow(e) {
		let currdate=Date.now();
		this.setState({ setVideographyShow: true,videographyTime:currdate });
	}
	setAddAssetShow(e){
		let currdate=Date.now();
		this.setState({ setAddAssetShow: true,isAddAsset:currdate });
	}
	producerChanges(event) {
		//console.log('event.target.value==',event.target.value,this.state.users,this.selectedCheckboxes);
		if (this.selectedCheckboxes.has(parseInt(event.target.value))) {
			this.selectedCheckboxes.delete(parseInt(event.target.value));
		} else {
			this.selectedCheckboxes.add(parseInt(event.target.value));
		}
		this.setState({ users: this.selectedCheckboxes });
		return false
	}
	caledlyAssocusersChanges(event) {
		//console.log('event.target.value==',event.target.value);
		//console.log('event.target.calendlyusers==',this.state.calendlyusers);
		//console.log('event.target.selectedCalendlyCheckboxes==',this.state.selectedCalendlyCheckboxes);
		
		this.state.selectedCalendlyCheckboxes.push(event.target.value);
		this.setState({ calendlyusers: this.state.selectedCalendlyCheckboxes });
		return false
	}
	handleSave(e) {
		let uid = [];
		this.setState({ loading: true });
		for (const userid of this.state.users) {
			uid.push(userid);
		}
		let data = {
			'users': uid,
			'project_id': this.state.project_id
		}
		PostData('associateusers', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ associatedusers: result.data });
				this.setState({ setShow: false, loading: false });
				//this.props.history.push("/org/"+this.props.match.params.id+"/project/list");
			}
		});
	}		
	handleProjectDateChange(dateName, dateValue) {
		let duedate = this.state.duedate;
		duedate = dateValue;
		this.setState({
			duedate,
			isduedate:duedate
		});
		let data = {
			'project_id': this.props.match.params.prjid,
			'due_date': duedate
		}
		PostData('updateduedate', data).then((result) => {
			if (result.statuscode === 200) {
				//console.log('resule=====', result);
				this.setState({ projectduedatesucsmsg: result.message, editedtaskduedate: new Date(result.data.due_date) }); setTimeout(() => this.setState({ projectduedatesucsmsg: '' }), 3000);
			}
		});
	}
	checkAssocaitedUsers(associatedusers, producersId) {
		for (var key in associatedusers) {
			if (associatedusers[key].ID === parseInt(producersId)) {
				return true;
			}
		}
		return false;
	}
	render() {
		//let assocusers='';
		let producers = this.state.producers;
		let scheduletype = this.state.scheduletype;
		let boarddisplaytype = this.state.boarddisplaytype;
		let associatedusers = this.state.associatedusers;
		const assocusers = [];
		let assocteammembers = [];
		const produceusers = [];
		const caledlyAssocusers = [];
		let taskactvactivitydata = [];
		let pboard = '';
		const loading = this.state.loading;
		const Inloading = this.state.Inloading;
		let editedtaskteammember = this.state.editedtaskteammember;
		let editedtaskassignment = this.state.editedtaskassignment;
		let editedtaskduedate = this.state.editedtaskduedate;
		let editedtaskstatus = this.state.editedtaskstatus;
		let taskactivity = this.state.taskactivity;
		let totalTime = this.state.totalTime;
		let remainingtime = this.state.remainingtime;
		const ischecked = false;
		const tasktime = [];
		const tasknote = [];
		let associateduserforhastag = [];
		if (totalTime)
			totalTime = totalTime;
		else
			totalTime = '0:0';
		const showMoretext=(this.state.showMoretext)?'showMoretext':'';
		const editdescriptiontext=(this.state.editdescription)?'editdescription':'';
		const tasks = [];
		const tasklist = this.state.taskdata;
		let customerdata = this.state.customerdata;
		const customers = [];
		if (tasklist) {
			//console.log('tasklist==',tasklist);
			for (var key in tasklist) {

				tasks.push(<option value={tasklist[key]._id} data-userid={tasklist[key].user_id} data-teammember={tasklist[key].teammember}>{tasklist[key].assignment +" - "+ tasklist[key].teammember}</option>)
			}
		}
		if (taskactivity) {
			for (var key in taskactivity) {
				if (taskactivity[key].type === 'notes') {
					tasknote.push(<li className="itemlist"><div className="row">
						<div className="col-12 ChatBoxDesign">
							<div className="type">
								<Link to={require('../../assets/images/projectsassets/' + taskactivity[key].notesfile + '')} target="_blank" download>Download
						</Link>
							</div>
							<div className="PB_create_date">
								<label>Comment: </label>
								{taskactivity[key].comment}
							</div>
						</div>
					</div>
					</li>
					)
				}
				else {
					tasktime.push(<li className="itemlist"><div className="row"><div className="col-12 ChatBoxDesign">
						<div className="type">
							<label>Time: </label>
							{taskactivity[key].time}
						</div>
						<div className="PB_create_date"><label>Comment: </label>{taskactivity[key].comment}
						</div></div></div></li>)

				}
			}
		}
		for (var key in producers) {
			if(producers[key].status==='active'){
			let removeUser=[4922222]
			//let removeUser=[49,639,51]
			//console.log('producers===',producers);
			let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
			var display_name ='';
			let email;
			if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
				//var userId=producers[key].id;
				//var display_name=producers[key].name;
				var userId = producers[key].ID;
				display_name = producers[key].first_name+' '+producers[key].last_name;
				//console.log('display_name===',display_name);
				email = producers[key].email;
			}
			else {
				var userId = producers[key].ID;
				display_name = producers[key].first_name+' '+producers[key].last_name;
				email = producers[key].email;
			}

			let ischecked = this.checkAssocaitedUsers(associatedusers, userId);
			//console.log('associatedusers==',associatedusers,'producers[key].data.ID==',producers[key].data.ID,ischecked);
			let check = removeUser.includes(parseInt(userId));
			if(!check)
			{
				//console.log('check===',check,userId,removeUser);
				produceusers.push(
					<div className="checkbox">
						<label >
							<input value={userId} type="checkbox" defaultChecked={ischecked} name={display_name} onChange={this.producerChanges} />
							{display_name}
						</label>
					</div>
				)
				if(ischecked){
					console.log('display_name=',display_name)
					caledlyAssocusers.push(
						<div className="checkbox">
							<label >
								<input value={email} type="checkbox" name={display_name} onChange={this.caledlyAssocusersChanges} />
								{display_name}
							</label>
						</div>
					)
				}
			}
		}
		}
		for (var key in customerdata) {
			if(customerdata[key].status==='active'){
				let removeUser=[4922222]
				let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
				var display_name ='';
				var userId = customerdata[key].ID;
				display_name = customerdata[key].first_name+' '+customerdata[key].last_name;
				//console.log('display_name===',display_name);
				
				let ischecked = this.checkAssocaitedUsers(associatedusers, userId);
				//console.log('associatedusers==',associatedusers,'producers[key].data.ID==',producers[key].data.ID,ischecked);
				let check = removeUser.includes(parseInt(userId));
				if(!check)
				{
					//console.log('check===',check,userId,removeUser);
					customers.push(
						<div className="checkbox">
							<label >
								<input value={userId} type="checkbox" defaultChecked={ischecked} name={display_name} onChange={this.producerChanges} />
								{display_name}
							</label>
						</div>
					)
				}
			}
		}
		if (associatedusers) {
			var userdataforcheck11 = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
			for (var key in associatedusers) {
				let noimage =false; 
                let noimagename=''; 
				if(associatedusers[key].ID)
				{
					var display_name = associatedusers[key].first_name+' '+associatedusers[key].last_name;
						
					var imageurl=''
					if(userdataforcheck11.role==='administrator' && associatedusers[key].profile_image)
					{
						//console.log('in admin associatedusers[key].==',associatedusers[key].first_name);
						imageurl='../../../../profile-image/'+associatedusers[key].profile_image;
					}
					else if((userdataforcheck11.role==='subscriber' || userdataforcheck11.role==='associatio-subscriber')  && associatedusers[key].profile_image)
					{
						//console.log('in subs associatedusers[key].==',associatedusers[key].first_name);
					imageurl='../../../../../profile-image/'+associatedusers[key].profile_image;
					}
					else
					{
						//console.log('in else associatedusers[key].==',associatedusers[key].first_name);
					imageurl='';
					}

					var imgpath=associatedusers[key].profile_image;
					//console.log('imgpath==',imgpath);					
					var imgname = imgpath.split(".");
					imgname = imgname[0];
					imgname = imgname.split("_");
					//console.log('imgname.length',imgname.length);
					//console.log('imgname====',imgname);
					if(imgname.length>1 && imgname[1]==='noimage')
					{
						noimage=true;
						if(associatedusers[key].first_name)
						var fn=associatedusers[key].first_name
						if(associatedusers[key].last_name)
						var ln=associatedusers[key].last_name
						noimagename=fn.charAt(0)+ln.charAt(0);
						noimagename = noimagename.toUpperCase();
						//console.log('imgname==',imgname);
					}

					let displayname=associatedusers[key].first_name+' '+associatedusers[key].last_name;
					assocusers.push(<li>
						{
							(noimage)?<div className="noimage pbnoimage">{noimagename}</div>:<img title={display_name} alt={associatedusers[key].display_name} src={imageurl} />
						}
						</li>)
					assocteammembers.push(<option data-id={associatedusers[key].ID} value={display_name}>{display_name}</option>)
					associateduserforhastag.push({ text: displayname, value: associatedusers[key].first_name, url: associatedusers[key].email });
				   
				}
			}
			associateduserforhastag.push({text:'All',value:'All'});
		}			
		const ExampleCustomInputForDuedate = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				{
					(!this.state.isduedate &&
						<div>
						TBD
						</div>
						
					)
				}
				{
					(this.state.isduedate &&
						<div>
						Due {value}
						</div>
						
					)
				}
				 
			</button>
		);
		const handleClose = this.state.handleClose;
		const handleShow = this.state.setShow;
		const avatar = this.state.userdata.profile_image;
		const fname = this.state.userdata.first_name;
		const lname = this.state.userdata.last_name;
		const loggedemail = this.state.userdata.email;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		const expensedata = this.state.data.filter((pbdata) => pbdata.type == 'expense' || (pbdata.type == 'Reply Travel AuthorizationForm' && pbdata.amount && pbdata.amount!=''));
		return (
			<div>
				{
					loading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<div className="WrapMenuTop container-fluid MoProViewTop">
				<div className="col-12 col-sm-12 col-md-6 col-lg-6 MobTabViewProTop">
							<label>View:</label>
							<ul className="top-links">
								<li>
									<a className="forcusror" onClick={this.changeView.bind(this, 'projectboard')}>All</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.showTaskList.bind(this)} data-id="UpdateTask">Tasks</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.showAssetList.bind(this)}>Assets</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.showDraftList.bind(this)}>Videos</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.changeView.bind(this, 'TimeExpense')}>Time & Expenses</a>
								</li>
							</ul>
						</div>
					<div className="row ProjectViewTop">
						<div className="col-6 col-sm-6 col-md-2 c
						ol-lg-2 LeftTopMenu">
							<div className="dropdown">
								<div className="successmsg">{this.state.projectstatussucsmsg}</div>
								<select name="taskstatus" className="form-control" value={this.state.editedprojectstatus} onChange={this.handleProjectStatusChange.bind(this)} >
									<option value=" ">Select Project Status</option>
									<option value="Active">Active</option>
									<option value="On-hold">On-hold</option>
									<option value="Completed">Completed</option>
								</select>
							</div>
						</div>
						<div className="col-6 col-sm-6 col-md-2 col-lg-2 LeftTopMenu MobileDisplay">
							<Dropdown>
								<Dropdown.Toggle variant="success1" id="dropdown-basic">
									<img className="dropdown-icon" src={require('../../assets/images/upload-icon.png')} /> Add
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{/* <Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Video Shoot">Video Shoot</Dropdown.Item> */}
									<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Save the Date">Save the Date</Dropdown.Item>
									<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Script">Script</Dropdown.Item>
									<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Voice Over">Voice Over</Dropdown.Item>
									<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Storyboard">Storyboard</Dropdown.Item>
									<Dropdown.Item onClick={this.setAddAssetShow.bind(this)} >Add Asset</Dropdown.Item>	
									{/* {userdata.capabilities && !userdata.capabilities.administrator && <div>
										<Dropdown.Item onClick={this.setAddAssetShow.bind(this)}>Add Asset</Dropdown.Item>	
										</div>									
									} */}
									{userdata.role && userdata.role==='administrator' && <div>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Draft">Draft</Dropdown.Item>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Video">Video</Dropdown.Item>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Comp File">Comp File</Dropdown.Item>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="CreateTask">Create Task</Dropdown.Item>
										<Dropdown.Item onClick={this.setAddTimeShow.bind(this)}>Add Time</Dropdown.Item>
										<Dropdown.Item onClick={this.scheduleACall.bind(this)}>Schedule A Call</Dropdown.Item>
										</div>
									}
									<Dropdown.Item onClick={this.setVideographyShow.bind(this)}>Videography Request</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div className="col-12 col-sm-12 col-md-5 col-lg-5 ProTitleInHeader">
							<div className="prj-title">
								<div className="successmsg">{this.state.projecttitlesucsmsg}</div>
								<h3> {this.state.project_name} <span class="edit-project" onClick={(e) => this.editProjectTitle(e)}> <img src={require('../../assets/images/edit-icon.png')} /></span></h3>
								{
									(this.state.showproject &&
										<div class="project-edit">
											<input type="text" defaultValue={this.state.project_name} onChange={this.onChange} className="form-control" name="project_name" id="projecttitle" />
											<button type="button" onClick={(e) => this.updateProjectTitle(e)} className="UpdateBtn btn btn-primary">Update</button>
										</div>
									)
								}
							</div>							
						<div class="MobileDisplay">
						<div className="ProTitleTime">
								<div className="successmsg">{this.state.projectduedatesucsmsg}</div>
								<div className="DueDate">
									<DatePicker className="form-control" selected={this.state.duedate}
										name="duedate"
										minDate={moment().toDate()}
										onChange={date => this.handleProjectDateChange('duedate', date)}
										dateFormat="MM/dd/yyyy"
										excludeDates={[
											new Date('11/23/2020'),
											new Date('11/24/2020'),
											new Date('11/25/2020'),
											new Date('11/26/2020'),
											new Date('11/27/2020'),
											new Date('12/21/2020'),
											new Date('12/22/2020'),
											new Date('12/23/2020'),
											new Date('12/24/2020'),
											new Date('12/25/2020'),
											new Date('12/26/2020'),
											new Date('12/27/2020'),
											new Date('12/28/2020'),
											new Date('12/29/2020'),
											new Date('12/30/2020'),
											new Date('12/31/2020'),
											new Date('1/1/2021'),
											new Date('2/15/2021'),
											new Date('2/16/2021'),
											new Date('2/17/2021'),
											new Date('2/18/2021'),
											new Date('2/19/2021'),
											new Date('4/5/2021'),
											new Date('4/6/2021'),
											new Date('4/7/2021'),
											new Date('4/8/2021'),
											new Date('4/9/2021'),
											new Date('5/17/2021'),
											new Date('5/18/2021'),
											new Date('5/19/2021'),
											new Date('5/20/2021'),
											new Date('5/21/2021'),
											new Date('7/5/2021'),
											new Date('7/6/2021'),
											new Date('7/7/2021'),
											new Date('7/8/2021'),
											new Date('7/9/2021'),
											new Date('9/6/2021'),
											new Date('9/7/2021'),
											new Date('9/8/2021'),
											new Date('9/9/2021'),
											new Date('9/10/2021'),
											new Date('11/22/2021'),
											new Date('11/23/2021'),
											new Date('11/24/2021'),
											new Date('11/25/2021'),
											new Date('11/26/2021'),
											new Date('12/20/2021'),
											new Date('12/21/2021'),
											new Date('12/22/2021'),
											new Date('12/23/2021'),
											new Date('12/24/2021'),
											new Date('12/25/2021'),
											new Date('12/26/2021'),
											new Date('12/27/2021'),
											new Date('12/28/2021'),
											new Date('12/29/2021'),
											new Date('12/30/2021'),
											new Date('12/31/2021'),
										]}
										customInput={<ExampleCustomInputForDuedate />} />
								</div>
								<div className="time-estimate">
									<p>&nbsp;&nbsp;/&nbsp;
										Time {remainingtime}
										{/* /{totalTime} Hours */}
									</p>
								</div>
							</div>
						</div>
						</div>
						<div className="col-12 col-sm-12 col-md-5 col-lg-5 RightTopMenu">
							<ul className="ProducersList">
								{assocusers}
								<li className="lastli">
									<a onClick={this.setShow.bind(this)}>
										<img src={require('../../assets/images/add_icon_box.png')} />
									</a>
								</li>
							</ul>
							<div className="dropdown">
								<Dropdown>
									<Dropdown.Toggle variant="success1" id="dropdown-basic">
										<img className="dropdown-icon" src={require('../../assets/images/upload-icon.png')} /> Add
									</Dropdown.Toggle>
									<Dropdown.Menu>
										{/* <Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Video Shoot">Video Shoot</Dropdown.Item> */}
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Save the Date">Save the Date</Dropdown.Item>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Script">Script</Dropdown.Item>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Voice Over">Voice Over</Dropdown.Item>
										<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Storyboard">Storyboard</Dropdown.Item>
										<Dropdown.Item onClick={this.setAddAssetShow.bind(this)} >Add Asset</Dropdown.Item>	
										{/* {userdata.capabilities && !userdata.capabilities.administrator && <div>
											<Dropdown.Item onClick={this.setAddAssetShow.bind(this)} >Add Asset</Dropdown.Item>	
											</div>									
										} */}
										{userdata.role && userdata.role==='administrator' && <div>
											<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Draft">Draft</Dropdown.Item>
											<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Video">Video</Dropdown.Item>
											<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Comp File">Comp File</Dropdown.Item>
											<Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="CreateTask">Create Task</Dropdown.Item>
											<Dropdown.Item onClick={this.setAddTimeShow.bind(this)}>Add Time</Dropdown.Item>
											<Dropdown.Item onClick={this.scheduleACall.bind(this)}>Schedule A Call</Dropdown.Item>
											</div>
											
										}
										<Dropdown.Item onClick={this.setVideographyShow.bind(this)}>Videography Request</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
								<div class="col-6 MobileDisplay">
									<div className="dropdown">
										<div className="successmsg">{this.state.projectstatussucsmsg}</div>
										<select name="taskstatus" className="form-control" value={this.state.editedprojectstatus} onChange={this.handleProjectStatusChange.bind(this)} >
											<option value=" ">Select Project Status</option>
											<option value="Active">Active</option>
											<option value="On-hold">On-hold</option>
											<option value="Completed">Completed</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row WrapMenuTopTabs">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 MobTabViewPro">
							<label>View:</label>
							<ul className="top-links">
								<li>
									<a className="forcusror" onClick={this.changeView.bind(this, 'projectboard')}>All</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.showTaskList.bind(this)} data-id="UpdateTask">Tasks</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.showAssetList.bind(this)}>Assets</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.showDraftList.bind(this)}>Videos</a>
								</li>
								<li>
									<a className="forcusror" onClick={this.changeView.bind(this, 'TimeExpense')}>Time & Expenses</a>
								</li>
							</ul>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 MobTabDueDt">
							<div className="ProTitleTime">
								<div className="successmsg">{this.state.projectduedatesucsmsg}</div>
								<div className="DueDate">
									<DatePicker className="form-control" selected={this.state.duedate}
										name="duedate"
										//minDate={moment().toDate()}
										onChange={date => this.handleProjectDateChange('duedate', date)}
										dateFormat="MM/dd/yyyy"
										excludeDates={[
											new Date('11/23/2020'),
											new Date('11/24/2020'),
											new Date('11/25/2020'),
											new Date('11/26/2020'),
											new Date('11/27/2020'),
											new Date('12/21/2020'),
											new Date('12/22/2020'),
											new Date('12/23/2020'),
											new Date('12/24/2020'),
											new Date('12/25/2020'),
											new Date('12/26/2020'),
											new Date('12/27/2020'),
											new Date('12/28/2020'),
											new Date('12/29/2020'),
											new Date('12/30/2020'),
											new Date('12/31/2020'),
											new Date('1/1/2021'),
											new Date('2/15/2021'),
											new Date('2/16/2021'),
											new Date('2/17/2021'),
											new Date('2/18/2021'),
											new Date('2/19/2021'),
											new Date('4/5/2021'),
											new Date('4/6/2021'),
											new Date('4/7/2021'),
											new Date('4/8/2021'),
											new Date('4/9/2021'),
											new Date('5/17/2021'),
											new Date('5/18/2021'),
											new Date('5/19/2021'),
											new Date('5/20/2021'),
											new Date('5/21/2021'),
											new Date('7/5/2021'),
											new Date('7/6/2021'),
											new Date('7/7/2021'),
											new Date('7/8/2021'),
											new Date('7/9/2021'),
											new Date('9/6/2021'),
											new Date('9/7/2021'),
											new Date('9/8/2021'),
											new Date('9/9/2021'),
											new Date('9/10/2021'),
											new Date('11/22/2021'),
											new Date('11/23/2021'),
											new Date('11/24/2021'),
											new Date('11/25/2021'),
											new Date('11/26/2021'),
											new Date('12/20/2021'),
											new Date('12/21/2021'),
											new Date('12/22/2021'),
											new Date('12/23/2021'),
											new Date('12/24/2021'),
											new Date('12/25/2021'),
											new Date('12/26/2021'),
											new Date('12/27/2021'),
											new Date('12/28/2021'),
											new Date('12/29/2021'),
											new Date('12/30/2021'),
											new Date('12/31/2021'),
										]}
										customInput={<ExampleCustomInputForDuedate />} />
								</div>
								<div className="time-estimate">
									<p>&nbsp;&nbsp;/&nbsp;
										Time {remainingtime}
										{/* /{totalTime} Hours */}
									</p>
								</div>
							</div>							
						</div>
					</div>
				</div>
				<div className="clearfix"></div>
				<div className="PrimaryWrap container-fluid MoProView">
					<div className="row">
						<div className="col-12 NonePadd">
							<div className={'ProducerChatBox '+showMoretext + ' ' + editdescriptiontext}>
								<div className="successmsg">{this.state.projectdessucsmsg}</div>
								<h5>Project Description <a className="forcusror descriptioncedit" onClick={this.editDescription.bind(this)}><img src={require('../../assets/images/edit-icon.png')} /></a></h5>								
								<Editor editorState={this.state.projectEditorState} toolbar={{
								options: ['inline', 'list', 'emoji', 'link'],
								}}
								onEditorStateChange={(e) => this.onEditorStateChange(e)}
								/>
								 <a className="updatedesc" onClick={this.updateDescription.bind(this)}>Update</a>
								{
									(!this.state.showText && 
										<a className="forcusror" onClick={this.showText.bind(this)}>See more</a>
										// <a onClick={() => this.setState({ showText: !this.state.showText })}>See more</a>
										)
								}								
								<Collapse in={this.state.showText}>
								<div>
								{
									(this.state.projectdata && this.state.projectdata.productionschedule && this.state.projectdata.productionschedule!='undefined') &&
									<div className="otherdesc">
										<strong>Production Schedule: </strong>{this.state.projectdata.productionschedule}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.selctedCategory && this.state.projectdata.selctedCategory!='undefined') &&
									<div className="otherdesc">
										<strong>Category: </strong>{this.state.projectdata.selctedCategory}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.selctedSubCategory && this.state.projectdata.selctedSubCategory!='undefined') &&
									<div className="otherdesc">
										<strong>Sub Category: </strong>{this.state.projectdata.selctedSubCategory}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.selectedEmo && this.state.projectdata.selectedEmo!='undefined') &&
									<div className="otherdesc">
										<strong>Emotions: </strong>{this.state.projectdata.selectedEmo.toString(',')}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.selectedAsset && this.state.projectdata.selectedAsset!='undefined') &&
									<div className="otherdesc">
										<strong>Assets: </strong>{this.state.projectdata.selectedAsset.toString(',')}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.selectedVideo && this.state.projectdata.selectedVideo!='undefined') &&
									<div className="otherdesc">
										<strong>How will this video be used: </strong>{this.state.projectdata.selectedVideo.toString(',')}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.videostyle && this.state.projectdata.videostyle!='undefined') &&
									<div className="otherdesc">
										<strong>Video Style: </strong>{this.state.projectdata.videostyle}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.doyouhaveimagevideos && this.state.projectdata.doyouhaveimagevideos!='undefined') &&
									<div className="otherdesc">
										<strong>Do you already have assets such as images or videos to use for this project: </strong>{this.state.projectdata.doyouhaveimagevideos}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.describeassets && this.state.projectdata.describeassets!='undefined') &&
									<div className="otherdesc">
										<strong>Great! Please describe the assets you have: </strong>{this.state.projectdata.describeassets}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.doyouneedscheduleinterview && this.state.projectdata.doyouneedscheduleinterview!='undefined') &&
									<div className="otherdesc">
										<strong>Do we need to schedule interviews or shoot an event:</strong>{this.state.projectdata.doyouneedscheduleinterview}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.describedetails && this.state.projectdata.describedetails!='undefined') &&
									<div className="otherdesc">
										<strong>Great! Please provide the details:</strong>{this.state.projectdata.describedetails}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.animationtype && this.state.projectdata.animationtype!='undefined') &&
									<div className="otherdesc">
										<strong>Animated Explainer Video Style: </strong>{this.state.projectdata.animationtype}
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.needascriptorvoice && this.state.projectdata.needascriptorvoice!='undefined') &&
									<div className="otherdesc">
										<strong>Sounds like we'll need a script and a voice over: </strong>{this.state.projectdata.needascriptorvoice}									
									</div>
								}
								{
									(this.state.projectdata && this.state.projectdata.scriptfile && this.state.projectdata.scriptfile!='undefined') &&
									<div className="otherdesc">
										<strong>Download Script: </strong>
										<Link to={require('../../assets/images/project/' + this.state.projectdata.scriptfile + '')} target="_blank" download>Download	</Link>
									</div>
								}
								</div>
							</Collapse>
							{
								(this.state.showText &&
									<a className="forcusror" onClick={() => this.setState({ showText: !this.state.showText })}>Show less</a>
									)
							}
							</div>														
							{
								(scheduletype == 'Save the Date' &&
									<SaveTheDate loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} />			
								)
							}							
							{
								(scheduletype == 'Script' &&
									<SaveTheScript associateduserforhastag={associateduserforhastag} fname={fname} lname={lname} allusers={this.state.allusers} loadProjectBoard={this.loadProjectBoard} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} editedboardid={this.state.editedboardid} editorState={this.state.editorState} />	
								)								
							}
							{
								(scheduletype == 'Voice Over' &&
									<SaveVoiceOver associateduserforhastag={associateduserforhastag} fname={fname} lname={lname} allusers={this.state.allusers} loadProjectBoard={this.loadProjectBoard} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} editedboardid={this.state.editedboardid} editorState={this.state.editorState} />	
								)							
							}
							{
								(scheduletype == 'Storyboard' &&
									<StoryBoard loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} />	
								)									
							}
							{
								(scheduletype == 'Draft' &&
									<Draft vimeo_folder_uri={this.state.vimeo_folder_uri} project_draft_box_folderid={this.state.project_draft_box_folderid} associateduserforhastag={associateduserforhastag} fname={fname} lname={lname} allusers={this.state.allusers} loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} editedboardid={this.state.editedboardid} editorState={this.state.editorState} />
								)
							}
							{
								(scheduletype == 'Video' &&
									<Video vimeo_folder_uri={this.state.vimeo_folder_uri} project_draft_box_folderid={this.state.project_draft_box_folderid} associateduserforhastag={associateduserforhastag} fname={fname} lname={lname} allusers={this.state.allusers} loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} editedboardid={this.state.editedboardid} editorState={this.state.editorState} />
								)
							}
							{
								(scheduletype == 'Comp File' &&
									<CompFile vimeo_folder_uri={this.state.vimeo_folder_uri} project_draft_box_folderid={this.state.project_draft_box_folderid} associateduserforhastag={associateduserforhastag} fname={fname} lname={lname} allusers={this.state.allusers} loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} editedboardid={this.state.editedboardid} editorState={this.state.editorState} />
								)
							}
							{
								(scheduletype == 'EditDraft' &&
									<EditDraft loadProjectBoard={this.loadMore.bind(this)} draftid={this.state.draftid} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} />
								)
							}
							{
								(scheduletype == 'CreateTask' &&
									<CreateTask loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} associatedusers={this.state.associatedusers} />	
								)								
							}
							{
								(scheduletype == 'EditTask' && editedtaskteammember &&
									<EditTask loadProjectBoard={this.updateTime} loadOProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} assocteammembers={assocteammembers} editedtaskteammember={editedtaskteammember} editedtaskassignment={editedtaskassignment} editedtaskduedate={editedtaskduedate} editedtaskstatus={editedtaskstatus} taskactvactivitydata={taskactvactivitydata} tasknote={tasknote} tasktime={tasktime} editedtaskid={this.state.editedtaskid} taskactivity={taskactivity} userid={this.state.userid} selectedassigneeuserid={this.state.selectedassigneeuserid} selectedassigneeteammember={this.state.selectedassigneeteammember} />	
								)								
							}
							{
								(scheduletype == 'AddTimeForTask' && boarddisplaytype === 'UpdateTask' &&
								<AddTimeForTask loadProjectBoard={this.loadProjectBoard} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} assocteammembers={assocteammembers} editedtaskteammember={editedtaskteammember} editedtaskassignment={editedtaskassignment} editedtaskduedate={editedtaskduedate} editedtaskstatus={editedtaskstatus} taskactvactivitydata={taskactvactivitydata} tasknote={tasknote} tasktime={tasktime} editedtaskid={this.state.editedtaskid} taskactivity={taskactivity} userid={this.state.userid} selectedassigneeuserid={this.state.selectedassigneeuserid} selectedassigneeteammember={this.state.selectedassigneeteammember} />
								)								
							}
							{
								(scheduletype == 'Schedule A Call' &&
									<CalendlyIframe addClendlyNotifyMessage={this.addClendlyNotifyMessage} onclose={this.onClose} isOpen={this.state.isOpen} eventtype={this.state.eventtype} fname={fname} lname={lname} loggedemail={loggedemail} allusers={this.state.allusers} loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar}  editorState={this.state.editorState} userid={this.state.userid}  />
								)
							}
							<div className="Projectboard" id="CompleteProjectBoard" ref='wrap'>

								{
									(boarddisplaytype === 'UpdateTask' &&									
										<UpdateTask backToProjectBoard={this.backToProjectBoard.bind(this)} editTask={this.editTask.bind(this)} addTimeForTask={this.addTimeForTask.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} taskdata={this.state.taskdata} />							
									)
								}
								{
									(boarddisplaytype === 'DraftList' &&	
									<div>{
										Inloading ?
											<div className="loadercenter">
												<Loader
													type="ThreeDots"
													color="#D10000"
													height={100}
													width={100}
												/>
											</div>
										: null
									}
									<DraftList editTask={this.editTask.bind(this)} addTimeForTask={this.addTimeForTask.bind(this)} editChat={this.editChat.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} draftdata={this.state.draftdata} commentdata={this.state.commentdata} editeddata={this.state.editeddata} />
									</div>									
									)
								}
								{
									(boarddisplaytype === 'projectassets' && this.state.project_box_folderid &&		
									<div>{
										Inloading ?
											<div className="loadercenter">
												<Loader
													type="ThreeDots"
													color="#D10000"
													height={100}
													width={100}
												/>
											</div>
										: null
									}
									<ProjectAssets editTask={this.editTask.bind(this)} addTimeForTask={this.addTimeForTask.bind(this)} editChat={this.editChat.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} assetdata={this.state.assetdata} project_box_folderid={this.state.project_box_folderid} />
									</div>										
									)
								}
								{
									(boarddisplaytype === 'TimeExpense' &&		
									<div>{
										Inloading ?
											<div className="loadercenter">
												<Loader
													type="ThreeDots"
													color="#D10000"
													height={100}
													width={100}
												/>
											</div>
										: null
									}
									<TimeExpense projectboarddata={this.state.data} freshbook_profile_id={this.state.freshbook_profile_id} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} organizationName={this.state.organizationName} project_name={this.state.project_name} />
									</div>										
									)
								}
								{
									(boarddisplaytype != 'TimeExpense' && boarddisplaytype != 'projectassets' && boarddisplaytype != 'UpdateTask' && boarddisplaytype != 'DraftList' &&
									//(boarddisplaytype === 'projectboard' &&
										<div>{
											Inloading ?
												<div className="loadercenter">
													<Loader
														type="ThreeDots"
														color="#D10000"
														height={100}
														width={100}
													/>
												</div>
											: null
										}
										<ProjectBoard showCalendly={this.showCalendly} allusers={this.state.allusers} assigneduser={this.state.assigneduser} requestVideographer={this.setVideographyShow.bind(this)} replyRequest={this.showReplyRequest.bind(this)} replyTravelAuthorization={this.showReplyTravelAuthorization.bind(this)} projecttime={remainingtime} associatedusers={associatedusers} editTask={this.editTask.bind(this)} addTimeForTask={this.addTimeForTask.bind(this)} editChat={this.editChat.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} data={this.state.data} commentdata={this.state.commentdata} editeddata={this.state.editeddata} datahello="hello" />
										</div>
									)
								}
								<div className="no-product">
									{
										(!this.state.isData) &&
										<p></p>
									}
								</div>
							</div>
							<div className="clearfix"></div>
							<br />
						</div>
						<div className="col-3">
											
						</div>
						<FooterChatBox associateduserforhastag={associateduserforhastag} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar} fname={fname} lname={lname} editedboardid={this.state.editedboardid} allusers={this.state.allusers} editorState={this.state.editorState} ischatedit={this.state.ischatedit} removeEditChat={this.removeEditChat.bind(this)} />		
					</div>
				</div>
				{
					(producers &&
						<Modal show={handleShow} onHide={handleClose}>
							{
								loading ?
									<div className="loaderspinner">
										<Loader
											type="TailSpin"
											color="#D10000"
											height={100}
											width={100}
										/>
									</div>
									: null
							}
							<Modal.Header class="SelectProducerHead">
								<Modal.Title>Select Production Team</Modal.Title>
							</Modal.Header>
							<Modal.Body class="SelectProducerModel">
								{produceusers}
								<hr/>
								{customers}
							</Modal.Body>
							<Modal.Footer class="FullWidthModelFoot">
								<Button variant="secondary" onClick={this.handleClose.bind(this)}>
									X
					          </Button>
								<Button variant="primary" onClick={this.handleSave.bind(this)}>
									Save Changes
					          </Button>
							</Modal.Footer>
						</Modal>
					)
				}
				{
					(this.state.setAddCalendlyShow &&
						<Calendly onPopUpClose={this.onPopUpClose} calendlyusers={this.state.calendlyusers} handleAddCalendlyShow={this.state.setAddCalendlyShow} isAddCalendlyTime={this.state.isAddCalendlyTime} handleCalendlyShow={this.state.handleCalendlyShow} caledlyAssocusers={caledlyAssocusers}  associateduserforhastag={associateduserforhastag} fname={fname} lname={lname} allusers={this.state.allusers} loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} avatar={avatar}  editorState={this.state.editorState} userid={this.state.userid}  />
					)
				}
				{
					(this.state.setAddTimeShow &&
						<AddTaskTime loadProjectBoard={this.loadProjectBoard} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid}  tasks={tasks} handleAddTimeShow={this.state.setAddTimeShow} isAddTaskTime={this.state.isAddTaskTime} userid={this.state.userid} />
					)
				}
				{
					(this.state.setAddAssetShow &&
						<AddAsset loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} isAddAsset={this.state.isAddAsset}  handleAddAssetShow={this.state.setAddAssetShow} userid={this.state.userid} />
					)
				}	
				{
					(this.state.setVideographyShow &&
						<AddVideographyForm loadProjectBoard={this.loadMore.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} handleVideographyShow={this.state.setVideographyShow} videographyTime={this.state.videographyTime} userid={this.state.userid} />
					)
				}
				{
					(this.state.setReplyVideographyShow &&
						<ReplyVideographyForm loadProjectBoard={this.loadMore.bind(this)} replydata={this.state.replydata} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} handleReplyVideographyShow={this.state.setReplyVideographyShow} replyvideographyTime={this.state.replyvideographyTime} userid={this.state.userid} />
					)
				}
				{
					(this.state.setReplyTravelAuthorizationShow &&
						<ReplyTravelAuthorizationForm freshbook_profile_id={this.state.freshbook_profile_id} orgname={this.state.orgname} loadProjectBoard={this.loadMore.bind(this)} replyTravelAuthorizationdata={this.state.replyTravelAuthorizationdata} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} handleReplyTravelAuthorizationShow={this.state.setReplyTravelAuthorizationShow} replyTravelAuthorizationTime={this.state.replyTravelAuthorizationTime} userid={this.state.userid} />
					)
				}
			</div>
		);
	}
}
export default ViewProject;
