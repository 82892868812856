import React, { Component } from "react";
import { apiConfig } from "../../config/api"
import Loader from 'react-loader-spinner'
import {PostData} from '../../services/PostData';
class UploadStock extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            loading: false,         
            selectedmoodcat:[],
            selectedgenrecat:[],
            fields:{},
            errors: {},    
            boxfolderid:'122120619948',
            audiosavesuccmsg:'',
            artistname:''   
        };
        this.onChange = this.onChange.bind(this);
        this.onVideoFileChangeHandler = this.onVideoFileChangeHandler.bind(this);
    }     
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });        
    }    
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    } 
    onUrlChange(e){
        this.setState({[e.target.name]:e.target.value});
        let data={
            'url':e.target.value,           
         }
        PostData('checkstockgraphicurl',data).then((result) => {
            console.log('result==',result); 
            let errors = {};            
            if(result.statuscode===200)
            {
                if(result.isExist)
                {
                    this.setState({
                        urlerrormsg:'URL already exist'
                    });   
                    errors["url"] = "URL already exist";
                    this.setState({
                        errors: errors
                    });           
                }
                else{
                    this.setState({
                        urlerrormsg:''
                    });
                    errors["url"] = "";
                    this.setState({
                        errors: errors
                    });
                }
            }
        });        
    }

    saveGraphicData(e){
        if (this.validateForm())
        {
            this.setState({
                loading:true
            })
            var formdata = new FormData();

            formdata.append('folderid', this.state.boxfolderid) 
            formdata.append('title',  this.state.title)
            formdata.append('url',  this.state.url)
            formdata.append('video',  this.state.video)
            let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
            let session_key = userdata.token;
            fetch(apiConfig.WEB_SERVICE_URL + 'savegraphic', {
                method: 'POST',
                body: formdata,
                headers: {
                    "session-key": session_key,
                }
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.statusCode)
                {
                    this.setState({
                        loading:false
                    })
                    this.setState({ audiosavesuccmsg: res.message, saveBoardDataloader: false, data: []});
                    setTimeout(() => {
                        this.setState({ audiosavesuccmsg: '' })
                        this.props.loadGraphics();
                    }, 3000);
                }
            });
                
        }                               
    }
    validateForm() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        console.log('fields==',fields);
        if (!fields["video"]) {
            formIsValid = false;
            errors["video"] = "*Please upload Demo Video";
        }   
        if (!fields["url"]) {
            formIsValid = false;
            errors["url"] = "*Please Add Video URl";
        }   
        if (fields["urlerrormsg"]) {
            formIsValid = false;
            errors["url"] = "*Url Already Exist";
        }        
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    onVideoFileChangeHandler=event=>{
        this.setState({
          'video': event.target.files[0],
          loaded: 0,
        })
    }
    render() {       
        const loading = this.state.loading;        
        return (
             <div>
                 {
                    loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="successmsg">{this.state.audiosavesuccmsg}</div>                             
                <div className="row no-border padding-rop-top">
                    <div className="col-3">
                        <div className="error-msg">
                            {this.state.errors.title}
                        </div>
                        <input className="form-control" type="text" value={this.state.title} onChange={this.onChange} name="title" placeholder="Title"/>
                    </div>                    
                    <div className="col-3">
                        <div className="error-msg">
                            {this.state.errors.url}
                        </div>
                        <input className="form-control" type="text" value={this.state.url} onChange={this.onUrlChange.bind(this)} name="url" placeholder="Url"/>
                    </div>
                    <div className="col-3">
                        <div className="dropdown">
                            <input type="file" name="video" onChange={this.onVideoFileChangeHandler} className="form-control videofile" autoFocus /> 
                            <div className="errorMsg">{this.state.errors.video} </div>  
                            <label>Upload Demo Video</label>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="col text-left">
                            <button type="button"  onClick={this.saveGraphicData.bind(this)}  className="UpdateBtn btn btn-primary">Save</button> 
                        </div>
                    </div>
                </div>
                                                                        
            </div>
        );
    }
}
export default UploadStock;
