import React, { Component } from 'react';
import axios from 'axios';
import {PutData,GetData} from '../../services/PostData';
import {apiConfig} from "../../config/api"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import dateFormat from 'dateformat';
import Configdata from '../../config.json';
const errorMsg = {
  color: '#cc0000',
  marginBottom:'12px'
};
const successMsg = {
  color: '#00ff00',
  fontSize:'16px'
};
export default class EditOrganization extends Component {
    state = {
	   // hours_renew_date: new Date()
	};
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {
        org_name: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        twitter: '',
        linkedin: '',
        logo: '',
        subscriber:'',
        boxfolderid:'',
        fields:'',
        errors:'',
        success:'',
        isshowing_subscription_hour:false,
        isshowing_org_credit:false,
        subscription_hour:'',
        totalavailable_hours:'',
        subscription_hour_rate:'',
        remaining_hours:'',
        credits:'',
        nick_name: '',
        website: '',
        hours_renew_date:'',
        freshbookprofileid:'',
        vimeo_uri:'',
        dates: [
			{ date: 1 },
			{ date: 2 },
			{ date: 3 },
			{ date: 4 },
			{ date: 5 },
			{ date: 6 },
			{ date: 7 },
			{ date: 8 },
			{ date: 9 },
			{ date: 10 },
			{ date: 11 },
			{ date: 12 },			
			{ date: 13 },
			{ date: 14 },
			{ date: 15 },
			{ date: 16 },
			{ date: 17 },
			{ date: 18 },
			{ date: 19 },
			{ date: 20 },
			{ date: 21 },
			{ date: 22 },
			{ date: 23 },
			{ date: 24 },
			{ date: 25 },
			{ date: 26 },
			{ date: 27 },
			{ date: 28 },			
		],
        }
        this.isSubscriberChange = this.isSubscriberChange.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    onChangeHandler=event=>{
        this.setState({
        'logo': event.target.files[0],
        loaded: 0,
        })
    }
    handleChange = date => {
        console.log('date=',date);
       const updateddate =  moment(date).format('YYYY/MM/DD');
       console.log('updateddate=',updateddate);
	    this.setState({
			hours_renew_date: updateddate
	    });
	}
    handleProjectDateChange(dateName, dateValue) {
		let hours_renew_date = this.state.hours_renew_date;
		hours_renew_date = dateValue;
		this.setState({
			hours_renew_date,			
		});
		
	}
    componentDidMount() {
        GetData('organization/'+this.props.match.params.id).then((result) => {     
        console.log('check result in edit page===',result);      
        console.log('check result in edit result.data.create_date===',result.data.create_date);    //result.data.create_date  
            this.setState({ 
            org_name: result.data.organization_name,
            street: result.data.street,
            city: result.data.city,
            state: result.data.state,
            zip: result.data.zip,
            phone: result.data.phone,
            twitter: result.data.twitter,
            linkedin: result.data.linkedin, 
            logo: result.data.logo,
            boxfolderid:result.data.boxfolderid, 
            subscriber: result.data.subscriber,
            nick_name:result.data.nick_name,
            website:result.data.website,
            hours_renew_date:result.data.renew_date,
            //renewdate:result.data.create_date,
            freshbookprofileid:result.data.freshbook_profile_id,
            vimeo_uri:result.data.vimeo_uri,
            //hours_renew_date: result.data.hours_renew_date?moment(result.data.hours_renew_date).format('YYYY/MM/DD') : new Date(),
            });
            if(result.data.subscriber==='yes')
            {
            this.setState({isshowing_subscription_hour:true,isshowing_org_credit:false,subscription_hour:result.data.subscription_hour,remaining_hours:result.data.remaining_hours,totalavailable_hours:result.data.totalavailable_hours,subscription_hour_rate:result.data.subscription_hour_rate});
            }
            else
            {
            this.setState({isshowing_org_credit:true,isshowing_subscription_hour:false,credits:result.data.credits});
            }
        });
    }
    onChange(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.value : target.value;
        const name = target.name;
        //console.log('this state===',name,value);
        this.setState({[name]:value});    
    } 
    isSubscriberChange(e){
        this.setState({[e.target.name]:e.target.value});
        console.log('e.target.value==',e.target.value);
            if(e.target.value==='yes')
            {
                this.setState({isshowing_subscription_hour:true});
                this.setState({isshowing_org_credit:false});
                //this.setState({org_credit:''});
            }
            else if(e.target.value==='no')
            {
                this.setState({isshowing_org_credit:true});
                this.setState({isshowing_subscription_hour:false});
                //this.setState({subscription_hour:''});
            }		
        }
    async submit(e) {
        e.preventDefault();
        if (this.validateForm())
        {
            const data = new FormData();
            if(!this.state.vimeo_uri){
                const response = await fetch(
                    'https://api.vimeo.com/users/70047375/projects',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `bearer `+Configdata.vimeo.AuthToken,//old 0c909954139c00b4b07af0ce71811f3d
                            'content-type': 'application/json',
                        },
                        body: JSON.stringify({
                            name : this.state.org_name,
                        }),
                    }
                );
                const resdata = await response.json();
                data.append('vimeo_uri', resdata.uri)
            }
            console.log('this.state.credits==',this.state.credits);		
            
            data.append('org_logo', this.state.logo)
            data.append('org_name', this.state.org_name)
            data.append('street', this.state.street)
            data.append('city', this.state.city)
            data.append('state', this.state.state)
            data.append('zip', this.state.zip)
            data.append('phone', this.state.phone)
            data.append('twitter', this.state.twitter)
            data.append('linkedin', this.state.linkedin)
            data.append('subscriber', this.state.subscriber)
            data.append('subscription_hour', this.state.subscription_hour)
            data.append('remaining_hours', this.state.remaining_hours)
            data.append('totalavailable_hours', this.state.totalavailable_hours)//totalavailable_hours
            data.append('subscription_hour_rate', this.state.subscription_hour_rate)//subscription_hour_rate
            data.append('hours_renew_date', this.state.hours_renew_date)//totalavailable_hours
            data.append('credits', this.state.credits)
            data.append('boxfolderid', this.state.boxfolderid)
            data.append('nick_name', this.state.nick_name)
            data.append('website', this.state.website)
            data.append('freshbook_profile_id', this.state.freshbookprofileid) 
            let session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
            console.log('data======data=',data);
            fetch(apiConfig.WEB_SERVICE_URL+'organization/'+this.props.match.params.id, {
            method: 'POST',
            body: data,
            headers: {
                    "session-key": session_key,
                }
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.statuscode===200)
                this.setState({success:'Organization Updated Successfully!!'}); 
                else
                this.setState({success:'Something went wrong'}); 
                //this.props.history.push("/organization/list");
            });
        }    
    }
    validateForm() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        if (!fields["org_name"]) {
            formIsValid = false;
            errors["org_name"] = "*Please enter your Organization Name";
        }
        if (typeof fields["zip"] !== "undefined") {
            //regular expression for email validation
            var zip = /^[0-9]{5}(?:-[0-9]{4})?$/;
            if (!zip.test(fields["zip"])) {
            formIsValid = false;
            errors["zip"] = "*Please enter valid zip code.";
            }
        }
        if (typeof fields["phone"] !== "undefined") {
            //regular expression for email validation
            var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
            if (!phoneNum.test(fields["phone"])) {
            formIsValid = false;
            errors["phone"] = "*Please enter valid phone number.";
            }
        }  
        
        if (!fields["subscriber"]) {
            formIsValid = false;
            errors["subscriber"] = "*Please select subscriber";
            console.log('formIsValid=',formIsValid);
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    handleDateChange(e) {
		this.setState({ hours_renew_date: e.target.value });
	}
    render() {
        const isshowing_subscription_hour=this.state.isshowing_subscription_hour;
        const isshowing_org_credit=this.state.isshowing_org_credit;
        const subscriber = this.state.subscriber;
        const succesmsg = this.state.success;
        let subscribed;
        let notsubscribed;
        if(subscriber)
        {
            if (subscriber=='yes') {
                subscribed = true;
                notsubscribed = false;
            } else {
                notsubscribed = true;
                subscribed = false;
            } 
        }
        const ExampleCustomInputForDuedate = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
			
				
						<div>
						Due {value}
						</div>
						
					
				 
			</button>
		);
        const dates = this.state.dates.map(i => (
        <option key={i.date} value={i.date}>
            { i.date }		      
        </option>
        ));
        return (
            <div className="container-fluid create-organization">
                <div class="row wrapper-box">
                    <div className="panel panel-primary">                    
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-md-7 col-lg-7">
                                    <h4 class="PageBarTitle">Update Organization</h4>
                                </div>
                                <div className="col-md-5 col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="panel-body">
                            <p style={successMsg}>{succesmsg}</p>
                            <div onSubmit={this.onSubmit} class="FormDesign">
                                <div className="form-label-group">
                                    <label htmlFor="org">Organization</label>
                                    <input type="text" name="org_name" value={this.state.org_name}
                                        onChange={this.onChange} className="form-control" autoFocus /> 
                                    <div style={errorMsg}>{this.state.errors.org_name}</div>                 
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label htmlFor="street">Street</label>
                                        <input type="text" name="street" value={this.state.street}
                                        onChange={this.onChange} className="form-control" autoFocus />  
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="city">City</label>
                                        <input type="text" name="city" value={this.state.city}
                                        onChange={this.onChange} className="form-control" autoFocus />  
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="state">State</label>
                                        <input type="text" name="state"  value={this.state.state}
                                            onChange={this.onChange} className="form-control" autoFocus />                    
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                    <label htmlFor="zip">Zip</label>
                                    <input type="text" name="zip" value={this.state.zip}
                                        onChange={this.onChange} className="form-control" autoFocus /> 
                                    <div style={errorMsg}>{this.state.errors.zip}</div>                    
                                    </div>
                                    <div className="col-sm-4">
                                    <label htmlFor="phone">Phone</label>
                                    <input type="text" name="phone"  value={this.state.phone}
                                        onChange={this.onChange} className="form-control" autoFocus />  
                                    <div style={errorMsg}>{this.state.errors.phone}</div>                   
                                    </div>
                                    <div className="col-sm-4">
                                    <label htmlFor="twitter">Twitter</label>
                                    <input type="text" name="twitter" value={this.state.twitter}
                                        onChange={this.onChange} className="form-control" autoFocus />                    
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                    <label htmlFor="linkedin">LinkedIn</label>
                                    <input type="text" name="linkedin" value={this.state.linkedin}
                                        onChange={this.onChange} className="form-control" autoFocus />                    
                                    </div>
                                    <div className="col-sm-4">
                                    <label htmlFor="logo">Upload Logo</label>
                                    <input type="file" name="logo" onChange={this.onChangeHandler} className="form-control" autoFocus />
                                    </div>
                                    <div className="col-sm-4">
                                    <label htmlFor="issubscriber">Subscriber</label><br/>
                                    <label className="radio-inline">
                                        <input type="radio" name="subscriber" defaultChecked={subscribed} onChange={this.isSubscriberChange} value="yes"/>Yes
                                    </label>
                                    <label className="radio-inline">
                                        <input type="radio" name="subscriber" defaultChecked={notsubscribed} onChange={this.isSubscriberChange} value="no" />No
                                    </label>
                                    </div>
                                </div> 
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label htmlFor="subscription_hour">Acronym/Nick Name</label>
                                        <input type="text" value={this.state.nick_name} name="nick_name" onChange={this.onChange} className="form-control" autoFocus />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="subscription_hour">Website</label>
                                        <input type="text" value={this.state.website} name="website" onChange={this.onChange} className="form-control" autoFocus />
                                    </div>
                                </div>
                                
                                    {
                                        isshowing_subscription_hour &&
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label htmlFor="subscription_hour">Subscription (In Hrs/Month )</label>
                                                <input type="number" value={this.state.subscription_hour} name="subscription_hour" onChange={this.onChange} className="form-control" autoFocus />
                                            </div>  
                                            <div className="col-sm-6">
                                                <label htmlFor="subscription_hour">Hours Renew Date </label>                                                
                                                <select name="taskstatus" className="form-control" value={this.state.hours_renew_date} onChange={this.handleDateChange.bind(this)} >
                                                    <option value=" ">Select Date</option>
                                                    {dates}							
                                                </select>
                                                {/* <DatePicker className="form-control"
                                                    selected={new Date(this.state.hours_renew_date)}
                                                    name="hours_renew_date"
                                                    onChange={this.handleChange}
                                                    dateFormat="MM/dd/yyyy"		
                                                />				 */}
                                            </div>
                                        </div>     
                                                   
                                    }
                                    

                                    {
                                        isshowing_subscription_hour &&
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label htmlFor="subscription_hour">Total Remaining Hours (In Hrs:Min )</label>
                                                <input type="text" value={this.state.totalavailable_hours} name="totalavailable_hours" onChange={this.onChange} className="form-control" autoFocus />
                                            </div> 
                                            <div className="col-sm-6">
                                                <label htmlFor="subscription_hour">Monthly Remaining Hours (In Hrs:Min )</label>
                                                <input type="text" value={this.state.remaining_hours} name="remaining_hours" onChange={this.onChange} className="form-control" autoFocus />
                                            </div> 
                                            <div className="col-sm-12">
                                                <label htmlFor="subscription_hour">Subscribed Hourly Rate </label>
                                                <input type="number" value={this.state.subscription_hour_rate} name="subscription_hour_rate" onChange={this.onChange} className="form-control" autoFocus />
                                            </div>   
                                        </div>                                        
                                    }
                                <div className="row">
                                    { isshowing_org_credit &&                
                                        <div className="col-sm-6">
                                            <label htmlFor="org_credit">Organization Credit</label>
                                            <input type="text" value={this.state.credits} onChange={this.isSubscriberChange} className="form-control" name="credits" id="org_credit" />
                                        </div>                
                                    }
                                    
                                    <div className="col-sm-6">
                                        <label htmlFor="linkedin">Box Folder ID</label>
                                        <input type="text" name="boxfolderid" value={this.state.boxfolderid}  onChange={this.onChange} className="form-control" autoFocus />
                                    </div>   
                                    <div className={isshowing_org_credit?'col-sm-12':'col-sm-6'}>
                                        <label htmlFor="linkedin">Freshbook Profile ID</label>
                                        <input type="text" name="freshbookprofileid" value={this.state.freshbookprofileid}  onChange={this.onChange} className="form-control" autoFocus />
                                    </div> 
                                </div>
                                <div className="form-group">
                                    <input type="submit" onClick={this.submit}
                                        value="Update Organization" 
                                        className="UpdateBtn btn btn-primary"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}