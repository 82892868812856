import React, { Component } from "react";
import ProjectList from '../reportdata/ProjectList'
import TimeLogs from '../reportdata/TimeLogs'
import TaskReports from '../reportdata/TaskReports'
import Loader from 'react-loader-spinner'
import {GetData} from '../../services/PostData';

class Report extends Component {
    constructor(props) {
        let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        super(props);
        this.state = {
           // projectfiltertype:'2',
            loggedInUserId:userdataforid.ID,
            data:[]
        }
    }
    handleProjectFilterChange(e) {
        this.setState({ projectfiltertype: e.target.value });
        let apiurl =e.target.value==1 ? 'activeprojectwithouttask' : 'activeprojectwithoutreviewtask'
        if(e.target.value==1 || e.target.value==5){
            this.setState({loading:true, });    
        
            GetData(apiurl).then((result) => {
                console.log('feteched dattaa===',result);
                if (result.statusCode === 200) {
                    this.setState({
                        data: result.data,
                        loading:false,
                    }); 
                }
            });
        }
       
	}
    render() {    
        //let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
       // userdataforid = 
    //console.log('userdataforid=',this.state.userdataforid);                   
            return (
			 <div className="container-fluid TaskListPage"  ref="selfdiv">
                {
                    this.state.loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                }
                <div className="panel panel-primary ProfilePanel">                    
                    <div className="WrapBarTop panel-heading">
                        <div className="row MoPrologoLeft">
                            <div className="col-12 ProAssetsHead">
                                <h4 className="PageTopTitle">Reports</h4>
                                <select name="projectfiltertype" className="form-control" value={this.state.projectfiltertype} onChange={this.handleProjectFilterChange.bind(this)} >
									<option value=" ">Select Project </option>
									<option value="1">Active Projects without Tasks</option>
                                    <option value="2">Active Projects without Activity</option>
                                    <option value="3">Task Report</option>
                                    <option value="4">Production / Post Production Time Log</option>
                                    {((this.state.loggedInUserId==26 || this.state.loggedInUserId==5699) &&
                                        <option value="5">Active Projects without Postproduction Task</option>
                                    )

                                    }
								</select>
                            </div>                         
                        </div>                        
                    </div>
                    <div className="panel-body">    
                        {                             
                           ((this.state.projectfiltertype==='1' || this.state.projectfiltertype==='5') && this.state.data.length>0 &&
                                <div className="project-list">  
                                    <ProjectList userid={this.state.loggedInUserId} data={this.state.data} /> 
                                </div>
                            )                       
                        }
                        {                             
                           (this.state.projectfiltertype==='3' &&
                                <div className="project-list">  
                                    <TaskReports userid={this.state.loggedInUserId} /> 
                                </div>
                            )                       
                        }
                        {                             
                           (this.state.projectfiltertype==='4' &&
                                <div className="project-list">  
                                    <TimeLogs userid={this.state.loggedInUserId} /> 
                                </div>
                            )                       
                        }                
                     </div>
                </div>
            </div>
		);
    }
}
export default Report;
