import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
class CreateTask extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			assignmentsubcayegory:'',
			tasks: [{ userid: "", teammember: "", assignment: "", tduedate: "" }],
		};
	}




	handleTeamMemberChange(i, e) {
		var index = e.target.selectedIndex;
		var optionElement = e.target.childNodes[index]
		var option = optionElement.getAttribute('data-id');

		let tasks = [...this.state.tasks];
		tasks[i] = { ...tasks[i], userid: option };
		tasks[i] = { ...tasks[i], teammember: e.target.value };
		this.setState({ tasks });
	}
	onTaskChange(i, e)
	{
		let tasks = [...this.state.tasks];
		console.log('e.target.value=',e.target.value);
		tasks[i] = { ...tasks[i], assignment: e.target.value };
		this.setState({ tasks,assignmentsubcayegory:e.target.value });
	}
	onVideoShootChange(i,e)
	{
		let tasks = [...this.state.tasks];
		console.log('e.target.value=',e.target.value);
		tasks[i] = { ...tasks[i], videoshoottype: e.target.value };
		this.setState({ tasks });
	}
	onPostReviewAssignment(i,e)
	{
		let tasks = [...this.state.tasks];
		console.log('e.target.value=',e.target.value);
		tasks[i] = { ...tasks[i], postreviewassignment: e.target.value };
		this.setState({ tasks });
	}
	handleTaskChange(i, e) {
		const { name, value } = e.target;
		let tasks = [...this.state.tasks];
		tasks[i] = { ...tasks[i], videoshoottype: value };
		this.setState({ tasks });
	}
	handleTaskDueDateChange(name, date, i) {
		let tasks = [...this.state.tasks];
		tasks[i] = { ...tasks[i], [name]: date };
		this.setState({ tasks });
	}
	handleDateChange(name, date, i) {
		let tasks = [...this.state.tasks];
		tasks[i] = { ...tasks[i], [name]: date };
		this.setState({ tasks });
	}

	removeTaskClick(i) {
		let tasks = [...this.state.tasks];
		tasks.splice(i, 1);
		this.setState({ tasks });
	}
	createTaskUI() {
		const CustomButton = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				<img src={require('../../../assets/images/due-date.png')} /> {value}
			</button>
		);
		const assocteammembers = [];
		const associatedteammemebers = this.props.associatedusers;
		if (associatedteammemebers) {
			for (var key in associatedteammemebers) {
				let display_name=associatedteammemebers[key].first_name+' '+ associatedteammemebers[key].last_name
				assocteammembers.push(<option data-id={associatedteammemebers[key].ID} value={display_name}>{display_name}</option>)
			}
		}
		return this.state.tasks.map((el, i) => (
			<div key={i}>
				<div className="row LP_Row">
					<div className="col-12 col-sm-12 col-md-3 col-lg-3 LeftPurpose">
						<img class="SelectIconLeft" src={require('../../../assets/images/upload-icon.png')} />
						<select name="teammember" className="form-control" onChange={this.handleTeamMemberChange.bind(this, i)} id="subscription_hour">
							<option value=" ">Select Team Member</option>
							{assocteammembers}
						</select>
					</div>
					<div className="col-12 col-sm-12 col-md-3 col-lg-3 LeftLocation">
						<select name="assignment" className="form-control" onChange={this.onTaskChange.bind(this, i)} id="assignment">
							<option value=" ">Select Assignment</option>
							<option value="Script">Script</option>
							<option value="Voice Over">Voice Over</option>
							<option value="Post Production">Post Production</option>
							<option value="Video Shoot">Video Shoot</option>	
							<option value="Review Project">Review Project</option>
							<option value="Add Request Videographer">Add Request Videographer</option>				
							<option value="Other">Other</option>	
						</select>
						{ /* <input className="LP_field" type="text" onChange={this.handleTaskChange.bind(this, i)} name="assignment" /> */}
					</div>

					{
						( this.state.assignmentsubcayegory =='Video Shoot' &&

						<div className="col-12 col-sm-12 col-md-3 col-lg-3 LeftLocation">
							<select name="videoshoottype" className="form-control" onChange={this.onVideoShootChange.bind(this, i)} id="assignment">
								<option value=" ">Select Video Shoot</option>
								<option value="Conference">Conference</option>
								<option value="Interview(s)">Interview(s)</option>
								<option value="Zoom">Post Zoom</option>															
							</select>						
						</div>
						)
					}
					{
						( this.state.assignmentsubcayegory =='Review Project' &&

						<div className="col-12 col-sm-12 col-md-3 col-lg-3 LeftLocation">
							<select name="postreviewassignment" className="form-control" onChange={this.onPostReviewAssignment.bind(this, i)} id="assignment">
								<option value=" ">Select Assignment</option>
								<option value="Script">Script</option>
								<option value="Voice Over">Voice Over</option>
								<option value="Post Production">Post Production</option>
								<option value="Video Shoot">Video Shoot</option>															
							</select>						
						</div>
						)
					}

					{
						( this.state.assignmentsubcayegory =='Other' &&

						<div className="col-12 col-sm-12 col-md-3 col-lg-3 LeftLocation">
							<input className="LP_field" type="text" onChange={this.handleTaskChange.bind(this, i)} name="assignment" placeholder="Assignment" />						
						</div>
						)
					}

					<div className="col-12 col-sm-12 col-md-3 col-lg-3 LeftLocation align-left">
						<h4>Due Date
							<DatePicker className="form-control" selected={this.state.tasks[i].tduedate}
								name="tduedate"
								minDate={moment().toDate()}
								onChange={date => this.handleTaskDueDateChange('tduedate', date, i)}
								//onChange={this.handleTaskDueDateChange.bind(this, i)}
								dateFormat="d MMMM"
								customInput={<CustomButton />} />
						</h4>
					</div>
				</div>
				{i != 0 &&
					<div className="CancelRepeatField">
						<div className="CancelInRepField">
							<img src={require('../../../assets/images/remove_icon.png')} />
							<input type='button' className="removeitem" value='remove' onClick={this.removeTaskClick.bind(this, i)} />
						</div>
					</div>
				}
				{this.state.assignmentsubcayegory =='Video Shoot' &&
					<div className="row LP_Row">
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 LeftLocation align-left">
							<p>Start Date/Time<br/>
								<DatePicker
									name="startDateTime"
									minDate={moment().toDate()}
									onChange={date => this.handleDateChange('startDateTime', date, i)}
									selected={this.state.tasks[i].startDateTime}
							      	showTimeSelect
							      	dateFormat="MMMM d, yyyy h:mm aa"
							    />
							</p>
						</div>
						<div className="col-12 col-sm-12 col-md-6 col-lg-6 LeftLocation align-left">
							<p>End Date/Time<br/>
								<DatePicker
									name="endDateTime"
									minDate={moment().toDate()}
									onChange={date => this.handleDateChange('endDateTime', date, i)}
									selected={this.state.tasks[i].endDateTime}
							      	showTimeSelect
							      	dateFormat="MMMM d, yyyy h:mm aa"
							    />
							</p>
						</div>
					</div>
				}

			</div>
		))
	}
	saveCreateTask(event) {
		if (this.validateCreateTaskForm()) {
			event.preventDefault();
			this.setState({ saveBoardDataloader: true });
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			console.log('userdata==',userdata);
			const data = {
				orgid: this.props.orgid,
				projectid: this.props.prjid,
				tasks: this.state.tasks,
				type: 'task',
				userid: userdata.user.ID,
			};
			let session_key = userdata.token;
			PostData('createtask', data).then((result) => {
				if (result.statuscode === 200) {
					this.setState({ taskboardsuccessmessage: result.message, saveBoardDataloader: false, data: [], values: [], tasks: [] });
					setTimeout(() => {
						this.setState({  taskboardsuccessmessage: '' });
						this.props.loadProjectBoard();
					}, 3000);
				}
			});
		}
	}
	validateCreateTaskForm() {
		let tasks = this.state.tasks;
		let errors = {};
		let formIsValid = true;
		if (tasks.length > 0) {
			tasks.forEach(
				function iterator(item) {
					if (!item.teammember || !item.assignment || !item.tduedate) {
						errors["createtaskboard"] = "*All fields are manadatory";
						formIsValid = false;
					}
				}
			);
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
    render() {
        return (
        	<div className="ProducerSaveDate AddCreatTask">
				<img className="SD_img_icon" src={'../../../../../profile-image/'+this.props.avatar} />
				{
					this.state.saveBoardDataloader ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<h3 class="AddTitleDrop">Create Task</h3>
				<div className="clearfix"></div>
				<div className="succes-msg">{this.state.taskboardsuccessmessage}</div>
				<div className="errorMsg">{this.state.errors.createtaskboard}</div>
				{this.createTaskUI()}
				<div className="clearfix"></div>
				<div className="BtnAddRemove">
					<div className="relativebox">
					</div>
					<button type="button" onClick={(e) => this.saveCreateTask(e)} className="UpdateBtn btn btn-primary">Save</button>
				</div>
				<hr />
			</div>	
		);
    }
}
export default CreateTask;