import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {PostData} from '../../services/PostData';
import ReactDOM from 'react-dom';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class AddProject extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(){
		console.log("checkkkkkkkkkk");
		super();
		this.state = {
			fields: {},
			errors: {},
			project_desc: EditorState.createEmpty(),
			project_name:'',
			duedate:'',
			org_id:''
		};
		this.submit = this.submit.bind(this);
		this.onChange = this.onChange.bind(this);
	};
	onEditorStateChange: Function = (project_desc) => {
		const rawState = JSON.stringify(convertToRaw(project_desc.getCurrentContent()));
	    this.setState({
	      project_desc:rawState
	    });
	};
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	handleChange = date => {
	    this.setState({
	      duedate: date
	    });
	};
	validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
      if (!fields["project_name"]) {
        formIsValid = false;
        errors["project_name"] = "*Please enter your Project Name";
      }
      if (!fields["duedate"]) {
        formIsValid = false;
        errors["duedate"] = "*Please select Due Date";
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
    }
    componentDidMount() {
		this.setState({org_id:this.props.match.params.orgid});
	}
	submit() {	
		if (this.validateForm())
		{
			PostData('addproject',this.state).then((result) => {				
				if(result.statuscode===200)
				{
					this.props.history.push("/org/"+this.props.match.params.orgid+"/project/list");
				}				
			});
		}
 	}
    render() {
    	const {   editorState   } = this.state; 	
    	const styles = {
		  editor: {
			    border: '1px solid gray',
			    minHeight: '6em'
			  }
			};
		const ExampleCustomInput = ({ value, onClick }) => (
		    <button className="example-custom-input" onClick={onClick}>
		    	<img src={require('../../assets/images/due-date.png')}/> {value}
		    </button>
		);
        return (
        	<div className="container-fluid AddProjectPage"  ref="selfdiv">
				<div className="panel panel-primary">
					<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-md-7 col-lg-7">
									<h4 class="PageTopTitle">Create Projects</h4>
								</div>
								<div className="col-md-3 col-lg-3"> </div>
								<div className="col-md-2 col-lg-2"> </div>
								<div  className="col-md-2 col-lg-2"> </div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid">
						<div className="row FormDesign">
							<div className="col-9">
								<label for="projectname">Project Title</label>
								<input type="text" name="project_name" placeholder="" onChange={this.onChange} className="PB_Title form-control" autoFocus />              
								<div className="errorMsg">{this.state.errors.project_name}</div>			            
								<hr/>
								<div className="CreateProjectChatBox">
									<Editor project_desc={this.state.project_desc} wrapperClassName="demo-wrapper" editorClassName="editer-content" onEditorStateChange={this.onEditorStateChange} />	            
								</div>
								<div className="clearfix"></div>
								<button type="submit" onClick={this.submit} className="UpdateBtn btn btn-primary">Create</button> 
							</div>
							<div className="col-3">
								<div className="DueDate"> 
									<DatePicker dateFormat="d MMMM" className="form-control" selected={this.state.duedate} onChange={this.handleChange}customInput={<ExampleCustomInput />}/> 
									<div className="errorMsg">{this.state.errors.duedate}</div>
								</div>
							</div>                  
						</div>
		        	</div>	
		        </div>					
			</div>	
		);
    }
}
export default AddProject;
