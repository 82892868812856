




import React, { Component } from 'react';  
import { Route } from 'react-router-dom';  
//import Header from '../components/Header'
//import Footer from '../components/Footer'
import Header from '../ComponentsLogin/Header'
import Footer from '../ComponentsLogin/Footer'
import { Link, Redirect } from 'react-router-dom';
const LoginLayout = ({ children }) => (    
    <div>
        <Header />
        {children}
        <Footer />
    </div>                     
  );  
  
  const LoginLayoutRouteStg = ({component: Component, ...rest}) => {  
    return (  
      <Route {...rest} render={matchProps => (  
        <LoginLayout>  
            <Component {...matchProps} />  
        </LoginLayout>  
      )} />  
    )  
  };  
  
export default LoginLayoutRouteStg;






