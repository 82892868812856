import React, { Component } from "react";
import {PostData,GetData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { apiConfig } from "../../../config/api"
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
class AddAsset extends Component {

    constructor(props){
		super(props);
		this.state = {
			boxfolderid:'',
			fields: {},
			errors: {},				
			setAddAssetShow:this.props.handleAddAssetShow,		
			folderlist:[],
			originalfolderlist:[],
			rootfolderid:'',
			fileid:'',
			foldersdroplist:[],
			originalfoldersdroplist:[],
			foldername:"Please Select Folder"
		};
		console.log('calledddd==',this.props.handleAddAssetShow);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isAddAsset !== this.props.isAddAsset) {
		 	this.setState({setAddAssetShow:this.props.isAddAsset})		
		}
	}		
	handleAddAssetClose(e) {
		this.setState({ setAddAssetShow: false });
	}	
	saveAsset(e) {
		console.log('this.state.assetfile=====',this.state.assetfile);
		let loadermessage='Uploading';
		if (this.validateAddAssetForm()) {
			let mimetype= this.state.assetfile.type;
            let checkvideo = mimetype.split('/');
            if(mimetype!='video/mp4' && checkvideo[0]=='video')
            {
                loadermessage='Uploading and converting file in mp4'
			}
			
			this.setState({ Inloading: true,assetuploadmessage:loadermessage });			
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			const data = new FormData();
			data.append('org_id', this.props.orgid)
			data.append('projectid', this.props.prjid)
			data.append('folderid', this.state.fileid)
			data.append('boxfile', this.state.assetfile)
			//.append('scripttime', finaltime)
			//data.append('type', type)
			data.append('userid', userdata.user.ID)
			let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL + 'assetuploadprojectboard', {
				method: 'POST',
				body: data,
				headers: {
					"session-key": session_key,
				}
			})
			.then((response) => response.json())
			.then((res) => {
				if (res.statusCode === 200) {
					this.setState({ assetsuccessmsg: res.message, assetfile: '',fileid:'' , data: [],assetuploadmessage:'' });
					setTimeout(() => {
						this.setState({ Inloading:false,assetsuccessmsg: '',setAddAssetShow: false })
						this.props.loadProjectBoard();
					}, 3000);
					const file = document.querySelector('.assetfile');
					file.value = '';
				}
			});
		}
	}
	validateAddAssetForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["fileid"]) {
			formIsValid = false;
			errors["fileid"] = "*Please select Folder";
		}
		if (!fields["assetfile"]) {
			formIsValid = false;
			errors["assetfile"] = "*Please upload File";
		}
		
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	
	componentDidMount() {
        this.setState({loading:true});
        Promise.all([this.getOrgData()])
			.then(([orgdata]) => {						
			if(orgdata.status==='success')
            {
                if(orgdata.data.boxfolderid)
                {
				   this.setState({boxfolderid:orgdata.data.boxfolderid,rootfolderid:orgdata.data.boxfolderid});
				   this.getFolderListByFolderId(orgdata.data.boxfolderid);
				   this.getCompleteFolderList(orgdata.data.boxfolderid);
                }
			}
			
		});
	}	
	getOrgData(){
		return GetData('organization/'+this.props.orgid).then((result) => {
			return result;
		});
	}
	getFolderListById(folderid,foldername){
		console.log('getFolderListById====',folderid);
		this.setState({
			boxfolderid:folderid,
			isInner:true,
			fileid:folderid,
			foldername:foldername
        });
		this.getFolderListByFolderId(folderid);
	}
	getFolderListByFolderId(folderid)
    {
        this.setState({loading:true});
        let data = {
            'boxfolderid': folderid,
        }
        PostData('boxassets', data).then((result) => {
			if(result.statusCode===200)
			{
				console.log('responseJson==',result);
				this.setState({
					folderlist: result.data,
					originalfolderlist: result.data,
					loading:false
				});
			}
		});
	}
	getCompleteFolderList(folderid)
	{
		this.setState({loading:true});
        GetData('getfolderlist/'+folderid).then((result) => {
			if(result.statusCode===200)
			{
				console.log('responseJson==',result);
				this.setState({
					foldersdroplist: result.data,
					originalfoldersdroplist: result.data,
					loading:false
				});
			}
		});
	}
	handleChangeOnFolderSelection(e){
		this.setState({
			fileid:e.target.value
		})
	}
	backToRootFolder(folderid){
        this.setState({
			boxfolderid:folderid,
			isInner:false,
        });
        this.getFolderListByFolderId(folderid);
	}
	onAssetfileChangeHandler = event => {
		//console.log('event.target=====',event.target);
		this.setState({
			'assetfile': event.target.files[0],
			loaded: 0,
		})
	}
    render() {
		console.log('called every time');
		let folderslist = this.state.folderlist.map((item, i) => {
		//const folderslist = this.state.folderlist.map(function(item){
			if(item.filetype==='folder')
			return <li className="folderlistitem">
					<a className="forcusror" onClick={this.getFolderListById.bind(this,item.fileid,item.filename)}>{item.filename}</a>
					<input value={item.fileid} name="fileid" type="radio" onChange={this.handleChangeOnFolderSelection.bind(this)}
					/>
				</li>;
		});
		let foldersdroplist = this.state.foldersdroplist.map((item, i) => {
			if(item.filetype==='folder')
			return <li>
				<a onClick={this.getFolderListById.bind(this,item.fileid,item.filename)} className="dropdown-item">{item.filename}</a>	
				<ul className="childlist1">	
					{item.child.map((item1, i) => {
							return (
								<li>
									<a onClick={this.getFolderListById.bind(this,item1.fileid,item1.filename)} className="dropdown-item">-{item1.filename}</a>	
									<ul className="childlist2">
									{item1.child.map((item2, i) => {
											return (
												<li>
													<a onClick={this.getFolderListById.bind(this,item2.fileid,item2.filename)} className="dropdown-item">-{item2.filename}</a>	
													<ul className="childlist3">
													{item2.child.map((item3, i) => {
															return (
																<li value={item3.fileid}>
																	<a onClick={this.getFolderListById.bind(this,item3.fileid,item3.filename)} className="dropdown-item">-{item3.filename}</a>	
																	<ul className="childlist4">
																	{item3.child.map((item4, i) => {
																			return (
																				<li value={item4.fileid}>
																					<a onClick={this.getFolderListById.bind(this,item4.fileid,item4.filename)} className="dropdown-item">-{item4.filename}</a>	
																					<ul className="childlist5">
																					{item4.child.map((item5, i) => {
																							return (
																								<li value={item5.fileid}>
																									<a onClick={this.getFolderListById.bind(this,item5.fileid,item5.filename)} className="dropdown-item">-{item5.filename}</a>
																								</li>
																							)
																						})						
																					}
																					</ul>
																				</li>
																			)
																		})										
																	}
																	</ul>
																</li>
															)
														})
														
													}
													</ul>
												</li>
											)
										})										
									}
									</ul>
								</li>
							)
						})						
					}
				</ul>									
			</li>;				
		});
        return (
        	<Modal show={this.state.setAddAssetShow}>	
				{
					this.state.Inloading ?
						<div className="loadercenter">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
							<div className="loadermessageforpb">{this.state.assetuploadmessage}</div>
						</div>
					: null
				}			
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Add Asset</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime addprojectasset">
					<div className="successmsg">{this.state.assetsuccessmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row formarginbtm">
						<div className="col-12">
							<div className="errormsg">{this.state.errors.assetfile}</div>
							<input type="file" name="assetfile" onChange={this.onAssetfileChangeHandler.bind(this)} className="form-control assetfile" autoFocus />
						</div>
					</div><br/>
					<div className="row">
						<div className="col-12 RightTopMenu text-align-left">
							<div className="errormsg">{this.state.errors.fileid}</div>
							{/* {(this.state.isInner &&
								<div>
									<a className="forcusror rootfolder" onClick={this.backToRootFolder.bind(this,this.state.rootfolderid)} >Back To Root Folder</a>								
								</div>
							)

							} */}
							{/* <ul className="folderlist">
								{folderslist}
							</ul> */}							
							<Dropdown>
									<Dropdown.Toggle variant="success1" id="dropdown-basic">
										{this.state.foldername}
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<ul className="rootlist">
											{foldersdroplist}
										</ul>
									</Dropdown.Menu>
							</Dropdown>
							
							
						</div>
					</div>
					
					
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddAssetClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.saveAsset(e)}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default AddAsset;