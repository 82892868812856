import React, { Component } from "react";
import { apiConfig } from "../../../config/api"
import Loader from 'react-loader-spinner'
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import io from "socket.io-client";
import SocketIOFileUpload from "socketio-file-upload"; 
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML, decorator, convertRawToConvertable, Entity, Modifier  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class SaveTheScript extends Component {
    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},		
			hour: '',
			minut: '',
			scriptfile: '',
			scripttitle: '',
			scriptsuccessmsg: '',
			showdialogbox:false,
			chatattchedfilename:[],
			chatattachament: '',
			chatattchedfilebasename:[],
			editorState: EditorState.createEmpty()
		};
		let url = apiConfig.WEB_SERVICE_URL;
		url = url.replace('http://', '');
		url = url.replace('/api/', '');
		this.socket = io(url);		
		let currentstate=this;
		this.socket.on('SAVE_ATTACHMENT', function (data) {
			console.log('check dataaaaaaaaaaa======',currentstate.state.chatuploadincurrentpage,data.bytesLoaded,'attachedfilesize==',currentstate.state.attachedfilesize);
			if(currentstate.state.chatuploadincurrentpage && data.bytesLoaded===currentstate.state.attachedfilesize)
			{
				currentstate.setState({
					//chatattchedfilebasename:data.base,
					//chatattchedfilename:data.name,
				});
				currentstate.setState({ chatattchedfilename: [...currentstate.state.chatattchedfilename, data.name],chatattchedfilebasename: [...currentstate.state.chatattchedfilebasename, data.base] })
			}
			//console.log('data.base',data.base);
			//console.log('chatattchedfilename',currentstate.state.chatattchedfilename);
			//console.log('chatattchedfilebasename',currentstate.state.chatattchedfilebasename);
		});
		
		this.sendMessage = ev => {
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
			ev.preventDefault();
			const datta = this.state.editorState;
			console.log('datta==',datta);
			if(!datta)
			{
				return false;	
			}
			const blocks = convertToRaw(datta.getCurrentContent()).blocks;
			let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
			value=value.trim()	
			const editorJSON = JSON.stringify(convertToRaw(datta.getCurrentContent()));
			
				let usrdata = JSON.parse(editorJSON);
				console.log('usrdataom footer chat==',usrdata);
				const taggedusr = usrdata.blocks[0].text
				const entityMap = usrdata.entityMap
				const objlength = Object.keys(entityMap).length;
				console.log('entityMap==',entityMap);

							
				//console.log('taggedusr==',taggedusr);
				const msgsplit = taggedusr.split(' ');
				//console.log('msgsplit==',msgsplit);
				msgsplit.forEach((element)=>{
					if(element == '@All'){
						
						console.log('editorJSON=====', '@All');
					}
				})

            console.log('value==',value);
			if(value && value!=" ")
			{



				//console.log('in iffffffff=====',value,"data");
				let cAttchedfilename=this.state.chatattchedfilename.toString();
				let cAttchedfilebasename=this.state.chatattchedfilebasename.toString();
				this.socket.emit('SEND_MESSAGE', {
					userdata: userdata,
					project_id: this.props.prjid,
					org_id:this.props.orgid,
					message: editorJSON,
					chatattachament: this.state.chatattachament,
					chatattchedfilename:cAttchedfilename,
					chatattchedfilebasename:cAttchedfilebasename,
					chatid:this.state.editedboardid,
					chatallusers:this.props.allusers
				})
				//this.scrollToBottom();
				const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
				this.setState({ editorState });
				this.setState({ chatattchedfilename:'',chatattchedfilebasename:'',editedboardid:'',iseditedchat:'',removechatvalidation:false });
				this.handleConfirmDialogBoxClose();
			}
		}

	
	}
	componentDidUpdate(prevProps) {
		if (prevProps.ischatedit !== this.props.ischatedit && this.props.editedboardid) {
			this.setState({editorState:this.props.editorState,iseditedchat:true,editedboardid:this.props.editedboardid})		
		}
	}
	handleEchatMessageChange = (editorState) => {
		this.setState({
			editorState,
		});
	};
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onScriptfileChangeHandler = event => {
		this.setState({
			'scriptfile': event.target.files[0],
			loaded: 0,
		})
	}
	onHourChange = hour => this.setState({ hour })
	onMinutChange = minut => this.setState({ minut })
	handleConfirmDialogBoxClose(e) {
		this.setState({
			showdialogbox: false,			
		});
		this.setState({
			editorState:EditorState.createEmpty(),
			//editedboardid:'',
			//iseditedchat:false,
		});
		let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		if (userdataforcheck.role && userdataforcheck.role=='administrator') {
			var hour = this.state.hour.format('HH');
			var minut = this.state.minut.format('mm');
			this.uploadScriptData('uploadscript', 'script', hour, minut);
			
		}
		else {
			var hour = '';
			var minut = '';
			this.uploadScriptData('uploadscript', 'script', hour, minut);			
		}
	}
	saveScriptData(e) {
		console.log("calledddddddddd in script  ");		
		let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		if (userdataforcheck.role && userdataforcheck.role=='administrator') {
			if (this.validateScriptDataFormAdmin()) {
				var hour = this.state.hour.format('HH');
				var minut = this.state.minut.format('mm');
				//this.uploadScriptData('uploadscript', 'script', hour, minut);
				this.setState({
					showdialogbox:true,			
				});
			}
		}
		else {
			if (this.validateScriptDataFormSubs()) {
				var hour = '';
				var minut = '';
				//this.uploadScriptData('uploadscript', 'script', hour, minut);
				this.setState({
					showdialogbox:true,			
				});
			}
		}
		
	}
	uploadScriptData(action, type, hour, minut) {
		const finaltime = hour + ':' + minut;
		this.setState({ saveBoardDataloader: true });
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		const data = new FormData();
		data.append('org_id', this.props.orgid)
		data.append('projectid', this.props.prjid)
		data.append('title', this.state.scripttitle)
		data.append('scriptfile', this.state.scriptfile)
		data.append('scripttime', finaltime)
		data.append('type', type)
		data.append('userid', userdata.user.ID)
		let session_key = userdata.token;
		fetch(apiConfig.WEB_SERVICE_URL + action, {
			method: 'POST',
			body: data,
			headers: {
				"session-key": session_key,
			}
		})
		.then((response) => response.json())
		.then((res) => {
			console.log('res.total_time before ifff===',res.totaltime);
			if (res.statuscode === 200) {
				this.setState({ scriptsuccessmsg: res.message, scripttitle: '', scriptfile: '', scripttime: '', saveBoardDataloader: false, data: [] });
				setTimeout(() => {
					this.setState({ scriptsuccessmsg: '' })
					this.props.loadProjectBoard(res.totaltime);
				}, 3000);
				const file = document.querySelector('.scriptfile');
				file.value = '';
			}
		});
	}
	validateScriptDataFormAdmin() {
		//const hour=this.state.hour;
		//const minut=this.state.minut;
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["scripttitle"]) {
			formIsValid = false;
			errors["scripttitle"] = "*Please enter script title";
		}
		if (!fields["scriptfile"]) {
			formIsValid = false;
			errors["scriptfile"] = "*Please upload script";
		}

		if (!fields["hour"]) {
			formIsValid = false;
			errors["hour"] = "*Please select hour";
		}
		if (!fields["minut"]) {
			formIsValid = false;
			errors["minut"] = "*Please select minut";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	validateScriptDataFormSubs() {
		//const hour=this.state.hour;
		//const minut=this.state.minut;
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["scripttitle"]) {
			formIsValid = false;
			errors["scripttitle"] = "*Please enter script title";
		}
		if (!fields["scriptfile"]) {
			formIsValid = false;
			errors["scriptfile"] = "*Please upload script";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
    render() {
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		const ExampleCustomInput = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				<img src={require('../../../assets/images/due-date.png')} /> {value}
			</button>
		);
        return (
        	<div className="ProducerSaveDate AddUploadScript">
				<img className="SD_img_icon" src={'../../../../../profile-image/'+this.props.avatar} />
				{
					this.state.saveBoardDataloader ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<h3 class="AddTitleDrop">Upload Script</h3>
				<div className="clearfix"></div>
				<div className="succes-msg">{this.state.scriptsuccessmsg}</div>
				<div className="row LP_Row">
					<div className="col-md-12 LeftLocation">
						<input className="LP_field" type="text" value={this.state.scripttitle} onChange={this.onChange.bind(this)} name="scripttitle" placeholder="Upload Title" />
						<div className="errorMsg">{this.state.errors.scripttitle}</div>
					</div>
					<div className="clearfix"></div>
					<div className="col-12 col-sm-4 col-md-3 col-lg-3">
						<div className="dropdown">
							<input type="file" name="scriptfile" onChange={this.onScriptfileChangeHandler.bind(this)} className="form-control scriptfile" autoFocus />
							<div className="errorMsg">{this.state.errors.scriptfile}</div>
						</div>
					</div>
					{userdata.role && userdata.role=='administrator' &&
						<div className="col-12 col-sm-4 col-md-5 col-lg-3 AddTimeBoxes">
							Add time:
							<TimePicker name="hour" value={this.state.hour} onChange={this.onHourChange} showMinute={false} showSecond={false} />
							<div className="errorMsg">{this.state.errors.hour}</div>
							<TimePicker name="minut" minuteStep={15} value={this.state.minut} onChange={this.onMinutChange} showHour={false} showSecond={false} />
							<div className="errorMsg">{this.state.errors.minut}</div>
						</div>
					}
					<div className="col-12 col-sm-4 col-md-3 col-lg-3">
						<div className="text-left">
							<button type="button" onClick={(e) => this.saveScriptData(e)} className="UpdateBtn btn btn-primary">Save</button>
						</div>
					</div>
				</div>
				<br />
				<hr />
				<Modal show={this.state.showdialogbox}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<Editor onFocus={() => this.setState({ hasFocus: true })} onBlur={() => this.setState({ hasFocus: false })} editorState={this.state.editorState} wrapperClassName="demo-wrapper" editorClassName="editer-content" onEditorStateChange={(e) => this.handleEchatMessageChange(e)} placeholder='Ask a question or post an update..." with "@mention someone to notify them.'
									toolbar={{
										options: ['inline', 'list', 'emoji', 'link'],
									}}
									mention={{
										separator: ' ',
										trigger: '@',
										suggestions: this.props.associateduserforhastag,
									}}
									hashtag={{}}
								/>									
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleConfirmDialogBoxClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={this.sendMessage.bind(this)}>
							Ok
						</Button>
						<Button variant="primary" onClick={this.handleConfirmDialogBoxClose.bind(this)}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>
			</div>	
		);
    }
}
export default SaveTheScript;