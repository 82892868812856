import React, { Component } from "react";
import {GetData,PostData} from '../../services/PostData';
import { apiConfig } from "../../config/api"
import Loader from 'react-loader-spinner'
import debounce from "lodash.debounce";
import { Button, Modal } from 'react-bootstrap';
import { forEach } from "lodash";
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class Portfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetList: [],
            originalAssetList: [],
            Inloading: false,
            selectedFile:[],
            selectedvideocat:[],     
            caterror:'',
            per: 6,
            page: 0,
            isData: true, 
            isreadytosend: true,  
            data:[],
            searchtext:'',
            projectlist:[],
            showAddToProject:false,
            boxfolderid:'113803491314',
            searchvideocat:'',
            selectedPositionId:'',
            searchText:'',  
            isallorgvideo:false,  
            categories: [
		        { title: 'Conferences & Events', id: 0 },
		        { title: 'Membership & Benefits', id: 1 },
		        { title: 'Advocacy & Public Affairs', id: 2 },
				{ title: 'Education', id: 3 },
				{ title: 'Something Else', id: 4 }
		    ],
			subcategories: [
				{ title: 'Promotional Video', id: 0, category: { title: 'Conferences & Events' } },
				{ title: 'Walk-in Video', id: 1, category: { title: 'Conferences & Events' } },
				{ title: 'Kickoff Video', id: 2, category: { title: 'Conferences & Events' } },
				{ title: 'Awards / Recognition Video', id: 3, category: { title: 'Conferences & Events' } },
				{ title: 'Wrap up / Highlight Video', id: 4, category: { title: 'Conferences & Events' } },
				{ title: 'Something Else', id: 5, category: { title: 'Conferences & Events' } },

				{ title: 'Industry Promotional Video', id: 6, category: { title: 'Membership & Benefits' } },
				{ title: 'Membership Benefits Video', id: 7, category: { title: 'Membership & Benefits' } },
				{ title: 'Membership Testimonial Video', id: 8, category: { title: 'Membership & Benefits' } },
				{ title: 'Year in Review Video', id: 9, category: { title: 'Membership & Benefits' } },
                { title: 'Snapshot / Update Video', id: 11, category: { title: 'Membership & Benefits' } },
				{ title: 'Something Else', id: 10, category: { title: 'Membership & Benefits' } },

				{ title: 'Issue Explainer Video', id: 11, category: { title: 'Advocacy & Public Affairs' } },
				{ title: 'Capitol Day Promotional Video', id: 12, category: { title: 'Advocacy & Public Affairs' } },
				{ title: 'Press Conference', id: 13, category: { title: 'Advocacy & Public Affairs' } },
				{ title: 'Something Else', id: 14, category: { title: 'Advocacy & Public Affairs' } },

				{ title: 'In Person Meeting', id: 15, category: { title: 'Education' } },
				{ title: 'Webinar or Prerecorded', id: 16, category: { title: 'Education' } },
				{ title: 'One on One Training', id: 17, category: { title: 'Education' } },
                { title: 'Promotional Video', id: 17, category: { title: 'Education' } },
				{ title: 'Something Else', id: 18, category: { title: 'Education' } },		
			],
            isadvanceserach:false,
            selctedCategory:'',
            selctedSubCategory:'',
            selectedEmo: [],
			selectedAsset: [],
			selectedVideo: [],
            selectedUEmo: [],
			selectedUAsset: [],
			selectedUVideo: [],
            editedCategory:'',
            editedSubCategory:'',
            editedEmo: [],
			editedAsset: [],
			editedVideo: [],
            showEditProject:false,
            selectedprojectid:'',
            editedproject:'',
            ucategory:'',
            usubcategory:'',
            org_nick_name:'',
            sharestatus:false,
            portsuccessmsg:''
        };
        this.onChange = this.onChange.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.onSelect=this.onSelect.bind(this);
        this.onRemove=this.onRemove.bind(this);
    }
    addToProject(filename,e){
        //console.log('filename,e==',filename,e);
        this.setState({
            filename:filename,            
        })
        this.setState({ showAddToProject: true });
    }
    editProject(selectedprojectid,title,filename,e){
        //console.log('selectedprojectid',selectedprojectid,e);
        let pdata = this.state.data;
        let editedproject = pdata.filter((project)=>selectedprojectid===project._id);
        //console.log('editprojeditedprojectect',editedproject[0]);
        let editorState = '';

        if (this.isJson(editedproject[0].description)) {
            const contentState = convertFromRaw(JSON.parse(editedproject[0].description));                          
            editorState = EditorState.createWithContent(contentState);
        }
        else {
            if(editedproject[0].description==='[object Object]')
            {					
                editorState = EditorState.createEmpty();
            }
            else
            {
                const sampleMarkup = '<p>' + editedproject[0].description+ '</p>';
                const blocksFromHTML = convertFromHTML(sampleMarkup);
                const contentState = ContentState.createFromBlockArray(
                    blocksFromHTML.contentBlocks,
                    blocksFromHTML.entityMap
                );
                editorState = EditorState.createWithContent(contentState);
            }
        }

        
        this.setState({
            projectEditorState: editorState,
            selectedprojectid:selectedprojectid,  
            editedproject: editedproject[0],
            editedCategory: editedproject[0].selctedCategory,
            editedSubCategory: editedproject[0].selctedSubCategory,
            editedAsset: editedproject[0].selectedAsset,
            editedVideo: editedproject[0].selectedVideo,
            editedEmo: editedproject[0].selectedEmo,   
            editedfilename: filename,    
            title: title,   
        })
        this.setState({ showEditProject: true });
    }
    isJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}
    onEditorStateChange = (projectEditorState) => {		
		//const rawState = JSON.stringify(convertToRaw(projectEditorState.getCurrentContent()));
	    this.setState({
			projectEditorState:projectEditorState
	    });
	};
    handleDropDownChange(e){
        this.setState({[e.target.name]:e.target.value});
    }
    getPosition(id,e)
    {
        //console.log('id==',id);
        GetData('getfileposition/'+id).then((result) => {
            //console.log('result==',result);  
            let pagenumber = Math.ceil(result.position/this.state.per);
            this.setState({
                selectedposition:result.position,
                searchvideocat:'',
                searchText:'',
                page:pagenumber,
                data:[],
                selectedPositionId:id
            },function(){
               // console.log('pagenumber==',pagenumber); 
               this.getListData(pagenumber) 
            })    
            
            
            //this.getListData(pagenumber)     
        });
    }
    loadPreviousData(e)
    {
        let pagenumber = this.state.page-1;
        if(pagenumber>0)
        {
            this.setState({
                page:pagenumber,
                data:[],
            },function(){
               // console.log('pagenumber==',pagenumber); 
               this.getListData(pagenumber) 
            }) 
        } 
    }
    handleAddToProjectClose(e) {
        this.setState({
            showAddToProject: false,
        });
    }
    handleEditProjectClose(e) {
        this.setState({
            showEditProject: false,
        });
    }
    addPortfolioVideoToProject(e){
        let filedetails = this.state.filedetails;
        //console.log('filedetails==',filedetails);
        if(this.state.selectedproject=='')
        {
            this.setState({
                errormsg:'Please select Project'
            })
        }
        else
        {
            this.setState({
                errormsg:''
            })
            let data = {
                'filename': this.state.filename,
                'videotype': 'Portfolio',
                'project_id': this.state.selectedproject,
            }
            //console.log('data==',data);
            PostData('addpvideotopboard', data).then((result) => {
               // console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
                }
            });
        }

    }
    updateProject(e){        
            this.setState({
                errormsg:''
            })
            let pdes=this.state.projectEditorState;
		    const rawState = JSON.stringify(convertToRaw(pdes.getCurrentContent()));
            let data = {
                'title': this.state.title,
                'project_description': rawState,
                'filename': this.state.editedfilename,
                'editedEmo': this.state.editedEmo,
                'editedAsset': this.state.editedAsset,
                'editedVideo': this.state.editedVideo,
                'ucategory': this.state.ucategory == "" ? this.state.editedCategory : this.state.ucategory,
                'usubcategory': this.state.usubcategory == "" ? this.state.editedSubCategory : this.state.usubcategory,
                'project_id': this.state.selectedprojectid,
            }
            console.log('data==',data);
            PostData('updateproject', data).then((result) => {
                //console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',showEditProject:false }), 3000);
                }
            });

    }
    searchFilter(){
        this.setState({
            page: 1, 
            isreadytosend: true,
            data:[] 
        })
       this.getVideoData(this.state.per,1,[]);
    }
    advanceSearch(){
        this.setState({
            isadvanceserach: !this.state.isadvanceserach,           
        })       
    }    
    componentDidMount() {
        this.callListData();   
        Promise.all([this.getProjectList(),this.getOrgData()])
            .then(([projectdata,orgdata]) => {
            if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data});
            }   
            if(orgdata.status=='success')   
            {
                this.setState({org_nick_name:orgdata.data.nick_name});
            }     
        });

       // GetData('allcompletedvideos/').then((result) => {
          //  console.log('result==',result);  
            // let pagenumber = Math.ceil(result.position/this.state.per);
            // this.setState({
            //     selectedposition:result.position,
            //     searchvideocat:'',
            //     searchText:'',
            //     page:pagenumber,
            //     data:[],
            //     selectedPositionId:id
            // },function(){
            //     console.log('pagenumber==',pagenumber); 
            //    this.getListData(pagenumber) 
            // })    
            
            
            //this.getListData(pagenumber)     
       // });
    }
    getProjectList(){
        //console.log('calleddd=');
        return GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    getOrgData(){
        //console.log('calleddd=');
        return GetData('organization/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    handleProjectChange(e){
        //console.log('selectedproject==',e.target.value);
        this.setState({ selectedproject: e.target.value });
    }
    callListData(){
        window.onscroll = debounce(() => {
          this.handleScroll();
        }, 100);
        //this.loadMore();

        //this.getListData(1);
        this.loadMore();
    }
    handleScroll = () => {
        var lastLi = document.querySelector(".rowlist > div:last-child");
       // console.log('lastLi==',lastLi);
        if (lastLi) {
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset && this.state.isData) {
                this.loadMore();
            }
        }
    };
    loadMore = () => {
       // console.log('loadMore==',this.state.page);
        const isreadytosend = this.state.isreadytosend;
        const pageno=this.state.page+1;
        if (isreadytosend) {
            console.log('isreadytosend=',isreadytosend);
            this.setState({ isreadytosend: false});
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    scrolling: true
                }),
                this.getListData(pageno)
            );
        }
    };  
    getListData(pageno) {
        //console.log('project_id==',project_id);this.setState({ loading: true});
        const { per, page, data } = this.state;
        //console.log('pageeeeee=',page,data);
        this.getVideoData(per,pageno,data,'');
    } 
    getVideoData(per,page,data){
        this.setState({ Inloading: true,isreadytosend: false});
        let selctedCategory= encodeURIComponent(this.state.selctedCategory);
        let selctedSubCategory= encodeURIComponent(this.state.selctedSubCategory);
        let selectedEmo= encodeURIComponent(this.state.selectedEmo);
        let selectedAsset= encodeURIComponent(this.state.selectedAsset);
        let selectedVideo= encodeURIComponent(this.state.selectedVideo);
        let isallorgvideo = this.state.isallorgvideo;
        let searchtext= this.state.searchText
        console.log('isallorgvideo==',isallorgvideo);
        const url = `allcompletedvideos/?per_page=${per}&page=${page}&searchtext=${searchtext}&selctedCategory=${selctedCategory}&selctedSubCategory=${selctedSubCategory}&selectedEmo=${selectedEmo}&selectedAsset=${selectedAsset}&selectedVideo=${selectedVideo}&isallorgvideo=${isallorgvideo}&orgid=${this.props.match.params.orgid}`;
        
        let postdata = {
            'org_id': this.props.match.params.orgid,
            
        }
        //console.log('data==',data);
        GetData(url).then((result) => {
            this.setState({ isreadytosend: true, Inloading: false });
            //console.log('result==',result);
            if (result.data.length > 0) {
                console.log('result==');
                this.setState({
                    data: [...data, ...result.data],
                    //data: [...data, ...result.data],
                    //originalAssetList:assets.data,
                    //data: result.data,
                    //assetList: [...assetList, ...result.data],
                    // originalAssetList:[...assetList, ...result.data],
                    scrolling: false,
                    isData: true,
                })
                setTimeout(() => {
                    this.callEvent();  
                }, 3000);
            }
        });

       
    }
    callEvent(){
        const vids = document.getElementsByTagName('video')
        //--- Now we loop over all of the selected elements and add event listeners
        for (let i = 0; i < vids.length; i++) {
            vids[i].addEventListener( 'mouseover', function(e) { 
                // console.log('vids[i].play()==',vids[i]);
                         vids[i].play()   
                         vids[i].muted = false;  
             })
             vids[i].addEventListener( 'mouseout', function(e) {
                // console.log('vids[i].pause()==',vids[i]);
                  vids[i].pause()    
                  vids[i].muted = true;  
               //vids[i].pause()
            })
        } 
    }
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }   
    updateInputValue(event){
        this.setState({
            searchText: event.target.value
        
       });
    } 
      
    handleChangeStatus = ({ meta, file }, status) => { 
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
    }
    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }
    handleSubmit = (files, allFiles) => {
        let videocat=this.state.selectedvideocat;
       // console.log('selectedvideocat===',videocat.length);
        if(videocat.length===0){
            this.setState({
                caterror:'Please select Category'
            });
        }
        else
        {  
            var loadermessage='Uploading';
            allFiles.forEach((f) => {
            // allFiles.forEach(f =>
                var mimetype= f.file.type;
                var checkvideo = mimetype.split('/');
                if(mimetype!='video/mp4' && checkvideo[0]=='video')
                {
                    loadermessage='Uploading and converting file in mp4'
                }                
            })
            //if(this.checkFolderID()){
            this.setState({assetuploadloader:true,assetuploadmessage:loadermessage,caterror:''});
            var formdata = new FormData();
            const selectedfiles=this.state.selectedFile;
            var uniqueArray = this.removeDuplicates(selectedfiles, "name");
            for(var x = 0; x<uniqueArray.length; x++) {
                formdata.append('stockvideos', uniqueArray[x])
            }
            //formdata.append('title', 'test');
            //fileid
            formdata.append('folderid', this.state.boxfolderid) 
            videocat= JSON.stringify(videocat);
            formdata.append('videocategory', videocat)  
            //formdata.append('folderid', this.state.boxfolder_name)    
            let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
            let session_key = userdata.token;
            fetch(apiConfig.WEB_SERVICE_URL+'uploadstockvideos', {
                method: 'POST',
                body: formdata,
                headers: {
                        "session-key": session_key,
                    }
            })
            .then((response) => response.json())
            .then((res) => {
                //console.log('cakkkeddd',res);
                if(res.statusCode===200)
                {               
                    this.setState({
                        assetuploadsuccessmsg:res.message,
                        assetuploadloader:false
                    });     
                    setTimeout(() => {
                        //this.setState({ assetuploadsuccessmsg: '',setAddAssetShow: false })
                        //this.getFolderListByFolderId(this.state.boxfolderid);
                        //this.props.loadFolderList();
                        allFiles.forEach(f => f.remove())                    
                        window.location.reload();                   
                    }, 3000);       
                }
            });   
            //}    
        }   
    }
    onSelect(selectedList, selectedItem) {
        //console.log('selectedList, selectedItem==',selectedList, selectedItem,this);
        this.setState({
            selectedvideocat:selectedList
        });
    }
    onRemove(selectedList, removedItem) {
        this.setState({
            selectedvideocat:selectedList
        });
    }
    handleSearchVideoChange(e) {
        this.setState({ searchvideocat: e.target.value });
        //console.log('searchvideocat===',e.target.value);
    }
    allOrgVideo = () => {
        this.setState({
            page: 1, 
            isreadytosend: true,
            data:[],
            isallorgvideo: true
        },function(){
            // console.log('pagenumber==',pagenumber); 
            //this.getListData(pagenumber) 
            this.getVideoData(this.state.per,1,[]);   
         })
       //this.getVideoData(this.state.per,1,[]);    
    }
    orgVideo = () => {
        this.setState({
            page: 1, 
            isreadytosend: true,
            data:[],
            isallorgvideo: false
        },function(){
            // console.log('pagenumber==',pagenumber); 
            //this.getListData(pagenumber) 
            this.getVideoData(this.state.per,1,[]); 
         })
        //this.getVideoData(this.state.per,1,[]);
    }
    handleCategoryChange(e) {
		this.setState({ selctedCategory: e.target.value,selctedSubCategory:'' });
	}	
	handleSubCategoryChange(e) {
		this.setState({ selctedSubCategory: e.target.value });
	}
    onEmoChange = text => {
		const selectedEmo = this.state.selectedEmo;

		// Find index
		const findIdx = selectedEmo.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedEmo.splice(findIdx, 1);
		} else {
		  selectedEmo.push(text);
		}
       // console.log('selectedEmo==',selectedEmo);
		this.setState({
		  selectedEmo: selectedEmo
		});
	};
	
	onAssetChange = text => {
		const selectedAsset = this.state.selectedAsset;

		// Find index
		const findIdx = selectedAsset.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedAsset.splice(findIdx, 1);
		} else {
		  selectedAsset.push(text);
		}
        //console.log('selectedAsset==',selectedAsset);
		this.setState({
		  selectedAsset: selectedAsset
		});
	};

	onVideoChange = text => {
		const selectedVideo = this.state.selectedVideo;

		// Find index
		const findIdx = selectedVideo.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedVideo.splice(findIdx, 1);
		} else {
		  selectedVideo.push(text);
		}
       // console.log('selectedVideo==',selectedVideo);
		this.setState({
		  selectedVideo: selectedVideo
		});
	};	
    onEmoUChange = text => {
		const selectedEmo = this.state.editedEmo;

		// Find index
		const findIdx = selectedEmo.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedEmo.splice(findIdx, 1);
		} else {
		  selectedEmo.push(text);
		}
        console.log('selectedEmo==',selectedEmo);
		this.setState({
            editedEmo: selectedEmo
		});
	};
	
	onAssetUChange = text => {
		const selectedAsset = this.state.editedAsset;

		// Find index
		const findIdx = selectedAsset.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedAsset.splice(findIdx, 1);
		} else {
		  selectedAsset.push(text);
		}
        console.log('selectedAsset==',selectedAsset);
		this.setState({
            editedAsset: selectedAsset
		});
	};

	onVideoUChange = text => {
		const selectedVideo = this.state.editedVideo;

		// Find index
		const findIdx = selectedVideo.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedVideo.splice(findIdx, 1);
		} else {
		  selectedVideo.push(text);
		}
        console.log('selectedVideo==',selectedVideo);
		this.setState({
            editedVideo: selectedVideo
		});
	};
    _handleChange(projectid,filename,isAddedtoPoprtfolio,e){
       // const {value} = e.target;
       const checked = e.target.checked;

        console.log('e.target=',e.target);
        console.log('checked=',checked);
        this.setState({
            sharestatus:!this.state.sharestatus
        },()=>{
            console.log('this.state.sharestatus=',this.state.sharestatus);
        });
        
        let data = {
            'filename': filename,
            'project_id': projectid,
            'isAddedtoPoprtfolio': checked,
            'updateportfolio':true,
        }
       // console.log('data==',data);
        PostData('updateproject', data).then((result) => {
            //console.log('result==',result);
            if (result.statuscode === 200) {
                this.setState({ portsuccessmsg: 'Updated Successfully' });
                setTimeout(() => this.setState({ portsuccessmsg: '',showEditProject:false }), 3000);
            }
        });
       
        
    }
    render() {  
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user; 
        console.log('userdata==',userdata.role);    
        const loading = this.state.Inloading;
        let datalist ='';
        let searchText =' ';
        var listItems ;
        searchText = this.state.searchText;
        let projects = this.state.projectlist.map((item, i) => {
            return <option className="folderlistitem" value={item._id}>
                {item.project_name}
            </option>;
        });
        if(this.state.originalAssetList.length>0) 
        {
            datalist = this.state.originalAssetList;
        } 
        else{
           datalist = this.state.data;
        }    
        if( datalist ) //if( datalist && !this.state.isallorgvideo )
        {
            let i=0;
            listItems = datalist.map((item, index) => {
                let assetpath= '';
                let downloadurl=''
                //let assetfilename= item.files[0].originalname;
               // console.log('item._id=',item._id);
               // console.log('item==',item);
                if(item.completedvideodata.length>0 && !this.state.isallorgvideo)
                {
                   // console.log('item.completedvideodata==',item.completedvideodata);
                    return item.completedvideodata.map((video,innerindex)=>{
                        
                        if(searchText)
                        {
                            //console.log('searchText==',searchText);
                           // console.log('video.vname==',video.vtitle);
                            let vtitle;
                            if(video && video.vtitle)
                            vtitle = video.vtitle
                            else if(video && video.vname)
                            vtitle= video.vname; 
                            vtitle = vtitle.toLowerCase();
                            let isavail = vtitle.includes(searchText);
                            //console.log('isavail==',isavail);
                            if(!isavail)
                            return false; // skip                              
                        }
                        i++;
                        //console.log('video==',video);
                        let keyindex=item._id+innerindex;
                        let projectid=item._id;
                        let title=video;
                        let portfoliofilename=title.vname;
                        let isAddedtoPoprtfolio =title.isAddedtoPoprtfolio;
                        //if(stockfilename.isAddedtoPoprtfolio) {
                            //console.log('stockfilename==',stockfilename);
                            //console.log('portfoliofilename==',portfoliofilename);
                            if(title.vtitle)
                                title=title.vtitle;
                            else{
                                title=title.vname;
                                title=title.split('.');
                                title=title[0]; 
                                title=title.split('_').join(' ');    
                            }            
                            // let filepage=item.filepage;
                            let posterimage='';
                            let videolink='';
                            let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
                            try{
                                if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {	
                                    videolink="../../draft/"+portfoliofilename+'#t=2';
                                }
                                else{
                                    videolink="../../../draft/"+portfoliofilename+'#t=2';
                                }
                            }
                            catch
                            {
                                videolink = '';
                                posterimage='';
                            }
                            let dynamicClassName='';
                            if(this.state.selectedPositionId==item._id)
                            dynamicClassName='selecteditem'

                            //console.log('videolink==',videolink);
                            return <div key={keyindex} className={'col-4 AssetsListCol collist '+dynamicClassName}> 
                                {(() => {
                                    if(videolink){
                                        return(
                                            <div class="AssetsListColIn">   
                                                
                                                {
                                                    (userdata.role && userdata.role==='administrator' &&                               
                                                    <video controls width="100%" key={videolink} height="220" poster={posterimage}>
                                                        <source src={videolink}
                                                                type="video/mp4"/>
                                                        Sorry, your browser doesn't support embedded videos.
                                                    </video>
                                                    )
                                                }
                                                {
                                                    (userdata.role && userdata.role!='administrator' &&                               
                                                    <video controls controlsList="nodownload" width="100%" key={videolink} height="220" poster={posterimage}>
                                                        <source src={videolink}
                                                                type="video/mp4"/>
                                                        Sorry, your browser doesn't support embedded videos.
                                                    </video>
                                                    )
                                                }
                                                <div class="AssetsColInText">
                                                    <h4> {title} </h4><br/>
                                                    
                                                    <div className="toggle-button">
                                                        <label  className={`switch ${(isAddedtoPoprtfolio) ? "pswitchon" : "pswitchoff"} ` }>
                                                            <input className="checkboxdata" onChange={this._handleChange.bind(this,projectid,portfoliofilename,isAddedtoPoprtfolio)} defaultChecked={(isAddedtoPoprtfolio) ? "checked": ""} type="checkbox"/>
                                                            <span className="slider round"></span>                      
                                                        </label>
                                                    </div> 
                                                    <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,portfoliofilename)}>Add To Project</a>
                                                    {(userdata.role && userdata.role==='administrator' &&
                                                    <a className="btn btn-primary addbtn" style={{float: "right"}} onClick={this.editProject.bind(this,projectid,title,portfoliofilename)}>Edit</a>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }                                
                                })()}                                        
                            </div>
                        //}
                    })
                }
                else if(item.completedvideodata.length>0 && this.state.isallorgvideo )
                {
                    return item.completedvideodata.map((video,innerindex)=>{
                        if(searchText)
                        {
                            //console.log('searchText==',searchText);
                           // console.log('video.vname==',video.vtitle);
                            let vtitle;
                            if(video && video.vtitle)
                            vtitle = video.vtitle
                            else if(video && video.vname)
                            vtitle= video.vname; 
                            vtitle = vtitle.toLowerCase();
                            let isavail = vtitle.includes(searchText);
                            //console.log('isavail==',isavail);
                            if(!isavail)
                            return false; // skip                              
                        }
                        i++;
                        //console.log('video==',video);
                        
                        let projectid=item._id;
                        let title=video;
                        let portfoliofilename=title.vname;
                        let keyindex=item._id+innerindex;
                        let isAddedtoPoprtfolio =Boolean(title.isAddedtoPoprtfolio);
                        //console.log('title.isAddedtoPoprtfolio==',title.isAddedtoPoprtfolio);
                        if(isAddedtoPoprtfolio) {
                            console.log('title.isAddedtoPoprtfolio==',isAddedtoPoprtfolio);
                            //console.log('stockfilename==',stockfilename);
                            //console.log('portfoliofilename==',portfoliofilename);
                            if(title.vtitle)
                                title=title.vtitle;
                            else{
                                title=title.vname;
                                title=title.split('.');
                                title=title[0]; 
                                title=title.split('_').join(' ');    
                            }            
                            // let filepage=item.filepage;
                            let posterimage='';
                            let videolink='';
                            let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
                            try{
                                if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {	
                                    videolink="../../draft/"+portfoliofilename+'#t=2';
                                }
                                else{
                                    videolink="../../../draft/"+portfoliofilename+'#t=2';
                                }
                            }
                            catch
                            {
                                videolink = '';
                                posterimage='';
                            }
                            let dynamicClassName='';
                            if(this.state.selectedPositionId==item._id)
                            dynamicClassName='selecteditem'

                            //console.log('videolink==',videolink);
                            return <div key={keyindex} className={'col-4 AssetsListCol collist '+dynamicClassName}> 
                                {(() => {
                                    if(videolink){
                                        return(
                                            <div class="AssetsListColIn">   
                                                
                                                {
                                                    (userdata.role && userdata.role==='administrator' &&                               
                                                    <video controls width="100%" key={videolink} height="220" poster={posterimage}>
                                                        <source src={videolink}
                                                                type="video/mp4"/>
                                                        Sorry, your browser doesn't support embedded videos.
                                                    </video>
                                                    )
                                                }
                                                {
                                                    (userdata.role && userdata.role!='administrator' &&                               
                                                    <video controls controlsList="nodownload" width="100%" key={videolink} height="220" poster={posterimage}>
                                                        <source src={videolink}
                                                                type="video/mp4"/>
                                                        Sorry, your browser doesn't support embedded videos.
                                                    </video>
                                                    )
                                                }
                                                <div class="AssetsColInText">
                                                    <h4> {title} </h4><br/>
                                                    {/* <div className="toggle-button">
                                                        <label  className={`switch ${(isAddedtoPoprtfolio) ? "pswitchon" : "pswitchoff"} ` }>
                                                            <input className="checkboxdata" onChange={this._handleChange.bind(this,projectid,portfoliofilename,isAddedtoPoprtfolio)} defaultChecked={(isAddedtoPoprtfolio) ? "checked": ""} type="checkbox"/>
                                                            <span className="slider round"></span>                      
                                                        </label>
                                                    </div>  */}
                                                    <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,portfoliofilename)}>Add To Project</a>
                                                    {(userdata.role && userdata.role==='administrator' &&
                                                    <a className="btn btn-primary addbtn" style={{float: "right"}} onClick={this.editProject.bind(this,projectid,title,portfoliofilename)}>Edit</a>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    }                                
                                })()}                                        
                            </div>
                        }
                    })
                } 
            });
        }        
        let uploadstock='';
        if(userdata.role && userdata.role==='administrator')
        {
            uploadstock=`/${this.props.match.params.orgid}/upload-stocks`;
        }
        let videocategory=['Agriculture','Animals & Nature','Arts & Entertainment','Building & Construction','Destinations & Travel','Disasters & Emergencies','Education','Energy & Utilities','Environment & Weather','Fashion','Finance','Food & Beverage','Government','Health care','Holidays & Celebrations','Housing & Real Estate','Law Enforcement','Legal System','Logistics','Manufacturing','Medical','Nutrition','People','Babies/Kids','Young Adults','Men','Women','Elderly','Family','Retail','Spirituality & Religion','Sports & Recreation','Transportation','Workplace'];
        const categories = this.state.categories.map(i => (
		    <option key={i.id} value={i.title}>
		      { i.title }		      
		    </option>
		));


        const subcategories = this.state.subcategories
            .filter(i => i.category.title === this.state.selctedCategory)
            .map(i => (
                <option key={i.id} value={i.title}>
                { i.title }		      
            </option>
        ));
        let  eselectedcategory=this.state.ucategory == "" ? this.state.editedCategory : this.state.ucategory;
       // console.log('eselectedcategory==',eselectedcategory);
        const editedsubcategories = this.state.subcategories
            .filter(i => i.category.title === eselectedcategory)
            .map(i => (
                <option key={i.id} value={i.title}>
                { i.title }		      
            </option>
        ));

        const { selectedEmo,selectedAsset,selectedVideo } = this.state;
        const emoCheckboxes = [
            { id: 1, text: "Informed" },
            { id: 2, text: "Curious" },
            { id: 4, text: "Happy" },
            { id: 5, text: "Excited" },
            { id: 6, text: "Upset" },
            { id: 7, text: "Angry" },
            { id: 8, text: "Motivated" },
            { id: 9, text: "Sympathy" },
            { id: 10, text: "Hopeful" },
            { id: 11, text: "Generous" },
            { id: 12, text: "Proud" },
            { id: 13, text: "Somthing Else" },
          ];
  
          const assetCheckboxes = [
            { id: 1, text: "Testimonial" },
            { id: 2, text: "Voiceover" },
            { id: 4, text: "Videos" },
            { id: 5, text: "Photos" },
            { id: 6, text: "Animations" },
            { id: 7, text: "Somthing Else" },		 
          ];
  
          const videoCheckboxes = [
            { id: 1, text: "Live Audience" },
            { id: 2, text: "Website" },
            { id: 4, text: "Social Media" },
            { id: 5, text: "Somthing Else" },		  
          ];

        return (
             <div className="container-fluid AssetsList ProjectList"  ref="selfdiv">
                {
                    loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="panel panel-primary">
                <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-2 UploadBreadcrumb">
                                    Portfolio
                                </div>      
                                <div className="col-2 SearchFolder graphicsearch">                            
                                    <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/> 
                                </div>                             
                                <div className="col-2 SearchFolder graphicsearch">                            
                                    <select name="searchvideocat" className="form-control" value={this.state.category} onChange={this.handleCategoryChange.bind(this)} >
                                        <option value=" ">Categories</option>
                                        {categories}
                                    </select>  
                                </div>   
                                <div className="col-2 SearchFolder graphicsearch">                         
                                    <select name="searchvideocat" className="form-control" value={this.state.subcategory} onChange={this.handleSubCategoryChange.bind(this)} >
                                        <option value=" ">Sub Category</option>
                                        {subcategories}	
                                    </select>                                                                     
                                </div>
                                <div className="col-4 SearchFolder graphicsearch">  
                                    <a className="search1 btn btn-primary1 addbtn1" onClick={this.advanceSearch.bind(this)}>Advanced Search Options</a>                   
                                    <a className="search btn btn-primary addbtn" onClick={this.searchFilter.bind(this)}>Go</a>                                 
                                </div>                              
                            </div> 
                            {
                                (this.state.isadvanceserach &&
                                    <div className="advance-search">
                                        <div className="row CreateProRadioBox">
                                            <div className="col-sm-12">
                                                <label for="projectname" class="ParentLab" >How should the viewer feel when watching the video?</label>
                                            </div>
                                            
                                            {emoCheckboxes.map(emo => (
                                                <div className="col-sm-2 hideinputbox">
                                                <label key={emo.id}>
                                                    <input
                                                    type="checkbox"
                                                    onChange={() => this.onEmoChange(emo.text)}
                                                    selected={selectedEmo.includes(emo.text)}
                                                    /> {emo.text}								            
                                                </label>
                                                </div>
                                            ))}	
                                                                    
                                        </div>  
                                        <div className="row CreateProRadioBox">
                                            <div className="col-sm-12">
                                                <label for="projectname" class="ParentLab" >How will this video be used ?</label>
                                            </div>
                                            
                                            {videoCheckboxes.map(video => (
                                                <div className="col-sm-2 hideinputbox">
                                                <label key={video.id}>
                                                    <input
                                                    type="checkbox"
                                                    onChange={() => this.onVideoChange(video.text)}
                                                    selected={selectedVideo.includes(video.text)}
                                                    /> {video.text}								            
                                                </label>
                                                </div>
                                            ))}	
                                            <div className="col-sm-2 hideinputbox"></div>
                                            <div className="col-sm-2 hideinputbox"></div>					
                                        </div>


                                        <div className="row CreateProRadioBox">
                                            <div className="col-sm-12">
                                                <label for="projectname" class="ParentLab" >What assets do you have or would like to use?</label>
                                            </div>
                                            
                                            {assetCheckboxes.map(asset => (
                                                <div className="col-sm-2 hideinputbox">
                                                <label key={asset.id}>
                                                    <input
                                                    type="checkbox"
                                                    onChange={() => this.onAssetChange(asset.text)}
                                                    selected={selectedAsset.includes(asset.text)}
                                                    /> {asset.text}								            
                                                </label>
                                                </div>
                                            ))}	
                                                                    
                                        </div>                                                                   
                                    </div> 
                                )
                            }
                            
                        </div>
                    </div>
                    <div className="row AssetsListGrid rowlist margintop20">
                        <ul className="list-inline">
                            <li className="list-inline-item orgdata"><a className={ !this.state.isallorgvideo ? "active" : ""} onClick={this.orgVideo}>{this.state.org_nick_name} Videos</a></li>
                            <li className="list-inline-item orgdata"><a className={ this.state.isallorgvideo ? "active" : ""} onClick={this.allOrgVideo}>All Videos</a></li>                            
                        </ul>
                        
                    </div>
                    <p>{this.state.portsuccessmsg}</p>
                    <div className="row AssetsListGrid rowlist margintop20">   
                                             
                        {listItems}
                    </div>                                                     
                </div>
                <Modal show={this.state.showAddToProject}>
                    {
                        this.state.loading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Add To Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="error-msg">{this.state.errormsg}</div>
                        <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                            <option value=" ">Select Project</option>
                            {projects}
                            {/* <option value="Active">Active</option>
                            <option value="On-hold">On-hold</option>
                            <option value="Completed">Completed</option> */}
                        </select>
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleAddToProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.addPortfolioVideoToProject.bind(this)}>
                            Add To Project
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showEditProject}>
                    {
                        this.state.loading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Edit Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="category ">
                            <label for="projectname">File Name</label>
                            <input type="text" name="title" value={this.state.title}
                                        onChange={this.onChange} className="form-control" autoFocus />                            								
                        </div>
                        <div className="category ">
                            <label for="projectname">Description</label>
                            <Editor editorState={this.state.projectEditorState} toolbar={{
								options: ['inline', 'list', 'emoji', 'link'],
								}}
								onEditorStateChange={(e) => this.onEditorStateChange(e)}
								/>                           								
                        </div>
                        
                        <div className="category ">
                            <label for="projectname">Category</label>
                            <select name="ucategory" className="form-control" value={this.state.ucategory == "" ? this.state.editedCategory : this.state.ucategory}
                                 onChange={this.handleDropDownChange.bind(this)} >
                                <option value=" ">Category</option>
                                {categories}
                                
                            </select>                           								
                        </div>
                        <div className="category ">
                            <label for="usubcategory">Subcategory</label>
                            <select name="usubcategory" className="form-control" value={this.state.usubcategory == "" ? this.state.editedSubCategory : this.state.usubcategory} onChange={this.handleDropDownChange.bind(this)} >
                                <option value=" ">Sub Category</option>
                                {editedsubcategories}										
                                
                            </select>                           								
                        </div>   
                        <div className="row CreateProRadioBox">
                            <div className="col-sm-12">
                                <label for="projectname" class="ParentLab" >How should the viewer feel when watching the video?</label>
                            </div>
                            
                            {emoCheckboxes.map(emo => (
                                <div className="col-sm-2 hideinputbox">
                                    <label key={emo.id}>
                                    <input
                                        defaultChecked={this.state.editedEmo.includes(emo.text) ? true : false} 
                                        type="checkbox"
                                        onChange={() => this.onEmoUChange(emo.text)}
                                        selected={this.state.selectedUEmo.includes(emo.text)}
                                    /> {emo.text}								            
                                    </label>
                                    </div>
                            ))}	
                                                    
                        </div>

                        

                        <div className="row CreateProRadioBox">
                            <div className="col-sm-12">
                                <label for="projectname" class="ParentLab" >How will this video be used ?</label>
                            </div>
                            
                            {videoCheckboxes.map(video => (
                                <div className="col-sm-2 hideinputbox">
                                    <label key={video.id}>
                                    <input
                                        defaultChecked={this.state.editedVideo.includes(video.text) ? true : false}
                                        type="checkbox"
                                        onChange={() => this.onVideoUChange(video.text)}
                                        selected={this.state.selectedUVideo.includes(video.text)}
                                    /> {video.text}								            
                                    </label>
                                    </div>
                            ))}	
                            <div className="col-sm-2 hideinputbox"></div>
                            <div className="col-sm-2 hideinputbox"></div>					
                        </div>


                        <div className="row CreateProRadioBox">
                            <div className="col-sm-12">
                                <label for="projectname" class="ParentLab" >What assets do you have or would like to use?</label>
                            </div>
                            
                            {assetCheckboxes.map(asset => (
                                <div className="col-sm-2 hideinputbox">
                                    <label key={asset.id}>
                                    <input
                                        defaultChecked={this.state.editedAsset.includes(asset.text) ? true : false}
                                        type="checkbox"
                                        onChange={() => this.onAssetUChange(asset.text)}
                                        selected={this.state.selectedUAsset.includes(asset.text)}
                                    /> {asset.text}								            
                                    </label>
                                    </div>
                            ))}	
                                                    
                        </div>                         
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleEditProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.updateProject.bind(this)}>
                            Update Project
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default Portfolio;
