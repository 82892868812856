import React, { Component } from "react";
//import { Helmet } from 'react-helmet';
class Support extends Component {
    constructor(props){
		super(props);
	}
    render() {		 		
        return (
        	<div className="ProducerSaveDate AddStoryBoard">
				 
					{/* <!-- Start of associationstudios Zendesk Widget script --> */}
					<script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=15d94dc7-4d75-4b65-897e-0bfcdd6f49b0"> </script>
					{/* <!-- End of associationstudios Zendesk Widget script -->			 */}	
				
					<h3>Support Page</h3>				
			</div>	
		);
    }
}
export default Support;