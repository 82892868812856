import React, {Component} from 'react';
import { Link,Redirect } from 'react-router-dom';
export default class Footer extends Component {
    render(){
        let checkuser1 = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
        if(checkuser1 && checkuser1.user && (checkuser1.user.ID==15888 || checkuser1.user.ID==12612 || checkuser1.user.ID==28 || checkuser1.user.ID==202371015253))
        {
            localStorage.setItem('userData','');
            return (<Redirect to={'/'}/>)
        }
        return (
            <footer className="footer">
		      
		    </footer>
        )
    }
}