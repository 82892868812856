import React, { Component } from "react";
import { PostData, GetData } from '../services/PostData';
class DownloadVideo extends Component {
    constructor(props){
		super(props);
		this.state={			
		}
	}
	componentDidMount() { 		

	    console.log('this.props.match.params.draftid=',this.props.match.params.draftid);    
	    GetData('showdraft/'+this.props.match.params.draftid).then((result) => {             
		  
			//this.setState({
				//issuccess:true,
				//videopagedata:result.data
			//});
			console.log('result==',result);
		               
		let draftvideosrcfordraftdownloadlink11="../../draft/"+result.data.draftfile;
	    const link = document.createElement('a');
	    link.href = draftvideosrcfordraftdownloadlink11;
	    link.setAttribute(
	      'download',
	      result.data.draftfile,
	    );
	    // Append to html link element page
	    document.body.appendChild(link);
	    // Start download
	    link.click();
	    // Clean up and remove the link
	    link.parentNode.removeChild(link);                        
		});
	    	
    }	
	render() {		
        return (
        	<>
        	</>	
		);
	}
}
export default DownloadVideo;