import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../services/PostData';
import dateFormat from 'dateformat';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import Loader from 'react-loader-spinner'
import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { CSVLink, CSVDownload } from "react-csv";

class ProfitabilityMeasurement extends Component {
    constructor(props) {
        super(props);
        let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        this.state = {
            loading: false,    
            customertype:'customer',
            data:[],
            avg_hourly_rate:'',
        };
    }
     componentDidMount() {
       let customer = (this.state.customertype==='customer')?1:2;
       console.log('customer=',customer);
       this.getReportData(customer);
    }
    reportType(customertype){
        this.setState({ customertype: customertype }); 
        let customer = (customertype==='customer')?1:2;
        this.getReportData(customer);
    } 
    getReportData(customer){
        GetData('getprofitpeasurement/'+customer).then((result) => {
            console.log('result=',result);
            if (result.statuscode === 200) {
                this.setState({
                    data:result.orgdata,
                    avg_hourly_rate:result.avg_hourly_rate,
                })
            }
        });
   }
    UpdateTaskStatus = (event) => {
        let status = event.target.value;
        let taskid = event.target.dataset.id;
        console.log('taskid',taskid);
        let data = {
            'taskstatus': status,
            'taskid': taskid
        }
        
        PostData('updatetaskstatus', data).then((result) => {
            if (result.statuscode === 200) {
                window.location.reload(true);
                console.log('success');
            }
        });
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
                if (value === "" || !value.length) filtered.splice(i, 1);
                else filter["value"] = value;
                insertNewFilter = 0;
            }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    };
  
  
    render() {
    let loading=this.state.loading;
    const columns = [
        {
            Header: 'Customer',
            accessor: 'organization_name',
            
        },   
        {
            Header: 'Hours Balance',
            accessor: 'hours_balance',
            
        },
        {
            Header: 'Active Projects',
            accessor: 'active_project_count',
            
        },
        {
            Header: 'Effective Hourly Rate',
            accessor: 'effective_hour_rate',         
        }, 
        {
            Header: 'Feedback Score',
            accessor: 'feedback_score',         
        },
    ];            
    const data=this.state.data;
    //console.log('data',data);
    let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
    const name=userdataforcheck.firstname+' '+userdataforcheck.lastname;
    const avatar=userdataforcheck.avatar;
    const username=userdataforcheck.username;
    const jobtitle=userdataforcheck.jobtitle;
    const description=userdataforcheck.description;
    const isClearable= this.state.isClearable;

    const filename = 'Measurement'; 
    const newDate = new Date()
    const currentm = parseInt(newDate.getMonth() +1);
    const currentdate = newDate.getDate()+'/'+currentm+'/'+newDate.getFullYear();
    const currenttime = newDate.getHours()+':'+newDate.getMinutes()+':'+newDate.getSeconds();

    const csvData = [
            ["Customer", "Hours Balance", "Active Projects","Effective Hourly Rate","Feedback Score"],
    ];
    data.forEach((item, index, array) => {
       var pusheddata = Array();
        if(item.organization_name){
            pusheddata.push(item.organization_name,item.hours_balance,item.active_project_count,item.effective_hour_rate,item.feedback_score);
            csvData.push(pusheddata);
        }
                    
    }); 
		return (
			 <div className="container-fluid ProjectList ProAssetsList"  ref="selfdiv">
                 {
	        		loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="profitabilityMeasurement">
                    <div className="WrapBarTop panel-heading financialReports">
                        <div className="">
                            <div className="">
                                {/* <h4 class="PageTopTitle">Profitability Measurement</h4> */}
                                <h4 class="PageTopTitle">Financial Reports</h4>
                                <select name="" id="financialReports">
                                <option value="">Effective Hourly Rate</option>
                                <option value="">Effective Hourly Rate 2</option>
                                <option value="">Effective Hourly Rate 3</option>
                                <option value="">Effective Hourly Rate 4</option>
                                </select>
                            </div>
                        </div>                        
                    </div>
                    <div className="rowCustom">
                    <div className="col">
                        <div className="row InboxTabRow">
                            <div className="effecyiveHourlyRateLeft">
                                <h4 class="PageTopTitle">Effective Hourly Rate</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="effecyiveHourlyRateBoxRight">
                               <CSVLink data={csvData} filename={filename+' '+currentdate+' at'+currenttime+'.csv'}>
                               <img src={require('../assets/images/excel-icon.png')} /></CSVLink>
                            </div>
                        </div>
                    </div>
                    <div className="rowCustom topBorderSolid">
                    <div className="col">
                        <div className="row InboxTabRow">
                            <div className="col">                                
                                <select name="" id="effecyiveHourlyRate">
                                <option value="">Subscriber</option>
                                <option value="">Admin</option>
                                <option value="">User</option>                                
                                </select>
                            </div>
                        </div>                        
                    </div>
                    <div className="col combinedBox">
                        <h3>Combined EHR: ${this.state.avg_hourly_rate}</h3>
                    </div>
                    </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12 col-sm-12 MobOrgProTab">
                            <ul className="top-links">
                                 <li>
                                    <a className="forcusror" onClick={this.reportType.bind(this,'customer')}>Customer</a>
                                </li>
                                <li>
                                    <a className="forcusror" onClick={this.reportType.bind(this,'noncustomer')}>Non Customer</a>
                                </li>                                                            
                            </ul>
                        </div>                                
                    </div> */}
                    <div className="panel-body">
                        <div className="container-fluid TaskListPage"  ref="selfdiv">
                        {
                            this.state.loading ?
                                <div className="loaderspinner">
                                    <Loader
                                         type="TailSpin"
                                         color="#D10000"
                                         height={100}
                                         width={100}                                 
                                    />
                                </div>
                            :null 
                        }
                        <div className="panel panel-primary">                   
                            <div className="panel-body">  
                                 
                                {   
                                                
                                    <ReactTable
                                        data={data}
                                        filtered={this.state.filtered}
                                        minRows = {0}
                                        pageSizeOptions = {[10,50,100,500,1000,2000]}
                                        defaultPageSize = {10}                             
                                        onFilteredChange={(filtered, column, value) => {
                                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                                        }}
                                        defaultSorted={[{ id: "due_date", desc: false }]}
                                       // defaultFiltered={[{ id: "due_date", value: due_date }]}
                                       
                                        columns={columns}
                                        className="-striped -highlight"
                                    />                            
                                }
                             </div>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
		);
    }
}
export default ProfitabilityMeasurement;
