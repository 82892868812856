import React, { Component } from "react";
import FreshbookInvoice from "./FreshbookInvoice";
import AddExpense from './AddExpense';
import TimeLog from "./TimeLog";

class TimeExpense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boarddisplaytype:'Expenses'
        };
    }
    componentDidMount() {     
                
    }

    changeView(displaytype, e) {
        this.setState({ boarddisplaytype: displaytype });
    }

    setAddExpenseShow(e) {
        let currdate=Date.now();
        this.setState({  setAddExpenseShow: true,isAddExpense:currdate });
    }

    render() {
        const expensedata = this.props.projectboarddata.filter((pbdata) => pbdata.type == 'expense');
        const timelogdata = this.props.projectboarddata.filter((pbdata) => (pbdata.type == 'script' || pbdata.type == 'voice over' || pbdata.type == 'draft' || pbdata.type == 'video' || pbdata.type == 'compfile' || pbdata.type == 'tasktimelog'));
        return (
			<div className="container-fluid ProjectList"  ref="selfdiv">
                <div className="row">
                    <div className="col-12">
                       <div className="row">
                            <div className="panel-body">
                                <ul className="top-links">
                                <li>
                                    <a className="forcusror" onClick={this.changeView.bind(this, 'Expenses')}>Expenses</a>
                                </li>
                                <li>
                                    <a className="forcusror" onClick={this.changeView.bind(this, 'Time Log')} data-id="UpdateTask">Time Log
</a>
                                </li>
                            </ul>
                            </div>
                        </div><br/>
                        <div className="row">
                            {
                                (this.state.boarddisplaytype === 'Expenses' &&
                                    <>
                                        <div className="col-6 "></div>
                                        <div className="col-6 ProSubHeadRight"><a onClick={this.setAddExpenseShow.bind(this)} className="forcusror">+  ADD EXPENSE</a></div>
                                        {
                                            (this.state.setAddExpenseShow &&
                                                <AddExpense freshbook_profile_id={this.props.freshbook_profile_id} orgid={this.props.orgid} 
                                                prjid={this.props.prjid} 
                                                organizationName={this.props.organizationName} handleAddExpenseShow={this.state.setAddExpenseShow} isAddExpense={this.state.isAddExpense} />
                                            )
                                        }
                                        <FreshbookInvoice projectboarddata={expensedata} freshbook_profile_id={this.props.freshbook_profile_id} />
                                         <TimeLog projectboarddata={timelogdata} freshbook_profile_id={this.props.freshbook_profile_id} />
                                    </>                       
                                )
                            }
                            {
                                (this.state.boarddisplaytype === 'Time Log' &&                                   
                                    <>Time Log
                                    </>                            
                                )
                            }
                        </div>
                    </div>
                </div> <br/><br/>
            </div>
		);
    }
}
export default TimeExpense;
