import React, { Component } from "react";
import {PostData,GetData} from '../../../services/PostData';
import {apiConfig} from "../../../config/api"
import Loader from 'react-loader-spinner'
class EditDraft extends Component {
    constructor(props){
		super(props);
		this.state = {
			draftdata:'',
		};
	}
	componentDidMount() {
		this.setState({Inloading: true});
		const url = `editdraft/${this.props.draftid}`;
		GetData(url).then((result) => {   
			if(result.statusCode===200)
			{
				this.setState({Inloading: false,draftdata:result.data});	
			}
		});
	}
	markAsComplete(e){
		console.log("completdeeeeeeeeeddd");
	}
	
    render() {
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		const Inloading = this.state.Inloading;
    	let draftdata=this.state.draftdata;
		let draftvideo='';
		if(draftdata)  
		{  
			draftvideo = (
				<div className="row">
					<div class="ProjectRepeatTbl">
						<a className="forcusror" onClick={this.markAsComplete.bind(this)} >Mark As Complete</a>
						<div className="vidoo-content">							
							<video data-title={draftdata.drafttitle} onClick={this.testClick} onPlay={this.onPlay} onPause={this.onPause} controls width="100%" height="220">
								<source src={'../../../assets/images/draft/'+draftdata.draftfile+''}
											type="video/mp4"/>
									Sorry, your browser doesn't support embedded videos.
							</video>
						</div>
						<ul className="top-links">
							<li>
								<a className="forcusror" target="_blank" href={'../../../assets/images/draft/'+draftdata.draftfile+''} download>Download</a>
							</li>
							<li>
								<a className="forcusror" >External Link</a>
							</li>
						</ul>	
					</div>
				</div>
			);
		}		
        return (
        	<div className="ProducerSaveDate AddStoryBoard">
				<img className="SD_img_icon" src={userdata.avatar}/>
				{
					Inloading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}						         
							/>
						</div>
					:null 
				} 
				<h3 class="AddTitleDrop">Edit Draft</h3>
				<div className="clearfix"></div>
				{draftvideo}				
			</div>	
		);
    }
}
export default EditDraft;