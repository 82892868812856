import React, { Component } from "react";
import {PostData,GetData,PostDataWithUpload} from '../../../services/PostData';
import {apiConfig} from "../../../config/api"
import Loader from 'react-loader-spinner'
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import io from "socket.io-client";
import SocketIOFileUpload from "socketio-file-upload"; 
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML, decorator, convertRawToConvertable, Entity, Modifier  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
var Vimeo = require('vimeo').Vimeo;
class Calendly extends Component {
    constructor(props){
		super(props);
		this.state = {
			calendlyusers: {},
			fields: {},
			errors: {},
			saveBoardDataloader:false,
			loadingstatus:'',
			showdialogbox:false,
			chatattchedfilename:[],
			chatattachament: '',
			chatattchedfilebasename:[],
			editorState: EditorState.createEmpty(),
			setAddCalendlyShow:this.props.handleAddCalendlyShow,
			calendly_id: ''
		};
		let url = apiConfig.WEB_SERVICE_URL;
		url = url.replace('http://', '');
		url = url.replace('/api/', '');
		this.socket = io(url);		
		let currentstate=this;
		this.socket.on('SAVE_ATTACHMENT', function (data) {
			console.log('check dataaaaaaaaaaa======',currentstate.state.chatuploadincurrentpage,data.bytesLoaded,'attachedfilesize==',currentstate.state.attachedfilesize);
			if(currentstate.state.chatuploadincurrentpage && data.bytesLoaded===currentstate.state.attachedfilesize)
			{
				currentstate.setState({
					//chatattchedfilebasename:data.base,
					//chatattchedfilename:data.name,
				});
				currentstate.setState({ chatattchedfilename: [...currentstate.state.chatattchedfilename, data.name],chatattchedfilebasename: [...currentstate.state.chatattchedfilebasename, data.base] })
			}
			//console.log('data.base',data.base);
			//console.log('chatattchedfilename',currentstate.state.chatattchedfilename);
			//console.log('chatattchedfilebasename',currentstate.state.chatattchedfilebasename);
		});
		
		this.sendMessage = () => {
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
			//ev.preventDefault();
			const datta = this.state.editorState;
			console.log('datta==',datta);
			if(!datta)
			{
				return false;	
			}
			const blocks = convertToRaw(datta.getCurrentContent()).blocks;
			let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
			value=value.trim()	
			console.log('usrdataom datta.getCurrentContent()==',datta.getCurrentContent());
			const editorJSON = JSON.stringify(convertToRaw(datta.getCurrentContent()));
			
				let usrdata = JSON.parse(editorJSON);
				console.log('usrdataom footer chat==',usrdata);
				const taggedusr = usrdata.blocks[0].text
				const entityMap = usrdata.entityMap
				const objlength = Object.keys(entityMap).length;
				console.log('entityMap==',entityMap);

							
				//console.log('taggedusr==',taggedusr);
				const msgsplit = taggedusr.split(' ');
				//console.log('msgsplit==',msgsplit);
				msgsplit.forEach((element)=>{
					if(element == '@All'){
						
						console.log('editorJSON=====', '@All');
					}
				})

            console.log('value==',value);
			if(value && value!=" ")
			{



				//console.log('in iffffffff=====',value,"data");
				let cAttchedfilename=this.state.chatattchedfilename.toString();
				let cAttchedfilebasename=this.state.chatattchedfilebasename.toString();
				this.socket.emit('SEND_MESSAGE', {
					userdata: userdata,
					project_id: this.props.prjid,
					org_id:this.props.orgid,
					message: editorJSON,
					chatattachament: this.state.chatattachament,
					chatattchedfilename:cAttchedfilename,
					chatattchedfilebasename:cAttchedfilebasename,
					chatid:this.state.editedboardid,
					chatallusers:this.props.allusers,
					calendly_id:this.state.calendly_id
				})
				//this.scrollToBottom();
				const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
				this.setState({ editorState });
				this.setState({ chatattchedfilename:'',chatattchedfilebasename:'',editedboardid:'',iseditedchat:'',removechatvalidation:false });
				//this.handleCalendlyShowWidget();
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.isAddCalendlyTime !== this.props.isAddCalendlyTime) {
			this.setState({
				setAddCalendlyShow:this.props.isAddCalendlyTime,
				editorState: EditorState.createEmpty(),
			});

		}
	}	

 	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["drafttitle"]) {
			formIsValid = false;
			errors["drafttitle"] = "*Please enter Video Title";
		}
		if (!fields["video"]) {
			formIsValid = false;
			errors["video"] = "*Please upload Video";
		}	
		if (!fields["dhour"]) {
			formIsValid = false;
			errors["dhour"] = "*Please select hour";
		}
		if (!fields["dminut"]) {
			formIsValid = false;
			errors["dminut"] = "*Please select minut";
		}	
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	handleConfirmDialogBoxClose(e) {
		this.setState({
			showdialogbox: false,			
		});
		this.setState({
			editorState:EditorState.createEmpty(),
			//editedboardid:'',
			//iseditedchat:false,
		});
		this.postAjax(apiConfig.WEB_SERVICE_URL+'savedraft', function(data){
			var res = data.json();
			console.log('checkresposise==',res,res.statuscode); 
			if(res.statuscode===200)
			{			
				console.log('status code==',res.statuscode); 	     		    
			} 			
		})	
	}
	
	handleEchatMessageChange = (editorState) => {
		this.setState({
			editorState,
		});
	};
	caledlyAssocusersChanges(event) {
		console.log('event.target.value==',event.target.value);
		console.log('event.target.calendlyusers==',this.state.calendlyusers);
		console.log('event.target.selectedCalendlyCheckboxes==',this.state.selectedCalendlyCheckboxes);
		
		this.state.selectedCalendlyCheckboxes.push(event.target.value);
		this.setState({ calendlyusers: this.state.selectedCalendlyCheckboxes });
		return false
	}
	handleCalendlyShowWidget() {
		//this.setState({ handleCalendlyShow: false });
		//this.setState({ isOpen: true });
		console.log('props.calendlyusers=',this.props.calendlyusers);
		const selectedcalusers = this.props.calendlyusers;
		const eventtype = this.getEventTypeByEmail(selectedcalusers);
		console.log('eventtype=',eventtype);

		this.setState({ loading: true });

		let data = {
			'eventtype': eventtype,
			'project_id': this.props.prjid,
			'org_id': this.props.orgid,
			'user_id': this.props.userid
		}
		PostData('addcalendlydata', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ setAddCalendlyShow: false, loading: false,calendly_id:result.data._id },()=>{
					this.sendMessage();
				});
				//this.props.history.push("/org/"+this.props.match.params.id+"/project/list");
			}
		});
	}

	getEventTypeByEmail(selectedcalusers) {
		let eventtype;
		if (selectedcalusers.length==1 && selectedcalusers.includes('jim@associationstudios.com')) {
			eventtype = 'https://calendly.com/jimwacksman/jim';
		} else if (selectedcalusers.length==1 && selectedcalusers.includes('darcy@associationstudios.com')) {
			eventtype = 'https://calendly.com/darcy-video-consult/meet-with-darcy';
		} else if (selectedcalusers.length==1 && selectedcalusers.includes('michelle@associationstudios.com')) {
			eventtype = 'https://calendly.com/michelle-um5a/meet-with-michelle';
		} else if (selectedcalusers.length==1 && selectedcalusers.includes('colby@associationstudios.com')) {
			eventtype = 'https://calendly.com/colby-h7tf/meet-with-colby';
		} else if (selectedcalusers.length==1 && selectedcalusers.includes('michael@associationstudios.com')) {
			eventtype = 'https://calendly.com/michael-ez_p/meet-with-michael';
		} else if (selectedcalusers.length==1 && selectedcalusers.includes('brad@associationstudios.com')) {
			eventtype = 'https://calendly.com/brad-cf1y/meet-with-brad';
		} else if (selectedcalusers.length==1 && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/matt-ksvx/meet-with-matt';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cj9d-zv5-7wg/meet-with-michael-jim';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpdx-z26-w4t/meet-with-colby-jim';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckgf-dp7-hs6/meet-with-michelle-jim';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cn6w-3fx-bx2/meet-with-michelle-brad';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cp3y-x4g-fjf/meet-with-michelle-colby';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cp3y-fhb-fj5/meet-with-michelle-michael';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('michelle@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpcz-pb2-ymg/meet-with-michelle-matt';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cp39-y8k-7w3/meet-with-michelle-darcy';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cp4c-673-7xr/meet-with-darcy-jim';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpd6-8wd-j5s/meet-with-darcy-colby';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpcp-wrf-gmg/meet-with-brad-colby';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpcy-8nm-m88/meet-with-brad-michael';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpcx-fqv-jmp/meet-with-darcy-michael';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckf3-92v-697/meet-with-michael-matt';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckgh-mvq-nj5/meet-with-colby-michael';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/ck73-gcf-k9d/meet-with-colby-matt';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ck6k-g45-c5h/meet-with-brad-jim';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('jim@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cj9d-vrr-tps/meet-with-matt-jim';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckgh-4y7-mrb/meet-with-darcy-brad';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cp47-54g-34c/meet-with-darcy-matt';
		} else if (selectedcalusers.length==2 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/ckf4-z7p-gpj/meet-with-brad-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cn6h-b53-v9f/meet-with-darcy-brad-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cn7v-xzm-gvq/meet-with-darcy-michelle-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckgh-5pd-swr/meet-with-michelle-brad-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckgh-5g9-hv2/meet-with-michelle-colby-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckf4-fx2-nkw/meet-with-michelle-michael-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpdy-fsy-z57/meet-with-michelle-matt-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpdv-sgv-t2b/meet-with-darcy-matt-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpx5-y44-g29/meet-with-darcy-colby-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cj83-3nq-5gk/meet-with-darcy-michael-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpdv-6fs-vb5/meet-with-brad-colby-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cj8z-9m8-mw8/meet-with-brad-michael-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpyg-jbw-ywr/meet-with-matt-brad-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckf7-bgz-64x/meet-with-colby-michael-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpyj-994-hgy/meet-with-colby-matt-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('jim@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckf6-rkq-5kq/meet-with-michael-matt-jim';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ck75-p4n-njr/meet-with-michelle-darcy-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cn7v-z6d-6tb/meet-with-michelle-darcy-colby';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpd9-crq-zqc/meet-with-michelle-darcy-michael';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/ckf6-k2w-g5x/meet-with-michelle-darcy-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckzq-qz2-sw3/meet-with-michelle-brad-colby';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cn74-h94-vf2/meet-with-michelle-brad-michael';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cpx5-kph-d79/meet-with-michelle-brad-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckhq-khr-c97/meet-with-michelle-colby-michael';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cpdv-7fm-r2r/michelle-colby-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('michelle@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckbp-pj9-y7t/meet-with-michelle-michael-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ck7r-k5p-wqk/meet-with-darcy-colby-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckzc-94n-yyv/meet-with-darcy-michael-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/ckzs-vxs-kzs/meet-with-darcy-matt-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/cpyk-bf5-9b6/meet-with-darcy-colby-michael';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cpyk-bpw-xcq/meet-with-darcy-colby-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('darcy@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckhg-xrp-d79/meet-with-darcy-michael-matt';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ck22-yct-jbv/meet-with-colby-michael-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cj99-fzg-gw5/meet-with-colby-matt-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('michael@associationstudios.com') && selectedcalusers.includes('brad@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com')) { 
			eventtype = 'https://calendly.com/d/cp47-73n-dvs/meet-with-michael-matt-brad';
		} else if (selectedcalusers.length==3 && selectedcalusers.includes('colby@associationstudios.com') && selectedcalusers.includes('matt@media-gen.com') && selectedcalusers.includes('michael@associationstudios.com')) { 
			eventtype = 'https://calendly.com/d/ckbc-rzk-wgy/meet-with-colby-michael-matt';
		}
		return eventtype;
	}
	handleCalendlyClose(e) {
		this.setState({ setAddCalendlyShow: false });
	}
    render() {
    	console.log('setAddCalendlyShow=',this.state.setAddCalendlyShow);
		const saveBoardDataloader = this.state.saveBoardDataloader;
    	const isshowing_subscription_hour=this.state.isshowing_subscription_hour;
		const isshowing_org_credit=this.state.isshowing_org_credit;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        return (
        	<div className="ProducerSaveDate AddDraftBoard">

				<Modal show={this.state.setAddCalendlyShow}>
					<Modal.Header class="SelectProducerHead">
								<Modal.Title>Select Team Membert for Schedule A Call </Modal.Title>
							</Modal.Header>
					<Modal.Body className="SelectProducerModel addtasktime">
						{this.props.caledlyAssocusers}
						<div className="row">
							<div className="col-12">
								<Editor onFocus={() => this.setState({ hasFocus: true })} onBlur={() => this.setState({ hasFocus: false })} editorState={this.state.editorState} wrapperClassName="demo-wrapper" editorClassName="editer-content" onEditorStateChange={(e) => this.handleEchatMessageChange(e)} placeholder='Ask a question or post an update..." with "@mention someone to notify them.'
									toolbar={{
										options: ['inline', 'list', 'emoji', 'link'],
									}}
									mention={{
										separator: ' ',
										trigger: '@',
										suggestions: this.props.associateduserforhastag,
									}}
									hashtag={{}}
								/>									
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleCalendlyClose.bind(this)}>
							X
			          </Button>
						<Button variant="primary" onClick={this.handleCalendlyShowWidget.bind(this)} >
							Save Changes
			          </Button>
					</Modal.Footer>
				</Modal>
			</div>		
		);
    }
}
export default Calendly;