




import React, { Component } from "react";
//import { withRouter } from "react-router-dom";
import { Redirect,withRouter } from 'react-router-dom';
import {PostData} from '../services/PostData';
import loginbg from '../assets/images/login-bg1.png';
import Loader from 'react-loader-spinner'
import LostPassword from './LostPassword';
class Login extends Component {
    constructor()
    {
		super();
		this.state = {
			redirectToReferrer: false,
			fields: {},
		    errors: {},
		    loading: false,
		    username:'',
		    password:'',
			servereerror:'',
			displayType:'login'
		};
		this.login = this.login.bind(this);
		this.onChange = this.onChange.bind(this);
		this.checkboxChange = this.checkboxChange.bind(this);
	}
	
	checkboxChange(e){
		let isChecked = e.target.checked;
		this.setState({[e.target.name]:isChecked});
		//this.setState({[e.target.name]:e.target.value});
	}
	onChange(e){
		//console.log('cleeed in check==',e.target.value);
		this.setState({[e.target.name]:e.target.value});
	}
	login() {	
		if(this.state.rememberme)
		{
			console.log('in ifffffffffffff');
			localStorage.setItem('username', this.state.user_email);
			localStorage.setItem('password', this.state.user_pass);
		}
		else
		{
			console.log('in elseeeeeeeeeee');
			localStorage.setItem('username', '');
			localStorage.setItem('password', '');
		}
		if (this.validateForm())
		{
			if(this.state.user_email && this.state.user_pass)
			{
				this.setState({ loading: true });
				PostData('login',this.state).then((result) => {				
					let responseJson = result;
					if(responseJson.status==200)
					{
						console.log('responseJson==',responseJson);///subs/project/list
						console.log('responseJson.data==',responseJson.data.orgid);
						//console.log('responseJson==',responseJson);
						//console.log('responseJson.data.user.role.administrator==',responseJson.data.user.role.administrator);///subs/project/list
						this.setState({redirectToReferrer: true, loading: false,});
						let redirectURL='subs/org/'+responseJson.data.orgid+'/project/list';
						localStorage.setItem('userData',JSON.stringify(responseJson));
						if(responseJson.data.user.role==='administrator')
						{
							console.log('in iffffffffffff admin');
							this.props.history.push("/profile");
						}
						else
						{
							console.log('in iffffffffffff subs',redirectURL);
							this.props.history.push(redirectURL);
						}
						//console.log('non admin==',responseJson.data);///subs/project/list
						//this.props.history.push("/vpma");
						///subs/org/:orgid/project/list
					}
					else
					{
						this.setState({servereerror: responseJson.message.error, loading: false,});
					}
				});
			}
		}
	}
	validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
      if (!fields["user_email"]) {
        formIsValid = false;
        errors["user_email"] = "*Please enter your email-ID.";
      }
      if (!fields["user_pass"]) {
        formIsValid = false;
        errors["user_pass"] = "*Please enter your password.";
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
	}  
	changeView(viewtype,e){
		console.log('viewtype==',viewtype,e);
		this.setState({
			displayType:viewtype
		});
	}
    render() {
        const { errors, formSubmitted,loading } = this.state;
        const username=localStorage.getItem('username');
		const password=localStorage.getItem('password');
		const displayType=this.state.displayType;
        if (localStorage.getItem('userData')){
			console.log('in userdata exist ');
			let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			if(!checkuser.user)
			{
				localStorage.setItem('userData','');
				return (<Redirect to={'/'}/>)
			}
			else{
        	return (<Redirect to={'/vpma'}/>)
			}
		}
        return (	
				<div className="login-section">
				<div className="login-section-in">
				   <div className="container">	
				   	<div class="row">
				   	<div class="col-12 col-sm-12 col-md-7 col-lg-7">
						<div class="LoginVector">
						<img class="" src={require('../assets/images/login-graphics.png')}/>
						</div>
					</div>
				   	<div class="col-12 col-sm-12 col-md-5 col-lg-5">
					   <div className="login-form">
						   { 	(displayType ==='login' &&
						   			<div>
										<h2>Login</h2>   
										{
											loading ?<div className="loaderspinner">
												<Loader
													type="TailSpin"
													color="#D10000"
													height={100}
													width={100}						         
												/>
											</div> :null
										}
										<div className="errorMsg">{this.state.servereerror}</div>
										<div className="form-group">
											<input type="text" defaultValue={username} placeholder="Email Address*" name="user_email" onChange={this.onChange}  autoFocus />
											<div className="errorMsg">{this.state.errors.user_email}</div>
										</div>
										<div className="form-group">
											<input type="password" defaultValue={password} placeholder="Password*" name="user_pass" onChange={this.onChange} autoFocus />
											<div className="errorMsg">{this.state.errors.user_pass}</div>
										</div>
										<div className="form-group">
											<button type="submit" onClick={this.login} className="btn-signin sign-marg-no">Login</button>
										</div>
										<div class="row LostForgot">
											<div className="col-12 form-group">
												<a className="forcusror" onClick={this.changeView.bind(this,'lostpassword')}>Lost password?</a>
											</div>										
										</div>										
									</div>
								)														
							}
							{
								(displayType ==='lostpassword' &&
									<LostPassword changeView={this.changeView.bind(this,'login')} />
						 		)
							}
							</div>
				    	</div>
				    </div>
				</div>
				</div>
				
				<div class="LoginFooter">
					<div class="container-fluid">
						<div class="row">
							<p>Copyright © Association Studios. All Right Reserved.</p>
						</div>
					</div>
				</div>
				</div>
				
			
		);
    }
}
export default Login;
