import React, { Component } from "react";
import { Row, FormGroup, FormControl, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import { withRouter,Redirect,Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {PostData,GetData,DeleteData} from '../../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SidebarUpdate from '../../components/Sidebar';
import ItemData from '../project/ItemData';
import dateFormat from 'dateformat';
import BroadCast from '../project/BroadCast';
import { toast } from 'react-toastify';
class ProjectListstg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            itemPerPage:10,
            searchText: '',
            isdiagram:true,
            isMax:false,
            projectList: [],
            originalProjectList: [],
            activeitemid:'',
            activeitemtype:'',
            activeitem:'',
            displaytype:'project',
            itemdata:''
        };
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.projectDetails = this.projectDetails.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.setSize=this.setSize.bind(this);        
    }
    componentDidMount() {
        //let data = '';
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        console.log("userdata==",userdata);
        let data ={user_id:userdata.ID}
        PostData('orgprojects/'+this.props.orgid,data).then((result) => {             
            let responseJson = result;
            console.log('responseJson==',responseJson);
           // let fdata = responseJson.data.reverse();
            let fdata = responseJson.data;
            this.setState({
                projectList: fdata,
                originalProjectList: fdata
            });
        });        
    }
     handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        console.log(this.state.projectList);
    }

    projectDetails(item, index) {
        console.log(index);
    }
    deleteProject(item, index) {
        DeleteData('project/'+item._id).then((result) => {       
            //this.props.history.push("/organization/list");
            window.location.reload();
        });
    }
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    setSize(){
        this.setState({
            isMax:!this.state.isMax
        });             
        if(!this.state.isMax){
        }
        console.log(this.props.children);
        if(this.props.children[1].props['data-event']){
            var self=this;
            setTimeout(()=>{
                self.props.children[1].props['data-event'].call();  
            },700);         
        }      
    }    
 
    updateInputValue(event) {     
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalProjectList.length; i++) {
                    let orgname=this.state.originalProjectList[i].project_name;
                    orgname=orgname.toLowerCase();
                    textToSearch=textToSearch.toLowerCase();
                    if(orgname!=null)
                    {
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalProjectList[i]);
                        }
                    }
                }
                this.setState({
                    projectList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    projectList: this.state.originalProjectList, 
                });
            }
       });
    }
    changeView(displaytype){
        const count = Number(this.props.credits);
        //const count = 0
        console.log('count==',count);
        if (count<1) {
            //const linktext =""
            const linktext = "/"+this.props.orgid+"/order-videos";
            toast.error(<Link to={linktext}><u>Please click here to purchase video credits.</u></Link>, { autoClose: 10000 });
              console.log('count==',count);
        }
        else
        this.props.view(displaytype);
    }
    changeViewType(displaytype,url){
        this.setState({
            displaytype:'broadcast',
            itemdata:url,
        })
        //console.log('displaytype,url==',displaytype,url);
    }
    toggleActions(itemid,itemtype,item){
        console.log('itemid==',itemid,itemtype);
        this.setState({
            activeitemid:itemid,
            activeitemtype:itemtype,
            activeitem:item
        })
    }
    render() {
        let description='';
        let createprojectlink='';
        let projectlink='';
        let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let renderedProjects = this.state.projectList.slice(indexOfFirstTodo, indexOfLastTodo);
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        let productionteamlink='/org/'+this.props.orgid+'/teams';
        console.log('userdata====',userdata);
        const currentUser = userdata.role;
        if(currentUser==='administrator')
        {
            createprojectlink='/org/'+this.props.orgid+'/project/create';
        }
        else
        {
            createprojectlink='/subs/org/'+this.props.orgid+'/project/create'; 
        }        
        let listItems = renderedProjects.map((item, index) => {
        if(currentUser==='administrator')
        {
            projectlink="/org/"+item.org_id+"/project/view/"+item._id;
        }
        else
        {
            projectlink="/subs/org/"+item.org_id+"/project/view/"+item._id;
        }
        if(item.description){
            if (this.isJson(item.description))
            {
                const contentState = convertFromRaw(JSON.parse(item.description));
                const editorState = EditorState.createWithContent(contentState);
                const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
                console.log('block.text====',blocks);
                description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
            }
            else
            {
                if(item.description==='[object Object]')
                description= '';
            }
        }
        let isProjectComplete=(item.status==='Completed')?'':'disableaction'     
        console.log('isProjectComplete==',isProjectComplete,item.status);    
        return <tbody>
            <tr key={index}>
                <td className="tbl_org_name">    
                    {/* {item.status!='Completed' ? (
                                <Link to={projectlink}><u>{item.project_name}</u></Link>
                    )
                    : (
                        <a className="forcusror" onClick={this.changeViewType.bind(this,'broadcast',item.completedvideourl)} >{item.project_name}</a>
                    )}                 */}
                    <Link to={projectlink}><u>{item.project_name}</u></Link>
                </td>
                <td>
                    <ul className={'actionsicon '+isProjectComplete}>
                            <li><a className="forcusror" onClick={this.toggleActions.bind(this,item._id,'description',description)}><img src={require('../../assets/images/des.png')}/></a></li>
                            <li><a className="forcusror disable" onClick={this.toggleActions.bind(this,item._id,'video',item.completedvideourl)}><img src={require('../../assets/images/video.png')}/></a></li>
                            <li><a className="forcusror disable" onClick={this.toggleActions.bind(this,item._id,'download',description)}><img src={require('../../assets/images/down.png')}/></a></li>
                            <li><a className="forcusror disable" onClick={this.toggleActions.bind(this,item._id,'embeded',item.completedvideourl)}><img src={require('../../assets/images/embeded.png')}/></a></li>
                    </ul>
                    
                    
                </td>
                {/* <td className="tbl_org_description">{description}</td> */}
                <td className="tbl_org_duedate">{dateFormat(item.due_date, "mm/dd/yyyy")}</td>      
                <td className="tbl_org_duedate">{item.status}</td>           
                <td className="tbl_org_action TblIconDel"><i  className="fa fa-trash" aria-hidden="true" onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteProject(item, index) } } > <img src={require('../../assets/images/delete-icon.png')}/> </i></td> 
                
            </tr>
            { (this.state.activeitemid===item._id &&
                <tr>
                <td style={{width:"100%"}}>                            
                    <ItemData itemtype={this.state.activeitemtype} item={this.state.activeitem} />
                </td>
                 </tr>
            )
            }
            </tbody>
        });
        return (
             <div className="container-fluid ProjectList"  ref="selfdiv">
                 {    
                (this.state.displaytype==='project' &&
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-6 col-md-4 col-lg-4 ProSubHeadLeft">
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 mobileDisable">
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 ProSubHeadRight">
<a className="forcusror" onClick={this.changeView.bind(this,'createproject')} >+ Create Project</a>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            <table className="table table-striped ListTable">
                                <thead>
                                    <tr>
                                        <th className="tbl_org_name"><b>Project Name</b></th>
                                        <th className="tbl_org_action"><b>Actions</b></th>
                                        {/* <th className="tbl_org_description"><b>Description</b></th> */}
                                        <th className="tbl_org_duedate"><b>Due date</b></th>   
                                        <th className="tbl_org_duedate"><b>Status</b></th>                                 
                                        <th className="tbl_org_action"><b>Action</b></th>
                                    </tr>
                                </thead>                               
                                {listItems}                           
                            </table>
                        </div>
                        <div className="Mobrow">
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.projectList.length}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                )
                }
                {    
                    (this.state.displaytype==='broadcast' &&
                        <div className="team-list">  
                            <BroadCast item={this.state.itemdata}/> 
                        </div>
                    )
                }
            </div>                                
        );
    }
}
export default ProjectListstg;
