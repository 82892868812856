import React, { Component } from 'react';  
import { Route,Redirect } from 'react-router-dom'; 
const bodybg = {
  backgroundColor: '#ffffff',
};
const LandingPageLayout = ({children, ...rest}) => {  
  //console.log('cheildrennn=========',children);
  return (  
    <div>  
      <div className="ProjectBoard" style={bodybg}>
        <div className="container-fluid">
          <div className="row">             
            <div id="main" className="landingpages record_video">
              {children}
            </div>
          </div>
        </div>
      </div>      
    </div>  
  )  
}
const LandingPageLayoutRoute = ({component: Component, ...rest}) => {    
   let checkuser1 = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
    if(checkuser1 && checkuser1.user && (checkuser1.user.ID==15888 || checkuser1.user.ID==12612 || checkuser1.user.ID==28 || checkuser1.user.ID==202371015253))
    {
      localStorage.setItem('userData','');
      return (<Redirect to={'/'}/>)
    }
  return (  
    <Route {...rest} render={matchProps => (       
      <LandingPageLayout>  
          <Component {...matchProps} />  
      </LandingPageLayout>  
           
    )} />  
  )  
};    
export default LandingPageLayoutRoute; 