import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner'
import AddTeam from './AddTeam'
import {GetData,PostData} from '../../services/PostData';
import TaskList from '../profiledata/TaskList'
import TimeLogs from './TimeLogs'
import Select from "react-select";
import TimePicker from 'rc-time-picker';
import { Button } from 'react-bootstrap';
class ViewTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            itemPerPage:10,
            teamList: [],
            originalTeamList: [],
            loading: false,
            profile_image:'',
            f_name:'',
            l_name:'',
            email:'',
            phone:'',
            jobtitle:'',
            role:'',
            userId:'',
            displaytype:'tasklist',
            orgList:[],
            prjList:[],
            tskList:[],
            reportdata:[],
            thour: '',
            tminut: '', 
            fields: {},
            errors: {}, 
            selectedtsk:''
        };
       //this.onChange = this.onChange.bind(this);
       //this.getProfileData=this.getProfileData.bind(this);
    }
    onTaskHourChange = thour => this.setState({ thour })
    onTaskMinutChange = tminut => this.setState({ tminut })
    commentChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    componentDidMount() {
       // this.getProfileData();
         this.setState({ 
             loading: true,           
         });

         GetData('postproductionreport/1').then((result) => {             
            console.log('result.data=',result.data);
            this.setState({
                reportdata: result.data,                
            });
        });  
         GetData('user/'+this.props.match.params.id).then((result) => {     
          console.log('check result in edit page===',result);        
            this.setState({ 
              f_name: result.data.first_name,
              l_name: result.data.last_name,
              email: result.data.email,
              getpassword: result.data.password,
             // org_name: result.data.organization,
              jobtitle: result.data.job_title,
              twitter: result.data.twitter,
              linkedin: result.data.linkedin,
              phone: result.data.phone,
              notes: result.data.notes,
              username: result.data.username,
              hourlyrate: result.data.hourlyrate,
              loading: false,   
              password:'',   
              profile_image: result.data.profile_image,  
              role:result.data.role,
              userId:result.data.ID,
              
            });
        })

        GetData('organizations').then((result) => {             
            console.log('result.data=',result.data);
            this.setState({
                orgList: result.data,                
            });
        });   

        
        
}
   //getProfileData(){
       // this.setState({ 
        //    loading: true,           
       // });
       // GetData('orgprofile/'+this.props.orgid).then((result) => {             
       //     this.setState({ 
        //        loading: false,
         //       teamList: result.data.org_users,
         //       originalTeamList:result.data.org_users,
          //  });
       // });
  // }
   
   
   changeDisplayType(displaytype){
        console.log('displaytype=userid==',displaytype);
        this.setState({displaytype:displaytype});
    }
    onOrgSelect = (org) => {  
        console.log('e,org=',org);
        this.setState({
            selectedorg:org,
            prjList:[],
            tskList:[]
        })
        GetData('orgprojects/'+org.value).then((result) => {             
            console.log('result.data=',result.data);
            this.setState({
                prjList: result.data,                
            });
        }); 
    }

    onPrjSelect = (prj) =>{
       console.log('org=',prj); 
       this.setState({
            selectedprj:prj,
            tskList:[]
        })
        GetData('tasklist/'+prj.value).then((result) => {             
            console.log('result.data=',result.data);
            this.setState({
                tskList: result.data,                
            });
        }); 
    }

    onTskSelect = (tsk) =>{
       console.log('tsk=',tsk); 
       this.setState({
            selectedtsk:tsk,            
        })       
    }
    validateAddTaskTimeForm() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        if (fields["orgList"].length==0) {
            formIsValid = false;
            errors["selectedorg"] = "*Please select Customer";
        }
        if (fields["prjList"].length==0) {
            formIsValid = false;
            errors["selectedprj"] = "*Please select Project";
        }
        if (fields["tskList"].length==0) {
            formIsValid = false;
            errors["selectedtask"] = "*Please select Task";
        }
        if (!fields["thour"]) {
            formIsValid = false;
            errors["thour"] = "*Please select Hour";
        }
        if (!fields["tminut"]) {
            formIsValid = false;
            errors["tminut"] = "*Please select Minut";
        }
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    saveTaskTime(e) {
        if (this.validateAddTaskTimeForm()) {
            this.setState({ Inloading: true });
            // const taskid = this.state.selectedtask;
            const hour = this.state.thour.format('HH');
            const minut = this.state.tminut.format('mm');
            console.log('taskid with date time==',hour,minut);
            const finaltime = hour + ':' + minut;
            const teammeber = this.state.f_name+' '+this.state.l_name;
            const selectedorg = this.state.selectedorg;
            const selectedprj = this.state.selectedprj;
            const selectedtsk = this.state.selectedtsk;
            let data = {
                'taskid': selectedtsk.value,
                'orgid':  selectedorg.value,
                'projectid': selectedprj.value,
                'time': finaltime,
                'comment': this.state.tcomment,
                'userid':this.state.userId,
                'taskname':selectedtsk.label,
                'selectedassigneeuserid':this.state.userId,
                'selectedassigneeteammember':teammeber,
                'ishide':true,
            }
            //console.log('data',data);
            this.saveTaskdata(data);
        }
    }
    saveTaskdata(data)
    {
        this.setState({ Inloading: true });
        PostData('addtasktime', data).then((result) => {
            if (result.statusCode === 200) {                
                this.setState({ tasktimesucsmsg: "Time Added Succesfully", thour: '', tminut: '', tcomment: ''});
                setTimeout(() => {
                    this.setState({  tasktimesucsmsg: '' });
                    
                }, 3000);
            }
            else if (result.statusCode === 401) {
                this.setState({ tasktimeerrormsg: result.Message });
                setTimeout(() => this.setState({ tasktimeerrormsg: '' }), 3000);
            }
            this.setState({ Inloading: false });
        });
    }
    render() {       
        let imagesrc=''
        let weeklyhours='';
        let weeklyamount='';
          if(this.state.reportdata.length>0 && this.state.userId)
        {
            let userdata = this.state.reportdata.filter((user)=>{
                return user.userid==this.state.userId
            })
            console.log('hourlyrate=',this.state.hourlyrate);
            console.log('userdata=',userdata);
            console.log('this.state.userId=',this.state.userId);
            if(this.state.hourlyrate && userdata.length>0 && userdata[0].tasktotaltime)
            {
                weeklyhours = userdata[0].tasktotaltime;
                let rateperminute = parseInt(this.state.hourlyrate)/60;
                console.log('rateperminute=',rateperminute);
                let tasktime = weeklyhours.split(':');
                console.log('tasktime=',tasktime);
                let tottimeinminute = parseInt(tasktime[0])*60 + parseInt(tasktime[1]);
                console.log('tottimeinminute=',tottimeinminute);
                weeklyamount = tottimeinminute*rateperminute;
                console.log('weeklyamount=',weeklyamount);
            }
        }

        if(this.state.role)
        {
            if (this.state.role && this.state.role==='administrator')
            {
                imagesrc='../../../profile-image/'+this.state.profile_image;
            }
            else
            {
                imagesrc='../../../../../profile-image/'+this.state.profile_image;
            }
        }
        return (
             <div className="container-fluid ProAssetsList"  ref="selfdiv">
                 {
                    this.state.loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
                        
                            <div className="row MoPrologoLeft">
                                <div className="col-4 ProAssetsHead">
                                    <img  className="mr-3" src={imagesrc}/>
                                    <h4 class="PageTopTitle">{this.state.f_name} {this.state.l_name}</h4>
                                    <p>
                                        {this.state.phone}<br/>
                                        {this.state.jobtitle} <br/>
                                       
                                    </p>
                                </div> 
                                 <div className="col-8 ProAssetsHead">  
                                    <div className="row">
                                        <div className="col-4 "> 
                                            <div className="success">{this.state.tasktimesucsmsg}</div>
                                            <div className="errorMsg">{this.state.errors.selectedorg}</div>
                                            <div className="errorMsg">{this.state.errors.selectedprj}</div>
                                            <div className="errorMsg">{this.state.errors.selectedtask}</div>
                                            {
                                                this.state.orgList.length>0 &&
                                                 <Select
                                                    placeholder="Select Customer"
                                                    className="basic-single projectlist"
                                                    classNamePrefix="select"
                                                    //isClearable={isClearable}
                                                    options={this.state.orgList.map((org, i) => {
                                                    return { id: i, value: org._id, label: org.organization_name };                      
                                                    })}
                                                    multi={true}
                                                    onChange={entry => {
                                                       this.onOrgSelect(entry);
                                                    }}
                                                    
                                                />
                                            }
                                        </div>
                                         <div className="col-4 "> 
                                            {
                                                this.state.prjList.length>0 &&
                                                 <Select
                                                    placeholder="Select Project"
                                                    className="basic-single projectlist"
                                                    classNamePrefix="select"
                                                    
                                                    options={this.state.prjList.map((prj, i) => {
                                                    return { id: i, value: prj._id, label: prj.project_name };                      
                                                    })}
                                                    multi={true}
                                                    onChange={entry => {
                                                       this.onPrjSelect(entry);
                                                    }}
                                                    
                                                />
                                            }
                                         </div>
                                         <div className="col-4 "> 
                                            {
                                                this.state.tskList.length>0 &&
                                                 <Select
                                                    placeholder="Select Task"
                                                    className="basic-single tskList"
                                                    classNamePrefix="select"
                                                    
                                                    options={this.state.tskList.map((tsk, i) => {
                                                    return { id: i, value: tsk._id, label: tsk.assignment };                      
                                                    })}
                                                    multi={true}
                                                    onChange={entry => {
                                                       this.onTskSelect(entry);
                                                    }}
                                                    
                                                />
                                            }
                                         </div>
                                    </div><br/>
                                    {
                                    this.state.tskList.length>0 && this.state.selectedtsk &&
                                    <>
                                    <div className="row">
                                        <div className="col-2">
                                            <label> Addtime:</label>
                                        </div>
                                        <div className="col-2">
                                            <TimePicker placeholder="Select Hour" name="thour" value={this.state.thour} onChange={this.onTaskHourChange} showMinute={false} showSecond={false} />
                                            <div className="errorMsg">{this.state.errors.thour}</div>
                                        </div>
                                        <div className="col-2">
                                            <TimePicker placeholder="Select Minute" name="tminut" minuteStep={15} value={this.state.tminut} onChange={this.onTaskMinutChange} showHour={false} showSecond={false} />
                                            <div className="errorMsg">{this.state.errors.tminut}</div>
                                        </div>
                                        <div className="col-3">
                                            <input className="LP_field tcomment" value={this.state.tcomment} onChange={this.commentChange.bind(this)} name="tcomment" type="textarea" placeholder="Comment" />
                                        </div>
                                        <div className="col-3">
                                            <Button variant="primary" onClick={(e) => this.saveTaskTime(e)}>
                                                Save Changes
                                            </Button>
                                        </div>
                                    </div>
                                    </>
                                }
                                 </div>                      
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8 MobOrgProTab">
                                    <ul className="top-links">
                                        <li>
                                            <a className="forcusror"  onClick={this.changeDisplayType.bind(this,'tasklist') }>
                                            Tasks</a>  
                                        </li>
                                       
                                        <li> 
                                            <a className="forcusror" onClick={this.changeDisplayType.bind(this,'timelog')} >
                                            Time Log</a>                                            
                                        </li>
                                        
                                    </ul>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 MobOrgProbtns">
                                    {
                                        weeklyhours && weeklyamount &&
                                        <>
                                           
                                            <a  className='numofcredits'>Amount: {weeklyamount}</a>
                                            <a  className='numofcredits avail-hours'>Weekly Hours: {weeklyhours}</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">                        
                        {    
                            (this.state.displaytype==='tasklist' && this.state.userId && 
                                <div className="project-list">  
                                    <TaskList userid={this.state.userId} /> 
                                </div>
                            )
                        }

                        {    
                            (this.state.displaytype==='timelog' && this.state.userId && 
                                <div className="project-list">  
                                    <TimeLogs userid={this.state.userId} name={this.state.f_name+' '+this.state.l_name} /> 
                                </div>
                            )
                        }

                    </div>
                    
                </div>
            </div>
             
        );
    }
}
export default ViewTeam;
