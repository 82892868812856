import React, { Component } from "react";
import {GetData} from '../services/PostData';
import {apiConfig} from "../config/api"
import Loader from 'react-loader-spinner'
class DraftPage extends Component {
    constructor(props){
		super(props);
		this.state = {
			draftdata:'',
		};
	}
	componentDidMount() {
		this.setState({Inloading: true});
		const url = `showdraft/${this.props.match.params.draftid}`;
		GetData(url).then((result) => {   
			if(result.statusCode===200)
			{
				this.setState({Inloading: false,draftdata:result.data});	
			}
		});
	}
    render() {
		 const Inloading = this.state.Inloading;
     	let draftdata=this.state.draftdata;
		let draftvideo='';
		let draftvideosrc ='';
		if(draftdata.assetdata)
		draftvideosrc= draftdata.assetdata;
		else
		draftvideosrc= "../../../draft/"+draftdata.draftfile;	
		if(draftdata)  
		{  
			draftvideo = (
				<div className="row">
					<div class="ProjectRepeatTbl">
						<div className="vidoo-content">							
							<video data-title={draftdata.drafttitle} onClick={this.testClick}  controls width="70%" height="100%">
							<source src={draftvideosrc}
																	type="video/mp4" />
									Sorry, your browser doesn't support embedded videos.
							</video>
						</div>
					</div>
				</div>
			);
		}		
        return (
        	<div className="ProducerSaveDate AddStoryBoard draftpage">
				{
					Inloading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}						         
							/>
						</div>
					:null 
				} 
				
				<div className="clearfix"></div>
				{draftvideo}				
			</div>	
		);
    }
}
export default DraftPage;