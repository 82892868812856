import React, { Component } from 'react';
class Timelogs extends Component {

	constructor(){
        super();
	}
 	logoutHandler =(e) => {
		//sessionStorage.clear();
		//window.location.href = '/';
    }
    render() {
        return (
            <div>
                <div className="welcome-section">
                    <div className="container-fluid">
                     <h3>Time Logs</h3>
                    </div>         
                </div>
                <div className="production-section">
                   <div className="container-fluid">
                    <h2>Time Logs</h2>                 
                                  
                   </div>
                </div>                
            </div> 
        );
    }
} 
export default Timelogs;