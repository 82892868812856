import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {PostData,GetData,DeleteData} from '../../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
import UploadAssets from './UploadAssets';
import { apiConfig } from "../../config/api"
import Dropzone from 'react-dropzone-uploader'
import { Dropdown } from 'react-bootstrap';
import ShareLink from './ShareLink';
class InviteeAssets extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            activePage: 1,
            itemPerPage:10,
            searchText: '',
            isdiagram:true,
            isMax:false,
            projectList: [],
            originalProjectList: [],
            loading:false,
            boxfolderid:'',
            isInner:false,
            rootfolderid:'',
            breadcrumblist:[],
            selectedFile:[],
            showsharelink:false,
            sharefolderid:''
        };
        //this.handlePageChange = this.handlePageChange.bind(this);
        
        
        this.projectDetails = this.projectDetails.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        
    }
    componentDidMount() {        
        console.log('this. props=== callleddddddddddddddd');
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"));
        console.log('userdata==',userdata);
        if(userdata.data.fileid)
        {
            console.log('userdata.fileid===',userdata.data.fileid);
            this.getFolderListByEmail(userdata.loggedinemail);
            this.getbreadcrumb(userdata.data.fileid);
        }               
    }
    getbreadcrumb(folderid){
        GetData('getbreadcrumb/'+folderid).then((result) => {   
            if(result.statusCode===200)   
            {
                this.setState({breadcrumblist:result.data});                   
            }              
        }); 
    }
    getFolderListByEmail(email)
    {
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"));
        //this.getFolderListByFolderId(email,'email')
        let data = {
            'email': email, 
            'fileid': userdata.data.fileid      
        } 
        
        console.log('caleddddddddddddddddddd in props');
        this.setState({loading:true});        
        PostData('inviteeboxassetsbyemail', data).then((result) => {                  
            if(result.statusCode===200)
            {
                console.log('responseJson==',result);
                this.setState({
                    activePage: 1,
                    projectList: result.data,
                    originalProjectList: result.data,
                    loading:false
                });
            }
        }); 
    }
    getFolderListByFolderId(folderid)
    {
        let data = {
            'boxfolderid': folderid,            
        }
        this.setState({boxfolderid:folderid});
        this.setState({loading:true});        
        PostData('inviteeboxassetsbyfolderid', data).then((result) => {                  
            if(result.statusCode===200)
            {
                console.log('responseJson==',result);
                this.setState({
                    activePage: 1,
                    projectList: result.data,
                    originalProjectList: result.data,
                    loading:false
                });
            }
        });  
        //this.getbreadcrumb(folderid)
    }
     handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        console.log(this.state.projectList);
    }

    projectDetails(item, index) {
        console.log(index);
    }
    deleteProject(item, index) {
        DeleteData('project/'+item._id).then((result) => {       
            //this.props.history.push("/organization/list");
            window.location.reload();
        });
    }
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalProjectList.length; i++) {
                    let orgname=this.state.originalProjectList[i].filename;
                    orgname=orgname.toLowerCase();
                    textToSearch=textToSearch.toLowerCase();
                    if(orgname!=null)
                    {
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalProjectList[i]);
                        }
                    }
                }
                this.setState({
                    projectList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    projectList: this.state.originalProjectList, 
                });
            }
       });
    }
    changeView(displaytype){
        this.props.view(displaytype);
    }
    handleClick(folderid){
        this.setState({
            isInner:true,
            boxfolderid:folderid
        });
        this.getFolderListByFolderId(folderid);
    }
    redirectToNewPage(){
        //window.location.assign('https://www.pakainfo.com');
        let data = {
            'orgid': this.props.match.params.orgid,
            'folderid': this.state.boxfolderid,
            //'userid': userdata.user.ID,
            //'password':this.state.password
        }
        console.log('data===',data);            
        PostData('createurlfrominvitee', data).then((result) => {
            console.log('result===',result);
            if (result.statusCode === 200) {
                this.setState({
                    slinkid:result.data._id,
                    folderid:result.data.folderid
                }); 
                if(result.data._id)
                {
                   //let savedlink='http://localhost:3000/invitee/'+this.props.match.params.orgid+'/'+this.state.boxfolderid+'/createvideo/'+result.data._id; //Local
                    let savedlink='https://vpma.associationstudios.com/invitee/'+this.props.match.params.orgid+'/'+this.state.boxfolderid+'/createvideo/'+result.data._id; //live
                    window.open(savedlink, "_blank"); 
                }
            }
        });
       
    }
    backToRootFolder(folderid){
        this.setState({
            isInner:false,
            boxfolderid:folderid
        });
        this.getFolderListByFolderId(folderid);
    }
    backToInnerFolder(folderid){
        this.setState({            
            boxfolderid:folderid
        });
        //this.getFolderListByFolderId(this.state.boxfolderid);
        this.getFolderListByFolderId(folderid);
    }
    loadFolderList(){
        this.getFolderListByFolderId(this.state.boxfolderid);
    }
    setAddAssetShow(e){
        let currdate=Date.now();
        this.setState({ setAddAssetShow: true,isAddAsset:currdate });
    }
    onAssetfileChangeHandler = event => {
        this.setState({
            'assetfile': event.target.files[0],
            loaded: 0,
        })
    }
    handleChangeStatus = ({ meta, file }, status) => { 
        console.log('selectedFile in 192===',this.state.selectedFile);
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
    }
    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    } 
    handleSubmit = (files, allFiles) => {
        var loadermessage='Uploading';
        allFiles.forEach((f) => {

        // allFiles.forEach(f =>
            var mimetype= f.file.type;
            var checkvideo = mimetype.split('/');
            if(mimetype!='video/mp4' && checkvideo[0]=='video')
            {
                        loadermessage='Uploading and converting file in mp4'
            }
            else
            {
                console.log('in elsee f=============',f)
            }
        })
        //if(this.checkFolderID()){
        this.setState({assetuploadloader:true,assetuploadmessage:loadermessage});
        var formdata = new FormData();
        const selectedfiles=this.state.selectedFile;
        console.log('selectedfiles===',selectedfiles);
        var uniqueArray = this.removeDuplicates(selectedfiles, "name");
        for(var x = 0; x<uniqueArray.length; x++) {
            formdata.append('boxfile', uniqueArray[x])
        }
        uniqueArray=[];
        this.setState(prevState => ({
            selectedFile: [[], []]
        }))
        let newState = this. state;
        delete newState['selectedFile'];
        this.setState(newState);
        this.setState({selectedFile:[]});
        //formdata.append('title', 'test');
        //fileid
        formdata.append('folderid', this.state.boxfolderid) 
        //formdata.append('folderid', this.state.boxfolder_name)    
        //let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
        let session_key = '';
        fetch(apiConfig.WEB_SERVICE_URL+'fileupload', {
            method: 'POST',
            body: formdata,
            headers: {
                    "session-key": session_key,
                }
        })
        .then((response) => response.json())
        .then((res) => {
            console.log('cakkkeddd',res);
            if(res.statusCode===200)
            {               
                this.setState({
                    assetuploadsuccessmsg:res.message,
                    assetuploadloader:false
                });     
                setTimeout(() => {
                    this.setState({ assetuploadsuccessmsg: '',setAddAssetShow: false,selectedFile:[] })
                    this.getFolderListByFolderId(this.state.boxfolderid);
                    //this.props.loadFolderList();
                    allFiles.forEach(f => f.remove())
                    window.location.reload();
                }, 3000);       
            }
        });   
        //}       
    }   
    shareLink(sharefolderid,foldername){
        console.log('calledd in showsharelink function',sharefolderid,foldername);
        this.setState({ showsharelink: true,sharefolderid:sharefolderid,foldername:foldername });
    }
    handleclose(){
        this.setState({ showsharelink: false });
    }
    render() {        
        let breadcrumblistdata='';
        let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let renderedProjects = this.state.projectList.slice(indexOfFirstTodo, indexOfLastTodo);
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }     
        let usertype='';
        //if (userdata.role && userdata.role!='administrator')
       // usertype='/subs'       
        let listItems = renderedProjects.map((item, index) => {       
            let name;
            if (item.filetype==='folder') {
                name = <a onClick={this.handleClick.bind(this,item.fileid)} className="forcusror">{item.filename}</a>;
            } else {
                name = <Link to={`/invitee/${this.props.match.params.orgid}/${item.fileid}/editfile`}>{item.filename}</Link>;               
            }
            return <tr key={index}>
                <td className="tbl_org_name">                   
                    {name}
                </td>
                <td className="tbl_org_description">{item.description}</td>
                <td className="tbl_org_duedate">{dateFormat(item.created_at, "mm/dd/yyyy")}</td>      
                <td className="tbl_org_duedate">{dateFormat(item.modefied_at, "mm/dd/yyyy")}</td>  
                <td className="tbl_org_description">
                    <ul className="actionsitem">
                        {/* <li><a onClick={this.shareLink.bind(this,item.fileid,item.filename)}><img src={require('../../assets/images/assets-icon4.png')}/></a></li> */}
                    </ul>    
                -</td>           
                {/* <td className="tbl_org_action TblIconDel"><i  className="fa fa-trash" aria-hidden="true" onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteProject(item, index) } } > <img src={require('../../assets/images/delete-icon.png')}/> </i></td>  */}     
            </tr>
        });
        breadcrumblistdata = this.state.breadcrumblist.map((item, i) => {
            //const folderslist = this.state.folderlist.map(function(item){
                if(i===0 && item.filetype==='folder' && item.parentid==='')
                return <li> 
                        <a className="forcusror">All Files</a>
                        {/* <Link to={`/${this.props.match.params.orgid}/assets/`}>All Files  </Link>            */}
                </li>;
                else if(i===0 && item.filetype==='folder')
                return <li> 
                        {item.filename}                     
                    </li>;
                else if(item.filetype==='folder' && item.parentid!='')
                return <li> 
                        <a className="forcusror" onClick={this.backToInnerFolder.bind(this,`${item.fileid}`)} >{item.filename} > </a>
                        {/* <Link to={
                             { 
                                pathname: `/${this.props.match.params.orgid}/assets/`,
                                folderid: `${item.fileid}`
                            }}
                            >{item.filename} > </Link>                       */}
                </li>;
                else if(item.filetype==='file' && item.parentid!='')
                return <li> 
                        {item.filename}                     
                </li>;
                else
                return <li> 
                        <a className="forcusror" onClick={this.backToRootFolder.bind(this,this.state.rootfolderid)} >All Files > </a> 
                </li>;
        }).reverse();
        return (
             <div className="container-fluid ProjectList"  ref="selfdiv">
                 {
                    this.state.loading ?
                        <div className="loaderspinner">
                            <Loader
                                type="TailSpin"
                                color="#D10000"
                                height={100}
                                width={100}
                            />
                        </div>
                        : null
                }
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-12 ProSubHeadLeft">
                            { 
                                    <div className="col-6 UploadBreadcrumb">
                                        {/* <a className="forcusror" onClick={this.backToRootFolder.bind(this,this.state.rootfolderid)} >Back To Root Folder</a> */}
                                        {/* <ul className="breadcrumblist">{breadcrumblistdata} </ul>    */}
                                        Assets
                                    </div>
                                
                                }
                                {   ( this.state.boxfolderid &&
                                <div className="col-6 SearchFolder">
                                
                                        {/* <Link to={`${usertype}/${this.props.match.params.orgid}/${this.state.boxfolderid}/createfolder`}><img src={require('../../assets/images/create-folder-icon.png')}/></Link> */}
                                   
                                <a className="aforcusror" onClick={this.setAddAssetShow.bind(this)} >Upload File</a>
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                                {/* <Dropdown>
                                    <Dropdown.Toggle variant="success1" id="dropdown-basic">
                                        Action
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to={`/invitee${usertype}/${this.props.match.params.orgid}/${this.state.boxfolderid}/createlink`}>Make External</Link>                         
                                    </Dropdown.Menu>
                                </Dropdown>   */}
                                </div>
                                 )
                                }
                            </div>
                        </div>
                        {   ( this.state.boxfolderid &&    
                        <div className="row DragFileRow InviteSS">
                            <div className="col-6 DragFileBox mobileDisable LeftBoxalign">
                                <div className="successmsg">{this.state.assetuploadsuccessmsg}</div>                
                                                            
                            {/* <div class="UploadAssetsIcons">
                                <img src={require('../../assets/images/upload-file-icon.png')}/>
                                <h4>Drag and drop anywhere to upload</h4>
                                <a href=""><img src={require('../../assets/images/upload-btn-icon.png')}/> <span>Or choose files</span> </a>
                            </div> */}
                                {
                                    this.state.assetuploadloader ?
                                        <div className="loaderspinner">
                                            <Loader
                                                type="TailSpin"
                                                color="#D10000"
                                                height={100}
                                                width={100}                              
                                            />
                                            <div className="loadermessage">{this.state.assetuploadmessage}</div>
                                        </div>
                                    :null      
                                }     
                                <Dropzone    
                                    //getUploadParams={getUploadParams}
                                    onChangeStatus={this.handleChangeStatus}
                                    onSubmit={this.handleSubmit}
                                    //accept="image/*,video/*"
                                    />                                 
                               
                            </div>
                            <div className="col-6 DragFileBox mobileDisable RightBoxalign">                                 
                                <a onClick={this.redirectToNewPage.bind(this)} className="recordvideo">Click to Record Video</a>                 
                            </div>  
                            <div className="beta_label beta2_label"> <img src={require('../../assets/images/beta_banner_label.png')}/> </div>                                       
                        </div>
                        )
                        }
                    </div>
                    <div className="panel-body">
                        <div className="row">
                        <table className="table table-striped ListTable">
                            <thead>
                                <tr>   
                                    <th className="tbl_org_name"><b>Folder Name</b></th>
                                    <th className="tbl_org_
                                    description"><b>Description</b></th>
                                    <th className="tbl_org_description"><b>Created Date</b></th>
                                    <th className="tbl_org_duedate"><b>Last Updated</b></th>                                    
                                    <th className="tbl_org_action"><b>Action</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {listItems}                               
                            </tbody>
                        </table>
                    </div>
                    <div className="Mobrow">
                        {
                            (this.state.projectList.length>0 &&
                            <Pagination
                                activePage={this.state.activePage} 
                                itemsCountPerPage={10}
                                totalItemsCount={this.state.projectList.length}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                            />
                            )
                        }
                    </div>
                    {
                        (this.state.setAddAssetShow &&
                            <UploadAssets loadFolderList={this.loadFolderList.bind(this)} orgid={this.props.match.params.orgid} prjid={this.props.match.params.prjid} isAddAsset={this.state.isAddAsset}  handleAddAssetShow={this.state.setAddAssetShow} userid={this.state.userid} boxfolderid={this.state.boxfolderid} />
                        )
                    }
                    {
                        (this.state.showsharelink &&
                            <ShareLink orgid={this.props.match.params.orgid} boxfolderid={this.state.sharefolderid} foldername={this.state.foldername} showshareLink={this.state.showsharelink} popupclose={this.handleclose.bind(this)} />
                        )
                    }
                    </div>
                </div>
            </div>
        );
    }
}
export default InviteeAssets