import React, { Component } from "react";
import {PostData,GetData} from '../../services/PostData';
import Loader from 'react-loader-spinner'
import { apiConfig } from "../../config/api"
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader'
class UploadAssets extends Component {
    constructor(props){
		super(props);
		this.state = {
			boxfolderid:this.props.boxfolderid,
			fields: {},
			errors: {},	
			setAddAssetShow:this.props.handleAddAssetShow,		
			selectedFile:[],
		};		
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isAddAsset !== this.props.isAddAsset) {
		 	this.setState({setAddAssetShow:this.props.isAddAsset})		
		}
	}	
	handleAddAssetClose(e) {
		this.setState({ setAddAssetShow: false });
	}	
	onAssetfileChangeHandler = event => {
		this.setState({
			'assetfile': event.target.files[0],
			loaded: 0,
		})
	}
	handleChangeStatus = ({ meta, file }, status) => { 
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
	}
	removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    } 
	handleSubmit = (files) => {
		//if(this.checkFolderID()){
			this.setState({assetuploadloader:true});
			var formdata = new FormData();
			const selectedfiles=this.state.selectedFile;
			var uniqueArray = this.removeDuplicates(selectedfiles, "name");
			for(var x = 0; x<uniqueArray.length; x++) {
				formdata.append('boxfile', uniqueArray[x])
			}
			//formdata.append('title', 'test');
			//fileid
			formdata.append('folderid', this.state.boxfolderid)	
			//formdata.append('folderid', this.state.boxfolder_name)	
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL+'fileupload', {
				method: 'POST',
				body: formdata,
				headers: {
						"session-key": session_key,
					}
			})
			.then((response) => response.json())
			.then((res) => {
				console.log('cakkkeddd',res);
				if(res.statusCode===200)
				{				
					this.setState({
						assetuploadsuccessmsg:res.message,
						assetuploadloader:false
					});		
					setTimeout(() => {
						this.setState({ assetuploadsuccessmsg: '',setAddAssetShow: false })
						this.props.loadFolderList();
					}, 3000);	    
				}
			});   
		//}       
	}	
    render() {
		console.log('called every time');
		const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }		
        return (
        	<Modal show={this.state.setAddAssetShow}>	
				{
					this.state.Inloading ?
						<div className="loadercenter">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
					: null
				}			
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Add Asset</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.assetuploadsuccessmsg}</div>					
					<div className="row">								
						{/* <div class="UploadAssetsIcons">
							<img src={require('../../assets/images/upload-file-icon.png')}/>
							<h4>Drag and drop anywhere to upload</h4>
							<a href=""><img src={require('../../assets/images/upload-btn-icon.png')}/> <span>Or choose files</span> </a>
						</div> */}
							{
								this.state.assetuploadloader ?
									<div className="loaderspinner">
										<Loader
											type="TailSpin"
											color="#D10000"
											height={100}
											width={100}						         
										/>
									</div>
								:null      
							}     
							<Dropzone    
								getUploadParams={getUploadParams}
								onChangeStatus={this.handleChangeStatus}
								onSubmit={this.handleSubmit}
								accept="image/*,video/*"
								/>
					</div>
					{/* <div className="row">
						<div className="col-12">
							<div className="errormsg">{this.state.errors.assetfile}</div>
							<input type="file" name="assetfile" onChange={this.onAssetfileChangeHandler.bind(this)} className="form-control assetfile" autoFocus />
						</div>
					</div> */}
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddAssetClose.bind(this)}>
						X
					</Button>
					{/* <Button variant="primary" onClick={(e) => this.saveAsset(e)}>
						Save Changes
					</Button> */}
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default UploadAssets;