import React, { Component } from "react";
import { apiConfig } from "../../../config/api"
import Loader from 'react-loader-spinner'
class UploadReceipt extends Component {
    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},		
			amount: '',
			receiptfile: '',
			description: '',
			receiptsuccessmsg: '',
		};


	}
	componentDidUpdate(prevProps) {
		if (prevProps.ischatedit !== this.props.ischatedit && this.props.editedboardid) {
			this.setState({editorState:this.props.editorState,iseditedchat:true,editedboardid:this.props.editedboardid})		
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	onScriptfileChangeHandler = event => {
		this.setState({
			'receiptfile': event.target.files[0],
			loaded: 0,
		})
	}
	
	saveReceiptData(e) {
		console.log("calledddddddddd in script  ");	
		this.uploadReceiptData('uploadreceipt', 'uploadreceipt');	
	}
	uploadReceiptData(action, type) {
		this.setState({ saveBoardDataloader: true });
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		const data = new FormData();
		data.append('org_id', this.props.orgid)
		data.append('projectid', this.props.prjid)
		data.append('amount', this.state.amount)
		data.append('description', this.state.description)
		data.append('receiptfile', this.state.receiptfile)
		data.append('type', type)
		data.append('userid', userdata.user.ID)
		let session_key = userdata.token;
		fetch(apiConfig.WEB_SERVICE_URL + action, {
			method: 'POST',
			body: data,
			headers: {
				"session-key": session_key,
			}
		})
		.then((response) => response.json())
		.then((res) => {
			if (res.statuscode === 200) {
				this.setState({ receiptsuccessmsg: res.message, description: '', receiptfile: '', amount: '', saveBoardDataloader: false, data: [] });
				setTimeout(() => {
					this.setState({ receiptsuccessmsg: '' })
					this.props.loadProjectBoard();
				}, 3000);
				const file = document.querySelector('.scriptfile');
				file.value = '';
			}
		});
	}
	validateScriptDataFormAdmin() {
		//const hour=this.state.hour;
		//const minut=this.state.minut;
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["description"]) {
			formIsValid = false;
			errors["description"] = "*Please Enetr Description";
		}
		if (!fields["amount"]) {
			formIsValid = false;
			errors["amount"] = "*Please Enter Amount";
		}
		

		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	
    render() {
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;

        return (
        	<div className="ProducerSaveDate AddUploadScript">
				<img className="SD_img_icon" src={'../../../../../profile-image/'+this.props.avatar} />
				{
					this.state.saveBoardDataloader ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<h3 class="AddTitleDrop">Upload a Receipt</h3>
				<div className="clearfix"></div>
				<div className="succes-msg">{this.state.receiptsuccessmsg}</div>
				<div className="row LP_Row">
					<div className="col-md-12 LeftLocation">
						<input className="LP_field" type="text" value={this.state.description} onChange={this.onChange.bind(this)} name="description" placeholder="Description" />
						<div className="errorMsg">{this.state.errors.description}</div>
					</div>
					<div className="clearfix"></div>
					<div className="col-12 col-sm-4 col-md-3 col-lg-3">
						<div className="dropdown">
							<input type="file" name="receiptfile" onChange={this.onScriptfileChangeHandler.bind(this)} className="form-control scriptfile" autoFocus />
							<div className="errorMsg">{this.state.errors.receiptfile}</div>
						</div>
					</div>
					{userdata.role && userdata.role=='administrator' &&
						<div className="col-12 col-sm-4 col-md-5 col-lg-3 AddTimeBoxes">
							<input className="LP_field" type="text" value={this.state.amount} onChange={this.onChange.bind(this)} name="amount" placeholder="Amount" />
							<div className="errorMsg">{this.state.errors.amount}</div>
						</div>
					}
					<div className="col-12 col-sm-4 col-md-3 col-lg-3">
						<div className="text-left">
							<button type="button" onClick={(e) => this.saveReceiptData(e)} className="UpdateBtn btn btn-primary">Save</button>
						</div>
					</div>
				</div>
				<br />
				<hr />
				
			</div>	
		);
    }
}
export default UploadReceipt;