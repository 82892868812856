import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import {PostData} from '../../services/PostData';
import ReactTable from "react-table";
import "react-table/react-table.css";  
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
import Select from "react-select";
class TaskList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: [],
            loading:false,
            data:[],
            isClearable: true,
        }
        console.log('loggedInUserId==',this.props.userid);
    }
    componentDidMount() {
        this.setState({loading:true, });	
        let data = {
            'userid': this.props.userid,
        }
        PostData('employeetask', data).then((result) => {
            if (result.statuscode === 200) {

                this.setState({
                    data: result.data,
                    loading:false,
                });	
            }
        });
        this.onFilteredChangeCustom('Active','status');
    }
    UpdateTaskStatus = (event) => {
        let status = event.target.value;
        let taskid = event.target.dataset.id;
        console.log('taskid',taskid);
        let data = {
            'taskstatus': status,
            'taskid': taskid
        }
        
        PostData('updatetaskstatus', data).then((result) => {
            if (result.statuscode === 200) {
                window.location.reload(true);
                console.log('success');
            }
        });
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
                if (value === "" || !value.length) filtered.splice(i, 1);
                else filter["value"] = value;
                insertNewFilter = 0;
            }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    };
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    
    render() {
        
            const columns = [
                {
                    Header: 'Taskid',
                    accessor: '_id',
                    show:false
                },
                {
                    Header: 'ID',
                    accessor: 'project_id',
                    show:false
                },
                {
                    Header: 'OrgID',
                    accessor: 'org_id',
                    show:false
                },
                {
                    Header: 'Task',
                    accessor: 'assignment',
                    Cell: ({ row }) => (<Link to={{ pathname: `/org/${row.org_id}/project/view/${row.project_id}` }}>{row.assignment}</Link>)
                },   
                {
                    Header: 'Project',
                    accessor: 'project',         
                }, 
                {
                    Header: 'Organization',
                    accessor: 'organization',         
                },
                {
                    Header: 'Status',
                    accessor: 'status', 
                    Cell:({row}) =>{
                        return(<select name="taskstatus" data-id={row._id} onChange={this.UpdateTaskStatus}>
                            <option value={row.status}>{row.status}</option>
                                {
                                    row.status === 'Active' ? (
                                        <option value='Completed' >Completed</option>
                                    ):
                                    (
                                        <option value='Active'>Active</option>  
                                    )
                                }
                        </select>)
                    }        
                },                    
                {
                    Header: 'Due Date',
                    accessor: 'due_date',
                    Cell: ({ row }) =>{ 
                        return(<p>{dateFormat(row.due_date, "mm/dd/yyyy")}</p>)
                    }
                },
            ];            
            const data=this.state.data;
            //console.log('data',data);
            let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
            const name=userdataforcheck.firstname+' '+userdataforcheck.lastname;
            const avatar=userdataforcheck.avatar;
            const username=userdataforcheck.username;
            const jobtitle=userdataforcheck.jobtitle;
            const description=userdataforcheck.description;
            const isClearable= this.state.isClearable;
            return (
			 <div className="container-fluid TaskListPage"  ref="selfdiv">
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	}
                <div className="panel panel-primary">                   
                    <div className="panel-body">  
                        <div className="row">
                            <div className="col-6">
                                                          
                            </div>
                            <div className="col-6">
                                <Select
                                    defaultValue={{ label: "Active", value: "Active" }}
                                    placeholder="Status"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={isClearable}
                                    options={this.uniqueOptions(this.state.data, "status").map((status, i) => {
                                        return { id: i, value: status, label: status };
                                    })}
                                    multi={true}
                                    onChange={entry => {
                                        if(!entry)
                                        {
                                            entry={id: '', value: '', label: ''};
                                        }
                                        this.setState({ select2: entry });
                                        this.onFilteredChangeCustom(
                                        // entry.map(o => {
                                            
                                            //return
                                                entry.value,
                                        //}),
                                        "status"
                                        );
                                    }}
                                />
                            </div>
                        </div>  
                        {   
                            (data.length>0) &&                
                            <ReactTable
                                data={data}
                                filtered={this.state.filtered}
                                minRows = {0}
                                pageSizeOptions = {[10,50,100,500,1000,2000]}
                                defaultPageSize = {10}                             
                                onFilteredChange={(filtered, column, value) => {
                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                }}
                                defaultSorted={[{ id: "due_date", desc: false }]}
                               // defaultFiltered={[{ id: "due_date", value: due_date }]}
                               
                                columns={columns}
                                className="-striped -highlight"
                            />                            
                        }
                     </div>
                </div>
            </div>
		);
    }
}
export default TaskList;
