import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import dateFormat from 'dateformat';
class UpdateTask extends Component {
    constructor(props){
		super(props);
	}	
	backToProjectBoard() {
		this.props.backToProjectBoard();
	}	
	editTask(taskid) {
		this.props.editTask(taskid);
	}
	addTimeForTask(taskid) {
		this.props.addTimeForTask(taskid);
	}
    render() {
		console.log('calleddddd=',this.props.taskdata);
		let pboard='';
		let taskdata=this.props.taskdata;
		let userdatatocheckusertype = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		if(taskdata)
		{
			pboard = taskdata.map((item) => {
				return <li className={"itemlist task"}>
					<div className="row EditTaskLink">
						{(() => {
							if(userdatatocheckusertype.capabilities && userdatatocheckusertype.capabilities.administrator)
							{
								return (
									<div>
									<a onClick={this.editTask.bind(this, item._id)} className="edittask"><img src={require('../../../assets/images/edit-icon.png')} /></a>
									<a className="TaskTime" onClick={this.addTimeForTask.bind(this, item._id)} ><img src={require('../../../assets/images/timelog.png')} /></a>
									</div>
								)
							}
						})()}
						<div className="col-12 ChatBoxDesign">
							<div className="ChatImgThumb">
								<img src={item.userdata[0].avtar} />
								
							</div>
							<div className="type">{item.userdata[0].first_name} {item.userdata[0].last_name}</div>
							<div className="PB_create_date"><label>Date: </label>{dateFormat(item.create_date, "mmm d, yyyy")}
							</div>
							{(() => {

								return (
									<div className="row">
										<div class="PB_save_date_row">
											<p><strong>Assigned: </strong> {item.teammember}  |  <strong>Assignment: </strong> {item.assignment}  |  <strong>Task Due Date: </strong> {dateFormat(item.due_date, "mmmm dS, yyyy")} </p>
										</div>
									</div>
								)
							})()}
						</div>
					</div>
				</li>

			})
		}
        return (
        	<div class="ParentUpdate">
				<div class="PrUpdateTaskHead">
					<h3 className="page-title">Update Task</h3>
					<a href="#" className="backtoprojectoard" onClick={this.backToProjectBoard.bind(this)}>Back To ProjectBoard</a>
				</div>
				<div class="clear"></div>
				<ul className="page-description">
					{pboard}
				</ul>
			</div>
		);
    }
}
export default UpdateTask;