import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import dateFormat from 'dateformat';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { GetData,PostData } from '../../../services/PostData';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
class DraftList extends Component {
    constructor(props){
		super(props);
		this.state={
			draftstatusloading: false,
			setConfirmMsgShow:false,
			commentdata:this.props.commentdata,
		}
		this.onPlay = this.onPlay.bind(this);
		this.onPause = this.onPause.bind(this);
	}	
	editTask(taskid) {
		this.props.editTask(taskid);
	}
	addTimeForTask(taskid) {
		this.props.addTimeForTask(taskid);
	}
	showConfirmMsgBox(e){
		console.log('e.currentTarget.dataset.videotitle==',e.currentTarget.dataset.videotitle);
		this.setState({
			setConfirmMsgShow:true,
			editeddraftid:e.currentTarget.dataset.id,
			editeddraftvideoname: e.currentTarget.dataset.videoname,
			editeddrafttitle:e.currentTarget.dataset.videotitle,
		});
	}
	handleCheckBox(e){
		this.setState({
			isChecked: !this.state.isChecked,
		  });
	}
	markAsComplete(e) {
		if (!this.state.isChecked) {
			this.setState({
				markascompleteerror:'Please check CheckBox for Mark As Complete'
			});			
		}
		else
		{
			this.setState({
				setConfirmMsgShow:false,
				draftstatusloading: true,
			});
			let data = {
				'draftid': this.state.editeddraftid,
				'draftvideoname': this.state.editeddraftvideoname,
				'draftvideotitle':this.state.editeddrafttitle
			}
			PostData('draftvideocomplete', data).then((result) => {
				if (result.statusCode === 200) {
					this.setState({
						draftstatusloading: false,
						draftstatus: true,
						draftidcompleted:result.data._id ,
						editedprojectstatus:'Completed'
					});	
				}
			});
		}
	}
	handleConfirmMsgBoxClose(e) {
		this.setState({
			setConfirmMsgShow: false,
			editeddraftid:'',
			editeddraftvideoname: '',
			editeddrafttitle:''
		});
	}
	onPlay(evt) {
		let title = evt.target.getAttribute('data-title')
		console.log("video start play==", title, 'currentTime==', evt.currentTime);
	}
	onPause(evt) {
		let title = evt.target.getAttribute('data-title');
		let draftid = evt.target.getAttribute('data-draftid');
		let currenttime = this.getTimeInHourMinuitSecond(evt.currentTarget.currentTime);
		console.log("video start pasue==", title,'draftidd==',draftid, 'currentTime==', evt.currentTarget.currentTime, currenttime);
		// this.setState({
		// 	setDraftCommentShow:true,
		// 	drafttime:currenttime,
		// 	editeddraftidforcomment:draftid
		// });
	}
	videoClick(evt){		
		let title = evt.target.getAttribute('data-title');
		let draftid = evt.target.getAttribute('data-draftid');
		let currenttime = this.getTimeInHourMinuitSecond(evt.currentTarget.currentTime);
		//console.log("draftid=",draftid);
		var video = document.getElementById(draftid);
		var isPaused = video.paused;		
		if(!isPaused)
		{
			this.setState({
				setDraftCommentShow:true,
				drafttime:currenttime,
				editeddraftidforcomment:draftid
			});
		}
	}
	handleDraftCommentClose(e){
		this.setState({
			setDraftCommentShow:false
		});
		var videoPlayer = document.getElementById(this.state.editeddraftidforcomment);
		//videoPlayer.play()
	}
	draftCommentChange(e){
		this.setState({ [e.target.name]: e.target.value });
	}
	saveDraftComment(e){
		if (!this.state.draftcomment) {
			this.setState({
				draftcommenterror:'Please add your Comment'
			});			
		}
		else
		{
			var videoPlayer = document.getElementById(this.state.editeddraftidforcomment);
			// Auto play, half volume.
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			//console.log('userdata==',userdata);
			let username=userdata.user.firstname+" "+userdata.user.lastname
			//console.log('username==',username); 
			let data = {
				boardid: this.state.editeddraftidforcomment,
				projectid: this.props.prjid,
				orgid: this.props.orgid,
				userid: userdata.user.ID,
				videotime:this.state.drafttime,
				comment:this.state.draftcomment,
				user:username
			}
			//console.log('called data',data);
			PostData('savedraftcomment', data).then((result) => {
				if (result.statuscode === 200) {
					this.setState({
						commentdata: [...this.state.commentdata, result.data],
						setDraftCommentShow:false,
						draftcomment:'',
						editedboardid:result.data.boardid
					});	
					videoPlayer.play()
				}
			});
		}
	}
	getTimeInHourMinuitSecond(timenow) {
		if (parseInt(timenow) / 60 >= 1) {
			var h = Math.floor(timenow / 3600);
			timenow = timenow - h * 3600;
			var m = Math.floor(timenow / 60);
			var s = Math.floor(timenow % 60);
			if (h.toString().length < 2) { h = '0' + h; }
			if (m.toString().length < 2) { m = '0' + m; }
			if (s.toString().length < 2) { s = '0' + s; }
			timenow = h + ' : ' + m + ' : ' + s;
		} else {
			var m = Math.floor(timenow / 60);
			var s = Math.floor(timenow % 60);
			if (m.toString().length < 2) { m = '0' + m; }
			if (s.toString().length < 2) { s = '0' + s; }
			timenow = m + ' : ' + s;
		}
		return timenow;
	}
    render() {
		let currentuserid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user.id;
		let pboard='';
		let draftdata=this.props.draftdata;
		const comments=this.state.commentdata
		const handleConfirmMsgShow = this.state.setConfirmMsgShow;
		const handleDraftComment = this.state.setDraftCommentShow;
		const tableheader = <tr>
			<th>Time Code</th>
			<th>Comment</th>
			<th>Date</th>
			<th>User</th>
		</tr>;
		if (draftdata) {
			pboard = draftdata.map((item) => {
				let createdraftpagelink = '';
				let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;

				let checkusertype = '';
				let draftlistpostersrc=''
				let draftlength=item.draftdata.length
				checkusertype = (item.userdata[0].id === currentuserid) ? ' currentuser' : ' otheruser';
				let draftvideosrcfordraft='';
				let draftvideosrcfordraftdownloadlink='';
				let draftsrc = item.draftfile.split(".");
				draftsrc=draftsrc[0]+".mp4";
				if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
					createdraftpagelink = `/draft/${item._id}`;
					draftvideosrcfordraft="../../../../draft/"+item.draftfile;
					draftvideosrcfordraftdownloadlink="../../../../draft/"+draftsrc;
					draftlistpostersrc="../../../../draft/"+item.draftpostarimage;
				}
				else {
					createdraftpagelink = `/draft/${item._id}`;
					draftvideosrcfordraft="../../../../../draft/"+item.draftfile;
					draftvideosrcfordraftdownloadlink="../../../../../draft/"+draftsrc;
					draftlistpostersrc="../../../../../draft/"+item.draftpostarimage;
				}
				return (
					<li className={"itemlist " + item.type + checkusertype}>
						<div className="row">
							{
								this.state.draftstatusloading ?
									<div className="loaderspinner">
										<Loader
											type="TailSpin"
											color="#D10000"
											height={100}
											width={100}
										/>
									</div>
								: null
							}
							<div className="col-12 ChatBoxDesign">
								<div class="ChatImgThumb">
									<img src={item.userdata[0].avtar} />
								</div>
								<div className='type'>
									<div> {item.userdata[0].first_name} {item.userdata[0].last_name}</div>
								</div>
								<div className="PB_create_date">{dateFormat(item.create_date, "mmm d, yyyy")}
								</div>
								<div class="row">
									<div class="ProjectRepeatTbl">
										<p>
											<strong>Video Title:</strong> {item.drafttitle}&nbsp;&nbsp;
										</p>
										<div className="ComIncLinkBox">
											{(item.draftstatus === true || (this.state.draftstatus === true && this.state.draftidcompleted===item._id)) ?
												<div className="ComIncLink">Completed	</div>
												: <a className="forcusror ComIncLink" data-id={item._id} data-videoname={item.draftfile} data-videotitle={item.drafttitle} onClick={this.showConfirmMsgBox.bind(this)} >Mark As Complete</a>
											}
										</div>
										<p>
											<div className="vidoo-content">
												<video id={item._id} poster={draftlistpostersrc} data-draftid={item._id} data-title={item.drafttitle} onClick={this.videoClick.bind(this)} onPlay={this.onPlay} onPause={this.onPause} controls width="100%" height="220">
													<source src={draftvideosrcfordraft}
														type="video/mp4" />
													Sorry, your browser doesn't support embedded videos.
												</video>
											</div>
										</p>
									</div>
									<ul className="ComIncLinkBoxExt">
										<li>
											<a className="forcusror" target="_blank" href={draftvideosrcfordraftdownloadlink} download> Download
											</a>
										</li>
										<li>
											<a className="forcusror" target="_blank" href={createdraftpagelink}>External Link</a>
										</li>
									</ul>
									<table className="popup-comment-box table table-striped table-bordered dataTable no-footer ">
											{draftlength>0 ?					
											(
												
												<thead>
													{tableheader}
												</thead>
												
											)																	
											:''
											}
											{draftlength==0 && comments.length>0 && this.state.editedboardid===item._id ?	
											(
												<thead>
													{tableheader}
												</thead>
											)																
											:''
											}	
											<tbody>										
											{draftlength>0 ?( item.draftdata.map((value, index) => {	
												return (
													<tr>
														<td>{value.videotime}</td>
														<td>{value.comment}</td>
														<td>{dateFormat(item.createdAt, "mmm d, yyyy h:MM:ss")}</td>
														<td>{value.user}</td>								
													</tr>
												)																
											})	
											)										
											:''
											}
											{comments.map((value, index) => {										if(value.boardid === item._id)
												{
													return (
														<tr>
															<td>{value.videotime}</td>
															<td>{value.comment}</td>
															<td>{dateFormat(item.createdAt, "mmm d, yyyy, h:MM:ss")}</td>
															<td>{value.user}</td>								
														</tr>
													)
												}											
											})}	
											</tbody>
										</table>
								</div>
							</div>
						</div>
					</li>
				)
			})
		}
        return (
        	<div>
				<ul className="page-description container">
					{pboard}
				</ul>
				{
					this.state.Inloading ?
						<div className="loadercenter">
							<Loader
								type="ThreeDots"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<Modal show={handleConfirmMsgShow}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<label>Great, we'll send this file to Vimeo for the embed code and additional back-up.</label>
								<label>Would you also like to close this project?</label>
								<div className="checkbox">
									<label >
										<input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheckBox.bind(this)} />
										Yes, this project is completed.
									</label>
									<div className="errorMsg">{this.state.markascompleteerror}</div>
								</div>										
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleConfirmMsgBoxClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={(e) => this.markAsComplete(e)}>
							Ok
						</Button>
						<Button variant="primary" onClick={this.handleConfirmMsgBoxClose.bind(this)}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={handleDraftComment}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<div className="checkbox">
									<label >
										Time:{this.state.drafttime}
									</label>
									<input className="LP_field draftcomment" rows="4" name="draftcomment" value={this.state.draftcomment} onChange={this.draftCommentChange.bind(this)} type="textarea" placeholder="Draft Comment" />
									<div className="errorMsg">{this.state.draftcommenterror}</div>
								</div>										
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleDraftCommentClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={(e) => this.saveDraftComment(e)}>Save
						</Button>								
					</Modal.Footer>
				</Modal>
			</div>
		);
    }
}
export default DraftList;