function ByteToSize(props) {
	var bytes = props.byte
	var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
	for (var i = 0; i < sizes.length; i++) {
		if (bytes <= 1024) {
		return bytes + ' ' + sizes[i];
		} else {
		bytes = parseFloat(bytes / 1024).toFixed(0)
		}
	}
	return bytes + ' P';
}  
export default ByteToSize;