import React, {Component} from 'react';
import logo from '../assets/images/logo.png'; 
import backbtnicon from '../assets/images/back-btn-icon.png';
import homebtnicon from '../assets/images/home-btn-icon.png';
import {  Dropdown } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import {GetData,PostData} from '../services/PostData';
class SubsSidebar extends Component{
	constructor(props){
		//console.log('propsssssss=',props);
		super(props);
		let useremail = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user.email;
		this.state = {
			org_id:props.orgid,
			useremail:useremail,
			issubcriber:'no',
			isorgdata:false,
		};
		this.renderActiveProjects = this.renderActiveProjects.bind(this);
		this.openNav = this.openNav.bind(this);
		this.closeNav = this.closeNav.bind(this);
		
		console.log("userdataforcheck=== in subs====useremail==",useremail);
		//console.log("userdataforcheck=== in subs====email==",userdataforcheck.email);
	}
	openNav() {
		document.getElementById("mySidebar").style.width = "20%";
		document.getElementById("main").style.marginLeft = "20%";
		document.getElementById("mySidebar").classList.add('OpenSidebar');
		document.getElementById("main").classList.add('FullWrapper');
		this.renderActiveProjects();
	}
	closeNav() {
		document.getElementById("mySidebar").style.width = "0";
		document.getElementById("main").style.marginLeft= "0";
		document.getElementById("mySidebar").classList.remove('OpenSidebar');
		document.getElementById("main").classList.remove('FullWrapper');
	}
	componentDidMount() {
		this.renderActiveProjects();
		GetData('organization/'+this.state.org_id).then((result) => {   
			console.log('resultresult==',result);         
            this.setState({
                orgdata: result.data,
				issubcriber:result.data.subscriber
            });
		});
		let data={
			useremail:this.state.useremail
		}
		let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let userslikedorganization = checkuser.user.userslikedorganization;
		let url = userslikedorganization.length>0?'userslikedorganizationlist':'getuserorganizations';
		PostData(url, data).then((result) => {   
			//console.log('resultresult==',result);
			if(url=='getuserorganizations'){
	            this.setState({
	                userswithorgdata: result.data,
					isorgdata:false
	            });
	        } else {
	        	this.setState({
	                orglistdata: result.data,
					isorgdata:true
	            });
	        }
        });
	}
	renderActiveProjects(){
		GetData('orgprojects/'+this.state.org_id).then((result) => {             
            let responseJson = result;
            //console.log('responseJson==',responseJson);
            this.setState({
                projectList: responseJson.data,                
            });
        });   
	}
    render(){
		let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		//let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
    	if(checkuser.user.ID==15888 || checkuser.user.ID==12612 || checkuser.user.ID==28 || checkuser.user.ID==202371015253)
		{
			localStorage.setItem('userData','');
			return (<Redirect to={'/'}/>)
		}
		let projecturl=(this.state.org_id)?"/subs/org/"+this.state.org_id+"/project/list":"projects/list";
    	let projectlist=this.state.projectList;
		let activeprojectlist=[];
		//console.log('in projectlistprojectlistprojectlistprojectlistprojectlist',projectlist);
		let userswithorgdata= this.state.userswithorgdata;
		let usersorgdata=[];
		if(projectlist)  
	    {  
			activeprojectlist=projectlist.map((item) => {
				if(item.status==='Active'){
					return <li>
						<Link to={"/subs/org/"+item.org_id+"/project/view/"+item._id}><u>{item.project_name}</u></Link>
					</li>
				}
			})
		}
		if(this.state.isorgdata) {
			usersorgdata=this.state.orglistdata.map((item) => {
				console.log('item=',item)
				if(true){

				return <Dropdown.Menu>
						<Dropdown.Item href={"/subs/org/"+item._id+"/project/list/"}><u>{item.organization_name}</u></Dropdown.Item>
						</Dropdown.Menu>
				}
				
				
			})

		} else if(this.state.userswithorgdata)
		{
			let checklength = userswithorgdata.length
			console.log('checklength===',checklength);
			usersorgdata=userswithorgdata.map((item) => {
				if(checklength==1){
				return <Dropdown.Menu>
						<Dropdown.Item href={"/subs/org/"+item.orgdetail[0]._id+"/project/list/"}><u>{item.organization}</u></Dropdown.Item>
						</Dropdown.Menu>
				}
				else
				{
					if(item.orgdetail[0]._id!=this.state.org_id){
				return <Dropdown.Menu>
						<Dropdown.Item href={"/subs/org/"+item.orgdetail[0]._id+"/project/list/"}><u>{item.organization}</u></Dropdown.Item>
						</Dropdown.Menu>
					}
				}
				
			})
		}
		let orgname='';
		if(this.state.isorgdata)
		orgname="Projects";
		else if(this.state.orgdata)
		orgname=this.state.orgdata.organization_name;
    	return (           
 			<div id="mySidebar" className="sidebar">
		        <button className="openbtn" onClick={this.openNav}>
		       	 <img src={require('../assets/images/toggle-icon.png')} />
		        </button>
		        <div className="SidebarInner"> 
		        <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}><img src={require('../assets/images/toggle-icon.png')}/>
		         </a>
		          <img className="AS_logo_sidebar img-responsive" src={require('../assets/images/sidebar-logo.png')}/>
				  
		          <ul className="SideIconNav">
		            <li className="customdropdown">
			            {/* <Link to={projecturl}> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-home.png')}/> {orgname}</Link> */}
						<Dropdown>
							<Dropdown.Toggle variant="success1" id="dropdown-basic">
							{orgname}
							</Dropdown.Toggle>
							{usersorgdata}
						</Dropdown>
						
			        </li>
					{/* <li><Link to={`/subs/${this.state.org_id}/assets`}>Assets</Link></li>
					<li><Link to={`/subs/${this.state.org_id}/stocks`}>Stock Graphics</Link></li> */}
					<li>
					<Link to={`/sub/org/${this.state.org_id}/inbox/list`}> 
					<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-inbox.png')}/> 
					Inbox
					</Link>
					</li>
					<li> 
					<Link to={`/subs/${this.state.org_id}/assets`}> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-assets.png')}/>  Assets</Link></li>
					<li></li>
					
					<li>
						<Link to={`/subs/${this.state.org_id}/stockvideos`}> 
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-projects2.png')}/>
						</span> Stock Videos</Link>
					</li>
					<li>
						<Link to={`/subs/${this.state.org_id}/stockmusic`}> 
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/music-note-icon.png')}/>
						</span> Stock Music</Link>
					</li>
					<li>
						<Link to={`/subs/${this.state.org_id}/stocks`}> 
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-stock-graphics.png')}/> 
						</span>Stock Graphics</Link>
					</li>
					<li>
						<Link to={`/subs/${this.state.org_id}/closedcaptioning`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/captain.png')}/>
							</span>Closed Captioning</Link>
					</li>
					<li>
						<Link to={`/subs/${this.state.org_id}/portfolio`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-projects.png')}/>
							</span>Portfolio</Link>
					</li>
					<li>
						<Link to={`/subs/${this.state.org_id}/styleguide`}>
							<span className="menu_img_icon">
								<img className="MenuIcon styleguide" src={require('../assets/images/menu-icons/sd-icon1.png')}/>
							</span>Style Guide</Link>
					</li>
					<li>
						<Link to={`/subs/org/${this.state.org_id}/invoices`}>
							<span className="menu_img_icon">
								<img className="MenuIcon styleguide" src={require('../assets/images/menu-icons/invoice.png')}/>
							</span>Invoices</Link>
					</li>
					{
						this.state.issubcriber ==='no' &&
						<li>
						<Link to={`/subs/${this.state.org_id}/order-videos`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/order-video.png')}/>
							</span>Order Videos</Link>
						</li>	
					}	
			        <li><Link to="/logout"> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-logout.png')}/> Logout</Link></li>
			       </ul>
		          <ul className="SideListNav">
		            <li><a href="#">Active Projects</a></li>
					{activeprojectlist}
		          </ul>
		        </div>
		    </div> 
		)
    }
}
export default SubsSidebar;