import React, {useState,useEffect} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Button, Collapse, Modal } from 'react-bootstrap';


 
  import { StripeProvider, Elements } from 'react-stripe-elements'
  import CheckoutForm from './CheckoutForm';
  

  import {GetData,PostData} from '../services/PostData';

  
function OrderForm(props){
	const {isshow,closePopup} = props;

	//console.log('props isshow==',isshow);
	//console.log('props orgid==',props.orgid);
	//console.log('props vimeoid==',props.vimeoid);
	//console.log('props isshow==',isshow);
	const [show, setShow] = useState(isshow);
	const [totalcost,setTotalcost] =useState(0);
	 const [stripeLoaded, setStripeLoaded] = useState({})
	const [selectedlanguage,setSelectedlanguage] = useState([])
  const [encheckboxDisbale,setEncheckboxDisbale] =useState(false);
  const [cards,setCards] =useState('');
  const [defaultcard,setDefaultcard] =useState('');
  const [custid,setCustid] =useState('');
	const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => {
      resolve({ successful: true })
    })
    script.addEventListener('error', event => {
      reject({ error: event })
    })
    document.head.appendChild(script)
  });
  const [languages_en, setLanguages_en] = useState([
		{	
			id:1,
		  	name: 'English $3/min',
			language:'en',
		 	cost: 3,
			checked: false
		}
	]);
	const [languages, setLanguages] = useState([
		 {
			id:2,
		 	name: 'Spanish (Spain) $5/min',
		 	language:'es-es',
		 	cost: 5,
		 	checked: false
		 },
		 {
		 	id:3,
		 	name: 'Spanish (Latin) $5/min',
		 	language:'es-la',
		 	cost: 5,
		 	checked: false
		 },
		 {
		 	id:4,
		 	name: 'Chinese (Simplified) $7/min',
		 	language:'zh-si',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:5,
		 	name: 'Chinese (Traditional) $7/min',
		 	language:'zh-tr',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:6,
		 	name: 'French $7/min',
			language:'fr',
		 	cost: 7,
		 	checked: false
		 },
		  {
		 	id:7,
		 	name: 'German $7/min',
		 	language:'de',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:13,
		 	name: 'Italian $7/min',
		 	language:'it',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:8,
		 	name: 'Japanese $9/min',
		 	language:'ja',
		 	cost: 9,
		 	checked: false
		 },
		 {
		 	id:10,
		 	name: 'Czeck $5/min',
		 	language:'cs',
		 	cost: 5,
		 	checked: false
		 },
		  {
		 	id:11,
		 	name: 'Dutch $7/min',
		 	language:'nl',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:12,
		 	name: 'Hindi $5/min',
		 	language:'hi',
		 	cost: 5,
		 	checked: false
		 },
		 
		 {
		 	id:14,
		 	name: 'Korean $7/min',
		 	language:'ko',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:15,
			name: 'Polish $5/min',
		 	language:'pl',
		 	cost: 5,
		 	checked: false
		 },
		 {
		 	id:16,
		 	name: 'Portuguese $5/min',
		 	language:'pt-br',
		 	cost: 5,
		 	checked: false
		 },
		 {
		 	id:17,
		 	name: 'Russian $7/min',
		 	language:'ru',
		 	cost: 7,
		 	checked: false
		 },
		 {
		 	id:18,
		 	name: 'Turkish $5/min',
		 	language:'tr',
		 	cost: 5,
		 	checked: false
		 },
		 {
		 	id:9,
		 	name: 'Arabic $5/min',
		 	language:'ar',
		 	cost: 5,
		 	checked: false
		 }
	  ]);
	useEffect(() => {
		setShow(isshow);
		const fetchData = async () => {
      const result = await loadScript('https://js.stripe.com/v3/')
      setStripeLoaded(result)
    }
    fetchData()
     GetData('savedcards/'+props.userId,).then((result) => {		
		   // console.log('result==',result);	
		   if(result.cards)	
		   {
				setCards(result.cards);
				 const ccustomer =  JSON.parse(result.customer);
				//  console.log('ccustomer=',ccustomer);
				 const decard = ccustomer.default_source
				setDefaultcard(result.defaultcard);
				setCustid(ccustomer.id);
				//setReceiptUrl(result.charge.receipt_url)	
		   }	
		});
	},[isshow]);
	const onToggle =(index) =>{
		//console.log('index==',index);

		let newItemsen = languages_en.slice();
		newItemsen[0].checked = false
		setLanguages_en(newItemsen);


		let newItems = languages.slice();
		newItems[index].checked = !newItems[index].checked
		setLanguages(newItems);
		var total = 0;
		var selectedlanguage =[];
		let encheckboxDisbale = false;
		setEncheckboxDisbale(encheckboxDisbale);		
		newItems.forEach(arg => {
			if(arg.checked)
			{
				total += props.videoinminute*arg.cost
				selectedlanguage.push(arg);
				let encheckboxDisbale = true;
				setEncheckboxDisbale(encheckboxDisbale);
			}
		});
		//console.log('selectedlanguage',selectedlanguage);
		setSelectedlanguage(selectedlanguage);		
		setTotalcost(total);
	}
	const onToggleEn =(index) =>{
		//console.log('index==',index);
		let newItems = languages_en.slice();
		newItems[index].checked = !newItems[index].checked
		setLanguages_en(newItems);
		var total = 0;
		var selectedlanguage =[];
		//let encheckboxDisbale = false;
		//setEncheckboxDisbale(encheckboxDisbale);		
		newItems.forEach(arg => {
			if(arg.checked)
			{
				total += props.videoinminute*arg.cost
				selectedlanguage.push(arg);
				//let encheckboxDisbale = true;
				//setEncheckboxDisbale(encheckboxDisbale);
			}
		});
	//	console.log('selectedlanguage',selectedlanguage);
		setSelectedlanguage(selectedlanguage);		
		setTotalcost(total);
	}
	const language = () => { languages.map((item, i) => {
		//console.log('calleddd==',item, i);
				return(<li key={i}>				
					<input type="checkbox" onChange = {() => {onToggle(i)}} /> {item.name}
				</li>)
			}
		)
	}
	function handleChildClick(cardid,defaultcardid,custid,userId)
	{
			console.log('data fro remove',cardid,defaultcardid,custid,userId);
			const data = {
			custid:custid,
			cardid:cardid,
			defaultcardid:defaultcardid,
			userId:userId,
		}
		PostData('deletecard',data).then((result) => {		
		    console.log('result==',result);		
				if(result.statuscode===200)
				{
					setCards(result.cards);
					setDefaultcard(result.defaultcard);
				}						
		});
	}
	const callFunction =  (id,order) => {
	//	console.log('calleddd==',id,order);
		for(let ord of order)
		{
			let	orders =ord.language;
			orders = JSON.parse(orders);
			if (orders[0].id === id) {
				//disable = true;
			//	console.log('checked',order[0]);
				return true;
				break;
			} 
		}	
		return false
	}
	return ( 
	<div className="order-form-section" >	
		
				
			<div className="succes-msg"></div>
			<div className="error-msg"></div>
			<ul className="languagelist languagelist_en">
				{/* <language/> */}
				{languages_en.map((item, i) =>
					{
						//console.log('item, i==',item, i);
						var checkid = item.id;
						var disable = false;
						var orders = props.order; 
						
						if(orders)
						{
							//orders =orders.language;
							//orders = JSON.parse(orders);
							var check=false;
							if(orders.length>0)
							{
								var check = callFunction(checkid,orders);								
							}
						}
						if(check)
						{
							return(
								<li key={i} className={item.language}>				
									<input type="checkbox" disabled={check} checked={check}  onChange = {() => {onToggleEn(i)}} /> {item.name}
								</li>
							)
						}
						else
						{
							if(encheckboxDisbale)
							{
								return(
									<li key={i} className={item.language}>				
										<input type="checkbox" disabled={encheckboxDisbale} checked={encheckboxDisbale} onChange = {() => {onToggleEn(i)}} /> {item.name}
									</li>
								)
							}
							else
							{
								return(
									<li key={i} className={item.language}>				
										<input type="checkbox" onChange = {() => {onToggleEn(i)}} /> {item.name}
									</li>
								)	
							}
							
						}						
					}
				)}
			</ul>
			<h3 className="subtitles PageTopTitle">Subtitles</h3>
			<p className="para-text">Includes free English captions</p>
			<ul className="languagelist">
				{/* <language/> */}
				{languages.map((item, i) =>
					{
						//console.log('item, i==',item, i);
						var checkid = item.id;
						var disable = false;
						var orders = props.order; 
						
						if(orders)
						{
							//orders =orders.language;
							//orders = JSON.parse(orders);
							var check=false;
							if(orders.length>0)
							{
								var check = callFunction(checkid,orders);								
							}
						}
						if(check)
						{
							return(
								<li key={i} className={item.language}>				
									<input type="checkbox" disabled={check} checked={check}  onChange = {() => {onToggle(i)}} /> {item.name}
								</li>
							)
						}
						else
						{
							return(
								<li key={i} className={item.language}>				
									<input type="checkbox" onChange = {() => {onToggle(i)}} /> {item.name}
								</li>
							)
						}						
					}
				)}
			</ul>
			{/* <select name="projectname" className="form-control"  >
				<option value=" ">Select Project</option>
			</select> */}
			<div className="totalcost">Order Total: ${totalcost}</div>
			
			<StripeProvider apiKey="pk_live_qhs2JPd41kDvGwZmp4UtDCTl">
			<Elements>
				<CheckoutForm onChildClick={handleChildClick} amount={totalcost} orgid={props.orgid} vimeoid={props.vimeoid} projectname={props.projectname} name={props.name} email={props.email} userId={props.userId}  cards={cards} custid={custid} defaultcard={defaultcard} selectedlanguage={selectedlanguage} />
			</Elements>
			</StripeProvider>
			
		
	</div>
	)
}
export default OrderForm;