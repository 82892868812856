import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import dateFormat from 'dateformat';
class AddVideographyForm extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			setVideographyShow:this.props.handleVideographyShow,
			selectedPurpose: [],
			arrivalDate: new Date(),
			departureDate: new Date(),
			is_accommodation_given:'',
			location:'',
			notes:'',
			onsitecontact:'',
			mobilenumber:'',
			emailaddress:'',
		};
		this.handleArrivalChange = this.handleArrivalChange.bind(this);
		this.handleDepartureChange = this.handleDepartureChange.bind(this);
		this.onChange = this.onChange.bind(this);
		console.log('calledddd==',this.props.handleVideographyShow);
	}
	handleArrivalChange(date) {  
	    this.setState({  
	      arrivalDate: date  
	    })  
	}
	handleDepartureChange(date) {  
	    this.setState({  
	      departureDate: date  
	    })  
	}
	componentDidUpdate(prevProps) {
		if (prevProps.videographyTime !== this.props.videographyTime) {
			this.setState({setVideographyShow:this.props.videographyTime})		
		}
	}	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	notesChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	handleVideographyClose(e) {
		this.setState({ setVideographyShow: false });
	}
	saveVideoGraphyRequest(e) {
		if (this.validateAddTaskTimeForm()) {
			this.setState({ Inloading: true });
			let purpose = this.state.selectedPurpose.toString();
			let data = {
				'orgid': this.props.orgid,
				'projectid': this.props.prjid,
				'location': this.state.location,
				'purpose': purpose,
				'arrivalDate': this.state.arrivalDate,
				'departureDate': this.state.departureDate,
				'notes': this.state.notes,
				'userid':this.props.userid,
				'onsitecontact':this.state.onsitecontact,
				'mobilenumber':this.state.mobilenumber,
				'emailaddress':this.state.emailaddress,
				'type':'Videography Request'
			}
			console.log('data',data);
			this.postVideoGraphyRequest(data);
		}
	}
	validateAddTaskTimeForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["location"]) {
			formIsValid = false;
			errors["location"] = "*Please select Task";
		}
		if (!fields["arrivalDate"]) {
			formIsValid = false;
			errors["arrivalDate"] = "*Please select Hour";
		}
		if (!fields["departureDate"]) {
			formIsValid = false;
			errors["departureDate"] = "*Please select Minut";
		}
		if (typeof fields["mobilenumber"] !== "undefined") {
			//regular expression for email validation
			var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
			if (!phoneNum.test(fields["mobilenumber"])) {
				formIsValid = false;
				errors["mobilenumber"] = "*Please enter valid Phone Number.";
			}
		}  
		if (typeof fields["emailaddress"] !== "undefined") {
			//regular expression for email validation
			var email = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; 
			if (!email.test(fields["emailaddress"])) {
				formIsValid = false;
				errors["emailaddress"] = "*Please enter valid Email Address.";
			}
		} 
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	postVideoGraphyRequest(data)
	{
		this.setState({ Inloading: true });
		PostData('savevideographyrequest', data).then((result) => {
			if (result.statuscode === 200) {				
				this.setState({ savevideographsucsmsg: "Videography Request Added Succesfully", location: '', selectedPurpose: [], notes: '',onsitecontact:'',mobilenumber:'',emailaddress:'' });
				setTimeout(() => {
					this.setState({  savevideographsucsmsg: '',setVideographyShow: false });
					this.props.loadProjectBoard();
				}, 3000);
			}
			this.setState({ Inloading: false });
		});
	}
	onPurposeChange = text => {
		const selectedPurpose = this.state.selectedPurpose;

		// Find index
		const findIdx = selectedPurpose.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedPurpose.splice(findIdx, 1);
		} else {
		  selectedPurpose.push(text);
		}
        console.log('selectedPurpose==',selectedPurpose);
		this.setState({
		  selectedPurpose: selectedPurpose
		});
	};
    render() {
		console.log('called every time');
		const purposeCheckboxes = [
		  { id: 1, text: "Interviews/Testimonials" },
		  { id: 2, text: "B-roll" },
		  { id: 4, text: "Conference/Expo-Footage" },
		  { id: 5, text: "Breakout Sessions" },
		  { id: 6, text: "Others" },
		];
		const { selectedPurpose } = this.state;
        return (
        	<Modal show={this.state.setVideographyShow}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Videography Request</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.savevideographsucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row CreateProRadioBox">
						<div className="col-sm-12">
							<label for="projectname" class="ParentLab" >Purpose</label>
						</div>
						
						{purposeCheckboxes.map(purpose => (
							<div className="col-sm-6 hideinputbox">
					          <label key={purpose.id}>
					            <input
					              type="checkbox"
					              onChange={() => this.onPurposeChange(purpose.text)}
					              selected={selectedPurpose.includes(purpose.text)}
					            /> {purpose.text}								            
					          </label>
					         </div>
				        ))}	
				        						
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-sm-12">
							<label for="projectname" class="ParentLab" >Location</label>
						</div>
						<div className="col-sm-12">
							<input type="text" name="location" onChange={this.onChange} className="PB_Title form-control" autoFocus />
							<div className="errorMsg">{this.state.errors.location}</div>
						</div>
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-sm-6" style={{position:"inherit"}}>
							<label for="projectname" class="ParentLab" >Arrival Date</label>
							<DatePicker
						      selected={ this.state.arrivalDate }
						      onChange={ this.handleArrivalChange }
						      showTimeSelect
						      dateFormat="MMMM d, yyyy h:mm aa"
						    />
						    <div className="errorMsg">{this.state.errors.arrivalDate}</div>
						</div>
						<div className="col-sm-6" style={{position:"inherit"}}>
							<label for="projectname" class="ParentLab" >Departure Date</label>
							<DatePicker
						      selected={ this.state.departureDate }
						      onChange={ this.handleDepartureChange }
						      showTimeSelect
						      dateFormat="MMMM d, yyyy h:mm aa"
					    	/>
					    	<div className="errorMsg">{this.state.errors.departureDate}</div>
						</div>
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-12">
							<label for="projectname" class="ParentLab" >Notes</label>
							<input className="LP_field tcomment" value={this.state.notes} onChange={this.notesChange.bind(this)} name="notes" type="textarea" />
						</div>
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-sm-4">
							<label for="projectname" class="ParentLab" >Onsite Contact</label>
							<input type="text" name="onsitecontact" onChange={this.onChange} className="PB_Title form-control" autoFocus />
						</div>
						<div className="col-sm-4">
							<label for="projectname" class="ParentLab" >Mobile Number</label>
							<input type="text" name="mobilenumber" onChange={this.onChange} className="PB_Title form-control" autoFocus />
							<div className="errorMsg">{this.state.errors.mobilenumber}</div>
						</div>
						<div className="col-sm-4">
							<label for="projectname" class="ParentLab" >Email Adderess</label>
							<input type="email" name="emailaddress" onChange={this.onChange} className="PB_Title form-control" autoFocus />
							<div className="errorMsg">{this.state.errors.emailaddress}</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleVideographyClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.saveVideoGraphyRequest(e)}>
						Submit
					</Button>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default AddVideographyForm;