import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../../services/PostData';
import Loader from 'react-loader-spinner'
import AddTeam from '../productionteams/AddTeam'
import EditTeam from '../productionteams/EditTeam'
import ListTeam from '../productionteams/ListTeam'
import ProjectList from '../project/ProjectList';
import AddProject from '../project/CreateProject';
import TimeLog from './TimeLogs';
class OrgProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgdata: '',
            teamList: [],
            originalTeamList: [],
            loading: false,
            isSelectedMessage:'',
            displaytype:'viewprojectlist',
            userid:'',
            credits:'',
            issubcriber:'no',
            totalavailable_hours:'',
            remaining_hours:'',
            vimeo_uri:''
        };
    }
    componentDidMount() {
        this.loadProductionTeamData();
    }
    loadProductionTeamData(){
        this.setState({ loading: true});
        GetData('orgprofile/'+this.props.match.params.orgid).then((result) => {     
            //let remainingcredits = result.data.credits-result.data.used_credit        
            this.setState({ 
                 loading: false,
                 teamList: result.data.org_users,
                 originalTeamList:result.data.org_users,
                 orgdata:result.data,
                 boxfolderid:result.data.boxfolderid,
                 credits:result.data.credits,
                 issubcriber:result.data.subscriber,
                 totalavailable_hours:result.data.totalavailable_hours,
                 remaining_hours:result.data.remaining_hours,
                 vimeo_uri:result.data.vimeo_uri
             });
         });
    }
    callProductionteamdata(e){        
        var elem = this;
        elem.loadProductionTeamData();
        elem.setState({ displaytype: 'viewteam' });
    }
    changeView(displaytype,userid){
        this.setState({ displaytype: displaytype,userid:userid }); 
        this.loadProductionTeamData();
    }
    render() {   
        let teamListdata=[];    
        const loading = this.state.loading;
        let orgdata=this.state.orgdata;
        let orglogo='';
        if(orgdata.orglogo)
            orglogo=orgdata.orglogo;
        else
        orglogo='';
        teamListdata=this.state.teamList;
        let imagesrc='';
        //console.log('orglogo====',orglogo,orgdata.orglogo,orgdata);
        if(orglogo && orglogo!='""')
        {
            //console.log('orglogo hereeeee====',orglogo);
            try{
                imagesrc=require('../../assets/images/organization-logo/'+orglogo);
               // console.log('orglogo tryyyyy====');
            }
            catch
            {
               // console.log('orglogo catchhhh====');
                imagesrc='';
            }
        }
        else
        {
            imagesrc='';
        }
        //console.log('imagesrc====',imagesrc);
        let { handleClick } = this.props;
       // console.log('handleClick====',handleClick);
       let totalavailable_hours;
       let final_totalavailable_hour;
       let totalavailable_minute;
       let totalavailable_is_neg = 1;
       if(this.state.totalavailable_hours)
       {
            totalavailable_hours= this.state.totalavailable_hours.split(':');
            if(totalavailable_hours[0])
            {
                final_totalavailable_hour = totalavailable_hours[0];
                let checknum = parseInt(totalavailable_hours[0]);
                totalavailable_is_neg = (checknum>0)?1:0;
            }
            if(totalavailable_hours[1] && totalavailable_hours[1]!=0)
            {
                //console.log('totalavailable_hours[1]=',totalavailable_hours[1]);
            totalavailable_minute = Math.abs(parseInt(totalavailable_hours[1]));
            final_totalavailable_hour=final_totalavailable_hour+':'+totalavailable_minute;
            }
       }
       let remaining_hours;
       let remaining_minute;
       let final_remaining_hour;
       let final_remaining_is_neg = 1;
       if(this.state.remaining_hours)
       {
        remaining_hours= this.state.remaining_hours.split(':');
            if(remaining_hours[0])
            {
                final_remaining_hour = remaining_hours[0];
                let checknum = parseInt(remaining_hours[0]);
                final_remaining_is_neg = (checknum>0)?1:0;
            }
            if(remaining_hours[1] && remaining_hours[1]!=0)
            {

                remaining_minute = Math.abs(parseInt(remaining_hours[1]));
                //console.log('remaining_minute=',remaining_minute);
                final_remaining_hour = final_remaining_hour+':'+remaining_minute;
            }
       }

       //remaining_hours
		return (
			 <div className="container-fluid ProAssetsList"  ref="selfdiv">
                 {
	        		loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
						<div class="MobileDisplay MobileTopNavBar">
						 <ul className="top-links">
                                        <li>
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewprojectlist')} >
                                            Projects</a>  
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Stream</Link>
                                        </li> */}
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewteam')} >
                                           Production Team</a>                                            
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Files</Link>
                                        </li>
                                        <li>
                                            <Link to="/upload-assets">Notes</Link>
                                        </li> */}
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'timelog')} >
                                            Time Log</a>                                            
                                        </li>
                                        {/* <li>
                                            <Link to={`/${this.props.match.params.orgid}/timelogs/`}>Time Log</Link>
                                        </li> */}
                                    </ul>
						</div>
                            <div className="row MoPrologoLeft">
                                <div className="col-12 ProAssetsHead">
                                    <img  className="mr-3" src={imagesrc}/>
                                    <h4 class="PageTopTitle">{orgdata.organization_name}</h4>
                                    <p>
                                        {orgdata.street}<br/>
                                        {orgdata.city},{orgdata.state} {orgdata.zip} <br/>
                                        {orgdata.phone}
                                    </p>
                                </div>                         
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8 MobOrgProTab">
                                    <ul className="top-links">
                                        <li>
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewprojectlist')} >
                                            Projects</a>  
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Stream</Link>
                                        </li> */}
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewteam')} >
                                           Production Team</a>                                            
                                        </li>
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'timelog')} >
                                            Time Log</a>                                            
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Files</Link>
                                        </li>
                                        <li>
                                            <Link to="/upload-assets">Notes</Link>
                                        </li> */}
                                        {/* <li>
                                            <Link to={`/${this.props.match.params.orgid}/timelogs/`}>Time Log</Link>
                                        </li> */} 
                                    </ul>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 MobOrgProbtns">
                                    {
                                        this.state.issubcriber ==='no' &&
                                        <a  className="numofcredits">Credits: {this.state.credits}</a>
                                    }
                                    {
                                        this.state.issubcriber ==='yes' &&
                                        <>
                                            <a  className={totalavailable_is_neg>0?'numofcredits': 'neg-balance numofcredits'}>Total: {final_totalavailable_hour}</a>
                                            <a  className={final_remaining_is_neg>0?'numofcredits avail-hours': 'neg-balance numofcredits avail-hours'}>Available Hours: {final_remaining_hour}</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {    
                        (this.state.displaytype==='createproject' &&
                            <div className="project-list">  
                                <AddProject vimeo_uri={this.state.vimeo_uri} view={this.changeView.bind(this)} boxfolderid={this.state.boxfolderid} orgid={this.props.match.params.orgid} credits={this.state.credits} /> 
                            </div>
                        )
                    }
                    {    
                        (this.state.displaytype==='viewprojectlist' &&
                            <div className="project-list">  
                                <ProjectList view={this.changeView.bind(this)} orgid={this.props.match.params.orgid} credits={this.state.credits} /> 
                            </div>
                        )
                    }
                    {    
                        (this.state.displaytype==='viewteam' &&
                            <div className="team-list">  
                                <ListTeam view={this.changeView.bind(this)} teamlist={this.state.teamList} orgiteamlist={this.state.originalTeamList} orgid={this.props.match.params.orgid} /> 
                            </div>
                        )
                    }
                    {   
                        (this.state.displaytype==='addteam' &&
                            <div className="add-team">
                                <AddTeam orgname={this.state.orgdata.organization_name} view={this.callProductionteamdata.bind(this)} orgid={this.props.match.params.orgid} />
                            </div> 
                        )
                    } 
                    {   
                        (this.state.displaytype==='editteam' &&
                            <div className="add-team">
                                <EditTeam view={this.changeView.bind(this)} userid={this.state.userid} orgid={this.props.match.params.orgid} />
                            </div> 
                        )
                    } 
                    {   
                        (this.state.displaytype==='timelog' &&
                            <div className="add-team">
                                <TimeLog view={this.changeView.bind(this)} orgid={this.props.match.params.orgid} userid={this.state.userid} />
                            </div> 
                        )
                    } 
                </div>
            </div>
		);
    }
}
export default OrgProjects;
