import React, { Component } from "react";
import {PostData,GetData} from '../services/PostData';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class BroadCast extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            selectedvideo:'',
            videolist:[],
            originalvideolist:[],
            showcode:false,
            filevideolist:[],
            fileselectedvideo:'',
            fileselectedvideo2:'',
            fileselectedvideo3:'',
            fileselectedvideo4:'',
            fileselectedvideo5:'',
            fileshowcode:false,
            broadcastdate1:'',
            broadcastdate2:'',
            broadcastdate3:'',
            broadcastdate4:'',
            broadcastdate5:'',
            broadcasttime1:'',
            broadcasttime2:'',
            broadcasttime3:'',
            broadcasttime4:'',
            broadcasttime5:'',
            projectid:'',
            successmsg:'',
            errormsg:'',
            autosuccessmsg:'',
            autoerrormsg:''
        };        
    }   
    componentDidMount() { 
        Promise.all([this.listcompletedprojectlist(), this.getOrgBroadCastData()])
            .then(([completedprojectlist, orgbroadcastdata ]) => {
            let currenttime = Date.now();
            let fileselectedvideo=''
            let selectedvideo=''
            let fileid=''
            if(orgbroadcastdata.data!=null){
                currenttime=orgbroadcastdata.data.broadcast_date_time
                console.log('herrererer dattatatata',currenttime);
                fileselectedvideo=orgbroadcastdata.data.broadcasts;
                selectedvideo=orgbroadcastdata.data.autopilotFile;    
                currenttime = parseInt(currenttime);
                fileid=orgbroadcastdata.data.id;
            }           
              
            console.log('herrererer dattatatata before currenttime =',currenttime);
            let dateStr1=''
            let timeStr1=''
            let dateStr2=''
            let timeStr2=''
            let dateStr3=''
            let timeStr3=''
            let dateStr4=''
            let timeStr4=''
            let dateStr5=''
            let timeStr5=''
            let fileselectedvideo1=''
            let fileselectedvideo2=''
            let fileselectedvideo3=''
            let fileselectedvideo4=''
            let fileselectedvideo5=''
            if(fileselectedvideo)
            {
                let videoslist=fileselectedvideo.split('|');
                //console.log('videoslist==',videoslist);
                if(videoslist[0])
                {
                    //console.log('videoslist[0]==',videoslist[0]);
                    let singlerow1 = videoslist[0];
                    if(singlerow1)
                    {
                        let forsingleitem1= singlerow1.split('^');
                        let timeforint1  = parseInt(forsingleitem1[1])
                        let time1 = new Date(timeforint1);
                        fileselectedvideo1=forsingleitem1[2];
                        dateStr1 = time1.getFullYear() + "-" + (time1.getMonth() + 1).toString().padStart(2, "0") + "-" + time1.getDate().toString().padStart(2, "0")
                        timeStr1 = [time1.getHours(), time1.getMinutes(), time1.getSeconds()].map(val => val.toString().padStart(2, "0")).join(":");
                    }
                }
                if(videoslist[1])
                {
                    //console.log('videoslist[0]==',videoslist[0]);
                    let singlerow2 = videoslist[1];
                    if(singlerow2)
                    {
                        let forsingleitem2= singlerow2.split('^');
                        let timeforint2  = parseInt(forsingleitem2[1])
                        let time2 = new Date(timeforint2);
                        fileselectedvideo2=forsingleitem2[2];
                        dateStr2 = time2.getFullYear() + "-" + (time2.getMonth() + 1).toString().padStart(2, "0") + "-" + time2.getDate().toString().padStart(2, "0")
                        timeStr2 = [time2.getHours(), time2.getMinutes(), time2.getSeconds()].map(val => val.toString().padStart(2, "0")).join(":");
                    }
                }                             
                if(videoslist[2])
                {
                    //console.log('videoslist[0]==',videoslist[0]);
                    let singlerow3 = videoslist[2];
                    if(singlerow3)
                    {
                        let forsingleitem3= singlerow3.split('^');
                        let timeforint3  = parseInt(forsingleitem3[1])
                        let time3 = new Date(timeforint3);
                        fileselectedvideo3=forsingleitem3[2];
                        dateStr3 = time3.getFullYear() + "-" + (time3.getMonth() + 1).toString().padStart(2, "0") + "-" + time3.getDate().toString().padStart(2, "0")
                        timeStr3 = [time3.getHours(), time3.getMinutes(), time3.getSeconds()].map(val => val.toString().padStart(2, "0")).join(":");
                    }
                }                                                                
                if(videoslist[3])
                {
                    //console.log('videoslist[0]==',videoslist[0]);
                    let singlerow4 = videoslist[3];
                    if(singlerow4)
                    {
                        let forsingleitem4= singlerow4.split('^');
                        let timeforint4  = parseInt(forsingleitem4[1])
                        let time4 = new Date(timeforint4);
                        fileselectedvideo4=forsingleitem4[2];
                        dateStr4 = time4.getFullYear() + "-" + (time4.getMonth() + 1).toString().padStart(2, "0") + "-" + time4.getDate().toString().padStart(2, "0")
                        timeStr4 = [time4.getHours(), time4.getMinutes(), time4.getSeconds()].map(val => val.toString().padStart(2, "0")).join(":");
                    }
                }
                if(videoslist[4])
                {
                    //console.log('videoslist[0]==',videoslist[0]);
                    let singlerow5 = videoslist[4];
                    if(singlerow5)
                    {
                        let forsingleitem5= singlerow5.split('^');
                        let timeforint5  = parseInt(forsingleitem5[1])
                        let time5 = new Date(timeforint5);
                        fileselectedvideo5=forsingleitem5[2];
                        dateStr5 = time5.getFullYear() + "-" + (time5.getMonth() + 1).toString().padStart(2, "0") + "-" + time5.getDate().toString().padStart(2, "0")
                        timeStr5 = [time5.getHours(), time5.getMinutes(), time5.getSeconds()].map(val => val.toString().padStart(2, "0")).join(":");
                    }
                }
            }
            this.setState({
                videolist: completedprojectlist.data,
                filevideolist: completedprojectlist.data,
                orgbroadcastdata:orgbroadcastdata.data,
                broadcastdate1:dateStr1,
                broadcasttime1:timeStr1,
                broadcastdate2:dateStr2,
                broadcasttime2:timeStr2,
                broadcastdate3:dateStr3,
                broadcasttime3:timeStr3,
                broadcastdate4:dateStr4,
                broadcasttime4:timeStr4,
                broadcastdate5:dateStr5,
                broadcasttime5:timeStr5,
                fileselectedvideo1:fileselectedvideo1,
                fileselectedvideo2:fileselectedvideo2,
                fileselectedvideo3:fileselectedvideo3,
                fileselectedvideo4:fileselectedvideo4,
                fileselectedvideo5:fileselectedvideo5,
                selectedvideo:selectedvideo,
                fileid:fileid
            });
            
        });      
    }
    onDateChange(type,e){
        console.log('type,e===',type,e.target.value);
        this.setState({            
            [type]:e.target.value,
        });        
    }
    onTimeChange(type,e){
        console.log('type,e===',type,e.target.value);
        this.setState({            
            [type]:e.target.value,
        });
    }   

    listcompletedprojectlist() {
        let data={
            org_id:this.props.match.params.orgid
        } 
        return PostData('listcompletedprojectlist/',data).then((result) => {
            return result;
        });
    }
    getOrgBroadCastData() {
        return GetData('getorgbroadcastdata/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    
    handleVideoChange(e){
        let idx = e.target.selectedIndex;
        let dataset = e.target.options[idx].dataset;
        //console.log('ISD id: ', dataset.id);    
        //console.log('e.currentTarget==',e.currentTarget,dataset.id);        
        console.log('e.target.value==',e.target.value);
        this.setState({
            selectedvideo:e.target.value,
            showcode:false,
           // autosuccessmsg:'Video Updated Succesfully',
            projectid:dataset.id
        })
        let data={
            orgid:this.props.match.params.orgid,
            projectid:dataset.id,
            fileselectedvideo:e.target.value
        }
        PostData('saveautopilotdata', data).then((result) => {
            if (result.statusCode === 200) {
                console.log('===',result.message);
                this.setState({
                    autosuccessmsg:result.message
                }); 
                setTimeout(() => {
                    this.setState({  
                        autosuccessmsg:'',                   
                    });                 
                }, 3000);
               
            }
        });
    }
    handleProgrammeVideoChange(videotype,e){
       // let pid= e.currentTarget.dataset.id;
        let idx = e.target.selectedIndex;
        let dataset = e.target.options[idx].dataset;
        console.log('videotype,e ', videotype,e);    
        //console.log('e.currentTarget==',e.currentTarget,dataset.id);
        this.setState({
            [videotype]:e.target.value,
            showcode:false,
            projectid:dataset.id
        }) 
    }
    showcode(){
        this.setState({
            showcode:true
        })
    }
    fileshowcode(){
        this.setState({
            fileshowcode:true
        })
    }
    saveBroadCast(e){
        this.setState({
            errormsg:''     
        });       
        if(this.state.fileselectedvideo1!=' ' && this.state.broadcastdate1 && this.state.broadcasttime1)
        {
            let finaldata='';
            // let time = (+new Date(this.state.broadcastdate + " " + this.state.broadcasttime)).toString();
           let ddd= this.state.broadcastdate1 
           let ttt= this.state.broadcasttime1
           //console.log('ddd==',ddd);
           //console.log('ttt==',ttt);
           let time =ddd.split("-").concat(
            ttt.split(":")
            );
           // console.log('time==',time);
            time = (+new Date(time[0], time[1]-1, time[2], time[3], time[4], (time[5] ? time[5] : 0))).toString();
            //console.log('time==',time);
            finaldata=this.state.fileselectedvideo1+'^'+time+'^'+this.state.fileselectedvideo1;
           // console.log('fileselectedvideo2==',this.state.fileselectedvideo2);
            //console.log('broadcastdate2==',this.state.broadcastdate2);
            //console.log('broadcasttime2==',this.state.broadcasttime2);
            if(this.state.fileselectedvideo2!=' ' && this.state.broadcastdate2 && this.state.broadcasttime2)
            {
                ddd= this.state.broadcastdate2
                ttt= this.state.broadcasttime2
                time =ddd.split("-").concat(
                    ttt.split(":")
                );
               // console.log('time 2==',time);
                time = (+new Date(time[0], time[1]-1, time[2], time[3], time[4], (time[5] ? time[5] : 0))).toString();
                //console.log('time2==',time);
                finaldata=finaldata+'|'+this.state.fileselectedvideo2+'^'+time+'^'+this.state.fileselectedvideo2;
            }
            if(this.state.fileselectedvideo3!=' ' && this.state.broadcastdate3 && this.state.broadcasttime3)
            {
                ddd= this.state.broadcastdate3
                ttt= this.state.broadcasttime3
                time =ddd.split("-").concat(
                    ttt.split(":")
                );
                console.log('fileselectedvideo3==',this.state.fileselectedvideo3);
                console.log('time333==',time);
                time = (+new Date(time[0], time[1]-1, time[2], time[3], time[4], (time[5] ? time[5] : 0))).toString();
                console.log('time333==',time);
                finaldata=finaldata+'|'+this.state.fileselectedvideo3+'^'+time+'^'+this.state.fileselectedvideo3;
            }
            if(this.state.fileselectedvideo4!=' ' && this.state.broadcastdate4 && this.state.broadcasttime4)
            {
                ddd= this.state.broadcastdate4
                ttt= this.state.broadcasttime4
                time =ddd.split("-").concat(
                    ttt.split(":")
                );
               // console.log('time==',time);
                time = (+new Date(time[0], time[1]-1, time[2], time[3], time[4], (time[5] ? time[5] : 0))).toString();
                //console.log('time==',time);
                finaldata=finaldata+'|'+this.state.fileselectedvideo4+'^'+time+'^'+this.state.fileselectedvideo4;
            }
            if(this.state.fileselectedvideo5!=' ' && this.state.broadcastdate5 && this.state.broadcasttime5)
            {
                ddd= this.state.broadcastdate5
                ttt= this.state.broadcasttime5
                time =ddd.split("-").concat(
                    ttt.split(":")
                );
              //  console.log('fileselectedvideo5==',this.state.fileselectedvideo5,'--');
               // console.log('time5555555555555==',time);
                time = (+new Date(time[0], time[1]-1, time[2], time[3], time[4], (time[5] ? time[5] : 0))).toString();
               // console.log('time5555555555==',time);
                finaldata=finaldata+'|'+this.state.fileselectedvideo5+'^'+time+'^'+this.state.fileselectedvideo5;
            }
            let data={
                orgid:this.props.match.params.orgid,
                time:time,
                projectid:this.state.projectid,
                fileselectedvideo:this.state.selectedvideo,
                broadcastdata:finaldata,
                id:this.state.selectedvideo,
                autopilotFile:this.state.selectedvideo,
            }
            console.log('data====',data);
            PostData('savebroadcastdata', data).then((result) => {
                if (result.statusCode === 200) {
                    console.log('===',result.message);
                    this.setState({
                        successmsg:result.message
                    }); 
                    setTimeout(() => {
                        window.location.reload();                     
                    }, 3000);                   
                }
            });
        }
        else
        {
            this.setState({
                errormsg:'Please select Video'      
            });  
        }
    }
    render() {
        let id=''
        let url=''
        let iframehtml=''
        let fileid=''
        let fileurl=''
        let fileiframehtml=''
        if(this.state.selectedvideo)
        {
            let forid =this.state.selectedvideo.split('/');
            console.log('forid===',forid);
            id=forid[2]
            url= 'https://player.vimeo.com/video/'+id+'?autoplay=1&loop=1';
            iframehtml='<iframe width="560" height="315" src="'+url+'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
        }
        if(this.state.fileid)
        {
           // let fileforid =this.state.fileselectedvideo.split('/');
           // fileid=fileforid[2]
            //fileurl= 'https://player.vimeo.com/video/'+fileid;
            let fileforid =this.state.fileid;
            //fileurl='http://localhost:8080/api/videolist?i='+fileforid; //Local
            fileurl='https://vpma.associationstudios.com/api/videolist?i='+fileforid; //live
            fileiframehtml='<iframe width="600" height="450" src="'+fileurl+'"></iframe>'
        }
        console.log('id===',id);
        let videolist = this.state.videolist;
        let filevideolist = this.state.filevideolist;
        let optionItems = videolist.map((item) =>{
                if (item.completedvideoarray && item.completedvideoarray.length>0 && item.completedvideoarray != "undefined") {
                    return item.completedvideoarray.map((inneritem) =>
                        <option value={inneritem} data-id={item._id}>{inneritem}</option>
                    );                
                }
            }
        );                                                                         
        let fileoptionItems = filevideolist.map((item) =>{                                     
                if (item.completedvideoarray && item.completedvideoarray.length>0 && item.completedvideoarray != "undefined") {
                    return item.completedvideoarray.map((inneritem) =>
                        <option value={inneritem} data-id={item._id}>{inneritem}</option>
                    );                
                }
            }
        );
        let dateStr1 = this.state.broadcastdate1;
        let timeStr1 = this.state.broadcasttime1;
        let dateStr2 = this.state.broadcastdate2;
        let timeStr2 = this.state.broadcasttime2;
        let dateStr3 = this.state.broadcastdate3;
        let timeStr3 = this.state.broadcasttime3;
        let dateStr4 = this.state.broadcastdate4;
        let timeStr4 = this.state.broadcasttime4;
        let dateStr5 = this.state.broadcastdate5;
        let timeStr5 = this.state.broadcasttime5;
        let draftvideosrc='';
        draftvideosrc="../../../draft/"+this.state.selectedvideo;
        return (
            <div className="container-fluid ProjectList"  ref="selfdiv">   
                <div className="row">
                    <div className="col-10">
                        <div className="row">
                            <div className="col-12">
                            <h3>Autopilot File</h3>
                            <div className="msg">
                                    <div className="errorsmsg">{this.state.autoerrormsg}</div>
                                    <div className="successmsg">{this.state.autosuccessmsg}</div>
                            </div>
                            <select name="taskstatus" className="form-control" value={this.state.selectedvideo} onChange={this.handleVideoChange.bind(this)}>
                                    <option value=" ">Select Video</option>  
                                    {optionItems}                          
                                </select>                        
                            </div>                        
                        </div><br/>
                        <div className="row prgramefiles">
                            <div className="col-12">
                                <div className="msg">
                                        <div className="errorsmsg">{this.state.errormsg}</div>
                                        <div className="successmsg">{this.state.successmsg}</div>
                                </div>
                                <h5>Program File 1</h5>                                
                            </div>
                            <div className="col-6">
                                <input type="date" onChange={e => this.onDateChange('broadcastdate1', e)}
                                value={dateStr1} />
                                <input type="time" onChange={e => this.onTimeChange('broadcasttime1', e)}
                                value={timeStr1} />
                            </div>
                            <div className="col-6">
                                <select name="taskstatus" className="form-control" value={this.state.fileselectedvideo1} onChange={this.handleProgrammeVideoChange.bind(this,'fileselectedvideo1')}>
                                    <option value=" ">Select Video</option>  
                                    {fileoptionItems}                          
                                </select>
                            </div>                            
                       </div><br/>
                       <div className="row prgramefiles">
                            <div className="col-12">
                                <h5>Program File 2</h5>
                            </div>
                            <div className="col-6">
                                <input type="date" onChange={e => this.onDateChange('broadcastdate2', e)}
                                value={dateStr2} />
                                <input type="time" onChange={e => this.onTimeChange('broadcasttime2', e)}
                                value={timeStr2} />
                            </div>
                            <div className="col-6">
                                <select name="taskstatus" className="form-control" value={this.state.fileselectedvideo2} onChange={this.handleProgrammeVideoChange.bind(this,'fileselectedvideo2')}>
                                    <option value=" ">Select Video</option>  
                                    {fileoptionItems}                          
                                </select>
                            </div>
                        </div><br/>
                       <div className="row prgramefiles">
                            <div className="col-12">
                                <h5>Program File 3</h5>
                            </div>
                            <div className="col-6">
                                <input type="date" onChange={e => this.onDateChange('broadcastdate3', e)}
                                value={dateStr3} />
                                <input type="time" onChange={e => this.onTimeChange('broadcasttime3', e)}
                                value={timeStr3} />
                            </div>
                            <div className="col-6">
                                <select name="taskstatus" className="form-control" value={this.state.fileselectedvideo3} onChange={this.handleProgrammeVideoChange.bind(this,'fileselectedvideo3')}>
                                    <option value=" ">Select Video</option>  
                                    {fileoptionItems}                          
                                </select>
                            </div>
                        </div><br/>
                       <div className="row prgramefiles">
                            <div className="col-12">
                                <h5>Program File 4</h5>
                            </div>
                            <div className="col-6">
                                <input type="date" onChange={e => this.onDateChange('broadcastdate4', e)}
                                value={dateStr4} />
                                <input type="time" onChange={e => this.onTimeChange('broadcasttime4', e)}
                                value={timeStr4} />
                            </div>
                            <div className="col-6">
                                <select name="taskstatus" className="form-control" value={this.state.fileselectedvideo4} onChange={this.handleProgrammeVideoChange.bind(this,'fileselectedvideo4')}>
                                    <option value=" ">Select Video</option>  
                                    {fileoptionItems}                          
                                </select>
                            </div>
                        </div><br/>
                       <div className="row prgramefiles">
                            <div className="col-12">
                                <h5>Program File 5</h5>
                            </div>
                            <div className="col-6">
                                <input type="date" onChange={e => this.onDateChange('broadcastdate5', e)}
                                value={dateStr5} />
                                <input type="time" onChange={e => this.onTimeChange('broadcasttime5', e)}
                                value={timeStr5} />
                            </div>
                            <div className="col-6">
                                <select name="taskstatus" className="form-control" value={this.state.fileselectedvideo5} onChange={this.handleProgrammeVideoChange.bind(this,'fileselectedvideo5')}>
                                    <option value=" ">Select Video</option>  
                                    {fileoptionItems}                          
                                </select>
                            </div>
                            <div className="col-12 alignright"><br/>
                                <a class="create_org btn btn-info" onClick={this.saveBroadCast.bind(this)}>Save Program Files</a>
                            </div>
                       </div><br/>                     
                        { (fileurl!='' && 
                            <div>    
                                {fileurl ? (
                                    <iframe src={fileurl} width="640" height="400" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
                                ) : (
                                    ''
                                )}                        
                                
                            </div>
                        )
                    }
                    </div>
                    <div className="col-2">
                        <ul className='actionsicon '>
                            <li>
                                <a className="forcusror disable" ><img src={require('../assets/images/embeded.png')} onClick={this.fileshowcode.bind(this)}/></a>
                                {
                                    ( this.state.fileshowcode===true && 
                                    <div>
                                        {fileiframehtml}
                                    </div>
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                </div> <br/><br/>
                                    
            </div>
        );
    }
}
export default BroadCast;
