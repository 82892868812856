import React, { Component } from "react";
import Select from "react-select";
import ReactTable from "react-table";
import {GetData,PostData} from '../../services/PostData';
import "react-table/react-table.css";  
import Loader from 'react-loader-spinner'
import { CSVLink, CSVDownload } from "react-csv";
import dateFormat from 'dateformat';
import { Link } from 'react-router-dom';
class TaskReports extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            timeLogList: [],
            originalTimeLogList: [],            
            filtered: [],
            select1: undefined,
            select2: undefined,
            isClearable: true,
            loading:false,
            csvfilename:'Everybody',           
            selectedOption1: {value:'',label:"Everybody"},
            selectedOption2: {value:'',label:"Task Status"},
        };
    }
    componentDidMount() {
        this.setState({loading:true});
        Promise.all([this.getTimeLog()])
		.then(([orgtaskdata])  => {
            this.setState({
                timeLogList: orgtaskdata.data,
                orginaltotaltime: orgtaskdata.projecttotaltime,
                totaltime: orgtaskdata.projecttotaltime,
                originalTimeLogList: orgtaskdata.data,
                loading:false
            });
           // this.onFilteredChangeCustom2('Active','status');
        });
        
    }
    getTimeLog(){
        return GetData('taskreport/').then((result) => {  
			return result;
		});
    }        
    onFilteredChangeCustom1 = (value, accessor) => {
        let selectedval=value;
        this.setState({ selectedOption1: {value:'',label:'Everybody'} });
        let filtered = this.state.filtered;        
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {                
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length)  filtered.splice(i, 1)  
                    else filter["value"] = value;
                    insertNewFilter = 0;                    
                }
            });
        }
        if (insertNewFilter) {
            this.setState({csvfilename:value});
            filtered.push({ id: accessor, value: value });
        }
        else
        {
            this.setState({csvfilename:'Everybody'});
        }
        if(value)
        {
            this.setState({ selectedOption1: {value:selectedval,label:value} });
            let datata=this.state.originalTimeLogList;
            let newdata=[];
            if (datata.length) {      
                datata.forEach((data, i) => { 
                    if(value===data.assigneduser)
                    {                                      
                        newdata.push(data);        
                    }             
                });
                this.setState({timeLogList:newdata});
            }    
        }
        else
        {
            this.setState({timeLogList:this.state.originalTimeLogList}); 
            this.setState({ selectedOption1: {value:'',label:'Everybody'} });
        }
        this.setState({ filtered: filtered });
    };    
    onFilteredChangeCustom2 = (value, accessor) => {
        let selectedval=value;
        this.setState({ selectedOption2: {value:'',label:'Task Status'} });
        let filtered = this.state.filtered;        
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {                
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length)  filtered.splice(i, 1)  
                    else filter["value"] = value;
                    insertNewFilter = 0;                    
                }
            });
        }
        if (insertNewFilter) {            
            filtered.push({ id: accessor, value: value });
        }        
        if(value)
        {
            this.setState({ selectedOption2: {value:selectedval,label:value} });
            let datata=this.state.originalTimeLogList;
            let newdata=[];
            if (datata.length) {      
                datata.forEach((data, i) => { 
                    if(value===data.taskstatus)
                    {                                      
                        newdata.push(data);        
                    }             
                });
                this.setState({timeLogList:newdata});
            }    
        }
        else
        {
            this.setState({timeLogList:this.state.originalTimeLogList}); 
            this.setState({ selectedOption2: {value:'',label:'Task Status'} });
        }
        this.setState({ filtered: filtered });
    };     
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    render() {
        const columns = [      
            {
                Header: 'ProjectID',
                accessor: 'project_id',
                show:false
            },
            {
                Header: 'OrgID',
                accessor: 'org_id',
                show:false
            },      
            {
                Header: 'Assigned User',
                accessor: 'assigneduser',                
            },
            {
                Header: 'Task',
                accessor: 'task',
                Cell: ({ row }) => (<Link to={{ pathname: `/org/${row.org_id}/project/view/${row.project_id}` }}>{row.task}</Link>),
            },
            {
                Header: 'Project',
                accessor: 'project',                
            },
            {
                Header: 'Organization',
                accessor: 'organization',                
            },            
            {
                Header: 'Due Date',
                accessor: 'duedate',
                Cell: ({ row }) =>{ 
                    return(<p>{dateFormat(row.duedate, "mm/dd/yyyy")}</p>)
                },
                width: 150
            },
            {
                Header: 'Task Status',
                accessor: 'taskstatus',
                
            },
            
        ];        
        const data=this.state.timeLogList;
        const isClearable= this.state.isClearable;      
        const csvData = [
            ["Assigned User","Task", "Projects","Organization","Due Date","Task Status"],
        ];       
        const filename = this.state.csvfilename; 
        const newDate = new Date()
        const currentm = parseInt(newDate.getMonth() +1);
        const currentdate = newDate.getDate()+'/'+currentm+'/'+newDate.getFullYear();
        const currenttime = newDate.getHours()+':'+newDate.getMinutes()+':'+newDate.getSeconds();       
        data.forEach((item, index, array) => {               
            var pusheddata = Array();
            let duedate= dateFormat(item.duedate, "mm/dd/yyyy");
            pusheddata.push(item.assigneduser,item.task,item.project,item.organization,duedate,item.taskstatus);
            csvData.push(pusheddata);             
        });              
		return (            
            <div className="container-fluid TaskListPage"  ref="selfdiv">
                <div className="export_timelog">
                    <CSVLink data={csvData} filename={filename+' '+currentdate+' at'+currenttime+'.csv'}><img src="https://img.icons8.com/color/48/000000/ms-excel.png" /></CSVLink>
                </div>            
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	}                
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-md-7 col-lg-7">
                                <h4 class="PageTopTitle">Task Report</h4>
                            </div>
                            <div className="col-md-2 col-lg-2"></div>
                            <div className="col-md-3 col-lg-3">
                            </div>
                        </div>
                    </div>                    
                    <div className="panel-body timelog-table-listing">
                       <div className="row">
                           <div className="col-6">
                                <Select
                                    placeholder="Everybody"
                                    className="basic-single projectlist"
                                    classNamePrefix="select"
                                    isClearable={isClearable}
                                    options={this.uniqueOptions(this.state.originalTimeLogList, "assigneduser").map((assigneduser, i) => {
                                    return { id: i, value: assigneduser, label: assigneduser };                      
                                    })}
                                    multi={true}
                                    onChange={entry => {
                                        if(!entry)
                                        {
                                            entry={id: '', value: '', label: ''};
                                        }
                                        this.setState({ select1: entry });
                                        this.onFilteredChangeCustom1(
                                        entry.value,
                                        "assigneduser"
                                        );
                                    }}
                                    value={this.state.selectedOption1}
                                />                        
                            </div>
                            <div className="col-6">
                                <Select
                                    defaultValue={{ label: "Active", value: "Active" }}
                                    placeholder="Task Status"
                                    className="basic-single status"
                                    classNamePrefix="select"
                                    isClearable={isClearable}
                                    options={this.uniqueOptions(this.state.originalTimeLogList, "taskstatus").map((taskstatus, i) => {
                                    return { id: i, value: taskstatus, label: taskstatus };                      
                                    })}
                                    multi={true}
                                    onChange={entry => {
                                        if(!entry)
                                        {
                                            entry={id: '', value: '', label: ''};
                                        }
                                        this.setState({ select2: entry });
                                        this.onFilteredChangeCustom2(
                                        entry.value,
                                        "taskstatus"
                                        );
                                    }}
                                    value={this.state.selectedOption2}
                                />                                
                            </div>
                        </div>                   
                        {   
                            (data.length>0) && 
                            <ReactTable
                                data={data}
                                filtered={this.state.filtered}
                                minRows = {0}
                                pageSizeOptions = {[10,50,100,500,1000]}
                                defaultPageSize = {10}                   
                                columns={columns}
                                className="-striped -highlight"
                                defaultSorted={[
                                    {
                                      id: "duedate",
                                      desc: true
                                    }
                                ]}
                            />                        
                        }       
                    </div>                
                </div>           
            </div>
		);
    }
}
export default TaskReports;
