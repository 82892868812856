import React, { Component } from "react";
import axios from "axios";
import {PostData,PostDataWithUpload} from '../../services/PostData';
import {apiConfig} from "../../config/api"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Configdata from '../../config.json';

class AddOrganization extends Component {
	//state = {
	  //  renewdate: new Date()
	//};
    constructor(){
	super();
	this.state = {
		fields: {},
		errors: {},
		selectedFile:'',
		isshowing_subscription_hour:false,
		isshowing_org_credit:false,
		subscription_hour:'',
		subscription_hour_rate:'',
		subscriber:'no',
		renewdate:'',
		dates: [
			{ date: 1 },
			{ date: 2 },
			{ date: 3 },
			{ date: 4 },
			{ date: 5 },
			{ date: 6 },
			{ date: 7 },
			{ date: 8 },
			{ date: 9 },
			{ date: 10 },
			{ date: 11 },
			{ date: 12 },			
			{ date: 13 },
			{ date: 14 },
			{ date: 15 },
			{ date: 16 },
			{ date: 17 },
			{ date: 18 },
			{ date: 19 },
			{ date: 20 },
			{ date: 21 },
			{ date: 22 },
			{ date: 23 },
			{ date: 24 },
			{ date: 25 },
			{ date: 26 },
			{ date: 27 },
			{ date: 28 },
		],
	};
	this.submit = this.submit.bind(this);
	this.onChange = this.onChange.bind(this);
	this.onChangeHandler = this.onChangeHandler.bind(this);
	this.isSubscriberChange = this.isSubscriberChange.bind(this);
	}
	handleChange = date => {
	    this.setState({
			renewdate: date
	    });
	}
	onChangeHandler=event=>{
	    this.setState({
	      'logo': event.target.files[0],
	      loaded: 0,
	    })
	  }
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	isSubscriberChange(e){
		console.log('e.target.value==',e.target.value);
		this.setState({[e.target.name]:e.target.value});
		if(e.target.value==='yes')
		{
			this.setState({isshowing_subscription_hour:true});
			this.setState({isshowing_org_credit:false});
			this.setState({org_credit:''});
		}
		else if(e.target.value==='no')
		{
			this.setState({isshowing_org_credit:true});
			this.setState({isshowing_subscription_hour:false});
			this.setState({subscription_hour:'',subscription_hour_rate:''});
		}		
	}
	async submit(e) {	

		e.preventDefault();
		if (this.validateForm())
		{
			console.log('AuthToken==',Configdata.vimeo.AuthToken);
			const response = await fetch(
			'https://api.vimeo.com/users/70047375/projects',
			{
				method: 'POST',
				headers: {
					Authorization: `bearer `+Configdata.vimeo.AuthToken,//old 0c909954139c00b4b07af0ce71811f3d
					'content-type': 'application/json',
				},
				body: JSON.stringify({
			        name : this.state.org_name,
			    }),
				}
		);
		const resdata = await response.json();
		console.log('resdata==',resdata);
		console.log('resdata==',resdata.uri);
			console.log('this.state.logo',this.state.logo);		
			const data = new FormData();
   			data.append('logo', this.state.logo)
   			data.append('org_name', this.state.org_name)
   			data.append('street', this.state.street)
   			data.append('city', this.state.city)
   			data.append('state', this.state.state)
   			data.append('zip', this.state.zip)
   			data.append('phone', this.state.phone)
   			data.append('twitter', this.state.twitter)
   			data.append('linkedin', this.state.linkedin)
   			data.append('subscriber', this.state.subscriber)
			data.append('subscription_hour', this.state.subscription_hour)
			data.append('subscription_hour_rate', this.state.subscription_hour_rate)
			data.append('renewdate', this.state.renewdate)
			data.append('nick_name', this.state.nick_name)
			//data.append('boxfolderid', this.state.boxfolderid)
 			data.append('org_credit', this.state.org_credit)
 			data.append('vimeo_uri', resdata.uri)
	    let session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
	    console.log('data======data=',data);
	    fetch(apiConfig.WEB_SERVICE_URL+'organization', {
		    method: 'POST',
		    body: data,
		    headers: {
		          "session-key": session_key,
		      }
	    })
	    .then((response) => response.json())
	    .then((res) => {
	     this.props.history.push("/organization/list");
	    });
		}
 	}
 	validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
      if (!fields["org_name"]) {
        formIsValid = false;
        errors["org_name"] = "*Please enter your Organization Name";
      }
      if (typeof fields["zip"] !== "undefined") {
        //regular expression for email validation
        var zip = /^[0-9]{5}(?:-[0-9]{4})?$/;
        if (!zip.test(fields["zip"])) {
          formIsValid = false;
          errors["zip"] = "*Please enter valid zip code.";
        }
      }
      if (typeof fields["phone"] !== "undefined") {
        //regular expression for email validation
        var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
        if (!phoneNum.test(fields["phone"])) {
          formIsValid = false;
          errors["phone"] = "*Please enter valid phone number.";
        }
      }  
      
      if (!fields["subscriber"]) {
        formIsValid = false;
        errors["subscriber"] = "*Please select subscriber";
        console.log('formIsValid=',formIsValid);
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
    }
	handleDateChange(e) {
		this.setState({ renewdate: e.target.value });
	}
    render() {
    	const isshowing_subscription_hour=this.state.isshowing_subscription_hour;
    	const isshowing_org_credit=this.state.isshowing_org_credit;
		const dates = this.state.dates.map(i => (
		    <option key={i.date} value={i.date}>
		      { i.date }		      
		    </option>
		  ));
        return (
        	<div className="container-fluid create-organization">
			 <div class="row wrapper-box">
			 <div className="panel panel-primary">
				<div className="panel-heading">
					<div className="row">
						<div className="col-md-7 col-lg-7">
							<h4 class="PageBarTitle">Create Organization</h4>
						</div>
						<div className="col-md-3 col-lg-3 pull-right">
							
						</div>
						<div className="col-md-2 col-lg-2 pull-right">
						</div>
						
					</div>
				</div>
			 
			 
           <div className="panel-body">
        	<form enctype="multipart/form-data">
			<div className="row " id="Body">
			<div className="col-md-12 FormDesign">
			<div className="form-label-group">
				<label htmlFor="org">Organization</label>				
				<input type="text" name="org_name" onChange={this.onChange} className="form-control" autoFocus />
				<div className="errorMsg">{this.state.errors.org_name}</div>				
			</div>
			<div className="row">
				<div className="col-sm-4">
					<label htmlFor="street">Street</label>					
					<input type="text" name="street" onChange={this.onChange} className="form-control" autoFocus />
				</div>
				<div className="col-sm-4">
					<label htmlFor="city">City</label>				
					<input type="text" name="city" onChange={this.onChange} className="form-control" autoFocus />
				</div>
				<div className="col-sm-4">
					<label htmlFor="state">State</label>
					<input type="text" name="state" onChange={this.onChange} className="form-control" autoFocus />
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<label htmlFor="zip">Zip</label>
					<input type="text" name="zip" onChange={this.onChange} className="form-control" autoFocus />
					<div className="errorMsg">{this.state.errors.zip}</div>	
				</div>
				<div className="col-sm-4">
					<label htmlFor="phone">Phone</label>
					<input type="text" name="phone" onChange={this.onChange} className="form-control" autoFocus />
					<div className="errorMsg">{this.state.errors.phone}</div>
				</div>
				<div className="col-sm-4">
					<label htmlFor="twitter">Twitter</label>
					<input type="text" name="twitter" onChange={this.onChange} className="form-control" autoFocus />
				</div>
			</div>
			<div className="row">
				<div className="col-sm-4">
					<label htmlFor="linkedin">LinkedIn</label>
					<input type="text" name="linkedin" onChange={this.onChange} className="form-control" autoFocus />
				</div>
				<div className="col-sm-4">
					<label htmlFor="logo">Upload Logo</label>
					<input type="file" name="logo" onChange={this.onChangeHandler} className="form-control" autoFocus />
				</div>
				<div className="col-sm-4">
					<label htmlFor="issubscriber">Subscriber</label><br/>
					<label className="radio-inline">
						<input type="radio" name="subscriber" onChange={this.isSubscriberChange} value="yes"/>Yes
					</label>
					<label className="radio-inline">
						<input type="radio" name="subscriber" onChange={this.isSubscriberChange} value="no" />No
					</label>	
					<div className="errorMsg">{this.state.errors.subscriber}</div>				
				</div>
			</div>
			<div className="row">
				<div className="col-sm-12">
					<label htmlFor="subscription_hour">Acronym/Nick Name</label>
					<input type="text" name="nick_name" onChange={this.onChange} className="form-control" autoFocus />
				</div>
			</div>
			{
				isshowing_subscription_hour &&
				<div className="row">
					<div className="col-sm-6">
						<label htmlFor="subscription_hour">Subscription Label (In Hrs/Month )</label>
						<input type="number" name="subscription_hour" onChange={this.onChange} className="form-control" autoFocus />
						{/* <select name="subscription_hour" value={this.state.value} className="form-control" onChange={this.isSubscriberChange}  id="subscription_hour">
				            <option value=" ">Select Hours</option>
				            <option value="20">20 Hrs/Month</option>
				            <option value="40">40 Hrs/Month</option>
				            <option value="60">60 Hrs/Month</option>
				        </select> */}
					</div>
					<div className="col-sm-6">
						<label htmlFor="subscription_hour">Hours Renew Date </label>
						<select name="taskstatus" className="form-control" value={this.state.renewdate} onChange={this.handleDateChange.bind(this)} >
							<option value=" ">Select Date</option>
							{dates}							
						</select>
						{/* <DatePicker
							dateFormat="MM-dd-yyyy"
							className="form-control"
							minDate={moment().toDate()}
							selected={this.state.renewdate}
							onChange={this.handleChange}
						/>					 */}
					</div>
					<div className="col-sm-12">
						<label htmlFor="subscription_hour">Subscribed Hourly Rate </label>
						<input type="number" name="subscription_hour_rate" onChange={this.onChange} className="form-control" autoFocus />
					</div>
				</div>
			}
			{ isshowing_org_credit &&
				<div className="row">
					<div className="col-sm-12">
						<label htmlFor="org_credit">Organization Credit</label>
						<input type="text" onChange={this.isSubscriberChange} className="form-control" name="org_credit" id="org_credit" />
					</div>
				</div>
			}
			{/* <div className="row">
			<div className="col-sm-4">
					<label htmlFor="linkedin">Box Folder ID</label>
					<input type="text" name="boxfolderid" onChange={this.onChange} className="form-control" autoFocus />
				</div>
			</div> */}
			
			<button type="submit" onClick={this.submit} className="UpdateBtn btn btn-primary">Create</button>
     		</div>
			</div>
			</form>
				</div>
				</div>
			</div>
			</div>
		);
    }
}
export default AddOrganization;