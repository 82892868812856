import React, { Component } from "react";
//import { Helmet } from 'react-helmet';
import { PostData, GetData } from '../services/PostData';

import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';
import VideoRecorder from 'react-video-recorder'
import {apiConfig} from "../config/api"
import Loader from 'react-loader-spinner'
class InviteeRecordVideo extends Component {
    constructor(props){
		super(props);
		this.state={
			errorname:'',
			erroremail:'',
			errormsg:'',
			errorfilename:'',
			issuccess:false,
			isauthenticate:false,
		}
	}
	componentDidMount() { 
		GetData('checkVideolink/'+this.props.match.params.videoid).then((result) => {             
		   console.log('result==',result);
		   if(result.status==='error' || result.status==='error')
		   {
			this.setState({
				errormsg:'Page Is Not Exist'
			});
		   }
		   else
		   {
			this.setState({
				issuccess:true,
				videopagedata:result.data
			});
		   }                                      
		});                                
	}
	onchange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	validatePage(e)
	{
		//console.log('calledddddddddd',this.state.videopagedata);
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;		
		//console.log('userdata===',userdata);
		//console.log('this.state.password===',this.state.password);
		if(!this.state.name)
		{
			//console.log('this.state.password in ifff ===',this.state.password);
			this.setState({
				errorname:'Please Enter name'
			});
			return;
		}
		else if(!this.state.email)
		{
			//console.log('this.state.password in ifff ===',this.state.password);
			this.setState({
				erroremail:'Please ente email'
			});
			return;
		}
		else if(!this.state.password)
		{
			//console.log('this.state.password in ifff ===',this.state.password);
			this.setState({
				page_pass:'Please add password'
			});
			return;
		}
		else{
			console.log('this.state.password in else ===',this.state.password);
			console.log('calledddddddddd',this.state.videopagedata);
			let vdodatapass=this.state.videopagedata;
			if(this.state.password===vdodatapass.password)
			{
				//console.log('in leseeeecalledddddddddd',this.state.videopagedata);
				this.setState({
					isauthenticate:true
				});
			}
			else
			{
				this.setState({
					page_pass:'Please enter valid password'
				});
			}
		}
	}
	onRecordingComplete :Function = (blobdata) => {
		console.log('blobdata===',blobdata);
		this.setState({
			blobdata:blobdata
		})
	}
	saveVideo()
	{
		if(!this.state.filename)
		{
			//console.log('this.state.password in ifff ===',this.state.password);
			this.setState({
				errorfilename:'Please enter file name'
			});
			return;
		}
		let blobdata=this.state.blobdata
		//let cnvtblbdate=this.blobToFile(blobdata,'blb.mp4');
		//let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"));
		console.log('userdata===',userdata);
		this.setState({
			loading:true
		});	
		const data = new FormData();
			//data.append('org_id', this.props.orgid)
			data.append('name',  userdata.loggedinname)
			data.append('email',  userdata.loggedinemail)
			data.append('filename',  this.state.filename)
			data.append('folderid',  this.props.match.params.folderid)
			data.append('blobfile',  blobdata)	

		var ajax = new XMLHttpRequest();
		ajax.upload.addEventListener("progress", this.progressHandler, false);
		ajax.addEventListener("load", this.completeHandler, false);
		ajax.addEventListener("error", this.errorHandler, false);
		ajax.addEventListener("abort", this.abortHandler, false);
		ajax.open("POST", apiConfig.WEB_SERVICE_URL+'savevideo'); 
		//ajax.setRequestHeader("session-key", session_key);
		ajax.send(data);
		return ajax;

	}
	saveVideo_old()
	{
		if(!this.state.filename)
		{
			//console.log('this.state.password in ifff ===',this.state.password);
			this.setState({
				errorfilename:'Please enter file name'
			});
			return;
		}
		let blobdata=this.state.blobdata
		//let cnvtblbdate=this.blobToFile(blobdata,'blb.mp4');
		//let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData"));
		console.log('userdata===',userdata);
		this.setState({
			loading:true
		});	
		const data = new FormData();
			//data.append('org_id', this.props.orgid)
			data.append('name',  userdata.loggedinname)
			data.append('email',  userdata.loggedinemail)
			data.append('filename',  this.state.filename)
			data.append('folderid',  this.props.match.params.folderid)
			data.append('blobfile',  blobdata)			
			//let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL+'savevideo', {
			method: 'POST',
			body: data,
			
			})
			.then((response) => response.json())
			.then((res) => {
				if(res.statusCode===200)
				{	
					console.log('res====',res);
					//this.props.view('viewprojectlist');	
					this.setState({
						loading:false,
						filename:'',
						fileuploadsuccess:res.message
					});
					setTimeout(() => {
						window.location.reload(); 
					}, 3000);
				}
			});
	}
	progressHandler(event) {
		console.log( "Uploaded " + event.loaded + " bytes of " + event.total);
		var percent = (event.loaded / event.total) * 100;
		console.log( Math.round(percent));
		console.log( Math.round(percent) + "% uploaded... please wait");
		//this.setState({loadingstatus:Math.round(percent) + "% uploaded... please wait"});
		document.getElementById("loadingstatus").innerHTML =Math.round(percent) + "% uploaded... please wait";
	}

	completeHandler(event) {
		console.log( 'completee fhandler111',event.target.responseText);
		var res =event.target.responseText;
		//var res = data.json();
		var res = JSON.parse(res);
		console.log('checkresposise11==',res,res.statuscode);
		/*console.log('checkresposise11==',res,res.statuscode); 
		if(res.statuscode===200)
		{	
			window.location.reload(false);		
			console.log('status code==',res.statuscode);     		    
		} */
		if(res.statusCode===200)
		{	
			console.log('res====',res);
			//this.props.view('viewprojectlist');	
			/*this.setState({
				loading:false,
				filename:'',
				fileuploadsuccess:res.message
			});*/
			setTimeout(() => {
				window.location.reload(); 
			}, 3000);
		}
	}

	errorHandler(event) {
		console.log("Upload Failed");
	}

	abortHandler(event) {
		console.log("Upload Aborted");
	}
	blobToFile(theBlob, fileName){
	    //A Blob() is almost a File() - it's just missing the two properties below which we will add
	    
        console.log('theBlob==',theBlob,new Date());
	    theBlob.lastModifiedDate = new Date();
	    theBlob.name = fileName;
	    return theBlob;
	}
	render() {	
	
        return (
        	<div className="ProducerSaveDate AddStoryBoard RecordVideo">
        		<div className="successmsg">{this.state.fileuploadsuccess}</div>
        		{
					this.state.loading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
							<div id="loadingstatus"></div>
						</div>
						: null
				}
				<div className="row">
					<div className="col-8">
						<h3>Record Video</h3>							
					</div>
					<div className="col-4"></div>
					</div>
					<div className="row">
					<div className="col-12">{this.state.errormsg}</div>
					</div>
					
						<div>
							<div className="row videorow">
							<div className="col-12">
						 <VideoRecorder
						 isFlipped={false}
						 chunkSize={10} 
							constraints={{ audio: true, video: { width: 1920, height: 1080,  }, }}

							onRecordingComplete={this.onRecordingComplete}
							replayVideoAutoplayAndLoopOff
  							//showReplayControls
						/> 

						</div>
						</div>
						<div className="row VideoEmailField">
							<div className="col-6">
						<label>Please enter file name:</label><input type="text"  placeholder="Enter file name *" className="pagepassword" name="filename" onChange={this.onchange.bind(this)} autoFocus />
							<div className="errorMsg">{this.state.errorfilename}</div>
						</div>
						<div className="col-6"></div>
						</div>
						<div className="row">
							<div className="col-6">
						<a className="forcusror graybtn ComIncLink btn btn-primary" onClick={this.saveVideo.bind(this)} >Save Video</a>
						</div>
						<div className="col-6"></div>
						</div>
						</div>
						
				
			</div>	
		);
	}
}
export default InviteeRecordVideo;