import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {PostData} from '../services/PostData';
import Loader from 'react-loader-spinner'
class InviteeLogin extends Component {
    constructor(props)
    {
		super(props);
		this.state = {
			fields: {},
		    errors: {},
		    loading: false,
			servereerror:'',
			displayType:'login',
			folderid:this.props.match.params.folderid
		};
		this.login = this.login.bind(this);
		this.onChange = this.onChange.bind(this);
	}
	componentDidMount(){
		console.log('cacccleddd in cponnet did mount');
		localStorage.setItem('userData','');
	}
	onChange(e){
		//console.log('cleeed in check==',e.target.value);
		this.setState({[e.target.name]:e.target.value});
	}
	login() {	
		if (this.validateForm())
		{
			if(this.state.email)
			{
				this.setState({ loading: true });
				PostData('inviteelogin',this.state).then((result) => {				
					let responseJson = result;
					if(responseJson.statuscode==200)
					{
						console.log('responseJson==',responseJson);///subs/project/list
						this.setState({loading: false,});
						localStorage.setItem('userData',JSON.stringify(responseJson));
						let redirectURL='/invitee/'+responseJson.data.orgid+'/assets';
						this.props.history.push(redirectURL);
						//console.log('responseJson.data==',responseJson.data.orgid);
						//console.log('responseJson==',responseJson);
						//console.log('responseJson.data.user.role.administrator==',responseJson.data.user.role.administrator);///subs/project/list
						
						//let redirectURL='subs/org/'+responseJson.data.orgid+'/project/list';
						//localStorage.setItem('userData',JSON.stringify(responseJson));
						// if(responseJson.data.user.role==='administrator')
						// {
						// 	console.log('in iffffffffffff admin');
						// 	this.props.history.push("/profile");
						// }
						// else
						// {
						// 	console.log('in iffffffffffff subs',redirectURL);
						// 	this.props.history.push(redirectURL);
						// }
						//console.log('non admin==',responseJson.data);///subs/project/list
						//this.props.history.push("/vpma");
						///subs/org/:orgid/project/list
					}
					else
					{
						this.setState({servereerror: responseJson.message, loading: false,});
					}
				});
			}
		}
	}
	validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
      if (!fields["email"]) {
        formIsValid = false;
        errors["user_email"] = "*Please enter your email-ID.";
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
	}  
    render() {
        const { errors, formSubmitted,loading } = this.state;
        const username=localStorage.getItem('username');
		const password=localStorage.getItem('password');
		const displayType=this.state.displayType;
        if (localStorage.getItem('userData')){
			console.log('in userdata exist ');
        	//return (<Redirect to={'/vpma'}/>)
		}
        return (	
			<div className="login-section">
				<div className="login-section-in">
				   <div className="container">	
						<div class="row">
						<div class="col-12 col-sm-12 col-md-7 col-lg-7">
							<div class="LoginVector">
							<img class="" src={require('../assets/images/girl.png')}/>
							</div>
						</div>
						<div class="col-12 col-sm-12 col-md-5 col-lg-5">
						<div className="login-form">
							{ 	(displayType ==='login' &&
										<div>
											<h2>Login</h2>   
											{
												loading ?<div className="loaderspinner">
													<Loader
														type="TailSpin"
														color="#D10000"
														height={100}
														width={100}						         
													/>
												</div> :null
											}
											<div className="errorMsg">{this.state.servereerror}</div>
											<div className="form-group">
												<input type="text" placeholder="First Name" name="fname" onChange={this.onChange}  autoFocus />										
											</div>
											<div className="form-group">
												<input type="text" placeholder="Last Name" name="lname" onChange={this.onChange}  autoFocus />										
											</div>
											<div className="form-group">
												<input type="text" placeholder="Email Address" name="email" onChange={this.onChange}  autoFocus />
												<div className="errorMsg">{this.state.errors.user_email}</div>
											</div>
											<div className="form-group">
												<button type="submit" onClick={this.login} className="btn-signin sign-marg-no">Login</button>
											</div>																	
										</div>
									)														
								}							
								</div>
							</div>
						</div>
					</div>
				</div>				
				<div class="LoginFooter">
					<div class="container-fluid">
						<div class="row">
							<p>Copyright © Association Studios. All Right Reserved.</p>
						</div>
					</div>
				</div>
			</div>			
		);
    }
}
export default InviteeLogin;
