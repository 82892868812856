// import logo from '../assets/img/vpma-page-navbar-logo.png';
import logo from '../assets/img/vpma-page-navbar-logo.png'
import React from "react";


export default class Header extends React.Component {
    render() {
        return (
            <><div className="loginPage">
                <header id="masthead" className="site-header">
                    <div className="site-logo vpma-logo">
                        <a className="custom-logo-link" href="/">
                            <img className="custom-logo" src={logo} alt="logo" />
                        </a>
                    </div>
                    <div className="header-contact">
                        <div className="contact-number">
                            <div className="contact-phone">
                                <a href="tel:8008206020">(800) 820-6020</a>
                            </div>
                        </div>
                    </div>
                </header>
                </div>
            </>
        )
    }
}
