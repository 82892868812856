import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
class StoryBoard extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			storyboardsuccessmessage: '',	
			values: [""],
			message: [],
			visual: [],
			scene: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Saven', 'Eight', 'Nine', 'Ten'],
		};
		//this.addClick();
		//this.createUI();
	}	
	handleMessageChange(event, i) {
		const rawState = JSON.stringify(convertToRaw(event.getCurrentContent()));
		let values = [...this.state.values];
		values[i] = rawState;
		this.setState({ values });

		let message = [...this.state.message];
		message[i] = rawState;
		this.setState({ message });
	}
	handleVisualChange(event, i) {
		const rawState = JSON.stringify(convertToRaw(event.getCurrentContent()));
		let values = [...this.state.values];
		values[i] = rawState;
		this.setState({ values });

		let visual = [...this.state.visual];
		visual[i] = rawState;
		this.setState({ visual });
	}
	saveStoryBoard(event) {
		if (this.validateUploadStoryForm()) {
			event.preventDefault();
			this.setState({ saveBoardDataloader: true });
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			const data = {
				org_id: this.props.orgid,
				projectid: this.props.prjid,
				message: this.state.message,
				visual: this.state.visual,
				type: 'storyboard',
				userid: userdata.user.ID,
			};
			let session_key = userdata.token;
			PostData('storyboard', data).then((result) => {
				if (result.statuscode === 200) {
					this.setState({ storyboardsuccessmessage: result.message, saveBoardDataloader: false, data: [], values: [], message: [], visual: [], });					
					this.addClick();
					setTimeout(() => {
						this.setState({  storyboardsuccessmessage: '' });
						this.props.loadProjectBoard();
					}, 3000);	
				}
			});
		}
	}
	validateUploadStoryForm() {
		let message = this.state.message;
		let visual = this.state.visual;
		let errors = {};
		let formIsValid = true;
		if (message.length > 0) {
			message.forEach(
				function iterator(item) {
					const contentState = convertFromRaw(JSON.parse(item));
					const editorState = EditorState.createWithContent(contentState);
					const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
					const description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
					if (!description.trim()) {
						errors["storyboard"] = "*All fields are manadatory";
						formIsValid = false;
					}
				}
			);
		}
		else {
			errors["storyboard"] = "*All fields are manadatory";
			this.setState({
				errors: errors
			});
			return false;
		}
		if (visual.length > 0) {
			visual.forEach(
				function iterator(item) {
					const contentState = convertFromRaw(JSON.parse(item));
					const editorState = EditorState.createWithContent(contentState);
					const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
					const description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
					if (!description.trim()) {
						errors["storyboard"] = "*All fields are manadatory";
						formIsValid = false;
					}
				}
			);
		}
		else {
			errors["storyboard"] = "*All fields are manadatory";
			this.setState({
				errors: errors
			});
			return false;
		}
		this.setState({
			errors: errors
		});

		return formIsValid;
	}
	createUI() {
		//console.log('callleddd createUI',this.state.values,this.state.values.length);
		return this.state.values.map((el, i) =>
			<div key={i}>
				<div className="row LP_Row">
					<div className="col-12 LeftLocation">
						<label>Scene {this.state.scene[i]}</label>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 LeftLocation">
						<Editor message={this.state.message} wrapperClassName="demo-wrapper" editorClassName="editer-content" placeholder='Script' onEditorStateChange={(e) => this.handleMessageChange(e, i)}
							toolbar={{
								options: ['inline', 'list', 'image', 'emoji'],
							}}
						/>
						{/* <input type="text"  onChange={this.handleMessageChange.bind(this, i)} />			 */}
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6 LeftLocation">
						<Editor visual={this.state.visual} wrapperClassName="demo-wrapper" editorClassName="editer-content" placeholder='Visual' onEditorStateChange={(e) => this.handleVisualChange(e, i)}
							toolbar={{
								options: ['inline', 'list', 'image', 'emoji'],
							}}
						/>
					</div>
				</div>
				{i != 0 &&
					<div className="CancelRepeatField">
						<div className="CancelInRepField">
							<img src={require('../../../assets/images/remove_icon.png')} />
							<input type='button' className="removeitem" value='remove' onClick={this.removeClick.bind(this, i)} />
						</div>
					</div>
				}
			</div>
		)
	}
	addClick() {
		console.log('calleddd addClick');
		if (this.state.values.length > 9) {
			alert("You can add more then 10")
			return false;
		}
		this.setState(prevState => ({ values: [...prevState.values, ''] }))
	}

	removeClick(i) {
		let values = [...this.state.values];
		values.splice(i, 1);
		this.setState({ values });

		let message = [...this.state.message];
		message.splice(i, 1);
		this.setState({ message });

		let visual = [...this.state.visual];
		visual.splice(i, 1);
		this.setState({ visual });
	}
    render() {

        return (
        	<div className="ProducerSaveDate AddStoryBoard">
				<img className="SD_img_icon" src={this.props.avatar} />
				{
					this.state.saveBoardDataloader ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<h3 class="AddTitleDrop">Storyboard</h3>
				<div className="clearfix"></div>
				<div className="succes-msg">{this.state.storyboardsuccessmessage}</div>
				<div className="errorMsg">{this.state.errors.storyboard}</div>
				<div className="row StoryboardTOpLabel">
					<div className="col-12 col-sm-12 col-md-6 col-lg-6">
						<label>Script</label>
					</div>
					<div className="col-12 col-sm-12 col-md-6 col-lg-6">
						<label>Visual</label>
					</div>
				</div>
				{this.createUI()}
				<div className="clearfix"></div>
				<div className="row AddRemoveStoryBtn">
					<div className="BtnAddRemove">
						<div class="relativebox">
							<img src={require('../../../assets/images/add_icon.png')} />
							<input type='button' className="addmore" value='add more' onClick={this.addClick.bind(this)} />
						</div>
						<button type="button" onClick={(e) => this.saveStoryBoard(e)} className="UpdateBtn btn btn-primary">Save</button>
					</div>
					<hr />
				</div>
			</div>	
		);
    }
}
export default StoryBoard;