import React, {useState,useEffect} from 'react';
import creditcardicons from '../assets/images/creditcard-icons.png';
import ordercardimg from '../assets/images/order-card-img.png';
import Popup from './Popup';
import { toast } from 'react-toastify';

import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
  } from 'react-stripe-elements'
  import axios from 'axios'
  //import './CheckoutForm.scss'
  import "../assets/css/CheckoutForm.css";
  import {PostData,GetData} from '../services/PostData';
  import { Country, State, City }  from 'country-state-city';

  const OrderCheckoutForm = ({  orgid,stripe, history }) => {
	console.log('props.orgid=',orgid);
	const [state, setState] = React.useState({
		first_name: '',
		last_name:'',
		email_name:'',
		st_address:'',
		st_address2:'',
		city:'',
		state:'',
		zipcode:'',
		country:'',
		country:'',
		orderamount:3000,
		videocost:3000,
		orgid:orgid,
		tokenid:'',
		coupon:'',
		qty:1,
	})
	//const amount = 10;
	const [ddcard, setDdcard] = useState('')
	const [receiptUrl, setReceiptUrl] = useState('')
    //const [name,setName] =useState('');
	//const [email,setEmail] =useState('');
	const [isOpen, setIsOpen] = useState(false);
	const countrylist = Country.getAllCountries();
	
	console.log('Country, State=',Country.getAllCountries())
	const [selectedCountry, setSelectedCountry] = React.useState();
	
	let stateslist;
	stateslist = State.getStatesOfCountry('US');
	if(selectedCountry)
	{
		let countrydata = JSON.parse(selectedCountry);
		//console.log('selectedCountry=',countrydata.isoCode);
		 stateslist = State.getStatesOfCountry(countrydata.isoCode);
		console.log('stateslist=',stateslist);
	}
	const [selectedState, setSelectedState] = React.useState();
	const availableState = countrylist.find((c) => c.name === selectedCountry);
	console.log('availableState==',availableState);
	//console.log('Country, State=',Country, State);
	
	const applyCoupon = async (event) => {
		console.log('coupon=',state.coupon);
		if(state.coupon)
		{
			let data={
				coupon:state.coupon,
				orderamount:50,
			}
			PostData('ordercoupon',data).then((result) => {		
				console.log('result==',result);		
				if(result.statusCode===200)
				{
					//setReceiptUrl(true)	
					toast.success('Coupon Applied Successfully');
					console.log('Success');
					let finalamt = state.videocost*state.qty;
					const finalcost =finalamt/2;
					setState({
						...state,
						['orderamount']: finalcost
					});
				}
				else{
					toast.error('Invalid Coupon');
					console.log('invalid code');
				}		
				//setReceiptUrl(result.charge.receipt_url)		
			});
		}
		else{
			toast.error('Please enter coupon Code');
		}
		
	}
	const handleSubmit = async (event) => {
		console.log('handleSubmit');
	  event.preventDefault()
   //console.log();
	  console.log('state',state);
  	  //	return false;
	  const { token } = await stripe.createToken()
	  console.log('token=',token);
	  console.log('Country, State=',Country, State);
	  
	
	if(token)
	{
		state.token = token;
		PostData('order',state).then((result) => {		
			console.log('result==',result);		
			if(result.statuscode===200)
			{
				setReceiptUrl(true)	
			}		
			//setReceiptUrl(result.charge.receipt_url)		
		});

	}
	  
	}
	const onToggleEn =(e) =>{
		console.log('index==');
		 //let value = e.target.checked;
		let value = e.target.value; //value
		console.log('value==',value);
		setDdcard(value);
	}
	const onCouponChange = (e) => {
		let value = e.target.value;
		setState({
			...state,
			[e.target.name]: value
		});
	}
	const onChange =(e) =>{
		//console.log('index==');
		 //let value = e.target.checked;
		let value = e.target.value; //value
		console.log('value==',value);
		//setDdcard(value);
		//const value = evt.target.value;
		setState({
			...state,
			[e.target.name]: value
		});
	}
	const onQTYChange =(e) =>{
		//console.log('index==');
		 //let value = e.target.checked;
		let value = e.target.value; //value
		console.log('value==',value);
		const cost = value*state.videocost;
		//setDdcard(value);
		//const value = evt.target.value;

		setState({
			...state,
			[e.target.name]: value,
			orderamount:cost,
		});
	}
	
	//onChange(e){
		////this.setState({[e.target.name]:e.target.value});
//	}
	const handleSavedCardChange = (e) =>{
		console.log('e.target.value==',e.target.value);
		setDdcard(e.target.value);
	}

	const togglePopup = () => {
		setIsOpen(!isOpen);
	}
	
	if (receiptUrl) {
		return (
		  <div className="success">
			<h2>Payment Successful!</h2>
			
		  </div>
		)
	  }
	  
	
	  return (
		<div className="checkout-form11 stripe-form11">
			  			   
		  <div className="row">
		  	<div className="col-6 col-sm-6 col-md-6 col-lg-6">
				<label for="projectname">First Name<span>*</span></label>
				<input type="text" name="first_name" value={state.first_name}
 placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
				
			</div> 
			<div className="col-6 col-sm-6 col-md-6 col-lg-6">
				<label for="projectname">Last Name</label>
				<input type="text" name="last_name" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
				
			</div> 
    
			<div className="col-12 col-sm-12 col-md-12 col-lg-12">
				<label for="projectname">Email<span>*</span></label>
				<input type="text" name="email_name" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
				
			</div>
			<div className="col-col-12 col-sm-12 col-md-12 col-lg-12">
				<label for="projectname">Street Adrress<span>*</span></label>
				<input type="text" name="st_address" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
				
				<input type="text" name="st_address2" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
			</div> 
			</div>  

			<div className="row city_state_wrap_group">
			
			<div className="col-6 col-sm-6 col-md-6 col-lg-6">
				<label for="projectname">Country<span>*</span></label>
				<select
					placeholder="Country"
					//value={this.state.selectedCountry}
					onChange={(e) => setSelectedCountry(e.target.value)}
					className="PB_Title form-control" 
				>
					<option>Select Country</option>
					{countrylist.map((e, key) => {
					return <option key={key} value={JSON.stringify(e)} selected={e.name == 'United States' ? true : false} >{e.name}</option>;
					})}
				</select>
				{/* <input type="text" name="country" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus /> */}
				
			</div>  
			<div className="col-6 col-sm-6 col-md-6 col-lg-6">
				<label for="projectname">State<span>*</span></label>
				<select
				    className="PB_Title form-control"
					placeholder="State"
					value={selectedState}
					onChange={(e) => setSelectedState(e.target.value)}
					>
					<option>Select State</option>
					{stateslist?.map((e, key) => {
						return (
						<option value={e.name} key={key}>
							{e.name}
						</option>
						);
					})}
					</select>
				{/* <input type="text" name="state" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus /> */}
				
			</div>    
			<div className="col-6 col-sm-6 col-md-6 col-lg-6">
				<label for="projectname">City<span>*</span></label>
				<input type="text" name="city" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
				
			</div> 	
			<div className="col-6 col-sm-6 col-md-6 col-lg-6">
				<label for="projectname">Postal/Zip Code<span>*</span></label>
				<input type="text" name="zipcode" placeholder="" onChange={onChange} className="PB_Title form-control" autoFocus />
				
			</div> 
			

			</div>
			<div className="row">
			<div className="col-col-12 col-sm-12 col-md-12 col-lg-12 order-table">
				<h3>Payment Summary</h3>
				<table>
					<tr>
						<th width="20%">#</th>
						<th width="40%">Item</th>
						<th width="40%">Price</th>
					</tr>
					<tr>
						<td><input className="video-qty" type="number" id="qty" value={state.qty || 1} onChange={onQTYChange}  name="qty" min="1" max="10" /></td>
						<td> Video</td>
						<td>${state.orderamount}</td>
					</tr>
					<tr>
						<td></td>
						<td>Total</td>
						<td>${state.orderamount}</td>
					</tr>
				</table>
			</div>	
			</div>
			<div className="row">
				<div className="col-sm-4">
					<div className="row">
					<div className="col-sm-8">
						<label for="projectname">Coupon</label>
						<input type="text" name="coupon" placeholder="" onChange={onCouponChange} className="PB_Title form-control" autoFocus />
					</div>
					<div className="col-sm-4">
					<button type="button" onClick={(e) => {applyCoupon(e)}} className="order-button">
						Apply
					</button>
					</div>
					</div>
				</div>
				<div className="col-sm-8"></div>
			</div>
			<div className="row card_details">
			<div className="col-12 col-sm-12 col-md-12 col-lg-12">
				<label for="projectname">Credit Card <span>*</span></label><br/>
				<img src={creditcardicons} />
				<br/><br/>
			</div>
			<div className="col-4 col-sm-4 col-md-4 col-lg-4">
				<label for="projectname">Card details<span>*</span></label>
				<CardNumberElement />
			</div>	
			<div className="col-4 col-sm-4 col-md-4 col-lg-4">
				<label for="projectname">Expiration date<span>*</span></label>
				<CardExpiryElement />
			</div>	
			<div className="col-4 col-sm-4 col-md-4 col-lg-4">
				<label for="projectname">CVC<span>*</span></label>
				<CardCVCElement />
			</div>	

			<button type="button" onClick={(e) => {handleSubmit(e)}} className="order-button">
			  Pay
			</button>
			
		  </div>
		  <div className="order_form_bottom_text">
			<p>Travel not included. To learn about travel expenses <a onClick={togglePopup} className="popup_trigger">Click here. </a> 
			All videos must be completed within 12 months. Full refund available within 30 days or until production begins.</p>
			<p>Need an invoice or to pay by check? Click Here</p>
			<img src={ordercardimg} />
		</div>
		
	{isOpen && <Popup
      content={<>
        <p>Did you know that over 50% of our video productions do not require travel? That said, sometimes productions do require live action video and we are happy to send one or more
			videographers anywhere around the world to capture whatever you need.</p>
			<p>We always do our best to make travel simple and affordable. There are only two expenses:</p>
			<p>$500 per person, per day<br/>
Reimbursement of expenses (i.e. air fare, hotel)<br/>
If you have any questions about travel for your video project please give us a call: (800) 820-6020</p>
      </>}
      handleClose={togglePopup}
    />}
		</div>

	  )
	}


 
export default injectStripe(OrderCheckoutForm)