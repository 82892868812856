import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {PostData} from '../services/PostData';
import loginbg from '../assets/images/login-bg1.png';
import Loader from 'react-loader-spinner'
class LostPassword extends Component {
    constructor()
    {
		super();
		this.state = {
			redirectToReferrer: false,
			fields: {},
		    errors: {},
		    loading: false,
		    username:'',
			servereerror:'',
			serversucess:''
		};
		this.submit = this.submit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.checkboxChange = this.checkboxChange.bind(this);
	}
	
	checkboxChange(e){
		let isChecked = e.target.checked;
		this.setState({[e.target.name]:isChecked});
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	submit() {	
		if (this.validateForm())
		{
			let data = {
				'useremail': this.state.user_email,
			}
			this.setState({ loading: true });
			PostData('lostpassword',data).then((result) => {		
				console.log('result====',result);		
				if(result.statusCode==200)
				{						
					this.setState({serversucess: result.message, loading: false,user_email:''});
					setTimeout(() => this.setState({ serversucess: ''}), 3000);
				}
				else
				{
					this.setState({servereerror: result.message, loading: false,});
					setTimeout(() => this.setState({ servereerror: ''}), 3000);
				}
			});
		}
	}
	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["user_email"]) {
			formIsValid = false;
			errors["user_email"] = "*Please enter your email-ID.";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}  
	changeView(){
		this.props.changeView();	
	}
    render() {
        const { errors, formSubmitted,loading } = this.state;
        const username=localStorage.getItem('username');
		const password=localStorage.getItem('password');
        if (localStorage.getItem('userData')){
        	return (<Redirect to={'/vpma'}/>)
		}
        return (	
			<div>
				<h2>Lost Password</h2>   
				{
					loading ?<div className="loaderspinner">
						<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}						         
						/>
					</div> :null
				}
				<div className="succes-msg">{this.state.serversucess}</div>
				<div className="errorMsg">{this.state.servereerror}</div>
					<div className="form-group">
					<input type="text" value={this.state.user_email} placeholder="Please enter your Email Address*" name="user_email" onChange={this.onChange}  autoFocus />
					<div className="errorMsg">{this.state.errors.user_email}</div>
				</div>
				<div className="form-group">
					<button type="submit" onClick={this.submit} className="btn-signin sign-marg-no">Submit</button>
				</div>
				<div class="row LostForgot">
					<div className="col-12 form-group">
					<a className="forcusror" onClick={this.changeView.bind(this)} >Click here for Login</a>
					</div>								
				</div>						    
			</div>	
		);
    }
}
export default LostPassword;
