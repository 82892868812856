import React from 'react'
import icon1 from '../assets/img/icon-1.png'
import icon2 from '../assets/img/icon-2.png'
import icon3 from '../assets/img/icon-3.png'
import icon4 from '../assets/img/icon-4.png'
import vpmaVideoAssociation from '../assets/img/vpma-video-association-img.png'
export default class Section2 extends React.Component {
    render() {
        return (
            <>
                <section className="section-gapping videos-association vpma-videos-association">
                    <div className="container left-right-warp">
                        <div className="right vc_col-sm-6">
                            <h2 className="common-title mobile-title-vpma wow fadeInUp">Meet With Genuine<br />
                                <em>Video Production Professionals</em></h2>
                            <div className="wow fadeInLeft">
                                <div className="common-img-warp">
                                    <img src={vpmaVideoAssociation} />
                                </div>
                            </div>
                        </div>
                        <div className="left vc_col-sm-6">
                            <h2 className="common-title desktop-title-vpma wow">
                                Meet With Genuine<br />
                                <em>Video Production Professionals</em>
                            </h2>
                            <div className="real-video-content wow fadeInUp">
                                <p>We have the professionals you need to make a powerful, engaging video. Working with Association Studios gives you access to an entire production studio, from script writers to voice artists, and even animators. Our professional team has decades of experience helping associations make incredible videos.</p>
                            </div>
                            <div className="img-content-warp first wow fadeInUp">
                                <div className="icon-img vc_col-sm-3">
                                    <div className="common-img-warp">
                                        <img src={icon1} alt="Script Writing" />
                                    </div>
                                </div>
                                <div className="title-content-warp vc_col-sm-9">
                                    <h2 className="title">Script Writing</h2>
                                    <div className="content">
                                        <p>The video script is the backbone of your video. Our professional script writers have written thousands of engaging video scripts for associations all over the country.<a className="redplus-icon" href="#">+</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="img-content-warp wow fadeInUp">
                                <div className="icon-img vc_col-sm-3">
                                    <div className="common-img-warp">
                                        <img src={icon2} alt="Voice Artist" />
                                    </div>
                                </div>
                                <div className="title-content-warp vc_col-sm-9">
                                    <h2 className="title">Voice Artist</h2>
                                    <div className="content">
                                        <p>The right voice brings your video to life, bringing mere words on the page into the real world. Our voice over artists know how to voice an engaging, lively video.<a className="redplus-icon" href="#">+</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="img-content-warp wow fadeInUp">
                                <div className="icon-img vc_col-sm-3">
                                    <div className="common-img-warp">
                                        <img src={icon3} alt="Animators" />
                                    </div>
                                </div>
                                <div className="title-content-warp vc_col-sm-9">
                                    <h2 className="title">Animators</h2>
                                    <div className="content">
                                        <div className="wpb_wrapper">
                                            <p>Whether you need a cartoon animation or a graphic motion animated explainer, Association Studios has experienced animators to bring your videos to life.<a className="redplus-icon" href="#">+</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="img-content-warp wow fadeInUp">
                                <div className="icon-img vc_col-sm-3">
                                    <div className="common-img-warp">
                                        <img src={icon4} alt="Editors" />
                                    </div>
                                </div>
                                <div className="title-content-warp vc_col-sm-9">
                                    <h2 className="title">Editors</h2>
                                    <div className="content">
                                        <p>The real magic of video production happens in the post-production editing. Our editors are ready to put their tens of thousands of hours of editing experience to work for you..<a className="redplus-icon" href="#">+</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}