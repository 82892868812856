import React, { Component } from "react";
import { Row, FormGroup, FormControl, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import { withRouter,Redirect,Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {PostData,GetData,DeleteData} from '../../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SidebarUpdate from '../../components/Sidebar';
import dateFormat from 'dateformat';
class ItemData extends Component {
    constructor(props) {
        super(props);
        this.state = {            
        };
        
    }      
    render() {
        let itemtype=this.props.itemtype;
        let item=''
        console.log('this.props.itemtype==',this.props.itemtype);
        console.log('this.props.item==',this.props.item);
        let id=''
        let url=''
        let iframehtml=''
        if(this.props.itemtype==='video' || this.props.itemtype==='embeded')
        {
            if(this.props.item)
            {
                let forid =this.props.item.split('/');
                console.log('forid===',forid);
                id=forid[2]
                url= 'https://player.vimeo.com/video/'+id;
                iframehtml='<iframe width="560" height="315" src='+url+' frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
            }
        }
        console.log('id===',id);
        return (
			<div className="itemfata"  ref="selfdiv">
                { (itemtype==='description' &&
                        <div>                            
                            Description:{this.props.item}
                        </div>
                    )
                }
                { (itemtype==='video' && 
                        <div>    
                            {url ? (
                                <iframe src={url} width="640" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                            ) : (
                                ''
                            )}                        
                            
                        </div>
                    )
                }
                { (itemtype==='embeded' &&
                        <div>                            
                            {iframehtml}
                        </div>
                    )
                }
                { (itemtype==='download' &&
                        <div>                            
                            Download:In Progress
                        </div>
                    )
                }
                
            </div>
		);
    }
}
export default ItemData;
