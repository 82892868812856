import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData} from '../../services/PostData';
import dateFormat from 'dateformat';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import Loader from 'react-loader-spinner'
class Inbox extends Component {
    constructor(props) {
        super(props);
        let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        //console.log('userdataforiduserdataforid',userdataforid.id);
        this.state = {
            loading: false,
            isreadytosend:true,
            data: [],
			per: 10,
            page: 1,
            isData:true,
            //userid:'all',
            userid:this.props.userid,
            currentuserid:this.props.userid
        };
    }
    projectListType(userid){
        //console.log('userid==',userid);
        this.setState({
            isreadytosend:true,
            data: [],
			per: 10,
            page: 1,
            isData:true,
            userid:userid,
        }, () => {
            this.loadMore();
        });
        //this.loadMore();
    }
    componentDidMount() {
        this.scrollListener = window.addEventListener("scroll", e => {
			this.handleScroll(e);
        });
        this.loadMore();
    }
    handleScroll = () => { 
        var lastLi = document.querySelector("ul.container > li:last-child");
		if(lastLi)
		{
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
			var pageOffset = window.pageYOffset + window.innerHeight;
			if (pageOffset > lastLiOffset && this.state.isData) {
					this.loadMore();
			}
		}
    };
    loadMore = () => {
        const isreadytosend=this.state.isreadytosend;
		if(isreadytosend)
		{
            this.setState(
				prevState => ({
					page: prevState.page + 1,
					scrolling: true
				}),
				this.getProjectActivityData()
			);
		}
    };
    getProjectActivityData(){
		this.setState({isreadytosend:false,loading: true});
		const { per, page, data,userid } = this.state;
		const url = `projectactivity?per_page=${per}&page=${page}&userid=${userid}`;        
        GetData(url).then((result) => {             
            this.setState({isreadytosend:true,loading: false});
			if(result.data.length>0)
			{	  
				this.setState({
				data: [...data, ...result.data],
				scrolling: false,
				isData:true,
				})
			}
			else
			{
				this.setState({
					isData: false,
				})
            }
        });			    
	}
    render() {
        let pboard = '';
        let projectboard = this.state.data;
        if(projectboard)  
        {  
            pboard=projectboard.map((item) => {
                
                if(item.boarddata && item.boarddata.length>0)
                {
                    let projectlink="/org/"+item.projectdata.org_id+"/project/view/"+item._id;
                    let orgdata=item.org_data;
                    let orglogo='';
                    //console.log('orgdata===',orgdata);
                    if(orgdata && orgdata[0] && orgdata[0].orglogo && orgdata)
                        orglogo=orgdata[0].orglogo;
                    else if(orgdata && orgdata.orglogo )
                        orglogo=orgdata.orglogo;
                    else
                        orglogo='';
                    //console.log('orglogo===',orglogo);
                return <li className="itemlist InboxProjectRepeat">
                        <div className="row">
                            <div className="col-12 ChatBoxDesign">
                                <div className="project-title">
                                    { (orglogo && orglogo!='""' && orglogo!=0)?<img  className="mr-3" style={{maxHeight:"80px"}} src={require('../../assets/images/organization-logo/'+orglogo+'')}/>:''
                                        }
                                    <Link to={projectlink}>{item.projectdata.project_name}</Link> 
                                    &nbsp; Due : {dateFormat(item.due_date, "mmm d, yyyy")}
                                </div>
                                {(() => {
                                    return(
                                    item.boarddata.reverse().map((value, index) => {
                                        let chatuserdisplayname='';
                                        let userdatadisplayname='';
                                        let chatuseravtare='';
                                        let userdataavtar='';
                                        if(value.chatuserdata[0])
                                        {
                                            chatuseravtare=(value.chatuserdata[0].avatar)?value.chatuserdata[0].avatar:value.chatuserdata[0].avtar
                                        }
                                        if(value.userdata[0])
                                        {
                                            userdataavtar=(value.userdata[0].avatar)?value.userdata[0].avatar:value.userdata[0].avtar
                                        }   
                                        if(value.chatuserdata[0] && value.chatuserdata[0].display_name)
                                        {
                                            chatuserdisplayname=value.chatuserdata[0].display_name
                                        }
                                        if(value.userdata[0] && value.userdata[0].display_name)
                                        {
                                            userdatadisplayname=value.userdata[0].display_name
                                        }                                        
                                        return(   
                                       <div class="InboxChildRep">
                                            { (value.type==='chat') ?
                                                <div class="ChatImgThumb">
                                                    <img src={chatuseravtare}/>
                                                </div>                                        
                                            :                                        
                                            <div class="ChatImgThumb">
                                                <img title={userdatadisplayname} alt={userdatadisplayname} src={userdataavtar}/>
                                            </div>
                                            }
                                           <div className="type"><label>Type: </label>{value.type}</div>
                                            <div className="PB_create_date"><label>Date: </label>{dateFormat(value.create_date, "mmmm dS, yyyy")}
                                            </div>
                                            {(() => {
                                                if (value.type==='task') {
                                                    // console.log("value.type===",value.type);
                                                     return (
                                                         <div className="row TextChildBox">
                                                                 {
                                                                     (value.taskdata)&&
                                                                     value.taskdata.map((task, index) => {
                                                                        //console.log("task===",task);
                                                                         return(
                                                                             <div class="ProjectRepeatTbl">
                                                                                 <p><strong>Team Member: </strong> {task.teammember}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                 <strong>Assignment: </strong>	{task.assignment}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                 <strong>Task Due Date: </strong> {dateFormat(task.due_date, "mmmm dS, yyyy")}
                                                                                 </p>
                                                                             </div>
                                                                         )											
                                                                     })
                                                                 
                                                                 }		
                                                         </div>
                                                     )
                                                 }
                                                 else if(value.type==='chat')
                                                 {
                                                    let echatmessage = convertFromRaw(JSON.parse(value.chatmessage));
                                                    let	echatmessageeditorState = EditorState.createWithContent(echatmessage, null);
                                                    return (
                                                    <div className="row">
                                                        <div class="PB_save_date_row">
                                                            <p class="InBoxSubName">
                                                            <strong>{value.chatuserdata[0].displayname} </strong>
                                                            </p>
                                                            <p class="InMemberPara">
                                                            <Editor editorState={echatmessageeditorState} readOnly={true} />
                                                            </p>
                                                        </div>                                                 
                                                    </div>
                                                    )
                                                 }
                                                 else if (value.type==='storyboard') {
                                                    return (
                                                        <div className="row">
                                                            {
                                                                value.message.map((values, index) => {
                                                                    let message = convertFromRaw(JSON.parse(values));
                                                                    let visual = convertFromRaw(JSON.parse(value.visual[index]));
                                                                    let	editorState1 = EditorState.createWithContent(message, null);
                                                                    let	editorState2 = EditorState.createWithContent(visual, null);
                                                                    return(
                                                                        <div class="ProjectRepeatTbl">
                                                                            <p class="InBoxSubName">
                                                                            <strong>Scene: </strong>  {(index+1)} <br/>
                                                                            <strong> Message: </strong> 
																			</p>
																			<p><Editor editorState={editorState1}
                                                                                readOnly={true} />
                                                                            <strong>Visual: </strong> <Editor editorState={editorState2}
                                                                                readOnly={true} />
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                                else if (value.type==='script') {
                                                    const scrptlink="../../../../../scriptdata/"+value.scriptfile;
                                                     return (
                                                         <div className="row">
                                                             <div class="ProjectRepeatTbl">
                                                                 <p class="InBoxSubName">
                                                                 <strong>Upload Title: </strong> {value.scripttitle}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                 <strong>Download Uploaded Script: </strong> <a href={scrptlink} target="_blank" download>Download</a> &nbsp;&nbsp;|&nbsp;&nbsp;
                                                                 <strong>Added Time: </strong> {value.scripttime}
                                                                 </p>
                                                             </div>
                                                         </div>
                                                     )
                                                 }
                                                 else if (value.type === 'voice over') {
                                                     let voiceoverlink="../../../../../voiceover/"+value.voicefile;	return (
                                                         <div className="row">
                                                             <div class="ProjectRepeatTbl">
                                                                 <p>
                                                                     <strong>Voice Over Title: </strong> {value.voicetitle}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                             <strong>Download Voice Over: </strong> <a href={voiceoverlink} target="_blank" download>Download</a> &nbsp;&nbsp;
                                                                             {/* |&nbsp;&nbsp;
                                                                             <strong>Added Time: </strong> {item.voicetime} */}
                                                                 </p>
                                                             </div>
                                                         </div>
                                                     )
                                                 }
                                                 else if(value.type === 'draft')
                                                 {
                                                     const draftvideosrc="../../../draft/"+value.draftfile;
                                                     return (
                                                         <div className="row">
                                                             <div class="ProjectRepeatTbl">
                                                                 <p>
                                                                     <a className="forcusror" target="_blank" href={draftvideosrc} download> 
                                                                     <strong>Download</strong>
                                                                 </a>
                                                                 </p>
                                                             </div>
                                                         </div>
                                                     )
                                                 }
                                                else
                                                {
                                                    return (
                                                        <div className="row">
                                                            <div class="ProjectRepeatTbl">
                                                                <p>
                                                                <strong>Location:</strong> {value.location}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Purpose:</strong> {value.purpose}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Arrive:</strong> {dateFormat(value.arrive, "mmmm dS, yyyy")}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Depart:</strong> {dateFormat(value.depart, "mmmm dS, yyyy")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                       </div>
                                        )
                                    })
                                )                                    
                            })()}									
                        </div>
                    </div>
                </li>
                }
            })	
        }
        let loading=this.state.loading;
		return (
			 <div className="container-fluid ProjectList ProAssetsList"  ref="selfdiv">
                 {
	        		loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="panel-body">
                        <ul className="container InboxContainer">
                        {pboard}
                        </ul>
                    </div>
                    <div className="no-product">
                        {
                            (!this.state.isData)&&
                            <p>No more data to Load</p>
                        }
                    </div>
                </div>
            </div>
		);
    }
}
export default Inbox;
