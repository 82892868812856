import { Component } from 'react';
import {GetData} from '../services/PostData';
class Logout extends Component {
	constructor(props){
        super(props);	
        GetData('logout').then((result) => {             
            let responseJson = result;
            localStorage.removeItem("userData");
            window.location.href = '/';
        });     
	} 	
    render() {
        return null;
    }
} 
export default Logout;