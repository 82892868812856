import React, { Component } from "react";
import {PostData,GetData} from '../../../services/PostData';
import { apiConfig } from "../../../config/api"
import Loader from 'react-loader-spinner'
import { Client } from '@freshbooks/api';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { SearchQueryBuilder } from '@freshbooks/api/dist/models/builders/SearchQueryBuilder'
import { PaginationQueryBuilder } from '@freshbooks/api/dist/models/builders/PaginationQueryBuilder';
import dateFormat from 'dateformat';
class AddExpense extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},
			receiptsuccessmsg:'',
			setAddExpenseShow:this.props.handleAddExpenseShow,		
		};
		this.onCheckboxChanges = this.onCheckboxChanges.bind(this);
		this.sendExpenseNotification = this.sendExpenseNotification.bind(this);
		this.saveExpense = this.saveExpense.bind(this);
	}
	onCheckboxChanges(event) {
		console.log('event.target.value==',event.target.value);
		if (event.target.checked) {
	        this.setState({isBillable:'Yes'})
	      } else {
	         this.setState({isBillable:'No'})
	      }
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isAddExpense !== this.props.isAddExpense) {
			this.setState({setAddExpenseShow:this.props.isAddExpense})		
		}
	}	

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	handleAddTimeClose(e) {
		this.setState({ setAddExpenseShow: false });
	}

	onSelect(e) {
		//var optionElement = e.target.childNodes[index]
		this.setState({ selectedcategory: e.target.value });
	}
	onScriptfileChangeHandler = e => {
		console.log('event==',e);
		console.log(`Selected file name - ${e.target.files[0].name}`);
		console.log(`Selected filen type - ${e.target.files[0].type}`);
	   // const file = document.querySelector("input[name='receiptfile']").files[0]
	    let tmppath = URL.createObjectURL(e.target.files[0]);
	    console.log('tmppath==',tmppath);
		this.setState({
			'receiptfile': e.target.files[0],
			loaded: 0,
			tmppath:tmppath,
			filetype:e.target.files[0].type,
			filename:e.target.files[0].name,
		})

		//const fr = new FileReader()
		//const file = e.target.files[0]
		//fr.readAsArrayBuffer(file)
		//const blob = new Blob([fr.result])
		///console.log('blob==',blob);

		//this.setState({receiptfile: blob});
	}

	async addExpense() {
		this.setState({ saveBoardDataloader: true });
		let fbtokendata = await this.getFBRefreshToken();
        console.log('fbtokendata=',fbtokendata);
        const clientId = '7753dc0bda63ceced9768da4cb1e515e85dc1402519b9b151a51ec3840dde238'; //live
        const clientSecret = 'ef028fb38c88258dbdaed4dba1513713cefbb3a6bb4ede4f49a0541f4171c112';//live
        const freshBooksClient = new Client(clientId, {
            clientSecret,
            redirectUri: 'https://associationstudios.com/'
        })
        const tokenData = await freshBooksClient.refreshAccessToken(fbtokendata.data.refreshtoken);
        console.log('refreshedTokenData=',tokenData);
        if(tokenData){
            let fbClient = new Client(clientId, { accessToken: tokenData.accessToken });
            const { data } = await fbClient.users.me();
            console.log('tokenData.data=',data);
            let accountid = data.businessMemberships[0].business.accountId;
            console.log('accountid=',accountid);
            this.setState({
              accountid: accountid,
              tokenData:tokenData,
              fbClient:fbClient
            })
            await this.updateFBRefreshToken(tokenData);
            let attchurl = 'https://api.freshbooks.com/uploads/account/'+accountid+'/attachments';
            //let url = 'https://api.freshbooks.com/accounting/account/'+accountid+'/expenses/expenses?include[]=attachment';
            let url = 'https://api.freshbooks.com/accounting/account/'+accountid+'/expenses/expenses';
            //let payurl = 'https://api.freshbooks.com/accounting/account/'+accountid+'/invoices/invoices';
            const today = new Date();
            const today_date = dateFormat(today, "yyyy-mm-dd");
            let categoryid;
            console.log('state check =',this.state.selectedcategory);
            if(this.state.selectedcategory =='Travel'){
            	categoryid = 3842;
            } else {
            	categoryid = 3988;	
            }
           	let expneseWithAttachment = {
				"expense": {
				    "amount": {
				      "amount": this.state.amount
				    },
				    "categoryid": categoryid,
				    "staffid": 1,
				    "date": today_date,
				    "clientid": this.props.freshbook_profile_id, //"clientid": this.props.freshbook_profile_id,
				    "notes": this.state.description,
				    //"projectid": this.props.prjid
				 }
			}
			
            if(this.state.receiptfile) {
	           	const form = new FormData();
	            const type = this.state.filename+";charset=utf-8";
				form.append('content',new File([this.state.receiptfile], this.state.filename, {type: type}));
	             const attachresponse = await fetch(attchurl, {
	              method: 'POST',
	              headers: {
	                Authorization: `Bearer `+tokenData.accessToken,
	               },
	              body: form
	            });
	            const fileattachment = await attachresponse.json();
			    url = 'https://api.freshbooks.com/accounting/account/'+accountid+'/expenses/expenses?include[]=attachment';

			     expneseWithAttachment = {
					"expense": {
					    "amount": {
					      "amount": this.state.amount
					    },
					    "categoryid": categoryid,
					    "staffid": 1,
					    "date": today_date,
					    "customerid": this.props.freshbook_profile_id,
					    "clientid": this.props.freshbook_profile_id,
					    "attachment": {
					      "jwt": fileattachment.attachment.jwt,
					      "media_type": fileattachment.attachment.media_type,
					      "expenseid": null
					    },
					    "notes": this.state.description,
					    "include_receipt":true
					 }
				}
	        }
            console.log('expneseWithAttachment=',expneseWithAttachment); 



            const response = await fetch(url, {
              method: 'POST',
              headers: {
                Authorization: `Bearer `+tokenData.accessToken,
                //Api-Version: 'alpha',
                'Content-Type': 'application/json' // Replace with your own access token
              },
              body: JSON.stringify(expneseWithAttachment),
            });
            const responseresult = await response.json();
            console.log('response=',responseresult);
            if (responseresult.response.result) {
            	//this.sendExpenseNotification(this.state.amount,this.state.description);
            	this.saveExpense();
				//this.setState({ receiptsuccessmsg: "Expense Created Successfully", description: '', receiptfile: '', amount: '', saveBoardDataloader: false, });
				//setTimeout(() => {
					//this.setState({ receiptsuccessmsg: '' })
				//	this.setState({ setAddExpenseShow: false });
					
				//}, 3000);
				//const file = document.querySelector('.scriptfile');
				//file.value = '';
			}
        }
	}

	async getFBRefreshToken(){
        let resultdata;
        await GetData('getfbrefreshtoken').then((result) => {
            console.log('refresh token result=',result);
            if (result.statuscode === 200) {                
                console.log('refresh token updated');
                resultdata= result;     
            }
        });
        return resultdata
    }
    
    updateFBRefreshToken(tokenData){
        const refreshtoken = tokenData.refreshToken;
        PostData('updatefbrefreshtoken', {refresh_token:refreshtoken}).then((result) => {
            console.log('refresh token result=',result);
            if (result.statuscode === 200) {                
                console.log('refresh token updated');               
            }
        });
    }

	sendExpenseNotification(amount,description) {
		console.log('callled inside function');
		let action='sendexpensenotification'; 
		//let type='expense';
		//this.setState({ saveBoardDataloader: true });
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		//data.append('org_id', this.props.orgid)
		//data.append('projectid', this.props.prjid)
		let data ={
			userid:userdata.user.ID,
			organizationName:this.props.organizationName,
			projectName:this.props.project_name,
			description:description,
			amount:amount,
			clientid: this.props.freshbook_profile_id
		}
		PostData(action, data).then((result) => {
			if (result.statuscode === 200) {
				
					
			}
		});
	}

	saveExpense() {
		let action='addexpense'; 
		let type='expense';
		this.setState({ saveBoardDataloader: true });
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		const data = new FormData();
		data.append('org_id', this.props.orgid)
		data.append('projectid', this.props.prjid)
		data.append('category', this.state.selectedcategory)//selectedcategory
		data.append('isBillable', 'yes')//selectedcategory
		data.append('organizationName', this.props.organizationName)//amount
		data.append('amount', this.state.amount)//amount
		data.append('description', this.state.description)
		data.append('clientid', this.props.freshbook_profile_id)
		data.append('receiptfile', this.state.receiptfile)
		data.append('type', type)
		data.append('userid', userdata.user.ID)
		let session_key = userdata.token;
		fetch(apiConfig.WEB_SERVICE_URL + action, {
			method: 'POST',
			body: data,
			headers: {
				"session-key": session_key,
			}
		})
		.then((response) => response.json())
		.then((res) => {
			if (res.statuscode === 200) {
				this.setState({ receiptsuccessmsg: res.message, description: '', receiptfile: '', amount: '', saveBoardDataloader: false });
				setTimeout(() => {
					this.setState({ receiptsuccessmsg: '' })
					this.setState({ setAddExpenseShow: false });
				}, 3000);
				const file = document.querySelector('.scriptfile');
				file.value = '';


			}
		});
	}

	validateScriptDataFormAdmin() {
		//const hour=this.state.hour;
		//const minut=this.state.minut;
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["description"]) {
			formIsValid = false;
			errors["description"] = "*Please Enetr Description";
		}
		if (!fields["amount"]) {
			formIsValid = false;
			errors["amount"] = "*Please Enter Amount";
		}
		

		this.setState({
			errors: errors
		});
		return formIsValid;
	}

    render() {
		console.log('called every time');
        return (
        	<Modal className="ProjectList" show={this.state.setAddExpenseShow}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Add Expense</Modal.Title>
				</Modal.Header>
				<Modal.Body className=" SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.receiptsuccessmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row mg-btm-15">
						<div className="col-12">
							<select name="task" className="form-control" value={this.state.selectedcategory} onChange={this.onSelect.bind(this)}>
								<option value=" ">Select Category</option>
								<option value="Travel">Travel</option>
								<option value="Other">Other</option>
							</select>
							<div className="errorMsg">{this.state.errors.selectedtask}</div>
						</div>
					</div>
					<div className="row mg-btm-15">
						<div className="col-12">
							<input className="LP_field tcomment form-control" value={this.state.description} onChange={this.onChange.bind(this)} name="description" type="textarea" placeholder="Description" />
						</div>
					</div>

					<div className="row mg-btm-15">
						<div className="col-12">
							<input className="LP_field form-control tcomment" value={this.state.amount} onChange={this.onChange.bind(this)} name="amount" type="text=" placeholder="Amount" />
						</div>
					</div>
					<div className="row mg-btm-15">
						<div className="col-12">
							<label>Upload Receipt </label>
							<input type="file" name="receiptfile" onChange={this.onScriptfileChangeHandler.bind(this)} className="form-control scriptfile" autoFocus />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot ProSubHeadRight">
					<Button variant="secondary" onClick={this.handleAddTimeClose.bind(this)}>
						X
					</Button>
					<a onClick={(e) => this.addExpense(e)} className="forcusror">Submit</a>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default AddExpense;