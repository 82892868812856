import React, { Component } from "react";
import DatePicker from "react-datepicker";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import moment from 'moment';
class SaveTheDate extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			location: '',
			purpose: '',
			videographers: '',		
		};

	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	handleDateChange(dateName, dateValue) {
		let { duedate, arrivedate, departdate } = this.state;
		if (dateName === 'duedate') {
			duedate = dateValue;
		}
		else if (dateName === 'arrivedate') {
			arrivedate = dateValue;
		}
		else if (dateName === 'departdate') {
			departdate = dateValue;
		}
		
		this.setState({
			duedate,
			arrivedate,
			departdate,
		});
	}
	saveDate(e) {
		if (this.validateSaveDateForm())
			this.saveData('savedate', 'savedate', this.state.videographers, this.state.location, this.state.purpose, this.state.arrivedate, this.state.departdate);
	}
	validateSaveDateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["purpose"]) {
			formIsValid = false;
			errors["purpose"] = "*Please enter your purpose";
		}
		if (!fields["location"]) {
			formIsValid = false;
			errors["location"] = "*Please enter your location";
		}
		if (!fields["arrivedate"]) {
			formIsValid = false;
			errors["arrivedate"] = "*Please select  arrival date";
		}
		if (!fields["departdate"]) {
			formIsValid = false;
			errors["departdate"] = "*Please select  departure date";
		}
		if (!fields["videographers"]) {
			formIsValid = false;
			errors["videographers"] = "*Please select videographs";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	saveData(action, type, videographers, location, purpose, arrivedate, departdate) {
		this.setState({ Inloading: true });
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		console.log('userdata==',userdata);
		const data = {
			org_id: this.props.orgid,
			projectid: this.props.prjid,
			videographers: videographers,
			location: location,
			purpose: purpose,
			arrive: arrivedate,
			departure: departdate,
			type: type,
			userid: userdata.user.ID,
		};
		PostData(action, data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ savedatesuccessmsg: result.message, purpose: '', location: '', arrivedate: '', departdate: '', videographers: '' });
				setTimeout(() => {
					this.setState({  Inloading:false,savedatesuccessmsg: '' });
					this.props.loadProjectBoard();
				}, 3000);		
			}
		});
	}
    render() {
		const arrivedate = this.state.arrivedate;
		const departdate = this.state.departdate;
		const ExampleCustomInput = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				<img src={require('../../../assets/images/due-date.png')} /> {value}
			</button>
		);
        return (
        	<div className="ProducerSaveDate AddSaveDate">
				{
					this.state.Inloading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}						         
							/>
						</div>
					:null 
				} 
				<img className="SD_img_icon" src={this.props.avatar} />
				<h3 class="AddTitleDrop">Save the Date</h3>
				<div className="clearfix"></div>
				<div className="succes-msg">{this.state.savedatesuccessmsg}</div>
				<div className="row LP_Row">
					<div className="col-6 LeftLocation">
						<input className="LP_field" type="text" value={this.state.location} onChange={this.onChange.bind(this)} name="location" placeholder="Location" />
						<div className="errorMsg">{this.state.errors.location}</div>
						<h4>to </h4>
					</div>
					<div className="col-6 LeftPurpose">
						<input className="LP_field" value={this.state.purpose} onChange={this.onChange.bind(this)} type="text" name="purpose" placeholder="Purpose" />
						<div className="errorMsg">{this.state.errors.purpose}</div>
					</div>
				</div>
				<div className="row LP_Row">
					<br />
					<br />
					<div className="col-12 col-sm-12 col-md-3 col-lg-3 VideoGraphySel">
						<div className="dropdown">
							<img class="SelectIconLeft" src={require('../../../assets/images/upload-icon.png')} />
							<select name="videographers" value={this.state.videographers} className="form-control" onChange={this.onChange.bind(this)} id="subscription_hour">
								<option value=" ">Select Videographers</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
							</select>
							<div className="errorMsg">{this.state.errors.videographers}</div>
						</div>
					</div>
					<div className="col-6 col-sm-12 col-md-3 col-lg-3 ArDpIcon MoArDpIconLeft">
						<h4>Arrive
							<DatePicker className="form-control" selected={arrivedate}
								name="arrivedate"
								minDate={moment().toDate()}
								onChange={date => this.handleDateChange('arrivedate', date)}
								dateFormat="d MMMM"
								startDate={arrivedate}
								endDate={departdate}
								customInput={<ExampleCustomInput />} />
							<div className="errorMsg">{this.state.errors.arrivedate}</div>
						</h4>
					</div>
					<div className="col-6 col-sm-12 col-md-3 col-lg-3 ArDpIcon MoArDpIconRight">
						<h4>Depart <DatePicker className="form-control" selected={departdate}
							name="departdate"
							endDate={departdate}
							minDate={arrivedate}
							onChange={date => this.handleDateChange('departdate', date)}
							dateFormat="d MMMM"
							customInput={<ExampleCustomInput />} />
							<div className="errorMsg">{this.state.errors.departdate}</div>
						</h4>
					</div>
					<div className="col-12 col-sm-12 col-md-3 col-lg-3">
						<div className="text-left">
							<button type="button" onClick={(e) => this.saveDate(e)} className="UpdateBtn btn btn-primary">Save</button>
						</div>
					</div>
				</div>
				<br />
				<hr />
			</div>	
		);
    }
}
export default SaveTheDate;