import React, { Component } from "react";
import {GetData,PostData} from '../../services/PostData';
import { apiConfig } from "../../config/api"
import Loader from 'react-loader-spinner'
import { Multiselect } from "multiselect-react-dropdown";
class UploadAudio extends Component {
    constructor(props) {
        super(props);
        this.state = {            
            loading: false,         
            selectedmoodcat:[],
            selectedgenrecat:[],
            fields:{},
            errors: {},    
            boxfolderid:'121618198254',
            audiosavesuccmsg:'',
            artistname:''   
        };
        this.onChange = this.onChange.bind(this);
        this.onSelectMood=this.onSelectMood.bind(this);
        this.onRemoveMood=this.onRemoveMood.bind(this);
        this.onSelectGenre=this.onSelectGenre.bind(this);
        this.onRemoveGenre=this.onRemoveGenre.bind(this);
        this.onAlbumCoverImageChange = this.onAlbumCoverImageChange.bind(this);
        this.onAudioFileChangeHandler = this.onAudioFileChangeHandler.bind(this);
    }     
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });        
    }    
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }
    onSelectMood(selectedList, selectedItem) {
        console.log('selectedList, selectedItem==',selectedList, selectedItem,this);
        this.setState({
            selectedmoodcat:selectedList
        });
    }
    onRemoveMood(selectedList, removedItem) {
        this.setState({
            selectedmoodcat:selectedList
        });
    }
    onSelectGenre(selectedList, selectedItem) {
        console.log('selectedList, selectedItem==',selectedList, selectedItem,this);
        this.setState({
            selectedgenrecat:selectedList
        });
    }
    onRemoveGenre(selectedList, removedItem) {
        this.setState({
            selectedgenrecat:selectedList
        });
    }
    saveAudioData(e){
        if (this.validateForm())
        {
            this.setState({
                loading:true
            })
            var formdata = new FormData();
            let moodcat     =   this.state.selectedmoodcat;
            let genrecat    =   this.state.selectedgenrecat;
            formdata.append('folderid', this.state.boxfolderid) 
            moodcat= JSON.stringify(moodcat);
            formdata.append('moodcat', moodcat) 
            genrecat= JSON.stringify(genrecat);
            formdata.append('genrecat', genrecat)   
            formdata.append('artistname',  this.state.artistname)
            formdata.append('audio',  this.state.audio)
            formdata.append('albumcoverimage',  this.state.albumcoverimage)
            let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
            let session_key = userdata.token;
            fetch(apiConfig.WEB_SERVICE_URL + 'saveaudio', {
                method: 'POST',
                body: formdata,
                headers: {
                    "session-key": session_key,
                }
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.statusCode)
                {
                    this.setState({
                        loading:false
                    })
                    this.setState({ audiosavesuccmsg: res.message, saveBoardDataloader: false, data: []});
                    setTimeout(() => {
                        this.setState({ audiosavesuccmsg: '' })
                        this.props.loadAudio();
                    }, 3000);
                }
            });
                
        }                               
    }
    validateForm() {
        let fields = this.state;
        let errors = {};
        let formIsValid = true;
        let moodcat     =   this.state.selectedmoodcat;
        let genrecat    =   this.state.selectedgenrecat;
        if(moodcat.length===0){
            formIsValid = false;
            errors["mooderror"] = "*Please select Mood";
        }
        if(genrecat.length===0){
            formIsValid = false;
            errors["genreerror"] = "*Please select Genre";
        }
        if (!fields["audio"]) {
            formIsValid = false;
            errors["audio"] = "*Please upload Audio";
        }   
        this.setState({
            errors: errors
        });
        return formIsValid;
    }
    onAlbumCoverImageChange=event=>{
        this.setState({
          'albumcoverimage': event.target.files[0],
          loaded: 0,
        })
    }
    onAudioFileChangeHandler=event=>{
        this.setState({
          'audio': event.target.files[0],
          loaded: 0,
        })
    }
    render() {       
        const loading = this.state.loading;        
        let mood_cat= ['Angry','Carefree','Dark','Dramatic','Epic','Exciting','Funny','Groovy','Happy','Hopeful','Love','Peaceful','Playful','Powerful','Sad','Scary','Serious','Sexy','Tense','Uplifting'];
        let genre_cat= ['Acoustic','Ambient','Blues','Children','Cinematic','Classical','Country','Electric','Folk','Funk','Hip Hop','Holiday','Indie','Jazz','Latin','Lounge','Pop','Reggae','Rock','Singer-Songwriter','Soul & RnB','World'];
        return (
             <div>
                 {
                    loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="successmsg">{this.state.audiosavesuccmsg}</div>                             
                <div className="row no-border padding-rop-top">
                    <div className="col-4">
                        <div className="error-msg">
                            {this.state.errors.mooderror}
                        </div>
                        <Multiselect                                       
                            options={mood_cat}
                            isObject={false}
                            onSelect={this.onSelectMood}
                            onRemove={this.onRemoveMood}
                            placeholder='Select Mood'
                        />
                    </div>                    
                    <div className="col-4">
                        <div className="error-msg">
                            {this.state.errors.genreerror}
                        </div>
                        <Multiselect                                       
                            options={genre_cat}
                            isObject={false}
                            onSelect={this.onSelectGenre}
                            onRemove={this.onRemoveGenre}
                            placeholder='Select Genre'
                        />
                    </div>
                    <div className="col-4">
                        <input className="form-control" type="text" value={this.state.artistname} onChange={this.onChange} name="artistname" placeholder="Artist's Name"/>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-4">
                        <div className="dropdown">
                            <input type="file" name="albumcoverimage" onChange={this.onAlbumCoverImageChange} className="form-control videofile" autoFocus /> 
                            <label>Upload album cover Art</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="dropdown">
                            <input type="file" name="audio" onChange={this.onAudioFileChangeHandler} className="form-control videofile" autoFocus /> 
                            <div className="errorMsg">{this.state.errors.audio} </div>  
                            <label>Upload Audio</label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="col text-left">
                            <button type="button"  onClick={this.saveAudioData.bind(this)}  className="UpdateBtn btn btn-primary">Save</button> 
                        </div>
                    </div>                            
                </div>                                                         
            </div>
        );
    }
}
export default UploadAudio;
