
import request from 'superagent'
import dateFormat from 'dateformat';
import moment from 'moment';
const CALENDAR_ID = 'productions@associationstudios.com'//const CALENDAR_ID = 'productions@associationstudios.com'
const API_KEY = 'AIzaSyBzFcec91sbImaWidbWZjun-CFT0hJuEs4'
let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}&maxResults=100000`
export function getEvents (callback) {
    request
    .get(url)
    .end((err, resp) => {
      if (!err) {
        const events = []
        //console.log('event====',resp);
        JSON.parse(resp.text).items.map((event) => {
            console.log('in top looooppppppp====',event.start.date,event.end.date,event.summary);
            if((event.start.date || event.start.dateTime) && (event.end.date || event.end.dateTime))
            {
                //var start=dateFormat(event.start.date || event.start.dateTime, "yyyy, mm, dd");
               // var end=dateFormat(event.end.date || event.end.dateTime, "yyyy, mm, dd");
                var start=moment(event.start.date).toDate();
                var end=moment(event.end.date).add(30, 'minute').toDate()
               // console.log('in looooppppppp otherrer====',start,end);
                events.push({
                    start: start,
                    end: end,
                    title: event.summary,
                })
            }
        })
        callback(events)
      }
    })
    
}