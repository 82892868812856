import React, { Component } from 'react';
//import './style.css';
//import Logout from './pages/Logout';
class Home extends Component {

	constructor(){
        console.log('called in homeeeee');
	super();
	}
 	logoutHandler =(e) => {
		//sessionStorage.clear();
		//window.location.href = '/';
    }
    render() {
        return (
            <div>
                <h2>Home page</h2>
                <a href="/logout">LOGOUT</a>               
				
            </div>
        );
    }
} 
export default Home;