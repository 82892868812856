import React from 'react';
import ReactDOM from 'react-dom';
import {toast} from 'react-toastify';

 toast.configure()
const TextComponent=(props)=>
{
  const [scriptdata, setScriptData] = React.useState(props.text);
  const copyContent = async() => {
    let text = document.getElementById('myText').innerHTML;
    try {
      let cleanText = text.replace(/<\/?[^>]+(>|$)/g, "");

      await navigator.clipboard.writeText(cleanText);
      console.log('Content copied to clipboard');
      toast.info('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  function handleScriptChange(event) {
      //setScriptData(event.target.value); // invert value
      console.log('Content copied to clipboard',event.target.value);
      props.onScriptChange('producescript', event.target.value);

  }

  //let data = props.text.split('\n').map((item, i) =><p key={i}>{item}</p>).join('');
 //let text = data.toString();
  console.log('props.text=',props);
  return <div className="generate-data">
    <textarea id="myText" value={props.text} onChange={ props.onScriptChange } style={{"width" : "100%"}}  />
      
   <br/>
    <button className="btn btn-primary " onClick={copyContent}>Copy Script</button>
</div>
}
 
export default TextComponent;