import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import { Button, Collapse, Modal } from 'react-bootstrap';
import { PostData } from '../../services/PostData';
import Papa from 'papaparse';
class ShareLink extends Component {
    constructor(props){
		super(props);
		this.state = {
			isChecked: this.props.isLinkCreated,
			isEmailRequired: this.props.isEmailRequired,
			loading: false,
			linkaddedsuccessmsg:'',
			csvfile: undefined,
			invalidfiletype:'',
			csvdata:[],
			isLinkCreated: this.props.isLinkCreated,
			fileid:this.props.boxfolderid
		};	
		this.updateData = this.updateData.bind(this);	
		console.log('check props data==',this.props.isEmailRequired,this.props.isLinkCreated);
	};	
	handleClose(e) {
		this.props.popupclose();
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	_handleChange () {
		this.setState({
			isChecked: !this.state.isChecked,	
			fileid:'',
		});		
    }
	createLink(){
		let emails= this.state.cemails;		
		//console.log('this.state.isChecked below==',this.state.isChecked);
		
		if(this.state.isChecked)
		{	
			this.setState( { erremails:'',loading:true } );		
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;	
			let data = {
				'orgid': this.props.orgid,
				'folderid': this.props.boxfolderid,
				'userid': userdata.user.ID,
				'emails':emails,
				'isEmailRequired':this.state.isEmailRequired
			}
			console.log('data===',data);
			PostData('sharefolder', data).then((result) => {
				console.log('result===',result);
				if (result.statuscode === 200) {
					this.setState({
						fileid:result.data.fileid,						
						loading:false,
						linkaddedsuccessmsg:result.message
					});		
					setTimeout(() => {
						this.setState({  linkaddedsuccessmsg: '' });
					}, 3000);				
				}
			});
		}
	}
	_handleEmailRequiredChange = () =>{
		//console.log('called event _handleEmailRequiredChange');
		this.setState({
			isEmailRequired:!this.state.isEmailRequired
		})
	}
	copyText(){
		var copyText = document.getElementById("myInput");
		/* Select the text field */
		copyText.select();
		copyText.setSelectionRange(0, 99999); /* For mobile devices */	  
		/* Copy the text inside the text field */
		document.execCommand("copy");
	}
	sendMailToInvitee(){ 
		console.log('sendmail');
		console.log('this.state.message==',this.state.message);
		this.setState({
			loading:true,			
		});
		let data = {
			'orgid': this.props.orgid,
			'folderid': this.state.fileid,
			'emails':this.state.cemails,
			'csvdata':this.state.csvdata,
			'message':this.state.message
		}

		PostData('sendmailtoinvitee', data).then((result) => {
			console.log('result===',result);
			if (result.statuscode === 200) {
				this.setState({
					loading:false,
					linkaddedsuccessmsg:result.message
				});		
				setTimeout(() => {
					this.setState({  linkaddedsuccessmsg: '' });
				}, 3000);				
			}
		});
	}
	handleChange = event => {
		const filetupe = event.target.files[0];
 
		if (!filetupe) {
			this.setState({ invalidfiletype: 'Please upload csv.' });
			//alert('upload csv');
			return false;
		}
		
		if (!filetupe.name.match(/\.(csv|CSV)$/)) {
			this.setState({ invalidfiletype: 'Please upload csv.' });
			//alert('upload csv');
			return false;
		}

		this.setState({
		  csvfile: event.target.files[0]
		});
		Papa.parse(event.target.files[0], {
		complete: this.updateData,
		header: true
		});
	  };
	
	//   importCSV = () => {
	// 	const { csvfile } = this.state;
	// 	Papa.parse(csvfile, {
	// 	  complete: this.updateData,
	// 	  header: true
	// 	});
	//   };
	
	updateData(result) {
		var data = result.data;
		console.log('data==',data);
		this.setState({
			csvdata:data
		});
		let copy='';
		for(let i=0;i<data.length; i++ ){
			if(data[i].hasOwnProperty("firstname"))
			{
				if(data[i].email)
				{
					var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
					if(data[i].email.match(mailformat))
					{
						console.log('data[i]=',data[i].email);
						copy  = copy + data[i].email +',';
					}
					else
					{
						this.setState({ invalidfiletype: 'invalid email in csv.' });
						return false;
					}
					
				}				
			}
			else
			{
				console.log('outer  in valid');	
				this.setState({ invalidfiletype: 'invalid csv formate.' });
				return false;
			}
		}
		var n=copy.lastIndexOf(",");
    	copy=copy.substring(0,n) 
		this.setState({
			cemails:copy,
			invalidfiletype:''
		});
		console.log('copy',copy);
	}
    render() {
		let fileid=this.state.fileid;
		let savedlink ='';
		console.log('fileid && this.state.isChecked==',fileid,this.state.isChecked);
		if(fileid && this.state.isChecked)
		{
			//savedlink='http://localhost:3000/'+this.props.orgid+'/'+fileid+'/sharefolder/'; //Local
            savedlink='https://vpma.associationstudios.com/'+this.props.orgid+'/'+fileid+'/sharefolder/'; //live
		}
		return (
        	<Modal show={this.props.showshareLink}>
				{
					this.state.loading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<Modal.Header className="SelectProducerHead sharetitle">
					{/* <Modal.Title>{this.props.foldername}</Modal.Title> */}
					<div className="titletext">
						<h4>{this.props.foldername}</h4>						
					</div><br/>
					<div className="form-data">
						<label>Invite People</label>
						<input type="text" className="form-control" value= {this.state.cemails} placeholder="Add names or email addresses with comma separated" name="cemails" onChange={this.onChange.bind(this)}  autoFocus />
						<div className="errormsg">{this.state.erremails}</div>
						<label>Or</label>
						<div className="errormsg">{this.state.invalidfiletype}</div>
						<label>Please upload Csv</label>
						<input
						className="csv-input"
						type="file"
						ref={input => {
							this.filesInput = input;
						}}
						name="file"
						placeholder={null}
						onChange={this.handleChange}
						accept=".csv,.CSV"
						/>
						<a href={require('../../assets/images/csvformate.csv')} download>Download Formate</a>
					</div>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel">
					<div className="successmsg">{this.state.linkaddedsuccessmsg}</div>
					<div className="row">
						<div className="col-6">
							<div className="form-data">
								<label>Share Link</label>										
							</div>					
							<label className="switch">
								<input className="checkboxdata" checked={ this.state.isChecked }  onChange={this._handleChange.bind(this)} type="checkbox"/>
								<span className="slider round"></span>						
							</label>					
							{
								this.state.isChecked &&
								<span>Shared link is created</span>
							}
						</div>
						<div className="col-6">
							<div className="form-data">
								<label>Email Required</label>										
							</div>					
							<label className="switch">
								<input className="checkboxdata" checked={ this.state.isEmailRequired }  onChange={this._handleEmailRequiredChange} type="checkbox"/>
								<span className="slider round"></span>						
							</label>						
						</div>
					</div>
					<div className="row">						
						<div className="col-12">
						<button type="button" className="search btn btn-primary addbtn" onClick={this.createLink.bind(this)} >Create Link</button>						
						</div>
					</div>								
					<input type="text" value={savedlink} id="myInput"/>					
					<button onClick={this.copyText.bind(this)}>Copy</button>
					<label>Message</label>
					<input type="text"  name="message" onChange={this.onChange.bind(this)}  />	
					<button type="button" className="search btn btn-primary addbtn sendbtn" onClick={this.sendMailToInvitee.bind(this)} disabled={this.state.isChecked ? '' : 'disabled' }>Send</button>
				</Modal.Body>
				<Modal.Footer className="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleClose.bind(this)}>
						X
					</Button>
				</Modal.Footer>
			</Modal>
		);
    }
}
export default ShareLink;
