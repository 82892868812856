import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
class InviteeSidebar extends Component{
	constructor(props){
		//console.log('propsssssss=',props);
		super(props);
		this.state = {
			org_id:props.orgid,
		};
		this.openNav = this.openNav.bind(this);
		this.closeNav = this.closeNav.bind(this);
	}
	openNav() {
		document.getElementById("mySidebar").style.width = "20%";
		document.getElementById("main").style.marginLeft = "20%";
		document.getElementById("mySidebar").classList.add('OpenSidebar');
		document.getElementById("main").classList.add('FullWrapper');
	}
	closeNav() {
		document.getElementById("mySidebar").style.width = "0";
		document.getElementById("main").style.marginLeft= "0";
		document.getElementById("mySidebar").classList.remove('OpenSidebar');
		document.getElementById("main").classList.remove('FullWrapper');
	}


    render(){
    	let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
    	if(checkuser.user.ID==15888 || checkuser.user.ID==12612 || checkuser.user.ID==28 || checkuser.user.ID==202371015253)
		{
			localStorage.setItem('userData','');
			return (<Redirect to={'/'}/>)
		}
	   	return (           
 			<div id="mySidebar" className="sidebar">
		        <button className="openbtn" onClick={this.openNav}>
		       	 <img src={require('../assets/images/toggle-icon.png')} />
		        </button>
		        <div className="SidebarInner"> 
		        <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}><img src={require('../assets/images/toggle-icon.png')}/>
		         </a>
		          <img className="AS_logo_sidebar img-responsive" src={require('../assets/images/sidebar-logo.png')}/>
				  
		          <ul className="SideIconNav">           
						
			       	<li> 
					<Link to={`/invitee/${this.state.org_id}/assets`}> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-assets.png')}/>  Assets</Link></li>
					
					
			        <li><Link to="/logout"> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-logout.png')}/> Logout</Link></li>
			       </ul>
		        </div>
		    </div> 
		)
    }
}
export default InviteeSidebar;