import React, { Component } from "react";
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../../services/PostData';
import Loader from 'react-loader-spinner'
import UploadAudio from './UploadAudio.js';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { Button, Collapse, Modal } from 'react-bootstrap';
import debounce from "lodash.debounce";
class StockAudios extends Component {
    constructor(props) {
        super(props);
        this.state = {
           // activePage: 1,
            //itemPerPage:12,
            assetList: [],
            originalAssetList: [],
            Inloading: false,
            activeItem:'',
            projectlist:[],
            showAddToProject:false,
            per: 10,
            page: 0,
            isData: true, 
            isreadytosend: true,  
            data:[], 
            searchmoodcat:'',
            searchgenrecat:''            
        };
        this.onChange = this.onChange.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }
    addToProject(albumcoverimage,downloadurl,stockfilename,filetype,moodcat,genrecat,artistname,fileid,e){
        console.log('albumcoverimage,downloadurl,stockfilename,filetype,videocat,fileid,e==',albumcoverimage,downloadurl,stockfilename,filetype,moodcat,genrecat,artistname,fileid,e);
        this.setState({
            albumcoverimage:albumcoverimage,
            fileurl:downloadurl,
            filename:stockfilename,
            filetype:filetype,
            moodcat:moodcat,
            genrecat:genrecat,
            artistname:artistname,
            fileid:fileid
        })
        this.setState({ showAddToProject: true });
    }
    handleAddToProjectClose(e) {
        this.setState({
            showAddToProject: false,
        });
    }
    handleProjectChange(e){
        console.log('selectedproject==',e.target.value);
        this.setState({ selectedproject: e.target.value });
    }
    addStockVideoToProject(e){
        let filedetails = this.state.filedetails;
        //console.log('filedetails==',filedetails);
        if(this.state.selectedproject=='')
        {
            this.setState({
                errormsg:'Please select Project'
            })
        }
        else
        {
            this.setState({
                errormsg:''
            })
            let data = {
                'albumcoverimage':this.state.albumcoverimage,
                'filename': this.state.filename,
                'fileid': this.state.fileid,
                'fileurl': this.state.fileurl,
                'filetype': this.state.filetype,
                'moodcat': this.state.moodcat,
                'genrecat': this.state.genrecat,
                'artistname': this.state.artistname,
                'project_id': this.state.selectedproject,
            }
            console.log('data==',data);
            PostData('stockaudioaddtoproject', data).then((result) => {
                console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
                }
            });
        }

    }
    componentDidMount() {
        this.callListData();   
        this.setState({ Inloading: true});
        Promise.all([this.getProjectList()])
        .then(([projectdata])  => {
            if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data});
            }
        });              
    }
    callListData(){
        /*this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);            
        });*/
        window.onscroll = debounce(() => {
          this.handleScroll();
        }, 100);
        this.loadMore();
    }
    handleScroll = () => {
        var lastLi = document.querySelector(".rowlist > div:last-child");
       // console.log('lastLi==',lastLi);
        if (lastLi) {
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset && this.state.isData) {
                this.loadMore();
            }
        }
    };
    loadMore = () => {
        console.log('loadMore==',this.state.page);
        const isreadytosend = this.state.isreadytosend;
        const pageno=this.state.page+1;
        if (isreadytosend) {
            this.setState({ isreadytosend: false});
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    scrolling: true
                }),
                this.getListData(pageno)
            );
        }
    };
    getListData(pageno) {
        //console.log('project_id==',project_id);this.setState({ loading: true});
        const { per, page, data } = this.state;
        console.log('pageeeeee=',page,data);
        this.getData(per,pageno,data,'');
    }
    getData(per,page,data){
        this.setState({ Inloading: true,isreadytosend: false});
        let searchtext= this.state.searchText
        let searchmoodcat= encodeURIComponent(this.state.searchmoodcat)
        let searchgenrecat= encodeURIComponent(this.state.searchgenrecat)
        const url = `audiodata/?per_page=${per}&page=${page}&searchtext=${searchtext}&searchmoodcat=${searchmoodcat}&searchgenrecat=${searchgenrecat}`;
        GetData(url).then((result) => {
            this.setState({ isreadytosend: true, Inloading: false });
            if (result.data.length > 0) {
                //console.log('data returnedddddddddd');
                this.setState({
                    data: [...data, ...result.data],
                    //data: [...data, ...result.data],
                    //originalAssetList:assets.data,
                    //data: result.data,
                    //assetList: [...assetList, ...result.data],
                    // originalAssetList:[...assetList, ...result.data],
                    scrolling: false,
                    isData: true,
                })
                //this.scrollToBottom();
               
            }
            else {
                this.setState({
                    isData: false,
                })
            }
        });
    }
    searchFilter(){
        this.setState({
            page: 1, 
            isreadytosend: true,
            data:[] 
        })
       this.getData(this.state.per,1,[]);
    }
    getProjectList(){
        console.log('calleddd=');
        return GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    getAssetsData () {
        return GetData('stockaudios/').then((result) => {  
            return result;
        });
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });        
    }
    
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }   
    
    updateInputValue(event){
        this.setState({
            searchText: event.target.value        
       });
    }    
    handleChangeStatus = ({ meta, file }, status) => { 
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
    }
    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }    
    loadAudio()
    {
        window.location.reload(); 
    }
    handleSearchMoodChange(e) {
        this.setState({ searchmoodcat: e.target.value });
        console.log('handleSearchMoodChange===',e.target.value);
    }
    handleSearchGenreChange(e){
        this.setState({ searchgenrecat: e.target.value });
        console.log('handleSearchGenreChange===',e.target.value);
    }
    render() {     
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;    
        const loading = this.state.Inloading;   
        let mood_cat= ['Angry','Carefree','Dark','Dramatic','Epic','Exciting','Funny','Groovy','Happy','Hopeful','Love','Peaceful','Playful','Powerful','Sad','Scary','Serious','Sexy','Tense','Uplifting'];
        let genre_cat= ['Acoustic','Ambient','Blues','Children','Cinematic','Classical','Country','Electric','Folk','Funk','Hip Hop','Holiday','Indie','Jazz','Latin','Lounge','Pop','Reggae','Rock','Singer-Songwriter','Soul & RnB','World'];
        let projects = this.state.projectlist.map((item, i) => {
            return <option className="folderlistitem" value={item._id}>
                {item.project_name}
            </option>;
        });
        let datalist ='';
        if(this.state.originalAssetList.length>0) 
        {
            datalist = this.state.originalAssetList;
        } 
        else{
           datalist = this.state.data;
        }
        if( datalist )
        {
            var listItems = datalist.map((item, index) => {
                let assetpath= '';
                let downloadurl=''
                //let assetfilename= item.files[0].originalname;
                let stockfilename=item.filename;
                stockfilename=stockfilename.split('.');
                let filetype=stockfilename[1]; 
                stockfilename=stockfilename[0];                
                downloadurl=item.downloadurl;
                let moodcat=item.moodcategory;
                moodcat = moodcat.join(", ");
                let genrecat=item.genrecategory;
                genrecat = genrecat.join(", ");
                let artistname=item.artistname;
               // let filepage=item.filepage;
                let posterimage='';
                try{
                    posterimage=require('../../assets/images/stockaudios/'+item.albumcoverimage+'');
                }
                catch
                {
                    posterimage='';
                }
                return <div key={index}> 
                    {(() => {
                        if(downloadurl){
                            return(
                                <div className="row  padding-row-top-20">
                                    <div className="col-6 AssetsListColIn">  
                                        <div className="media">
                                            <img width="100" height="100" src={posterimage}/> 
                                            <div className="media-body">
                                                <h5 className="mt-0" >{stockfilename} </h5>
                                                <p className="artistname">{artistname}</p>
                                                <audio controls key={downloadurl}>
                                                    <source src={downloadurl} type="audio/mpeg"/>
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        </div>                        
                                    </div>                                 
                                    <div className="col-2 AssetsListColIn">                                   
                                        <div className="AssetsColInText padding-top-30">
                                            <h6><b>Mood:</b><br/>{moodcat}</h6>                                          
                                        </div>
                                    </div>
                                    <div className="col-2 AssetsListColIn">                                   
                                        <div className="AssetsColInText padding-top-30">
                                            <h6><b>Genre:</b><br/> {genrecat}</h6>                                          
                                        </div>
                                    </div>
                                    <div className="col-2 AssetsListColIn">                                   
                                        <div className="AssetsColInText padding-top-30">
                                            <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,item.albumcoverimage,downloadurl,stockfilename,filetype,moodcat,genrecat,artistname,item.fileid)}>Add To Project</a>                                           
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return(
                                <div className="row padding-row-top-20">
                                    <div className="col-6 AssetsListColIn">  
                                        <div className="media">
                                            <img width="100" height="100" src={posterimage}/> 
                                            <div className="media-body">
                                                <h5 className="mt-0" >{stockfilename} </h5>
                                                <p className="artistname">{artistname}</p>
                                                <audio controls key={downloadurl}>
                                                    <source src={downloadurl} type="audio/mpeg"/>
                                                    Your browser does not support the audio element.
                                                </audio>
                                            </div>
                                        </div>                        
                                    </div>                                 
                                    <div className="col-2 AssetsListColIn">                                   
                                        <div className="AssetsColInText padding-top-30">
                                            <h6><b>Mood:</b><br/>{moodcat}</h6>                                          
                                        </div>
                                    </div>
                                    <div className="col-2 AssetsListColIn">                                   
                                        <div className="AssetsColInText padding-top-30">
                                            <h6><b>Genre:</b><br/> {genrecat}</h6>                                          
                                        </div>
                                    </div>
                                    <div className="col-2 AssetsListColIn">                                   
                                        <div className="AssetsColInText padding-top-30">
                                            <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,item.albumcoverimage,downloadurl,stockfilename,filetype,moodcat,genrecat,artistname,item.fileid)}>Add To Project</a>                                          
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    })()}                   
                </div>
            });
        }        
        return (
            <div className="container-fluid AssetsList ProjectList audiolist"  ref="selfdiv">
                {
                    loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-4 UploadBreadcrumb">
                                    Stock Music
                                </div>
                                <div className="col-3 SearchFolder graphicsearch">                            
                                    <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/> 
                                </div>  
                                <div className="col-2 SearchFolder graphicsearch">                         
                                    <select name="searchvideocat" className="form-control" value={this.state.searchvideocat} onChange={this.handleSearchMoodChange.bind(this)} >
                                        <option value=" ">Select Mood</option>
                                        {
                                        mood_cat.map((value, index) => {    
                                                return (
                                                <option value={value}>{value}</option>
                                                    
                                                )                                                               
                                            })
                                        }
                                    </select>                                                                     
                                </div> 
                                <div className="col-2 SearchFolder graphicsearch">                         
                                    <select name="searchvideocat" className="form-control" value={this.state.searchvideocat} onChange={this.handleSearchGenreChange.bind(this)} >
                                        <option value=" ">Select Genre</option>
                                        {
                                        genre_cat.map((value, index) => {   
                                                return (
                                                <option value={value}>{value}</option>
                                                    
                                                )                                                               
                                            })
                                        }
                                    </select>                                                                     
                                </div>
                                <div className="col-1 SearchFolder graphicsearch">                    
                                    <a className="search btn btn-primary addbtn" onClick={this.searchFilter.bind(this)}>Go</a>                                 
                                </div>                                  
                            </div>
                            {
                                (userdata.role && userdata.role==='administrator' &&  
                                    <UploadAudio loadAudio={this.loadAudio} orgid={this.props.match.params.orgid}/>
                                )
                            }
                            <div className="AssetsListGrid rowlist">                        
                                {listItems}
                            </div>                                                             
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showAddToProject}>
                    {
                        this.state.Inloading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Add To Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="error-msg">{this.state.errormsg}</div>
                        <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                            <option value=" ">Select Project</option>
                            {projects}
                            {/* <option value="Active">Active</option>
                            <option value="On-hold">On-hold</option>
                            <option value="Completed">Completed</option> */}
                        </select>
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleAddToProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.addStockVideoToProject.bind(this)}>
                            Add To Project
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default StockAudios;
