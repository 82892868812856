import React from 'react'
import portfolio1 from '../assets/img/portfolio-img-1.png'
import portfolio2 from '../assets/img/portfolio-img-2.png'
import portfolio3 from '../assets/img/portfolio-img-3.png'
import portfolio4 from '../assets/img/portfolio-img-4.png'
import portfolio5 from '../assets/img/portfolio-img-5.png'
import portfolio6 from '../assets/img/portfolio-img-6.png'
// import ReactDOM from 'react-dom';
import Popup from 'reactjs-popup';

export default class Section3 extends React.Component {

    render() {
        return (
            <>
                <section className="section-gapping live-action-section vpma-portfolio-section">
                    <div className="vc_row">
                        <div className="container vc_col-sm-12">
                            <h2 className="common-title wow fadeInUp">Association <em>Portfolio</em></h2>
                            <div className="portfolio-content wow fadeInUp">
                                <p>Here is a small sample of the thousands of association videos we have produced to engage your membership and industry.</p>
                            </div>
                            <div className="portfolio-blog-warp wow fadeInUp">
                                <div className='portfolio-content-wrap'>
                                    <div className="portfolio-section">
                                        <div className="portfolio-wrap">
                                            <div className="portfolio-video">
                                                <Popup
                                                    trigger={<a className="button video-popup"><img src={portfolio6} alt="VPMA Stock Library" /></a>}
                                                    modal
                                                    nested
                                                >
                                                    {close => (
                                                        <div className="modalp">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="content">
                                                                {' '}
                                                                <div className='videoItem'>
                                                                    <iframe src="https://player.vimeo.com/video/412253743?autoplay=1&api=1&player_id=html5boxiframevideo" title="VPMA Stock Library"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>

                                            </div>
                                            <div className="partners-title">VPMA Stock Library</div>
                                        </div>
                                    </div>

                                    {/* / 2  */}
                                    <div className="portfolio-section">
                                        <div className="portfolio-wrap">
                                            <div className="portfolio-video">
                                                <Popup
                                                    trigger={<a className="button video-popup"><img src={portfolio5} alt="NGMA Membership Promo Video" /></a>}
                                                    modal
                                                    nested
                                                >
                                                    {close => (
                                                        <div className="modalp">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="content">
                                                                {' '}
                                                                <div className='videoItem'>
                                                                    <iframe src="https://player.vimeo.com/video/454108795?autoplay=1&api=1&player_id=html5boxiframevideo" title="NGMA Membership Promo Video"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            <div className="partners-title">NGMA Membership Promo Video</div>
                                        </div>
                                    </div>
                                    {/* / 3  */}
                                    <div className="portfolio-section">
                                        <div className="portfolio-wrap">
                                            <div className="portfolio-video">
                                                <Popup
                                                    trigger={<a className="button video-popup"><img src={portfolio4} alt="2021 AASL Conference Promo" /> </a>}
                                                    modal
                                                    nested
                                                >
                                                    {close => (
                                                        <div className="modalp">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="content">
                                                                <div className='videoItem'>
                                                                    <iframe src="https://player.vimeo.com/video/447812515?autoplay=1&api=1&player_id=html5boxiframevideo" title="2021 AASL Conference Promo"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            <div className="partners-title">2021 AASL Conference Promo</div>
                                        </div>
                                    </div>
                                    {/* / 4  */}
                                    <div className="portfolio-section">
                                        <div className="portfolio-wrap">
                                            <div className="portfolio-video">
                                                <Popup
                                                    trigger={<a className="button video-popup"><img src={portfolio3} alt="AASL Industry Video" /></a>}
                                                    modal
                                                    nested
                                                >
                                                    {close => (
                                                        <div className="modalp">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="content">
                                                                <div className='videoItem'>
                                                                    <iframe src="https://player.vimeo.com/video/440460177?autoplay=1&api=1&player_id=html5boxiframevideo" title="AASL Librarians from Association Studios on Vimeo"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            <div className="partners-title">AASL Industry Video</div>
                                        </div>
                                    </div>
                                    {/* / 5  */}
                                    <div className="portfolio-section">
                                        <div className="portfolio-wrap">
                                            <div className="portfolio-video">
                                                <Popup
                                                    trigger={<a className="button video-popup"><img src={portfolio2} alt="VPMA Stock Library" /></a>}
                                                    modal
                                                    nested
                                                >
                                                    {close => (
                                                        <div className="modalp">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="content">
                                                                <div className='videoItem'>
                                                                    <iframe src="https://player.vimeo.com/video/471549573?autoplay=1&api=1&player_id=html5boxiframevideo" title="VPMA Stock Library from Association Studios on Vimeo"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            <div className="partners-title">VPMA Stock Library</div>
                                        </div>
                                    </div>
                                    {/* / 6  */}
                                    <div className="portfolio-section">
                                        <div className="portfolio-wrap">
                                            <div className="portfolio-video">
                                                <Popup
                                                    trigger={<a className="button video-popup"><img src={portfolio1} alt="AAFA Shipping Crisis Explainer" /></a>}
                                                    modal
                                                    nested
                                                >
                                                    {close => (
                                                        <div className="modalp">
                                                            <button className="close" onClick={close}>
                                                                &times;
                                                            </button>
                                                            <div className="content">
                                                                <div className='videoItem'>
                                                                    <iframe src="https://player.vimeo.com/video/412253743?autoplay=1&api=1&player_id=html5boxiframevideo" title="AACD 2020 April Snapshot"></iframe>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            <div className="partners-title">AAFA Shipping Crisis Explainer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}