import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import dateFormat from 'dateformat';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML,genKey } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { GetData,PostData,DeleteData } from '../../../services/PostData';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import moment from 'moment';
import io from "socket.io-client";
import {apiConfig} from "../../../config/api";
import ApproveReviewTask from './ApproveReviewTask' 
var Vimeo = require('vimeo').Vimeo;

class ProjectBoard extends Component {
    constructor(props){
		super(props);
		this.state={
			draftstatusloading: false,
			setConfirmMsgShow:false,
			commentdata:this.props.commentdata,	
			prjid: this.props.prjid,
			projecttime:this.props.projecttime,
			chatdeletedid:[],	
			setEditVideoBox:false,	
			edittaskcomment:'',
			showItems: [],
			draftvimeoid: '',
			setReviewProject:false,
			selectedTaskId:'',
			taskstatustype:''
		}
		this.onPlay = this.onPlay.bind(this);
		this.onPause = this.onPause.bind(this);
		//this.editVideo = this.editVideo.bind(this);
		console.log('projecttime==',this.props.projecttime);
		let url = apiConfig.WEB_SERVICE_URL;
		url = url.replace('http://', '');
		url = url.replace('/api/', '');
		this.socket = io(url);	
	}	
	removeChat(boardid){
		console.log('called',boardid);
		DeleteData('deletechat/'+boardid).then((result) => {       
			if(result.statuscode===200)
			{
				this.setState({ chatdeletedid: [...this.state.chatdeletedid, boardid] })
				// this.setState({
				// 	chatdeletedid:boardid,
				// });
				console.log('check data==',result);
			}
        });
	}
	showMore(boardid) {
		console.log(boardid);
	    let showItems = this.state.showItems.slice();
	    showItems[boardid] = !showItems[boardid];
	    this.setState({showItems});
	}
	hide(){
		this.setState({showItems:[]});
	}
	requestVideographer(item) {
		this.props.requestVideographer();
	}
	onReviewProject(item,type) {
		console.log('item=',item.taskid);
		console.log('type=',type);
		//console.log('thisobj=',thisobj);
		let currdate=Date.now();
		this.setState({
			setReviewProject:true,
			isAddReviewProject:currdate,
			selectedTaskId:item.taskid,
			taskstatustype: type,
		});
	}
	async onReviewDeclineProject(item) {
		console.log('item=',item.taskid);
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
	    await this.getUserData(202312118405).then((udata)=>{
	    	console.log('udata=',udata);
	    	let dbuserdata = udata.data
	    	this.sendMessageFromParis(dbuserdata,userdata);
	    });
	}
	reply(item) {
		this.props.replyRequest(item);
	}
	replyTravelAuthorization(item) {
		this.props.replyTravelAuthorization(item);
	}
	componentDidMount() {		
		GetData('project/' + this.props.prjid).then((result) => {       
			if(result.statuscode===200)
			{
				this.setState({
					totaladdedtime:result.data.totaladded_time
				})						
			}
        });
	}	
	backToProjectBoard() {
		//this.setState({ boarddisplaytype: 'projectboard' });
		this.props.backToProjectBoard();
	}	
	editTask(taskid) {
		this.props.editTask(taskid);
	}
	addTimeForTask(taskid) {
		this.props.addTimeForTask(taskid);
	}
	showConfirmMsgBox(e){
		this.setState({
			setConfirmMsgShow:true,
			editeddraftid:e.currentTarget.dataset.id,
			editeddraftvideoname: e.currentTarget.dataset.videoname,
			editeddrafttitle:e.currentTarget.dataset.videotitle,
			editdraftboxid:e.currentTarget.dataset.draftboxid,
			draftvimeoid:e.currentTarget.dataset.vimeoid
		});
	}
	showEditVideoBox(pb_id,tasktype,e){
		console.log('pb_id=',pb_id,tasktype,e);
		this.setState({
			pb_id:pb_id,
			tasktype:tasktype,
			setEditVideoBox:true,
		});
	}
	closeEditVideoBox(e) {
		this.setState({
			setEditVideoBox: false,
		});
	}
	handleTaskDueDateChange(name, date) {
		this.setState({ tduedate:date });
	}
	handleTaskCommentChange(e) {
		//console.log('target.value=',e.target.value);
		this.setState({ edittaskcomment:e.target.value });
	}
	editVideo(e){
		console.log('e,item=',e);
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		const data = {
			orgid: this.props.orgid,
			projectid: this.props.prjid,
			pb_id: this.state.pb_id,
			type: 'task',
			tasktype:this.state.tasktype,
			userid: userdata.user.ID,
			tduedate:this.state.tduedate,
			edittaskcomment:this.state.edittaskcomment,
		};
		this.setState({	draftstatusloading:true, });
		const  apiurl ='createautotask';
		
		PostData(apiurl, data).then((result) => {
			console.log('result=',result);
			if (result.statuscode === 200) {
				this.setState({ taskboardsuccessmessage: result.message, draftstatusloading: false });
				setTimeout(() => {
					this.setState({  taskboardsuccessmessage: '' });
					window.location.reload(); 
				}, 3000);
			}
		});
	}
	editChat(boardid) {
		this.props.editChat(boardid);		
	}
	removeEditChat(e){
		this.setState({
			editedboardid:''
		});
	}
	handleCheckBox(e){
		this.setState({
			isChecked: !this.state.isChecked,
		  });
	}
	handlePortfolioCheckBox(e){
		this.setState({
			isAddedtoPoprtfolio: !this.state.isAddedtoPoprtfolio,
		  });
	}


	markAsComplete(e) {
		if (!this.state.isChecked) {
			this.setState({
				markascompleteerror:'Please check CheckBox for Mark As Complete'
			});			
		}
		else
		{
			console.log('this.state.totaladdedtime==',this.state.totaladdedtime);
			this.setState({
				setConfirmMsgShow:false,
				draftstatusloading: true,
			});
			let data = {
				'draftid': this.state.editeddraftid,
				'draftvideoname': this.state.editeddraftvideoname,
				'draftvideotitle':this.state.editeddrafttitle,
				'draftboxid':this.state.editdraftboxid,
				'prjid':this.state.prjid,
				'projecttime':this.state.totaladdedtime,
				'isAddedtoPoprtfolio':this.state.isAddedtoPoprtfolio
			}
			//let url =this.state.draftvimeoid ? "draftvideostatustocomplete" : 'draftvideocompletestg';
			//let markcompletepath = (this.state.editdraftboxid)? 'draftvideocompletestg' : 'draftvideocomplete';
			let markcompletepath;
			if(this.state.draftvimeoid){
				markcompletepath = 'draftvideostatustocomplete'
			} else if(this.state.editdraftboxid){
				markcompletepath = 'draftvideocompletestg'
			} else {
				markcompletepath = 'draftvideocomplete'
			}
			PostData(markcompletepath, data).then((result) => {
				if (result.statusCode === 200) {
					this.setState({
						draftstatusloading: false,
						draftstatus: true,
						draftidcompleted:result.data._id ,
						editedprojectstatus:'Completed',
						draftvimeoid:this.state.draftvimeoid,
					});	
				}
			});
		}
	}
	handleConfirmMsgBoxClose(e) {
		this.setState({
			setConfirmMsgShow: false,
			editeddraftid:'',
			editeddraftvideoname: '',
			editeddrafttitle:''
		});
	}
	
	onPlay(evt) {
		let title = evt.target.getAttribute('data-title')
		//console.log("video start play==", title, 'currentTime==', evt.currentTime);		
	}
	onPause(evt) {
		let title = evt.target.getAttribute('data-title');
		let draftid = evt.target.getAttribute('data-draftid');
		let currenttime = this.getTimeInHourMinuitSecond(evt.currentTarget.currentTime);
		//console.log("video start pasue==", title,'draftidd==',draftid, 'currentTime==', evt.currentTarget.currentTime, currenttime);
		// this.setState({
		// 	setDraftCommentShow:true,
		// 	drafttime:currenttime,
		// 	editeddraftidforcomment:draftid
		// });
	}
	videoClick(evt){		
		let title = evt.target.getAttribute('data-title');
		let draftid = evt.target.getAttribute('data-draftid');
		let currenttime = this.getTimeInHourMinuitSecond(evt.currentTarget.currentTime);
		//console.log("draftid=",draftid);
		var video = document.getElementById(draftid);
		var isPaused = video.paused;		
		if(!isPaused)
		{
			this.setState({
				setDraftCommentShow:true,
				drafttime:currenttime,
				editeddraftidforcomment:draftid
			});
		}
	}
	handleDraftCommentClose(e){
		this.setState({
			setDraftCommentShow:false,			
		});
		var videoPlayer = document.getElementById(this.state.editeddraftidforcomment);
		//videoPlayer.play()
	}
	draftCommentChange(e){
		this.setState({ [e.target.name]: e.target.value });
	}
	saveDraftComment(e){
		if (!this.state.draftcomment) {
			this.setState({
				draftcommenterror:'Please add your Comment'
			});			
		}
		else
		{
			var videoPlayer = document.getElementById(this.state.editeddraftidforcomment);
			// Auto play, half volume.
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			//console.log('userdata userdata==',userdata);
			let username=userdata.user.first_name+" "+userdata.user.last_name
			//console.log('username==',username); 
			let data = {
				boardid: this.state.editeddraftidforcomment,
				projectid: this.props.prjid,
				orgid: this.props.orgid,
				userid: userdata.user.ID,
				videotime:this.state.drafttime,
				comment:this.state.draftcomment,
				user:username
			}
			//console.log('called data',data);
			PostData('savedraftcomment', data).then((result) => {
				if (result.statuscode === 200) {
					this.setState({
						commentdata: [...this.state.commentdata, result.data],
						setDraftCommentShow:false,
						draftcomment:'',
						editedboardid:result.data.boardid
					});	
					videoPlayer.play()
				}
			});
		}
	}
	getTimeInHourMinuitSecond(timenow) {
		if (parseInt(timenow) / 60 >= 1) {
			var h = Math.floor(timenow / 3600);
			timenow = timenow - h * 3600;
			var m = Math.floor(timenow / 60);
			var s = Math.floor(timenow % 60);
			if (h.toString().length < 2) { h = '0' + h; }
			if (m.toString().length < 2) { m = '0' + m; }
			if (s.toString().length < 2) { s = '0' + s; }
			timenow = h + ' : ' + m + ' : ' + s;
		} else {
			var m = Math.floor(timenow / 60);
			var s = Math.floor(timenow % 60);
			if (m.toString().length < 2) { m = '0' + m; }
			if (s.toString().length < 2) { s = '0' + s; }
			timenow = m + ' : ' + s;
		}
		return timenow;
	}
	async shareVimeoVideo(vimeoid){
		console.log('vimeoid====',vimeoid);
		//var AuthToken = '0c909954139c00b4b07af0ce71811f3d';
      //  var AuthSecret = '3KfGrEheWGD7IXa7WolO6l1Dl10ifPY7n2JI9jXTi797trJ+O2jWTEd06EBlaUI6jUreapYboOrGsmo+MKihzwIvdiDaokXxlC6aHOoWggse70lpbVw/XUmJb3u16LLy';
       // var ClientId = '4fb799f14677e49b5cc5989cc2c2e21195c6ad95';

        //var client = new Vimeo(ClientId, AuthSecret, AuthToken);

        fetch('https://api.vimeo.com/videos/'+vimeoid, {
		    headers: {
		       Authorization: `bearer 5ac3ef32ed8fa0bc320d5eabea2da16e`,//Authorization: `bearer 5ac3ef32ed8fa0bc320d5eabea2da16e`,//old 0c909954139c00b4b07af0ce71811f3d
		      'content-type': 'application/json',
		    },
		 })
	    .then(res => {
	      const json = res.json();
	      console.log('above response==',json);
	      return json;
	    })
	    .then(json => {
	    	if(json && json.review_page && json.review_page.link) {
		     	console.log('response==',json);
		    	window.open(json.review_page.link, '_blank');
		    	console.log('json.review_page',json.review_page);
		    	console.log('json.review_page.link',json.review_page.link);
		    }
	    });
	    await this.getUserData(202312118405).then((udata)=>{
	    	console.log('udata=',udata);
	    	let dbuserdata = udata.data
	    	// userdatadb = await userdatadb.json();
		    // console.log('userdatadb=',userdatadb);
		    let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		    const userrole = userdata.role;
		    //console.log('userdata=',userdata);
		    //this.sendMessage(dbuserdata);
		    if(this.props.assigneduser && (userrole=='associatio-subscriber' || userrole=='subscriber')) { //if(this.props.assigneduser && userrole=='associatio-subscriber') {
			    this.sendMessage(dbuserdata);
			}
	    });
	   
	}
	getUserData(userid) {
		const data={
			userid:userid
		}
		return PostData('edituser',data).then((result) => {
			return result;
		});
	}
	sendMessageFromParis(userdata,currentuser){
		let assigneduser = currentuser;
		console.log('assigneduser==',assigneduser);

		const mi_tname='@Michelle';
		const mi_tvalue='Michelle';
		const mi_turl='michelle@associationstudios.com';

		const msg =  mi_tname+', @'+assigneduser.first_name+' is unavailable for this project.';
		const tname='@'+assigneduser.first_name;
		const tvalue=assigneduser.first_name;
		const turl=assigneduser.email;
		let exampleState1 = {
		  blocks: [
		        {
		          key: genKey(), //Use the genKey function from draft
		          text: msg,
		          type: 'unstyled',
		          inlineStyleRanges: [],
		          data: {},
		          depth: 0,
		          entityRanges: [
		           	{ offset: 0, length: 30, key: 0 },
		            { offset: 31, length: 30, key: 1 },
		          ]
		        }
		  ],
		  entityMap: [
		    {
		      "type": "mention",
		      "mutability": "SEGMENTED",
		      "data": {
		        "text": tname,
				"url":turl,
				"value":tvalue
		      }
		    },
		    {
		      "type": "mention",
		      "mutability": "SEGMENTED",
		      "data": {
		        "text": mi_tname,
				"url":mi_turl,
				"value":mi_tvalue
		      }
		    }
		  ]
		};

    	const es =  EditorState.createWithContent(convertFromRaw(exampleState1));
		const editorJSON = JSON.stringify(convertToRaw(es.getCurrentContent()));

        if(true) {
			this.socket.emit('SEND_MESSAGE', {
				userdata: userdata,
				project_id: this.props.prjid,
				org_id:this.props.orgid,
				message: editorJSON,
				chatattachament: '',
				chatattchedfilename:'',
				chatattchedfilebasename:'',
				chatid:'',
				chatallusers:this.props.allusers
			})
	    }
	}

	sendMessageFromParisForMaybe(userdata,currentuser,msgdata){
		let assigneduser = currentuser;
		console.log('assigneduser==',assigneduser);

		const mi_tname='@Michelle';
		const mi_tvalue='Michelle';
		const mi_turl='michelle@associationstudios.com';

		const msg =  mi_tname+', @'+assigneduser.first_name+'  is asking: '+msgdata.comment;
		const tname='@'+assigneduser.first_name;
		const tvalue=assigneduser.first_name;
		const turl=assigneduser.email;
		let exampleState1 = {
		  blocks: [
		        {
		          key: genKey(), //Use the genKey function from draft
		          text: msg,
		          type: 'unstyled',
		          inlineStyleRanges: [],
		          data: {},
		          depth: 0,
		          entityRanges: [
		           	{ offset: 0, length: 30, key: 0 },
		            { offset: 31, length: 30, key: 1 },
		          ]
		        }
		  ],
		  entityMap: [
		    {
		      "type": "mention",
		      "mutability": "SEGMENTED",
		      "data": {
		        "text": tname,
				"url":turl,
				"value":tvalue
		      }
		    },
		    {
		      "type": "mention",
		      "mutability": "SEGMENTED",
		      "data": {
		        "text": mi_tname,
				"url":mi_turl,
				"value":mi_tvalue
		      }
		    }
		  ]
		};

    	const es =  EditorState.createWithContent(convertFromRaw(exampleState1));
		const editorJSON = JSON.stringify(convertToRaw(es.getCurrentContent()));

        if(true) {
			this.socket.emit('SEND_MESSAGE', {
				userdata: userdata,
				project_id: this.props.prjid,
				org_id:this.props.orgid,
				message: editorJSON,
				chatattachament: '',
				chatattchedfilename:'',
				chatattchedfilebasename:'',
				chatid:'',
				chatallusers:this.props.allusers
			})
	    }
	}

	 saveMaybeOption = async (returndata) => {
		console.log('returndata==',returndata);	
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
	    await this.getUserData(202312118405).then((udata)=>{
	    	console.log('udata=',udata);
	    	let dbuserdata = udata.data
	    	this.sendMessageFromParisForMaybe(dbuserdata,userdata,returndata);
	    });

	};


	sendMessage(userdata){
		let assigneduser = this.props.assigneduser;
		console.log('assigneduser==',assigneduser);
		const msg =  '@'+assigneduser.first_name+' just a reminder to check the Vimeo Review Page for any feedback';
		const tname='@'+assigneduser.first_name;
		const tvalue=assigneduser.first_name;
		const turl=assigneduser.email;
		let exampleState1 = {
		  blocks: [
		        {
		          key: genKey(), //Use the genKey function from draft
		          text: msg,
		          type: 'unstyled',
		          inlineStyleRanges: [],
		          data: {},
		          depth: 0,
		          entityRanges: [
		            { offset: 15, length: 7, key: 0 }
		          ]
		        }
		  ],
		  entityMap: [
		     {
		      "type": "mention",
		      "mutability": "SEGMENTED",
		      "data": {
		        "text": tname,
				"url":turl,
				"value":tvalue
		      }
		    }
		  ]
		};

    	const es =  EditorState.createWithContent(convertFromRaw(exampleState1));
		const editorJSON = JSON.stringify(convertToRaw(es.getCurrentContent()));

        if(true) {
			this.socket.emit('SEND_MESSAGE', {
				userdata: userdata,
				project_id: this.props.prjid,
				org_id:this.props.orgid,
				message: editorJSON,
				chatattachament: '',
				chatattchedfilename:'',
				chatattchedfilebasename:'',
				chatid:'',
				chatallusers:this.props.allusers
			})
	    }
	}
	async showCalendly(e) {
		//this.setState({ isOpen: true });
		//this.setState({ scheduletype: e.currentTarget.dataset.id, boarddisplaytype: '' });
		//this.scrollToTop();
		let calendlyId = e.currentTarget.dataset.id
		const url = 'getcalendlydatabyid/'+calendlyId;
		GetData(url).then((result) => {
			if (result && result.data) {
				this.setState({ isreadytosend: true, Inloading: false, eventtype:result.data.eventtype });
				console.log('data returnedddddddddd',result.data);
				//this.setState({ isOpen: true });
				this.props.showCalendly(result.data.eventtype);
			}
		});
	}
    render() {
		//console.log('data====',this.props.data);
		let pboard='';
		let projectboard=this.props.data;
		let userdatatocheckusertype = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		let currentuserid = userdatatocheckusertype.ID;
		const comments=this.state.commentdata
		const handleDraftComment = this.state.setDraftCommentShow;
		const tableheader = <tr>
			<th>Time Code</th>
			<th>Comment</th>
			<th>Date</th>
			<th>User</th>
		</tr>;
		const CustomButton = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				<img src={require('../../../assets/images/due-date.png')} /> {value}
			</button>
		);
		const handleConfirmMsgShow = this.state.setConfirmMsgShow;
		if (projectboard) {
			pboard = projectboard.map((item) => {	
				let chatdeletedids=this.state.chatdeletedid;
				let isdeleted = chatdeletedids.includes(item._id);
				if(isdeleted || item.ishide)
				{
					return false;
				}	
					
				const propertyName = item.type;
				let innertext = '';
				let checkusertype = '';
				//console.log('item.chatuserdata==',item.chatuserdata);
				let userlink='';
				let noimage =false; 
                let noimagename=''; 
				if (item.type == 'chat')
				{
					//console.log('item.userdata[0].iditem.userdata[0].id=',item.userdata[0].id);
					checkusertype = (item.chatuserdata[0].ID === currentuserid) ? ' currentuser' : ' otheruser';
					if(item.userdata && item.userdata[0] && item.userdata[0].ID===null)
					{
						//userlink=item.userdata[0].avtar
						userlink=item.chatuserdata[0].profile_image;
					}
					else
					{
						if(item.userdata && item.userdata[0] && item.userdata[0].ID!=null)
						{
							userlink=item.userdata[0].profile_image
						}
						else
						{
							userlink=item.chatuserdata[0].profile_image
						}
						//userlink=item.chatuserdata[0].avatar;
					}
				}
				else if(item.type === 'task' && item.taskdata && item.taskdata.length===0)
				{
					return;
				}
				else
				{
					checkusertype = (item.userdata[0].ID === currentuserid) ? ' currentuser' : ' otheruser';
					userlink=item.userdata[0].profile_image
					//console.log('item type==',item.type);
				}
				var imageurl=''
				if(userlink)
				{
					//console.log('ccalled in user link==',userlink);
					imageurl='../../../../../profile-image/'+userlink;
				}
				else
				{
					//console.log('ccalled in else  user link==');
					imageurl='';
				}

				if(userlink)
				{
					var imgname = userlink.split(".");
					imgname = imgname[0];
					imgname = imgname.split("_");
					//console.log('imgname===',imgname);
					//console.log('imgname.length===',imgname.length);
					if(imgname.length>1 && imgname[1]==='noimage')
					{
						console.log('in iff imgname.length===',imgname.length);

						if (item.type == 'chat')
						{
							console.log('in iff chat imgname.length===',imgname.length);
							if(item.userdata && item.userdata[0] && item.userdata[0].ID===null)
							{
								console.log('in iff chat item.userdat imgname.length===',imgname.length);
								//userlink=item.userdata[0].avtar
								//userlink=item.chatuserdata[0].profile_image;

								noimage=true;
								if(item.chatuserdata[0].first_name)
								var fn=item.chatuserdata[0].first_name
								if(item.chatuserdata[0].last_name)
								var ln=item.chatuserdata[0].last_name
								noimagename=fn.charAt(0)+ln.charAt(0);
								noimagename = noimagename.toUpperCase();
								console.log('in iff imgname==',imgname);

							}
							else
							{
								//userlink=item.chatuserdata[0].profile_image;
								console.log('in else chat item.userdat imgname.length===',imgname.length);							
								noimage=true;
								if(item.chatuserdata[0].first_name)
								var fn=item.chatuserdata[0].first_name
								if(item.chatuserdata[0].last_name)
								var ln=item.chatuserdata[0].last_name
								noimagename=fn.charAt(0)+ln.charAt(0);
								noimagename = noimagename.toUpperCase();
								console.log('in iff imgname==',imgname);
							}
							
						}
						else{
							console.log('in else item item===',item);
							noimage=true;
							if(item.userdata[0].first_name)
							var fn=item.userdata[0].first_name
							if(item.userdata[0].last_name)
							var ln=item.userdata[0].last_name
							noimagename=fn.charAt(0)+ln.charAt(0);
							noimagename = noimagename.toUpperCase();
							console.log('imgname==',imgname);
						}
					}
				}
				let dynclassname = (item.type == 'savedate') ? 'PB_create_date type' : 'type';
				//else
				//checkusertype='';
				return <li className={"itemlist " + item.type + checkusertype}>
					<div className="row">
						<div className="col-12 ChatBoxDesign">
							{/* {
								(item.type === 'chat') ?
								<div class="ChatImgThumb">
									<img src={item.chatuserdata[0].avatar} />
								</div>
								:
								<div class="ChatImgThumb">
									<img src={item.userdata[0].avtar} />
								</div>
							}	 */}
							<div class="ChatImgThumb">
								{(noimage)?<div className="noimage pbnoimage">{noimagename}</div>:<img src={imageurl} />
								}
								
							</div>															
							{
								(item.type === 'savedate') &&
								<div className="type"><label>Type: </label>{item.type}</div>
							}
							<div className={dynclassname}>{
								(item.type === 'chat') ? <div className="inchat">{item.chatuserdata[0].first_name} {item.chatuserdata[0].last_name}</div> : <div>{item.userdata[0].first_name} {item.userdata[0].last_name}</div>
							}</div>
							<div className="PB_create_date">{dateFormat(item.create_date, "mmm d, yyyy")}
							</div>
							
							{(() => {
								if (item.type === 'draft') {
									//console.log('item.draftstatus==',item.draftstatus,"this.state.draftstatus==",this.state.draftstatus);
									console.log("typeof item.draftdata====",typeof item.draftdata,item.draftdata.length);
									let draftlength=item.draftdata.length
									let createdraftpagelink = '';
									let draftvideosrc='';
									let draftpostersrc='';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									let draftvideosrcfordraftdownloadlink11='';
									let draftsrc1 = item.draftfile.split(".");
									let draftsrccom = item.draftfile.split(".");
									draftsrc1=draftsrc1[0]+".mp4";
									console.log('draftsrccom=',draftsrccom);
									console.log('item.isUploadToBox=',item.isUploadToBox);
									let imgcomingsoon;
									
									if (item.draft_vimeo_id){
										draftvideosrc='https://player.vimeo.com/video/'+item.draft_vimeo_id;
									} else if (item.isUploadToBox === true) {
										draftvideosrc=item.assetdata;
									}
									else if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										draftvideosrc="../../../../draft/"+item.draftfile;
										draftvideosrcfordraftdownloadlink11="../../../../draft/"+draftsrc1
										draftpostersrc="../../../../draft/"+item.draftpostarimage;
									}
									else {
										draftvideosrc="../../../../../draft/"+item.draftfile;
										draftvideosrcfordraftdownloadlink11="../../../../../draft/"+draftsrc1;
										draftpostersrc="../../../../../draft/"+item.draftpostarimage;
									}
									createdraftpagelink = `/draft/${item._id}`;
									//if (item.isUploadToBox === false && (draftsrccom[1]==='mov' || draftsrccom[1]==='MOV')) {
										draftpostersrc=require('../../../assets/images/video-coming-soon.gif');
									//}
									let draftsrc = '';
									try {
										draftsrc = '../../assets/images/draft/' + item.draftfile + '';
									}
									catch
									{
										draftsrc = '';
									}

									return (
										<div className="row">
											{
												this.state.draftstatusloading ?
													<div className="loaderspinner">
														<Loader
															type="TailSpin"
															color="#D10000"
															height={100}
															width={100}
														/>
													</div>
												: null
											}
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Video Title:</strong> {item.drafttitle}&nbsp;&nbsp;
												</p>
												<div className="ComIncLinkBox">
													{(item.draftstatus === true || (this.state.draftstatus === true && this.state.draftidcompleted===item._id)) ?
														<div className="ComIncLink">Completed	</div>
														: <a className="forcusror ComIncLink" data-vimeoid={item.draft_vimeo_id ? item.draft_vimeo_id :''} data-id={item._id} data-videoname={item.draftfile}  data-videotitle={item.drafttitle} data-draftboxid={item.isUploadToBox === true ? item.draft_box_id :''}  onClick={this.showConfirmMsgBox.bind(this)} >Mark As Complete</a>
													}
												</div>
												<p>
													<div className="vidoo-content iframeVideo">
														{(item.draft_vimeo_id) ?
															<iframe src={draftvideosrc} width="100%" height="220" align="right" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
															: 
														     <video id={item._id} poster={draftpostersrc} data-draftid={item._id} data-title={item.drafttitle} onClick={this.videoClick.bind(this)} onPlay={this.onPlay} onPause={this.onPause} controls width="100%" height="220">
																<source src={draftvideosrc}
																	type="video/mp4" />
																Sorry, your browser doesn't support embedded videos.
															</video>
														}
													</div>
												</p>
											</div>
											<ul className="ComIncLinkBoxExt">
												{/*<li>
													{(item.draft_vimeo_id) ?
														<a className="forcusror" target="_blank" href={draftvideosrcfordraftdownloadlink11} download > Download
														</a>
														:
														<a className="forcusror" target="_blank"  href={draftvideosrcfordraftdownloadlink11} download> Download
														</a>
													}
												</li>
												*/}
												<li>
													{(item.draft_vimeo_id) ?
														<a className="forcusror" onClick={this.shareVimeoVideo.bind(this, item.draft_vimeo_id)}>Vimeo Review Page</a>
														:
														<a className="forcusror" target="_blank" href={createdraftpagelink}>Vimeo Review Page</a>
													}
												</li>
											</ul>	
											<table className="popup-comment-box table table-striped table-bordered dataTable no-footer ">
											{draftlength>0 ?					
											(
												
												<thead>
													{tableheader}
												</thead>
												
											)																	
											:''
											}
											{draftlength==0 && comments.length>0 && this.state.editedboardid===item._id ?	
											(
												<thead>
													{tableheader}
												</thead>
											)																
											:''
											}	
											<tbody>										
											{draftlength>0 ?( item.draftdata.map((value, index) => {	
												return (
													<tr>
														<td>{value.videotime}</td>
														<td>{value.comment}</td>
														<td>{dateFormat(item.createdAt, "mmm d, yyyy h:MM:ss")}</td>
														<td>{value.user}</td>								
													</tr>
												)																
											})	
											)										
											:''
											}
											{comments.map((value, index) => {										if(value.boardid === item._id)
												{
													return (
														<tr>
															<td>{value.videotime}</td>
															<td>{value.comment}</td>
															<td>{dateFormat(item.createdAt, "mmm d, yyyy, h:MM:ss")}</td>
															<td>{value.user}</td>								
														</tr>
													)
												}											
											})}	
											</tbody>
										</table>								
										</div>
									)
								} else if (item.type === 'video') {
									//console.log('item.draftstatus==',item.draftstatus,"this.state.draftstatus==",this.state.draftstatus);
									console.log("typeof item.draftdata====",typeof item.draftdata,item.draftdata.length);
									let draftlength=item.draftdata.length
									let createdraftpagelink = '';
									let draftvideosrc='';
									let draftpostersrc='';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									let draftvideosrcfordraftdownloadlink11='';
									let draftsrc1 = item.draftfile.split(".");
									let draftsrccom = item.draftfile.split(".");
									draftsrc1=draftsrc1[0]+".mp4";
									console.log('draftsrccom=',draftsrccom);
									console.log('item.isUploadToBox=',item.isUploadToBox);
									let imgcomingsoon;
									
									if (item.draft_vimeo_id){
										draftvideosrc='https://player.vimeo.com/video/'+item.draft_vimeo_id;
									} else if (item.isUploadToBox === true) {
										draftvideosrc=item.assetdata;
									}
									else if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										draftvideosrc="../../../../draft/"+item.draftfile;
										draftvideosrcfordraftdownloadlink11="../../../../draft/"+draftsrc1
										draftpostersrc="../../../../draft/"+item.draftpostarimage;
									}
									else {
										draftvideosrc="../../../../../draft/"+item.draftfile;
										draftvideosrcfordraftdownloadlink11="../../../../../draft/"+draftsrc1;
										draftpostersrc="../../../../../draft/"+item.draftpostarimage;
									}
									createdraftpagelink = `/draft/${item._id}`;
									//if (item.isUploadToBox === false && (draftsrccom[1]==='mov' || draftsrccom[1]==='MOV')) {
										draftpostersrc=require('../../../assets/images/video-coming-soon.gif');
									//}
									let draftsrc = '';
									try {
										draftsrc = '../../assets/images/draft/' + item.draftfile + '';
									}
									catch
									{
										draftsrc = '';
									}

									return (
										<div className="row">
											{
												this.state.draftstatusloading ?
													<div className="loaderspinner">
														<Loader
															type="TailSpin"
															color="#D10000"
															height={100}
															width={100}
														/>
													</div>
												: null
											}
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Video Title:</strong> {item.drafttitle}&nbsp;&nbsp;
												</p>
												<p>
													<div className="vidoo-content iframeVideo">
														{(item.draft_vimeo_id) ?
															<iframe src={draftvideosrc} width="100%" height="220" align="right" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
															: 
														     <video id={item._id} poster={draftpostersrc} data-draftid={item._id} data-title={item.drafttitle} onClick={this.videoClick.bind(this)} onPlay={this.onPlay} onPause={this.onPause} controls width="100%" height="220">
																<source src={draftvideosrc}
																	type="video/mp4" />
																Sorry, your browser doesn't support embedded videos.
															</video>
														}
													</div>
												</p>
											</div>
											<ul className="ComIncLinkBoxExt">
												{/*<li>
													{(item.draft_vimeo_id) ?
														<a className="forcusror" target="_blank" href={draftvideosrcfordraftdownloadlink11} download > Download
														</a>
														:
														<a className="forcusror" target="_blank"  href={draftvideosrcfordraftdownloadlink11} download> Download
														</a>
													}
												</li>
												*/}
												<li>
													{(item.draft_vimeo_id) ?
														<a className="forcusror" onClick={this.shareVimeoVideo.bind(this, item.draft_vimeo_id)}>Vimeo Review Page</a>
														:
														<a className="forcusror" target="_blank" href={createdraftpagelink}>Vimeo Review Page</a>
													}
												</li>
											</ul>	
											<table className="popup-comment-box table table-striped table-bordered dataTable no-footer ">
											{draftlength>0 ?					
											(
												
												<thead>
													{tableheader}
												</thead>
												
											)																	
											:''
											}
											{draftlength==0 && comments.length>0 && this.state.editedboardid===item._id ?	
											(
												<thead>
													{tableheader}
												</thead>
											)																
											:''
											}	
											<tbody>										
											{draftlength>0 ?( item.draftdata.map((value, index) => {	
												return (
													<tr>
														<td>{value.videotime}</td>
														<td>{value.comment}</td>
														<td>{dateFormat(item.createdAt, "mmm d, yyyy h:MM:ss")}</td>
														<td>{value.user}</td>								
													</tr>
												)																
											})	
											)										
											:''
											}
											{comments.map((value, index) => {										if(value.boardid === item._id)
												{
													return (
														<tr>
															<td>{value.videotime}</td>
															<td>{value.comment}</td>
															<td>{dateFormat(item.createdAt, "mmm d, yyyy, h:MM:ss")}</td>
															<td>{value.user}</td>								
														</tr>
													)
												}											
											})}	
											</tbody>
										</table>								
										</div>
									)
								} else if (item.type === 'compfile') {
									//console.log('item.draftstatus==',item.draftstatus,"this.state.draftstatus==",this.state.draftstatus);
									console.log("typeof item.draftdata====",typeof item.draftdata,item.draftdata.length);
									let draftlength=item.draftdata.length
									let createdraftpagelink = '';
									let draftvideosrc='';
									let draftpostersrc='';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									let draftvideosrcfordraftdownloadlink11='';
									let draftsrc1 = item.draftfile.split(".");
									let draftsrccom = item.draftfile.split(".");
									draftsrc1=draftsrc1[0]+".mp4";
									console.log('draftsrccom=',draftsrccom);
									console.log('item.isUploadToBox=',item.isUploadToBox);
									let imgcomingsoon;
									
									if (item.draft_vimeo_id){
										draftvideosrc='https://player.vimeo.com/video/'+item.draft_vimeo_id;
									} else if (item.isUploadToBox === true) {
										draftvideosrc=item.assetdata;
									}
									else if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										draftvideosrc="../../../../draft/"+item.draftfile;
										draftvideosrcfordraftdownloadlink11="../../../../draft/"+draftsrc1
										draftpostersrc="../../../../draft/"+item.draftpostarimage;
									}
									else {
										draftvideosrc="../../../../../draft/"+item.draftfile;
										draftvideosrcfordraftdownloadlink11="../../../../../draft/"+draftsrc1;
										draftpostersrc="../../../../../draft/"+item.draftpostarimage;
									}
									createdraftpagelink = `/draft/${item._id}`;
									//if (item.isUploadToBox === false && (draftsrccom[1]==='mov' || draftsrccom[1]==='MOV')) {
										draftpostersrc=require('../../../assets/images/video-coming-soon.gif');
									//}
									let draftsrc = '';
									try {
										draftsrc = '../../assets/images/draft/' + item.draftfile + '';
									}
									catch
									{
										draftsrc = '';
									}

									return (
										<div className="row">
											{
												this.state.draftstatusloading ?
													<div className="loaderspinner">
														<Loader
															type="TailSpin"
															color="#D10000"
															height={100}
															width={100}
														/>
													</div>
												: null
											}
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Video Title:</strong> {item.drafttitle}&nbsp;&nbsp;
												</p>
												<p>
													<div className="vidoo-content iframeVideo">
														{(item.draft_vimeo_id) ?
															<iframe src={draftvideosrc} width="100%" height="220" align="right" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
															: 
														     <video id={item._id} poster={draftpostersrc} data-draftid={item._id} data-title={item.drafttitle} onClick={this.videoClick.bind(this)} onPlay={this.onPlay} onPause={this.onPause} controls width="100%" height="220">
																<source src={draftvideosrc}
																	type="video/mp4" />
																Sorry, your browser doesn't support embedded videos.
															</video>
														}
													</div>
												</p>
											</div>
											<ul className="ComIncLinkBoxExt">
												{/*<li>
													{(item.draft_vimeo_id) ?
														<a className="forcusror" target="_blank" href={draftvideosrcfordraftdownloadlink11} download > Download
														</a>
														:
														<a className="forcusror" target="_blank"  href={draftvideosrcfordraftdownloadlink11} download> Download
														</a>
													}
												</li>
												*/}
												<li>
													{(item.draft_vimeo_id) ?
														<a className="forcusror" onClick={this.shareVimeoVideo.bind(this, item.draft_vimeo_id)}>Vimeo Review Page</a>
														:
														<a className="forcusror" target="_blank" href={createdraftpagelink}>Vimeo Review Page</a>
													}
												</li>
											</ul>	
											<table className="popup-comment-box table table-striped table-bordered dataTable no-footer ">
											{draftlength>0 ?					
											(
												
												<thead>
													{tableheader}
												</thead>
												
											)																	
											:''
											}
											{draftlength==0 && comments.length>0 && this.state.editedboardid===item._id ?	
											(
												<thead>
													{tableheader}
												</thead>
											)																
											:''
											}	
											<tbody>										
											{draftlength>0 ?( item.draftdata.map((value, index) => {	
												return (
													<tr>
														<td>{value.videotime}</td>
														<td>{value.comment}</td>
														<td>{dateFormat(item.createdAt, "mmm d, yyyy h:MM:ss")}</td>
														<td>{value.user}</td>								
													</tr>
												)																
											})	
											)										
											:''
											}
											{comments.map((value, index) => {										if(value.boardid === item._id)
												{
													return (
														<tr>
															<td>{value.videotime}</td>
															<td>{value.comment}</td>
															<td>{dateFormat(item.createdAt, "mmm d, yyyy, h:MM:ss")}</td>
															<td>{value.user}</td>								
														</tr>
													)
												}											
											})}	
											</tbody>
										</table>								
										</div>
									)
								}
								else if (item.type === 'Portfolio') {
									//console.log('item.draftstatus==',item.draftstatus,"this.state.draftstatus==",this.state.draftstatus);
									//console.log("typeof item.draftdata====",typeof item.filename,item.draftdata.length);
									let draftlength=item.draftdata.length
									let createdraftpagelink = '';
									let draftvideosrc='';
									let draftpostersrc='';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									let draftvideosrcfordraftdownloadlink11='';
									let draftsrc1 = item.filename.split(".");
									//draftsrc1=draftsrc1[0]+".mp4";
									if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										createdraftpagelink = `/draft/${item._id}`;
										draftvideosrc="../../../../draft/"+item.filename;
										draftvideosrcfordraftdownloadlink11="../../../../draft/"+item.filename
										draftpostersrc="../../../../draft/"+item.draftpostarimage;
									}
									else {
										createdraftpagelink = `/draft/${item._id}`;
										draftvideosrc="../../../../../draft/"+item.filename;
										draftvideosrcfordraftdownloadlink11="../../../../../draft/"+item.filename;
										draftpostersrc="../../../../../draft/"+item.draftpostarimage;
									}
									let draftsrc = '';
									try {
										draftsrc = '../../assets/images/draft/' + item.filename + '';
									}
									catch
									{
										draftsrc = '';
									}

									return (
										<div className="row">
											{
												this.state.draftstatusloading ?
													<div className="loaderspinner">
														<Loader
															type="TailSpin"
															color="#D10000"
															height={100}
															width={100}
														/>
													</div>
												: null
											}
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Video Title:</strong> {draftsrc1[0]}&nbsp;&nbsp;
												</p>
												<div className="ComIncLinkBox">
													{/* {(item.draftstatus === true || (this.state.draftstatus === true && this.state.draftidcompleted===item._id)) ?
														<div className="ComIncLink">Completed	</div>
														: <a className="forcusror ComIncLink" data-id={item._id} data-videoname={item.draftfile}  data-videotitle={item.drafttitle}  onClick={this.showConfirmMsgBox.bind(this)} >Mark As Complete</a>
													} */}
												</div>
												<p>
													<div className="vidoo-content">
														<video id={item._id} poster={draftpostersrc} data-draftid={item._id} data-title={item.drafttitle} onClick={this.videoClick.bind(this)} onPlay={this.onPlay} onPause={this.onPause} controls width="100%" height="220">
															<source src={draftvideosrc}
																type="video/mp4" />
															Sorry, your browser doesn't support embedded videos.
														</video>
													</div>
												</p>
											</div>
											<ul className="ComIncLinkBoxExt">
												<li>
													<a className="forcusror" target="_blank" href={draftvideosrcfordraftdownloadlink11} download> Download
													</a>
												</li>
												{/* <li>
													<a className="forcusror" target="_blank" href={createdraftpagelink}>External Link</a>
												</li> */}
											</ul>	
											<table className="popup-comment-box table table-striped table-bordered dataTable no-footer ">
											{draftlength>0 ?					
											(
												
												<thead>
													{tableheader}
												</thead>
												
											)																	
											:''
											}
											{draftlength==0 && comments.length>0 && this.state.editedboardid===item._id ?	
											(
												<thead>
													{tableheader}
												</thead>
											)																
											:''
											}	
											<tbody>										
											{draftlength>0 ?( item.draftdata.map((value, index) => {	
												return (
													<tr>
														<td>{value.videotime}</td>
														<td>{value.comment}</td>
														<td>{dateFormat(item.createdAt, "mmm d, yyyy h:MM:ss")}</td>
														<td>{value.user}</td>								
													</tr>
												)																
											})	
											)										
											:''
											}
											{comments.map((value, index) => {										if(value.boardid === item._id)
												{
													return (
														<tr>
															<td>{value.videotime}</td>
															<td>{value.comment}</td>
															<td>{dateFormat(item.createdAt, "mmm d, yyyy, h:MM:ss")}</td>
															<td>{value.user}</td>								
														</tr>
													)
												}											
											})}	
											</tbody>
										</table>								
										</div>
									)
								}
								else if (item.type === 'task') {
									return (
										<div className="row">
											{
												(item.taskdata) &&
												item.taskdata.map((value, index) => {
													let assignment = value.assignment=='Other'? value.videoshoottype : value.assignment;
													let tm = value.teammember;
													tm = tm.split(' ');
													let start_date;
													let end_date;
													let start_time;
													let end_time;
													if(value.startDateTime && value.endDateTime) {
														start_date = dateFormat(value.startDateTime, "mmm d, yyyy");
														end_date = dateFormat(value.endDateTime, "mmm d, yyyy");
														start_time = dateFormat(value.startDateTime, "h:MM:ss TT");
														end_time = dateFormat(value.endDateTime, "h:MM:ss TT");
													}
													

													return (
														<div class="ProjectRepeatTbl">
															{(() => {
															if(userdatatocheckusertype.role && userdatatocheckusertype.role==='administrator')
															{
																return (
																	<div>
																<a onClick={this.editTask.bind(this, value._id)} className="edittask"><img src={require('../../../assets/images/edit-icon.png')} /></a>						
																{/* <a className="TaskTime" onClick={this.addTimeForTask.bind(this, value._id)} ><img src={require('../../../assets/images/timelog.png')} /></a> */}
																</div>
																)
															}
															})()}

															{( assignment=="Add Request Videographer" ?
																<p>
																@{tm[0]} please use this form to <a className="forcusror color-blue" onClick={this.requestVideographer.bind(this, item)}><strong> request a videographer </strong></a>
																</p>
																:
																<p>
																
																	<strong>Assigned: </strong> {value.teammember}&nbsp;&nbsp;|&nbsp;&nbsp;
																	{( assignment=="Add Request Videographer" ?
																	<>
																	<strong>Assignment: </strong><a className="forcusror" onClick={this.requestVideographer.bind(this, item)}>	{assignment}&nbsp;&nbsp;|&nbsp;&nbsp;</a>
																	</>
																	:
																	<>
																	<strong>Assignment: </strong>	{assignment}&nbsp;&nbsp;|&nbsp;&nbsp;
																	</>
																	)}
																	{( value.startDateTime && value.endDateTime &&
																	<>
																	<strong>Start Date/Time: </strong>	{start_date} at {start_time}&nbsp;&nbsp;|&nbsp;&nbsp;
																	<strong>End Date/Time: </strong>	{end_date} at {end_time}&nbsp;&nbsp;|&nbsp;&nbsp;
																	</>
																	)}
																	<strong>Task Due Date: </strong> {dateFormat(value.due_date, "mmmm dS, yyyy")}

																	{( value.assignment == 'Review Project' &&
																	<>
																	<br/>
																	<a className="forcusror color-blue" onClick={this.onReviewProject.bind(this, item,'accept')}><strong> accept
																	</strong></a> |&nbsp;&nbsp;
																	<a className="forcusror color-blue" onClick={this.onReviewDeclineProject.bind(this, item)}><strong> decline
																	</strong></a> |&nbsp;&nbsp;
																	<a className="forcusror color-blue" onClick={this.onReviewProject.bind(this, item, 'maybe')}><strong> maybe
																	</strong></a>
																	</>
																	)}
																</p>
															)}
															
														</div>
													)
												})

											}
										</div>
									)
								}
								else if (item.type === 'tasktimelog' && !item.ishide) {
									return (
										<div className="row">						
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Assigned: </strong> {item.selectedassigneeteammember}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Assignment: </strong>	{item.taskname}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Time: </strong> {item.tasktime}
												</p>
											</div>
										</div>
									)
								}
								else if (item.type === 'storyboard') {
									return (
										<div className="row">
											{
												item.message.map((value, index) => {
													let message = convertFromRaw(JSON.parse(value));
													let visual = convertFromRaw(JSON.parse(item.visual[index]));
													let editorState1 = EditorState.createWithContent(message, null);
													let editorState2 = EditorState.createWithContent(visual, null);

													return (
														<div class="ProjectRepeatTbl">
															<h5 class="Scenerpt">
																<strong>Scene: </strong>  {(index + 1)}</h5>
															<table>
																<thead>
																	<tr>
																		<th>Message:</th>
																		<th>Visual:</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td><Editor editorState={editorState1}
																			readOnly={true} /></td>
																		<td><Editor editorState={editorState2}
																			readOnly={true} /></td>
																	</tr>
																</tbody>
															</table>
														</div>
													)
												})
											}
										</div>
									)
								} else if (item.type === 'script') {
									let scrptlink = '';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										scrptlink="../../../../scriptdata/"+item.scriptfile;
									}
									else {
										scrptlink="../../../../../scriptdata/"+item.scriptfile;					
									}
									return (
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Upload Title: </strong> {item.scripttitle}&nbsp;&nbsp;|&nbsp;&nbsp;
														<strong>Download Uploaded Script: </strong> <a href={scrptlink} target="_blank" download>Download</a> &nbsp;&nbsp;
														{/* |&nbsp;&nbsp;
														<strong>Added Time: </strong> {item.scripttime} */}
												</p>
												{ ( item.scriptaddedfrom=='scriptwritter' ?
												<a style={{display:"block"}} className="forcusror ComIncLink neededits"  data-id={item._id}  href={'https://vpma.associationstudios.com/org/'+this.props.orgid+'/scriptwriter?editscript='+item._id} target="_blank">Edit</a>
												:
												<a style={{display:"block"}} className="forcusror ComIncLink neededits"  data-id={item._id}  onClick={ this.showEditVideoBox.bind(this,item._id,'script')} >Edit</a>
												)}
											</div>
										</div>
									)
								} else if (item.type === 'uploadreceipt') {
									let scrptlink = '';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										scrptlink="../../../../receiptdata/"+item.receiptfile;
									}
									else {
										scrptlink="../../../../../receiptdata/"+item.receiptfile;					
									}
									return (
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Receipt Description: </strong> {item.description}&nbsp;&nbsp;|&nbsp;&nbsp;
														<strong>Download Uploaded Receipt: </strong> <a href={scrptlink} target="_blank" download>Download</a> &nbsp;&nbsp;
														 |&nbsp;&nbsp;
														<strong>Amount: </strong> {item.amount} 
												</p>
												
											</div>
										</div>
									)
								} else if (item.type === 'voice over') {
									let voiceoverlink = '';
									let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
									if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
										voiceoverlink="../../../../voiceover/"+item.voicefile;
									}
									else {
										voiceoverlink="../../../../../voiceover/"+item.voicefile;					
									}
									return (
										<div>
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Voice Over Title: </strong> {item.voicetitle}&nbsp;&nbsp;|&nbsp;&nbsp;
															<strong>Download Voice Over: </strong> <a href={voiceoverlink} target="_blank" download>Download</a> &nbsp;&nbsp;
															{/* |&nbsp;&nbsp;
															<strong>Added Time: </strong> {item.voicetime} */}
												</p>

											</div>
										</div>
										<div className="row">
											<audio controls key={voiceoverlink}>
                                                <source src={voiceoverlink} type="audio/mpeg"/>
                                                Your browser does not support the audio element.
                                            </audio><br/>
                                            <a style={{display:"block"}} className="forcusror ComIncLink neededits"  data-id={item._id}  onClick={ this.showEditVideoBox.bind(this,item._id,'voice')} >Need Edits</a>
										</div>
										</div>
									)
								}
								else if (item.type === 'chat') {
									//if(this.state.chatdeletedid!=item._id){
										let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
										let echatmessage = convertFromRaw(JSON.parse(item.chatmessage));
										let echatmessageeditorState = EditorState.createWithContent(echatmessage, null);
										let attachtype=item.chatattachedfile;
										let attachfileurl='';
										let editeddata=this.props.editeddata;
										//console.log('in editeddata above',editeddata);
										if(editeddata && editeddata._id===item._id)
										{
											//console.log('in editeddata',editeddata);
											echatmessage = convertFromRaw(JSON.parse(editeddata.chatmessage));
											echatmessageeditorState = EditorState.createWithContent(echatmessage, null);
										}
										
										let attachtypes=item.chatattachedfile;
										//attachtypes=attachtypes.split(',');
										return (
											<div className="row">
												<div class="PB_save_date_row">
													{(() => {														
														if(checkusertype===' currentuser')
														{
															return (
																<div>
																	<a onClick={this.editChat.bind(this, item._id)} className="edittask"><img src={require('../../../assets/images/edit-icon.png')} /></a>	
																	<a className="deletechat" onClick={this.removeChat.bind(this, item._id)} >X</a>	
																</div>
															)
														}
													})()}													
													<p class="InMemberPara">
														<Editor editorState={echatmessageeditorState} readOnly={true} />
														{( item.calendly_id &&
															<a className="sheduleacall anchor" data-id={item.calendly_id} onClick={this.showCalendly.bind(this)} >Schedule a Call</a>
														)}
													</p>
													
													<div>		
													{(() => {
													if(attachtypes){
													attachtypes=attachtypes.split(',');
													//console.log('attchatypes inner ==',attachtypes);
													{
														return (<div>
														{attachtypes.map((attachtype, index) => {
															return (
																<div>														
																{(() => {
															let attachfileurl='';									
															let attachtypeforurl='';										
															if(attachtype)
															{
																console.log('attachtypeattachtype beforee==',attachtype);
																attachtypeforurl=attachtype
																attachtype=attachtype.split('.');
																attachtype=attachtype[1];												
																if (userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
																	attachfileurl="../../../../images/chatfiles/"+attachtypeforurl;
																}
																else {
																	attachfileurl="../../../../../images/chatfiles/"+attachtypeforurl;
																}    
															}
															
															if (attachtype === 'png' || attachtype === 'jpeg' || attachtype === 'jpg') {
																console.log('in iff attachtypeattachtype beforee==',attachtype);
																return (
																	<div class="image projectassets">
																		<img width="100%" height="220" src={attachfileurl} /><br/>
																		<a className="forcusror downloadattach" target="_blank" href={attachfileurl} download> Download
																			</a>
																	</div>
																)
															}
															else if (attachtype === 'mp4') {									return (
																	<div class="video projectassets">
																		<video controls width="100%" height="220">
																			<source src={attachfileurl}
																				type="video/mp4" />
																			Sorry, your browser doesn't support embedded videos.
																		</video>
																		<br/>
																		<a className="forcusror downloadattach" target="_blank" href={attachfileurl} download> Download
																			</a>
																	</div>
																)
															}
															if(attachtype)
															{
																console.log('in sep attachtypeattachtype attachtype==',attachtype);
																return (
																	<div class="video projectassets">
																		<a className="forcusror" target="_blank" href={attachfileurl} download> Download
																		</a>
																	</div>
																)
															}
															
														})()}
														</div>
														)										
														})
													}</div>);

													}

													}
												})()}

												</div>					
												</div>
											</div>
										)
									//	}
								}
								else if (item.type === 'asset') {
									//let assetpath = item.filename;
									//let image = assetpath.split(".");
									//let imagename = image[0].split("_");
									//let assetpath = item.filename;
									let filetype = item.filetype;
									if (filetype === 'png' || filetype === 'jpeg' || filetype === 'jpg' || filetype === 'pdf'|| filetype === 'mp4'|| filetype === 'MP4'|| filetype === 'avi'|| filetype === 'mov'){
										filetype = filetype;
									}
									else
									{
										var checkfiletype            =item.filename.split(/\.(?=[^\.]+$)/);
										 filetype = checkfiletype[1];
									}
									let image = item.fileurl;
									let imagename = item.filename;
									let assetpath = item.assetdata;
									return <div className="row">
										{(() => {
											// console.log('item=== caledddddddddddddddddddddd',item);
											if (filetype === 'png' || filetype === 'jpeg' || filetype === 'jpg') {
												return (
													<div class="image projectassets">
														<div class="AssetsColInText">
															<p>Asset Name: {imagename} </p>
														</div>
														<img width="100%" height="220" src={assetpath} /><br/>
														<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download</a>
													</div>
												)
											}
											else if (filetype === 'pdf') {
												return (
													<div class="image projectassets">
														<div class="AssetsColInText">
															<p>Asset Name: {imagename} </p>
														</div>									
														<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download</a>
													</div>
												)
											}
											else if (filetype === 'mp4' || filetype === 'MP4' || filetype === 'mov' || filetype === 'MOV' || filetype === 'AVI') {
												return (
													<div class="video projectassets">                            <div class="AssetsColInText">
														<p>Asset Name: {imagename} </p>
													</div>
														<video controls width="100%" height="220">
															<source src={ assetpath}
																type="video/mp4" />
															Sorry, your browser doesn't support embedded videos.
															</video><br/>
															<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download
																			</a>
													</div>
												)
											}
										})()}
									</div>
								}
								else if (item.type === 'stock video') {
									//let assetpath = item.filename;
									//let image = assetpath.split(".");
									//let imagename = image[0].split("_");
									//let assetpath = item.filename;
									let filetype = item.filetype;
									let image = item.fileurl;
									let imagename = item.filename;
									let assetpath = item.assetdata;
									return <div className="row">
										{(() => {
											// console.log('item=== caledddddddddddddddddddddd',item);
											if (filetype === 'mp4' || filetype === 'MOV' || filetype === 'AVI') {
												return (
													<div class="video projectassets">                            <div class="AssetsColInText">
														<p>Stock Video Name: {imagename} </p>
													</div>
													<div>
																	<video controls width="100%" height="100%">
																	<source src={ assetpath}
																		type="video/mp4" />
																	Sorry, your browser doesn't support embedded videos.
																	</video><br/>
																	<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download</a>
																	</div>
														
															{
																( userdatatocheckusertype.capabilities && userdatatocheckusertype.capabilities.administrator &&
																	<div>
																	<video controls width="100%" height="100%">
																	<source src={ assetpath}
																		type="video/mp4" />
																	Sorry, your browser doesn't support embedded videos.
																	</video><br/>
																	<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download</a>
																	</div>
																)
															}	
															{
																(userdatatocheckusertype.capabilities && !userdatatocheckusertype.capabilities.administrator &&                               
																<video controls controlsList="nodownload" width="100%" key={assetpath} height="100%">
																	<source src={assetpath}
																			type="video/mp4"/>
																	Sorry, your browser doesn't support embedded videos.
																</video>
																)
															}														
													</div>
												)
											}
										})()}
									</div>
								}
								else if (item.type === 'stock audio') {
									//let assetpath = item.filename;
									//let image = assetpath.split(".");
									//let imagename = image[0].split("_");
									//let assetpath = item.filename;
									let filetype = item.filetype;
									let image = item.fileurl;
									let imagename = item.filename;
									let assetpath = item.assetdata;
									let posterimage='';
									try{
										posterimage=require('../../../assets/images/stockaudios/'+item.albumcoverimage+'');
									}
									catch
									{
										posterimage='';
									}
									return <div className="row">
										{(() => {
											// console.log('item=== caledddddddddddddddddddddd',item);
											if (filetype === 'mp3' || filetype === 'wav' || filetype === 'AVI') {
												return (
													<div class="video projectassets">                            <div class="AssetsColInText">
														<p>Stock Audio Name: {imagename} </p>
													</div>
													<div className="media">
														<img width="100" height="100" src={posterimage}/> 
														<div className="media-body">
															<audio controls key={assetpath}>
																<source src={assetpath} type="audio/mpeg"/>
																Your browser does not support the audio element.
															</audio>
														</div>
													</div>
														{/* <audio width="100%" controls key={assetpath}>
															<source src={assetpath} type="audio/mpeg"/>
															Your browser does not support the audio element.
														</audio> */}
														<br/>
														{
															( userdatatocheckusertype.capabilities && userdatatocheckusertype.capabilities.administrator &&
																<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download</a>
															)
														}
														
													</div>
												)
											}
										})()}
									</div>
								}
								else if (item.type === 'stock graphic') {
									//let assetpath = item.filename;
									//let image = assetpath.split(".");
									//let imagename = image[0].split("_");
									//let assetpath = item.filename;
									let filetype = item.filetype;
									let image = item.fileurl;
									let imagename = item.title;
									let assetpath = item.assetdata;
									return <div className="row">
										{(() => {
											// console.log('item=== caledddddddddddddddddddddd',item);
											if (filetype === 'mp4' || filetype === 'MOV' || filetype === 'AVI') {
												return (
													<div class="video projectassets">                            <div class="AssetsColInText">
														<p>Stock Graphic Name: {imagename} </p>
													</div>
													<div>
																<video controls width="100%" height="100%">
																<source src={ assetpath}
																	type="video/mp4" />
																Sorry, your browser doesn't support embedded videos.
																</video><br/>	
																<a className="forcusror downloadattach formarginright" target="_blank" href={item.url}> Get Template
																</a>
																<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download
																</a>
																</div>
														
															{
																( userdatatocheckusertype.capabilities && userdatatocheckusertype.capabilities.administrator &&
																<div>
																<video controls width="100%" height="100%">
																<source src={ assetpath}
																	type="video/mp4" />
																Sorry, your browser doesn't support embedded videos.
																</video><br/>	
																<a className="forcusror downloadattach formarginright" target="_blank" href={item.url}> Get Template
																</a>
																<a className="forcusror downloadattach" target="_blank" href={assetpath} download> Download
																</a>
																</div>
																)
															}
															{
																(userdatatocheckusertype.capabilities && !userdatatocheckusertype.capabilities.administrator &&                               
																<video controls controlsList="nodownload" width="100%" key={assetpath} height="100%" >
																	<source src={assetpath}
																			type="video/mp4"/>
																	Sorry, your browser doesn't support embedded videos.
																</video>
																)
															}
															
													</div>
												)
											}
										})()}
									</div>
								}
								else if (item.type === 'Videography Request') {
									return (
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Videographer Request</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Location:</strong> {item.location}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Purpose:</strong> {item.purpose}&nbsp;&nbsp;|&nbsp;&nbsp;
													{( item.arrive && item.arrive!='undefined' &&
													<><strong>Arrive:</strong> {dateFormat(item.arrive, "mmmm dS, yyyy h:MM:ss TT")}&nbsp;&nbsp;|&nbsp;&nbsp;
													</>
													)}
													{( item.depart && item.depart!='undefined' &&
														<>
														<strong>Depart:</strong> {dateFormat(item.depart, "mmmm dS, yyyy h:MM:ss TT")}
														</>
													)}
													{this.state.showItems[item._id] ? null :
														<><br/>
														<a className="forcusror" onClick={this.showMore.bind(this, item._id)}>Show More</a> &nbsp;&nbsp;|&nbsp;&nbsp;
														</>
													}
													{this.state.showItems[item._id] ? <> 
													 <strong>Notes:</strong> {item.comment}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Onsite Contact:</strong> {item.onsitecontact}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Mobile Number:</strong> {item.mobilenumber}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Email Address:</strong> {item.emailaddress}<br/>
													<a className="forcusror" onClick={this.hide.bind(this, item._id)}>Hide</a> &nbsp;&nbsp;|&nbsp;&nbsp;
													</> : null}
													<a className="forcusror" onClick={this.reply.bind(this, item)}>Reply</a>
												</p>
											</div>
										</div>
									)
								}
								else if (item.type === 'Reply Videography Request') {
									return (
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Travel Authorization Request</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Videographer:</strong> {item.videographername}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Email Address:</strong> {item.vg_emailaddress}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Primary Transportation:</strong> {item.primarytransportation}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Scheduled Arrive:</strong> {dateFormat(item.schedarrivalDate, "mmmm dS, yyyy h:MM:ss TT")}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Scheduled Depart:</strong> {dateFormat(item.scheddepartureDate, "mmmm dS, yyyy h:MM:ss TT")}
													{this.state.showItems[item._id] ? null :
														<><br/>
														<a className="forcusror" onClick={this.showMore.bind(this, item._id)}>Show More</a>&nbsp;&nbsp;|&nbsp;&nbsp;
													    </>
													}
													{this.state.showItems[item._id] ? <div> 
													 <strong>Travel required?:</strong> {item.travelrequired}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Hotel Night(s):</strong> {item.hotelnight}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Flight Expenses:</strong> {item.flightexpense}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Vehicle Name:</strong> {item.vehiclename}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Estimated Miles:</strong> {item.numofmile}&nbsp;&nbsp;|&nbsp;&nbsp;
													 <strong>Travel Expenses:</strong> {item.travelexpenses}&nbsp;&nbsp;|&nbsp;&nbsp;
													 
													<a className="forcusror" onClick={this.hide.bind(this, item._id)}>Hide</a>
													</div> : null}
													<a className="forcusror" onClick={this.replyTravelAuthorization.bind(this, item)}>Reply</a>
												</p>
											</div>
										</div>
									)
								}
								else if (item.type === 'Reply Travel AuthorizationForm') {
									return (
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Travel Authorization Reply</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Perdiem:</strong> {item.perdiem}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Charge Type:</strong> {item.chargetype}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Accommodation:</strong> {item.accommodation}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Hotel Name:</strong> {item.hotelname}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Daily Budget:</strong> {item.dailybudget}
												</p>
											</div>
										</div>
									)
								}
								else if(!item.ishide) {
									return (
										<div className="row">
											<div class="ProjectRepeatTbl">
												<p>
													<strong>Location:</strong> {item.location}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Purpose:</strong> {item.purpose}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Arrive:</strong> {dateFormat(item.arrive, "mmmm dS, yyyy")}&nbsp;&nbsp;|&nbsp;&nbsp;
													<strong>Depart:</strong> {dateFormat(item.depart, "mmmm dS, yyyy")}
												</p>
											</div>
										</div>
									)
								}
							})()}
						</div>
					</div>
				</li>
			})
		}
        return (
        	<div>
				<ul className="page-description container">
					{pboard}
				</ul>
				{
					this.state.Inloading ?
						<div className="loadercenter">
							<Loader
								type="ThreeDots"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
						: null
				}
				<Modal show={handleConfirmMsgShow}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<label>Great, we'll send this file to Vimeo for the embed code and additional back-up.</label>
								<label>Would you also like to close this project?</label>
								<div className="checkbox">
									<label >
										<input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheckBox.bind(this)} />
										Yes, this project is completed.
									</label>
									<label >
										<input type="checkbox" checked={this.state.isAddedtoPoprtfolio} onChange={this.handlePortfolioCheckBox.bind(this)} />
										Share this video with other organizations in the portfolio?
									</label>
									<div className="errorMsg">{this.state.markascompleteerror}</div>
								</div>										
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleConfirmMsgBoxClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={(e) => this.markAsComplete(e)}>
							Ok
						</Button>
						<Button variant="primary" onClick={this.handleConfirmMsgBoxClose.bind(this)}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={handleDraftComment}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<div className="checkbox">
									<label >
										Time:{this.state.drafttime}
									</label>
									<input className="LP_field draftcomment" rows="4" name="draftcomment" value={this.state.draftcomment} onChange={this.draftCommentChange.bind(this)} type="textarea" placeholder="Draft Comment" />
									<div className="errorMsg">{this.state.draftcommenterror}</div>
								</div>										
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleDraftCommentClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={(e) => this.saveDraftComment(e)}>Save
						</Button>								
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.setEditVideoBox}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime taskduedate">
						<div className="row">
							<div className="col-12" style={{display:"block"}}>
								<div className="succes-msg">{this.state.taskboardsuccessmessage}</div>
								<label>Comment</label>								
								<textarea style={{display:"block",width:"100%"}} className="LP_field"  onChange={this.handleTaskCommentChange.bind(this)} name="taskcomment"  >
								</textarea>
																				
							</div>
							<div className="col-12">
								<label>Select Due Date</label>								
								<h4>
									<DatePicker className="form-control" 
									    selected={this.state.tduedate}
										name="tduedate"
										minDate={moment().toDate()}
										onChange={date => this.handleTaskDueDateChange('tduedate', date)}
										dateFormat="d MMMM"
										customInput={<CustomButton />} />
								</h4>														
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.closeEditVideoBox.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={(e) => this.editVideo(e)}>
							Send
						</Button>
						<Button variant="primary" onClick={this.closeEditVideoBox.bind(this)}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>
				{ this.state.setReviewProject && this.state.taskstatustype &&
					<ApproveReviewTask saveMaybeOption={this.saveMaybeOption} taskstatustype={this.state.taskstatustype} taskid={this.state.selectedTaskId} loadProjectBoard={this.props.loadProjectBoard} orgid={this.props.orgid} prjid= {this.props.prjid} user={userdatatocheckusertype} handleAddReviewProject={this.state.setReviewProject} isAddReviewProject={this.state.isAddReviewProject} />
				}
			</div>
		);
    }
}
export default ProjectBoard;