import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import {GetData} from '../../services/PostData';
import ReactTable from "react-table";
import "react-table/react-table.css";  
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
class ProjectList extends Component {
    constructor(props) {
        let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        super(props);
        this.state = {
            filtered: [],
            loading:false,
            data:this.props.data,
        }
    }
    componentDidMount() {
        //this.setState({loading:true, });	
        let data = {
            'userid': this.props.userid,
        }
        
    }    
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
                if (value === "" || !value.length) filtered.splice(i, 1);
                else filter["value"] = value;
                insertNewFilter = 0;
            }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        this.setState({ filtered: filtered });
    };
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    render() {
            const columns = [
                {
                    Header: 'ID',
                    accessor: '_id',
                    show:false
                },
                {
                    Header: 'OrgID',
                    accessor: 'org_id',
                    show:false
                },
                {
                    Header: 'Project',
                    accessor: 'project_name',
                    Cell: ({ row }) => (<Link target="_blank"  to={{ pathname: `/org/${row.org_id}/project/view/${row._id}` }}>{row.project_name}</Link>),
                    width: 200
                },   
                {
                    Header: 'Project Description',
                    accessor: 'description',
                    Cell: ({ row }) =>{ 
                        //console.log('row=====',row);
                        let description='';
                        if(row.description){
                            if (this.isJson(row.description))
                            {
                                const contentState = convertFromRaw(JSON.parse(row.description));
                                const editorState = EditorState.createWithContent(contentState);
                                const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
                                //console.log('block.text====',blocks);
                                description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
                            }
                            else
                            {
                                if(row.description==='[object Object]')
                                description= '';
                            }
                        }
                        return(<p>{description}</p>)
                    }
                }, 
                {
                  Header: 'Organization Name',
                  accessor: 'orgname',
                  width: 200
                },
                // {
                //     Header: 'Project Hours',
                //     accessor: 'totaladded_time',
                //     //show:false
                // }, 
                // {
                //     Header: 'Project Status',
                //     accessor: 'status',                    
                // },     
                {
                    Header: 'Submitted Date',
                    accessor: 'create_date',  
                    Cell: ({ row }) =>{ 
                        return(<p>{dateFormat(row.create_date, "mm/dd/yyyy")}</p>)
                    },
                    width: 150                  
                },  
                {
                    Header: 'Due Date',
                    accessor: 'due_date',
                    Cell: ({ row }) =>{ 
                        return(<p>{dateFormat(row.due_date, "mm/dd/yyyy")}</p>)
                    },
                    width: 150
                },
            ];            
            const data=this.props.data;
            return (
			 <div className="container-fluid TaskListPage"  ref="selfdiv">
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	}
                <div className="panel panel-primary">                  
                    <div className="panel-body">    
                        {   
                            (data.length>0) &&                
                            <ReactTable
                                data={data}
                                filtered={this.state.filtered}
                                minRows = {0}
                                pageSizeOptions = {[10,50,100,500,1000,2000]}
                                defaultPageSize = {10}                             
                                onFilteredChange={(filtered, column, value) => {
                                    this.onFilteredChangeCustom(value, column.id || column.accessor);
                                }}
                                defaultFilterMethod={(filter, row, column) => {
                                    const id = filter.pivotId || filter.id;
                                    if (typeof filter.value === "object") {
                                    return row[id] !== undefined
                                        ? filter.value.indexOf(row[id]) > -1
                                        : true;
                                    } else {
                                    return row[id] !== undefined
                                        ? String(row[id]).indexOf(filter.value) > -1
                                        : true;
                                    }
                                }}
                                columns={columns}
                                className="-striped -highlight"
                                defaultSorted={[
                                    {
                                      id: "create_date",
                                      desc: true
                                    }
                                  ]}
                            />                            
                        }                    
                    </div>
                </div>
            </div>
		);
    }
}
export default ProjectList;
