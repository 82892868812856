import React, { Component } from "react";
import {PostData, GetData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import dateFormat from 'dateformat';
import CheckoutReplyTravelAuthorizationForm from './CheckoutReplyTravelAuthorizationForm';
import { Client } from '@freshbooks/api';
import { StripeProvider, Elements } from 'react-stripe-elements'

const ACCOUNT_ID = '<your account id>'


class ReplyTravelAuthorizationForm extends Component {

    constructor(props){
		super(props);
		this.state = {
			accommodation:'',
			hotelname:'',
			dailybudget:'',
			fields: {},
			errors: {},	
			setReplyTravelAuthorizationShow:this.props.handleReplyTravelAuthorizationShow,
			tokenData:'',
			accountid:'',
			fbClient:{}
		};
		this.onRadioChange= this.onRadioChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onRadioChargeTypeChange= this.onRadioChargeTypeChange.bind(this);
		console.log('replyTravelAuthorizationdata==',this.props.replyTravelAuthorizationdata);
	}
	
	onRadioChargeTypeChange(e){
		this.setState({[e.target.name]:e.target.value});
		console.log('check valeeeeeeeee===',e.target.value);
	}
	onRadioChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	handleArrivalChange(date) {  
	    this.setState({  
	      arrivalDate: date  
	    })  
	}
	handleDepartureChange(date) {  
	    this.setState({  
	      departureDate: date  
	    })  
	}
	async componentDidMount() {		
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let name=userdata.user.first_name+" "+userdata.user.last_name;
		let email=userdata.user.email
		let fbtokendata = await this.getFBRefreshToken();
		console.log('fbtokendata=',fbtokendata);
		//const clientId = '6040b7b1c1319a062a52c67ebd07b2fa45055fb28436c263a0e087bed3e044ae'; //local
		//const clientSecret = 'd2d8087d22550415b9ba58c520e9592eddbc7d9e6f88202ded5bd096530a4f38';//local
		const clientId = '7753dc0bda63ceced9768da4cb1e515e85dc1402519b9b151a51ec3840dde238'; //live
		const clientSecret = 'ef028fb38c88258dbdaed4dba1513713cefbb3a6bb4ede4f49a0541f4171c112';//live
		const freshBooksClient = new Client(clientId, {
	        clientSecret,
	        redirectUri: 'https://associationstudios.com/'
	    })
	   // const clientdata = await freshBooksClient.users.me();
  		//console.log('clientdata=',clientdata);
	    const tokenData = await freshBooksClient.refreshAccessToken(fbtokendata.data.refreshtoken);
	    console.log('refreshedTokenData=',tokenData);
	    if(tokenData){
		    let fbClient = new Client(clientId, { accessToken: tokenData.accessToken });
			const { data } = await fbClient.users.me();
		    console.log('tokenData.data=',data);
		    let accountid = data.businessMemberships[0].business.accountId;
		    console.log('accountid=',accountid);
		    this.setState({
			  accountid: accountid,
			  tokenData:tokenData,
			  fbClient:fbClient
		    }) 
		}
		await this.updateFBRefreshToken(tokenData)
		this.setState({
		  videographer: name,
	      vg_emailaddress: email,
	      
	    }) 
	}
	componentDidUpdate(prevProps) {
		if (prevProps.replyTravelAuthorizationTime !== this.props.replyTravelAuthorizationTime) {
			this.setState({setReplyTravelAuthorizationShow:this.props.replyTravelAuthorizationTime})		
		}
	}	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	
	handleReplyVideographyClose() {
		console.log('handleReplyVideographyClose in reply=='); 
		this.setState({ setReplyTravelAuthorizationShow: false });
	}
	async saveReplyTravelAuthorizationForm(e) {
		if (this.validateSaveReplyTravelAuthorizationForm()) {
			//let selectedTP = this.state.selectedTP.toString();
			/*
			this.setState({ Inloading: true });

			let fbtokendata = await this.getFBRefreshToken();

			console.log('fbtokendata=',fbtokendata);
			const clientId = '7be6238707f550b827593f50ecbb8fae424b5e60b0d838800e59c71e172a3537';
   			const clientSecret = '247e51cf369a7de7442745e00fcfa03b9fb8070159c8a686cd49700e49c98d5c';
			const freshBooksClient = new Client(clientId, {
		        clientSecret,
		        redirectUri: 'https://associationstudios.com/'
		    })
		    const tokenData = await freshBooksClient.refreshAccessToken(fbtokendata.data.refreshtoken);
		    console.log('refreshedTokenData=',tokenData);

		    await this.updateFBRefreshToken(tokenData);*/

		    //const clientId = await this.createClient();
		   // console.log('after clientId=',clientId);
			//let invoice = await this.createInvoice(clientId)
			// Invoices are created in draft status, so we need to mark it as sent
			//invoice = await this.markInvoiceSent(invoice)

		   // console.log('after invoice=',invoice);

			let data = {
				'orgid': this.props.orgid,
				'projectid': this.props.prjid,

				'location': this.props.replyTravelAuthorizationdata.location,
				'purpose': this.props.replyTravelAuthorizationdata.purpose,
				'arrivalDate': this.props.replyTravelAuthorizationdata.arrive,
				'departureDate': this.props.replyTravelAuthorizationdata.depart,
				'notes': this.props.replyTravelAuthorizationdata.comment,
				'onsitecontact':this.props.replyTravelAuthorizationdata.onsitecontact,
				'onsitemobilenumber':this.props.replyTravelAuthorizationdata.mobilenumber,
				'onsiteemailaddress':this.props.replyTravelAuthorizationdata.emailaddress,
				'travelrequired':this.props.replyTravelAuthorizationdata.travelrequired,
				'hotelnight':this.props.replyTravelAuthorizationdata.hotelnight,
				'primarytransportation':this.props.replyTravelAuthorizationdata.primarytransportation,
				'schedarrivalDate':this.props.replyTravelAuthorizationdata.schedarrivalDate,
				'scheddepartureDate':this.props.replyTravelAuthorizationdata.scheddepartureDate,

				'videographername':this.props.replyTravelAuthorizationdata.videographername,
				'vg_emailaddress':this.props.replyTravelAuthorizationdata.vg_emailaddress,

				'perdiem': this.state.perdiem,
				'chargetype': this.state.chargetype,
				'userid':this.props.userid,
				'customerid':this.props.replyTravelAuthorizationdata.userid,
				'hotelname':this.state.hotelname,
				'dailybudget':this.state.dailybudget,
				'accommodation':this.state.accommodation,
				'type':'Reply Travel AuthorizationForm',
				'flightexpense':this.props.replyTravelAuthorizationdata.flightexpense,
				'driveexpense':this.props.replyTravelAuthorizationdata.driveexpense,
				'travelexpenses':this.props.replyTravelAuthorizationdata.travelexpenses,
				'trainexpense':this.props.replyTravelAuthorizationdata.trainexpense,
			}
			console.log('data',data);
			


			//this.postReplyTravelAuthorizationForm(data);
		}
	}
	updateFBRefreshToken(tokenData){
		const refreshtoken = tokenData.refreshToken;
		PostData('updatefbrefreshtoken', {refresh_token:refreshtoken}).then((result) => {
			console.log('refresh token result=',result);
			if (result.statuscode === 200) {				
				console.log('refresh token updated');				
			}
		});
	}

	async getFBRefreshToken(){
		let resultdata;
		await GetData('getfbrefreshtoken').then((result) => {
			console.log('refresh token result=',result);
			if (result.statuscode === 200) {				
				console.log('refresh token updated');
				resultdata= result;		
			}
		});
		return resultdata
	}

	
	validateSaveReplyTravelAuthorizationForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["videographer"]) {
			formIsValid = false;
			errors["videographer"] = "*Please add Videographer Name";
		}
		  
		if (typeof fields["vg_emailaddress"] !== "undefined") {
			//regular expression for email validation
			var email = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; 
			if (!email.test(fields["vg_emailaddress"])) {
				formIsValid = false;
				errors["vg_emailaddress"] = "*Please enter valid Email Address.";
			}
		} 
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	postReplyTravelAuthorizationForm(data)
	{
		this.setState({ Inloading: true });
		PostData('replytravelauthorizationformstg', data).then((result) => {
			if (result.statuscode === 200) {				
				this.setState({ savevideographsucsmsg: "Travel Authorization Reply Added Succesfully",hotelname:'',dailybudget:'', selectedTP: "", flightexpense:'',vehiclename:'',mile:'',travelexpenses:'',emailaddress:'' });
				setTimeout(() => {
					this.setState({  savevideographsucsmsg: '',setReplyTravelAuthorizationShow: false });
					this.props.loadProjectBoard();
				}, 3000);
			}
			this.setState({ Inloading: false });
		});
	}
	loadProjectBoard() {
		this.props.loadProjectBoard();
	}
	handlePerDiemChange(e) {
		this.setState({ perdiem: e.target.value });
	}
    render() {

		return (
			<StripeProvider apiKey="pk_test_Re8OQCVZDbFxcrGV9KlVoHSn">
			<Elements>
				<CheckoutReplyTravelAuthorizationForm orgname={this.props.orgname} freshbook_profile_id={this.props.freshbook_profile_id} fbClient={this.state.fbClient} accountid={this.state.accountid} tokenData={this.state.tokenData} handleReplyVideographyClose={this.handleReplyVideographyClose.bind(this)} loadProjectBoard={this.loadProjectBoard.bind(this)} userid={this.props.userid} orgid={this.props.orgid} prjid={this.props.prjid} setReplyTravelAuthorizationShow={this.state.setReplyTravelAuthorizationShow} replyTravelAuthorizationdata={this.props.replyTravelAuthorizationdata} />
			</Elements>
			</StripeProvider>
        	

		);
    }
}
export default ReplyTravelAuthorizationForm;