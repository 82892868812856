import React, {Component} from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Home from './pages/Home';
import Vpma from './pages/Vpma';
import LoginLayoutRoute from "./layouts/LoginLayout";  
import InviteeLoginLayoutRoute from "./layouts/InviteeLoginLayout";  
import DashboardLayoutRoute from "./layouts/DashboardLayout";  
import DashboardProfileLayoutRoute from "./layouts/DashboardProfileLayout";
import InnerDashboardLayoutRoute from "./layouts/InnerDashboardLayout"; 
import LandingPageLayoutRoute from "./layouts/LandingPageLayout"; 
import SubsDashboardLayoutRoute from "./layouts/SubsDashboardLayout"; 
import AddOrganization from './pages/organization/AddOrganization';
import AddOrganizationstg from './pages/organizationstg/AddOrganization'  
import Organizations from './pages/organization/Organizations' 
import EditOrganization from './pages/organization/EditOrganization'
import ViewOrganization from './pages/organization/ViewOrganization'
import Projects from './pages/Projects' 
import AddProject from './pages/project/AddProject'
import OrgProjects from './pages/project/Projects' 
import ViewProject from './pages/project/ViewProject'
import Tasks from './pages/Tasks'
import Timelogs from './pages/Timelogs'
import Inbox from './pages/Inbox'
import SubsInbox from './pages/SubsInbox'
import UploadAssets from './pages/uploadassets/UploadAssets'
import Assets from './pages/uploadassets/Assets'
import AssetsStg from './pages/uploadassetsstg/AssetsStg'
import AddTeam from './pages/productionteams/AddTeam'   
import ProjectList from './pages/project/OrgProjects'; 
import SubProjectList from './pages/project/SubOrgProjects';
import Stocks from './pages/stockgraphics/Stocks';
import DraftPage from './pages/DraftPage'
import Support from './pages/Support'
import Calendar from './pages/Calendars'
import ResetPassword from './pages/ResetPassword'
import Profile from './pages/Profile'
import Reports from './pages/Reports'
import CreateFolder from './pages/uploadassets/CreateFolder'
import EditFile from './pages/uploadassets/EditFile'
import UploadFile from './pages/uploadassets/UploadFile'
import BroadCast from './pages/BroadCast'
import VoiceOvers from './pages/VoiceOvers'
import StockVideos from './pages/stockvideos/StockVideos';
import StockAudios from './pages/stockaudios/StockAudios';
import AddVideoURL from './pages/AddVideoURL';
import RecordVideo from './pages/RecordVideo';
import InviteeLogin from './pages/InviteeLogin';
import InviteeDashboardLayoutRoute from "./layouts/InviteeDashboardLayout"; 
import InviteeAssets from './pages/uploadassets/InviteeAssets';
import InviteeAddVideoURL from './pages/InviteeAddVideoURL';
import InviteeEditFile from './pages/uploadassets/InviteeEditFile'
import InviteeRecordVideo from './pages/InviteeRecordVideo'
import ClosedCaptioning from './pages/ClosedCaptioning'
import ClosedCaptioningstg from './pages/ClosedCaptioningstg'
import DownloadVideo from './pages/DownloadVideo';
import ViewProjectstg from './pages/project/ViewProjectstg'
import StockVideosstg from './pages/stockvideos/StockVideosstg';
import ProjectListstg from './pages/project/OrgProjectsstg'; 
import Portfolio from './pages/portfolio/Portfolio';
import OrderVideos from './pages/OrderVideos'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ListTeam from './pages/admins/ListTeam';
import EditTeam from './pages/admins/EditTeam'; 
import ViewTeam from './pages/admins/ViewTeam'; 
import LoginLayoutRouteStg from "./layouts/LoginLayoutStg";  
import Loginstg from './pages/Loginstg';
import ProfitabilityMeasurement from './pages/ProfitabilityMeasurement' 
import Survey from './pages/Survey';
import ScriptWriter from './pages/ScriptWriter';
import ChatBoard from './pages/ChatBoard';
import Messaging from './pages/Messaging';
import StyleDesign from './pages/StyleDesign'
import FreshbookInvoice from './pages/FreshbookInvoice'

class App extends Component {
  render() {
    return (    
        <BrowserRouter>
          <ToastContainer />
          <Switch>
            <Route path='/logout' component={Logout} /> 
            <LoginLayoutRoute exact path='/loginold' component={Login} /> 
            <LoginLayoutRouteStg exact path='/' component={Loginstg} /> 
            <LoginLayoutRoute path="/resetpassword" component={ResetPassword} /> 
            <DashboardLayoutRoute path="/organization/create" component={AddOrganization} /> 
            <DashboardLayoutRoute path="/organizationstg/create" component={AddOrganizationstg} /> 
            <DashboardLayoutRoute path="/organization/list" component={Organizations} />
            <DashboardLayoutRoute path="/organization/edit/:id" component={EditOrganization} />
            <DashboardLayoutRoute path="/organization/view/:id" component={ViewOrganization} />
            <DashboardLayoutRoute path="/projects/list" component={Projects} />
            <InnerDashboardLayoutRoute path="/org/:orgid/project/create" component={AddProject} />
            <InnerDashboardLayoutRoute path="/org/:orgid/project/list" component={OrgProjects} />
            <InnerDashboardLayoutRoute path="/org/:orgid/project/view/:prjid" component={ViewProject} />
            <InnerDashboardLayoutRoute path="/orgstg/:orgid/project/view/:prjid" component={ViewProjectstg} />
            <InnerDashboardLayoutRoute path="/org/:orgid/task/list" component={Tasks} />
            <InnerDashboardLayoutRoute path="/org/:orgid/inbox/list" component={Inbox} />
            <InnerDashboardLayoutRoute path="/:orgid/timelogs" component={Timelogs} />
            <InnerDashboardLayoutRoute path="/:orgid/calendar" component={Calendar} />
            <InnerDashboardLayoutRoute path="/:orgid/upload-assets" component={UploadAssets} />
            <InnerDashboardLayoutRoute path="/:orgid/assets" component={Assets} />
            <InnerDashboardLayoutRoute path="/:orgid/:folderid/createfolder" component={CreateFolder} />
            <InnerDashboardLayoutRoute path="/:orgid/:folderid/uploadfile" component={UploadFile} />
            <InnerDashboardLayoutRoute path="/:orgid/:folderid/editfile" component={EditFile} />
            <InnerDashboardLayoutRoute path="/:orgid/stocks" component={Stocks} />
            <InnerDashboardLayoutRoute path="/:orgid/stockvideos" component={StockVideos} />
            <InnerDashboardLayoutRoute path="/:orgid/portfolio" component={Portfolio} />
            <InnerDashboardLayoutRoute path="/:orgid/stockvideosstg" component={StockVideosstg} />
            <InnerDashboardLayoutRoute path="/:orgid/stockmusic" component={StockAudios} />
            <InnerDashboardLayoutRoute path="/org/:orgid/projects" component={ProjectList} />
            <InnerDashboardLayoutRoute path="/org/:orgid/projectsstg" component={ProjectListstg} />
            <InnerDashboardLayoutRoute path="/org/:orgid/team/create" component={AddTeam} />
            <InnerDashboardLayoutRoute path="/org/:orgid/broadcast" component={BroadCast} />
            <InnerDashboardLayoutRoute path="/org/:orgid/voiceovers" component={VoiceOvers} />
            <InnerDashboardLayoutRoute path="/org/:orgid/messaging" component={Messaging} />
            <InnerDashboardLayoutRoute path="/org/:orgid/addvideourl" component={AddVideoURL} />
            <InnerDashboardLayoutRoute path="/:orgid/:folderid/createlink" component={AddVideoURL} />
            <InnerDashboardLayoutRoute path="/:orgid/closedcaptioning" component={ClosedCaptioning} />
            <InnerDashboardLayoutRoute path="/:orgid/styleguide" component={StyleDesign} />
            <SubsDashboardLayoutRoute path="/subs/:orgid/styleguide" component={StyleDesign} />
            <InnerDashboardLayoutRoute path="/:orgid/closedcaptioningstg" component={ClosedCaptioningstg} />   
            <InnerDashboardLayoutRoute path="/:orgid/order-videos" component={OrderVideos} />   
            <InnerDashboardLayoutRoute path="/org/:orgid/scriptwriter" component={ScriptWriter} />
            <InnerDashboardLayoutRoute path="/:orgid/chatboard" component={ChatBoard} />
            <LandingPageLayoutRoute path="/:orgid/:folderid/createvideo/:videoid" component={RecordVideo} />
            <LandingPageLayoutRoute path="/draft/:draftid" component={DraftPage} />
            <LandingPageLayoutRoute path="/support" component={Support} />
            <DashboardLayoutRoute path="/home" component={Home} /> 
            <DashboardLayoutRoute path="/vpma" component={Vpma} /> 
            <DashboardProfileLayoutRoute path="/profile" component={Profile} />
            <DashboardProfileLayoutRoute path="/calendar" component={Calendar} />
            <DashboardProfileLayoutRoute path="/inbox/list" component={Inbox} />
            <DashboardProfileLayoutRoute path="/task/list" component={Tasks} />
            <DashboardProfileLayoutRoute path="/reports" component={Reports} />
            <DashboardProfileLayoutRoute path="/profitability-measurement" component={ProfitabilityMeasurement} />
            <SubsDashboardLayoutRoute path="/subs/org/:orgid/project/create" component={AddProject} />
            <SubsDashboardLayoutRoute path="/subs/org/:orgid/project/list" component={SubProjectList} />
            <SubsDashboardLayoutRoute path="/subs/org/:orgid/project/view/:prjid" component={ViewProject} />
            <SubsDashboardLayoutRoute path="/subs/orgstg/:orgid/project/view/:prjid" component={ViewProjectstg} />
            <SubsDashboardLayoutRoute path="/subs/:orgid/upload-assets" component={UploadAssets} />
            <SubsDashboardLayoutRoute path="/subs/:orgid/assets" component={Assets} />
            <SubsDashboardLayoutRoute path="/subs/:orgid/stocks" component={Stocks} />
            <SubsDashboardLayoutRoute path="/sub/org/:orgid/inbox/list" component={SubsInbox} />   
            <SubsDashboardLayoutRoute path="/subs/:orgid/:folderid/editfile" component={EditFile} />  
            <SubsDashboardLayoutRoute path="/subs/:orgid/:folderid/createfolder" component={CreateFolder} />   
            <SubsDashboardLayoutRoute path="/subs/:orgid/stockvideos" component={StockVideos} />  
            <SubsDashboardLayoutRoute path="/subs/:orgid/stockmusic" component={StockAudios} />
            <SubsDashboardLayoutRoute path="/subs/:orgid/closedcaptioning" component={ClosedCaptioning} />     
            <SubsDashboardLayoutRoute path="/subs/:orgid/portfolio" component={Portfolio} />  
            <SubsDashboardLayoutRoute path="/subs/:orgid/order-videos" component={OrderVideos} />  
            <InviteeLoginLayoutRoute path='/:orgid/:folderid/sharefolder' component={InviteeLogin} />  
            <InviteeDashboardLayoutRoute path="/invitee/:orgid/assets" component={InviteeAssets} />    
            <InviteeDashboardLayoutRoute path="/invitee/:orgid/:folderid/createlink" component={InviteeAddVideoURL} /> 
            <InviteeDashboardLayoutRoute path="/invitee/:orgid/:folderid/editfile" component={InviteeEditFile} /> 
            <LandingPageLayoutRoute path="/invitee/:orgid/:folderid/createvideo/:videoid" component={InviteeRecordVideo} />     
            <LandingPageLayoutRoute path="/subs/:orgid/:folderid/createvideo/:videoid" component={RecordVideo} />
            <LandingPageLayoutRoute path="/downloadvideo/:draftid" component={DownloadVideo} />
            <LandingPageLayoutRoute path="/survey/:prjid/:userid" component={Survey} />
            <DashboardLayoutRoute path="/productionteam/create" component={AddOrganization} /> 
            <DashboardProfileLayoutRoute path="/productionteam/list" component={ListTeam} />
            <DashboardLayoutRoute path="/productionteam/edit/:id" component={EditTeam} />
            <DashboardProfileLayoutRoute path="/productionteam/view/:id" component={ViewTeam} />
            <InnerDashboardLayoutRoute path="/:orgid/assetsstg" component={AssetsStg} />
            <InnerDashboardLayoutRoute path="/org/:orgid/invoices" component={FreshbookInvoice} />
            <SubsDashboardLayoutRoute path="/subs/org/:orgid/invoices" component={FreshbookInvoice} />
          </Switch>
        </BrowserRouter>
    );
  }
}
export default App;
