import {apiConfig} from "../config/api"
let BaseURL = apiConfig.WEB_SERVICE_URL;
//let BaseURL = 'http://localhost:8080/api/';
//console.log('hostname = ',process.env.DB_HOST);
//console.log('BaseURL===',BaseURL,process.env.REACT_APP_API);
let session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
//console.log('session_key===',session_key);
export function PostData(type, data) {
 return new Promise((resolve, reject) =>{
  if(session_key===null)
  session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
   fetch(BaseURL+type, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
          'Content-Type': 'application/json',
          'session-key': session_key,
      }
    })
    .then((response) => response.json())
    .then((res) => {
     resolve(res);
    })
    .catch((error) => {
     reject(error);
    });
  });
}

export function GetData(type) {
 return new Promise((resolve, reject) =>{
   if(session_key===null)
    session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
   fetch(BaseURL+type, {
    method: 'GET',
    headers: {
          'session-key': session_key, 
          'Content-Type': 'application/json',      
      }
    })
    .then((response) => response.json())
    .then((res) => {
     resolve(res);
    })
    .catch((error) => {
     reject(error);
    });
  });
}
export function PutData(type,data) {
  return new Promise((resolve, reject) =>{
   fetch(BaseURL+type, {
    method: 'put',
    body: JSON.stringify(data),
    headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          'session-key': session_key,           
      }
    })
    .then((response) => response.json())
    .then((res) => {
     resolve(res);
    })
    .catch((error) => {
     reject(error);
    });
  });
}
export function DeleteData(type) {
 //console.log('type==',type);
  return new Promise((resolve, reject) =>{
   fetch(BaseURL+type, {
    method: 'DELETE',
    //body: JSON.stringify(data),
    //mode: 'CORS',
    headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',    
          'session-key': session_key,       
      }
    })
    .then((response) => response.json())
    .then((res) => {
     resolve(res);
    })
    .catch((error) => {
     reject(error);
    });
  });
}

