import React, { Component } from "react";
import { render } from "react-dom";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../services/PostData';
import DataTable from 'react-data-table-component';
import Select from "react-select";
import ReactTable from "react-table";
import "react-table/react-table.css";  
class Tasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            taskList: [],
            originalTaskList: [],
            taskteammembersucsmsg:'',
            filtered: [],
            select1: undefined,
            select2: undefined,
            isClearable: true,
        };
        this.updateInputValue = this.updateInputValue.bind(this);
    }
    componentDidMount() {
        Promise.all([this.getOrgTaskList()])
		.then(([orgtaskdata, producers])  => {
            let responseJson = orgtaskdata;
            this.setState({
                taskList: responseJson.data,
                originalTaskList: responseJson.data,
            });
        });
    }
    getOrgTaskList(){
        return GetData('orgtasklist/'+this.props.match.params.orgid).then((result) => {  
			return result;
		});
    }
    updateInputValue(event) {
        this.setState({ itemPerPage: 2 });
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalTaskList.length; i++) {
                    let assignment=this.state.originalTaskList[i].assignment;
                    if(assignment!=null)
                    {
                        if (assignment.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalTaskList[i]);
                        }
                    }
                }
                this.setState({
                    taskList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    taskList: this.state.originalTaskList, 
                });
            }
       });
    }
    onFilteredChangeCustom = (value, accessor) => {
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {
            if (filter["id"] === accessor) {
                if (value === "" || !value.length) filtered.splice(i, 1);
                else filter["value"] = value;
                insertNewFilter = 0;
            }
            });
        }

        if (insertNewFilter) {
            filtered.push({ id: accessor, value: value });
        }
        console.log('filtered===',filtered);
        this.setState({ filtered: filtered });
    };
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    render() {
        const columns = [
            {
                Header: 'ID',
                accessor: 'project_id',
                show:false
            },
            {
                Header: 'Task Name',
                accessor: 'assignment',
                Cell: ({ row }) => (<Link to={{ pathname: `/org/${this.props.match.params.orgid}/project/view/${row.project_id}` }}>{row.assignment}</Link>)
            },
            {
                Header: 'Assigned Member',
                accessor: 'teammember',
             
            },
            {
                Header: 'Task Status',
                accessor: 'status',
              
            },
            {
                Header: 'Due date',
                accessor: 'due_date',
                
            },
          ];
          const data=this.state.taskList;
          const isClearable= this.state.isClearable;
          const options = [
            { value: 'chocolate', label: 'Chocolate' },
            { value: 'strawberry', label: 'Strawberry' },
            { value: 'vanilla', label: 'Vanilla' }
          ]
		    return (
			 <div className="container-fluid TaskListPage"  ref="selfdiv">
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-md-7 col-lg-7">
                                <h4 class="PageTopTitle">Task List</h4>
                            </div>
                            <div className="col-md-2 col-lg-2"></div>
                            <div className="col-md-3 col-lg-3">
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                       <div className="row">
                           <div className="col-6">
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={isClearable}
                                    options={this.uniqueOptions(this.state.taskList, "teammember").map((teammember, i) => {
                                    return { id: i, value: teammember, label: teammember };
                                    
                                    })}
                                    multi={true}
                                    onChange={entry => {
                                        if(!entry)
                                        {
                                            entry={id: '', value: '', label: ''};
                                        }
                                        console.log('entry==',entry);
                                        this.setState({ select1: entry });
                                        this.onFilteredChangeCustom(
                                        // entry.map(o => {                                            
                                            //return
                                            entry.value,
                                        //}),
                                        "teammember"
                                        );
                                    }}
                                />                        
                        </div>
                        <div className="col-6">
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={isClearable}
                                options={this.uniqueOptions(this.state.taskList, "status").map((status, i) => {
                                    return { id: i, value: status, label: status };
                                })}
                                multi={true}
                                onChange={entry => {
                                    if(!entry)
                                    {
                                        entry={id: '', value: '', label: ''};
                                    }
                                    console.log('entry==',entry);
                                    this.setState({ select2: entry });
                                    this.onFilteredChangeCustom(
                                    // entry.map(o => {
                                        
                                        //return
                                            entry.value,
                                    //}),
                                    "status"
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <ReactTable
                        data={data}
                        filtered={this.state.filtered}
                        minRows = {0}
                        pageSizeOptions = {[10,50,100]}
                        defaultPageSize = {10} 
                        
                        onFilteredChange={(filtered, column, value) => {
                            this.onFilteredChangeCustom(value, column.id || column.accessor);
                        }}
                        defaultFilterMethod={(filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            if (typeof filter.value === "object") {
                            return row[id] !== undefined
                                ? filter.value.indexOf(row[id]) > -1
                                : true;
                            } else {
                            return row[id] !== undefined
                                ? String(row[id]).indexOf(filter.value) > -1
                                : true;
                            }
                        }}
                        columns={columns}
                        className="-striped -highlight"
                    />
                </div>
                </div>
            </div>
		);
    }
}
export default Tasks;
