import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { PostData, GetData } from '../services/PostData';
import Loader from 'react-loader-spinner'
import OrderForm from './OrderForm'
import { loadStripe } from "@stripe/stripe-js";
import { StripeProvider, Elements } from 'react-stripe-elements'
import CheckoutForm from './CheckoutForm';
import ChatBoardSidebar from "./ChatBoardComponents/ChatBoardSidebar";
import ChatBoardContent from "./ChatBoardComponents/ChatBoardContent";
import chatboard from "./ChatBoardComponents/chatboard.css";


class ChatBoard extends Component {
	state = {
		duedate: new Date()
	};
	constructor() {
		super();
		this.state = {
		};

	};

	render() {

		return (
			<div className="container-fluid AddProjectPage MobPaddHide" ref="selfdiv">


				<div className="panel panel-primary closedcaptioning" style={{maxWidth: "100%"}}>
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}
								/>
							</div>
							: null
					}
					<div className="panel-heading">
						<div className="panel-heading">
							<div className="row">

								<div className="col-5">
									<h4 class="PageTopTitle">ChatBoard</h4>
								</div>
								<div className="col-7">

								</div>
							</div>
						</div>
					</div>
					<div className="PrimaryWrap container-fluid video-data">
						<div className="row FormDesign">
							<div className="col-12">

								<div component="div" class="TabsAnimation-appear-done TabsAnimation-enter-done">
									<div class="app-inner-layout chat-layout">
										<div class="app-inner-layout__wrapper" id="chatBoard">
											<chatboard>
											<ChatBoardSidebar />
											<ChatBoardContent />
											</chatboard>
											
										</div>
									</div>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default ChatBoard;
