import React, {useState,useEffect} from 'react';


import { loadStripe } from "@stripe/stripe-js";
  import { StripeProvider, Elements } from 'react-stripe-elements'
  import OrderCheckoutForm from './OrderCheckoutForm';

  
function OrderVideoForm(props){

	 const [stripeLoaded, setStripeLoaded] = useState({})

	const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.addEventListener('load', () => {
      resolve({ successful: true })
    })
    script.addEventListener('error', event => {
      reject({ error: event })
    })
    document.head.appendChild(script)
  });
  
	
	useEffect(() => {
		const fetchData = async () => {
      const result = await loadScript('https://js.stripe.com/v3/')
      setStripeLoaded(result)
    }
    fetchData()

	},[]);
	
	
	
	
	return ( 
	<div className="order-form-section" >	
				
		
			{ stripeLoaded.successful ? (
    <StripeProvider apiKey="pk_live_qhs2JPd41kDvGwZmp4UtDCTl">
      <Elements>
        <OrderCheckoutForm orgid={props.orgid} />
      </Elements>
    </StripeProvider>) : null
}
		
	</div>
	)
}
export default OrderVideoForm;