





import React, { Fragment } from "react";

import { Redirect } from 'react-router-dom';
import {PostData} from '../services/PostData';
import Loader from 'react-loader-spinner'

import '../assets/lcss/custom.css'
import '../assets/lcss/responsive.css'
import '../assets/lcss/animate.css';

import WOW from 'wowjs';
import Section1 from '../ComponentsLogin/Section1'
import Section2 from '../ComponentsLogin/Section2'
import Section3 from '../ComponentsLogin/Section3'
import Section4 from '../ComponentsLogin/Section4'
import LostPassword from './LostPassword';
class Loginstg extends React.Component {
  constructor(props)
    {
        super(props);
        this.state = {
            redirectToReferrer: false,
            fields: {},
            errors: {},
            loading: false,
            username:'',
            password:'',
            servereerror:'',
            displayType:'login'
        };
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
       
    }
   componentDidMount() {
    new WOW.WOW().init();
  }



    onChange(e){
        //console.log('cleeed in check==',e.target.value);
        this.setState({[e.target.name]:e.target.value});
    }
    login() {   
        if(this.state.rememberme)
        {
            console.log('in ifffffffffffff');
            localStorage.setItem('username', this.state.user_email);
            localStorage.setItem('password', this.state.user_pass);
        }
        else
        {
            console.log('in elseeeeeeeeeee');
            localStorage.setItem('username', '');
            localStorage.setItem('password', '');
        }
        if (this.validateForm())
        {
            if(this.state.user_email && this.state.user_pass)
            {
                this.setState({ loading: true });
                PostData('login',this.state).then((result) => {             
                    let responseJson = result;
                    if(responseJson.status==200)
                    {
                        console.log('responseJson==',responseJson);///subs/project/list
                        console.log('responseJson.data==',responseJson.data.orgid);
                        //console.log('responseJson==',responseJson);
                        //console.log('responseJson.data.user.role.administrator==',responseJson.data.user.role.administrator);///subs/project/list
                        this.setState({redirectToReferrer: true, loading: false,});
                        let redirectURL='subs/org/'+responseJson.data.orgid+'/project/list';
                        localStorage.setItem('userData',JSON.stringify(responseJson));
                        if(responseJson.data.user.role==='administrator')
                        {
                            console.log('in iffffffffffff admin');
                            this.props.history.push("/profile");
                        }
                        else
                        {
                            console.log('in iffffffffffff subs',redirectURL);
                            this.props.history.push(redirectURL);
                        }
                        //console.log('non admin==',responseJson.data);///subs/project/list
                        //this.props.history.push("/vpma");
                        ///subs/org/:orgid/project/list
                    }
                    else
                    {
                        this.setState({servereerror: responseJson.message.error, loading: false,});
                    }
                });
            }
        }
    }
    validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
      if (!fields["user_email"]) {
        formIsValid = false;
        errors["user_email"] = "*Please enter your email-ID.";
      }
      if (!fields["user_pass"]) {
        formIsValid = false;
        errors["user_pass"] = "*Please enter your password.";
      }
      this.setState({
        errors: errors
      });
      return formIsValid;
    }  

    changeView(viewtype,e){
        console.log('viewtype==',viewtype,e);
        this.setState({
            displayType:viewtype
        });
    }
  render() {
   const { errors, formSubmitted,loading } = this.state;
        const username=localStorage.getItem('username');
        const password=localStorage.getItem('password');
        const displayType=this.state.displayType;
        if (localStorage.getItem('userData')){
            console.log('in userdata exist ');
            let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
            if(!checkuser.user)
            {
                localStorage.setItem('userData','');
                return (<Redirect to={'/'}/>)
            }
            else{
            return (<Redirect to={'/vpma'}/>)
            }
        }
    return (
    <div className="loginPage">
      <Fragment>
        
        <section className="vpma-banner-section">
                    <div className="home-banner">
                        <div className="vc_col-sm-12">
                            <div className="banner-content container">
                                <div className="left vc_col-sm-6 wow fadeInLeft">
                                    <h2 className="banner-title">Video Production <em> Management App Login</em></h2>
                                    <p className="content">The VPMA is the smart way to produce videos for associations. Sign in to get started.</p>
                                </div>
                                <div className="right vc_col-sm-6 wow fadeInRight">
                                    <div className="form-content">
                                        <div className="login-form">
                                            {   (displayType ==='login' &&
                                            <div>
                                                <h2>Login</h2>
                                                {
                                                    loading ?<div className="loaderspinner">
                                                        <Loader
                                                            type="TailSpin"
                                                            color="#D10000"
                                                            height={100}
                                                            width={100}                              
                                                        />
                                                    </div> :null
                                                }
                                                <div className="errorMsg">{this.state.servereerror}</div>
                                                <div className="form-group"><input type="text" placeholder="Email Address*" defaultValue={username} name="user_email" onChange={this.onChange} />
                                                    <div className="errorMsg">{this.state.errors.user_email}</div>
                                                </div>
                                                <div className="form-group"><input type="password" defaultValue={password} placeholder="Password*" name="user_pass" onChange={this.onChange} />
                                                    <div className="errorMsg">{this.state.errors.user_pass}</div>
                                                </div>
                                                <div className="form-group common-btn">
                                                    <button type="submit" onClick={this.login} className="btn-signin sign-marg-no">Login</button>
                                                </div>
                                                <div class="row LostForgot">
                                                    <div className="col-12 form-group">
                                                        <a className="forcusror" onClick={this.changeView.bind(this,'lostpassword')}>Lost password?</a>
                                                    </div>                                      
                                                </div>
                                            </div>
                                            )                                                       
                                            }
                                            {
                                                (displayType ==='lostpassword' &&
                                                    <LostPassword changeView={this.changeView.bind(this,'login')} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        <Section2 />
        <Section3 />
        <Section4 />      
      </Fragment>
      </div>
    );
  }
}
export default Loginstg;




