import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Select from "react-select";
import ReactTable from "react-table";
import {GetData} from '../../services/PostData';
import "react-table/react-table.css";  
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
import { CSVLink, CSVDownload } from "react-csv";
//import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
class UserLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            timeLogList: [],
            originalTimeLogList: [],
            taskteammembersucsmsg:'',
            filtered: [],
            select1: undefined,
            select2: undefined,
            isClearable: true,
            loading:false,
            csvfilename:'Everybody',
            monthfilter:'All Time',
            selectedOption1: {value:'',label:"Everybody"},
            selectedOption2: {value:'',label:"All Time"},
            displaytype:'timelog'
            
        };
    }
    componentDidMount() {
        this.setState({loading:true});
        Promise.all([this.getOrgTimeLog()])
		.then(([orgtaskdata])  => {
            this.setState({
                timeLogList: orgtaskdata.data,
                orginaltotaltime: orgtaskdata.projecttotaltime,
                totaltime: orgtaskdata.projecttotaltime,
                originalTimeLogList: orgtaskdata.data,
                loading:false
            });
        });
    }
    getOrgTimeLog(){
        return GetData('userprojectsreport/'+this.props.userid).then((result) => {  
			return result;
		});
    }        
    onFilteredChangeCustom = (value, accessor) => {
        let selectedval=value;
        this.setState({ selectedOption2: {value:'',label:'All Time'} });
        let filtered = this.state.filtered;        
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {                
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length)  filtered.splice(i, 1)  
                    else filter["value"] = value;
                    insertNewFilter = 0;                    
                }
            });
        }
        if (insertNewFilter) {
            this.setState({csvfilename:value});
            filtered.push({ id: accessor, value: value });
        }
        else
        {
            this.setState({csvfilename:'Everybody'});
        }
        if(value)
        {
            this.setState({ selectedOption1: {value:selectedval,label:value} });
            let datata=this.state.originalTimeLogList;
            let newdata=[];
            if (datata.length) {  
                var projecttotaltime = '';  
                var projecthr = 0;
                var projectmin = 0; 
                var dmin = 0;       
                datata.forEach((data, i) => { 
                    if(value===data.project_name)
                    {                                      
                        newdata.push(data);                        
                        var project_time = data.totaladded_time;                    
                        if(project_time != undefined){
                            var time = project_time.split(':')
                            projecthr += parseInt(time[0]);
                            projectmin += parseInt(time[1]);
                            if(projectmin >=  60){
                                var dhr = parseInt(projectmin/60);
                                dmin = projectmin%60;
                                projectmin = dmin;
                            }
                            if(dhr){
                                projecthr = projecthr+dhr;
                            }
                        }  
                    }             
                });
                projecthr = (projecthr < 10 ? '0' : '') + projecthr
                projectmin = (projectmin < 10 ? '0' : '') + projectmin
                projecttotaltime = projecthr+':'+projectmin;
                this.setState({timeLogList:newdata});
                this.setState({totaltime:projecttotaltime})
            }    
        }
        else
        {
            this.setState({timeLogList:this.state.originalTimeLogList}); 
            this.setState({ selectedOption1: {value:'',label:'Everybody'} });
        }
        this.setState({ filtered: filtered });
    };    
    onCustomFilteredChangeCustom = (value, accessor) => {        
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        this.setState({ selectedOption1: {value:'',label:'Everybody'} });
        if(!value)
        {
            if (filtered.length) {
                filtered.forEach((filter, i) => {
                    if (filter["id"] === accessor) {
                        if (value === "" || !value.length) filtered.splice(i, 1);
                        else filter["value"] = value;
                        insertNewFilter = 0;
                    }
                });
            }
            if (insertNewFilter) {
                filtered.push({ id: accessor, value: value });
            }
            this.setState({ filtered: filtered,timeLogList:this.state.originalTimeLogList,totaltime:this.state.orginaltotaltime});
            this.setState({ selectedOption2: {value:'',label:'All Time'} });
       }
        else
        {
            this.setState({ selectedOption2: {value:value,label:value} });
            let datata=this.state.originalTimeLogList;
            filtered=[];
            let d = new Date();
            let currentmonth = parseInt(d.getMonth()+1);            
            let currentyear = parseInt(d.getFullYear());
            let lastmonth=currentmonth-1;
            let lastyear=currentyear-1;
            if(currentmonth==0){
                currentmonth=1;
                lastmonth = 12;
                if(value=='Last Month')
                    currentyear=currentyear-1;
            }
            let month='';
            let year='';
            switch(value) {
                case 'This Month':
                    month=currentmonth;
                    year=currentyear;
                    break;
                case 'Last Month':
                    month=lastmonth;
                    year=currentyear;
                    break;
                case 'This Year':
                    year=currentyear;
                    break;
                case 'Last Year':
                    year=lastyear;
                    break;
            }       
            if (filtered.length) {
                filtered.forEach((filter, i) => {
                    if (filter["id"] === accessor) {
                        if (value === "" || !value.length) filtered.splice(i, 1);
                        else filter["value"] = value;
                        insertNewFilter = 0;
                    }
                });
            }
            let newdata=[];
            if (datata.length) {  
                var projecttotaltime = '';  
                var projecthr = 0;
                var projectmin = 0; 
                var dmin = 0;       
                datata.forEach((data, i) => {  
                    this.setState({monthfilter:value});                            
                    let due = new Date(data.due_date);
                    let dmonth = parseInt(due.getMonth());
                    let dyear = parseInt(due.getFullYear());
                    if(dmonth==0)
                    dmonth=1;                                                          
                    else
                    dmonth=dmonth+1
                    if(value==='This Year' || value==='Last Year')
                    {
                        if (parseInt(year)===parseInt(dyear)){
                            newdata.push(data);                                
                            var project_time = data.totaladded_time;                            
                            if(project_time != undefined){
                                var time = project_time.split(':')
                                projecthr += parseInt(time[0]);
                                projectmin += parseInt(time[1]);
                                if(projectmin >=  60){
                                    var dhr = parseInt(projectmin/60);
                                    dmin = projectmin%60;
                                    projectmin = dmin;
                                }
                                if(dhr){
                                    projecthr = projecthr+dhr;
                                }
                            }
                        }                                          
                    }
                    else
                    {
                        if (parseInt(month)===parseInt(dmonth)){
                            newdata.push(data); 
                            var project_time = data.totaladded_time;                            
                            if(project_time != undefined){
                                var time = project_time.split(':')
                                projecthr += parseInt(time[0]);
                                projectmin += parseInt(time[1]);
                                if(projectmin >=  60){
                                    var dhr = parseInt(projectmin/60);
                                    dmin = projectmin%60;
                                    projectmin = dmin;
                                }
                                if(dhr){
                                    projecthr = projecthr+dhr;
                                }
                            }
                        }                 
                    }
                });
                projecthr = (projecthr < 10 ? '0' : '') + projecthr
                projectmin = (projectmin < 10 ? '0' : '') + projectmin
                projecttotaltime = projecthr+':'+projectmin;
                console.log('newdata===',newdata);  
                this.setState({timeLogList:newdata});
                this.setState({totaltime:projecttotaltime})
            }
            this.setState({ filtered: filtered });
        }       
    };
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    changeDisplayType(){
        this.setState({displaytype:'userlog'});
    }
    backToTimeLog(e){
        this.props.viewDisplaytype();
    }
    render() {
        const columns = [
            // {
            //     Header: 'Assigned to',
            //     accessor: 'assigneduser',               
            // },
            {
                Header: 'Task',
                accessor: 'task',               
            },
            {
                Header: 'Project',
                accessor: 'project',
                //width: 200,
            },
            {
                Header: 'Organization',
                accessor: 'organization',
               // width:200
            },
            // {
            //     Header: 'Status',
            //     accessor: 'taskstatus',
            //    // width:200
            // },
            // {
            //     Header: 'Due Date',
            //     accessor: 'duedate',
            //    // width:200
            // },
            {
                Header: 'Time',
                accessor: 'tasktime',
                width:200
            }
        ];

        
        const data=this.state.timeLogList;
        const totaltime = this.state.totaltime;
        const isClearable= this.state.isClearable;
        let d = new Date();
        //console.log('d==',d);
        let currentmonth = parseInt(d.getMonth());
        let currentyear = parseInt(d.getFullYear());
        //console.log('currentmonth',currentmonth);
        let lastmonth=currentmonth-1;
        let lastyear=currentyear-1;
        if(currentmonth==0){
            currentmonth=1;
            lastmonth = 12;
        }
        const options = [
            { value: 'This Month', label: 'This Month' },
            { value: 'Last Month', label: 'Last Month' },
            { value: 'This Year', label: 'This Year' },
            { value: 'Last Year', label: 'Last Year' }
        ]
        var desc = '';
        
        //const csvData = []
        const csvData = [
            ["Project", "Time", "Notes","Status","Due Date"],
        ];
          const ProjectTotalTime = ["Total Time: ", totaltime]
         
        var editortext = '';
        var itemsProcessed = 0;
        const filename = this.state.csvfilename; 
        const monthfilter = this.state.monthfilter;
        const newDate = new Date()
        const currentm = parseInt(newDate.getMonth() +1);
        const currentdate = newDate.getDate()+'/'+currentm+'/'+newDate.getFullYear();
        const currenttime = newDate.getHours()+':'+newDate.getMinutes()+':'+newDate.getSeconds();     
        
        data.forEach((item, index, array) => {                          
            var pusheddata = Array();
            pusheddata.push(item.task,item.project,item.organization);
            csvData.push(pusheddata);                      
        });              
		return (
            
            <div className="userlogPage"  ref="selfdiv">         
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-md-7 col-lg-7">
                                <h4 class="PageTopTitle">{this.props.username} Time Log</h4>
                            </div>
                            <div className="col-md-2 col-lg-2"></div>
                            <div className="col-md-3 col-lg-3">
                            </div>
                        </div>
                    </div>                    
                    <div className="panel-body timelog-table-listing">
                       <div className="row">
                           <div className="col-6">    
                                <a className="forcusror" onClick={this.backToTimeLog.bind(this)}>Back To Time Log</a>                                                 
                            </div>
                            <div className="col-6">                                
                            </div>
                        </div>                   
                        {   
                            (data.length>0) && 
                            <ReactTable
                                data={data}
                                filtered={this.state.filtered}
                                minRows = {0}
                                pageSizeOptions = {[10,50,100]}
                                defaultPageSize = {10}                   
                                columns={columns}
                                className="-striped -highlight"
                            />                        
                        }                    
                        {/* <div class="total_time">Total Time: {totaltime}</div>  */}
                    </div>                
                </div>
            </div>
		);
    }
}
export default UserLogs;
