import React, { Component } from "react";
//import { Helmet } from 'react-helmet';
import { PostData, GetData } from '../services/PostData';
import { Link  } from 'react-router-dom';
class InviteeAddVideoURL extends Component {
    constructor(props){
		super(props);
		this.state={
			page_pass:'',
			password:''
		}
	}
	onchange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	addLink(e)
	{
		console.log('calledddddddddd');
		if(!this.state.password)
		{
			console.log('this.state.password in ifff ===',this.state.password);
			this.setState({
				page_pass:'Please add password'
			});
			return;
		}
		else{
			console.log('this.state.password in else ===',this.state.password);
		
			let data = {
				'orgid': this.props.match.params.orgid,
				'folderid': this.props.match.params.folderid,
				//'userid': userdata.user.ID,
				'password':this.state.password
			}
			console.log('data===',data);			
			PostData('createurlfrominvitee', data).then((result) => {
				console.log('result===',result);
				if (result.statusCode === 200) {
					this.setState({
						slinkid:result.data._id,
						folderid:result.data.folderid
					});	
				}
			});
		}
	}
    render() {	
		let slinkid=this.state.slinkid;
		let folderid=this.state.folderid;
		let savedlink ='';
		if(slinkid)
		{
			//savedlink='http://localhost:3000/'+this.props.match.params.orgid+'/'+folderid+'/createvideo/'+slinkid; //Local
            savedlink='https://vpma.associationstudios.com/'+this.props.match.params.orgid+'/'+folderid+'/createvideo/'+slinkid;; //live
		}	
		// let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		// let usertype='';
        // if (userdata.role && userdata.role!='administrator')
       // usertype='/subs'  		
        return (
        	<div className="ProducerSaveDate AddStoryBoard">
				<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-6">
									<h4 class="PageTopTitle">Add Link</h4>
								</div>							
								 <div  className="col-6 text-right"> 
								{/* <Link to={`${usertype}/${this.props.match.params.orgid}/assets`}>Back To Assets</Link>  */}
								</div> 
							</div>
						</div>
					</div>
				<div className="row">
					<div className="col-8">
						<label>Create Password for Page:</label><input type="password"  placeholder="Create Password for Page*" className="pagepassword" name="password" onChange={this.onchange.bind(this)} autoFocus />
						<div className="errorMsg">{this.state.page_pass}</div>	
						<a className="forcusror graybtn ComIncLink btn btn-primary" onClick={this.addLink.bind(this)} >Add Link</a>	
						<div className="savedlink">{savedlink}</div>
					</div>
					<div className="col-4"></div>
				</div>	
			</div>	
		);
    }
}
export default InviteeAddVideoURL;