import React, { Component } from "react";
import TaskList from './profiledata/TaskList'
import ProjectList from './profiledata/ProjectList'
import Inbox from './profiledata/Inbox'
import Reports from './profiledata/Reports'

class Profile extends Component {
    constructor(props) {
        let userdataforid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        console.log('userdataforid==',userdataforid);
        super(props);
        this.state = {
            displaytype:'tasklist',
            loggedInUserId:userdataforid.ID
        }
        //console.log("product props is", this.props.location.tabname);
    }
    changeView(displaytype){
        this.setState({ displaytype: displaytype }); 
     } 
    componentDidMount() {
        if(this.props.location.tabname)
        {
            this.setState({
                displaytype:this.props.location.tabname.activetab,
            });
            console.log("product props is", this.props.location.tabname);
        }
    }
    render() {                       
            let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
            const name=userdataforcheck.first_name+' '+userdataforcheck.last_name;
            let avatar;
            if(userdataforcheck.profile_image)
            avatar='../../../profile-image/'+userdataforcheck.profile_image;
            else
            avatar = userdataforcheck.avatar;
            //const avatar=userdataforcheck.avatar;
            const username=userdataforcheck.username;

            let jobtitle;
            if(userdataforcheck.job_title)
            jobtitle=userdataforcheck.job_title;
            else
            jobtitle = userdataforcheck.jobtitle;

            //const jobtitle=userdataforcheck.jobtitle;
            const description=userdataforcheck.description;
            return (
			 <div className="container-fluid TaskListPage"  ref="selfdiv">
                <div className="panel panel-primary ProfilePanel">                    
                    <div className="WrapBarTop panel-heading">
                        <div className="row MoPrologoLeft">
                            <div className="col-12 ProAssetsHead">
                                <img  className="mr-3" src={avatar}/>
                                <h4 className="PageTopTitle">{name}</h4>
                                <p> {jobtitle}<br/>
                                    @{username}<br/>
                                    {description}                                                                    
                                </p>
                            </div>                         
                        </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 MobOrgProTab">
                                <ul className="top-links">
                                     <li>
                                        <a className="forcusror" onClick={this.changeView.bind(this,'tasklist')}>Tasks</a>
                                    </li>
                                    <li>
                                        <a className="forcusror" onClick={this.changeView.bind(this,'projectlist')}>Projects</a>
                                    </li>                                      
                                    <li>
                                        <a className="forcusror" onClick={this.changeView.bind(this,'inbox')}>Inbox</a>
                                    </li>      
                                    <li>
                                        <a className="forcusror" onClick={this.changeView.bind(this,'report')}>Reports</a>
                                    </li>
                                      
                                </ul>
                            </div>                                
                        </div>
                    </div>
                    <div className="panel-body">    
                        {                             
                           (this.state.displaytype==='projectlist' &&
                                <div className="project-list">  
                                    <ProjectList userid={this.state.loggedInUserId} /> 
                                </div>
                            )                       
                        }
                        {    
                            (this.state.displaytype==='tasklist' &&
                                <div className="project-list">  
                                    <TaskList userid={this.state.loggedInUserId} /> 
                                </div>
                            )
                        }
                        {    
                            (this.state.displaytype==='inbox' &&
                                <div className="project-list">  
                                    <Inbox userid={this.state.loggedInUserId} /> 
                                </div>
                            )
                        }  
                        {    
                            (this.state.displaytype==='report' &&
                                <div className="project-list">  
                                    <Reports userid={this.state.loggedInUserId} /> 
                                </div>
                            )
                        }
               
                     </div>
                </div>
            </div>
		);
    }
}
export default Profile;
