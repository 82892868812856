import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { apiConfig } from "../../../config/api"
import { Link } from 'react-router-dom';
class AddTimeForTask extends Component {
    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			thour: '',
			tminut: '',
			editedtaskteammember:this.props.editedtaskteammember,
			editedtaskduedate:this.props.editedtaskduedate,
			editedtaskstatus:this.props.editedtaskstatus,
			taskactivity: this.props.taskactivity,
			selectedassigneeuserid:this.props.selectedassigneeuserid,
			selectedassigneeteammember:this.props.selectedassigneeteammember,
		};
		console.log('this.props.selectedassigneeuserid==',this.props.selectedassigneeuserid);
		//console.log('this.props.selectedassigneeteammember==',this.props.selectedassigneeteammember);
	}	
	handleTaskTeamMemberChange(e) {
		var index = e.target.selectedIndex;
		var optionElement = e.target.childNodes[index]
		var option = optionElement.getAttribute('data-id');
		this.setState({ editedtaskteammember: e.target.value,selectedassigneeteammember:e.target.value,selectedassigneeuserid:option });
		let data = {
			'taskid': this.props.editedtaskid,
			'teammember': e.target.value,
			'userid': option
		}
		PostData('updatetaskteammember', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ taskteammembersucsmsg: result.message }); setTimeout(() => this.setState({ taskteammembersucsmsg: '' }), 3000);
			}
		});
	}
	handleTaskstatusChange(e) {		
		let data = {
			'taskid': this.props.editedtaskid,
			'taskstatus': e.target.value,
		}
		if(e.target.value==='Delete')
		{
			if(window.confirm("Are you sure you want to Delete the Task?"))
			{
				this.setState({ editedtaskstatus: e.target.value });
				this.updateTaskStatus(data);	
			}		
		}
		else
		{
			this.setState({ editedtaskstatus: e.target.value });
			this.updateTaskStatus(data);			
		}		
	}
	updateTaskStatus(data){
		PostData('updatetaskstatus', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ taskstatussucsmsg: result.message });
				setTimeout(() => this.setState({ taskstatussucsmsg: '' }), 3000);
			}
		});
	}
	handleEditedTaskDueDateChange(name, date) {
		let data = {
			'taskid': this.props.editedtaskid,
			'due_date': date
		}
		PostData('updatetaskduedate', data).then((result) => {
			if (result.statuscode === 200) {
				this.setState({ taskduedatesucsmsg: result.message, editedtaskduedate: new Date(result.data.due_date) }); setTimeout(() => this.setState({ taskduedatesucsmsg: '' }), 3000);
			}
		});
	}
	onTaskFileChangeHandler = event => {
		this.setState({
			'taskfile': event.target.files[0],
			loaded: 0,
		})
	}
	commentChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	validateAddTaskTimeForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["selectedtask"]) {
			formIsValid = false;
			errors["selectedtask"] = "*Please select Task";
		}
		if (!fields["thour"]) {
			formIsValid = false;
			errors["thour"] = "*Please select Hour";
		}
		if (!fields["tminut"]) {
			formIsValid = false;
			errors["tminut"] = "*Please select Minut";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	validateAddNoteForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["taskfile"]) {
			formIsValid = false;
			errors["taskfile"] = "*Please upload file";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	saveNote(action, type) {
		if (this.validateAddNoteForm()) {
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			const data = new FormData();
			data.append('orgid', this.props.orgid)
			data.append('projectid', this.props.prjid)
			data.append('taskid', this.props.editedtaskid)
			data.append('notesfile', this.state.taskfile)
			data.append('comment', this.state.notecomment)
			let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL + 'addnotes', {
				method: 'POST',
				body: data,
				headers: {
					"session-key": session_key,
				}
			})
				.then((response) => response.json())
				.then((res) => {
					if (res.statusCode === 200) {
						const file = document.querySelector('.taskfile');
						file.value = '';
						this.setState({ taskactivity: res.data, tasknotesucsmsg: res.message, taskfile: '', notecomment: '' });
						setTimeout(() => this.setState({ tasknotesucsmsg: '' }), 3000);
					}
				});
		}
	}
	addTaskNotes(e) {
		this.setState({ addTaskType: 'Notes' });
	}
	addTaskTime(e) {
		this.setState({ addTaskType: 'Time' });
	}
	onTaskHourChange = thour => this.setState({ thour })
	onTaskMinutChange = tminut => this.setState({ tminut })
	validateAddTimeForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["thour"]) {
			formIsValid = false;
			errors["thour"] = "*Please select hour";
		}
		if (!fields["tminut"]) {
			formIsValid = false;
			errors["tminut"] = "*Please select minut";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	saveTime(e) {
		if (this.validateAddTimeForm()) {
			this.setState({ Inloading: true });
			const hour = this.state.thour.format('HH');
			const minut = this.state.tminut.format('mm');
			const finaltime = hour + ':' + minut;
			let data = {
				'taskid': this.props.editedtaskid,
				'orgid': this.props.orgid,
				'projectid': this.props.prjid,
				'time': finaltime,
				'comment': this.state.tcomment,
				'userid':this.props.userid,
				'taskname':this.props.editedtaskassignment,
				'selectedassigneeuserid':this.state.selectedassigneeuserid,
				'selectedassigneeteammember':this.state.selectedassigneeteammember,
			}
			this.saveTaskdata(data);			
		}
	}
	saveTaskdata(data)
	{
		this.setState({ Inloading: true });
		PostData('addtasktime', data).then((result) => {
			if (result.statusCode === 200) {
				//let remainingtime = this.getDifferenceTime(result.totaltime);
				this.setState({ taskactivity: result.data, tasktimesucsmsg: "Time Added Succesfully", thour: '', tminut: '', tcomment: '',selectedtask:'',selectedassigneeteammember:'',selectedassigneeuserid:'' });
				setTimeout(() => {
					this.setState({ tasktimesucsmsg: '',setAddTimeShow: false });
					//this.loadMore();
					this.props.loadProjectBoard(result.totaltime);
				}, 3000);
			}
			else if (result.statusCode === 401) {
				this.setState({ tasktimeerrormsg: result.Message });
				setTimeout(() => this.setState({ tasktimeerrormsg: '' }), 3000);
			}
			this.setState({ Inloading: false });
		});
	}
    render() {
		const CustomButton = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				<img src={require('../../../assets/images/due-date.png')} /> {value}
			</button>
		);
		let taskactivity = this.state.taskactivity;
		let tasktime = [];
		let tasknote = [];
		console.log('taskactivity==',taskactivity);
		if (taskactivity) {
			for (var key in taskactivity) {
				if (taskactivity[key].type === 'notes') {
					tasknote.push(<li className="itemlist"><div className="row">
						<div className="col-12 ChatBoxDesign">
							<div className="type">
								<Link to={require('../../../assets/images/projectsassets/' + taskactivity[key].notesfile + '')} target="_blank" download>Download
						</Link>
							</div>
							<div className="PB_create_date">
								<label>Comment: </label>
								{taskactivity[key].comment}
							</div>
						</div>
					</div>
					</li>
					)
				}
				else {
					tasktime.push(<li className="itemlist"><div className="row"><div className="col-12 ChatBoxDesign">
						<div className="type">
							<label>Time: </label>
							{taskactivity[key].time}
						</div>
						<div className="PB_create_date"><label>Comment: </label>{taskactivity[key].comment}
						</div></div></div></li>)

				}
			}
		}
		//console.log('tasktime==',tasktime);
        return (
        	<div className="ProducerSaveDate EditUpdateTask">
				<img className="SD_img_icon" src={'../../../../../profile-image/'+this.props.avatar} />
				{
					this.state.Inloading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>
						</div>
					: null
				}		
				<div>
					{tasktime}
					<div className="row LP_Row">
						<div className="successmsg">{this.state.tasktimesucsmsg}</div>
						<div className="errormsg">{this.state.tasktimeerrormsg}</div>
						<div className="col-4">
							Addtime:
							<TimePicker name="thour" value={this.state.thour} onChange={this.onTaskHourChange} showMinute={false} showSecond={false} />
							<div className="errorMsg">{this.state.errors.thour}</div>
						</div>
						<div className="col-4">
							<TimePicker name="tminut" minuteStep={15} value={this.state.tminut} onChange={this.onTaskMinutChange} showHour={false} showSecond={false} />
							<div className="errorMsg">{this.state.errors.tminut}</div>
						</div>
						<div className="col-4">
							<input className="LP_field" value={this.state.tcomment} onChange={this.commentChange.bind(this)} name="tcomment" type="textarea" placeholder="Comment" />
						</div>
						<div className="col-12 text-right">
							<button type="button" onClick={(e) => this.saveTime(e)} className="UpdateBtn btn btn-primary">Save</button>
						</div>
					</div>
				</div>
								
			</div>
		);
    }
}
export default AddTimeForTask;