





import React, { Component } from "react";
import {PostData,GetData} from '../services/PostData';

const errorMsg = {
  color: '#cc0000',
  marginBottom:'12px'
};
const successMsg = {
  color: '#00ff00',
  fontSize:'16px'
};
class Messaging extends Component {
      constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.testEmailSubmit = this.testEmailSubmit.bind(this);
        this.state = {
        openingsection: '',
        closingsection: '',
        errors:'',
        success:'',
        testsuccesmsg:'',
        errortestemail:''
       }

    }
    componentDidMount() {
        GetData('getemailformate/').then((result) => {     
            console.log('check result in edit page===',result);      
            this.setState({ 
                openingsection: result.data.openingsection,
                closingsection: result.data.closingsection,
            });
        });
    }
    onChange(e){
        const target = e.target;
        const value = target.type === 'checkbox' ? target.value : target.value;
        const name = target.name;
        //console.log('this state===',name,value);
        this.setState({[name]:value});    
    } 

    async submit(e) {
        e.preventDefault();
        let data ={
            openingsection:this.state.openingsection,
            closingsection:this.state.closingsection
        }

        PostData('updateemailformate', data).then((result) => {
            console.log('result=',result);
            if (result.statusCode === 200) {
                this.setState({ success: 'Email Formate Updated Successfully' });
                setTimeout(() => {
                    this.setState({  success: '' });
                }, 3000);

            }
        });
    }
    testEmailSubmit(e) {
        e.preventDefault();
        if(this.state.testemail) {
            this.setState({  errortestemail: '' });
            let data ={
                selectededitor:this.state.selectededitor,
                testemail:this.state.testemail
            }
            console.log('data==',data);
            PostData('testemailfortask', data).then((result) => {
                console.log('result=',result);
                if (result.statusCode === 200) {
                    this.setState({ testsuccesmsg: 'Test Email send Successfully', testemail:'' });
                    setTimeout(() => {
                        this.setState({  testsuccesmsg: '' });
                    }, 3000);

                }
            });
        } else {
            this.setState({  errortestemail: 'Please enter Email Address' });
        }
    }
    
    onEditorChange(e)
    {
        //let tasks = [...this.state.tasks];
        console.log('e.target.value=',e.target.value);
        //tasks[i] = { ...tasks[i], videoshoottype: e.target.value };
        this.setState({ selectededitor: e.target.value});
    }
    render() {
        const succesmsg = this.state.success;
        return (
			<div className="container-fluid ProjectList"  ref="selfdiv">
                <div className="row">
                    <div className="col-12">
                       <h3>Messaging</h3>
                       <div className="msg">
                            <p style={successMsg}>{succesmsg}</p>
                            <div onSubmit={this.onSubmit} class="FormDesign">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label htmlFor="org"> Opening Section</label>
                                        <textarea  onChange={this.onChange} className="form-control" value={this.state.openingsection} name="openingsection"/> 
                                        <div style={errorMsg}>{this.state.errors.org_name}</div>     
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label htmlFor="street">closing Section</label>
                                        <textarea  onChange={this.onChange} className="form-control" value={this.state.closingsection} name="closingsection"/>
                                    </div>
                                    
                                </div>
                                 <div className="row">
                                    <div className="form-group col-sm-12">
                                        <p style={successMsg}>{this.state.testsuccesmsg}</p>
                                    </div>
                                 </div>
                                 <div className="row">
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="street">Select Editor</label>
                                        <select name="selectededitor" className="form-control" onChange={this.onEditorChange.bind(this)} id="assignment">
                                            <option value=" ">Select Editor</option>
                                            <option value="281">Colby Natal</option>
                                            <option value="282">Michael Barry</option>
                                            <option value="502">Hassan Raja</option>
                                            <option value="664">Matt Maple</option>                                                      
                                            <option value="48">Brad Sapienza</option> 
                                            <option value="15946">Roberto Stenner</option> 
                                        </select>   
                                    </div>
                                    <div className="form-group col-sm-4">
                                        <label htmlFor="street">Enter Test Email</label>
                                        <input type="text"  onChange={this.onChange} className="form-control" value={this.state.testemail} name="testemail"/>  
                                        <div style={errorMsg}>{this.state.errortestemail}</div>     
                                    </div>
                                     <div className="form-group col-sm-4">
                                        <br/>
                                        <input type="submit" onClick={this.testEmailSubmit}
                                            value="Test Email" 
                                            className="UpdateBtn btn btn-primary"/>  
                                    </div>
                                </div><br/>
                                <div className="row">
                                    <div className="form-group col-sm-12">
                                        <input type="submit" onClick={this.submit}
                                            value="Update Email Formate" 
                                            className="UpdateBtn btn btn-primary"/>
                                    </div>
                                </div>

                            </div>
                       </div>
                       
                    </div>
                </div> <br/><br/>
            </div>
		);
    }
}
export default Messaging;
