import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
import { apiConfig } from "../../../config/api"
import Dropzone from 'react-dropzone-uploader'
import { Dropdown } from 'react-bootstrap';
import ByteToSize from '../../ByteToSize'
import ReactTable from "react-table";
import "react-table/react-table.css";  
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { GetData,PostData } from '../../../services/PostData';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
class ProjectAssets extends Component {
    constructor(props){
		super(props);        
        this.state = {
            activePage: 1,
            itemPerPage:10,
            searchText: '',
            isdiagram:true,
            isMax:false,
            projectList: [],
            originalProjectList: [],
            loading:false,
            boxfolderid:'',
            isInner:false,
            rootfolderid:'',
            breadcrumblist:[],
            selectedFile:[],
            showsharelink:false,
            sharefolderid:'',
            isChecked:false
        };
        this.projectDetails = this.projectDetails.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);    
	}	
	
	componentDidMount() {        
        console.log('this. props=== callleddddddddddddddd');
        if(this.props.project_box_folderid)
        {
           // console.log('this. props===',this.props.location.folderid);
            this.getFolderListByFolderId(this.props.project_box_folderid);
           // this.getbreadcrumb(this.props.location.folderid);

           //boxfolderid
           this.setState({
                boxfolderid:this.props.project_box_folderid
            });
        } 
             
    }
    
    getFolderListByFolderId(folderid)
    {
        console.log('caleddddddddddddddddddd in props');
        this.setState({loading:true});
        let data = {
            'boxfolderid': folderid,            
        }
        this.setState({boxfolderid:folderid});
        PostData('boxassets', data).then((result) => {                  
            if(result.statusCode===200)
            {
                console.log('responseJson==',result);
                this.setState({
                    activePage: 1,
                    projectList: result.data,
                    originalProjectList: result.data,
                    loading:false
                });
            }
        });  
       // this.getbreadcrumb(folderid)
    }
     handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        console.log(this.state.projectList);
    }
    projectDetails(item, index) {
        console.log(index);
    }
    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalProjectList.length; i++) {
                    let orgname=this.state.originalProjectList[i].filename;
                    orgname=orgname.toLowerCase();
                    textToSearch=textToSearch.toLowerCase();
                    if(orgname!=null)
                    {
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalProjectList[i]);
                        }
                    }
                }
                this.setState({
                    projectList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    projectList: this.state.originalProjectList, 
                });
            }
       });
    }
    changeView(displaytype){
        this.props.view(displaytype);
    }
    handleClick(folderid){
        this.setState({
            isInner:true,
            boxfolderid:folderid
        });
        this.getFolderListByFolderId(folderid);
    }
    backToRootFolder(folderid){
        this.setState({
            isInner:false,
            boxfolderid:folderid
        });
        this.getFolderListByFolderId(folderid);
    }
    backToInnerFolder(folderid){
        this.setState({            
            boxfolderid:folderid
        });
        //this.getFolderListByFolderId(this.state.boxfolderid);
        this.getFolderListByFolderId(folderid);
    }
    loadFolderList(){
        this.getFolderListByFolderId(this.state.boxfolderid);
    }
    setAddAssetShow(e){
        let currdate=Date.now();
        this.setState({ setAddAssetShow: true,isAddAsset:currdate });
    }
    onAssetfileChangeHandler = event => {
        this.setState({
            'assetfile': event.target.files[0],
            loaded: 0,
        })
    }
    handleChangeStatus = ({ meta, file }, status) => { 
        console.log('selectedFile in 192===',this.state.selectedFile);
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
    }
    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    } 
    handleSubmit1 = (files, allFiles) => {
        var loadermessage='Uploading';
        allFiles.forEach((f) => {

        // allFiles.forEach(f =>
            var mimetype= f.file.type;
            var checkvideo = mimetype.split('/');
            if(mimetype!='video/mp4' && checkvideo[0]=='video')
            {
                        loadermessage='Uploading and converting file in mp4'
            }
            else
            {
                console.log('in elsee f=============',f)
            }
        })
        //if(this.checkFolderID()){
        this.setState({assetuploadloader:true,assetuploadmessage:loadermessage});
        var formdata = new FormData();
        const selectedfiles=this.state.selectedFile;
        console.log('selectedfiles===',selectedfiles);
        var uniqueArray = this.removeDuplicates(selectedfiles, "name");
        for(var x = 0; x<uniqueArray.length; x++) {
            formdata.append('boxfile', uniqueArray[x])
        }
        uniqueArray=[];
        this.setState(prevState => ({
            selectedFile: [[], []]
        }))
        let newState = this. state;
        delete newState['selectedFile'];
        this.setState(newState);
        this.setState({selectedFile:[]});
        //formdata.append('title', 'test');
        //fileid
        formdata.append('folderid', this.state.boxfolderid) 
        //formdata.append('folderid', this.state.boxfolder_name)    
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
        let session_key = userdata.token;
        var ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", this.progressHandler, false);
        ajax.addEventListener("load", this.completeHandler, false);
        ajax.addEventListener("error", this.errorHandler, false);
        ajax.addEventListener("abort", this.abortHandler, false);
        ajax.open("POST", apiConfig.WEB_SERVICE_URL+'fileupload'); 
        ajax.setRequestHeader("session-key", session_key);
        ajax.send(formdata);
        return ajax;
    
    }
    handleSubmit = async (files, allFiles) => {
        this.setState({assetuploadloader:true},
            ()=>{
                document.getElementById("loadingstatus").innerHTML ="File is uploading";
            });
        
         GetData('getboxaccesstoken').then(async (result) => {
            console.log('result=',result);
            var loadermessage='Uploading';
            const selectedfiles=this.state.selectedFile;
            //console.log('selectedfiles===',selectedfiles);
            var uniqueArray = this.removeDuplicates(selectedfiles, "name");
           // console.log('uniqueArray===',uniqueArray);
            for(var x = 0; x<uniqueArray.length; x++) {
                let filecount = x+1;
                document.getElementById("loadingstatus").innerHTML ="File "+filecount+" is uploading";
            //for (let f of uniqueArray) {
                let date_ob_new = Date.now();
                
                 var formData = new FormData();
                  console.log('f=',uniqueArray[x]);
                  var filename = uniqueArray[x].name;

                var file = filename.split(/\.(?=[^\.]+$)/);
                var newFileName = file[0] +'_'+ date_ob_new+'.'+file[1];
                //console.log('newFileName=',newFileName);
                formData.append('file', uniqueArray[x],newFileName);

                // formData.append('file', uniqueArray[x])
                formData.append('parent_id', this.state.boxfolderid); // this.state.boxfolderid Replace with the ID of the folder where you want to upload the file
               
                const response = await fetch('https://upload.box.com/api/2.0/files/content', {
                  method: 'POST',
                  headers: {
                    Authorization: `Bearer `+result.access_token, // Replace with your own access token
                  },
                  body: formData,
                });
                console.log('response=',response);
                console.log('response=',response.body);
                if (response.status === 201) {
                    console.log('All file uploaded successfully.');
                    let boxdata={
                        filename:newFileName,
                        parent_id:this.state.boxfolderid
                    }
                     PostData('fileupload',boxdata).then(async (bresult) => {
                        console.log('bresult=',bresult);
                     })
                } else {
                 // const data = await response.json();
                  console.log(`Error uploading file`);
                }
            }
         
            //if(this.checkFolderID()){
            document.getElementById("loadingstatus").innerHTML ="All file uploaded successfully.";
            
            setTimeout(function() {
               window.location.reload(); 
            }, 3000);

                 
            uniqueArray=[];
            this.setState(prevState => ({
                selectedFile: [[], []]
            }))
            let newState = this. state;
            delete newState['selectedFile'];
            this.setState(newState);
            this.setState({selectedFile:[]});

        });
    } 
    progressHandler(event) {
        console.log( "Uploaded " + event.loaded + " bytes of " + event.total);
        var percent = (event.loaded / event.total) * 100;
        console.log( Math.round(percent));
        console.log( Math.round(percent) + "% uploaded... please wait");
        //this.setState({loadingstatus:Math.round(percent) + "% uploaded... please wait"});
        document.getElementById("loadingstatus").innerHTML =Math.round(percent) + "% uploaded... please wait";
    }

    completeHandler(event) {
        console.log( 'completee fhandler111',event.target.responseText);
        var res =event.target.responseText;
        //var res = data.json();
        var res = JSON.parse(res);
        console.log('checkresposise11==',res,res.statuscode); 
        document.getElementById("loadingstatus").innerHTML =res.message;
        setTimeout(()=>{
            if(res.statusCode===200)
            {   
                window.location.reload();      
                console.log('status code==',res.statuscode);                
            } 
        },2000)    
    }

    errorHandler(event) {
        console.log("Upload Failed");
    }

    abortHandler(event) {
        console.log("Upload Aborted");
    }
    shareLink(sharefolderid,foldername,isLinkCreated,isEmailRequired){
        console.log('calledd in showsharelink function',sharefolderid,foldername,isLinkCreated,isEmailRequired);
        this.setState({ showsharelink: true,sharefolderid:sharefolderid,foldername:foldername,isLinkCreated:isLinkCreated,isEmailRequired:isEmailRequired });
    }
    handleclose(){
        this.setState({ showsharelink: false });
    }
    downloadarchive(sharefolderid,filetype,filename){
        this.setState( { loading:true } );
        //console.log('this.state.isChecked below==',this.state.isChecked);\
        console.log('filename==',filename);
        let data = {
            'folderid': sharefolderid,
            'filetype':filetype,
            'filename':filename,
        }
        console.log('data===',data);
        if(!this.state.isChecked)
        {           
            PostData('downloadarchive', data).then((result) => {
                console.log('result===',result);
                if (result.statusCode === 200) {
                    this.setState({
                        //fileid:result.data.fileid,
                        //isChecked: !this.state.isChecked,
                        loading:false,
                       // linkaddedsuccessmsg:result.message
                       
                    }); 
                    let link = document.createElement('a');
                    link.href = result.data.download_url;
                    let filename= `$filename.zip`
                    link.setAttribute(
                    'download',
                    filename,
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);  
                                
                }
            });
        }
    }
    deleteassets(event,folderid,filetype){
        console.log('folderid,filetype===',folderid,filetype);
        this.setState( { loading:true } );
        //console.log('this.state.isChecked below==',this.state.isChecked);\
        let data = {
            'folderid': folderid,
            'filetype':filetype,
        }
        console.log('data===',data);        
        PostData('deleteassets', data).then((result) => {
            console.log('result===',result);
            if (result.statusCode === 200) {
                this.setState({
                    loading:false,                
                });  
                window.location.reload();                                   
            }
        });
    }
   
    _handleChange(id,status,e){
        console.log('this.status.status=',status);
        this.setState({loading:true});
        let data = {
            'assetid': id,  
            'boxfolderid':this.state.boxfolderid ,
            'status' : (status==='active')? 'inactive' : 'active'               
        }
        PostData('changefolderstatus', data).then((result) => {                  
            if(result.statusCode===200)
            {
                console.log('responseJson==',result);
                this.setState({
                    checkedCheckbox: id,
                    isChecked: !this.state.isChecked,
                    loading:false,
                    activePage: 1,
                    projectList: result.data,
                    originalProjectList: result.data,
                })

               // window.location.reload(false);


            }
        });
        
    }
    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) {
          return {
            className: (rowInfo && rowInfo.original && rowInfo.original.status == 'inactive') ? "disablerow" : "activerow"
          }
        }
        return {};
    }
	
	render() {   
    let breadcrumblistdata='';
        let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let renderedProjects = this.state.projectList.slice(indexOfFirstTodo, indexOfLastTodo);
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }     
        let usertype='';
        if (userdata.role && userdata.role!='administrator')
        usertype='/subs'       
        let listItems = renderedProjects.map((item, index) => {       
            let name;
            let file='';
            let filesize='';
            let videolink = item.assetdata;
            if (item.filetype==='folder') {
                name = <a onClick={this.handleClick.bind(this,item.fileid)} className="forcusror">{item.filename}</a>;
                file =<img src={require('../../../assets/images/assets-icon/folder.png')} width="50" height="50" />
                filesize=<div>{item.filecount} Files</div>
            } else {
                if(item.assetsize)
                filesize=<ByteToSize byte={item.assetsize} />
                else
                filesize='';
                var filename = item.filename;
                var filetype =filename.split(/\.(?=[^\.]+$)/);
                 if(filetype[1]==='jpg' || filetype[1]==='jpeg' || filetype[1]==='png')
                {
                    file =<img key={videolink} src={videolink}  width="70" height="50" />                       
                }
                else if(filetype[1]==='png')
                {
                    file =<img src={require('../../../assets/images/assets-icon/png.png')} width="50" height="50" />                       
                }
                else if(filetype[1]==='pdf')
                {
                    file =<img src={require('../../../assets/images/assets-icon/pdf.jpg')} width="50" height="50" />                       
                }
                else if(filetype[1]==='mp3')
                {
                    file =<img src={require('../../../assets/images/assets-icon/mp3.png')} width="50" height="50" />                       
                }
                else if(filetype[1]==='mp4'  || filetype[1]==='MP4')
                {
                    if(!item.isfilemovedtoassets)
                    {
                        file =<img src={require('../../../assets/images/assets-icon/pending-video-thumbnail.png')} height="50" />                       
                    }
                    else
                    {
                        file =<video width="90" height="50" key={videolink}>
                            <source key={videolink} src={videolink}
                                type="video/mp4" />
                                Sorry, your browser doesn't support embedded videos.
                        </video>
                    }
                }
                name = <Link to={`${usertype}/${this.props.orgid}/${item.fileid}/editfile`}>{filetype[0]}</Link>;               
                videolink="";
            }
            return <tr key={index} className={` ${(item.status === 'inactive') ? "disablerow" : "activerow"} ` }>
                <td className="tbl_org_name">                   
                    {file}{name}
                </td>
                {/* <td className="tbl_org_description">{item.description}</td> */}
                {/* <td className="tbl_org_duedate">{dateFormat(item.created_at, "mm/dd/yyyy")}</td>       */}
                <td className="tbl_org_duedate">{dateFormat(item.modefied_at, "mm/dd/yyyy")}</td>  
                <td className="tbl_org_duedate">
                    {filesize}
                </td>  
                <td className="tbl_org_duedate">
                    {
                        (item.filetype==='folder' &&
                        <label labelfor={item._id} className={`switch ${(item.status === 'active') ? "switchon" : "switchoff"} ` }>
                            <input key={item._id} className="checkboxdata" value={item._id} onChange={this._handleChange.bind(this,item._id,item.status)} defaultChecked={(item.status === 'active') ? "checked": ""} type="checkbox"/>
                            <span className="slider round"></span>                      
                        </label>
                        )
                    }
                </td>
                <td className="tbl_org_description">
                    <ul className="actionsitem asset-actionsitem">
                        <li><a onClick={this.shareLink.bind(this,item.fileid,item.filename,item.isLinkCreated,item.isEmailRequired)}><img src={require('../../../assets/images/assets-icon/share.png')}/></a></li>
                        <li><a onClick={this.downloadarchive.bind(this,item.fileid,item.filetype,item.filename)}><img src={require('../../../assets/images/assets-icon/dwn.png')}/></a></li>
                        <li><a onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteassets(this,item.fileid,item.filetype) } } ><img src={require('../../../assets/images/assets-icon/delete.png')}/></a></li>
                    </ul>    
                </td>           
                {/* <td className="tbl_org_action TblIconDel"><i  className="fa fa-trash" aria-hidden="true" onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteProject(item, index) } } > <img src={require('../../../assets/images/delete-icon.png')}/> </i></td>  */}     
            </tr>
        });
        
        const columns = [
            {
                Header: '_id',
                accessor: '_id',
                show:false
            },            
            {
                Header: 'fileid',
                accessor: 'fileid',
                show:false
            },
            {
                Header: 'assetdata',
                accessor: 'assetdata',
                show:false
            },
            {
                Header: 'filetype',
                accessor: 'filetype',
                show:false
            },
            {
                Header: 'filecount',
                accessor: 'filecount',
                show:false
            },
            {
                Header: 'isfilemovedtoassets',
                accessor: 'isfilemovedtoassets',
                show:false
            },
            {
                Header: 'isEmailRequired',
                accessor: 'isEmailRequired',
                show:false
            },
            {
                Header: 'isLinkCreated',
                accessor: 'isLinkCreated',
                show:false
            },            
            {
                Header: 'Name',
                accessor: 'filename',
                className: 'tbl_org_name',
                Cell: ({ row }) => 
                    {
                    let name;
                    let file='';              
                    let videolink = row.assetdata;
                    if (row.filetype==='folder') {
                        name = <a onClick={this.handleClick.bind(this,row.fileid)} className="forcusror">{row.filename}</a>;
                        file =<img src={require('../../../assets/images/assets-icon/folder.png')} width="50" height="50" />
                      
                    } else {
                        
                        var filename = row.filename;
                        var filetype =filename.split(/\.(?=[^\.]+$)/);
                         if(filetype[1]==='jpg' || filetype[1]==='jpeg' || filetype[1]==='png')
                        {
                            file =<img key={videolink} src={videolink}  width="70" height="50" />                       
                        }
                        else if(filetype[1]==='png')
                        {
                            file =<img src={require('../../../assets/images/assets-icon/png.png')} width="50" height="50" />                       
                        }
                        else if(filetype[1]==='pdf')
                        {
                            file =<img src={require('../../../assets/images/assets-icon/pdf.jpg')} width="50" height="50" />                       
                        }
                        else if(filetype[1]==='mp3')
                        {
                            file =<img src={require('../../../assets/images/assets-icon/mp3.png')} width="50" height="50" />                       
                        }
                        else if(filetype[1]==='mp4'  || filetype[1]==='MP4')
                        {
                            if(!row.isfilemovedtoassets)
                            {
                                console.log('row.isfilemovedtoassets==',row.isfilemovedtoassets);
                                console.log('filetype[0]==',filetype[0]);
                                file =<img src={require('../../../assets/images/assets-icon/pending-video-thumbnail.png')} height="50" />                       
                            }
                            else
                            {
                                file =<video width="90" height="50" key={videolink}>
                                    <source key={videolink} src={videolink}
                                        type="video/mp4" />
                                        Sorry, your browser doesn't support embedded videos.
                                </video>
                            }
                        }
                        name = <Link to={`${usertype}/${this.props.orgid}/${row.fileid}/editfile`}>{filetype[0]}</Link>;               
                        videolink="";
                    }
                    return (
                        <div>{file}{name}</div>
                    )
                }
                
            },
            {
                Header: 'Updated',
                accessor: 'create_date',
                Cell: ({ row }) =>{
                    return (
                        <p>{dateFormat(row.create_date, "mm/dd/yyyy")}</p>
                    )
                }               
            },
            {
                Header: 'Size',
                accessor: 'assetsize',
                Cell: ({ row }) => 
                {
                    let filesize='';                 
                    if (row.filetype==='folder') {                       
                        filesize=<div>{row.filecount} Files</div>
                    } else {
                        if(row.assetsize)
                        filesize=<ByteToSize byte={row.assetsize} />
                        else
                        filesize='';
                    }
                    return (
                        <div>{filesize}</div>
                    )
                }
            },
            {
                Header: 'Status',
                accessor: 'status',
                sortable:false,
                filterable:false,
                Cell: ({ row }) => 
                {
                    if (row.filetype==='folder') {
                        return(<label labelfor={row._id} className={`switch ${(row.status === 'active') ? "switchon" : "switchoff"} ` }>
                            <input key={row._id} className="checkboxdata" value={row._id} onChange={this._handleChange.bind(this,row._id,row.status)} defaultChecked={(row.status === 'active') ? "checked": ""} type="checkbox"/>
                            <span className="slider round"></span>                      
                        </label>
                        )
                    }
                    else
                    return (<p></p>)
                }
               
            },
            {
                Header: 'Action',
                accessor: 'due_date',
                sortable:false,
                filterable:false,
                Cell: ({ row }) => 
                {
                    return(
                        <ul className="actionsitem asset-actionsitem">
                        <li><a onClick={this.shareLink.bind(this,row.fileid,row.filename,row.isLinkCreated,row.isEmailRequired)}><img src={require('../../../assets/images/assets-icon/share.png')}/></a></li>
                        <li><a onClick={this.downloadarchive.bind(this,row.fileid,row.filetype,row.filename)}><img src={require('../../../assets/images/assets-icon/dwn.png')}/></a></li>
                        <li><a onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteassets(this,row.fileid,row.filetype) } } ><img src={require('../../../assets/images/assets-icon/delete.png')}/></a></li>
                    </ul>
                    )
                }    
            },
          ];
        const data=this.state.projectList;
        return (
             <div className="container-fluid ProjectList TaskListPage"  ref="selfdiv">
                 {
                    this.state.loading ?
                        <div className="loaderspinner">
                            <Loader
                                type="TailSpin"
                                color="#D10000"
                                height={100}
                                width={100}
                            />
                        </div>
                        : null
                }
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        
                        <div className="row DragFileRow InviteSS">
                            <div className="col-6 DragFileBox mobileDisable LeftBoxalign">
                                <div className="successmsg">{this.state.assetuploadsuccessmsg}</div>                
                                                            

                                {
                                    this.state.assetuploadloader ?
                                        <div className="loaderspinner">
                                            <Loader
                                                type="TailSpin"
                                                color="#D10000"
                                                height={100}
                                                width={100}                              
                                            />
                                            <div id="loadingstatus"></div>                                           
                                        </div>
                                    :null      
                                }     
                                <Dropzone    
                                    //getUploadParams={getUploadParams}
                                    onChangeStatus={this.handleChangeStatus}
                                    onSubmit={this.handleSubmit}
                                    //accept="image/*,video/*"
                                    />                                
                            </div>
             
                        </div>
                    </div>
                    <div className="panel-body">
                        <div className="row">
                            {/* <table className="table table-striped ListTable assetlist">
                                <thead>
                                    <tr>   
                                        <th className="tbl_org_name"><b>Name</b></th>                                        
                                        <th className="tbl_org_duedate"><b>Updated</b></th>
                                        <th className="tbl_org_action"><b>Size</b></th>
                                        <th className="tbl_org_action"><b>Status</b></th>                                   
                                        <th className="tbl_org_action"><b>Action</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listItems}                         
                                </tbody>
                            </table> */}
                            <ReactTable
                                data={data}                                
                                minRows = {0}
                                pageSizeOptions = {[10,50,100]}
                                defaultPageSize = {10}                         
                                columns={columns}
                                getTrProps={this.getTrProps}
                                className="-striped -highlight table table-striped ListTable assetlist"
                            />
                        </div>
                        {/* <div className="Mobrow">
                            {
                                (this.state.projectList.length>0 &&
                                <Pagination
                                    activePage={this.state.activePage} 
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.projectList.length}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                                )
                            }
                        </div> */}
                        
                        
                    </div>
                </div>
            </div>
        );
    }
}
export default ProjectAssets;