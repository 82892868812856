import React, { Component } from "react";
import {PostData,GetData} from '../services/PostData';
import Loader from 'react-loader-spinner';
import {apiConfig} from "../config/api";
import { Button, Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';
import AddLogo from "./StyleDesign/AddLogo";
import AddFont from "./StyleDesign/AddFont";
import AddColor from "./StyleDesign/AddColor";
import ReactTable from "react-table";
import "react-table/react-table.css";
import dateFormat from 'dateformat';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

class StyleDesign extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(){
		super();
		this.state = {
			loading: false,
			logos:[],
			font:[],
			color: '#aabbcc',
			logolist: [],
			fontlist: [],
			completedvideolist: [],
			showAddToProject:false,
			projectlist:[],
			showStyleAddToProject:false
		};
		this.onChange = this.onChange.bind(this);
		this.onfileChangeHandler = this.onfileChangeHandler.bind(this);
		this.onfontfileChangeHandler = this.onfontfileChangeHandler.bind(this);
		this.submit = this.submit.bind(this);
	};

	submit() {
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		const data = new FormData();
		data.append('org_id',  this.props.match.params.orgid);
		if(this.state.colornotes)
        data.append('colornotes',  this.state.colornotes);
        if(this.state.logonotes)
        data.append('logonotes',  this.state.logonotes);
    	if(this.state.fontnotes)
        data.append('fontnotes',  this.state.fontnotes);
		data.append('userid',  userdata.user.ID)
		let session_key = userdata.token;
		fetch(apiConfig.WEB_SERVICE_URL+'savestyledesign', {
		method: 'POST',
		body: data,
		headers: {
				"session-key": session_key,
			}
		})
		.then((response) => response.json())
		.then((res) => {
			if(res.statusCode===200)
			{	
				console.log('res====',res);
				//this.props.view('viewprojectlist');	
				this.setState({ sucsmsg: 'File uploaded Successfully' });
				setTimeout(() => {
					this.setState({ sucsmsg: '' });
					window.location.reload();
				}
				, 3000);
			}
		});
	}

	onfileChangeHandler=event=>{
		this.setState({
			'logos': event.target.files,
			loaded: 0,
		})
	}
	onfontfileChangeHandler=event=>{
		this.setState({
			'font': event.target.files,
			loaded: 0,
		})
	}

	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	componentDidMount() {
		this.setState({loading:true});
		Promise.all([this.getProjectList(),this.getStyleDesign(),this.getCompletedVideoList()])
            .then(([projectdata,styledata,videolistdata]) => {
            this.setState({loading:false}); 
            if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data});
            }   
            if(styledata.statuscode===200 && styledata.styledesign)   
            {
                this.setState({
					colordata:styledata.styledesign.colors,
					fontdata:styledata.styledesign.fonts,
					colornotes:styledata.styledesign.colornotes,
					logonotes:styledata.styledesign.logonotes,
					fontnotes:styledata.styledesign.fontnotes,
					logodata:styledata.styledesign.logos,
				});
            }
            if(videolistdata.statuscode===200)   
            {
            	this.setState({completedvideolist:videolistdata.videodata});
            }
            
        });

	}
	getStyleDesign(){
        //console.log('calleddd=');
        return GetData('getstyledesign/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    getCompletedVideoList(){
        //console.log('calleddd=');
        return GetData('completedvideolist/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
	getProjectList(){
        //console.log('calleddd=');
        return GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
	handleVideoChange(i, e) {
		var index = e.target.selectedIndex;
		var optionElement = e.target.childNodes[index]
		var option = optionElement.getAttribute('data-id');

		let tasks = [...this.state.tasks];
		tasks[i] = { ...tasks[i], userid: option };
		tasks[i] = { ...tasks[i], teammember: e.target.value };
		this.setState({ tasks });
	}
	handleProjectChange(e){
        //console.log('selectedproject==',e.target.value);
        this.setState({ selectedproject: e.target.value });
    }
	
	addToProject(filename,videoid,projectid,e){
		console.log('projectid====',projectid);
        this.setState({ 
        showAddToProject: true,
        filename:filename,
        video_vimeo_id:videoid,
        project_id:projectid
        });
    }
    handleAddToProjectClose(e) {
        this.setState({
            showAddToProject: false,
        });
    }
    addStyleDeisgnVideoToProject(e){
        let filedetails = this.state.filedetails;
        //console.log('filedetails==',filedetails);
        if(this.state.selectedproject=='')
        {
            this.setState({
                errormsg:'Please select Project'
            })
        }
        else
        {
            this.setState({
                errormsg:''
            })
            let data = {
                'filename': this.state.filename,
                'video_vimeo_id': this.state.video_vimeo_id,
                'videotype': 'StyleDesign',
                'project_id': this.state.selectedproject,
            }
            //console.log('data==',data);
            PostData('addstyledesignvideotopboard', data).then((result) => {
               // console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
                }
            });
        }

    }
    addStyleToProject(e){
    	let filedetails = this.state.filedetails;
        //console.log('filedetails==',filedetails);
        if(this.state.selectedproject=='')
        {
            this.setState({
                errormsg:'Please select Project'
            })
        }
        else
        {
            this.setState({
                errormsg:''
            })

            let data;
            if(this.state.selectedtype=='color'){
                data = {
                    'type': this.state.selectedtype,
                    'colorcode': this.state.colorcode,
                    'name': this.state.name,
                    'project_id': this.state.selectedproject,
                }
            } else {
                data = {
                    'type': this.state.selectedtype,
                    'filename': this.state.selectedfile,
                    'name': this.state.name,
                    'project_id': this.state.selectedproject,
                }
            }
            //console.log('data==',data);
            PostData('addstyletopboard', data).then((result) => {
               // console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
                }
            });
        }
    }
    handleType(e) {
    	let currdate=Date.now();
		this.setState({ scheduletype: e.currentTarget.dataset.id });
		if(e.currentTarget.dataset.id == 'Logos') {
			this.setState({ setLogoShow: true, isAddLogo:currdate });
		} else if (e.currentTarget.dataset.id == 'Fonts') {
			this.setState({ setFontShow: true, isAddFont:currdate });
		} else if (e.currentTarget.dataset.id == 'Colors') {
			this.setState({ setColorShow: true, isAddColor:currdate });
		}
	}
	addtoproject(event,type,filename,name){
		this.setState({
            showStyleAddToProject: true,
            selectedtype: type,
            selectedfile: filename,
            name:name
        });
	}

    coloraddtoproject(event,type,colorcode,name){
        this.setState({
            showStyleAddToProject: true,
            selectedtype: type,
            colorcode: colorcode,
            name:name
        });
    }

	handleStyleAddToProjectClose(){
		this.setState({
            showStyleAddToProject: false,
        });
	}

	deleteDesignByType(event,type,filename){
        console.log('folderid,filename===',filename);
        this.setState( { loading:true } );
        let data = {
            'filename': filename,
            'org_id': this.props.match.params.orgid,
            'type': type
        }
        console.log('data===',data);        
        PostData('deletedesignbytype', data).then((result) => {
            console.log('result===',result);
            if (result.statusCode === 200) {
                this.setState({
                    loading:false,                
                });  
                window.location.reload();                                   
            }
        });
    }
	render() {		
    	
		const files = this.state.logos ? [...this.state.logos] : [];
		const fonts = this.state.font ? [...this.state.font] : [];
		const completedvideo = [];
		const completedvideolist = this.state.completedvideolist;
		console.log('completedvideolist=',completedvideolist);
		let videoarray = completedvideolist.map((item) => { 
			if(item.draft_vimeo_id) {
				let videourl = 'https://player.vimeo.com/video/'+item.draft_vimeo_id;
				let vimeoid = item.draft_vimeo_id;
				let projectid = item.project_id;
				let filename = item.draftfile;
	           return <>
					{/* <div className="col-8 video-main-div">
						<div className="project-title">
							<div className="video-inner-heading">Project Title:</div>
							<div>{item.project}</div>
						</div>
						<div className="project-editor">
							<div className="video-inner-heading">Editor:</div>
							<div>{item.boarduser}</div>
						</div>
						<iframe src={videourl} width="100%" height="440" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
						<div className="video-add-btn-div">
							<a className="btn btn-primary addbtn addvideobtn" onClick={this.addToProject.bind(this,filename,vimeoid,projectid)}>Add To Project</a>
						</div>
						<br/>
					</div>
					<div className="col-4">
					</div> */}
					<Col xs={12} md={12} lg={6} xxl={6} xl={6}>
						<div className="video-main-div">
							<iframe src={videourl} width="100%" height="350" frameborder="0" allow=" fullscreen" allowfullscreen></iframe>
							<div className="videomaindivinner">
								<div>
									<div className="project-title">
										<div className="video-inner-heading">Project Title:</div>
										<div>{item.project}</div>
									</div>
									<div className="project-editor">
										<div className="video-inner-heading">Editor:</div>
										<div>{item.boarduser}</div>
									</div>
								</div>
								<div className="video-add-btn-div">
									<a className="btn btn-primary addbtn addvideobtn" onClick={this.addToProject.bind(this,filename,vimeoid,projectid)}>Add To Project</a>
								</div>
							</div>
							<br/>
						</div>
					</Col>
			    </>
			}
       });
       let projects = this.state.projectlist.map((item, i) => {
            return <option className="folderlistitem" value={item._id}>
                {item.project_name}
            </option>;
        });

        const logocolumns = [
        	{
                Header: 'filename',
                accessor: 'filename',
                show:false
            },
            {
	            Header: 'Name',
	            accessor: 'logoname',
	            className: 'tbl_org_name',
	            Cell: ({ row }) => 
	                {
	                let name = row.logoname; 
                    var filename = row.filename;
                    let checkfiletype =row.filename.split(/\.(?=[^\.]+$)/);
                    let filetype = checkfiletype[1];
                    let file;
                    if(filetype=='png' || filetype=='PNG' || filetype=='jpeg' || filetype=='jpg')
                    file =<img src={require('../assets/styledesign/'+filename)} width="50" height="50" />                        
	                else if(filetype=='ai')
                    file =<img src={require('../assets/images/illustrator.png')} width="50" height="50" />    
	                else if(filetype=='psd')
                    file =<img src={require('../assets/images/photoshop.png')} width="50" height="50" />
                    else if(filetype=='eps')
                    file =<img src={require('../assets/images/eps-file-format.png')} width="50" height="50" />
                    else if(filetype=='pdf')
                    file =<img src={require('../assets/images/pdf.png')} width="50" height="50" />
                    else 
                    file='';
	                return (
	                    <div>{file}<span className="childlist1">{name}</span></div>
	                )
	            }
	            
	        },
	        {
                Header: 'Type',
                accessor: 'type',
                show:true
            },
            {
                Header: 'Action',
                accessor: 'due_date',
                sortable:false,
                filterable:false,
                Cell: ({ row }) => 
                {
                	var filename = row.filename;
                    return(
                        <ul className="actionsitem asset-actionsitem">
                        <li className="iconLiDownload">
                        	<a href={require('../assets/styledesign/'+filename)} download>
                        		<img className="table-icon" src={require('../assets/images/assets-icon/3401819_download_essential_web_icon.png')}/>
                        	</a>
                        </li>
                        <li className="iconLiDelete">
                        	<a onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteDesignByType(this,'logo',row.filename) } } >
                        		<img className="table-icon" src={require('../assets/images/assets-icon/3401868_bin_essential_trash_web_icon.png')}/>
                        	</a>
                        </li>
                        <li className="iconLiDelete">
                        	<a onClick={(e) => {  this.addtoproject(this,'logo',row.filename,row.logoname) } } >
                        		Add To Project
                        	</a>
                        </li>
                    </ul>
                    )
                }    
            },
        ];
        const fontcolumns = [
        	{
                Header: 'filename',
                accessor: 'filename',
                show:false
            },
            {
                Header: 'Name',
                accessor: 'fontname',
                show:true,
                Cell: ({ row }) => 
                    {
                    let name = row.fontname; 
                    let file;
                    file =<img src={require('../assets/images/font.png')} width="50" height="50" />
                    return (
                        <div>{file}<span className="childlist1">{name}</span></div>
                    )
                }
            },
            {
                Header: 'Type',
                accessor: 'type',
                show:true
            },
            {
                Header: 'Action',
                accessor: 'due_date',
                sortable:false,
                filterable:false,
                Cell: ({ row }) => 
                {
                	var filename = row.filename;
                    return(
                        <ul className="actionsitem asset-actionsitem">
                        <li className="iconLiDownload">
                        	<a href={require('../assets/styledesign/'+filename)} download>
                        		<img className="table-icon" src={require('../assets/images/assets-icon/3401819_download_essential_web_icon.png')}/>
                        	</a>
                        </li>
                        <li className="iconLiDelete" >
                        	<a onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteDesignByType(this,'font',row.filename) } } >
                        		<img className="table-icon" src={require('../assets/images/assets-icon/3401868_bin_essential_trash_web_icon.png')}/>
                        	</a>
                        </li>
                        <li className="iconLiDelete">
                            <a onClick={(e) => {  this.addtoproject(this,'font',row.filename,row.fontname) } } >
                                Add To Project
                            </a>
                        </li>
                    </ul>
                    )
                }    
            },
        ];

        const colorcolumns = [
            {
                Header: 'Color Name',
                accessor: 'colorname',
                show:true,
                Cell: ({ row }) => 
                    {
                    let name = row.colorname; 
                    let colorcode = row.colorcode; 
                    let file;
                    file =<div className="colorbg" style={{width: 50, height: 50,backgroundColor: colorcode}}/>
                    return (
                        <div>{file}<span className="childlist1 colorname">{name}</span></div>
                    )
                }
            },
            {
                Header: 'Color Code',
                accessor: 'colorcode',
                show:true
            },
            {
                Header: 'Type',
                accessor: 'type',
                show:true
            },
            {
                Header: 'Action',
                accessor: 'due_date',
                sortable:false,
                filterable:false,
                Cell: ({ row }) => 
                {
                	var filename = row.filename;
                    return(
                        <ul className="actionsitem asset-actionsitem">
                        <li>
                        	<a onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteDesignByType(this,'color',row.colorcode) } } >
                        		<img className="table-icon-single" src={require('../assets/images/assets-icon/3401868_bin_essential_trash_web_icon.png')}/>
                        	</a>
                        </li>
                        <li className="iconLiDelete">
                            <a onClick={(e) => {  this.coloraddtoproject(this,'color',row.colorcode,row.colorname) } } >
                                Add To Project
                            </a>
                        </li>
                    </ul>
                    )
                }    
            },
        ];
        const logodata = this.state.logodata?this.state.logodata:[];
        const fontdata = this.state.fontdata?this.state.fontdata:[];
        const colordata = this.state.colordata?this.state.colordata:[];
        return (
        	<div className="container-fluid AddProjectPage MobPaddHide TaskListPage"  ref="selfdiv">
				<div className="panel panel-primary closedcaptioning">
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}
								/>
							</div>
						:null
					}
					<div className="panel-heading">
						<div className="panel-heading">
							<div className="row top-page-heading">
								<div className="col-12" style={{padding: "0px"}}>
									<img src={require('../assets/img/flat-lay-desk-with-coffee-keyboard.jpg')} style={{width: "100%",  height: "280px", objectFit: "cover", objectPosition: "0 81%"}} />
									<h4 class="PageTopTitle stylePageTitle">Style Guide</h4>
								</div>
								<div className="dropdown col-12 RightTopMenu text-align-right" style={{ paddingTop: "35px"}}>
									<Dropdown>
										<Dropdown.Toggle variant="success1" id="dropdown-basic">
											<img className="dropdown-icon" src={require('../assets/images/upload-icon.png')} /> Add
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item onClick={this.handleType.bind(this)} data-id="Logos">Logo</Dropdown.Item>
											<Dropdown.Item onClick={this.handleType.bind(this)} data-id="Colors">Color</Dropdown.Item>
											<Dropdown.Item onClick={this.handleType.bind(this)} data-id="Fonts">Font</Dropdown.Item>
											{/* <Dropdown.Item onClick={this.handleType.bind(this)} data-id="Imagery">Imagery</Dropdown.Item> */}
										</Dropdown.Menu>
									</Dropdown>
								</div>
							</div>
						</div>
					</div><br/><br/>
	        		<div className="PrimaryWrap container-fluid video-data">
						<div className="row FormDesign">
							{/* <div className="dropdown col-12 RightTopMenu text-align-left">
								<Dropdown>
									<Dropdown.Toggle variant="success1" id="dropdown-basic">
										<img className="dropdown-icon" src={require('../assets/images/upload-icon.png')} /> Add
									</Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item onClick={this.handleType.bind(this)} data-id="Logos">Logo</Dropdown.Item>
										<Dropdown.Item onClick={this.handleType.bind(this)} data-id="Colors">Color</Dropdown.Item>
										<Dropdown.Item onClick={this.handleType.bind(this)} data-id="Fonts">Font</Dropdown.Item>
										
									</Dropdown.Menu>
								</Dropdown>
							</div> */}
							<div className="col-12 heading-content-div">
								<label className="label-heading" for="projectname">Logo</label>
								<div className="row table-main-div">
                                    {logodata && logodata.length>0 &&
    		                            <ReactTable
    		                                data={logodata} 
    		                                showPagination= {false}                           
    		                                minRows = {0}
    		                               // pageSizeOptions = {[10,50,100]}
    		                               // defaultPageSize = {10}                         
    		                                columns={logocolumns}
    		                               // getTrProps={this.getTrProps}
    		                                className="-striped -highlight table table-striped ListTable assetlist"
    		                            />
                                    }
		                        </div>
							</div>
							<div className="col-12 formarginbtmnote">
								<label for="projectname">Notes:</label>
								<textarea value={this.state.logonotes} onChange={this.onChange} className="PB_Title form-control" name="logonotes"/>
							</div>
							<div className="col-12 add-note-div">
								<button type="submit" onClick={this.submit} className=" UpdateBtn btn btn-primary formarginbtm noteBtn">Add Notes</button> 
							</div>
							<br/>
							<div className="col-12 heading-content-div">
								<label className="label-heading" for="projectname">Color</label>
								<div className="row table-main-div">
                                    {colordata && colordata.length>0 &&
    		                            <ReactTable
    		                                data={colordata} 
    		                                showPagination= {false}                           
    		                                minRows = {0}
    		                               // pageSizeOptions = {[10,50,100]}
    		                               // defaultPageSize = {10}                         
    		                                columns={colorcolumns}
    		                               // getTrProps={this.getTrProps}
    		                                className="-striped -highlight table table-striped ListTable assetlist"
    		                            />
                                    }
		                        </div>
							</div>
							<div className="col-12 formarginbtmnote">
								<label for="projectname">Notes:</label>
								<textarea value={this.state.colornotes} onChange={this.onChange} className="PB_Title form-control" name="colornotes"/>
							</div>
							<div className="col-12 add-note-div">
								<button type="submit" onClick={this.submit} className=" UpdateBtn btn btn-primary formarginbtm noteBtn">Add Notes</button> 
							</div>
							<br/>
							<div className="col-12 heading-content-div">
								<label className="label-heading" for="projectname">Font</label>
								<div className="row table-main-div">
                                    {fontdata && fontdata.length>0 &&
    		                            <ReactTable
    		                                data={fontdata} 
    		                                showPagination= {false}                           
    		                                minRows = {0}
    		                               // pageSizeOptions = {[10,50,100]}
    		                               // defaultPageSize = {10}                         
    		                                columns={fontcolumns}
    		                               // getTrProps={this.getTrProps}
    		                                className="-striped -highlight table table-striped ListTable assetlist"
    		                            />
                                    }
		                        </div>
							</div>
							<div className="col-12 formarginbtmnote">
								<label for="projectname">Notes:</label>
								<textarea value={this.state.fontnotes} onChange={this.onChange} className="PB_Title form-control" name="fontnotes"/>
							</div>
							<div className="col-12 add-note-div">
								<button type="submit" onClick={this.submit} className=" UpdateBtn btn btn-primary formarginbtm noteBtn">Add Notes</button> 
							</div>
							<br/>
							{/*<div className="col-12">
								<label for="projectname">Notes:</label>
								<textarea value={this.state.notes} onChange={this.onChange} className="PB_Title form-control" name="notes"/>
							</div>*/}<br/>
							<div className="col-12 heading-content-div">
								<label className="label-heading" for="projectname">Videos</label>
								<div style={{marginTop: "5px"}}>
									<Row xs={1} md={2} lg={2} xxl={2} xl={2}>
										{videoarray}
									</Row>
								</div>
								{/* <div className="row">
						        		{videoarray}
						        </div> */}
							</div>
							{/*<div className="col-12">
								<button type="submit" onClick={this.submit} className="CPRadioBtn UpdateBtn btn btn-primary">Submit</button> 
							</div>*/}
						</div>                     
		        	</div>
		        </div>
		         <Modal show={this.state.showAddToProject}>
                    {
                        this.state.loading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Add To Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="error-msg">{this.state.errormsg}</div>
                        <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                            <option value=" ">Select Project</option>
                            {projects}
                            {/* <option value="Active">Active</option>
                            <option value="On-hold">On-hold</option>
                            <option value="Completed">Completed</option> */}
                        </select>
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleAddToProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.addStyleDeisgnVideoToProject.bind(this)}>
                            Add To Project
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                <Modal show={this.state.showStyleAddToProject}>
                    {
                        this.state.loading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Add To Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="error-msg">{this.state.errormsg}</div>
                        <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                            <option value=" ">Select Project</option>
                            {projects}
                        </select>
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleStyleAddToProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.addStyleToProject.bind(this)}>
                            Add To Project
                        </Button>
                    </Modal.Footer>
                </Modal>
                {
					(this.state.setLogoShow &&
						<AddLogo  orgid={this.props.match.params.orgid}  handleAddLogoShow={this.state.setLogoShow} isAddLogo={this.state.isAddLogo} />
					)
				}
				{
					(this.state.setFontShow &&
						<AddFont  orgid={this.props.match.params.orgid}  handleAddFontShow={this.state.setFontShow} isAddFont={this.state.isAddFont} />
					)
				}
				{
					(this.state.setColorShow &&
						<AddColor orgid={this.props.match.params.orgid}  handleAddColorShow={this.state.setColorShow} isAddColor={this.state.isAddColor} />
					)
				}							
			</div>
		);
    }
}
export default StyleDesign;
