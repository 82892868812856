import React, {Component} from 'react';
import logo from '../assets/images/logo.png'; 
import backbtnicon from '../assets/images/back-btn-icon.png';
import homebtnicon from '../assets/images/home-btn-icon.png';
import {  Dropdown } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import {GetData} from '../services/PostData';
class ProfileSidebar extends Component{
	constructor(props){
		//console.log('propsssssss=',props);
		super(props);
		this.state = {
			//org_id:props.orgid
		};
		//this.renderActiveProjects = this.renderActiveProjects.bind(this);
		this.openNav = this.openNav.bind(this);
		this.closeNav = this.closeNav.bind(this);
	}
	openNav() {
		document.getElementById("mySidebar").style.width = "20%";
		document.getElementById("main").style.marginLeft = "20%";
		document.getElementById("mySidebar").classList.add('OpenSidebar');
		document.getElementById("main").classList.add('FullWrapper');
		//this.renderActiveProjects();
	}
	closeNav() {
		document.getElementById("mySidebar").style.width = "0";
		document.getElementById("main").style.marginLeft= "0";
		document.getElementById("mySidebar").classList.remove('OpenSidebar');
		document.getElementById("main").classList.remove('FullWrapper');
	}
	componentDidMount() {
		
	}

    render(){
		let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		//let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
    	if(checkuser.user.ID==15888 || checkuser.user.ID==12612 || checkuser.user.ID==28 || checkuser.user.ID==202371015253)
		{
			localStorage.setItem('userData','');
			return (<Redirect to={'/'}/>)
		}
		//console.log('checkuser=',checkuser.user._id);
		let dashlink;
		if(checkuser.user.ID==26 || checkuser.user.ID==5699)
		dashlink = `/productionteam/list`;
		else
		dashlink = `/productionteam/view/`+checkuser.user._id;	
    	return (           
 			<div id="mySidebar" className="sidebar">
		        <button className="openbtn" onClick={this.openNav}>
		       	 <img src={require('../assets/images/toggle-icon.png')} />
		        </button>
		        <div className="SidebarInner"> 
		        <a href="javascript:void(0)" className="closebtn" onClick={this.closeNav}><img src={require('../assets/images/toggle-icon.png')}/>
		         </a>
		          <img className="AS_logo_sidebar img-responsive" src={require('../assets/images/sidebar-logo.png')}/>
		          <ul className="SideIconNav">
		          	<li><Link to={dashlink}> <img className="MenuIcon" src={require('../assets/images/menu-icons/dash-icon.png')}/> Dashboard </Link></li>
					<li><Link to={`/calendar`}> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-calender.png')}/> Calendars </Link></li>
					<li><Link to={`/inbox/list`}> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-inbox.png')}/> Inbox</Link></li>
					<li><Link to='/organization/list'> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-organizations.png')}/> Organizations</Link></li>
					{(
					checkuser.user.ID==26 &&
					<li><Link to='/profitability-measurement'> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-organizations.png')}/> Profitability Measurement</Link></li>
					)}
					{/* <li><Link to='/organization/list'>Tasks</Link></li>*/}
			        <li><Link to="/logout"> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-logout.png')}/> Logout</Link></li>
			       </ul>
		        </div>
		    </div> 
		)
    }
}
export default ProfileSidebar;