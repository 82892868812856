import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import dateFormat from 'dateformat';
class ReplyVideographyForm extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			setReplyVideographyShow:this.props.handleReplyVideographyShow,
			selectedTP: "",
			arrivalDate: new Date(),
			departureDate: new Date(),
			location:'',
			notes:'',
			onsitecontact:'',
			mobilenumber:'',
			vg_emailaddress:'',
			travelexpenses:'',
			vehiclename:'',
			arrdate:'',
			deptdate:''
		};
		this.handleArrivalChange = this.handleArrivalChange.bind(this);
		this.handleDepartureChange = this.handleDepartureChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onMileChange = this.onMileChange.bind(this); 
		this.onRadioTravelReqChange= this.onRadioTravelReqChange.bind(this);
		console.log('replydata==',this.props.replydata);
	}
	
	onRadioTravelReqChange(e){
		this.setState({[e.target.name]:e.target.value});
		console.log('check valeeeeeeeee===',e.target.value);
	}

	handleArrivalChange(date) {  
	    this.setState({  
	      arrdate: date  
	    })  
	}
	handleDepartureChange(date) {  
	    this.setState({  
	      deptdate: date  
	    })  
	}
	componentDidMount() {		
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let name=userdata.user.first_name+" "+userdata.user.last_name;
		let email=userdata.user.email;

		const arrdate = new Date(this.props.replydata.arrive);
		const deptdate = new Date(this.props.replydata.depart);
		console.log('name==',name); 
		console.log('email==',email); 
		this.setState({
		  videographer: name,
	      vg_emailaddress: email,
	      arrdate:arrdate,
	      deptdate:deptdate
	    }) 
	}
	componentDidUpdate(prevProps) {
		if (prevProps.replyvideographyTime !== this.props.replyvideographyTime) {
			this.setState({setReplyVideographyShow:this.props.replyvideographyTime})		
		}
	}	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	onMileChange (e) {
		console.log('totalexpenses=',e.target.value);
		let mile = e.target.value; 
		let totalexpenses = mile*.656;
		totalexpenses = totalexpenses.toFixed(2)
		this.setState({travelexpenses:totalexpenses,mile:e.target.value});
	}
	notesChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	handleReplyVideographyClose(e) {
		this.setState({ setReplyVideographyShow: false });
	}
	saveReplyVideoGraphyRequest(e) {
		if (this.validateSaveReplyVideoGraphyRequestForm()) {
			//let selectedTP = this.state.selectedTP.toString();
			this.setState({ Inloading: true });
			let data = {
				'orgid': this.props.orgid,
				'projectid': this.props.prjid,
				'location': this.props.replydata.location,
				'purpose': this.props.replydata.purpose,
				'arrivalDate': this.props.replydata.arrive,
				'departureDate': this.props.replydata.depart,
				'notes': this.props.replydata.comment,
				'userid':this.props.userid,
				'customerid':this.props.replydata.userid,
				'onsitecontact':this.props.replydata.onsitecontact,
				'onsitemobilenumber':this.props.replydata.mobilenumber,
				'onsiteemailaddress':this.props.replydata.emailaddress,
				'videographername':this.state.videographer,
				'vg_emailaddress':this.state.vg_emailaddress,
				'primarytransportation':this.state.selectedTP,
				'schedarrivalDate':this.state.arrivalDate,
				'scheddepartureDate':this.state.departureDate,
				'flightexpense':this.state.flightexpense,
				'driveexpense':this.state.driveexpense,//mile
				'travelexpenses':this.state.travelexpenses,
				'trainexpense':this.state.trainexpense,
				'type':'Reply Videography Request',
				'hotelnight':this.state.hotelnight,
				'travelrequired':this.state.travelrequired,
				'vehiclename':this.state.vehiclename

			}
			console.log('data',data);
			this.postReplyVideoGraphyRequest(data);
		}
	}
	validateSaveReplyVideoGraphyRequestForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["videographer"]) {
			formIsValid = false;
			errors["videographer"] = "*Please add Videographer Name";
		}
		  
		if (typeof fields["vg_emailaddress"] !== "undefined") {
			//regular expression for email validation
			var email = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; 
			if (!email.test(fields["vg_emailaddress"])) {
				formIsValid = false;
				errors["vg_emailaddress"] = "*Please enter valid Email Address.";
			}
		} 
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	postReplyVideoGraphyRequest(data)
	{
		this.setState({ Inloading: true });
		PostData('savereplyvideographyrequest', data).then((result) => {
			if (result.statuscode === 200) {				
				this.setState({ savevideographsucsmsg: "Videography Reply Added Succesfully", selectedTP: "", flightexpense:'',vehiclename:'',mile:'',travelexpenses:'',emailaddress:'' });
				setTimeout(() => {
					this.setState({  savevideographsucsmsg: '',setReplyVideographyShow: false });
					this.props.loadProjectBoard();
				}, 3000);
			}
			this.setState({ Inloading: false });
		});
	}
	onTPChange = text => {
		//const selectedTP = this.state.selectedTP;

		// Find index
		//const findIdx = selectedTP.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		//if (findIdx > -1) {
		//  selectedTP.splice(findIdx, 1);
		//} else {
		//  selectedTP.push(text);
		//}
        console.log('selectedTP==',text);
		this.setState({
		  selectedTP: text
		});
	};
	onVehicleChange = text => {
		//const selectedVehicle = this.state.selectedVehicle;
        console.log('selectedVehicle==',text);
		this.setState({
		  vehiclename: text
		});
	};
	handleHotelNightChange(e) {
		this.setState({ hotelnight: e.target.value });
	}
    render() {
		console.log('called every time');
		const primaryTPCheckboxes = [
		  { id: 1, text: "Fly" },
		  { id: 2, text: "Drive" },
		  { id: 3, text: "Train" },
		  { id: 4, text: "Multiple" },
		];
		const vehicleCheckboxes = [
		  { id: 1, text: "Personal" },
		  { id: 2, text: "Rental" }
		];
		return (
        	<Modal show={this.state.setReplyVideographyShow}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title> Travel Authorization Request Form</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.savevideographsucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					
					<div className="row CreateProRadioBox">
						<div className="col-sm-6">
							<label for="projectname" class="ParentLab" >Purpose : {this.props.replydata.purpose}</label>
						</div>
						<div className="col-sm-6">
							<label for="projectname" class="ParentLab" >Location : {this.props.replydata.location}</label>
						</div>
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-sm-6">
							<label for="projectname" class="ParentLab" >Arrival Date: {dateFormat(this.props.replydata.arrive, "mmmm dS, yyyy")}</label>
						</div>
						<div className="col-sm-6">
							<label for="projectname" class="ParentLab" >Departure Date: {dateFormat(this.props.replydata.depart, "mmmm dS, yyyy")}</label>
						</div>
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-12">
							<label for="projectname" class="ParentLab" >Notes: {this.props.replydata.comment}</label>
						</div>
					</div>
					<div className="row CreateProRadioBox">
						<div className="col-4">
							<label for="projectname" class="ParentLab" >Contact: {this.props.replydata.onsitecontact}</label>
						</div>
						<div className="col-4">
							<label for="projectname" class="ParentLab" >Mobile: {this.props.replydata.mobilenumber}</label>
						</div>
						<div className="col-4">
							<label for="projectname" style={{wordBreak: 'break-word'}} class="ParentLab" >Email: {this.props.replydata.emailaddress}</label>
						</div>
					</div>

					<div className="row CreateProRadioBox">
						<div className="col-sm-12">
							<label for="projectname" class="ParentLab" > Travel required?</label>
						</div>
						<div className="col-sm-6">					
							<label className="radio-inline">
								<input type="radio" onChange={this.onRadioTravelReqChange} value="Yes" name="travelrequired"/>&nbsp;Yes
							</label>
						</div>
						<div className="col-sm-6">
							<label className="radio-inline">
								<input type="radio" onChange={this.onRadioTravelReqChange}  value="No" name="travelrequired"/>&nbsp;No
							</label>	
						</div>
					</div>
					{
						this.state.travelrequired=="Yes" &&
						(
							<>
							<div className="row CreateProRadioBox">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Hotel Night(s)</label>
									<select name="hotelnight" className="form-control" value={this.state.hotelnight} onChange={this.handleHotelNightChange.bind(this)} >
										<option value=" ">Select Hotel Night(s)</option>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>
										<option value="10">10</option>
									</select>
								</div>
							</div>
							</>
						)
					}
					{
						this.state.travelrequired=="Yes" &&
						(
							<div className="row CreateProRadioBox">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Primary Transportation</label>
								</div>
								
								{primaryTPCheckboxes.map(pt => (
									<div className="col-sm-3 hideinputbox">
							          <label key={pt.id}>
							            <input
							              type="radio"
							              name="selectedTP"
							              onChange={() => this.onTPChange(pt.text)}
							              //selected={selectedTP.includes(pt.text)}
							            /> {pt.text}								            
							          </label>
							         </div>
						        ))}	
						    </div>
						)
					}
					<div className="row CreateProRadioBox">
						<div className="col-sm-6" style={{position:"inherit"}}>
							<label for="projectname" class="ParentLab" >Scheduled Arrival Date</label>
							<DatePicker
						      selected={ this.state.arrdate }
						      onChange={ this.handleArrivalChange }
						      showTimeSelect
						      dateFormat="MMMM d, yyyy h:mm aa"
						    />
						    <div className="errorMsg">{this.state.errors.arrivalDate}</div>
						</div>
						<div className="col-sm-6" style={{position:"inherit"}}>
							<label for="projectname" class="ParentLab" >Scheduled Departure Date</label>
							<DatePicker
						      selected={ this.state.deptdate }
						      onChange={ this.handleDepartureChange }
						      showTimeSelect
						      dateFormat="MMMM d, yyyy h:mm aa"
					    	/>
					    	<div className="errorMsg">{this.state.errors.departureDate}</div>
						</div>
					</div>
					{ this.state.selectedTP!='' &&
						(
						<>
						
						{ (this.state.selectedTP=='Fly' || this.state.selectedTP=='Multiple') &&
							(<div className="row CreateProRadioBox forprefixrow">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Flight Expenses</label>
									<div class="input-group prefix">                           
								        <span class="input-group-addon">$</span>
								        <input type="text" name="flightexpense" onChange={this.onChange} className="PB_Title form-control" autoFocus />
								    </div>
								</div>
							</div>
							)
						}
						{ (this.state.selectedTP=='Train' || this.state.selectedTP=='Multiple') &&
							(<div className="row CreateProRadioBox forprefixrow">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Train Expenses</label>
									<div class="input-group prefix">                           
								        <span class="input-group-addon">$</span>
								        <input type="text" name="trainexpense" onChange={this.onChange} className="PB_Title form-control" autoFocus />
								    </div>
								</div>
							</div>
							)
						}
						{ (this.state.selectedTP=='Drive' || this.state.selectedTP=='Multiple') &&
							(
							<>
							<div className="row CreateProRadioBox">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Vehicle</label>
								</div>
								
								{vehicleCheckboxes.map(vehicle => (
									<div className="col-sm-6 hideinputbox">
							          <label key={vehicle.id}>
							            <input
							              name="vehiclename"
							              type="radio"
							              onChange={() => this.onVehicleChange(vehicle.text)}
							             // selected={selectedVehicle.includes(vehicle.text)}
							            /> {vehicle.text}								            
							          </label>
							         </div>
						        ))}	
						        						
							</div>
							{ (this.state.vehiclename=='Personal' &&
							<div className="row CreateProRadioBox">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Estimated Miles</label>
									
								</div>
								<div className="col-sm-6">
									<input type="text" name="mile" onChange={this.onMileChange} className="PB_Title form-control" autoFocus />
								</div>
								<div className="col-sm-3">
									<label for="projectname" class="ParentLab" >X.656</label>
								</div>
								<div className="col-sm-3">
									<label for="projectname" class="ParentLab" >Travel Expenses: {this.state.travelexpenses}</label>
								</div>
							</div>
							)}
							{ (this.state.vehiclename=='Rental' &&
							<div className="row CreateProRadioBox forprefixrow">
								<div className="col-sm-12">
									<label for="projectname" class="ParentLab" >Drive Expenses</label>
									
									<div class="input-group prefix">                           
								        <span class="input-group-addon">$</span>
								        <input type="text" name="driveexpense" onChange={this.onChange} className="PB_Title form-control" autoFocus />
								    </div>
								</div>
							</div>
							)}
							</>
							)
						}
						
						</>
						)
					}
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleReplyVideographyClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.saveReplyVideoGraphyRequest(e)}>
						Submit
					</Button>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default ReplyVideographyForm;