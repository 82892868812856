import React, { Component } from "react";
import { Row, FormGroup, FormControl, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import { withRouter,Redirect,Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {PostData,GetData,DeleteData} from '../../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SidebarUpdate from '../../components/Sidebar';
class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
			itemPerPage:3,
            searchText: '',
            isdiagram:true,
            isMax:false,
            projectList: [],
            originalProjectList: []
        };
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.projectDetails = this.projectDetails.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.setSize=this.setSize.bind(this);
    }
    componentDidMount() {
        let data = '';
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		console.log("userdata==",userdata);
		
        GetData('orgprojects/'+this.props.match.params.orgid).then((result) => {             
            let responseJson = result;
            console.log('responseJson==',responseJson);
            this.setState({
                projectList: responseJson.data,
                originalProjectList: responseJson.data
            });
        });        
    }
     handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        console.log(this.state.projectList);
    }

    projectDetails(item, index) {
        console.log(index);
    }
    deleteProject(item, index) {
        DeleteData('project/'+item._id).then((result) => {       
            //this.props.history.push("/organization/list");
            window.location.reload();
        });
    }
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    setSize(){
        this.setState({
            isMax:!this.state.isMax
        });             
        if(!this.state.isMax){
        }
        console.log(this.props.children);
        if(this.props.children[1].props['data-event']){
            var self=this;
            setTimeout(()=>{
                self.props.children[1].props['data-event'].call();  
            },700);         
        }
    }

    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalProjectList.length; i++) {
                    let orgname=this.state.originalProjectList[i].project_name;
                    if(orgname!=null)
                    {
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalProjectList[i]);
                        }
                    }
                }
                this.setState({
                    projectList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    projectList: this.state.originalProjectList, 
                });
            }
       });
    }

    render() {
        let description='';
        let createprojectlink='';
        let projectlink='';
		let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
		let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let renderedProjects = this.state.projectList.slice(indexOfFirstTodo, indexOfLastTodo);
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        let productionteamlink='/org/'+this.props.match.params.orgid+'/teams';
        if(userdata.capabilities.administrator)
        {
            createprojectlink='/org/'+this.props.match.params.orgid+'/project/create';
        }
        else
        {
            createprojectlink='/subs/org/'+this.props.match.params.orgid+'/project/create'; 
        }
        let listItems = renderedProjects.map((item, index) => {
        if(userdata.capabilities.administrator)
        {
            projectlink="/org/"+item.org_id+"/project/view/"+item._id;
        }
        else
        {
            projectlink="/subs/org/"+item.org_id+"/project/view/"+item._id;
        }
        if(item.description){
            if (this.isJson(item.description))
            {
                const contentState = convertFromRaw(JSON.parse(item.description));
                const editorState = EditorState.createWithContent(contentState);
                const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
                description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
            }
            else
            {
                description= item.description;
            }
        }
        return <tr key={index}>
                <td className="tbl_org_name">                    
                    <Link to={projectlink}><u>{item.project_name}</u></Link>
                </td>
		        <td className="tbl_org_description">{description}</td>
                <td className="tbl_org_duedate">{item.due_date}</td>                
		        <td className="tbl_org_action TblIconDel"><i  className="fa fa-trash" aria-hidden="true" onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteProject(item, index) } } > <img src={require('../../assets/images/delete-icon.png')}/> </i></td> 
		    </tr>
		});
		return (
			 <div className="container-fluid ProjectList"  ref="selfdiv">
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-md-7 col-lg-7">
                                <h4 class="PageTopTitle">Project List</h4>
                            </div>
                            <div className="col-md-3 col-lg-3">
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                            <div className="col-md-2 col-lg-2">
                                <Link to={createprojectlink} className="create_org btn btn-info">Create Project</Link>                                
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                    <Link to={productionteamlink} className="production-team">Production Team</Link> 
                        <table className="table table-striped ListTable">
                            <thead>
                                <tr>
                                    <th><b>Project Name</b></th>
                                    <th><b>Description</b></th>
                                    <th><b>Due date</b></th>                                    
                                    <th><b>Action</b></th>
                                </tr>
                            </thead>
                            <tbody>
                            {listItems}
                            
                                
                            </tbody>
                        </table>
                    </div>
                    <div className="Mobrow">
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={3}
                            totalItemsCount={this.state.projectList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />

                    </div>
                </div>
            </div>
		);
    }
}
export default Projects;
