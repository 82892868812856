import React from 'react';
import footerLogo from '../assets/img/footer-logo.png';
import BackToTop from "react-back-to-top-button";

export default class Footer extends React.Component {
    render() {
        return (
            <><div className="loginPage">
                <footer id="colophon" className="site-footer">
                    <div className="footer-details">
                        <div className="footer-top-war">
                            <div className="contect-details-box">
                                <div className="footer-logo-warp">
                                    <a href="https://associationstudios.yoursmarthost.net/">
                                        <img src={footerLogo} alt="image" />
                                    </a>
                                </div>
                                <div className="contact-number">
                                    <div className="contact-phone">
                                        <a href="tel:8008206020">wewe (800) 820-6020</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-info">
                        <div className="footer-copyright">
                            <div className="left">
                                <span>&copy;</span> 2022&nbsp;<a href="index.html">8 Association Studios</a>.&nbsp;All Rights Reserved.
                            </div>
                            <div className="right contect-details-box">
                                <ul className="menu">
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Terms of Use</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                
                <BackToTop
                    showOnScrollUp
                    showAt={100}
                    speed={1500}
                    easing="easeInOutQuint"
                >
                    <div className="tp"><a className="top-btn"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
</svg></a></div>
                </BackToTop>
                </div>
            </>
        )
    }
}
