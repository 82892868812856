import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
class AddTaskTime extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			thour: '',
			tminut: '',	
			setAddTimeShow:this.props.handleAddTimeShow,		
		};
		console.log('calledddd==',this.props.handleAddTimeShow);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isAddTaskTime !== this.props.isAddTaskTime) {
			this.setState({setAddTimeShow:this.props.isAddTaskTime})		
		}
	}	
	onTaskHourChange = thour => this.setState({ thour })
	onTaskMinutChange = tminut => this.setState({ tminut })
	commentChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	handleAddTimeClose(e) {
		this.setState({ setAddTimeShow: false });
	}
	onSelectTask(e) {
		//var optionElement = e.target.childNodes[index]
		let taskname = e.target[e.target.selectedIndex].text;
		let userid = e.target[e.target.selectedIndex].getAttribute('data-userid');
		let teammember = e.target[e.target.selectedIndex].getAttribute('data-teammember');
		console.log('selcted task change data==',userid,teammember,taskname);
		this.setState({ selectedtask: e.target.value,selectedassigneeteammember:teammember,selectedassigneeuserid:userid,editedtaskassignment:taskname });
	}
	saveTaskTime(e) {
		if (this.validateAddTaskTimeForm()) {
			this.setState({ Inloading: true });
			const taskid = this.state.selectedtask;
			const hour = this.state.thour.format('HH');
			const minut = this.state.tminut.format('mm');
			console.log('taskid with date time==',taskid,hour,minut);
			const finaltime = hour + ':' + minut;
			let data = {
				'taskid': taskid,
				'orgid': this.props.orgid,
				'projectid': this.props.prjid,
				'time': finaltime,
				'comment': this.state.tcomment,
				'userid':this.props.userid,
				'taskname':this.state.editedtaskassignment,
				'selectedassigneeuserid':this.state.selectedassigneeuserid,
				'selectedassigneeteammember':this.state.selectedassigneeteammember,
			}
			//console.log('data',data);
			this.saveTaskdata(data);
		}
	}
	validateAddTaskTimeForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["selectedtask"]) {
			formIsValid = false;
			errors["selectedtask"] = "*Please select Task";
		}
		if (!fields["thour"]) {
			formIsValid = false;
			errors["thour"] = "*Please select Hour";
		}
		if (!fields["tminut"]) {
			formIsValid = false;
			errors["tminut"] = "*Please select Minut";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	saveTaskdata(data)
	{
		this.setState({ Inloading: true });
		PostData('addtasktime', data).then((result) => {
			if (result.statusCode === 200) {				
				this.setState({ tasktimesucsmsg: "Time Added Succesfully", thour: '', tminut: '', tcomment: '',selectedtask:'',selectedassigneeteammember:'',selectedassigneeuserid:'' });
				setTimeout(() => {
					this.setState({  tasktimesucsmsg: '',setAddTimeShow: false });
					this.props.loadProjectBoard(result.totaltime);
				}, 3000);
			}
			else if (result.statusCode === 401) {
				this.setState({ tasktimeerrormsg: result.Message });
				setTimeout(() => this.setState({ tasktimeerrormsg: '' }), 3000);
			}
			this.setState({ Inloading: false });
		});
	}
    render() {
		console.log('called every time');
        return (
        	<Modal show={this.state.setAddTimeShow}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Add Time</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.tasktimesucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row">
						<div className="col-12">
							<select name="task" className="form-control" value={this.state.selectedtask} onChange={this.onSelectTask.bind(this)}>
								<option value=" ">Select Task</option>
								{this.props.tasks}
							</select>
							<div className="errorMsg">{this.state.errors.selectedtask}</div>
						</div>
					</div><br/>
					<div className="row">
						<div className="col-4">
							<label>	Addtime:</label>
						</div>
						<div className="col-4">
							<TimePicker name="thour" value={this.state.thour} onChange={this.onTaskHourChange} showMinute={false} showSecond={false} />
							<div className="errorMsg">{this.state.errors.thour}</div>
						</div>
						<div className="col-4">
							<TimePicker name="tminut" minuteStep={15} value={this.state.tminut} onChange={this.onTaskMinutChange} showHour={false} showSecond={false} />
							<div className="errorMsg">{this.state.errors.tminut}</div>
						</div>
					</div><br/>
					<div className="row">
						<div className="col-12">
							<input className="LP_field tcomment" value={this.state.tcomment} onChange={this.commentChange.bind(this)} name="tcomment" type="textarea" placeholder="Comment" />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddTimeClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.saveTaskTime(e)}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default AddTaskTime;