import React, {useState,useEffect} from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
  } from 'react-stripe-elements'
  import axios from 'axios'
  //import './CheckoutForm.scss'
  import "../assets/css/CheckoutForm.css";
  import {PostData,GetData} from '../services/PostData';
  const CheckoutFormstg = ({ onChildClick,amount,orgid,vimeoid,projectname,name,email,userId,cards,custid,defaultcard,selectedlanguage, stripe, history }) => {

	const [ddcard, setDdcard] = useState('')
	const [receiptUrl, setReceiptUrl] = useState('')
    //const [name,setName] =useState('');
	//const [email,setEmail] =useState('');
	function handleClick(event,cardid,defaultcardid) {
		console.log('event,cardid,defaultcardid==',event,cardid,defaultcardid);
	    onChildClick(cardid,defaultcardid,custid,userId); // pass any argument to the callback
	  }
	const handleSubmit = async (event,issave=false) => {
	  event.preventDefault()
  
	  console.log('issave',issave);
  	  //	return false;
	  const { token } = await stripe.createToken()
	  let data;
	  let setddcard
	  if(ddcard)
	  setddcard = ddcard
	  else
	  setddcard = defaultcard

	//  console.log('setddcard',setddcard);
	  if(token)
	  {
	    data ={
		  	custid:custid,
		  	defaultcard:setddcard,
			amount: amount,
			source: token.id,
			name: name,
			receipt_email: email,
			userId:userId,
			org_id:orgid,
			vimeoid:vimeoid,
			projectname:projectname,
			language:selectedlanguage,
			issave:issave
		 }
	  }
	 else if(custid && setddcard)
	  {
	  	data ={
		  	custid:custid,
		  	defaultcard:setddcard,
			amount: amount,
			name: name,
			receipt_email: email,
			userId:userId,
			org_id:orgid,
			vimeoid:vimeoid,
			projectname:projectname,
			language:selectedlanguage,
			issave:issave
		  }
	  }
	  
	  PostData('stripe/chargestg',data).then((result) => {		
		    console.log('result==',result);		
			if(result.statuscode===200)
			{
				setReceiptUrl(true)	
			}		
			//setReceiptUrl(result.charge.receipt_url)		
		});
	  
  
	//   const order = await axios.post('http://localhost:7000/api/stripe/charge', {
	// 	amount: selectedProduct.price.toString().replace('.', ''),
	// 	source: token.id,
	// 	receipt_email: 'customer@example.com'
	//   })
  
	  
	}
	const onToggleEn =(e) =>{
		console.log('index==');
		 //let value = e.target.checked;
		let value = e.target.value; //value
		console.log('value==',value);
		setDdcard(value);
	}
	const handleSavedCardChange = (e) =>{
		console.log('e.target.value==',e.target.value);
		setDdcard(e.target.value);
	}
	const removeCard= (e,cardid,defaultcardid)=>{
		console.log('remove=',e,cardid,defaultcardid);
		const data = {
			custid:custid,
			cardid:cardid,
			defaultcardid:defaultcardid,
			userId:userId,
		}
		PostData('deletecard',data).then((result) => {		
		    console.log('result==',result);		
			if(result.statuscode===200)
			{
				//setReceiptUrl(true)	
			}		
			//setReceiptUrl(result.charge.receipt_url)		
		});
	}
	if (receiptUrl) {
		return (
		  <div className="success">
			<h2>Payment Successful!</h2>
			
		  </div>
		)
	  }
	  
	  let optionItems = cards && cards.data && cards.data.length>0 && cards.data.map((item) =>{

	  		var check = false
			let dcardid
			if(ddcard)
			{
				if(ddcard && ddcard===item.id)
				{
					var check = true;								
				}
				dcardid= ddcard
			}
			else if(defaultcard && defaultcard===item.id)
			{
				var check = true;
				dcardid= defaultcard

			}	

			return(
				<option key={item.id} value={item.id}>{`**** **** **** ${item.last4}`}</option>
		            
		        
				
			)	

	      
	    }
	    );
	  return (
		<div className="checkout-form stripe-form">
			  <form>
			 	 {
					(cards && cards.data && cards.data.length>0 &&
						<div>
						  	<span id="card-header">Saved cards:</span>
						 	<div className="row row-1">
								<select className="saved-card-dropdown" onChange={(e) => {handleSavedCardChange(e)}}>
								    <option> Select Card </option>
									{optionItems}
								</select>	
								{
									( ddcard &&
										<input className="order-button" type="button" onClick={(e) => {handleSubmit(e,true)}} value="Pay" />
									)
								}							
					        </div>
			        	</div>
				 	 )
				}
				

			</form>
			   
		  <form>
			<label>
			  Card details
			  <CardNumberElement />
			</label>
			<label>
			  Expiration date
			  <CardExpiryElement />
			</label>
			<label>
			  CVC
			  <CardCVCElement />
			</label>
			<button type="button" onClick={(e) => {handleSubmit(e)}} disabled={amount==0?true:false} className="order-button">
			  Pay
			</button>
			<button type="button" onClick={(e) => {handleSubmit(e,true)}} disabled={amount==0?true:false} className="order-button">
			  Pay and Save the Card
			</button>
		  </form>
		</div>
	  )
	}

	export default injectStripe(CheckoutFormstg)