import React, {Component} from 'react';
import { Link , Redirect } from 'react-router-dom';
import {GetData,PostData} from '../services/PostData';
import Loader from 'react-loader-spinner'
class Sidebar extends Component{
	constructor(props){
		super(props);
		//console.log('propsssssss=',props);
		this.state = {
			org_id:props.orgid,
			loading:false,
			issubcriber:'no',
			remaining_hours:'',
			isnotificationon:false,
		};
		this.renderActiveProjects = this.renderActiveProjects.bind(this);
		this.openNav = this.openNav.bind(this);
		this.closeNav = this.closeNav.bind(this);
	}
	static updateSideNav(checkstatus) {
		//console.log('propsssssss= calledddddddddddd',checkstatus);
	};
	static listSideNav() {
		//console.log('propsssssss= calledddddddddddd',);
		//this.setState({abc:'true'});
	};	
	openNav() {
		document.getElementById("mySidebar").style.width = "15%";
		document.getElementById("main").style.marginLeft = "15%";
		document.getElementById("mySidebar").classList.add('OpenSidebar');
		document.getElementById("main").classList.add('FullWrapper');
		this.renderActiveProjects();
	}
	closeNav() {
		document.getElementById("mySidebar").style.width = "0";
		document.getElementById("main").style.marginLeft= "0";
		document.getElementById("mySidebar").classList.remove('OpenSidebar');
		document.getElementById("main").classList.remove('FullWrapper');
	}
	componentDidMount() {
		this.renderActiveProjects();
		GetData('organization/'+this.state.org_id).then((result) => {   
			//console.log('resultresult==',result); 
		if(result.data.remaining_hours)
		{
		 let remaining_hours= result.data.remaining_hours.split(':');
			 if(remaining_hours[0])
			 {
				 let checknum = parseInt(remaining_hours[0]);
				 let isnotificationon = (checknum>0)?false:true;
				 console.log('isnotificationon==',isnotificationon);
				 this.setState({
					isnotificationon:isnotificationon,
				});
			 }

		}        
            this.setState({
                orgdata: result.data,
				issubcriber:result.data.subscriber,
				remaining_hours:result.data.remaining_hours,
            });
        });
	}
	renderActiveProjects(){
		// GetData('orgprojects/'+this.state.org_id).then((result) => {             
        //     let responseJson = result;
        //   	this.setState({
        //         projectList: responseJson.data,                
        //     });
		// });   
		//let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		// this.setState({loading:true, });	
        // let data = {
        //     'userid': userdata.user.id,
        // }
        // PostData('employeetask', data).then((result) => {
        //     if (result.statuscode === 200) {
        //         this.setState({
        //             tasklist: result.data,
        //             loading:false,
        //         });	
        //     }
        // });
	}
	removeNotification(e){
		console.log('removeNotification calledd');
		this.setState({
			isnotificationon:false,
		});
	}
    render(){
    	let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
    	if(checkuser.user.ID==15888 || checkuser.user.ID==12612 || checkuser.user.ID==28 || checkuser.user.ID==202371015253)
		{
			localStorage.setItem('userData','');
			return (<Redirect to={'/'}/>)
		}
		console.log('checkuser=',checkuser.user);
		let dashlink;
		
		if(checkuser.user.ID==26 || checkuser.user.ID==5699)
		dashlink = `/productionteam/list`;
		else
		dashlink = `/productionteam/view/`+checkuser.user._id;	
    	let projecturl=(this.state.org_id)?"/org/"+this.state.org_id+"/projects":"projects/list";
		let inboxurl=(this.state.org_id)?"/org/"+this.state.org_id+"/inbox/list":"inbox/list";
		//let tasklist=this.state.tasklist;
		// let activetasklist=[]
		// if(tasklist)  
	    // {  
		// 	activetasklist=tasklist.map((item) => {
		// 		if(item.status==='Active'){
		// 			return <li>
		// 				<Link to={"/org/"+item.org_id+"/project/view/"+item.project_id}><u>{item.assignment}</u></Link>
		// 			</li>
		// 		}
		// 	})

		// } 
		
		let orgname='';
		if(this.state.orgdata)
		orgname=this.state.orgdata.organization_name;
		
    	return (    
			<React.Fragment>     
 			<div id="mySidebar" className="sidebar">
				 {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	}
		        <button className="openbtn" onClick={this.openNav}>
		       	 <img src={require('../assets/images/toggle-icon.png')} />
		        </button>
		        <div className="SidebarInner"> 
		        <a className="closebtn" onClick={this.closeNav}><img src={require('../assets/images/toggle-icon.png')}/>
		         </a>
				<img className="AS_logo_sidebar img-responsive" src={require('../assets/images/sidebar-logo.png')}/>
				<ul className="SideIconNav">
					<li>
						<Link to={projecturl}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-home.png')}/>
							</span>{orgname}</Link>
					</li>
					{/* <li><Link to={`/${this.state.org_id}/timelogs`}>Time Logs</Link></li>
					<li><Link to={`/${this.state.org_id}/calendar`}>Calendars</Link></li> */}
					<li> 
						<Link to={`/${this.state.org_id}/assets`}>
							<span className="menu_img_icon"> 
								<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-assets.png')}/>
							</span> Assets</Link>
					</li>
					<li> 
						<Link to='#'> 
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-script.png')}/>
							</span>  Scripts</Link>
					</li>					
					<li>
						<Link to={`/org/${this.state.org_id}/voiceovers`}>
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-voiceover.png')}/></span>
							Voice Overs</Link>
					</li>
					<li>
						<Link to={`/${this.state.org_id}/stockvideos`}> 
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-projects2.png')}/>
						</span> Stock Videos</Link>
					</li>
					<li>
						<Link to={`/${this.state.org_id}/stockmusic`}> 
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/music-note-icon.png')}/>
						</span> Stock Music</Link>
					</li>
					<li>
						<Link to={`/${this.state.org_id}/stocks`}> 
							<span className="menu_img_icon">
							<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-stock-graphics.png')}/> 
						</span>Stock Graphics</Link>
					</li>
					<li>
						<Link to={`/org/${this.state.org_id}/broadcast`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/Broadcast.png')}/>
							</span>Broadcast</Link>
					</li>
					<li>
						<Link to={`/${this.state.org_id}/closedcaptioning`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/captain.png')}/>
							</span>Closed Captioning</Link>
					</li>
					<li>
						<Link to={`/${this.state.org_id}/portfolio`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-projects.png')}/>
							</span>Portfolio</Link>
					</li>
					{ (checkuser.user.ID==26 &&
                        <li>
							<Link to={`/org/${this.state.org_id}/messaging`}>
								<span className="menu_img_icon">
									<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-messaging-new1.png')}/>
								</span>Messaging</Link>
						</li>
                        )
                     }
                    <li>
						<Link to={`/${this.state.org_id}/styleguide`}>
							<span className="menu_img_icon">
								<img className="MenuIcon styleguide" src={require('../assets/images/menu-icons/sd-icon1.png')}/>
							</span>Style Guide</Link>
					</li>
					<li>
						<Link to={`/org/${this.state.org_id}/invoices`}>
							<span className="menu_img_icon">
								<img className="MenuIcon styleguide" src={require('../assets/images/menu-icons/invoice.png')}/>
							</span>Invoices</Link>
					</li>
					{
						this.state.issubcriber ==='no' &&
						<li>
						<Link to={`/${this.state.org_id}/order-videos`}>
							<span className="menu_img_icon">
								<img className="MenuIcon" src={require('../assets/images/menu-icons/order-video.png')}/>
							</span>Order Videos</Link>
						</li>	
					}
													          
				</ul>
				  <ul className="SideIconNav">
				  	<li><Link to={dashlink}> <img className="MenuIcon" src={require('../assets/images/menu-icons/dash-icon.png')}/> Dashboard </Link></li>
					<li>
						<Link to='/profile'><span className="menu_img_icon"> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-profile.png')}/></span> My Profile</Link>
			        </li>
		            <li>
						<Link to='/organization/list'><span className="menu_img_icon"> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-organizations.png')}/></span> Organizations</Link>
			        </li>
					<li>
					<Link to='/profile'> <span className="menu_img_icon"><img className="MenuIcon" src={require('../assets/images/menu-icons/menu-tasks.png')}/></span> Tasks</Link></li>
					<li>
						<Link to={{ pathname: "/profile",
							tabname: {
							activetab: "projectlist"
							}
						}}> <span className="menu_img_icon"><img className="MenuIcon" src={require('../assets/images/menu-icons/menu-projects.png')}/></span> Projects</Link>
					</li>
					<li>
						<Link to={{ pathname: "/profile",
							tabname: {
							activetab: "inbox"
							}
						}}><span className="menu_img_icon"> <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-inbox.png')}/> </span>Inbox</Link>
					</li>
					<li>
						<Link to={{ pathname: "/profile",
						tabname: {
						activetab: "report"
						} }}>
							<span className="menu_img_icon"> 
								<img className="MenuIcon" src={require('../assets/images/menu-icons/menu-report.png')}/>
							</span> Reports</Link>
					</li>
					<li>
					<Link to="/logout"><span className="menu_img_icon">
						 <img className="MenuIcon" src={require('../assets/images/menu-icons/menu-logout.png')}/> 
						</span>
						 Logout</Link></li>
		        </ul>
		        {/* <ul className="SideListNav">
		            <li><a href="#">Active Tasks</a></li>
					{activetasklist}
				</ul> */}
		        </div>
		    </div> 
			{/*{
				this.state.isnotificationon && 
			<div className="notification-popup">You exceeded hours of the month<a className="remove-nootification" onClick={this.removeNotification.bind(this)}>x</a></div>
			} */}
			</React.Fragment>  
		)
    }
}
export default Sidebar;