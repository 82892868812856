import React, { Component } from "react";
import {PostData} from '../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import {apiConfig} from "../../config/api";
import { SketchPicker } from 'react-color';
class AddColor extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			thour: '',
			tminut: '',	
			setAddColorShow:this.props.handleAddColorShow,
			background: '#fff',
		};
		this.onChange = this.onChange.bind(this);
		this.submit = this.submit.bind(this);
	}
	handleChangeComplete = (color) => {
	    this.setState({ background: color.hex });
	};
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isAddColor !== this.props.isAddColor) {
			this.setState({setAddColorShow:this.props.isAddColor})		
		}
	}
	handleColorTypeChange(e){
        //console.log('selectedproject==',e.target.value);
        this.setState({ selectedColorType: e.target.value });
    }
	submit() {
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			
		const data = new FormData();
			data.append('colorcode', this.state.background);
			data.append('org_id',  this.props.orgid);
	        data.append('colorname',  this.state.colorname);
	        data.append('selectedColorType',  this.state.selectedColorType);
			data.append('userid',  userdata.user.ID)
			let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL+'savestyledesign', {
			method: 'POST',
			body: data,
			headers: {
					"session-key": session_key,
				}
			})
			.then((response) => response.json())
			.then((res) => {
				if(res.statusCode===200)
				{	
					console.log('res====',res);
					this.setState({ sucsmsg: 'File uploaded Successfully' });
					setTimeout(() => {
						this.setState({ sucsmsg: '' });
						window.location.reload();
					}
					, 3000);
				}
			});
	}
	
	handleAddColorClose(e) {
		this.setState({ setAddColorShow: false });
	}
	
	validateAddTaskTimeForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["selectedtask"]) {
			formIsValid = false;
			errors["selectedtask"] = "*Please select Task";
		}
		if (!fields["thour"]) {
			formIsValid = false;
			errors["thour"] = "*Please select Hour";
		}
		if (!fields["tminut"]) {
			formIsValid = false;
			errors["tminut"] = "*Please select Minut";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	
    render() {
		console.log('called every time');
        return (
        	<Modal show={this.state.setAddColorShow}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Add Color</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.sucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row formarginbtm">
						<div className="col-12">
							<label for="projectname">Color Name:</label>
							<input type="text" value={this.state.colorname} name="colorname" placeholder="" onChange={this.onChange} className="PB_Title form-control"  />
							<div className="errorMsg">{this.state.errors.selectedtask}</div>
						</div>
					</div>
					<div className="row formarginbtm">
						<div className="col-12">
							<select name="task" className="form-control" value={this.state.selectedColorType} onChange={this.handleColorTypeChange.bind(this)}>
								<option value=" ">Select Color Type</option>
								<option value="Primary Color">Primary Color</option>
								<option value="Secondary Color">Secondary Color</option>
							</select>
							<div className="errorMsg">{this.state.errors.selectedtask}</div>
						</div>
					</div>
					<div className="row formarginbtm">
						<div className="col-12">
							<label for="projectname">Color Picker:</label>
							<SketchPicker color={ this.state.background } onChangeComplete={ this.handleChangeComplete } />
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddColorClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={this.submit}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default AddColor;