import React, { Component } from 'react';
import { render } from 'react-dom';
import { getEvents } from './CalendarEvents'
import moment from 'moment'
import BigCalendar from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
BigCalendar.momentLocalizer(moment)
class Calendars extends Component {
	constructor(props) {
        super(props);        
        this.state = {
          events: []
        };
      }     
      componentDidMount () {
        getEvents((events) => {
          this.setState({events})
        })
      }    
      render(){
        const {localizer}=this.props;
        const localize=BigCalendar.momentLocalizer(moment);
        const allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);
        return(
            <div style={{ height: 700 }}>
                <BigCalendar
                    localizer={localize}
                    selectable
                        onSelectSlot={e => alert(e)}
                    events={this.state.events}
                    step={60}
                    //views={allViews}
                    // defaultDate={new Date(2019, 10, 1)}
                    //startAccessor='start' endAccessor='end'
                    //views={['month', 'week', 'day']}
                />
            </div>
        )
      }
} 
export default Calendars;