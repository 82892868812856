import React, { Component } from 'react';  
import { Route,Redirect } from 'react-router-dom'; 
import SubsSidebar from '../components/SubsSidebar'; 
const bodybg = {
  backgroundColor: '#ffffff',
};
const InnerDashboardLayout = ({children, ...rest}) => {  
  //console.log('cheildrennn=========',children.props.match.params.orgid);
  return (  
    <div>  
      <div className="ProjectBoard" style={bodybg}>
        <div className="container-fluid">
          <div className="row"> 
            <SubsSidebar orgid={children.props.match.params.orgid} /> 
            <div id="main">
              {children}
            </div>
          </div>
        </div>
      </div>    	
    </div>  
  )  
}
const SubsDashboardLayoutRoute = ({component: Component, ...rest}) => { 
 let checkuser1 = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
      if(checkuser1.user.ID==15888 || checkuser1.user.ID==12612 || checkuser1.user.ID==28 || checkuser1.user.ID==202371015253)
    {
      localStorage.setItem('userData','');
      return (<Redirect to={'/'}/>)
    }   
  const checkuser=localStorage.getItem('userData');
  if(checkuser)
  {
    const userdata =JSON.parse(checkuser);
    const currentUser = userdata.data.user.role;
    console.log('currentUser==',currentUser);
    if (currentUser!='associatio-subscriber' && currentUser!='subscriber') { 
        return <Redirect to={{ pathname: '/logout' }} />
    }
  }
  return (  
    <Route {...rest} render={matchProps => (       
      checkuser?<InnerDashboardLayout>  
          <Component {...matchProps} />  
      </InnerDashboardLayout>  
      : <Redirect to={{
          pathname: '/'
        }} />     
    )} />  
  )  
};    
export default SubsDashboardLayoutRoute; 