import React, { Component } from 'react';  
import { Route,Redirect } from 'react-router-dom';  
import Header from '../components/Header'
import Footer from '../components/Footer'
const DashboardLayout = ({children, ...rest}) => {  
  return (  
    <div>  
    	<Header />    			
        <div className="main" style={{backgroundColor: '#1C2F41'}}>{children}</div>                 
      <Footer />
    </div>  
  )  
}
  const DashboardLayoutRoute = ({component: Component, ...rest}) => {    
     let checkuser1 = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
      if(checkuser1.user.ID==15888 || checkuser1.user.ID==12612 || checkuser1.user.ID==28 || checkuser1.user.ID==202371015253)
    {
      localStorage.setItem('userData','');
      return (<Redirect to={'/'}/>)
    }
  const checkuser=localStorage.getItem('userData');
  if(checkuser)
  {
    const userdata =JSON.parse(checkuser);
    console.log('userdata',userdata);
    const currentUser = userdata.data.user.role;
    if (currentUser!='administrator') {
        return <Redirect to={{ pathname: '/logout' }} />
    }
  }
  return (  
    <Route {...rest} render={matchProps => (       
      checkuser?<DashboardLayout>  
          <Component {...matchProps} />  
      </DashboardLayout>  
      : <Redirect to={{
          pathname: '/'
        }} />     
    )} />  
  )  
};    
export default DashboardLayoutRoute; 