import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Select from "react-select";
import ReactTable from "react-table";
import {GetData,PostData} from '../../services/PostData';
import "react-table/react-table.css";  
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import dateFormat from 'dateformat';
import Loader from 'react-loader-spinner'
import { CSVLink, CSVDownload } from "react-csv";
//import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
class TimeLogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            timeLogList: [],
            originalTimeLogList: [],
            taskteammembersucsmsg:'',
            filtered: [],
            select1: undefined,
            select2: undefined,
            isClearable: true,
            loading:false,
            csvfilename:'Everybody',
            monthfilter:'All Time',
            selectedOption1: {value:'',label:"Everybody"},
            selectedOption2: {value:'',label:"All Time"},
            displaytype:'timelog',
            editedusername:'',  
            editeduserid:'',    
            editedtime:'',
            
        };
        console.log('this.props.name=',this.props.name);
        this.onFilteredChangeCustom(
            this.props.name,
            "username"
        );
    }
    componentDidMount() {
        this.setState({loading:true});
        Promise.all([this.getTimeLog()])
		.then(([orgtaskdata])  => {
            this.setState({
                timeLogList: orgtaskdata.data,
                orginaltotaltime: orgtaskdata.projecttotaltime,
                totaltime: orgtaskdata.projecttotaltime,
                originalTimeLogList: orgtaskdata.data,
                loading:false
            });
        });
    }
    getTimeLog(){
        return GetData('postproductionreport/').then((result) => {  
			return result;
		});
    }        
    onFilteredChangeCustom = (value, accessor) => {
        let selectedval=value;
        this.setState({ selectedOption2: {value:'',label:'All Time'} });
        let filtered = this.state.filtered;        
        let insertNewFilter = 1;
        if (filtered.length) {
            filtered.forEach((filter, i) => {                
                if (filter["id"] === accessor) {
                    if (value === "" || !value.length)  filtered.splice(i, 1)  
                    else filter["value"] = value;
                    insertNewFilter = 0;                    
                }
            });
        }
        if (insertNewFilter) {
            this.setState({csvfilename:value});
            filtered.push({ id: accessor, value: value });
        }
        else
        {
            this.setState({csvfilename:'Everybody'});
        }
        if(value)
        {
            this.setState({ selectedOption1: {value:selectedval,label:value} });
            let datata=this.state.originalTimeLogList;
            let newdata=[];
            if (datata.length) {      
                datata.forEach((data, i) => { 
                    if(value===data.username)
                    {                                      
                        newdata.push(data);        
                    }             
                });
                this.setState({timeLogList:newdata});
            }    
        }
        else
        {
            this.setState({timeLogList:this.state.originalTimeLogList}); 
            this.setState({ selectedOption1: {value:'',label:'Everybody'} });
        }
        this.setState({ filtered: filtered });
    };    
    onCustomFilteredChangeCustom = (value, accessor) => {        
        let filtered = this.state.filtered;
        let insertNewFilter = 1;
        this.setState({ selectedOption1: {value:'',label:'Everybody'} });
        if(!value)
        {
            if (filtered.length) {
                filtered.forEach((filter, i) => {
                    if (filter["id"] === accessor) {
                        if (value === "" || !value.length) filtered.splice(i, 1);
                        else filter["value"] = value;
                        insertNewFilter = 0;
                    }
                });
            }
            if (insertNewFilter) {
                filtered.push({ id: accessor, value: value });
            }
            this.setState({ filtered: filtered,timeLogList:this.state.originalTimeLogList,totaltime:this.state.orginaltotaltime});
            this.setState({ selectedOption2: {value:'',label:'All Time'} });
       }
        else
        {
            this.setState({ selectedOption2: {value:value,label:value} });
            let datata=this.state.originalTimeLogList;
            filtered=[];
            let d = new Date();
            let currentmonth = parseInt(d.getMonth()+1);            
            let currentyear = parseInt(d.getFullYear());
            let lastmonth=currentmonth-1;
            let lastyear=currentyear-1;
            if(currentmonth==0){
                currentmonth=1;
                lastmonth = 12;
                if(value=='Last Month')
                    currentyear=currentyear-1;
            }
            let month='';
            let year='';
            switch(value) {
                case 'This Month':
                    month=currentmonth;
                    year=currentyear;
                    break;
                case 'Last Month':
                    month=lastmonth;
                    year=currentyear;
                    break;
                case 'This Year':
                    year=currentyear;
                    break;
                case 'Last Year':
                    year=lastyear;
                    break;
            }       
            if (filtered.length) {
                filtered.forEach((filter, i) => {
                    if (filter["id"] === accessor) {
                        if (value === "" || !value.length) filtered.splice(i, 1);
                        else filter["value"] = value;
                        insertNewFilter = 0;
                    }
                });
            }
            let newdata=[];
            if (datata.length) {  
                var projecttotaltime = '';  
                var projecthr = 0;
                var projectmin = 0; 
                var dmin = 0;       
                datata.forEach((data, i) => {  
                    this.setState({monthfilter:value});                            
                    let due = new Date(data.due_date);
                    let dmonth = parseInt(due.getMonth());
                    let dyear = parseInt(due.getFullYear());
                    if(dmonth==0)
                    dmonth=1;                                                          
                    else
                    dmonth=dmonth+1
                    if(value==='This Year' || value==='Last Year')
                    {
                        if (parseInt(year)===parseInt(dyear)){
                            newdata.push(data);                                
                            var project_time = data.totaladded_time;                            
                            if(project_time != undefined){
                                var time = project_time.split(':')
                                projecthr += parseInt(time[0]);
                                projectmin += parseInt(time[1]);
                                if(projectmin >=  60){
                                    var dhr = parseInt(projectmin/60);
                                    dmin = projectmin%60;
                                    projectmin = dmin;
                                }
                                if(dhr){
                                    projecthr = projecthr+dhr;
                                }
                            }
                        }                                          
                    }
                    else
                    {
                        if (parseInt(month)===parseInt(dmonth)){
                            newdata.push(data); 
                            var project_time = data.totaladded_time;                            
                            if(project_time != undefined){
                                var time = project_time.split(':')
                                projecthr += parseInt(time[0]);
                                projectmin += parseInt(time[1]);
                                if(projectmin >=  60){
                                    var dhr = parseInt(projectmin/60);
                                    dmin = projectmin%60;
                                    projectmin = dmin;
                                }
                                if(dhr){
                                    projecthr = projecthr+dhr;
                                }
                            }
                        }                 
                    }
                });
                projecthr = (projecthr < 10 ? '0' : '') + projecthr
                projectmin = (projectmin < 10 ? '0' : '') + projectmin
                projecttotaltime = projecthr+':'+projectmin;
                console.log('newdata===',newdata);  
                this.setState({timeLogList:newdata});
                this.setState({totaltime:projecttotaltime})
            }
            this.setState({ filtered: filtered });
        }       
    };
    uniqueOptions = (objectsArray, objectKey) => {
        var a = objectsArray.map((o, i) => {
            return o[objectKey];
        });

        return a.filter(function(i, index) {
            return a.indexOf(i) >= index;
        });
    };
    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    changeDisplayType(displaytype,username,userid){
        console.log('displaytype=userid==',displaytype,username,userid);
        this.setState({displaytype:displaytype,editedusername:username,editeduserid:userid});
    }
    handleTimeFilterChange(e) {
		this.setState({ editedtime: e.target.value,loading:true });
		GetData('postproductionreport/'+e.target.value).then((result) => {
            console.log('result==',result);
            this.setState({
                timeLogList: result.data,
                orginaltotaltime: result.projecttotaltime,
                totaltime: result.projecttotaltime,
                originalTimeLogList: result.data,
                loading:false
            });

		});
	}
    render() {
        const columns = [
            {
                Header: 'UserId',
                accessor: 'userid',
                show:false,
            },
            {
                Header: 'Team Member',
                accessor: 'username',
               // width: 400,
                Cell: ({ row }) =>{
                    return (
                        <a className="forcusror" onClick={this.changeDisplayType.bind(this,'userlog',row.username,row.userid)}>{row.username}</a>
                    )                     
                },
            },
            // {
            //     Header: 'Projects',
            //     accessor: 'projectcount',
            //     width: 200,
            // },
            {
                Header: 'Time',
                accessor: 'tasktotaltime',
                width:200
            }
        ];        
        const data=this.state.timeLogList;
        const totaltime = this.state.totaltime;
        const isClearable= this.state.isClearable;
        let d = new Date();
        //console.log('d==',d);
        let currentmonth = parseInt(d.getMonth());
        let currentyear = parseInt(d.getFullYear());
        //console.log('currentmonth',currentmonth);
        let lastmonth=currentmonth-1;
        let lastyear=currentyear-1;
        if(currentmonth==0){
            currentmonth=1;
            lastmonth = 12;
        }
        const options = [
            { value: 'This Month', label: 'This Month' },
            { value: 'Last Month', label: 'Last Month' },
            { value: 'This Year', label: 'This Year' },
            { value: 'Last Year', label: 'Last Year' }
        ]
        var desc = '';
        
        //const csvData = []
        const csvData = [
            ["Team Member", "Projects", "Time"],
        ];
          const ProjectTotalTime = ["Total Time: ", totaltime]
         
        var editortext = '';
        var itemsProcessed = 0;
        const filename = this.state.csvfilename; 
        const monthfilter = this.state.monthfilter;
        const newDate = new Date()
        const currentm = parseInt(newDate.getMonth() +1);
        const currentdate = newDate.getDate()+'/'+currentm+'/'+newDate.getFullYear();
        const currenttime = newDate.getHours()+':'+newDate.getMinutes()+':'+newDate.getSeconds();       
        data.forEach((item, index, array) => {               
            var pusheddata = Array();
            pusheddata.push(item.username,item.projectcount,item.tasktotaltime);
            csvData.push(pusheddata);             
        });              
		return (            
            <div className="container-fluid TaskListPage"  ref="selfdiv">
            <div className="export_timelog">
            <CSVLink data={csvData} filename={filename+' '+monthfilter+' '+currentdate+' at'+currenttime+'.csv'}><img src="https://img.icons8.com/color/48/000000/ms-excel.png" /></CSVLink>
            </div>            
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                {(this.state.displaytype==='timelog') &&
                <div className="panel panel-primary">
                                       
                    <div className="panel-body timelog-table-listing">
                       <div className="row">
                           <div className="col-6" >
                                                    
                        </div>
                        <div className="col-6">
                            <select name="taskstatus" className="form-control tasklogstatus" value={this.state.editedtime} onChange={this.handleTimeFilterChange.bind(this)} >
                                <option value=" ">All Time</option>
                                <option value="1">This Week</option>
                                 <option value="2">Last Week</option>
                                <option value="3">This Month</option>
                                <option value="4">Last Month</option>
                                <option value="5">This Year</option>
                                <option value="6">Last Year</option> 
							</select>
                            {/* <Select
                                placeholder="All Time"
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={isClearable}
                                options={options}
                                multi={true}
                                onChange={entry => {
                                    if(!entry)
                                    {
                                        entry={id: '', value: '', label: ''};
                                    }
                                    this.setState({ select2: entry });
                                    this.onCustomFilteredChangeCustom(
                                    entry.value,
                                    "due_date"
                                    );
                                }}
                                value={this.state.selectedOption2}
                            /> */}
                        </div>
                    </div>                   
                    {   
                        (data.length>0) && 
                        <ReactTable
                            data={data}
                            filtered={this.state.filtered}
                            minRows = {0}
                            pageSizeOptions = {[10,50,100]}
                            defaultPageSize = {10}                   
                            columns={columns}
                            className="-striped -highlight"
                            showPagination={false}
                        />                        
                    }                    
                    {/* <div class="total_time">Total Time: {totaltime}</div>  */}
                </div>                
                </div>
            }
            
            </div>
		);
    }
}
export default TimeLogs;
