import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import {PostData} from '../services/PostData';
import loginbg from '../assets/images/login-bg1.png';
import Loader from 'react-loader-spinner'
import LostPassword from './LostPassword';
import qs from "query-string";
import { Link  } from 'react-router-dom';
class ResetPassword extends Component {
    constructor(props)
    {
		super(props);
		this.state = {
			redirectToReferrer: false,
			fields: {},
		    errors: {},
		    loading: false,
		    username:'',
		    password:'',
			servereerror:'',
			displayType:'login'
		};
		this.updatePassword = this.updatePassword.bind(this);
		this.onChange = this.onChange.bind(this);
		this.checkboxChange = this.checkboxChange.bind(this);
		//console.log(this.props)
		//const obj = qs.parse(this.props.location.search);
		//console.log('objjj',obj.userid,obj.token); 
	}
	
	checkboxChange(e){
		let isChecked = e.target.checked;
		this.setState({[e.target.name]:isChecked});
		//this.setState({[e.target.name]:e.target.value});
	}
	onChange(e){
		//console.log('cleeed in check==',e.target.value);
		this.setState({[e.target.name]:e.target.value});
	}
	updatePassword() {	
		if (this.validateForm())
		{
			const obj = qs.parse(this.props.location.search);
			console.log('objjj',obj.userid,obj.token); 
			this.setState({ loading: true });
			let data = {
				'userid': obj.userid,
				'token': obj.token,
				'password': this.state.user_pass,
				'repeatpassword': this.state.user_conf_pass,
			}
			PostData('updatepassword',data).then((result) => {				
				if(result.statusCode==200)
				{
					this.setState({serversucess: result.message, loading: false,user_email:''});
					setTimeout(() => this.setState({ serversucess: ''}), 3000);
				}
				else
				{
					this.setState({servereerror: result.message, loading: false,});
					setTimeout(() => this.setState({ servereerror: ''}), 3000);
				}
			});
		}
	}
	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["user_pass"]) {
			formIsValid = false;
			errors["user_pass"] = "*Please enter your password.";
		}
		if (!fields["user_conf_pass"]) {
			formIsValid = false;
			errors["user_conf_pass"] = "*Please enter your confirm password.";
		}
		if (fields["user_pass"] !== fields["user_conf_pass"]) {
			formIsValid = false;
			errors["servereerror"] = "*Passwords don't match.";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}  
	changeView(viewtype,e){
		this.setState({
			displayType:viewtype
		});
	}
    render() {
        const { loading } = this.state;
        const username=localStorage.getItem('username');
		const password=localStorage.getItem('password');
		const displayType=this.state.displayType;
        if (localStorage.getItem('userData')){
        	return (<Redirect to={'/vpma'}/>)
		}
        return (	
			<div className="login-section">
				<div className="login-section-in">
					<div className="container">	
					<div class="row">
					<div class="col-12 col-sm-12 col-md-7 col-lg-7">
						<div class="LoginVector">
						<img class="" src={require('../assets/images/login-graphics.png')}/>
						</div>
					</div>
					<div class="col-12 col-sm-12 col-md-5 col-lg-5">
						<div className="login-form">							
							<div>
								<h2>Reset Password</h2>   
								{
									loading ?<div className="loaderspinner">
										<Loader
											type="TailSpin"
											color="#D10000"
											height={100}
											width={100}						         
										/>
									</div> :null
								}
								<div className="succes-msg">{this.state.serversucess}</div>
								<div className="errorMsg">{this.state.servereerror}</div>
								<div className="errorMsg">{this.state.errors.servereerror}</div>
								<div className="form-group">
									<input type="password" placeholder="Password*" name="user_pass" onChange={this.onChange} autoFocus />
									<div className="errorMsg">{this.state.errors.user_pass}</div>
								</div>
								<div className="form-group">
									<input type="password" placeholder="Confirm Password*" name="user_conf_pass" onChange={this.onChange} autoFocus />
									<div className="errorMsg">{this.state.errors.user_conf_pass}</div>
								</div>
								<div className="form-group">
									<button type="submit" onClick={this.updatePassword} className="btn-signin sign-marg-no">Submit</button>
								</div>
								<div class="row LostForgot">
									<div className="col-12 form-group">
										<Link to="/">Click here for Login</Link>
									</div>								
								</div>								
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>			
			<div class="LoginFooter">
				<div class="container-fluid">
					<div class="row">
						<p>Copyright © Association Studios. All Right Reserved.</p>
					</div>
				</div>
			</div>
		</div>	
		);
    }
}
export default ResetPassword;
