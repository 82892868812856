import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../services/PostData';
import Loader from 'react-loader-spinner'
import AddTeam from './productionteams/AddTeam'
import EditTeam from './productionteams/EditTeam'
import ListTeam from './productionteams/ListTeam'
import ProjectList from './project/ProjectList';
import AddProject from './project/CreateProject';
import AddToProject from './AddToProject'
import TextComponent from './TextComponent';
import { Dropdown } from 'react-bootstrap';
import { saveAs } from "file-saver";
import { Document, ImageRun, Packer, Paragraph } from "docx";

class ScriptWriter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgdata: '',
            teamList: [],
            originalTeamList: [],
            loading: false,
            isSelectedMessage:'',
            displaytype:'viewprojectlist',
            userid:'',
            credits:'',
            issubcriber:'no',
            totalavailable_hours:'',
            remaining_hours:'',
            projectlist:[],
            producescript:'',
            word_length:'',
            setAddToProjectShow:false,
            isScriptEdit:false,
            editedboardid:''
        };
        this.onChange = this.onChange.bind(this);
        //this.onScriptChange = this.onScriptChange.bind(this);
    }
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }
    onScriptChange(event){
        //console.log('field, value=',field, value);
        //this.setState({field:value});
        console.log('event.target.value=',event.target.value);
        this.setState({ producescript: event.target.value });
    }
    componentDidMount() {
        this.loadProductionTeamData();
        this.getProjectList();
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let editscript = params.get('editscript');
        if(editscript){
            console.log('editscript=',editscript);
           this.setState({isScriptEdit:true,editedboardid:editscript});
           GetData('getprojectboarddeatil/'+editscript).then((result) => {
                if(result.statuscode===200)
                {
                    this.setState({
                        eventname:result.data.scriptwritertitle,
                        selectedproject:result.projectdata.project_name,
                        producescript:result.data.scriptwriterdata,
                        prjid:result.projectdata._id,
                    });
                }
            })
        }
    }
    getProjectList(){
        GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
            if(result.statuscode===200)
            {
                this.setState({projectlist:result.data});
            }
        })
    }
    loadProductionTeamData(){
        this.setState({ loading: true});
        GetData('orgprofile/'+this.props.match.params.orgid).then((result) => {     
            //let remainingcredits = result.data.credits-result.data.used_credit        
            this.setState({ 
                 loading: false,
                 teamList: result.data.org_users,
                 originalTeamList:result.data.org_users,
                 orgdata:result.data,
                 boxfolderid:result.data.boxfolderid,
                 credits:result.data.credits,
                 issubcriber:result.data.subscriber,
                 totalavailable_hours:result.data.totalavailable_hours,
                 remaining_hours:result.data.remaining_hours,
             });
         });
    }
    handleProjectChange(e){
        //console.log('selectedproject==',e.target.value);
        this.setState({ selectedproject: e.target.value });
        let prjid = e.target[e.target.selectedIndex].getAttribute('data-prjid');
        console.log('prjid=',prjid);
        this.setState({
            prjid:prjid
        });
    }
    handleVideoLengthChange(e){
        this.setState({ word_length: e.target.value });
    }
    callProductionteamdata(e){        
        var elem = this;
        elem.loadProductionTeamData();
        elem.setState({ displaytype: 'viewteam' });
    }
    changeView(displaytype,userid){
        this.setState({ displaytype: displaytype,userid:userid }); 
        this.loadProductionTeamData();
    }
    generateScript(e){
        this.setState({ loading: true});
        let data = {
            eventname:this.state.eventname,
            orgname:this.state.orgdata.organization_name,
            word_length:this.state.word_length,
        }
        PostData('generatescript/',data).then((result) => {     
            console.log('result=',result);
            //let remainingcredits = result.data.credits-result.data.used_credit    
            if(result.statusCode==200) {   
                let script = result.data;
                //script = script.replace('\n', '<br/>');
                this.setState({ producescript: script,loading: false }); 
             }
         });
    }
    addToProject(e) {

    }
    setAddToProjectShow(e) {
        this.setState({ setAddToProjectShow: true });
    }
    handleCheck(e) {
        this.setState({ scheduletype: e.currentTarget.dataset.id, boarddisplaytype: '' });
let script = this.state.producescript;
const map1 = script?script.split('\n').map((item, i) =>new Paragraph(item)):'';
map1.unshift(new Paragraph({text:'Video Script',bold: true}));
console.log('map1=',map1);
        const doc = new Document({
      sections: [
        {
          children: map1
        }
      ]
    });
    
    const selectedproject = this.state.selectedproject+' Script.docx'

    Packer.toBlob(doc).then(blob => {
      console.log(blob);
      saveAs(blob, selectedproject);
      console.log("Document created successfully");
    });


    }
    render() {   
        let teamListdata=[];    
        const loading = this.state.loading;
        let orgdata=this.state.orgdata;
        let orglogo='';
        if(orgdata.orglogo)
            orglogo=orgdata.orglogo;
        else
        orglogo='';
        teamListdata=this.state.teamList;
        let imagesrc='';
        //console.log('orglogo====',orglogo,orgdata.orglogo,orgdata);
        if(orglogo && orglogo!='""')
        {
            //console.log('orglogo hereeeee====',orglogo);
            try{
                imagesrc=require('../../assets/images/organization-logo/'+orglogo);
               // console.log('orglogo tryyyyy====');
            }
            catch
            {
               // console.log('orglogo catchhhh====');
                imagesrc='';
            }
        }
        else
        {
            imagesrc='';
        }
        //console.log('imagesrc====',imagesrc);
        let { handleClick } = this.props;
       // console.log('handleClick====',handleClick);
       let totalavailable_hours;
       let final_totalavailable_hour;
       let totalavailable_minute;
       let totalavailable_is_neg = 1;
       if(this.state.totalavailable_hours)
       {
            totalavailable_hours= this.state.totalavailable_hours.split(':');
            if(totalavailable_hours[0])
            {
                final_totalavailable_hour = totalavailable_hours[0];
                let checknum = parseInt(totalavailable_hours[0]);
                totalavailable_is_neg = (checknum>0)?1:0;
            }
            if(totalavailable_hours[1] && totalavailable_hours[1]!=0)
            {
                //console.log('totalavailable_hours[1]=',totalavailable_hours[1]);
            totalavailable_minute = Math.abs(parseInt(totalavailable_hours[1]));
            final_totalavailable_hour=final_totalavailable_hour+':'+totalavailable_minute;
            }
       }
       let remaining_hours;
       let remaining_minute;
       let final_remaining_hour;
       let final_remaining_is_neg = 1;
       if(this.state.remaining_hours)
       {
        remaining_hours= this.state.remaining_hours.split(':');
            if(remaining_hours[0])
            {
                final_remaining_hour = remaining_hours[0];
                let checknum = parseInt(remaining_hours[0]);
                final_remaining_is_neg = (checknum>0)?1:0;
            }
            if(remaining_hours[1] && remaining_hours[1]!=0)
            {

                remaining_minute = Math.abs(parseInt(remaining_hours[1]));
                //console.log('remaining_minute=',remaining_minute);
                final_remaining_hour = final_remaining_hour+':'+remaining_minute;
            }
       }

       let projects = this.state.projectlist.map((item, i) => {
            return <option className="folderlistitem" data-prjid={item._id} value={item.project_name}>
                {item.project_name}
            </option>;
        });
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
       //remaining_hours
		return (
			 <div className="container-fluid ProAssetsList"  ref="selfdiv">
                 {
	        		loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
						<div class="MobileDisplay MobileTopNavBar">
						 <ul className="top-links">
                                        <li>
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewprojectlist')} >
                                            Projects</a>  
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Stream</Link>
                                        </li> */}
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewteam')} >
                                           Production Team</a>                                            
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Files</Link>
                                        </li>
                                        <li>
                                            <Link to="/upload-assets">Notes</Link>
                                        </li> */}
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'timelog')} >
                                            Time Log</a>                                            
                                        </li>
                                        {/* <li>
                                            <Link to={`/${this.props.match.params.orgid}/timelogs/`}>Time Log</Link>
                                        </li> */}
                                    </ul>
						</div>
                            <div className="row MoPrologoLeft">
                                <div className="col-12 ProAssetsHead">
                                    <img  className="mr-3" src={imagesrc}/>
                                    <h4 class="PageTopTitle">{orgdata.organization_name}</h4>
                                    <p>
                                        {orgdata.street}<br/>
                                        {orgdata.city},{orgdata.state} {orgdata.zip} <br/>
                                        {orgdata.phone}
                                    </p>
                                </div>                         
                            </div>
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-8 MobOrgProTab">
                                    <ul className="top-links">
                                        <li>
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewprojectlist')} >
                                            Projects</a>  
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Stream</Link>
                                        </li> */}
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'viewteam')} >
                                           Production Team</a>                                            
                                        </li>
                                        <li> 
                                            <a className="forcusror" onClick={this.changeView.bind(this,'timelog')} >
                                            Time Log</a>                                            
                                        </li>
                                        {/* <li>
                                            <Link to="/upload-assets">Files</Link>
                                        </li>
                                        <li>
                                            <Link to="/upload-assets">Notes</Link>
                                        </li> */}
                                        {/* <li>
                                            <Link to={`/${this.props.match.params.orgid}/timelogs/`}>Time Log</Link>
                                        </li> */} 
                                    </ul>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 MobOrgProbtns">
                                    {
                                        this.state.issubcriber ==='no' &&
                                        <a  className="numofcredits">Credits: {this.state.credits}</a>
                                    }
                                    {
                                        this.state.issubcriber ==='yes' &&
                                        <>
                                            <a  className={totalavailable_is_neg>0?'numofcredits': 'neg-balance numofcredits'}>Total: {final_totalavailable_hour}</a>
                                            <a  className={final_remaining_is_neg>0?'numofcredits avail-hours': 'neg-balance numofcredits avail-hours'}>Available Hours: {final_remaining_hour}</a>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                   
                    <div className="add-team">
                        <div>
                            <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                                <option value=" ">Select Project</option>
                                {projects}
                            </select>
                        </div><br/>
                         <div>
                            <input className="form-control" placeholder="Write a video script for the 50th Annual Conference in Las Vegas, NV..." type="text" value={this.state.eventname} onChange={this.onChange} name="eventname" />
                        </div><br/>
                        
                         <div>
                         <div className="row">
                         <div className="col-6 col-sm-6">
                        <button type="button"  onClick={this.generateScript.bind(this)}  className="UpdateBtn btn btn-primary">Generate</button>
                        </div>
                         <div className="col-6 col-sm-6 RightTopMenu">
                         { this.state.producescript &&
                            <Dropdown>
                                <Dropdown.Toggle variant="success1" id="dropdown-basic">
                                    Action
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={this.handleCheck.bind(this)} data-id="Script">Download Script</Dropdown.Item>
                                    <Dropdown.Item onClick={this.setAddToProjectShow.bind(this)} data-id="addtoproject">Add to Project</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                         }
                         </div>
                          </div>
                        </div><br/>
                        <div className="producescript">
                        <TextComponent text={this.state.producescript} onScriptChange={this.onScriptChange.bind(this)} />
                        </div>
                        {
                            (this.state.setAddToProjectShow &&
                                <AddToProject orgid={this.props.match.params.orgid} userid={userdata.user.ID} prjid={this.state.prjid} project={this.state.selectedproject} producescript={this.state.producescript} handleAddToProjectShow={this.state.setAddToProjectShow} scriptwritertitle={this.state.eventname} isScriptEdit={this.state.isScriptEdit} editedboardid={this.state.editedboardid} />
                            )
                        }
                    </div> 
                </div>
            </div>
		);
    }
}
export default ScriptWriter;
