import React, { Component } from "react";
//import { Helmet } from 'react-helmet';
import { PostData, GetData } from '../services/PostData';

import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import 'webrtc-adapter';
import RecordRTC from 'recordrtc';
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';
import VideoRecorder from 'react-video-recorder'
import {apiConfig} from "../config/api"
import Loader from 'react-loader-spinner'
import { Redirect } from 'react-router-dom';
class Survey extends Component {
    constructor(props){
		super(props);
		this.state={
			errorname:'',
			erroremail:'',
			errormsg:'',
			errorfilename:'',
			issuccess:false,
			loading: false,
			surveysubmoitted:'',
		}
	}
	componentDidMount() { 
		const checkuser=localStorage.getItem('userData');  
		if(!checkuser)
		{
			this.props.history.push('/');	
			return false;
		}
		//const currentUser = userdata.data.user.role;
		GetData('checkprojectsurvey/'+this.props.match.params.prjid).then((result) => {             
		   console.log('result==',result);
		   if(result.status==='error' || result.status==='error')
		   {
			this.setState({
				errormsg:'' //errormsg:'Page Is Not Exist'
			});
		   }
		   else
		   {
		   	if(!result.data) {
				this.setState({
					issuccess:true,
				});
			}else
			{
				this.setState({
					surveysubmoitted:'Survey is submitted Successfully'
				});
			}
		   }                                      
		});
		                                           
		
    }
	onchange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	submitSurvey()
	{
		let prjid = this.props.match.params.prjid;
		let userid = this.props.match.params.userid;
		let data = {
			projectid:prjid,
			userid:userid,
			q1_answer:this.state.q1_answer,
			q2_answer:this.state.q2_answer,
			q3_answer:this.state.q3_answer,
		}
		console.log('data=',data);
		PostData('submitsurvey',data).then((result) => {				
			let responseJson = result;
			if(responseJson.statusCode==200)
			{
				console.log('responseJson==',responseJson);
				this.setState({
					issuccess:false,
					surveysubmoitted:'Survey is submitted Successfully'
				});
			}
		});

	}
	
	render() {	
	
        return (
        	<div className="ProducerSaveDate AddStoryBoard RecordVideo">
        		<div className="successmsg">{this.state.fileuploadsuccess}</div>
        		{
					this.state.loading ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}
							/>							
						   <div id="loadingstatus"></div>
						</div>
						: null
				}
				<div className="row">
					<div className="col-8">
						<h3>Survey Form</h3><br/>	
						<p>Your feedback is very important to us. Thank you for letting us know how we are doing and how we may serve you better.</p>	<br/>					
					</div>
					<div className="col-4"></div>
					</div>
					<div className="row">
					<div className="col-12">{this.state.errormsg}</div>
					</div>
					
					{
						(this.state.issuccess ? 

						<div>
							<div className="row">
								<div className="col-12">
									<label>Do you love your video? </label><br/>
									<fieldset className="rating">
									    <input type="radio" onChange={this.onchange.bind(this)} id="star5" name="q1_answer" value="5" />
									    <label for="star5">5 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star4" name="q1_answer" value="4" />
									    <label for="star4">4 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star3" name="q1_answer" value="3" />
									    <label for="star3">3 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star2" name="q1_answer" value="2" />
									    <label for="star2">2 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star1" name="q1_answer" value="1" />
									    <label for="star1">1 star</label>
									</fieldset>
								</div>
								<div className="col-12">
									<label>How would you rate your experience with Association Studios?</label><br/>
									<fieldset className="rating2">
									    <input type="radio" onChange={this.onchange.bind(this)} id="star55" name="q2_answer" value="5" />
									    <label for="star55">5 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star44" name="q2_answer" value="4" />
									    <label for="star44">4 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star33" name="q2_answer" value="3" />
									    <label for="star33">3 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star22" name="q2_answer" value="2" />
									    <label for="star22">2 stars</label>
									    <input type="radio" onChange={this.onchange.bind(this)} id="star11" name="q2_answer" value="1" />
									    <label for="star11">1 star</label>
									</fieldset>
								</div>
								<div className="col-12">
									<label>Anything else you would like us to know?</label><br/>
									<textarea style={{width:"50%"}} rows="5"  onChange={this.onchange.bind(this)} name="q3_answer"/>
									<div className="errorMsg">{this.state.errorfilename}</div><br/>
								</div>
							</div>
							<div className="row">
								<div className="col-6">
									<a className="forcusror graybtn ComIncLink btn btn-primary" onClick={this.submitSurvey.bind(this)} >Submit</a>
								</div>
								<div className="col-6"></div>
							</div>
						</div>:
						<div> {this.state.surveysubmoitted} </div>
						)
					}
				
			</div>	
		);
	}
}
export default Survey;