import React, { Component } from "react";
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../../services/PostData';
import { apiConfig } from "../../config/api"
import Loader from 'react-loader-spinner'
import Dropzone from 'react-dropzone-uploader'
import { Multiselect } from "multiselect-react-dropdown";
import { animateScroll as scroll } from 'react-scroll'
import debounce from "lodash.debounce";
import { Dropdown, SplitButton } from 'react-bootstrap';
import { Button, Collapse, Modal } from 'react-bootstrap';
class StockVideos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetList: [],
            originalAssetList: [],
            Inloading: false,
            selectedFile:[],
            selectedvideocat:[],     
            caterror:'',
            per: 9,
            page: 0,
            isData: true, 
            isreadytosend: true,  
            data:[],
            searchtext:'',
            projectlist:[],
            showAddToProject:false,
            boxfolderid:'113803491314',
            searchvideocat:'',
            selectedPositionId:'',
            searchText:'',    
        };
        this.onChange = this.onChange.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.onSelect=this.onSelect.bind(this);
        this.onRemove=this.onRemove.bind(this);
    }
    addToProject(downloadurl,stockfilename,filetype,videocat,fileid,e){
        console.log('downloadurl,stockfilename,filetype,videocat,fileid,e==',downloadurl,stockfilename,filetype,videocat,fileid,e);
        this.setState({
            fileurl:downloadurl,
            filename:stockfilename,
            filetype:filetype,
            videocat:videocat,
            fileid:fileid,
            selectedposition:'',
        })
        this.setState({ showAddToProject: true });
    }
    getPosition(id,e)
    {
        console.log('id==',id);
        GetData('getfileposition/'+id).then((result) => {
            console.log('result==',result);  
            let pagenumber = Math.ceil(result.position/this.state.per);
            this.setState({
                selectedposition:result.position,
                searchvideocat:'',
                searchText:'',
                page:pagenumber,
                data:[],
                selectedPositionId:id
            },function(){
                console.log('pagenumber==',pagenumber); 
               this.getListData(pagenumber) 
            })    
            
            
            //this.getListData(pagenumber)     
        });
    }
    loadPreviousData(e)
    {
        let pagenumber = this.state.page-1;
        if(pagenumber>0)
        {
            this.setState({
                page:pagenumber,
                data:[],
            },function(){
                console.log('pagenumber==',pagenumber); 
               this.getListData(pagenumber) 
            }) 
        } 
    }
    handleAddToProjectClose(e) {
        this.setState({
            showAddToProject: false,
        });
    }
    addStockVideoToProject(e){
        let filedetails = this.state.filedetails;
        //console.log('filedetails==',filedetails);
        if(this.state.selectedproject=='')
        {
            this.setState({
                errormsg:'Please select Project'
            })
        }
        else
        {
            this.setState({
                errormsg:''
            })
            let data = {
                'filename': this.state.filename,
                'fileid': this.state.fileid,
                'fileurl': this.state.fileurl,
                'filetype': this.state.filetype,
                'stockvideocat': this.state.videocat,
                'project_id': this.state.selectedproject,
            }
            console.log('data==',data);
            PostData('stockvideoaddtoproject', data).then((result) => {
                console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
                }
            });
        }

    }
    searchFilter(){
        this.setState({
            page: 1, 
            isreadytosend: true,
            data:[] 
        })
       this.getData(this.state.per,1,[]);
    }
    componentDidMount() {
        this.callListData();   
        Promise.all([this.getProjectList()])
            .then(([projectdata]) => {
            if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data});
            }           
        });
    }
    getProjectList(){
        console.log('calleddd=');
        return GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    handleProjectChange(e){
        console.log('selectedproject==',e.target.value);
        this.setState({ selectedproject: e.target.value });
    }
    callListData(){
        /*this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);            
        });*/
        window.onscroll = debounce(() => {
          this.handleScroll();
        }, 100);
        this.loadMore();
    }
    handleScroll = () => {
        var lastLi = document.querySelector(".rowlist > div:last-child");
       // console.log('lastLi==',lastLi);
        if (lastLi) {
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset && this.state.isData) {
                this.loadMore();
            }
        }
    };
    loadMore = () => {
        console.log('loadMore==',this.state.page);
        const isreadytosend = this.state.isreadytosend;
        const pageno=this.state.page+1;
        if (isreadytosend) {
            this.setState({ isreadytosend: false});
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    scrolling: true
                }),
                this.getListData(pageno)
            );
        }
    };  
    getListData(pageno) {
        //console.log('project_id==',project_id);this.setState({ loading: true});
        const { per, page, data } = this.state;
        console.log('pageeeeee=',page,data);
        this.getData(per,pageno,data,'');
    } 
    getData(per,page,data){
        this.setState({ Inloading: true,isreadytosend: false});
        let searchtext= this.state.searchText
        let searchvideocat= encodeURIComponent(this.state.searchvideocat)
        const url = `videodata/?per_page=${per}&page=${page}&searchtext=${searchtext}&searchvideocat=${searchvideocat}`;
        GetData(url).then((result) => {
            this.setState({ isreadytosend: true, Inloading: false });
            if (result.data.length > 0) {
                //console.log('data returnedddddddddd');
                this.setState({
                    data: [...data, ...result.data],
                    //data: [...data, ...result.data],
                    //originalAssetList:assets.data,
                    //data: result.data,
                    //assetList: [...assetList, ...result.data],
                    // originalAssetList:[...assetList, ...result.data],
                    scrolling: false,
                    isData: true,
                })
                //this.scrollToBottom();
                setTimeout(() => {
                    this.callEvent();  
                }, 3000); 
            }
            else {
                this.setState({
                    isData: false,
                })
            }
        });
    }
    callEvent(){
        const vids = document.getElementsByTagName('video')
        //--- Now we loop over all of the selected elements and add event listeners
        for (let i = 0; i < vids.length; i++) {
            vids[i].addEventListener( 'mouseover', function(e) { 
                // console.log('vids[i].play()==',vids[i]);
                         vids[i].play()   
                         vids[i].muted = false;  
             })
             vids[i].addEventListener( 'mouseout', function(e) {
                // console.log('vids[i].pause()==',vids[i]);
                  vids[i].pause()    
                  vids[i].muted = true;  
               //vids[i].pause()
            })
        } 
    }  
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }   
    updateInputValue(event){
        this.setState({
            searchText: event.target.value
        
       });
    } 
      
    handleChangeStatus = ({ meta, file }, status) => { 
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
    }
    removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }
    handleSubmit = (files, allFiles) => {
        let videocat=this.state.selectedvideocat;
        console.log('selectedvideocat===',videocat.length);
        if(videocat.length===0){
            this.setState({
                caterror:'Please select Category'
            });
        }
        else
        {  
            var loadermessage='Uploading';
            allFiles.forEach((f) => {
            // allFiles.forEach(f =>
                var mimetype= f.file.type;
                var checkvideo = mimetype.split('/');
                if(mimetype!='video/mp4' && checkvideo[0]=='video')
                {
                    loadermessage='Uploading and converting file in mp4'
                }
                else
                {
                    console.log('in elsee f=============',f)
                }
            })
            //if(this.checkFolderID()){
            this.setState({assetuploadloader:true,assetuploadmessage:loadermessage,caterror:''});
            var formdata = new FormData();
            const selectedfiles=this.state.selectedFile;
            var uniqueArray = this.removeDuplicates(selectedfiles, "name");
            for(var x = 0; x<uniqueArray.length; x++) {
                formdata.append('stockvideos', uniqueArray[x])
            }
            //formdata.append('title', 'test');
            //fileid
            formdata.append('folderid', this.state.boxfolderid) 
            videocat= JSON.stringify(videocat);
            formdata.append('videocategory', videocat)  
            //formdata.append('folderid', this.state.boxfolder_name)    
            let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
            let session_key = userdata.token;
            fetch(apiConfig.WEB_SERVICE_URL+'uploadstockvideos', {
                method: 'POST',
                body: formdata,
                headers: {
                        "session-key": session_key,
                    }
            })
            .then((response) => response.json())
            .then((res) => {
                console.log('cakkkeddd',res);
                if(res.statusCode===200)
                {               
                    this.setState({
                        assetuploadsuccessmsg:res.message,
                        assetuploadloader:false
                    });     
                    setTimeout(() => {
                        //this.setState({ assetuploadsuccessmsg: '',setAddAssetShow: false })
                        //this.getFolderListByFolderId(this.state.boxfolderid);
                        //this.props.loadFolderList();
                        allFiles.forEach(f => f.remove())                    
                        window.location.reload();                   
                    }, 3000);       
                }
            });   
            //}    
        }   
    }
    onSelect(selectedList, selectedItem) {
        console.log('selectedList, selectedItem==',selectedList, selectedItem,this);
        this.setState({
            selectedvideocat:selectedList
        });
    }
    onRemove(selectedList, removedItem) {
        this.setState({
            selectedvideocat:selectedList
        });
    }
    handleSearchVideoChange(e) {
        this.setState({ searchvideocat: e.target.value });
        console.log('searchvideocat===',e.target.value);
    }
    render() {  
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;     
        const loading = this.state.Inloading;
        let datalist ='';
        let searchText =' '
        searchText = this.state.searchText;
        let projects = this.state.projectlist.map((item, i) => {
            return <option className="folderlistitem" value={item._id}>
                {item.project_name}
            </option>;
        });
        if(this.state.originalAssetList.length>0) 
        {
            datalist = this.state.originalAssetList;
        } 
        else{
           datalist = this.state.data;
        }    
        if( datalist )
        {
            var listItems = datalist.map((item, index) => {
                let assetpath= '';
                let downloadurl=''
                //let assetfilename= item.files[0].originalname;
                let stockfilename=item.filename;
                stockfilename=stockfilename.split('.');
                let filetype=stockfilename[1]; 
                stockfilename=stockfilename[0]; 
                stockfilename=stockfilename.split('_').join(' ');                
                downloadurl=item.downloadurl;
                let videocat=item.videocategory;
                videocat = videocat.join(", ");
               // let filepage=item.filepage;
                let posterimage='';
                try{
                    posterimage=require('../../assets/images/stockassets/'+item.stockimage+'');
                }
                catch
                {
                    posterimage='';
                }
                let dynamicClassName='';
                if(this.state.selectedPositionId==item._id)
                dynamicClassName='selecteditem'
                return <div key={index} className={'col-4 AssetsListCol collist '+dynamicClassName}> 
                    {(() => {
                        if(downloadurl){
                            return(
                                <div class="AssetsListColIn">    
                                    {
                                        (userdata.role && userdata.role==='administrator' &&                               
                                        <video controls width="100%" key={downloadurl} height="220" poster={posterimage}>
                                            <source src={downloadurl}
                                                    type="video/mp4"/>
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                        )
                                    }
                                    {
                                        (userdata.role && userdata.role!='administrator' &&                               
                                        <video controls controlsList="nodownload" width="100%" key={downloadurl} height="220" poster={posterimage}>
                                            <source src={downloadurl}
                                                    type="video/mp4"/>
                                            Sorry, your browser doesn't support embedded videos.
                                        </video>
                                        )
                                    }
                                    <div class="AssetsColInText">
                                        <h4> {stockfilename} </h4><br/>
                                        {/* <h4>{filepage} </h4> */}  
                                        <h6>{videocat}</h6>  
                                        {
                                            ((searchText!=' ' || this.state.searchvideocat) &&
                                            <a className="target-link" onClick={this.getPosition.bind(this,item._id)}>
                                             <img src={require('../../assets/images/assets-icon/target.png')} />
                                            </a>
                                            )
                                        }
                                        <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,downloadurl,stockfilename,filetype,videocat,item.fileid)}>Add To Project</a>                                        
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return(
                                <div class="AssetsListColIn">                                   
                                    <div class="AssetsColInText">
                                        <h4> {stockfilename} </h4><br/>
                                        <h6>{videocat}</h6>                                           
                                        <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,downloadurl,stockfilename,filetype,videocat,item.fileid)}>Add To Project</a>                                         
                                    </div>
                                </div>
                            )
                        }
                    })()}                   
                </div>
            });
        }        
        let uploadstock='';
        if(userdata.role && userdata.role==='administrator')
        {
            uploadstock=`/${this.props.match.params.orgid}/upload-stocks`;
        }
        let videocategory=['Agriculture','Animals & Nature','Arts & Entertainment','Building & Construction','Destinations & Travel','Disasters & Emergencies','Education','Energy & Utilities','Environment & Weather','Fashion','Finance','Food & Beverage','Government','Health care','Holidays & Celebrations','Housing & Real Estate','Law Enforcement','Legal System','Logistics','Manufacturing','Medical','Nutrition','People','Babies/Kids','Young Adults','Men','Women','Elderly','Family','Retail','Spirituality & Religion','Sports & Recreation','Transportation','Workplace'];
        return (
             <div className="container-fluid AssetsList ProjectList"  ref="selfdiv">
                {
                    loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="panel panel-primary">
                <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-4 UploadBreadcrumb">
                                    Stock Videos
                                </div>                                
                                <div className="col-4 SearchFolder graphicsearch">                            
                                    <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/> 
                                </div>   
                                <div className="col-3 SearchFolder graphicsearch">                         
                                    <select name="searchvideocat" className="form-control" value={this.state.searchvideocat} onChange={this.handleSearchVideoChange.bind(this)} >
                                        <option value=" ">All Categories</option>
                                        {
                                        videocategory.map((value, index) => {   
                                                return (
                                                <option value={value}>{value}</option>
                                                    
                                                )                                                               
                                            })
                                        }
                                    </select>                                                                     
                                </div>
                                <div className="col-1 SearchFolder graphicsearch">                    
                                    <a className="search btn btn-primary addbtn" onClick={this.searchFilter.bind(this)}>Go</a>                                 
                                </div>                              
                            </div> 
                            {
                                (userdata.role && userdata.role==='administrator' &&
                                <div className="row DragFileRow">
                                    <div className="col-3 forpaddingtopbottom">
                                        <div className="error-msg">
                                            {this.state.caterror}
                                        </div>
                                        <Multiselect                                       
                                            options={videocategory}
                                            selectedValues={this.state.selectedValue}
                                            isObject={false}
                                            onSelect={this.onSelect}
                                            onRemove={this.onRemove}
                                            placeholder='Please select category'
                                        />
                                    </div>
                                    <div className="col-9 DragFileBox mobileDisable">
                                        <div className="successmsg">
                                            {this.state.assetuploadsuccessmsg}
                                        </div>                                                
                                        {
                                            this.state.assetuploadloader ?
                                                <div className="loaderspinner">
                                                    <Loader
                                                        type="TailSpin"
                                                        color="#D10000"
                                                        height={100}
                                                        width={100}                              
                                                    />
                                                    <div className="loadermessage">{this.state.assetuploadmessage}</div>
                                                </div>
                                            :null      
                                        }     
                                        <Dropzone    
                                        // getUploadParams={getUploadParams}
                                            onChangeStatus={this.handleChangeStatus}
                                            onSubmit={this.handleSubmit}
                                            inputContent="Drop Or Upload graphic videos Here"
                                            //accept="image/*,video/*"
                                            />                                
                                    </div>                                                  
                                </div>                            
                                )
                            }
                        </div>
                    </div>
                    { (this.state.selectedPositionId && this.state.page>1 &&
                        <a className="btn btn-primary addbtn" onClick={this.loadPreviousData.bind(this)}>Load Previous Videos</a>
                        )
                    }
                    <div className="row AssetsListGrid rowlist margintop20">                        
                        {listItems}
                    </div>                                                     
                </div>
                <Modal show={this.state.showAddToProject}>
                    {
                        this.state.loading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Add To Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="error-msg">{this.state.errormsg}</div>
                        <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                            <option value=" ">Select Project</option>
                            {projects}
                            {/* <option value="Active">Active</option>
                            <option value="On-hold">On-hold</option>
                            <option value="Completed">Completed</option> */}
                        </select>
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleAddToProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.addStockVideoToProject.bind(this)}>
                            Add To Project
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
export default StockVideos;
