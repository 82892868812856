import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData} from '../services/PostData';
import dateFormat from 'dateformat';
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import Loader from 'react-loader-spinner'
class SubsInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
			itemPerPage:10,
            searchText: '',
            taskList: [],
            originalTaskList: [],
            loading: false,
        };
        this.updateInputValue = this.updateInputValue.bind(this);
        //console.log('props====',props);
    }
    componentDidMount() {
        this.setState({loading: true});
        let data = '';
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        console.log('userdata=======',userdata);
		GetData('userinbox/'+this.props.match.params.orgid).then((result) => {             
            let responseJson = result;
            //console.log('responseJson==',responseJson);
            this.setState({
                taskList: responseJson.data,
                originalTaskList: responseJson.data,
                loading: false
            });
        });        
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
    }

    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalTaskList.length; i++) {
                    let assignment=this.state.originalTaskList[i].project_name;
                    if(assignment!=null)
                    {
                        if (assignment.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalTaskList[i]);
                        }
                    }
                }
                this.setState({
                    taskList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    taskList: this.state.originalTaskList, 
                });
            }
       });
    }

    render() {
        let pboard = '';
        let createprojectlink='';
        let projectlink='';
		let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
		let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let projectboard = this.state.taskList.slice(indexOfFirstTodo, indexOfLastTodo);
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        let productionteamlink='/org/'+this.props.match.params.orgid+'/teams';
        let currentuserid = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user.id;

        

        if(projectboard)  
        {  
            pboard=projectboard.map((item) => {
                const propertyName = item.type;
                let innertext='';	
                let checkusertype='';
                let projectlink="/subs/org/"+item.org_id+"/project/view/"+item._id;

                let orgdata=item.org_data;
               // let org_name=item.org_data[0].org_name;
                let orglogo='';
                if(orgdata[0].orglogo)
                    orglogo=orgdata[0].orglogo;
                else
                orglogo='';

                if(item.boarddata.length>0)
                {
                return <li className="itemlist InboxProjectRepeat">
                        <div className="row">
                            <div className="col-12 ChatBoxDesign">
                                <div className="project-title">
                                    { (orglogo && orglogo!='""')?<img  className="mr-3" title={orgdata[0].organization_name} style={{maxHeight:"80px"}} src={require('../assets/images/organization-logo/'+orglogo+'')}/>:''
                                        }
                                    <Link to={projectlink}>{item.project_name}</Link> 
                                    &nbsp; Due : {dateFormat(item.due_date, "mmm d, yyyy")}
                                </div>
                                {(() => {
                                    return(
                                    item.boarddata.reverse().map((value, index) => {
                                        let chatuserdisplayname='';
                                        let userdatadisplayname='';
                                        let chatuseravtare='';
                                        let userdataavtar='';
                                        if(value.chatuserdata[0])
                                        {
                                            chatuseravtare=(value.chatuserdata[0].avatar)?value.chatuserdata[0].avatar:value.chatuserdata[0].avtar
                                        }
                                        if(value.userdata[0])
                                        {
                                            userdataavtar=(value.userdata[0].avatar)?value.userdata[0].avatar:value.userdata[0].avtar
                                        }   
                                        if(value.chatuserdata[0] && value.chatuserdata[0].display_name)
                                        {
                                            chatuserdisplayname=value.chatuserdata[0].display_name
                                        }
                                        if(value.userdata[0] && value.userdata[0].display_name)
                                        {
                                            userdatadisplayname=value.userdata[0].display_name
                                        }                                     
                                        return(   
                                       <div class="InboxChildRep">
                                            { (value.type==='chat') ?
                                                <div class="ChatImgThumb">
                                                    <img title={chatuserdisplayname}  alt={chatuserdisplayname} src={chatuseravtare}/>
                                                </div>                                        
                                            :                                        
                                            <div class="ChatImgThumb">
                                                <img title={userdatadisplayname} alt={userdatadisplayname} src={userdataavtar}/>
                                            </div>
                                            }
                                           <div className="type"><label>Type: </label>{value.type}</div>
                                            <div className="PB_create_date"><label>Date: </label>{dateFormat(value.create_date, "mmmm dS, yyyy")}
                                            </div>
                                            {(() => {
                                                if (value.type==='task') {
                                                    // console.log("value.type===",value.type);
                                                     return (
                                                         <div className="row TextChildBox">
                                                                 {
                                                                     (value.taskdata)&&
                                                                     value.taskdata.map((task, index) => {
                                                                         //console.log("task===",task);
                                                                         return(
                                                                             <div class="ProjectRepeatTbl">
                                                                                 <p><strong>Team Member: </strong> {task.teammember}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                 <strong>Assignment: </strong>	{task.assignment}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                                 <strong>Task Due Date: </strong> {dateFormat(task.due_date, "mmmm dS, yyyy")}
                                                                                 </p>
                                                                             </div>
                                                                         )											
                                                                     })
                                                                 
                                                                 }		
                                                         </div>
                                                     )
                                                 }
                                                 else if(value.type==='chat')
                                                 {
                                                    let echatmessage = convertFromRaw(JSON.parse(value.chatmessage));
                                                    let	echatmessageeditorState = EditorState.createWithContent(echatmessage, null);
                                                    return (
                                                    <div className="row">
                                                        <div class="PB_save_date_row">
                                                            <p class="InBoxSubName">
                                                            <strong>{value.chatuserdata[0].displayname} </strong>
                                                            </p>
                                                            <p class="InMemberPara">
                                                            <Editor editorState={echatmessageeditorState} readOnly={true} />
                                                            </p>
                                                        </div>                                                 
                                                    </div>
                                                    )
                                                 }
                                                 else if (value.type==='storyboard') {
                                                    return (
                                                        <div className="row">
                                                            {
                                                                value.message.map((values, index) => {
                                                                    let message = convertFromRaw(JSON.parse(values));
                                                                    let visual = convertFromRaw(JSON.parse(value.visual[index]));
                                                                    let	editorState1 = EditorState.createWithContent(message, null);
                                                                    let	editorState2 = EditorState.createWithContent(visual, null);
                                                                    return(
                                                                        <div class="ProjectRepeatTbl">
                                                                            <p class="InBoxSubName">
                                                                            <strong>Scene: </strong>  {(index+1)} <br/>
                                                                            <strong> Message: </strong> 
																			</p>
																			<p><Editor editorState={editorState1}
                                                                                readOnly={true} />
                                                                            <strong>Visual: </strong> <Editor editorState={editorState2}
                                                                                readOnly={true} />
                                                                            </p>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                                else if (value.type==='script') {
                                                   const scrptlink="../../../../scriptdata/"+value.scriptfile;
                                                    return (
                                                        <div className="row">
                                                            <div class="ProjectRepeatTbl">
                                                                <p class="InBoxSubName">
                                                                <strong>Upload Title: </strong> {value.scripttitle}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Download Uploaded Script: </strong> <a href={scrptlink} target="_blank" download>Download</a> &nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Added Time: </strong> {value.scripttime}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if (value.type === 'voice over') {
                                                    let voiceoverlink="../../../../voiceover/"+value.voicefile;	return (
                                                        <div className="row">
                                                            <div class="ProjectRepeatTbl">
                                                                <p>
                                                                    <strong>Voice Over Title: </strong> {value.voicetitle}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                            <strong>Download Voice Over: </strong> <a href={voiceoverlink} target="_blank" download>Download</a> &nbsp;&nbsp;
                                                                            {/* |&nbsp;&nbsp;
                                                                            <strong>Added Time: </strong> {item.voicetime} */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else if(value.type === 'draft')
                                                {
                                                    const draftvideosrc="../../../../draft/"+value.draftfile;
                                                    return (
                                                        <div className="row">
                                                            <div class="ProjectRepeatTbl">
                                                                <p>
                                                                    <a className="forcusror" target="_blank" href={draftvideosrc} download> 
                                                                    <strong>Download</strong>
                                                                </a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else
                                                {
                                                    return (
                                                        <div className="row">
                                                            <div class="ProjectRepeatTbl">
                                                                <p>
                                                                <strong>Location:</strong> {value.location}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Purpose:</strong> {value.purpose}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Arrive:</strong> {dateFormat(value.arrive, "mmmm dS, yyyy")}&nbsp;&nbsp;|&nbsp;&nbsp;
                                                                <strong>Depart:</strong> {dateFormat(value.depart, "mmmm dS, yyyy")}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })()
                                        }
                                       </div>
                                        )
                                    })
                                )                                    
                            })()}									
                        </div>
                    </div>
                </li>
                }
            })	
        }
        let loading=this.state.loading;
		return (
			 <div className="container-fluid ProjectList"  ref="selfdiv">
                 {
	        		loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="row">
                            <div className="col-md-7 col-lg-7">
                                <h4 class="PageTopTitle">Inbox</h4>
                            </div>
                            <div className="col-md-2 col-lg-2"></div>
                            <div className="col-md-3 col-lg-3">
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        {pboard}
                     </div>
                    <div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.taskList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    </div>
                </div>
            </div>
		);
    }
}
export default SubsInbox;
