import React, { Component } from "react";
import { Row, FormGroup, FormControl, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import { withRouter,Redirect,Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {PostData,GetData} from '../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
			itemPerPage:3,
            searchText: '',
            isdiagram:true,
            isMax:false,
            projectList: [],
            originalProjectList: []
        };
        //this.handlePageChange = this.handlePageChange.bind(this);
        this.projectDetails = this.projectDetails.bind(this);
        this.deleteMessage = this.deleteMessage.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.setSize=this.setSize.bind(this);
    }
    componentDidMount() {
        let data = '';
        GetData('projects').then((result) => {             
            let responseJson = result;
            console.log('responseJson==',responseJson.data);
            this.setState({
                projectList: responseJson.data,
                originalProjectList: responseJson.data
            });
        });       
    }
     handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        console.log(this.state.projectList);
    }
    projectDetails(item, index) {
        console.log(index);
    }
    deleteMessage(item, index) {
       //showconfrim("Do you want to delete this Project?", this.deleteProject(item, index));
       // console.log('delete');
    }
    deleteProject(item, index) {
       // $("#confirmwindow").modal('hide');
        //console.log('delete');
    }
    setSize(){
        this.setState({
            isMax:!this.state.isMax
        });             
        if(!this.state.isMax){
        }
        console.log(this.props.children);
        if(this.props.children[1].props['data-event']){
            var self=this;
            setTimeout(()=>{
                self.props.children[1].props['data-event'].call();  
            },700);         
        }
    }
  isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalProjectList.length; i++) {
                    let orgname=this.state.originalProjectList[i].project_name;
                    if(orgname!=null)
                    {
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalProjectList[i]);
                        }
                    }
                }
                this.setState({
                    projectList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    projectList: this.state.originalProjectList, 
                });
            }
       });
    }

    render() {
        let description='';
		let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
		let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
		let renderedProjects = this.state.projectList.slice(indexOfFirstTodo, indexOfLastTodo);
        let createprojectlink='/org/'+this.props.match.params.orgid+'/project/create';        
		let listItems = renderedProjects.map((item, index) => {
        if(item.description){
            if (this.isJson(item.description))
            {
            const contentState = convertFromRaw(JSON.parse(item.description));
            const editorState = EditorState.createWithContent(contentState);
            const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
            description = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
           }
            else
            {
                description= item.description;
            }
        }
            return <tr key={index}>
                <td>                    
                    <Link to={"/org/"+item.org_id+"/project/view/"+item._id}><u>{item.project_name}</u></Link>
                </td>
		        <td>{description}</td>
                <td>{item.due_date}</td>                
		        <td><i className="glyphicon glyphicon-trash" onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteMessage(item, index) } } > </i></td> 
		    </tr>
		});
		return (
			 <div className="container"  ref="selfdiv">
                <br />
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-md-2 col-lg-2">
                                <h4 >Project List</h4>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <input type="text" className="form-control" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                            <div className="col-md-2 col-lg-2">
                                <Link to={createprojectlink} className="btn btn-sm btn-success">Create Project</Link>                                
                            </div>
                            <div  className="col-md-2 col-lg-2">
                                 
                            </div>
                        </div>
                    </div>
                    <div className="panel-body">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th><b>Project Name</b></th>
                                    <th><b>Description</b></th>
                                    <th><b>Due date</b></th>                                    
                                    <th><b>Action</b></th>
                                </tr>
                            </thead>
                            <tbody>
                            {listItems}
                            
                                
                            </tbody>
                        </table>
                    </div>
                    <div >
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={3}
                            totalItemsCount={this.state.projectList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />

                    </div>
                </div>
            </div>
		);
    }
}
export default Projects;
