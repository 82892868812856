import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {PostData,DeleteData} from '../../services/PostData';
class Organizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
			itemPerPage:10,
            searchText: '',
            isdiagram:true,
            isMax:false,
            projectList: [],
            originalProjectList: []
        };
        //this.handlePageChange = this.handlePageChange.bind(this);
       // this.projectDetails = this.projectDetails.bind(this);
        this.deleteOrganization = this.deleteOrganization.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        //this.setSize=this.setSize.bind(this);
    }
    componentDidMount() {
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        let data ={user_id:userdata.ID,role:userdata.role}
        PostData('organizations',data).then((result) => {             
            let responseJson = result.data;
            responseJson.sort((a, b) => a.organization_name.trim().localeCompare(b.organization_name.trim(), 'es', {sensitivity: 'base'}))
            const filtereddata = responseJson.filter((x) => x.isorgeligibletouser === true);
            this.setState({
                projectList: filtereddata,
                originalProjectList: filtereddata
            });
        });             
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });
        console.log(this.state.projectList);
    }

   
    deleteOrganization(item, index) {
        DeleteData('organization/'+item._id).then((result) => {       
            //this.props.history.push("/organization/list");
            window.location.reload();
        });
       //showconfrim("Do you want to delete this Project?", this.deleteProject(item, index));
       // console.log('delete');
    }


    updateInputValue(event) {
        this.setState({
            searchText: event.target.value
        }, function () {
            let textToSearch = this.state.searchText;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalProjectList.length; i++) {
                    let orgname=this.state.originalProjectList[i].organization_name;  
                    if(orgname!=null)
                        orgname=orgname.toLowerCase();
                    textToSearch=textToSearch.toLowerCase();                      
                    if(orgname!=null)
                    {
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalProjectList[i]);
                        }
                    }
                }
                this.setState({
                    projectList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    projectList: this.state.originalProjectList, 
                });
            }
       });
    }
    render() {       
		var indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
		var indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        if( this.state.projectList )
        {
    		var renderedProjects = this.state.projectList.slice(indexOfFirstTodo, indexOfLastTodo);
    		var listItems = renderedProjects.map((item, index) => {
                console.log('item===',item);
    		    return <tr key={index}>
    		        <td class="tbl_org_name">                    
                        <Link to={"/org/"+item._id+"/projects"}><u>{item.organization_name}</u></Link>
                    </td>
    		        <td class="tbl_org_street">{item.street}</td>
                    <td class="tbl_org_city">{item.city}</td>
                    <td class="tbl_org_state">{item.state}</td>
                    <td class="tbl_org_zip">{item.zip}</td>
                    <td class="tbl_org_phone">{item.phone}</td>
                    <td class="tbl_org_twitter">{item.twitter}</td>
                    <td class="tbl_org_linkedin">{item.linkedin}</td>
    		        <td class="tbl_org_action TblIconDel"><i className="glyphicon glyphicon-trash" onClick={(e) => { if (window.confirm('All its related data will be deleted. Are you sure you want to delete?')) this.deleteOrganization(item, index) } } > <img src={require('../../assets/images/delete-icon.png')}/> </i></td> 
    		        <td class="tbl_org_edit TblIconedit"><Link to={"/organization/edit/"+item._id} className=""><img src={require('../../assets/images/edit-icon.png')}/></Link></td>
    		    </tr>
    		});
        }
		return (
			 <div className="container-fluid"  ref="selfdiv">
			 <div class="row wrapper-box">
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-5 col-lg-7">
                                <h4 class="PageBarTitle">Organization List</h4>
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 pull-right">
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-2 pull-right">
                                <Link to="/organization/create" className="create_org btn btn-info">Create Organization</Link>
                            </div>
                            
                        </div>
                    </div>
                    <div className="panel-body">
                        <table className="table table-striped ListTable">
                            <thead>
                                <tr>
                                    <th class="tbl_org_name"><b>Organization Name</b></th>
                                    <th class="tbl_org_street"><b>Street</b></th>
                                    <th class="tbl_org_city"><b>City</b></th>
                                    <th class="tbl_org_state"><b>State</b></th>
                                    <th class="tbl_org_zip"><b>Zip Code</b></th>
                                    <th class="tbl_org_phone"><b>Phone</b></th>
                                    <th class="tbl_org_twitter"><b>Twitter</b></th>
                                    <th class="tbl_org_linkedin"><b>Linkedin</b></th>
                                    <th class="tbl_org_action"><b>Action</b></th>
                                    <th class="tbl_org_edit"><b></b></th>
                                </tr>
                            </thead>
                            <tbody>
                            {listItems}
                            </tbody>
                        </table>
                    { (this.state.projectList) &&
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.projectList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    }
                    </div>
                    </div>
                    <div >
                </div>
            </div>
            </div>
		);
    }
}
export default Organizations;
