import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import {GetData,PostData} from '../../services/PostData';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import Loader from 'react-loader-spinner'
import UploadStock from './UploadStock.js';
import debounce from "lodash.debounce";
class Stocks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            itemPerPage:10,
            assetList: [],
            originalAssetList: [],
            Inloading: false,
            activeItem:'',
            selectedProjectId:'',
            isSelectedMessage:'',
            isfirst:true,
            showAddToProject:false,
            projectlist:[],
            per: 9,
            page: 0,
            isData: true, 
            isreadytosend: true,  
            data:[],
            searchtext:'',
        };
        this.onChange = this.onChange.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }
    addToProject(title,url,downloadurl,stockfilename,filetype,fileid,e){
        console.log('downloadurl,stockfilename,filetype,videocat,fileid,e==',title,url,downloadurl,stockfilename,filetype,fileid,e);
        this.setState({
            title:title,
            url:url,
            fileurl:downloadurl,
            filename:stockfilename,
            filetype:filetype,
            fileid:fileid
        })
        this.setState({ showAddToProject: true });
    }
    handleAddToProjectClose(e) {
        this.setState({
            showAddToProject: false,
        });
    }
    addStockGraphicToProject(e){
        let filedetails = this.state.filedetails;
        //console.log('filedetails==',filedetails);
        if(this.state.selectedproject=='')
        {
            this.setState({
                errormsg:'Please select Project'
            })
        }
        else
        {
            this.setState({
                errormsg:''
            })
            let data = {
                'title': this.state.title,
                'url': this.state.url,
                'filename': this.state.filename,
                'fileid': this.state.fileid,
                'fileurl': this.state.fileurl,
                'filetype': this.state.filetype,
                'project_id': this.state.selectedproject,
            }
            console.log('data==',data);
            PostData('stockgraphicaddtoproject', data).then((result) => {
                console.log('result==',result);
                if (result.statuscode === 200) {
                    this.setState({ successmsg: result.message });
                    setTimeout(() => this.setState({ successmsg: '',selectedproject:'',showAddToProject:false }), 3000);
                }
            });
        }

    }
    handleProjectChange(e){
        console.log('selectedproject==',e.target.value);
        this.setState({ selectedproject: e.target.value });
    }
    searchFilter(){
        this.setState({
            page: 1, 
            isreadytosend: true,
            data:[] 
        })
       this.getData(this.state.per,1,[],'');
    }
    searchByKeyWord(keyword,e){
        console.log('keyword,e==',keyword,e);        
        this.setState({
            searchText:keyword,
            page: 1, 
            isreadytosend: true,
            data:[] 
        })
       this.getData(this.state.per,1,[],keyword);
      
    }
    componentDidMount() {
        this.setState({ Inloading: true});
        this.callListData();   
        Promise.all([this.getProjectData(),this.getProjectList()])
        .then(([projects,projectdata])  => {                    
            if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data});
            }
            setTimeout(() => {
                this.callEvent();  
            }, 3000);   
        });
        console.log('caleeeeeeeeeeedddddddddd');
        
    }
    getProjectList(){
        console.log('calleddd=');
        return GetData('orgprojectlist/'+this.props.match.params.orgid).then((result) => {
            return result;
        });
    }
    callListData(){
        /*this.scrollListener = window.addEventListener("scroll", e => {
            this.handleScroll(e);            
        });*/
        window.onscroll = debounce(() => {
          this.handleScroll();
        }, 100);
        this.loadMore();
    }
    handleScroll = () => {
        var lastLi = document.querySelector(".rowlist > div:last-child");
       // console.log('lastLi==',lastLi);
        if (lastLi) {
            var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            var pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastLiOffset && this.state.isData) {
                this.loadMore();
            }
        }
    };
    loadMore = () => {
        console.log('loadMore==',this.state.page);
        const isreadytosend = this.state.isreadytosend;
        const pageno=this.state.page+1;
        if (isreadytosend) {
            this.setState({ isreadytosend: false});
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                    scrolling: true
                }),
                this.getListData(pageno)
            );
        }
    };
        
    getListData(pageno) {
        //console.log('project_id==',project_id);this.setState({ loading: true});
        const { per, page, data } = this.state;
        console.log('pageeeeee=',page,data);
        this.getData(per,pageno,data,'');
    }
    getData(per,page,data,searchkey){
        this.setState({ Inloading: true,isreadytosend: false});
        let searchtext=''
        if(searchkey)
        searchtext= searchkey
        else
        searchtext= this.state.searchText
        const url = `graphicdata/?per_page=${per}&page=${page}&searchtext=${searchtext}`;
        GetData(url).then((result) => {
            this.setState({ isreadytosend: true, Inloading: false });
            if (result.data.length > 0) {
                //console.log('data returnedddddddddd');
                this.setState({
                    data: [...data, ...result.data],
                    //data: [...data, ...result.data],
                    //originalAssetList:assets.data,
                    //data: result.data,
                    //assetList: [...assetList, ...result.data],
                    // originalAssetList:[...assetList, ...result.data],
                    scrolling: false,
                    isData: true,
                })
                //this.scrollToBottom();
                setTimeout(() => {
                    this.callEvent();  
                }, 3000); 
            }
            else {
                this.setState({
                    isData: false,
                })
            }
        });
    }
    callEvent(){
        var ua = navigator.userAgent.toLowerCase();
        var is_safari = (ua.indexOf("safari/") > -1 && ua.indexOf("chrome") < 0);        
        const vids = document.getElementsByTagName('video')
        for (let i = 0; i < vids.length; i++) {
            vids[i].addEventListener( 'mouseover', function(e) { 
                if(!is_safari)
                vids[i].muted = false;
                vids[i].play() 
            })
            vids[i].addEventListener( 'mouseout', function(e) {
                console.log('vids[i].pause()==',vids[i]);
                if(!is_safari)
                vids[i].muted = true; 
                vids[i].pause()                  
            })            
        }
        //--- Now we loop over all of the selected elements and add event listeners
        /*vids.addEventListener("loadedmetadata", function() {
         this.currentTime = 10;
        }, false);*/
        /*for (let i = 0; i < vids.length; i++) {
            vids[i].addEventListener( 'mouseover', function(e) { 
                // console.log('vids[i].play()==',vids[i]);
                         vids[i].play()   
                         vids[i].muted = false;  
             })
             vids[i].addEventListener( 'mouseout', function(e) {
                // console.log('vids[i].pause()==',vids[i]);
                  vids[i].pause()    
                  vids[i].muted = true;  
               //vids[i].pause()
            })
        }*/ 
    }
    getProjectData () {
        return GetData('projects/').then((result) => {  
            return result;
        });
    }
    getAssetsData () {
        return GetData('stockgraphics/').then((result) => {  
            return result;
        });
    }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });        
    }
    setShow(clickitem){
        console.log('item===',clickitem);
        const projects=this.state.projects;
        projects.map((item) => {
            if(item._id==clickitem.projectid)
            {
                //const selectedProjectId=clickitem._id
                console.log('activeItem===',clickitem.projectid);
                this.setState({ selectedProjectId:clickitem.projectid});
            }
        });
        this.setState({setShow:true,activeItem:clickitem});
    }
    handleClose(e){
        this.setState({setShow:false,selectedProjectId:''});
    }
    onChange(e){
        this.setState({[e.target.name]:e.target.value});
    }
    handleSave(e){
        if(!this.state.selectedProjectId)
        {
            this.setState({
                isSelectedMessage:"Please select project"
            });
            return false;
        }
        this.setState({ Inloading: true });
        let data={
            'assetid':this.state.activeItem._id,
            'project_id':this.state.selectedProjectId
         }
        PostData('stockassetaddtoproject',data).then((result) => {
            console.log('result==',result);             
            if(result.statuscode===200)
            {
                //this.setState({associatedusers:result.data});
                this.setState({setShow:false,Inloading: false,selectedProjectId:'',assetList: result.data,
                originalAssetList:result.data});
                //this.props.history.push("/org/"+this.props.match.params.id+"/project/list");
            }               
        });
    }   
    
    filterGraphics(catname){
        this.filterData( catname ); 
    }
    updateInputValue(event){
        this.setState({
            searchText: event.target.value        
       });
    }
    filterData(catname) {
        console.log('catname===',catname,this.state.originalAssetList);
        this.setState({
            searchText: catname
        }, function () {
            let textToSearch = catname;
            if (textToSearch != undefined || textToSearch != '') {
                let searchData = [];
                for (var i = 0; i < this.state.originalAssetList.length; i++) {
                    let orgname=this.state.originalAssetList[i].stockcategory;
                    if(orgname!=null)
                    {
                        console.log('check===',orgname,textToSearch);
                        if (orgname.indexOf(textToSearch) != -1) {
                            searchData.push(this.state.originalAssetList[i]);
                        }
                    }
                }
                this.setState({
                    assetList: searchData
                });
            }

            if(textToSearch == '') {
                this.setState({
                    assetList: this.state.originalAssetList, 
                });
            }
       });
    }
    loadGraphics()
    {
        window.location.reload(); 
    }
    render() {       
        const handleClose = this.state.handleClose;
        const handleShow = this.state.setShow;
        const loading = this.state.Inloading;
        let activeItem = this.state.activeItem;
        let isSelectedMessage=this.state.isSelectedMessage;
        let projects = this.state.projectlist.map((item, i) => {
            return <option className="folderlistitem" value={item._id}>
                {item.project_name}
            </option>;
        });
        let datalist ='';
        if(this.state.originalAssetList.length>0) 
        {
            datalist = this.state.originalAssetList;
        } 
        else{
           datalist = this.state.data;
        }
        
        if( datalist )
        {
            var i=0;
            var listItems = datalist.map((item, index) => {
                let stockfilename=item.filename;
                stockfilename=stockfilename.split('.');
                let filetype=stockfilename[1]; 
                stockfilename=stockfilename[0];
                let title=item.title;
                let url = item.url
                let downloadurl=item.downloadurl+'#t=2';
                
                i++;
                return <div key={index} className="col-4 AssetsListCol">                      
                        {(() => {
                           if(downloadurl){
                            return(
                                <div class="AssetsListColIn graphicslist">                                   
                                    <video muted playsinline  controls id={'video'+i} width="100%" preload="metadata" key={downloadurl} height="220">
                                        <source src={downloadurl}
                                                type="video/mp4"/>
                                        Sorry, your browser doesn't support embedded videos.
                                    </video>
                                    <div class="AssetsColInText">
                                        <h4> {title} </h4><br/>
                                        {/* <h4>{filepage} </h4> */}  
                                        {/*<a className="btn btn-primary" href={url} target="_blank">Buy Now</a> */} 
                                        <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,title,url,downloadurl,stockfilename,filetype,item.fileid)}>Add To Project</a>                                             
                                    </div>
                                </div>
                            )
                        }
                        else{
                            return(
                                <div class="AssetsListColIn">                                   
                                    <div class="AssetsColInText">
                                        <h4> {title} </h4><br/>
                                         {/*<a className="btn btn-primary" href={url} target="_blank">Buy Now</a> */} 
                                         <a className="btn btn-primary addbtn" onClick={this.addToProject.bind(this,title,url,downloadurl,stockfilename,filetype,item.fileid)}>Add To Project</a>                                                  
                                    </div>
                                </div>
                            )
                        }
                        })()}
                   
                </div>
            });
        }
        let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        let uploadstock='';
        if(userdata.role && userdata.role==='administrator')
        {
            uploadstock=`/${this.props.match.params.orgid}/upload-stocks`;
        }
        return (
             <div className="container-fluid AssetsList"  ref="selfdiv">
                 {
                    loading ?
                        <div className="loaderspinner">
                            <Loader
                                 type="TailSpin"
                                 color="#D10000"
                                 height={100}
                                 width={100}                                 
                            />
                        </div>
                    :null 
                } 
                <div className="panel panel-primary">
                    <div className="WrapBarTop panel-heading">
                        <div className="panel-heading">
                            <div className="row">
                                <div className="col-md-4">
                                    <h4 class="PageTopTitle">Stock Graphics</h4>
                                </div>                       
                                <div className="col-8 SearchFolder graphicsearch"> 
                                    <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/> 
                                    <a className="search" onClick={this.searchFilter.bind(this)}><img src={require('../../assets/images/search.png')}/></a> 
                                    <div className="filterskeyword">
                                        Try:<a  className="aforcusror" onClick={this.searchByKeyWord.bind(this,'logo')}> logos</a>, <a className="aforcusror"  onClick={this.searchByKeyWord.bind(this,'photo')}>photos</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'slideshow')}>slideshow</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'timeline')}>timeline</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'app')}>app</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'conference')}>conferences</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'promo')}>promo</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'typography')}>typography</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'transition')}>transitions</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'cartoon')}>cartoons</a>, <a className="aforcusror" onClick={this.searchByKeyWord.bind(this,'animation')}>animations</a>
                                    </div>                                
                                </div>                     
                                <div  className="col-md-2 col-lg-2">
                                    
                                </div>
                            </div>
                            {
                                (userdata.role && userdata.role==='administrator' &&
                                <UploadStock loadGraphics={this.loadGraphics} orgid={this.props.match.params.orgid}/>
                                )
                            }
                        </div>
                    </div>
                    <div className="row AssetsListGrid rowlist">
                        
                        {listItems}
                    </div>            
                    <Modal show={this.state.showAddToProject}>
                    {
                        this.state.Inloading ?
                            <div className="loaderspinner">
                                <Loader
                                    type="TailSpin"
                                    color="#D10000"
                                    height={100}
                                    width={100}
                                />
                            </div>
                            : null
                    }
                    <Modal.Header class="SelectProducerHead">
                        <Modal.Title>Add To Project</Modal.Title>
                    </Modal.Header>
                    <Modal.Body class="SelectProducerModel">
                        <div className="succes-msg">{this.state.successmsg}</div>
                        <div className="error-msg">{this.state.errormsg}</div>
                        <select name="projectname" className="form-control" value={this.state.selectedproject} onChange={this.handleProjectChange.bind(this)} >
                            <option value=" ">Select Project</option>
                            {projects}
                            {/* <option value="Active">Active</option>
                            <option value="On-hold">On-hold</option>
                            <option value="Completed">Completed</option> */}
                        </select>
                    </Modal.Body>
                    <Modal.Footer class="FullWidthModelFoot">
                        <Button variant="secondary" onClick={this.handleAddToProjectClose.bind(this)}>
                            X
                        </Button>
                        <Button variant="primary" onClick={this.addStockGraphicToProject.bind(this)}>
                            Add To Project
                        </Button>
                    </Modal.Footer>
                </Modal>                    
                </div>
            </div>
        );
    }
}
export default Stocks;
