import React, { Component } from "react";
import OrderVideoForm from './OrderVideoForm'
class OrderVideos extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(){
		super();		
	};	
    render() {
	return (
        	<div className="container-fluid AddProjectPage MobPaddHide create-project"  ref="selfdiv">
				<div className="panel panel-primary">
					<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-12 col-md-7 col-lg-7">
									<h4 class="PageTopTitle">Order Videos</h4>
								</div>
								<div className="col-md-3 col-lg-3"> </div>
								<div className="col-md-2 col-lg-2"> </div>
								<div  className="col-md-2 col-lg-2"> </div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid">
						<OrderVideoForm orgid={this.props.match.params.orgid} />				
					</div>	
		        </div>					
			</div>	
		);
    }
}
export default OrderVideos;
