import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner'
import AddTeam from './AddTeam'
import {GetData,PostData} from '../../services/PostData';
class ListTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
			itemPerPage:10,
            teamList: [],
            originalTeamList: [],
            loading: false,
        };
       this.onChange = this.onChange.bind(this);
       this.getProfileData=this.getProfileData.bind(this);
    }
    componentDidMount() {
       // this.getProfileData();
         this.setState({ 
             loading: true,           
         });
         GetData('adminlist/').then((result) => {  
            console.log('result=',result);           
            this.setState({ 
                  loading: false,
                  teamList: result.data,
                  originalTeamList:result.data,
              });
         });
        // this.setState({ 
        //     teamList: this.props.teamlist,
        //     originalTeamList:this.props.orgiteamlist,
        // });
   }
   getProfileData(){
       // this.setState({ 
        //    loading: true,           
       // });
       // GetData('orgprofile/'+this.props.orgid).then((result) => {             
       //     this.setState({ 
        //        loading: false,
         //       teamList: result.data.org_users,
         //       originalTeamList:result.data.org_users,
          //  });
       // });
   }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });        
    }
    onChange(e){
		this.setState({[e.target.name]:e.target.value});
    }
    changeView(displaytype,userid){
        this.props.view(displaytype,userid);
    }
    
    updateUserStatus (e,status,id) {
        console.log('idd=',e,status,id);
        let msg= (status=='active')?'activate':'inactivate'
        if (window.confirm('Are you sure you want to '+msg+' the user?'))
        {
            console.log('in iff');
        }
        else 
        {
            console.log('elseee');
        }
    }
    submitPayment(e){
        console.log('check');
        const data ={
            amount:10
        }
        PostData('submitpayment/',data).then((result) => {  
            console.log('result=',result);          
        });
    }
    render() {       
		var indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        var indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        if( this.state.teamList.length>0 )
        {

            let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user
            var teamList = this.state.teamList.slice(indexOfFirstTodo, indexOfLastTodo);
            let imgpath = require('../../assets/images/msg-icon.jpg');
            var listItems = teamList.map((item, index) => {
                console.log('item===',item);
                let fname=''
                if(item.first_name)
                fname=item.first_name
                let lname=''
                if(item.last_name)
                lname=item.last_name
                let jobtitle=''
                if(item.job_title)
                jobtitle=item.job_title
                let location=''
                if(item.city)
                location=item.city+', '+item.state

                let activetask = item.activetask;
                let completetask = item.completedtask;

                

                return <tr key={index}>
                    {/* <td class="tbl_org_name">                    
                        <Link to={"/productionteam/view/"+item._id}><u>{fname} {lname}</u></Link>
                    </td> */}
                    <td class="tbl_org_name">
                    <Link to={"/productionteam/view/"+item._id}><u>{fname} {lname}</u></Link>
                    </td>
                    <td class="tbl_job_roles"><b>{jobtitle}</b></td>
                    <td class="tbl_job_location"><b>{location}</b></td>
                    <td class="tbl_job_roles"><b>{activetask}</b></td>
                    <td class="tbl_completed_tasks"><b>{completetask}</b></td>
                    <td class="tbl_org_message"><img src={imgpath} width="25" /></td>
                    <td class="tbl_org_status"><a className="status" onClick={(e)=>this.updateUserStatus(e,item.status,item._id)}>{item.status}</a></td>
                    <td class="tbl_org_edit TblIconedit"><Link to={"/productionteam/edit/"+item._id} className=""><img src={require('../../assets/images/edit-icon.png')}/></Link></td>
                    {/* <td class="tbl_org_phone">{item.phone}</td>
                    <td class="tbl_org_phone">{item.email}</td>
                    <td class="tbl_org_phone"><a className="status" onClick={(e)=>this.updateUserStatus(e,item.status,item._id)}>{item.status}</a></td>
                    <td class="tbl_org_edit TblIconedit"><Link to={"/productionteam/edit/"+item._id} className=""><img src={require('../../assets/images/edit-icon.png')}/></Link></td> */}
                </tr>
            });       
           
        }
		return (
			 <div className="container-fluid"  ref="selfdiv">
             <div class="row wrapper-box">
                <div className="panel panel-primary">
                    <div className="panel-heading">
                        <div className="row">
                            <div className="col-12 col-sm-4 col-md-5 col-lg-7">
                                <h4 class="PageBarTitle">Production Team</h4>
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 pull-right">
                                <input type="text" className="form-control search-type" placeholder="Search" value={this.state.searchText} onChange={this.updateInputValue}/>
                            </div>
                            <div className="col-6 col-sm-4 col-md-3 col-lg-2 pull-right">
                                
                            </div>
                            
                        </div>
                    </div>
                    <div className="panel-body">
                        <table className="table table-striped ListTable">
                            <thead>
                                <tr>
                                    {/* <th class="tbl_org_name"><b>Name</b></th>   
                                    <th class="tbl_org_phone"><b>Phone</b></th>
                                    <th class="tbl_org_phone"><b>Email</b></th>     
                                    <th class="tbl_org_phone"><b>Status</b></th>                              
                                    <th class="tbl_org_action"><b>Action</b></th> */}

                                    <th class="tbl_org_name"><b>Name</b></th>
                                    <th class="tbl_job_roles"><b>Job Roles</b></th>
                                    <th class="tbl_job_location"><b>Location</b></th>
                                    <th class="tbl_job_roles"><b>Active tasks</b></th>
                                    <th class="tbl_completed_tasks"><b>Completed tasks</b></th>
                                    <th class="tbl_org_message"><b>Message</b></th>
                                    <th class="tbl_org_status"><b>Status</b></th>                                    
                                    <th class="tbl_org_status"><b>Edit</b></th>    
                                </tr>
                            </thead>
                            <tbody>
                            {listItems}
                            </tbody>
                        </table>
                    { (this.state.teamList) &&
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={this.state.teamList.length}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                        />
                    }

                    
                    </div>
                    </div>
                    <div >
                </div>
            </div>
            </div>
		);
    }
}
export default ListTeam;
