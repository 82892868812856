import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {PostData} from '../../services/PostData';
import ReactDOM from 'react-dom';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {apiConfig} from "../../config/api"
import moment from 'moment';
import Loader from 'react-loader-spinner'

import { toast } from 'react-toastify';

import Configdata from '../../config.json';
class CreateProjectstg extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(){
		console.log("checkkkkkkkkkk");
		super();
		this.state = {
			isshowing_video_style:false,
			fields: {},
			errors: {},
			project_desc: EditorState.createEmpty(),
			project_name:'',
			duedate:'',
			org_id:'',
			isshowing_live_action:false,
			isshowing_animated_explainer:false,
			isshowing_already_have_assets:false,
			isshowing_schedule_type:false,
			isshowing_script_type:false,
			users:[5699,26],//Michelle Brown and Jim Wacksman  default user
			additionaluser:'',
			scheduleduser:'',
			cartonexplaineruser:'',
			scriptwritteruser:'',
			loading: false,
			commingsoonmessage:'',
			productionschedule:'',
			numOfBlockDays:0,
			categories: [
		        { title: 'Conferences & Events', id: 0 },
		        { title: 'Membership & Benefits', id: 1 },
		        { title: 'Advocacy & Public Affairs', id: 2 },
				{ title: 'Education', id: 3 },
				{ title: 'Something Else', id: 4 }
		    ],
			subcategories: [

				{ title: 'Promotional Video', id: 0, category: { title: 'Conferences & Events' } },
				{ title: 'Walk-in Video', id: 1, category: { title: 'Conferences & Events' } },
				{ title: 'Kickoff Video', id: 2, category: { title: 'Conferences & Events' } },
				{ title: 'Awards Video', id: 3, category: { title: 'Conferences & Events' } },
				{ title: 'Wrap up / Highlight Video', id: 4, category: { title: 'Conferences & Events' } },
				{ title: 'Something Else', id: 5, category: { title: 'Conferences & Events' } },

				{ title: 'Industry Promotional Video', id: 6, category: { title: 'Membership & Benefits' } },
				{ title: 'Membership Benefits Video', id: 7, category: { title: 'Membership & Benefits' } },
				{ title: 'Membership Testimonial Video', id: 8, category: { title: 'Membership & Benefits' } },
				{ title: 'Year in Review Video', id: 9, category: { title: 'Membership & Benefits' } },
				{ title: 'Something Else', id: 10, category: { title: 'Membership & Benefits' } },

				{ title: 'Issue Explainer Video', id: 11, category: { title: 'Advocacy & Public Affairs' } },
				{ title: 'Capitol Day Promotional Video', id: 12, category: { title: 'Advocacy & Public Affairs' } },
				{ title: 'Press Conference', id: 13, category: { title: 'Advocacy & Public Affairs' } },
				{ title: 'Something Else', id: 14, category: { title: 'Advocacy & Public Affairs' } },

				{ title: 'In Person Meeting', id: 15, category: { title: 'Education' } },
				{ title: 'Webinar or Prerecorded', id: 16, category: { title: 'Education' } },
				{ title: 'One on One Training', id: 17, category: { title: 'Education' } },
				{ title: 'Something Else', id: 18, category: { title: 'Education' } },
		
			
			],
			productionschedule:'',
			selctedCategory:'',
			selctedSubCategory:'',
			selectedEmo: [],
			selectedAsset: [],
			selectedVideo: []
		};
		this.submit = this.submit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onVideoStyleChange= this.onVideoStyleChange.bind(this);
		this.onAnimatedStyleChange= this.onAnimatedStyleChange.bind(this);
		this.onAssetsTypeChange= this.onAssetsTypeChange.bind(this);
		this.onScheduleTypeChange= this.onScheduleTypeChange.bind(this);
		this.onScriptTypeChange= this.onScriptTypeChange.bind(this);
		this.onScriptfileChangeHandler = this.onScriptfileChangeHandler.bind(this);

		Date.prototype.addDays = function (days) {
			var date = new Date(this.valueOf());
			date.setDate(date.getDate() + days);
			return date;
		};
	};
	onEditorStateChange: Function = (project_desc) => {
		const rawState = JSON.stringify(convertToRaw(project_desc.getCurrentContent()));
	    this.setState({
	      project_desc:rawState
	    });
	};
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}


	handleChange = date => {
	    this.setState({
	      duedate: date
	    });
		let fromdate = date;
		var todate = new Date();  
		// To calculate the time difference of two dates
		var Difference_In_Time = fromdate.getTime() - todate.getTime();		
		// To calculate the no. of days between two dates
		var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        let numofdays = parseInt(Difference_In_Days);
		//console.log('due date  = ',fromdate,todate,Difference_In_Days,numofdays);
		if(numofdays<10)
		{
			let monthsList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

			let date = fromdate.getDate();
			let month = monthsList[fromdate.getMonth()];
			let year = fromdate.getFullYear();

						
		}
		// else{
		// 	this.setState({
		// 		commingsoonmessage:''
		// 	});
		// }
	};
	validateForm() {
      let fields = this.state;
      let errors = {};
      let formIsValid = true;
	  const count = Number(this.props.credits) //1234
	  console.log('count top==',count);
	  if (count<1) {
		toast.error('Credit Required');
		  console.log('count==',count);
        formIsValid = false;
        //errors["project_name"] = "*Credit Required";
      }
      if (!fields["project_name"]) {
        formIsValid = false;
        errors["project_name"] = "*Please enter your Project Name";
      }
      //if (!fields["duedate"]) {
      //  formIsValid = false;
      //  errors["duedate"] = "*Please select Due Date";
      //}
      this.setState({
        errors: errors
      });
      return formIsValid;
    }
    componentDidMount() {
		this.setState({org_id:this.props.orgid});
	}
	onScriptfileChangeHandler=event=>{
		this.setState({
			'scriptfile': event.target.files[0],
			loaded: 0,
		})
	}
	async submit() {	
		if (this.validateForm())
		{
			const response = await fetch(
			'https://api.vimeo.com/users/70047375/projects',
			{
				method: 'POST',
				headers: {
					Authorization: `bearer `+Configdata.vimeo.AuthToken,//old 0c909954139c00b4b07af0ce71811f3d
					'content-type': 'application/json',
				},
				body: JSON.stringify({
			        name : this.state.project_name,
			        parent_folder_uri: this.props.vimeo_uri
			    }),
				}
		);
		const resdata = await response.json();
		console.log('resdata==',resdata);
		console.log('resdata==',resdata.uri);

			this.setState({loading:true});			
			const rawState=this.state.project_desc;
			if(rawState==='[object Object]')
			{
				rawState='';
			}
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			const data = new FormData();
			data.append('org_id', this.props.orgid)
			data.append('boxfolderid', this.props.boxfolderid)
			data.append('project_name', this.state.project_name)
			data.append('duedate', this.state.duedate)
			data.append('project_desc', rawState)

			data.append('videostyle', this.state.videostyle)
			data.append('doyouhaveimagevideos', this.state.doyouhaveimagevideos)
			data.append('describeassets', this.state.describeassets)
			data.append('doyouneedscheduleinterview', this.state.doyouneedscheduleinterview)
			data.append('describedetails', this.state.describedetails)
			data.append('animationtype', this.state.animationtype)
			data.append('needascriptorvoice', this.state.needascriptorvoice)
			data.append('scriptfile',  this.state.scriptfile)

			data.append('productionschedule',  this.state.productionschedule)
			data.append('selctedCategory',  this.state.selctedCategory)
			data.append('selctedSubCategory',  this.state.selctedSubCategory)
			data.append('selectedEmo',  this.state.selectedEmo)
			data.append('selectedAsset',  this.state.selectedAsset)
			data.append('selectedVideo',  this.state.selectedVideo)
			data.append('vimeo_folder_uri',  resdata.uri)

			let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL+'addproject', {
			method: 'POST',
			body: data,
			headers: {
					"session-key": session_key,
				}
			})
			.then((response) => response.json())
			.then((res) => {
				if(res.statuscode===200)
				{	
					console.log('res====',res);
					//this.props.view('viewprojectlist');	
					this.addDefaultProducers(res.data._id);
				}
			});
		
		}
	}
	addDefaultProducers(projectid){
		let uid=this.state.users;
		if(this.state.additionaluser)
		uid.push(this.state.additionaluser);
		if(this.state.scheduleduser)
		uid.push(this.state.scheduleduser);
		if(this.state.cartonexplaineruser)
		uid.push(this.state.cartonexplaineruser);
		if(this.state.scriptwritteruser)
		uid.push(this.state.scriptwritteruser);
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		if(userdata.user.ID!=26 || userdata.user.ID!=5699)
		{
			uid.push(userdata.user.ID);
		}
		//return false;
		let data={'users':uid,
				'project_id':projectid
		}
		PostData('associateusers',data).then((result) => {
    		if(result.statuscode===200)
			{
				this.setState({loading:false});
				this.props.view('viewprojectlist');	
			}				
		});
	}
	onAnimatedStyleChange(e){
		this.setState({[e.target.name]:e.target.value});
		if(e.target.value==='Cartoon'){
			this.setState({cartonexplaineruser:284});//added aditional user- EJ Rankin
		}
		else
		{
			this.setState({cartonexplaineruser:''});
		}
	}
	onVideoStyleChange(e){
		this.setState({[e.target.name]:e.target.value});
		console.log('check valeeeeeeeee===',e.target.value);
		if(e.target.value==='Live Action')
		{
			this.setState({isshowing_live_action:true});
			//this.setState({isshowing_animated_explainer:false,additionaluser:48});//added aditional user- Brad Sapienza
			this.setState({isshowing_animated_explainer:false});//added aditional user- Brad Sapienza
		}
		else if(e.target.value==='Animated Explainer')
		{
			this.setState({isshowing_animated_explainer:true});
			//this.setState({isshowing_live_action:false,additionaluser:47});//added aditional user- Kenan Bateman
			this.setState({isshowing_live_action:false});//added aditional user- Kenan Bateman
		}
		else{
			this.setState({isshowing_animated_explainer:false});
			this.setState({isshowing_live_action:false,additionaluser:''});
		}		
	}
	onAssetsTypeChange(e){
		this.setState({[e.target.name]:e.target.value});
		if(e.target.value==='Yes')
		{
			this.setState({isshowing_already_have_assets:true});
		}
		else if(e.target.value==='No')
		{
			this.setState({isshowing_already_have_assets:false});
		}
		else{
			this.setState({isshowing_already_have_assets:false});
		}		
	}
	onScheduleTypeChange(e){
		this.setState({[e.target.name]:e.target.value});
		if(e.target.value==='Yes')
		{
			//this.setState({isshowing_schedule_type:true,scheduleduser:44});//added aditional user- Joe Summers
			this.setState({isshowing_schedule_type:true});//added aditional user- Joe Summers
		}
		else if(e.target.value==='No')
		{
			this.setState({isshowing_schedule_type:false,scheduleduser:''});
		}
		else{
			this.setState({isshowing_schedule_type:false,scheduleduser:''});
		}		
	}
	onScriptTypeChange(e){
		this.setState({[e.target.name]:e.target.value});
		if(e.target.value==='I have the script')
		{
			this.setState({isshowing_script_type:true,scriptwritteruser:''});
		}
		else if(e.target.value==='Schedule a meeting with a script writer')
		{
			this.setState({isshowing_script_type:false,scriptwritteruser:28});//added aditional user- Josh Kilen
		}
	}
	handleDueDateChange(e) {
		this.setState({ productionschedule: e.target.value });
		if(e.target.value=='Urgent')
		{
			var message = 'We will get in touch with you just as soon as possible and will do our very best to meet your deadline.'	
			this.setState({
				commingsoonmessage:message,
				numOfBlockDays:0
			});
		}
		else{
			let numOfBlockDays = (e.target.value=='Soon')?10:15
			this.setState({
				commingsoonmessage:'',
				numOfBlockDays:numOfBlockDays,
			});
		}
	}	
	handleCategoryChange(e) {
		this.setState({ selctedCategory: e.target.value });
	}	
	handleSubCategoryChange(e) {
		this.setState({ selctedSubCategory: e.target.value });
	}
	onEmoChange = text => {
		const selectedEmo = this.state.selectedEmo;

		// Find index
		const findIdx = selectedEmo.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedEmo.splice(findIdx, 1);
		} else {
		  selectedEmo.push(text);
		}
        console.log('selectedEmo==',selectedEmo);
		this.setState({
		  selectedEmo: selectedEmo
		});
	};
	
	onAssetChange = text => {
		const selectedAsset = this.state.selectedAsset;

		// Find index
		const findIdx = selectedAsset.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedAsset.splice(findIdx, 1);
		} else {
		  selectedAsset.push(text);
		}
        console.log('selectedAsset==',selectedAsset);
		this.setState({
		  selectedAsset: selectedAsset
		});
	};

	onVideoChange = text => {
		const selectedVideo = this.state.selectedVideo;

		// Find index
		const findIdx = selectedVideo.indexOf(text);

		// Index > -1 means that the item exists and that the checkbox is checked
		// and in that case we want to remove it from the array and uncheck it
		if (findIdx > -1) {
		  selectedVideo.splice(findIdx, 1);
		} else {
		  selectedVideo.push(text);
		}
        console.log('selectedVideo==',selectedVideo);
		this.setState({
		  selectedVideo: selectedVideo
		});
	};	

	getNumWorkDays = (numofdays) => {
		var numWorkDays = 0;
		var currentDate = new Date();
		let datearray = [];
		while (numWorkDays <= numofdays) {
			if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
				numWorkDays++;				
			}
			var dateString = moment(currentDate). format('MM-DD-YYYY');
			let udate = new Date(dateString);
			datearray.push(udate);
			currentDate = currentDate.addDays(1);
		}
		return datearray;
	}
	
    render() {

		let checkdays = this.getNumWorkDays(this.state.numOfBlockDays);
		const {   editorState   } = this.state; 	
    	const styles = {
		  editor: {
			    border: '1px solid gray',
			    minHeight: '6em'
			  }
			};
		const ExampleCustomInput = ({ value, onClick }) => (
		    <button className="example-custom-input" onClick={onClick}>
		    	{<input type="text" value={value} /> /* <img src={require('../../assets/images/schedule-icon.png')}/> */}
		    </button>
		);

		  const categories = this.state.categories.map(i => (
		    <option key={i.id} value={i.title}>
		      { i.title }		      
		    </option>
		  ));


			  const subcategories = this.state.subcategories
			    .filter(i => i.category.title === this.state.selctedCategory)
			    .map(i => (
			      <option key={i.id} value={i.title}>
			      { i.title }		      
			    </option>
			    ));
			

		const isshowing_live_action=this.state.isshowing_live_action;
		const isshowing_animated_explainer=this.state.isshowing_animated_explainer;
		const isshowing_already_have_assets=this.state.isshowing_already_have_assets;
		const isshowing_schedule_type=this.state.isshowing_schedule_type;
		const isshowing_script_type=this.state.isshowing_script_type;
		const { selectedEmo,selectedAsset,selectedVideo } = this.state;

		const emoCheckboxes = [
		  { id: 1, text: "Informed" },
		  { id: 2, text: "Curious" },
		  { id: 4, text: "Happy" },
		  { id: 5, text: "Excited" },
		  { id: 6, text: "Upset" },
		  { id: 7, text: "Angry" },
		  { id: 8, text: "Motivated" },
		  { id: 9, text: "Sympathy" },
		  { id: 10, text: "Hopeful" },
		  { id: 11, text: "Generous" },
		  { id: 12, text: "Proud" },
		  { id: 13, text: "Somthing Else" },
		];

		const assetCheckboxes = [
		  { id: 1, text: "Testimonial" },
		  { id: 2, text: "Voiceover" },
		  { id: 4, text: "Videos" },
		  { id: 5, text: "Photos" },
		  { id: 6, text: "Animations" },
		  { id: 7, text: "Somthing Else" },		 
		];

		const videoCheckboxes = [
		  { id: 1, text: "Live Audience" },
		  { id: 2, text: "Website" },
		  { id: 4, text: "Social Media" },
		  { id: 5, text: "Somthing Else" },		  
		];
        return (
        	<div className="container-fluid AddProjectPage MobPaddHide create-project"  ref="selfdiv">
				<div className="panel panel-primary">
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}						         
								/>
							</div>
						:null 
					} 
					<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-12 col-md-7 col-lg-7">
									<h4 class="PageTopTitle">Create Projects</h4>
								</div>
								<div className="col-md-3 col-lg-3"> </div>
								<div className="col-md-2 col-lg-2"> </div>
								<div  className="col-md-2 col-lg-2"> </div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid">
						<div className="row FormDesign">
							<div className="col-12 col-sm-12 col-md-12 col-lg-12">
						<div className="row">
								<div className="col-9 col-sm-9 col-md-9 col-lg-10">
								<label for="projectname">Project Title</label>
								<input type="text" name="project_name" placeholder="" onChange={this.onChange} className="PB_Title form-control" autoFocus />
								<div className="errorMsg">{this.state.errors.project_name}</div>
								</div>              	
								<div className="col-3 col-sm-3 col-md-3 col-lg-2">
								<label for="projectname">Production Schedule</label>
								<select name="taskstatus" className="form-control" value={this.state.productionschedule} onChange={this.handleDueDateChange.bind(this)} >
									<option value=" ">Select</option>
									<option value="Urgent">Urgent</option>
									<option value="Soon">Soon (2+ Weeks)</option>
									<option value="No rush">No Rush (3+ Weeks)</option>
								</select>
								{/* <div className="errorMsg">{this.state.errors.duedate}</div> */}
								
								</div>
								</div>
								{(this.state.productionschedule =='Urgent' &&
									<p>{this.state.commingsoonmessage}</p> 
								)}
								{ ((this.state.productionschedule=='Soon' || this.state.productionschedule=='No rush') &&
									<div className="CreateProRadioBox">
									<label for="projectname">Final Due Date</label>
									<div className="DueDate1"> 
										<DatePicker
											dateFormat="MM-dd-yyyy"
											className="form-control"
											minDate={moment().toDate()}
											selected={this.state.duedate}
											excludeDates={checkdays}
											onChange={this.handleChange}customInput={<ExampleCustomInput />}
										/> 
										<div className="errorMsg">{this.state.errors.duedate}</div>
									</div><br/> 
									</div>
								)
								}
								            	
								<div className="category ">
									<label for="projectname">Category</label>
									<select name="taskstatus" className="form-control" value={this.state.category} onChange={this.handleCategoryChange.bind(this)} >
										<option value=" ">Category</option>
										{categories}
										
									</select>
									<div className="errorMsg">{this.state.errors.category}</div>								
								</div>
								{ (subcategories.length>0 &&
									<div className="category ">
										<label for="projectname">Subcategory</label>
										<select name="taskstatus" className="form-control" value={this.state.subcategory} onChange={this.handleSubCategoryChange.bind(this)} >
											<option value=" ">Sub Category</option>
											{subcategories}										
											
										</select>
										<div className="errorMsg">{this.state.errors.category}</div>								
									</div>
									)
								}

								{/* <p>{this.state.commingsoonmessage}</p> */}
								<div className="CreateProjectChatBox">
									<label>What is the purpose of this video?</label>
									<Editor 
										project_desc={this.state.project_desc}
										wrapperClassName="demo-wrapper"
										editorClassName="editer-content"
										onEditorStateChange={this.onEditorStateChange} 
										toolbar={{
											options: ['inline', 'list','image','emoji','link'],
										}}
									/>	            
								</div>
								<div className="clearfix"></div>


								<div className="row CreateProRadioBox">
									<div className="col-sm-12">
										<label class="ParentLab" htmlFor="issubscriber">Video Style</label>
									</div>
									<div className="col-sm-4">					
										<label className="radio-inline">
											<input type="radio" onChange={this.onVideoStyleChange} value="Live Action" name="videostyle"/>Live Action
										</label>
									</div>
									<div className="col-sm-4">
										<label className="radio-inline">
											<input type="radio" onChange={this.onVideoStyleChange}  value="Animated Explainer" name="videostyle"/>Animated Explainer
										</label>	
									</div>
									<div className="col-sm-4">
										<label className="radio-inline">
											<input type="radio" onChange={this.onVideoStyleChange}  value="Not Sure" name="videostyle"/>Not Sure
										</label>	
									</div>				
								</div>
								
								{
									isshowing_live_action &&
									<div>
									<div className="row CreateProRadioBox">
										<div className="col-sm-12">
											<label class="ParentLab" htmlFor="issubscriber">Do you already have assets such as images or videos to use for this project?</label>
										</div>
										<div className="col-sm-2">					
											<label className="radio-inline">
												<input type="radio" onChange={this.onAssetsTypeChange} value="Yes" name="doyouhaveimagevideos"/>Yes
											</label>
										</div>
										<div className="col-sm-2">
											<label className="radio-inline">
												<input type="radio" onChange={this.onAssetsTypeChange} value="No" name="doyouhaveimagevideos"/>No
											</label>	
										</div>
										<div className="col-sm-3">
											<label className="radio-inline">
												<input type="radio" onChange={this.onAssetsTypeChange} value="Maybe / Not Sure" name="doyouhaveimagevideos"/>Maybe / Not Sure
											</label>	
										</div>	
										<div className="col-sm-5"></div>			
									</div>
									{
									isshowing_already_have_assets &&
										<div className="row CreateProRadioBox">
											<div className="col-sm-12">
												<div className="describeassets">
													<label class="ParentLab">Great! Please describe the assets you have.</label><br/>
													<textarea  onChange={this.onChange} name="describeassets"/>
												</div>
											</div>
										</div>
									}
									<div className="row CreateProRadioBox">
										<div className="col-sm-12">
											<label class="ParentLab" htmlFor="issubscriber">Do we need to schedule interviews or shoot an event? </label>
										</div>
										<div className="col-sm-2">					
											<label className="radio-inline">
												<input type="radio" onChange={this.onScheduleTypeChange} value="Yes" name="doyouneedscheduleinterview"/>Yes
											</label>
										</div>
										<div className="col-sm-2">
											<label className="radio-inline">
												<input type="radio" onChange={this.onScheduleTypeChange}  value="No" name="doyouneedscheduleinterview"/>No
											</label>	
										</div>	
										<div className="col-sm-8"></div>		
									</div>
									{
									isshowing_schedule_type &&
										<div className="row CreateProRadioBox">
											<div className="col-sm-12">
												<div className="describeassets">
													<label class="ParentLab">Great! Please provide the details.</label><br/>
													<textarea onChange={this.onChange} name="describedetails"/>
												</div>
											</div>
										</div>
									}
									</div>
								}
								{
									isshowing_animated_explainer &&
									<div>
									<div className="row CreateProRadioBox">
										<div className="col-sm-12">
											<p>We have three animated explainer videos to choose from. Please let us know which style you would like.</p>
											<label class="ParentLab" htmlFor="issubscriber">Animated Explainer Video Style</label>
										</div>
										<div className="col-sm-3">					
											<label className="radio-inline">
												<input type="radio" onChange={this.onAnimatedStyleChange} value="Cartoon" name="animationtype"/>Cartoon
											</label>
										</div>
										<div className="col-sm-3">	
											<label className="radio-inline">
												<input type="radio" onChange={this.onAnimatedStyleChange} value="Infographic" name="animationtype"/>Infographic
											</label>
										</div>
										<div className="col-sm-3">
											<label className="radio-inline">
												<input type="radio" onChange={this.onAnimatedStyleChange}  value="Whiteboard / Chalkboard" name="animationtype"/>Whiteboard / Chalkboard
											</label>	
										</div>
										<div className="col-sm-3">
											<label className="radio-inline">
												<input type="radio" onChange={this.onAnimatedStyleChange} value="Not Sure" name="animationtype"/>Not Sure
											</label>
										</div>			
									</div>
									<div className="row CreateProRadioBox">
										<div className="col-sm-12">
											<label class="ParentLab" htmlFor="issubscriber">Sounds like we'll need a script and a voice over.</label>
										</div>
										<div className="col-sm-3">					
											<label className="radio-inline">
												<input type="radio" onChange={this.onScriptTypeChange} value="I have the script" name="needascriptorvoice"/>I have the script
											</label>
										</div>
										<div className="col-sm-5">
											<label className="radio-inline">
												<input type="radio" onChange={this.onScriptTypeChange}  value="Schedule a meeting with a script writer" name="needascriptorvoice"/>Schedule a meeting with a script writer
											</label>	
										</div>	
										<div className="col-sm-4">	</div>		
									</div>
									{
									isshowing_script_type ?
										<div className="row CreateProRadioBox">
											<div className="col-sm-12">
												<div className="describeassets">
													<label>Great! Please upload the script or email to productions@associationstudios.com</label><br/>
													<input type="file" onChange={this.onScriptfileChangeHandler} name="uploadscript"/>
												</div>
											</div>
										</div>
										:
										(this.state.needascriptorvoice =='Schedule a meeting with a script writer' &&
										<div className="row CreateProRadioBox">
											<div className="col-sm-12">
												<div className="describeassets">
													<p>Sounds good. We'll get you scheduled with a script writer right away!</p>													
												</div>
											</div>
										</div>)
									}
									</div>
								}


								<div className="row CreateProRadioBox">
									<div className="col-sm-12">
										<label for="projectname" class="ParentLab" >How should the viewer feel when watching the video?</label>
									</div>
									
									{emoCheckboxes.map(emo => (
										<div className="col-sm-2 hideinputbox">
								          <label key={emo.id}>
								            <input
								              type="checkbox"
								              onChange={() => this.onEmoChange(emo.text)}
								              selected={selectedEmo.includes(emo.text)}
								            /> {emo.text}								            
								          </label>
								         </div>
							        ))}	
							        						
								</div>

								

								<div className="row CreateProRadioBox">
									<div className="col-sm-12">
										<label for="projectname" class="ParentLab" >How will this video be used ?</label>
									</div>
									
									{videoCheckboxes.map(video => (
										<div className="col-sm-2 hideinputbox">
								          <label key={video.id}>
								            <input
								              type="checkbox"
								              onChange={() => this.onVideoChange(video.text)}
								              selected={selectedVideo.includes(video.text)}
								            /> {video.text}								            
								          </label>
								         </div>
							        ))}	
							        <div className="col-sm-2 hideinputbox"></div>
							        <div className="col-sm-2 hideinputbox"></div>					
								</div>


								<div className="row CreateProRadioBox">
									<div className="col-sm-12">
										<label for="projectname" class="ParentLab" >What assets do you have or would like to use?</label>
									</div>
									
									{assetCheckboxes.map(asset => (
										<div className="col-sm-2 hideinputbox">
								          <label key={asset.id}>
								            <input
								              type="checkbox"
								              onChange={() => this.onAssetChange(asset.text)}
								              selected={selectedAsset.includes(asset.text)}
								            /> {asset.text}								            
								          </label>
								         </div>
							        ))}	
							        						
								</div>
								
							 <button type="submit" onClick={this.submit} className="CPRadioBtn UpdateBtn btn btn-primary">Create</button> 
							</div>
							<div className="col-3">
								
							</div>                  
						</div>

		        	</div>	
		        </div>					
			</div>	
		);
    }
}
export default CreateProjectstg;
