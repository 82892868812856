import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {PostData,GetData} from '../../services/PostData';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner'
import { Link  } from 'react-router-dom';
import { apiConfig } from "../../config/api"
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
class UploadFile extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(props){
		console.log('this.props.match.params.folderid==',props.match.params.folderid);
		super(props);
		this.state = {
			fields: {},
			errors: {},
			folder_desc: EditorState.createEmpty(),
			filename:'',
			boxfolder_name:props.match.params.folderid,			
			loading: false,
			folderaddsuccs:'',
			assetuploadloader:'',
			selectedFile:[],
			folderlist:[],
			originalfolderlist:[],
			rootfolderid:'',
			fileid:''
		};		
	};
	handleChangeStatus = ({ meta, file }, status) => { 
        this.setState(prevState => ({
            selectedFile: [...prevState.selectedFile, file]
        }))
    }
	onFileChangeHandler=event=>{
	    this.setState({
	      'filename': event.target.files[0],
	      loaded: 0,
	    })
	}
	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["filename"]) {
			formIsValid = false;
			errors["filename"] = "*Please upload Attachment";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
    }   
	 removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    } 
	 handleSubmit = (files) => {
		if(this.checkFolderID())
		{
			this.setState({assetuploadloader:true});
			var formdata = new FormData();
			const selectedfiles=this.state.selectedFile;
			var uniqueArray = this.removeDuplicates(selectedfiles, "name");
			for(var x = 0; x<uniqueArray.length; x++) {
				formdata.append('boxfile', uniqueArray[x])
			}
			formdata.append('title', 'test');
			//fileid
			formdata.append('folderid', this.state.fileid)	
			//formdata.append('folderid', this.state.boxfolder_name)	
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			let session_key = userdata.token;
			fetch(apiConfig.WEB_SERVICE_URL+'fileupload', {
				method: 'POST',
				body: formdata,
				headers: {
						"session-key": session_key,
					}
			})
			.then((response) => response.json())
			.then((res) => {
				console.log('cakkkeddd',res);
				if(res.statusCode===200)
				{				
					this.setState({
						assetuploadsuccessmsg:res.message,
						assetuploadloader:false
					});			    
				}
			});   
		}       
	}
	checkFolderID() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["fileid"]) {
			formIsValid = false;
			errors["fileid"] = "*Please select Folder";
		}		
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	componentDidMount() {
        this.setState({loading:true});
        Promise.all([this.getOrgData()])
			.then(([orgdata]) => {						
			if(orgdata.status==='success')
            {
                if(orgdata.data.boxfolderid)
                {
				   this.setState({boxfolderid:orgdata.data.boxfolderid,rootfolderid:orgdata.data.boxfolderid});
				   this.getFolderListByFolderId(orgdata.data.boxfolderid);
                }
			}
			
		});
	}	
	getOrgData(){
		return GetData('organization/'+this.props.match.params.orgid).then((result) => {
			return result;
		});
	}
	getFolderListById(folderid){
		this.setState({
			boxfolderid:folderid,
			isInner:true,
        });
		this.getFolderListByFolderId(folderid);
	}
	getFolderListByFolderId(folderid)
    {
        this.setState({loading:true});
        let data = {
            'boxfolderid': folderid,
        }
        PostData('boxassets', data).then((result) => {
			if(result.statusCode===200)
			{
				console.log('responseJson==',result);
				this.setState({
					folderlist: result.data,
					originalfolderlist: result.data,
					loading:false
				});
			}
		});
	}
	handleChangeOnFolderSelection(e){
		this.setState({
			fileid:e.target.value
		})
	}
	backToRootFolder(folderid){
        this.setState({
			boxfolderid:folderid,
			isInner:false,
        });
        this.getFolderListByFolderId(folderid);
	}
	render() {
		const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
		let folderslist = this.state.folderlist.map((item, i) => {
		//const folderslist = this.state.folderlist.map(function(item){
			if(item.filetype==='folder')
			return <li className="folderlistitem">
					<a className="forcusror" onClick={this.getFolderListById.bind(this,item.fileid)}>{item.filename}</a>
					<input value={item.fileid} name="fileid" type="radio" onChange={this.handleChangeOnFolderSelection.bind(this)}
					/>
				</li>;
		});
        return (
        	<div className="container-fluid AddProjectPage MobPaddHide"  ref="selfdiv">
				<div className="panel panel-primary">
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}						         
								/>
							</div>
						:null 
					} 
					<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-6">
									<h4 class="PageTopTitle">Upload File</h4>
								</div>							
								<div  className="col-6 text-right"> 
								<Link to={`/${this.props.match.params.orgid}/assets`}>Back To Assets</Link> 
								</div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid">
						<div className="row FormDesign">
							<div className="col-9 col-sm-9 col-md-9 col-lg-9">
							<div className="row">
								<div className="succes-msg">{this.state.assetuploadsuccessmsg}</div>
								<div className="col-12">
									<h3>Select folder</h3>
									<div className="errormsg">{this.state.errors.fileid}</div>
									{(this.state.isInner &&
										<div>
											<a className="forcusror rootfolder" onClick={this.backToRootFolder.bind(this,this.state.rootfolderid)} >Back To Root Folder</a>								
										</div>
									)

									}
									<ul className="folderlist uploadfolderlist">
										{folderslist}
									</ul>
								</div>
							</div><br/>
								<div className="row">								
								<div class="UploadAssetsIcons">
									<img src={require('../../assets/images/upload-file-icon.png')}/>
									<h4>Drag and drop anywhere to upload</h4>
									<a href=""><img src={require('../../assets/images/upload-btn-icon.png')}/> <span>Or choose files</span> </a>
									</div>
									{
										this.state.assetuploadloader ?
											<div className="loaderspinner">
												<Loader
													type="TailSpin"
													color="#D10000"
													height={100}
													width={100}						         
												/>
											</div>
										:null      
									}     
									<Dropzone    
										getUploadParams={getUploadParams}
										onChangeStatus={this.handleChangeStatus}
										onSubmit={this.handleSubmit}
										accept="image/*,video/*"
										/>
								</div>
								{/* <div className="row">
									<div className="col-12">
										<div className="succes-msg">{this.state.folderaddsuccs}</div>
										<label for="projectname">Upload File</label>
										<input type="file" name="filename" onChange={this.onFileChangeHandler.bind(this)} className="PB_Title form-control filename" autoFocus />
										<div className="errorMsg">{this.state.errors.filename}</div>
									</div>            	
								</div>	
								<div className="row">
									<div className="col-12">
										<label for="projectname">Box Folder</label>
										<input type="text" name="boxfolder_name" value={this.state.boxfolder_name} placeholder="" className="PB_Title form-control" autoFocus readOnly />
										<div className="errorMsg">{this.state.errors.boxfolder_name}</div>
									</div>            	
								</div>							 */}
								{/* <div className="CreateProjectChatBox">
									<label for="projectname">Decription</label>
									<Editor 
										folder_desc={this.state.folder_desc}
										wrapperClassName="demo-wrapper"
										editorClassName="editer-content"
										onEditorStateChange={this.onEditorStateChange} 
										toolbar={{
											options: ['inline', 'list','image','emoji','link'],
										}}
									/>	            
								</div>								 */}
								{/* <button type="submit" onClick={this.submit} className="CPRadioBtn UpdateBtn btn btn-primary">Create</button>  */}
							</div>							                 
						</div>
		        	</div>	
		        </div>					
			</div>	
		);
    }
}
export default UploadFile;
