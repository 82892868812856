import React, { Component } from "react";
import Loader from 'react-loader-spinner'
import { apiConfig } from "../../../config/api"
import io from "socket.io-client";
import SocketIOFileUpload from "socketio-file-upload"; 
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML, decorator, convertRawToConvertable, Entity, Modifier  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { forEach } from "async";
class FooterChatBox extends Component {
    constructor(props){
		super(props);
		this.state = {
			chatattchedfilename:[],
			chatattachament: '',
			chatattchedfilebasename:[],
			iseditedchat:false,
			editedboardid:'',
			attachedfilesize:'',
			setConfirmMsgShow:false,
			taggedobj:[],
			removechatvalidation:false
		};
		
		let url = apiConfig.WEB_SERVICE_URL;
		url = url.replace('http://', '');
		url = url.replace('/api/', '');
		this.socket = io(url);		
		let currentstate=this;
		this.socket.on('SAVE_ATTACHMENT', function (data) {
			console.log('check dataaaaaaaaaaa======',currentstate.state.chatuploadincurrentpage,data.bytesLoaded,'attachedfilesize==',currentstate.state.attachedfilesize);
			if(currentstate.state.chatuploadincurrentpage && data.bytesLoaded===currentstate.state.attachedfilesize)
			{
				currentstate.setState({
					//chatattchedfilebasename:data.base,
					//chatattchedfilename:data.name,
				});
				currentstate.setState({ chatattchedfilename: [...currentstate.state.chatattchedfilename, data.name],chatattchedfilebasename: [...currentstate.state.chatattchedfilebasename, data.base] })
			}
			//console.log('data.base',data.base);
			//console.log('chatattchedfilename',currentstate.state.chatattchedfilename);
			//console.log('chatattchedfilebasename',currentstate.state.chatattchedfilebasename);
		});
		
		this.sendMessage = ev => {
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
			ev.preventDefault();
			const datta = this.state.editorState;
			console.log('datta==',datta);
			if(!datta)
			{
				return false;	
			}
			const blocks = convertToRaw(datta.getCurrentContent()).blocks;
			let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
			value=value.trim()	
			const editorJSON = JSON.stringify(convertToRaw(datta.getCurrentContent()));
			
				let usrdata = JSON.parse(editorJSON);
				console.log('usrdataom footer chat==',usrdata);
				const taggedusr = usrdata.blocks[0].text
				const entityMap = usrdata.entityMap
				const objlength = Object.keys(entityMap).length;
				console.log('entityMap==',entityMap);

				

				if(!this.state.removechatvalidation && objlength===0)
				{					
					this.setState({
						setConfirmMsgShow:true,						
					});
					return false;					
				}
				
				//console.log('taggedusr==',taggedusr);
				const msgsplit = taggedusr.split(' ');
				//console.log('msgsplit==',msgsplit);
				msgsplit.forEach((element)=>{
					if(element == '@All'){
						
						console.log('editorJSON=====', '@All');
					}
				})


			if(value && value!=" ")
			{



				//console.log('in iffffffff=====',value,"data");
				let cAttchedfilename=this.state.chatattchedfilename.toString();
				let cAttchedfilebasename=this.state.chatattchedfilebasename.toString();
				this.socket.emit('SEND_MESSAGE', {
					userdata: userdata,
					project_id: this.props.prjid,
					org_id:this.props.orgid,
					message: editorJSON,
					chatattachament: this.state.chatattachament,
					chatattchedfilename:cAttchedfilename,
					chatattchedfilebasename:cAttchedfilebasename,
					chatid:this.state.editedboardid,
					chatallusers:this.props.allusers
				})
				//this.scrollToBottom();
				const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
				this.setState({ editorState });
				this.setState({ chatattchedfilename:'',chatattchedfilebasename:'',editedboardid:'',iseditedchat:'',removechatvalidation:false });
			}
		}

	}
	handleConfirmMsgBoxClose(e) {
		this.setState({
			setConfirmMsgShow: false,	
			taggedobj:[],		
		});
	}
	handleEchatMessageChange = (editorState) => {
		this.setState({
			editorState,
		});
	};
	componentDidMount() {	
		var url11 = apiConfig.WEB_SERVICE_URL;
		url11 = url11.replace('http://', '');
		url11 = url11.replace('/api/', '');
		var socket = io(url11);
		var siofu = new SocketIOFileUpload(socket);
		siofu.listenOnInput(document.getElementById("chatattachament"));
		let currentev=this;
		// Do something on upload progress:
		siofu.addEventListener("start", function(event){
			console.log('start==',event);
			//siofu.abort(event.file.id, socket);
		});
		siofu.addEventListener("progress", function(event){
			var percent = event.bytesLoaded / event.file.size * 100;
			console.log("File is in progresss==", percent.toFixed(2), "percent loaded event=",event.bytesLoaded);
			document.getElementById("loadingstatus").innerHTML =Math.round(percent) + "% uploaded... please wait";
			currentev.setState({
				attachedfilesize:event.bytesLoaded
			});
		});
		// Do something when a file is uploaded:
		siofu.addEventListener("complete", function(event){
			console.log("File is in complete==eventdetail==",event.detail);
			currentev.setState({
				uploadchattattchamentloader:false,
			});
		});	
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	
	onChangeHandler = event => {
		this.setState({
			'chatattachament': event.target.files[0],
			loaded: 0,
			uploadchattattchamentloader:true,
			chatuploadincurrentpage:true,
		})
	}
	componentDidUpdate(prevProps) {
		if (prevProps.ischatedit !== this.props.ischatedit && this.props.editedboardid) {
			this.setState({editorState:this.props.editorState,iseditedchat:true,editedboardid:this.props.editedboardid})		
		}
	}
	removeEditChat(e){
		this.setState({
			editorState:EditorState.createEmpty(),
			editedboardid:'',
			iseditedchat:false,
		});
		this.props.removeEditChat();
	}
	tagUserChanges =(data,e) =>{
		const userdata = e.target.value;
		console.log('data=',data);
		const obj = {
			mutability: "IMMUTABLE",
			type: "MENTION",
			data:{
				text: "@"+data.value, value: data.value, url: data.url
			}
		} 
		var objdata =[];
		objdata.push(obj);
		console.log('objdata=',objdata);
	
		const datta = this.state.editorState;
		const blocks = convertToRaw(datta.getCurrentContent()).blocks;
		let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
		value=value.trim()	
		const contentState = datta.getCurrentContent();

		let usrdata = convertToRaw(contentState);

		//let usrdata = convertToRaw(datta);

		console.log('usrdataom footer chat==',usrdata);
		const taggedusr = usrdata.blocks[0].text
		const entityMap = usrdata.entityMap
		const objlength = Object.keys(entityMap).length;
		console.log('entityMap==',entityMap);
		console.log('taggedusr==',taggedusr);
		console.log('objdata===',objdata);
		Object.assign(usrdata.entityMap, objdata);
		this.setState({ taggedobj: [...this.state.taggedobj, obj ] },	function(){
			console.log('taggedobj=',this.state.taggedobj);
			console.log('usrdataom at last==',usrdata);
		})	
	}
	postMessage = () => {
		const datta = this.state.editorState;
		const blocks = convertToRaw(datta.getCurrentContent()).blocks;
		let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
		value=value.trim()	
		const editorJSON = JSON.stringify(convertToRaw(datta.getCurrentContent()));			
		let usrdata = JSON.parse(editorJSON);
		console.log('usrdataom footer chat==',usrdata);
		const taggedusr = usrdata.blocks[0].text
		const entityMap = usrdata.entityMap
		const objlength = Object.keys(entityMap).length;
		var dataitem = taggedusr;

		const taggedobj= this.state.taggedobj;
		if(taggedobj.length >0)
		{
			let currentstate=this;
			taggedobj.forEach(async element => {
				console.log('element==',element.data.value);
				//dataitem = dataitem + ' @'+ element.data.value;
				await this.updatestate(element);
			})
			
			console.log('dataitem',dataitem);
			//let curr =this;
			setTimeout(function(){
				let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
				
				const datta = currentstate.state.editorState;
				console.log('datta==',datta);
				if(!datta)
				{
					return false;	
				}
				const blocks = convertToRaw(datta.getCurrentContent()).blocks;
				let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
				value=value.trim()	
				const editorJSON = JSON.stringify(convertToRaw(datta.getCurrentContent()));
				if(value && value!=" ")
				{
					//console.log('in iffffffff=====',value,"data");
					let cAttchedfilename=currentstate.state.chatattchedfilename.toString();
					let cAttchedfilebasename=currentstate.state.chatattchedfilebasename.toString();
					currentstate.socket.emit('SEND_MESSAGE', {
						userdata: userdata,
						project_id: currentstate.props.prjid,
						org_id:currentstate.props.orgid,
						message: editorJSON,
						chatattachament: currentstate.state.chatattachament,
						chatattchedfilename:cAttchedfilename,
						chatattchedfilebasename:cAttchedfilebasename,
						chatid:currentstate.state.editedboardid,
						chatallusers:currentstate.props.allusers
					})
					//this.scrollToBottom();
					const editorState = EditorState.push(currentstate.state.editorState, ContentState.createFromText(''));
					currentstate.setState({ editorState });
					currentstate.setState({ chatattchedfilename:'',chatattchedfilebasename:'',editedboardid:'',iseditedchat:'',removechatvalidation:false });
				}
			}, 500);

		}
		else
		{
			this.setState({
				removechatvalidation:true,
			});
		}
		this.setState({
			taggedobj:[],
			setConfirmMsgShow:false,
		});
	}

	 updatestate(element){
		 let curr =this;
		setTimeout(function(){
		  console.log ('callleddddddddd updatestate');
				const editorState = curr.state.editorState;	
				//console.log('const===',const);
				const currentContent = editorState.getCurrentContent();	   
				const selection = editorState.getSelection();
				const entityKey = Entity.create('MENTION', 'IMMUTABLE', {"text":element.data.text,"value":element.data.value,"url":element.data.url});
				const textWithEntity = Modifier.insertText(currentContent, selection, ' @'+element.data.value, null, entityKey); 
				curr.setState({ editorState: EditorState.push(editorState, textWithEntity, 'insert-characters') });
		}, 10);
	}
    render() {
		//console.log('chatattchedfilename==',this.state.chatattchedfilename);
		let attchedfile=this.state.chatattchedfilename.toString();
		var imageurl=''
		if(this.props.avatar)
			imageurl='../../../../../profile-image/'+this.props.avatar;
		else
		imageurl='';
		let noimage =false; 
        let noimagename=''; 
		if(this.props.avatar){
			var imgpath=this.props.avatar;		
			//console.log('imgpath==',imgpath);					
			var imgname = imgpath.split(".");
			imgname = imgname[0];
			imgname = imgname.split("_");
			//console.log('imgname.length',imgname.length);
			//console.log('imgname====',imgname);
			if(imgname.length>1 && imgname[1]==='noimage')
			{
				noimage=true;
				if(this.props.fname)
				var fn=this.props.fname
				if(this.props.lname)
				var ln=this.props.lname
				noimagename=fn.charAt(0)+ln.charAt(0);
				noimagename = noimagename.toUpperCase();
				//console.log('imgname==',imgname);
			}
		}
		const setConfirmMsgShow = this.state.setConfirmMsgShow;
		const  associateduserforhastag= this.props.associateduserforhastag;
		console.log('associateduserforhastag===',associateduserforhastag);
		const userlist = associateduserforhastag.map((element) =>
			<div className="checkbox">
				<label >
					<input type="checkbox"  value={element.value} onChange={this.tagUserChanges.bind(this,element)} />
					{element.text}
				</label>
			</div>
		)
        return (
        	<div className="FooterChatBox">
        		{(noimage)?<div className="noimage pbnoimage">{noimagename}</div>:<img className="avatarimage" src={imageurl} />
								}
				
				<form>
					<div className={`container-root ${this.state.hasFocus ? 'hasFocus' : ''}`}>
						{
							(this.state.iseditedchat &&
								<a className="forcusror" onClick={this.removeEditChat.bind(this)} >X</a>
							)
						}
						<Editor onFocus={() => this.setState({ hasFocus: true })} onBlur={() => this.setState({ hasFocus: false })} editorState={this.state.editorState} wrapperClassName="demo-wrapper" editorClassName="editer-content" onEditorStateChange={(e) => this.handleEchatMessageChange(e)} placeholder='Ask a question or post an update..." with "@mention someone to notify them.'
							toolbar={{
								options: ['inline', 'list', 'emoji', 'link'],
							}}
							mention={{
								separator: ' ',
								trigger: '@',
								suggestions: this.props.associateduserforhastag,
							}}
							hashtag={{}}
						/>		
						<div className="selectedfile">{attchedfile}</div>
						<input type="file" name="chatattachament" id="chatattachament" onChange={this.onChangeHandler} className="form-control" autoFocus />
					</div>
					<div class="FootChatSearchBox">
						{
							this.state.uploadchattattchamentloader ?
								<div className="loaderspinner">
									<Loader
										type="TailSpin"
										color="#D10000"
										height={100}
										width={100}						         
									/>
								<div id="loadingstatus"></div>
								</div>
							:null 
						} 
						<input class="UpdateBtn btn btn-primary" id="addchatmessage" type='button' value='Submit' onClick={this.sendMessage.bind(this)} />	
						<img src={require('../../../assets/images/send-btn-footer.png')} />
					</div>
				</form>
				<Modal show={setConfirmMsgShow}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<label>Tag someone in this message to send them a notification email.</label>		{userlist}						
								{/* <div className="checkbox">
									<label >
										<input type="checkbox" checked={this.state.isChecked} onChange={this.handleCheckBox.bind(this)} />
										Yes, this project is completed.
									</label>
									<div className="errorMsg">{this.state.markascompleteerror}</div>
								</div>										 */}
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleConfirmMsgBoxClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick ={this.postMessage}>
							Post Message
						</Button>
						{/* <Button variant="primary" onClick={this.handleConfirmMsgBoxClose.bind(this)}>
							Cancel
						</Button> */}
					</Modal.Footer>
				</Modal>
			</div>	
		);
    }
}
export default FooterChatBox;