import React, { Component } from "react";
import {PostData} from '../../../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
class ApproveReviewTask extends Component {

    constructor(props){
		super(props);
		this.state = {
			tasksucsmsg:'',
			fields: {},
			errors: {},	
			setReviewProject:this.props.handleAddReviewProject,		
		};
		console.log('isAddReviewProject==',this.props.isAddReviewProject);
	}
	componentDidUpdate(prevProps) {
		if (prevProps.isAddReviewProject !== this.props.isAddReviewProject) {
			this.setState({setReviewProject:this.props.isAddReviewProject})		
		}
	}	

	commentChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	handleAddReviewProjectClose(e) {
		this.setState({ setReviewProject: false });
	}

	saveTask(e) {
		this.setState({ Inloading: true });
		const userdatatocheckusertype = this.props.user;
		let data = {
			'duedate': this.state.duedate,
			'orgid': this.props.orgid,
			'projectid': this.props.prjid,
			'userid':userdatatocheckusertype.ID,
			'approvetaskid' :this.props.taskid
		}
		//console.log('data',data);
		this.saveTaskdata(data);
	}

	async saveMaybeOption(e) {
		const userdatatocheckusertype = this.props.user;
		let data = {
			'userid':userdatatocheckusertype.ID,
			'comment' :this.state.comment
		}
		//console.log('data',data);
		//this.saveTaskdata(data);
		await this.props.saveMaybeOption(data);
		this.setState({comment:'', setReviewProject: false });
	}
	
	saveTaskdata(data)
	{
		this.setState({ Inloading: true });
		PostData('createvideoshootpostproductiontask', data).then((result) => {
			if (result.statuscode === 200) {				
				this.setState({ tasksucsmsg: "Task Added Succesfully" });
				setTimeout(() => {
					this.setState({  tasksucsmsg: '',setReviewProject: false });
					this.props.loadProjectBoard();
				}, 3000);
			}
			else if (result.statusCode === 401) {
				this.setState({ tasktimeerrormsg: result.Message });
				setTimeout(() => this.setState({ tasktimeerrormsg: '' }), 3000);
			}
			this.setState({ Inloading: false });
		});
	}
	handleProjectDateChange(dateName, dateValue) {
		this.setState({
			duedate: dateValue
		});

	}

    render() {
    	const ExampleCustomInputForDuedate = ({ value, onClick }) => (
			<button className="example-custom-input" onClick={onClick}>
				<div>
				{
					(!this.state.duedate ?
						<>
						Select Date
						</>
						:
						<>{value}</>
						
					)
				}
				</div>
			</button>
		);
    	const userdatatocheckusertype = this.props.user;
		console.log('taskstatustype=',this.props.taskstatustype);
		const firstname = userdatatocheckusertype.first_name;
        return (
        	<div>
        	{this.props.taskstatustype=='accept'?
        	<Modal show={this.state.setReviewProject}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Accept</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.tasksucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row">
						<div className="col-12">
						<p>@{firstname}, thank you for accepting this project. What due date would you like me to assign? </p>
						</div>
					</div><br/>
					<div className="row">
						<div className="col-4">
							<label>	Select Due Date:</label>
						</div>
						<div className="col-8">
							<DatePicker className="form-control" selected={this.state.duedate}
								name="duedate"
								minDate={moment().toDate()}
								onChange={date => this.handleProjectDateChange('duedate', date)}
								dateFormat="MM/dd/yyyy"
								customInput={<ExampleCustomInputForDuedate />} 
								inline
								/>
						</div>
					</div><br/>
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddReviewProjectClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.saveTask(e)}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
			:
			<Modal show={this.state.setReviewProject}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Maybe</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.tasksucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
					<div className="row">
						<div className="col-12">
						<p>@{firstname}, thank you for looking at this project. Please let us know how we could make this work for you. </p>
						</div>
					</div><br/>
					<div className="row">
						<div className="col-12">
							<input className="LP_field draftcomment" style={{"width": "100%", "height": "50px"}} rows="6" name="comment" value={this.state.comment} onChange={this.commentChange.bind(this)} type="textarea" placeholder="Comment" />
						</div>
						
					</div><br/>
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddReviewProjectClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.saveMaybeOption(e)}>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		}
			</div>
		);
    }
}
export default ApproveReviewTask;