import React, { Component } from "react";
import axios from "axios";
import {GetData,PostDataWithUpload} from '../../services/PostData';
import {apiConfig} from "../../config/api"
class AddTeam extends Component {
    constructor(props){
	super(props);
	this.state = {
		fields: {},
		errors: {},
		servererror:''
	};
	this.submit = this.submit.bind(this);
	this.onChange = this.onChange.bind(this);
	this.onChangeHandler = this.onChangeHandler.bind(this);
	//console.log('checkkkkk',this.props.orgid);
	}
	componentDidMount() {
        GetData('orgprojectlist/'+this.props.orgid).then((result) => {   
			console.log('result',result);   //projectlist   
			this.setState({ 
				projectlist: result.data,
			});    
            // this.setState({ 
            //      loading: false,
            //      teamList: result.data.org_users,
            //      originalTeamList:result.data.org_users,
            //  });
        });
        // this.setState({ 
        //     teamList: this.props.teamlist,
        //     originalTeamList:this.props.orgiteamlist,
        // });
   }
	onChangeHandler=event=>{
	    this.setState({
	      'profileimg': event.target.files[0],
	      loaded: 0,
	    })
	  }
	onChange(e){
		//console.log('checkkkkk',this.props.orgid);
		this.setState({[e.target.name]:e.target.value});
	}
	submit(e) {	
		e.preventDefault();
		//this.props.view('viewteam');
		if (this.validateForm())
		{
			//console.log('this.state.profileimg==',this.state.profileimg);		
			const data = new FormData();
   			data.append('first_name', this.state.f_name)
   			data.append('last_name', this.state.l_name)
   			data.append('email', this.state.email)
   			data.append('phone', this.state.phone)
   			data.append('username', this.state.username)
   			data.append('organization', this.props.orgname)
   			data.append('password', this.state.password)
   			data.append('job_title', this.state.jobtitle)
   			data.append('linkedin', this.state.linkedin)
   			data.append('twitter', this.state.twitter)
   			data.append('profile_pic', this.state.profileimg)
			data.append('notes', this.state.notes)
			data.append('projects',JSON.stringify(this.state.projects)
			);
		    let session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
		   // console.log('data======data=',data);
		    fetch(apiConfig.WEB_SERVICE_URL+'addorguser', {
				method: 'POST',
				body: data,
				headers: {
					"session-key": session_key,
				}
		    })
		    .then((response) => response.json())
		    .then((res) => {
				//console.log('res====',res);;
				console.log('res',res)
				//this.props.view();
				if(res.statusCode===401)
				{
					this.setState({
						servererror:res.message
					})
				}
				else
				{
					this.props.view();
				}
		     //this.props.history.push("/organization/list");
		    });
		}
 	}
 	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["f_name"]) {
			formIsValid = false;
			errors["f_name"] = "*Please enter your First Name";
		}
		if (!fields["l_name"]) {
			formIsValid = false;
			errors["l_name"] = "*Please enter your Last Name";
		}
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your Email Address";
		}
		// if (!fields["org_name"]) {
		// 	formIsValid = false;
		// 	errors["org_name"] = "*Please enter your Organization Name";
		// }
		if (!fields["username"]) {
			formIsValid = false;
			errors["username"] = "*Please enter User Name";
		}
		if (!fields["password"]) {
			formIsValid = false;
			errors["password"] = "*Please enter Password";
		}
		if (typeof fields["phone"] !== "undefined") {
			//regular expression for email validation
			var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
			if (!phoneNum.test(fields["phone"])) {
				formIsValid = false;
				errors["phone"] = "*Please enter valid Phone Number.";
			}
		}  
		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var email = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; 
			if (!email.test(fields["email"])) {
				formIsValid = false;
				errors["email"] = "*Please enter valid Email Address.";
			}
		}  
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	selectProject(e){
		let value = Array.from(e.target.selectedOptions, option => option.value);
		this.setState({projects: value});
		console.log('value===',value);
	}
    render() {
    	const isshowing_subscription_hour=this.state.isshowing_subscription_hour;
		const isshowing_org_credit=this.state.isshowing_org_credit;
		//console.log('this.props.orgname==',this.props.orgname);
		const plist = [];
		const projectlist = this.state.projectlist;
		if (projectlist) {
			console.log('projectlist==',projectlist);
			for (var key in projectlist) {
				plist.push(<option value={projectlist[key]._id} >{projectlist[key].project_name}</option>)
			}
		}
        return (
        	<div className="container-fluid create-organization">
				<div class="row EditTeamBox">
					<div className="panel panel-primary">
						<h4 class="PageTopTitle">Add  Team</h4>			 
						<div className="panel-body">
							<form enctype="multipart/form-data">
								<div className="row " id="Body">
									<div className="col-md-12 FormDesign">
										<div className="row">
											<div className="col-12 errorMsg">{this.state.servererror}</div>
											<div className="col-6">
												<label htmlFor="f_name">First Name <span>*</span></label>			
												<input type="text" name="f_name" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.f_name}</div>			
											</div>
											<div className="col-6">
												<label htmlFor="l_name">Last Name <span>*</span></label>			
												<input type="text" name="l_name" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.l_name}</div>			
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="email">Email <span>*</span></label>				
												<input type="email" name="email" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.email}</div>			
											</div>
											<div className="col-6">
												<label htmlFor="phone">Phone</label>
												<input type="text" name="phone" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.phone}</div>			
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="username">Username <span>*</span></label>			
												<input type="text" name="username" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.username}</div>		
											</div>
											<div className="col-6">
												<label htmlFor="password">Password <span>*</span></label>			
												<input type="password" name="password" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.password}</div>		
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="org">Organization  <span>*</span></label>			
												<input type="text" readOnly defaultValue={this.props.orgname} name="org_name" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.org_name}</div>		
											</div>
											<div className="col-6">
												<label htmlFor="jobtitle">Job Title</label>				
												<input type="text" name="jobtitle" onChange={this.onChange} className="form-control" autoFocus />
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="twitter">Twitter</label>
												<input type="text" name="twitter" onChange={this.onChange} className="form-control" autoFocus />
											</div>
											<div className="col-6">
												<label htmlFor="linkedin">LinkedIn</label>
												<input type="text" name="linkedin" onChange={this.onChange} className="form-control" autoFocus />				
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="logo">Upload Profile Pic</label>
												<input type="file" name="profileimg" onChange={this.onChangeHandler} className="form-control" autoFocus />
											</div>
											<div className="col-6">
												<label htmlFor="notes">Notes</label>
												<input type="text" name="notes" onChange={this.onChange} className="form-control" autoFocus />
											</div>
										</div>	
										{/* <div className="row">
											<div className="col-6">
												<select name="taskstatus" className="form-control" value={this.state.editedprojectstatus} onChange={this.selectProject.bind(this)} multiple>
													<option value=" ">Select Project Status</option>
													{plist}
												</select>
											</div>
											<div className="col-6"></div>
										</div> */}
										<button type="submit" onClick={this.submit} className="UpdateBtn btn btn-primary">Create</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
    }
}
export default AddTeam;