import React, {Component} from 'react';
import logo from '../assets/images/logo.png'; 
import admin from '../assets/images/admin.jpg'; 
import backbtnicon from '../assets/images/back-btn-icon.png';
import homebtnicon from '../assets/images/home-btn-icon.png';
import menutoggleicon from '../assets/images/menu-toggle.png';
import {  Dropdown } from 'react-bootstrap';
import { Link  } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
export default class Header extends Component {
	constructor(props) {
        super(props);

        this.state = {
            visible: false  
        };

        this.toggleMenu = this.toggleMenu.bind(this);
	}
	toggleMenu() {
        this.setState({visible: !this.state.visible})
    }

    render(){
		let visible=this.state.visible;
		let toggleclassname= (visible)?'showtopmenu':''
		let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		//let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
    	 if(checkuser && checkuser.user && (checkuser.user.ID==15888 || checkuser.user.ID==12612 || checkuser.user.ID==28 || checkuser.user.ID==202371015253))
		{
			localStorage.setItem('userData','');
			return (<Redirect to={'/'}/>)
		}
		console.log('checkuser from header=',checkuser);
        if(checkuser && checkuser.user && (checkuser.ID==26|| (checkuser && !checkuser.user)))
		{
			localStorage.setItem('userData','');
			return (<Redirect to={'/'}/>)
		}
		return ( 

        	 <nav className="navbar navbar-expand-lg">
        	  <Link className="navbar-brand" to="/">
        	  <img src={logo} alt="logo"/>
			  <h2>PROJECTS</h2>
			  </Link>
			  <button className="navbar-toggler" onClick={this.toggleMenu} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <img src={menutoggleicon} alt="image"/>
			  </button>
			  
			  {
      			checkuser?
		        (
			  <div className={`collapse navbar-collapse ${toggleclassname}`} id="navbarSupportedContent">
			  	<ul className="navbar-nav mr-auto">
		            
		           
		            <li className="nav-item">
		            	<Link to="/organization/list">Organization</Link>
		            </li>
		            				            
		            <li className="nav-item BackHideMo">
		            <Link to="/"><img src={backbtnicon} alt="image"/></Link></li>
		            <li className="nav-item HomeHideMo">
		            	<Link to="/"><img src={homebtnicon} alt="image"/></Link>
		            </li>
		            <li className="nav-item LogoutShowMo">
		            	<Dropdown.Item href="/logout">LOGOUT</Dropdown.Item>
		            </li>	
		            <li className="nav-item dropdown MoHide">
		                <Dropdown>
						  <Dropdown.Toggle variant="success1" id="dropdown-basic">
						     <img src={admin}/>{checkuser.user.displayname}
						  </Dropdown.Toggle>
						  <Dropdown.Menu>
						    <Dropdown.Item href="/logout">Logout</Dropdown.Item>
						  </Dropdown.Menu>
						</Dropdown>
		            </li>
		          </ul>			    
			  </div>
			  )
			        :null 
		        } 
			</nav>    
        )
    }
}