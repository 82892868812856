import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {PostData,GetData} from '../services/PostData';


import Loader from 'react-loader-spinner'
import OrderForm from './OrderForm'

import { loadStripe } from "@stripe/stripe-js";



 
  import { StripeProvider, Elements } from 'react-stripe-elements'
  import CheckoutForm from './CheckoutForm';

class ClosedCaptioning extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(){
		super();
		this.state = {
			selectedvideo:'',
			isshow:false,
			gridIframe:null,
			projectname:'',
			order:'',
			successmessage:'',
			fileshowcode:false,
			selectedorder:''
		};
		
	};
	moveFolder(e){
		this.setState({ setShow: true });
	}
	orderNow(e){
		this.setState({
			isshow:true
		});
		// let data = {
		// 	'videoid': 'id',			
		// }
		// PostData('createorder', data).then((result) => {
		// 	var link = 'https://my.freshbooks.com/#/checkout/'+result.entitylink;
		// 	window.open(link, '_blank')
		// 	if (result.statusCode === 200) {
		// 		this.setState({ showfile: false, filetitlesucsmsg: result.message });
		// 		setTimeout(() => this.setState({ filetitlesucsmsg: '' }), 3000);	
		// 	}
		// });
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	componentDidMount() {		
		this.setState({loading:true});
		console.log('this.props.match.params.orgid==',this.props.match.params.orgid);
        let data = {
            'org_id': this.props.match.params.orgid,
		}
		Promise.all([this.getProjectData(data)])
			.then(([projectdata]) => {
			if(projectdata.statuscode===200)
            {
                this.setState({projectlist:projectdata.data,loading:false});
			}
		})
			
	}
	getProjectData(data){
		return PostData('listcompletedvideolist', data).then((result) => {
			return result;
		});
	}
	onIframeRef = (node) => {
		this.Iframe = node;
	 };                  
	handleVideoChange(e){
		this.setState({
			isshow:false
		});
		console.log('e.target.value==',e.target.value);
		//var video = document.querySelector('video');
	//	console.log('video.duration ==',video.duration );
		let index = e.nativeEvent.target.selectedIndex;
		let label = e.nativeEvent.target[index].text;  
		console.log('label',label);
		this.setState({selectedvideo:e.target.value,projectname:label,srtfile: '',});
		let vimeovideo = e.target.value;
		let forid =vimeovideo.split('/');
		console.log('forid===',forid);
		let id=forid[2]
		let url= 'https://player.vimeo.com/video/'+id+'?autoplay=1';
		
		

		//var x = document.getElementsByClassName("focus-target").getAttribute("aria-valuemax");
		//console.log('x==',x);
		
		let data = {
			 	'videoid': id,			
		 }
		PostData('getvideodurationstg', data).then((result) => {
			if (result.statusCode === 200) {
				var time = result.videoduration;
				//var time = 481;
				var inmin = Math.floor(time / 60);
				var sec = time % 60;
				if(sec>0)
				{
					inmin = inmin+1;
				}
				console.log('inmin==',inmin);
				this.setState({
					//isshow:true,
					videodurationinminute: inmin,
					order:result.order,
					isshow:true
				});

						
				//this.setState({ showfile: false, filetitlesucsmsg: result.message });
				//setTimeout(() => this.setState({ filetitlesucsmsg: '' }), 3000);	
			}
		});
		
		//var x = document.getElementById("myAnchor").getAttribute("target");

		// let data = {
		// 	'videoid': id,			
		// }
		// PostData('getvideoduration', data).then((result) => {
		// 	if (result.statusCode === 200) {
		// 		this.setState({ showfile: false, filetitlesucsmsg: result.message });
		// 		setTimeout(() => this.setState({ filetitlesucsmsg: '' }), 3000);	
		// 	}
		// });
	}
	handleLanguageChange(e){
		console.log('e.target.value==',e.target.value);
		let order = e.target.value;

		let order1= JSON.parse(order);
        console.log('order1==',order1);

		this.setState({
			srtfile: '',
			selectedorder:order1		
		});
		if(order)
		{
			let data = {
				'order': order1,			
			}
			PostData('readsrtfile', data).then((result) => {
				if (result.statusCode === 200) {

					this.setState({
						//isshow:true,
						srtfile: result.file,
						issrtshow:true
					});
					
					
				}
			});
		}


		// var preview = document.getElementById('show-text');
		// var textFile = /text.*/;
		// var reader = new FileReader()
		// if (textFile) {
		// 	reader.onload = function (event) {
		// 		preview.innerHTML = event.target.result;
		// 	}
		// } else {
		// 	preview.innerHTML = "<span class='error'>No file!</span>";
		// }
		// reader.readAsText(file);


	}	
	closePopup = () =>{
		this.setState({
			isshow:false,
			issrtshow:false
		});
	}
	handleOnChange(e)
	{
		console.log('e.target.value==',e.target.value);
		this.setState({
			srtfile:e.target.value
		})
	}
	updateSRT()
	{
		let data = {
			'order': this.state.selectedorder,	
			'srtfile': 	this.state.srtfile,	
		}
		PostData('updatesrtfile', data).then((result) => {
			if (result.statusCode === 200) {

				this.setState({
					successmessage:result.message
				})
				setTimeout(() => this.setState({ successmessage: '' }), 3000);	
			}
		});
	}
	cancelSRT()
	{
		console.log('called in cancel');
	}
    fileshowcode(){
        this.setState({
            fileshowcode:true
        })
    }
	render() {		
    	
		const plist = [];
		let iframehtml=''
		let url = ''
		const projectlist = this.state.projectlist;
		let id;
		const llist =[];
		let orderlist =this.state.order;
		let selectedorder = this.state.selectedorder;
		//console.log(selectedorder);
		let srturl = selectedorder.revfilelocation;
		//if(orderlist)
		//orderlist = orderlist.language;
		if (projectlist) {
			console.log('projectlist==',projectlist);
			for (var key in projectlist) {
				if(projectlist[key].completedvideourl)
				{
					let name = projectlist[key].project_name;
					let videolen = projectlist[key].completedvideoarray.length;
					let video_name_len = projectlist[key].completedvideodata.length;
					if(video_name_len>0)
					{
						let video_name_uri = projectlist[key].completedvideodata
						video_name_uri.reverse();
						video_name_uri.forEach((val)=>{
							let vnamev = ''
							let videoname1 = val.vname;
							videoname1 =	videoname1.split('_');
						  	console.log("videoname1",videoname1);
						  	let vlen = videoname1.length;
						  	videoname1.splice(vlen-1,1);
						  	console.log("videoname after",videoname1);
						  	videoname1 = videoname1.join('_');

							let vname = name+"-"+videoname1;
							plist.push(<option value={val.vimeourl} >{vname}</option>)
						})
					}
					else
					{
						
						if(videolen>0)
						{
							let videoname=projectlist[key].completedvideoarray[videolen-1];
						  	videoname =	videoname.split('_');
						  	console.log("videoname",videoname);
						  	let vlen = videoname.length;
						  	videoname.splice(vlen-1,1);
						  	console.log("videoname after",videoname);
						  	videoname = videoname.join('_');
						  	//videoname=videoname[0]
						  	name = name+"-"+videoname;
						}
						plist.push(<option value={projectlist[key].completedvideourl} >{name}</option>)
					}
				}
			}
		}
		if (orderlist) {
			console.log('orderlist in if ==',orderlist);
			
			for (var val of orderlist) {
				console.log('val==',val);
				let valintringify= JSON.stringify(val);
				let language= JSON.parse(val.language);
				console.log('language in for loop ==',language);
				if(language[0].id)
				{
					console.log('language[key].id ==',language[0].id);
					let lan;
					switch(language[0].language)
					{
						case 'en':
							lan="English";
						break;
						case 'es-es':
							lan="Spanish (Spain)";
						break;
						case 'es-la':
							lan="Spanish (Latin)";
						break;
						case 'fr':
							lan="French";
						break;
						case 'zh-si':
							lan="Chinese (Simplified)";
						break;
						case 'zh-tr':
							lan="Chinese (Traditional)";
						break;
						case 'de':
							lan="German";
						break;
						case 'ja':
							lan="Japanese";
						break;
						case 'ar':
							lan="Arabic";
						break;
						case 'cs':
							lan="Czeck";
						break;
						case 'nl':
							lan="Dutch";
						break;
						case 'hi':
							lan="Hindi";
						break;
						case 'it':
							lan="Italian";
						break;
						case 'ko':
							lan="Korean";
						break;
						case 'po':
							lan="polish";
						break;
						case 'pt-br':
							lan="Portuguese";
						break;
						case 'ru':
							lan="Russian";
						break;
						case 'tr':
							lan="Turkish";
						break;						
						default:
							lan="English";
					}
					llist.push(<option value={valintringify} >{lan}</option>)
				}
			}
		}
		if(this.state.selectedvideo)
        {
			let forid =this.state.selectedvideo.split('/');
			console.log('forid===',forid);
			id=forid[2]
			url= 'https://player.vimeo.com/video/'+id+'?autoplay=1';
			iframehtml='<iframe width="560" height="315" src="'+url+'" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
		}

		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let fullname=userdata.user.first_name+" "+userdata.user.last_name
		let email=userdata.user.email
		let userId=userdata.user.ID
		//console.log('userdata=',userdata);
		//console.log('fullname=',fullname);
		//console.log('email=',email);
        return (
        	<div className="container-fluid AddProjectPage MobPaddHide"  ref="selfdiv">
				
				
				{/* <SRTForm isshow={this.state.issrtshow} vimeoid={id} orgid={this.props.match.params.orgid} videoinminute={this.state.videodurationinminute} projectname={this.state.projectname} order={this.state.order} srtfile={this.state.srtfile} closePopup={this.closePopup} />	 */}
				<div className="panel panel-primary closedcaptioning">
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}
								/>
							</div>
						:null
					}
					<div className="panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-7">									
									<select name="vimeovideo" className="form-control vimeovideo" value={this.state.selectedvideo} onChange={this.handleVideoChange.bind(this)} >
 										<option value="">Please Select</option>
										 {plist}									
									</select>
								</div>
								<div className="col-5">									
									<h4 class="PageTopTitle">Closed Captioning</h4>									
								</div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid video-data">
						<div className="row FormDesign">
							<div className="col-7">

								{ 	(url!='' && 
										<div>
										<div className="embeded-code-btn-row">
											<a className="embeded-code-btn" onClick={this.fileshowcode.bind(this)}>Embed Code</a>
											{
			                                    ( this.state.fileshowcode===true && 
			                                    <div>
			                                        {iframehtml}
			                                    </div>
			                                    )
			                                }
										</div>    
											{url ? (
												<iframe src={url} id="myiframe" ref={this.onIframeRef} width="640" height="400" frameborder="0" allow="fullscreen" allowfullscreen></iframe>
											) : (
												''
											)}                        
										</div>
									)
								}                                                    	                         
								
								{
									(this.state.selectedvideo &&
										<select name="vimeovideo" className="form-control vimeovideo" onChange={this.handleLanguageChange.bind(this)} >
											<option value="">Please select Language</option>
											{llist}									
										</select>	
									)
								}

								{
									(this.state.srtfile && 
										<div>
										<div className="two-btn subtitle-area-top-bar">
											<a className="file subtitle-area-top-bar-download" href={require(`../assets/vtt/${srturl}`)} download> Download</a>
											<a className="file subtitle-area-top-bar-cancel" onClick={(e)=>this.cancelSRT(e)} > Cancel</a>
											<a className="file subtitle-area-top-bar-save" onClick={(e)=>this.updateSRT(e)} > Save</a><br/>
										</div>
										<div> {this.state.successmessage} </div>
										<textarea style={{"width" : "100%"}} rows="8" className="subtitle-textarea"  type="text" value={this.state.srtfile} onChange={(event) => this.handleOnChange(event)}></textarea>                                                     
									</div>
									)
								}
								
							</div>
							<div className="col-5">
		
			
						            {
						            	(this.state.isshow &&
						            	<OrderForm isshow={this.state.isshow} vimeoid={id} orgid={this.props.match.params.orgid} videoinminute={this.state.videodurationinminute} projectname={this.state.projectname} order={this.state.order} name={fullname} email={email} userId={userId} closePopup={this.closePopup} />	
						            	)
						            }
                               
							</div>         
						</div>                     
		        	</div>
		        </div>										
			</div>
		);
    }
}
export default ClosedCaptioning;
