import React, { Component } from "react";
import {PostData} from '../services/PostData';
import Loader from 'react-loader-spinner'
import DatePicker from "react-datepicker";
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
class AddToProject extends Component {

    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},	
			setAddToProjectShow:this.props.handleAddToProjectShow,		
		};
		console.log('calledddd==',this.props.handleAddToProjectShow);
	}
	
	handleAddTimeClose(e) {
		this.setState({ setAddToProjectShow: false });
	}

	addToProjectBoard(e) {
		//if (false && this.validateAddTaskTimeForm()) {
			this.setState({ Inloading: true });
			//const taskid = this.state.selectedtask;
			//const hour = this.state.thour.format('HH');
			//const minut = this.state.tminut.format('mm');
			//console.log('taskid with date time==',hour,minut);
			//const finaltime = hour + ':' + minut;
			let data = {
				//'title': this.state.tcomment,
				'org_id': this.props.orgid,
				'projectid': this.props.prjid,
				//'scripttime': finaltime,
				'script': this.props.producescript,
				'project': this.props.project,
				'userid':this.props.userid,
				'scriptwritertitle':this.props.scriptwritertitle,
				'editedboardid':this.props.editedboardid
			}
			//console.log('data',data);
			this.savescriptdata(data);
			//if(this.props.isScriptEdit)
			//this.updatescriptdata(data);
		   // else
		   // this.savescriptdata(data);
		//}
	}
	validateAddTaskTimeForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;

		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	savescriptdata(data)
	{
		this.setState({ Inloading: true });
		PostData('uploadscripttoprojectboard', data).then((result) => {
			if (result.statuscode === 200) {				
				this.setState({ tasktimesucsmsg: "Script Added Succesfully", thour: '', tminut: '', tcomment: '',selectedtask:'',selectedassigneeteammember:'',selectedassigneeuserid:'' });
				setTimeout(() => {
					this.setState({  tasktimesucsmsg: '',setAddTimeShow: false });
					//this.props.loadProjectBoard(result.totaltime);
				}, 3000);
			}
			else if (result.statusCode === 401) {
				this.setState({ tasktimeerrormsg: result.Message });
				setTimeout(() => this.setState({ tasktimeerrormsg: '' }), 3000);
			}
			this.setState({ Inloading: false });
		});
	}
	updatescriptdata(data)
	{
		this.setState({ Inloading: true });
		PostData('updatescripttoprojectboard', data).then((result) => {
			if (result.statuscode === 200) {				
				this.setState({ tasktimesucsmsg: "Script Updated Succesfully", thour: '', tminut: '', tcomment: '',selectedtask:'',selectedassigneeteammember:'',selectedassigneeuserid:'' });
				setTimeout(() => {
					this.setState({  tasktimesucsmsg: '',setAddTimeShow: false });
					//this.props.loadProjectBoard(result.totaltime);
				}, 3000);
			}
			else if (result.statusCode === 401) {
				this.setState({ tasktimeerrormsg: result.Message });
				setTimeout(() => this.setState({ tasktimeerrormsg: '' }), 3000);
			}
			this.setState({ Inloading: false });
		});
	}
    render() {
		console.log('called every time');
        return (
        	<Modal show={this.state.setAddToProjectShow}>				
				<Modal.Header class="SelectProducerHead">
					<Modal.Title>Add To Project</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{this.state.tasktimesucsmsg}</div>
					<div className="errormsg">{this.state.tasktimeerrormsg}</div>
				
				</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={this.handleAddTimeClose.bind(this)}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => this.addToProjectBoard(e)}>
						Add To Project
					</Button>
				</Modal.Footer>
			</Modal> 

		);
    }
}
export default AddToProject;