import React, { Component } from "react";
import {PostData,GetData} from '../services/PostData';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class VoiceOvers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successmsg:'',
            errormsg:'',
            voiceovertext:'',
            selectedvoice:'',
            fields: {},
			errors: {},
        };
    }
    componentDidMount() {

    }
    saveVoice(e){
        if (this.validateVoiceData()) {
            console.log('ccaledddddddd if success');
            // let data={
            //     orgid:this.props.match.params.orgid,
            //     voiceovertext:this.state.voiceovertext,
            //     selectedvoice:this.state.selectedvoice
            // }

            let url = "https://api.lovo.ai/v1/conversion";
                let  data = {
                text: this.state.voiceovertext,
                speaker_id: this.state.selectedvoice,
                emphasis: "[0, 1]",
                //emphasis: "[0, 1]",
                speed: 1,
                pause: 0,
                };
                const option = {
                method: "POST",
                headers: {
                    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVmMWU0NjY0NGIzOWVjMDAzMmUyYzRkYSIsImlhdCI6MTU5NTgyMDQ0OTE2MH0.fuUgJRIwvDr125BpZBqA8Dqm9OU3BXvZuPhvNuTt2a4",   // Your API key goes here
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(data),
                };

                fetch(url, option)
                .then((res) => res.arrayBuffer())
                .then((buffer) => {
                    // you can also download the audio file
                    console.log('buffer====',buffer);
                    const blob = new Blob([buffer], { type: "audio/wav" });
                    console.log('blob====',blob);
                    const objUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = objUrl;
                    a.download = "test.wav";
                    // create click event handler to revoke objectURL and remove this event handler
                    const clickHandler = () => {
                    setTimeout(() => {
                        window.URL.revokeObjectURL(url);
                        this.removeEventListener("click", clickHandler);
                    }, 100);
                    };
                    // add above event handler and click to download
                    a.addEventListener("click", clickHandler);
                    a.click();
                    console.log(`Audio content written to file `)
                    //console.log(`Audio content written to file ${filename}`)
                })
                .catch((error) => console.log(error));

            // PostData('savevoice', data).then((result) => {
            //     if (result.statusCode === 200) {
            //         console.log('===',result.message);
            //         this.setState({
            //             successmsg:result.message
            //         });
            //         setTimeout(() => {
            //             window.location.reload();
            //         }, 3000);
            //     }
            // });
        }
    }
    validateVoiceData() {
		//const hour=this.state.hour;
		//const minut=this.state.minut;
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["voiceovertext"]) {
			formIsValid = false;
			errors["voiceovertext"] = "*Please enter Voice Text";
		}
		if (!fields["selectedvoice"]) {
			formIsValid = false;
			errors["selectedvoice"] = "*Please Select Voice";
		}
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
    voiceOverTextChange(e){
		this.setState({ [e.target.name]: e.target.value });
    }
    handleVoiceChange(e){
        this.setState({
            selectedvoice:e.target.value,
        })
    }
    render() {

        return (
			<div className="container-fluid ProjectList"  ref="selfdiv">
                <div className="row">
                    <div className="col-12">
                       <h3>Voice Overs</h3>
                       <div className="msg">
                            <div className="errorsmsg">{this.state.errormsg}</div>
                            <div className="successmsg">{this.state.successmsg}</div>
                       </div>
                       <div className="row">
                            <div className="col-8 alignleft">
                                <textarea  className="LP_field voiceovertext form-control" rows="4" name="voiceovertext" value={this.state.voiceovertext} onChange={this.voiceOverTextChange.bind(this)}  placeholder="Voice Over Text" />
                                <div className="errorMsg">{this.state.errors.voicetext}</div>
                                <br/>
                                <select name="taskstatus" className="form-control" value={this.state.selectedvoice} onChange={this.handleVoiceChange.bind(this)}>
                                    <option value=" ">Select Voice</option>
                                    <option value="Caroline Hughes">Caroline Hughes</option>
                                    <option value="Kim Ferdowsi">Kim Ferdowsi</option>
                                    <option value="Ronald Brooks">Ronald Brooks</option>
                                    <option value="Glenn da Jolla">Glenn da Jolla</option>
                                    <option value="Saoirse O’Conor">Saoirse O’Conor</option>
                                    <option value="Luna Sidana">Luna Sidana</option>
                                    <option value="Jeff Lee">Jeff Lee</option>
                                    <option value="Ian Kensington">Ian Kensington</option>
                                    <option value="Jess Jones">Jess Jones</option>
                                    <option value="Amy Chang">Amy Chang</option>
                                    <option value="Bryce Stone">Bryce Stone</option>
                                    <option value="Dick Cransen">Dick Cransen</option>
                                    <option value="Tess Uberville">Tess Uberville</option>
                                    <option value="Karen Sidhart-Jones">Karen Sidhart-Jones</option>
                                    <option value="Thalia LeChamp">Thalia LeChamp</option>
                                </select><br/>
                                <div className="errorMsg">{this.state.errors.voiceperson}</div>
                                <a class="create_org btn btn-info" onClick={this.saveVoice.bind(this)}>Save Voice Over</a>
                            </div>
                            <div className="col-4 alignright">
                                
                                
                            </div>
                        </div><br/>
                    </div>
                </div> <br/><br/>
            </div>
		);
    }
}
export default VoiceOvers;
