import React from "react";
import { useCalendlyEventListener,PopupModal } from "react-calendly";
import axios from "axios";

const CalendlyIframe = (props) => {
	const utm={
	  utmCampaign: 'Spring Sale 2019',
	  utmContent: 'Shoe and Shirts',
	  utmMedium: 'Ad',
	  utmSource: 'Facebook',
	  utmTerm: 'Spring'
	};
	const styles={
	  height: '1000px'
	};
	const pageSettings={
	  backgroundColor: 'ffffff',
	  hideEventTypeDetails: false,
	  hideLandingPageDetails: false,
	  primaryColor: '00a2ff',
	  textColor: '4d5055'
	};
	const prefill={
	  email: props.loggedemail,//paris@associationstudios.com
	  firstName: props.fname,
	  lastName: props.lname,
	  name: 'Schedule Event',
	  smsReminderNumber: '+1234567890',
	  guests: [],
	  customAnswers: {
	    a1: 'a1',
	    a2: 'a2',
	    a3: 'a3',
	    a4: 'a4',
	    a5: 'a5',
	    a6: 'a6',
	    a7: 'a7',
	    a8: 'a8',
	    a9: 'a9',
	    a10: 'a10'
	  },
	  date: new Date(Date.now() + 86400000)
	};
	function onClose(){
        props.onclose();
    }
     useCalendlyEventListener({
	    onProfilePageViewed: () => console.log("onProfilePageViewed"),
	    onDateAndTimeSelected: async (e) => {
	    	console.log("onDateAndTimeSelected",e);
	    },
	    onEventTypeViewed: () => console.log("onEventTypeViewed"),
	    onEventScheduled: async (e) => {
	    	console.log('payload=',e.data.payload);
	    	if(true && e.data.payload){
	    		let payload = e.data.payload;
		    	const url = payload.event.uri;
		    	console.log('url=',url);
		    	const array = url.split('/');
				const lastsegment = array[array.length-1];
				console.log('lastsegment=',lastsegment);
				if(lastsegment){
					try {
					  	const options1 = {
						  method: 'GET',
						  url: 'https://api.calendly.com/scheduled_events/'+lastsegment,
						  headers: {'Content-Type': 'application/json', Authorization: 'Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE1MjQ1Mjk1LCJqdGkiOiI2NDZiNzFjMi00MzM1LTQ0NDMtOTIxNy05OWUzNTNkNzJiMWIiLCJ1c2VyX3V1aWQiOiJGQURERDVXSkNINVJFWFY3In0.fBjBlT2i83JQlQd8dBFidubOEy_n9eeL88KCTsTQUdrZzSb8o7yN1x54IdQt9y_penbRlkrC66H8HC7C8tad4g'}
						};

						const { data } = await axios.request(options1);
					    console.log('data==',data);
					    if(data){
					     	props.addClendlyNotifyMessage(data.resource);
					     	setTimeout(() => {
								props.onclose();
							}, 5000);
					    }
					} catch (error) {
					  console.error(error);
					}
				}
			}
	    },
	 });
  	return (
	    <div className="App">
	      <PopupModal
	          url={props.eventtype}
	          pageSettings={pageSettings}
	          utm={utm}
	          prefill={prefill}
	          onModalClose={onClose} 
	      	  open={props.isOpen}
	          /*
	           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
	           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
	           */
	          rootElement={document.getElementById("root")}
	        />
	    </div>
	 );
};

export default CalendlyIframe;