import React, { Component } from "react";
import {PostData,GetData,PostDataWithUpload} from '../../../services/PostData';
import {apiConfig} from "../../../config/api"
import Loader from 'react-loader-spinner'
import TimePicker from 'rc-time-picker';
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import io from "socket.io-client";
import SocketIOFileUpload from "socketio-file-upload"; 
import { EditorState, ContentState, convertFromRaw, convertToRaw, convertFromHTML, decorator, convertRawToConvertable, Entity, Modifier  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
var Vimeo = require('vimeo').Vimeo;
class Video extends Component {
	state = {
		dhour:'00:00',
		dminut:'00:00',
	};
    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},
			saveBoardDataloader:false,
			dhour:'',
			dminut:'',
			loadingstatus:'',
			showdialogbox:false,
			chatattchedfilename:[],
			chatattachament: '',
			chatattchedfilebasename:[],
			editorState: EditorState.createEmpty()
		};
		this.onChange = this.onChange.bind(this);
		this.onDraftChangeHandler = this.onDraftChangeHandler.bind(this);
		this.onDraftPosterImageChange = this.onDraftPosterImageChange.bind(this);
		let url = apiConfig.WEB_SERVICE_URL;
		url = url.replace('http://', '');
		url = url.replace('/api/', '');
		this.socket = io(url);		
		let currentstate=this;
		this.socket.on('SAVE_ATTACHMENT', function (data) {
			console.log('check dataaaaaaaaaaa======',currentstate.state.chatuploadincurrentpage,data.bytesLoaded,'attachedfilesize==',currentstate.state.attachedfilesize);
			if(currentstate.state.chatuploadincurrentpage && data.bytesLoaded===currentstate.state.attachedfilesize)
			{
				currentstate.setState({
					//chatattchedfilebasename:data.base,
					//chatattchedfilename:data.name,
				});
				currentstate.setState({ chatattchedfilename: [...currentstate.state.chatattchedfilename, data.name],chatattchedfilebasename: [...currentstate.state.chatattchedfilebasename, data.base] })
			}
			//console.log('data.base',data.base);
			//console.log('chatattchedfilename',currentstate.state.chatattchedfilename);
			//console.log('chatattchedfilebasename',currentstate.state.chatattchedfilebasename);
		});
		
		this.sendMessage = ev => {
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
			ev.preventDefault();
			const datta = this.state.editorState;
			console.log('datta==',datta);
			if(!datta)
			{
				return false;	
			}
			const blocks = convertToRaw(datta.getCurrentContent()).blocks;
			let value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');	
			value=value.trim()	
			const editorJSON = JSON.stringify(convertToRaw(datta.getCurrentContent()));
			
				let usrdata = JSON.parse(editorJSON);
				console.log('usrdataom footer chat==',usrdata);
				const taggedusr = usrdata.blocks[0].text
				const entityMap = usrdata.entityMap
				const objlength = Object.keys(entityMap).length;
				console.log('entityMap==',entityMap);

							
				//console.log('taggedusr==',taggedusr);
				const msgsplit = taggedusr.split(' ');
				//console.log('msgsplit==',msgsplit);
				msgsplit.forEach((element)=>{
					if(element == '@All'){
						
						console.log('editorJSON=====', '@All');
					}
				})

            console.log('value==',value);
			if(value && value!=" ")
			{



				//console.log('in iffffffff=====',value,"data");
				let cAttchedfilename=this.state.chatattchedfilename.toString();
				let cAttchedfilebasename=this.state.chatattchedfilebasename.toString();
				this.socket.emit('SEND_MESSAGE', {
					userdata: userdata,
					project_id: this.props.prjid,
					org_id:this.props.orgid,
					message: editorJSON,
					chatattachament: this.state.chatattachament,
					chatattchedfilename:cAttchedfilename,
					chatattchedfilebasename:cAttchedfilebasename,
					chatid:this.state.editedboardid,
					chatallusers:this.props.allusers
				})
				//this.scrollToBottom();
				const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
				this.setState({ editorState });
				this.setState({ chatattchedfilename:'',chatattchedfilebasename:'',editedboardid:'',iseditedchat:'',removechatvalidation:false });
				this.handleConfirmDialogBoxClose();
			}
		}
	}
	onHourChange = dhour => this.setState({ dhour })
	onMinutChange = dminut => this.setState({ dminut })
	onDraftChangeHandler=event=>{
	    this.setState({
	      'video': event.target.files[0],
	      loaded: 0,
	    })
	}
	componentDidMount() {
		//console.log('componentDidMount');
		GetData('getboxaccesstoken').then((result) => {
				//console.log('result=',result);
				this.setState({
					accestoken:result.access_token
				})
			});  
	}
	onDraftPosterImageChange=event=>{
	    this.setState({
	      'draftposterimage': event.target.files[0],
	      loaded: 0,
	    })
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
 	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["drafttitle"]) {
			formIsValid = false;
			errors["drafttitle"] = "*Please enter Video Title";
		}
		if (!fields["video"]) {
			formIsValid = false;
			errors["video"] = "*Please upload Video";
		}	
		if (!fields["dhour"]) {
			formIsValid = false;
			errors["dhour"] = "*Please select hour";
		}
		if (!fields["dminut"]) {
			formIsValid = false;
			errors["dminut"] = "*Please select minut";
		}	
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	handleConfirmDialogBoxClose(e) {
		this.setState({
			showdialogbox: false,			
		});
		this.setState({
			editorState:EditorState.createEmpty(),
			//editedboardid:'',
			//iseditedchat:false,
		});
		this.postAjax(apiConfig.WEB_SERVICE_URL+'savedraft', function(data){
			var res = data.json();
			console.log('checkresposise==',res,res.statuscode); 
			if(res.statuscode===200)
			{			
				console.log('status code==',res.statuscode); 	     		    
			} 			
		})	
	}
	handleEchatMessageChange = (editorState) => {
		this.setState({
			editorState,
		});
	};
	saveDraftData(e){
		if (this.validateForm())
		{
			this.setState({
				showdialogbox:true,			
			});
		}						 		
	}
	async postAjax(url){
		this.setState({saveBoardDataloader:true},()=>{
			document.getElementById("loadingstatus").innerHTML ="Video is uploading... please wait...";
		});
		 var AuthToken = '0c909954139c00b4b07af0ce71811f3d';
        var AuthSecret = '3KfGrEheWGD7IXa7WolO6l1Dl10ifPY7n2JI9jXTi797trJ+O2jWTEd06EBlaUI6jUreapYboOrGsmo+MKihzwIvdiDaokXxlC6aHOoWggse70lpbVw/XUmJb3u16LLy';
        var ClientId = '4fb799f14677e49b5cc5989cc2c2e21195c6ad95';

        var client = new Vimeo(ClientId, AuthSecret, AuthToken);
       // console.log('filepath',process.cwd());
        let videofile = this.state.video;

        let date_ob_new = Date.now();
	    let filename = videofile.name;
	    var file = filename.split(/\.(?=[^\.]+$)/);
	    var newFileName = file[0] +'_'+ date_ob_new+'.'+file[1];

	    const hour=this.state.dhour.format('HH');
		const minut=this.state.dminut.format('mm');
		const finaltime=hour+':'+minut;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
		let orgid = this.props.orgid;
    	let prjid =this.props.prjid;
    	let title =this.state.drafttitle;
    	let dataobj
    	if(this.props.vimeo_folder_uri){
    			dataobj ={
            'name': title,
            'description': '',
            'folder_uri': this.props.vimeo_folder_uri
          };
        } else {
        	dataobj ={
            'name': title,
            'description': '',
            };
        }
    	let ev=this;
        client.upload(
          videofile,    //For Server
          dataobj,
          //process.cwd()+'/public/draft/'+draftvideoname,   //For Local
          function (uri) {
          	console.log('File upload completed. Your Vimeo URI is:', uri);
          	let vimeo_id = uri.split('/');
            console.log('vimeo_id==',vimeo_id);
            let optvimeoid;
            if(vimeo_id.length>0)
            {
                optvimeoid = vimeo_id[2];
            }
          	let data={
	        	filename:newFileName,
	        	orgid:orgid,
	        	prjid:prjid,
	        	title:title,
	        	drafttime:finaltime,
	        	type:'video',
	        	userid:userdata.user.ID,
	        	draft_vimeo_id:optvimeoid,
	        }
	        PostData('savedraft',data).then((result) => {
				console.log('result=',result);
				document.getElementById("loadingstatus").innerHTML ="Video uploaded successfully";	
				
				setTimeout(function(){
					ev.setState({saveBoardDataloader:false});
					window.location.reload(false);		
				},5000);
			});
        })
	}
	async postAjax_old(url){
		this.setState({saveBoardDataloader:true},()=>{
			document.getElementById("loadingstatus").innerHTML ="Video is uploading... please wait...";
		});
		
		//document.getElementById("loadingstatus").innerHTML ="Video uploading";
		const formData = new FormData();

	    formData.append('parent_id', this.props.project_draft_box_folderid); // Replace with the ID of the folder where you want to upload the file
	    let videofile = this.state.video;
	    let date_ob_new = Date.now();
	    let filename = videofile.name;
	    var file = filename.split(/\.(?=[^\.]+$)/);
	    var newFileName = file[0] +'_'+ date_ob_new+'.'+file[1];
	    console.log('newFileName=',newFileName);
	    formData.append('file', this.state.video,newFileName);
	    //formData.append('name', 'testfile');
	   // formData.append('attributes.description', 'project_12345678_123');
	    
	    const response = await fetch('https://upload.box.com/api/2.0/files/content', {
	      method: 'POST',
	      headers: {
	        Authorization: `Bearer `+this.state.accestoken, // Replace with your own access token
	      },
	      body: formData,
	    });
	    console.log('response=',response);
	    console.log('response=',response.body);
	    if (response.status === 201) {
	        console.log('File uploaded successfully.');
	        //document.getElementById("loadingstatus").innerHTML ="Video uploaded successfully";
	        const hour=this.state.dhour.format('HH');
			const minut=this.state.dminut.format('mm');
			const finaltime=hour+':'+minut;
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			
	        let data={
	        	filename:newFileName,
	        	orgid:this.props.orgid,
	        	prjid:this.props.prjid,
	        	title:this.state.drafttitle,
	        	drafttime:finaltime,
	        	type:'draft',
	        	userid:userdata.user.ID,
	        	draft_box_parent_id:this.props.project_draft_box_folderid,
	        }
	        PostData('savedraft',data).then((result) => {
				console.log('result=',result);
				document.getElementById("loadingstatus").innerHTML ="Video uploaded successfully";	
				let ev=this;
				setTimeout(function(){
					ev.setState({saveBoardDataloader:false});
					window.location.reload(false);		
				},5000);
			});
	     
	    } else {
	      const data = await response.json();
	      console.log(`Error uploading file: ${data.message}`);
	    }
	}
	postAjax_old_old(url){
		const hour=this.state.dhour.format('HH');
			const minut=this.state.dminut.format('mm');
			const finaltime=hour+':'+minut;
			this.setState({saveBoardDataloader:true});
			let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
			const data = new FormData();
			data.append('orgid', this.props.orgid)
			data.append('prjid', this.props.prjid)
			data.append('title', this.state.drafttitle)
			data.append('video',  this.state.video)
			data.append('draftposterimage',  this.state.draftposterimage)
			data.append('drafttime', finaltime)
			data.append('type', 'draft')
			data.append('userid', userdata.user.ID)
			let session_key = userdata.token;
			var ajax = new XMLHttpRequest();
			ajax.upload.addEventListener("progress", this.progressHandler, false);
			ajax.addEventListener("load", this.completeHandler, false);
			ajax.addEventListener("error", this.errorHandler, false);
			ajax.addEventListener("abort", this.abortHandler, false);
			ajax.open("POST", apiConfig.WEB_SERVICE_URL+'savedraft'); 
			ajax.setRequestHeader("session-key", session_key);
			ajax.send(data);
			return ajax;
	}
	progressHandler(event) {
		console.log( "Uploaded " + event.loaded + " bytes of " + event.total);
		var percent = (event.loaded / event.total) * 100;
		console.log( Math.round(percent));
		console.log( Math.round(percent) + "% uploaded... please wait");
		//this.setState({loadingstatus:Math.round(percent) + "% uploaded... please wait"});
		document.getElementById("loadingstatus").innerHTML =Math.round(percent) + "% uploaded... please wait";
	}

	completeHandler(event) {
		console.log( 'completee fhandler111',event.target.responseText);
		var res =event.target.responseText;
		//var res = data.json();
		var res = JSON.parse(res);
		console.log('checkresposise11==',res,res.statuscode); 
		if(res.statuscode===200)
		{	
			//window.location.reload(false);		
			console.log('status code==',res.statuscode);     
			console.log('status res==',res);  
			document.getElementById("loadingstatus").innerHTML ="Video uploaded successfully";	
			setTimeout(function(){
						window.location.reload(false);		
			},5000);   
			  
		} 
	}

	errorHandler(event) {
		console.log("Upload Failed");
	}

	abortHandler(event) {
		console.log("Upload Aborted");
	}
    render() {
		const saveBoardDataloader = this.state.saveBoardDataloader;
    	const isshowing_subscription_hour=this.state.isshowing_subscription_hour;
		const isshowing_org_credit=this.state.isshowing_org_credit;
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
        return (
        	<div className="ProducerSaveDate AddDraftBoard">
				<img className="SD_img_icon" src={'../../../../../profile-image/'+this.props.avatar} />
				{
					saveBoardDataloader ?
						<div className="loaderspinner">
							<Loader
								type="TailSpin"
								color="#D10000"
								height={100}
								width={100}						         
							/>
						   {this.state.loadingstatus}
						   <div id="loadingstatus"></div>
						</div>
					:null 
				} 
				<h3 class="AddTitleDrop">Video</h3>
				<div className="clearfix"></div>
				<div className="succes-msg">{this.state.draftsuccessmessage}</div>
				<div className="row LP_Row">
				
					<div className="col-md-12 LeftLocation">
						<input className="LP_field" type="text" value={this.state.drafttitle} onChange={this.onChange} name="drafttitle" placeholder="Title"/>
						<div className="errorMsg">{this.state.errors.drafttitle}</div>		
					</div>		
     				<div className="col-12 col-sm-6 col-md-3 col-lg-3">
						<div className="dropdown">
							<input type="file" name="video" onChange={this.onDraftChangeHandler} className="form-control videofile" autoFocus /> 
							<div className="errorMsg">{this.state.errors.video} </div>	
							<label>Upload Video</label>
						</div>
					</div>	
					
					<div className="col-12 col-sm-6 col-md-3 col-lg-3 AddTimeBoxes">
						Add time:
						<TimePicker name="dhour" value={this.state.dhour} onChange={this.onHourChange}  showMinute={false} showSecond={false} />
						<div className="errorMsg">{this.state.errors.dhour}</div>	
						<TimePicker name="dminut" minuteStep={15} value={this.state.dminut} onChange={this.onMinutChange} showHour={false} showSecond={false}/>
						<div className="errorMsg">{this.state.errors.dminut}</div>	
					</div>
					<div className="col-12 col-sm-6 col-md-3 col-lg-3">
						<div className="col text-left">
							<button type="button"  onClick={this.saveDraftData.bind(this)}  className="UpdateBtn btn btn-primary">Save</button> 
						</div>
					</div>
				</div>
				<Modal show={this.state.showdialogbox}>
					{/* <Modal.Header class="SelectProducerHead">
						<Modal.Title>Add Time</Modal.Title>
					</Modal.Header> */}
					<Modal.Body className="SelectProducerModel addtasktime">
						<div className="row">
							<div className="col-12">
								<Editor onFocus={() => this.setState({ hasFocus: true })} onBlur={() => this.setState({ hasFocus: false })} editorState={this.state.editorState} wrapperClassName="demo-wrapper" editorClassName="editer-content" onEditorStateChange={(e) => this.handleEchatMessageChange(e)} placeholder='Ask a question or post an update..." with "@mention someone to notify them.'
									toolbar={{
										options: ['inline', 'list', 'emoji', 'link'],
									}}
									mention={{
										separator: ' ',
										trigger: '@',
										suggestions: this.props.associateduserforhastag,
									}}
									hashtag={{}}
								/>									
							</div>
						</div><br/>					
					</Modal.Body>
					<Modal.Footer class="FullWidthModelFoot">
						<Button variant="secondary" onClick={this.handleConfirmDialogBoxClose.bind(this)}>
							X
						</Button>
						<Button className="okbtn" variant="primary" onClick={this.sendMessage.bind(this)}>
							Ok
						</Button>
						<Button variant="primary" onClick={this.handleConfirmDialogBoxClose.bind(this)}>
							Cancel
						</Button>
					</Modal.Footer>
				</Modal>
			</div>		
		);
    }
}
export default Video;