import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {PostData} from '../../services/PostData';
import { EditorState,ContentState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Loader from 'react-loader-spinner'
import { Link  } from 'react-router-dom';
class CreateFolder extends Component {
	state = {
	    duedate: new Date()
	};
    constructor(props){
		console.log('this.props.match.params.folderid==',props.match.params.folderid);
		super(props);
		this.state = {
			fields: {},
			errors: {},
			folder_des: '',
			folder_name:'',
			boxfolder_name:props.match.params.folderid,			
			loading: false,
			folderaddsuccs:''
		};
		this.submit = this.submit.bind(this);
		this.onChange = this.onChange.bind(this);		
	};	
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["folder_name"]) {
			formIsValid = false;
			errors["folder_name"] = "*Please enter your Project Name";
		}
		// if (!fields["boxfolder_name"]) {
		// 	formIsValid = false;
		// 	errors["boxfolder_name"] = "*Please enter your Box Folder ID";
		// }
		this.setState({
			errors: errors
		});
		return formIsValid;
    }
    submit() {	
		if (this.validateForm())
		{
			let data={
				foldername:this.state.folder_name,
				parentid:this.state.boxfolder_name,
				folderdesc:this.state.folder_des,
			}
			this.setState({
				loading:true,				
			});
			PostData('createfolder',data).then((result) => {				
				if(result.statusCode===200)
				{
					this.setState({
						folderaddsuccs:"Folder added Successfully",
						loading:false,
						folder_name:'',	
						folder_des:''		
					});
					setTimeout(() => {
						this.setState({ folderaddsuccs: '' });						
					}, 3000);
				}				
			});
		}
 	}
	render() {
    	const {   editorState   } = this.state; 	
    	const styles = {
		  editor: {
			    border: '1px solid gray',
			    minHeight: '6em'
			  }
			};
		const ExampleCustomInput = ({ value, onClick }) => (
		    <button className="example-custom-input" onClick={onClick}>
		    	<img src={require('../../assets/images/due-date.png')}/> {value}
		    </button>
		);
		let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
		let usertype='';
        if (userdata.role && userdata.role!='administrator')
        usertype='/subs' 
        return (
        	<div className="container-fluid AddProjectPage MobPaddHide"  ref="selfdiv">
				<div className="panel panel-primary">
					{
						this.state.loading ?
							<div className="loaderspinner">
								<Loader
									type="TailSpin"
									color="#D10000"
									height={100}
									width={100}						         
								/>
							</div>
						:null 
					} 
					<div className="WrapBarTop panel-heading">
						<div className="panel-heading">
							<div className="row">
								<div className="col-6">
									<h4 class="PageTopTitle">Add Folder Name</h4>
								</div>							
								<div  className="col-6 text-right"> 
								<Link to={`${usertype}/${this.props.match.params.orgid}/assets`}>Back To Assets</Link> 
								</div>
							</div>
						</div>
					</div>
	        		<div className="PrimaryWrap container-fluid">
						<div className="row FormDesign">
							<div className="col-9 col-sm-9 col-md-9 col-lg-9">
								<div className="row">
									<div className="col-12">
										<div className="succes-msg">{this.state.folderaddsuccs}</div>
										<label for="projectname">Folder Name</label>
										<input type="text" name="folder_name" placeholder="" onChange={this.onChange} className="PB_Title form-control" value={this.state.folder_name} autoFocus />
										<div className="errorMsg">{this.state.errors.folder_name}</div>
									</div>            	
								</div>																
								<div className="CreateProjectChatBox">
									<label for="projectname">Description</label>
									<input type="text" name="folder_des" placeholder="" onChange={this.onChange} className="PB_Title form-control" value={this.state.folder_des} autoFocus />	            
								</div>															
								<button type="submit" onClick={this.submit} className="CPRadioBtn UpdateBtn btn btn-primary">Create</button> 
							</div>							                 
						</div>
		        	</div>	
		        </div>					
			</div>	
		);
    }
}
export default CreateFolder;
