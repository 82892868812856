import React, {useState,useEffect} from 'react';
import {
	CardNumberElement,
	CardExpiryElement,
	CardCVCElement,
	injectStripe
  } from 'react-stripe-elements'
  import axios from 'axios'
  //import './CheckoutForm.scss'
  import "../../../assets/css/CheckoutForm.css";
  import {PostData,GetData} from '../../../services/PostData';
  import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import dateFormat from 'dateformat';
  const CheckoutReplyTravelAuthorizationForm = ({ onChildClick, orgname, freshbook_profile_id, fbClient, accountid, tokenData, handleReplyVideographyClose, loadProjectBoard, userid, orgid, prjid, setReplyTravelAuthorizationShow,replyTravelAuthorizationdata, stripe, history }) => {

  	const [state, setState] = React.useState({
		chargetype:'Send Invoice',
		savevideographsucsmsg:'',
		tasktimeerrormsg:'',
		accommodation:'',
		tasktimeerrormsg:'',
		amount:''
	})
  let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
	console.log('userdataforcheck==',userdataforcheck);
	let username = userdataforcheck.first_name;
	const [replyTAuthorizationShow, setReplyTAuthorizationShow] = useState(setReplyTravelAuthorizationShow);
	const [receiptUrl, setReceiptUrl] = useState('')
    //const [name,setName] =useState('');
	//const [email,setEmail] =useState('');
	//function handleClick(event,cardid,defaultcardid) {
		//console.log('event,cardid,defaultcardid==',event,cardid,defaultcardid);
	   // onChildClick(cardid,defaultcardid,custid,userId); // pass any argument to the callback
	// }
	
	 useEffect(() => {
    // Update the document title using the browser API
    setReplyTAuthorizationShow(setReplyTravelAuthorizationShow);
  });
  const validateSaveReplyTravelAuthorizationForm = async () => {
	//validateSaveReplyTravelAuthorizationForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["videographer"]) {
			formIsValid = false;
			errors["videographer"] = "*Please add Videographer Name";
		}
		  
		if (typeof fields["vg_emailaddress"] !== "undefined") {
			//regular expression for email validation
			var email = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; 
			if (!email.test(fields["vg_emailaddress"])) {
				formIsValid = false;
				errors["vg_emailaddress"] = "*Please enter valid Email Address.";
			}
		} 
		this.setState({
			errors: errors
		});
		return formIsValid;
	}


	const handlePerDiemChange = (e) => {
		setState({
			...state,
			perdiem: e.target.value
		});
	}
	const onRadioChange = (e) => {
		setState({
			...state,
			[e.target.name]:e.target.value
		});
	}
	const onChange = (e) => {
		setState({
			...state,
			[e.target.name]:e.target.value
		});
	}

	const onRadioChargeTypeChange = (e) => {
		setState({
			...state,
			[e.target.name]:e.target.value
		});
	}
	const handleReplyVideographyToClose = (e) => {
		console.log('handleReplyVideographyClose in checkout=='); 
		handleReplyVideographyClose();
	}
const saveReplyTravelAuthorizationForm = async (e) => {
	e.preventDefault()
  
	  //console.log('issave',issave);
  	  //	return false;
  	let data
  	if(state.chargetype=='Pay Now') {
		  const { token } = await stripe.createToken();
		  if(token) {
		    data = {
					'orgid': orgid,
					'projectid': prjid,
					'source': token.id,
					'location': replyTravelAuthorizationdata.location,
					'purpose': replyTravelAuthorizationdata.purpose,
					'arrivalDate': replyTravelAuthorizationdata.arrive,
					'departureDate': replyTravelAuthorizationdata.depart,
					'notes': replyTravelAuthorizationdata.comment,
					'onsitecontact':replyTravelAuthorizationdata.onsitecontact,
					'onsitemobilenumber':replyTravelAuthorizationdata.mobilenumber,
					'onsiteemailaddress':replyTravelAuthorizationdata.emailaddress,
					'travelrequired':replyTravelAuthorizationdata.travelrequired,
					'hotelnight':replyTravelAuthorizationdata.hotelnight,
					'primarytransportation':replyTravelAuthorizationdata.primarytransportation,
					'schedarrivalDate':replyTravelAuthorizationdata.schedarrivalDate,
					'scheddepartureDate':replyTravelAuthorizationdata.scheddepartureDate,

					'videographername':replyTravelAuthorizationdata.videographername,
					'vg_emailaddress':replyTravelAuthorizationdata.vg_emailaddress,

					'perdiem': state.perdiem,
					'chargetype': state.chargetype,
					'userid':userid,
					'customerid':replyTravelAuthorizationdata.userid,
					'hotelname':state.hotelname,
					'dailybudget':state.dailybudget,
					'accommodation':state.accommodation,
					'type':'Reply Travel AuthorizationForm',
					'flightexpense':replyTravelAuthorizationdata.flightexpense,
					'driveexpense':replyTravelAuthorizationdata.driveexpense,
					'travelexpenses':replyTravelAuthorizationdata.travelexpenses,
					'trainexpense':replyTravelAuthorizationdata.trainexpense,
				}
			}
		} else {
			data = {
				'orgid': orgid,
				'projectid': prjid,
				'location': replyTravelAuthorizationdata.location,
				'purpose': replyTravelAuthorizationdata.purpose,
				'arrivalDate': replyTravelAuthorizationdata.arrive,
				'departureDate': replyTravelAuthorizationdata.depart,
				'notes': replyTravelAuthorizationdata.comment,
				'onsitecontact':replyTravelAuthorizationdata.onsitecontact,
				'onsitemobilenumber':replyTravelAuthorizationdata.mobilenumber,
				'onsiteemailaddress':replyTravelAuthorizationdata.emailaddress,
				'travelrequired':replyTravelAuthorizationdata.travelrequired,
				'hotelnight':replyTravelAuthorizationdata.hotelnight,
				'primarytransportation':replyTravelAuthorizationdata.primarytransportation,
				'schedarrivalDate':replyTravelAuthorizationdata.schedarrivalDate,
				'scheddepartureDate':replyTravelAuthorizationdata.scheddepartureDate,

				'videographername':replyTravelAuthorizationdata.videographername,
				'vg_emailaddress':replyTravelAuthorizationdata.vg_emailaddress,

				'perdiem': state.perdiem,
				'chargetype': state.chargetype,
				'userid':userid,
				'customerid':replyTravelAuthorizationdata.userid,
				'hotelname':state.hotelname,
				'dailybudget':state.dailybudget,
				'accommodation':state.accommodation,
				'type':'Reply Travel AuthorizationForm',
				'flightexpense':replyTravelAuthorizationdata.flightexpense,
				'driveexpense':replyTravelAuthorizationdata.driveexpense,
				'travelexpenses':replyTravelAuthorizationdata.travelexpenses,
				'trainexpense':replyTravelAuthorizationdata.trainexpense,
			}

		let expenses1;
	    let flyexpenses1 =0;
	    let trainexpenses1 =0;
	    let driveexpenses1 =0;
	    let travelexpenses1 =0;
	    
	    if(replyTravelAuthorizationdata.flightexpense) {
	       flyexpenses1= parseInt(replyTravelAuthorizationdata.flightexpense);
	    } 
	    if(replyTravelAuthorizationdata.trainexpense) {
	        trainexpenses1= parseInt(replyTravelAuthorizationdata.trainexpense);
	    }
	    if(replyTravelAuthorizationdata.driveexpense) {
	        driveexpenses1= parseInt(replyTravelAuthorizationdata.driveexpense);
	    }
	    if(replyTravelAuthorizationdata.travelexpenses) {
	        travelexpenses1= parseInt(replyTravelAuthorizationdata.travelexpenses);
	    }
	    console.log('flyexpenses1=',flyexpenses1);
	    console.log('trainexpenses1=',trainexpenses1);
	    console.log('driveexpenses1=',driveexpenses1);
	    console.log('travelexpenses1=',travelexpenses1);
	    expenses1 =  flyexpenses1+trainexpenses1+driveexpenses1+travelexpenses1;
	   let perdiemQuantiy =  parseInt(state.perdiem);
		//const clientId = await createClient();
		const clientId= freshbook_profile_id;
		console.log('clientId=',clientId);
		let invoice = await createInvoice(clientId,expenses1,replyTravelAuthorizationdata.location,perdiemQuantiy,replyTravelAuthorizationdata.hotelnight,state.dailybudget,state.accommodation)
		let invoiceid = invoice.id;
		data.amount = invoice.amount.amount
		invoice = await markInvoiceSent(invoice)
		let payurl = 'https://api.freshbooks.com/payments/account/'+accountid+'/invoice/'+invoiceid+'/payment_options';
		let payment_option = {
		    "has_credit_card": true,
		    "gateway_name": "fbpay"
		}
		const response = await fetch(payurl, {
	      method: 'POST',
	      headers: {
	        Authorization: `Bearer `+tokenData.accessToken,
	        //Api-Version: 'alpha',
	        'Content-Type': 'application/json' // Replace with your own access token
	      },
	      body: JSON.stringify(payment_option),
	    });
		console.log('response=',response);
	}


console.log('data=',data);
	PostData('replytravelauthorizationform', data).then((result) => {
		console.log('result=',result);
		if (result.statuscode === 200) {		
			setState({
				...state,
				savevideographsucsmsg:'Travel Authorization Reply Added Succesfully',
				hotelname:'',
				dailybudget:'',
			});		
			setTimeout(() => {
				setState({
				...state,
				savevideographsucsmsg:'',
			});	
				handleReplyVideographyClose();
			  loadProjectBoard();
			}, 3000);
		}

	});
		
}
async function markInvoiceSent(invoice) {
	console.log('Marking invoice as sent...')
	invoice.actionMarkAsSent = true
	invoice.actionEmail = true
	try {
		return await fbClient.invoices.update(accountid, invoice.id, invoice)
	} catch (e) {
		console.log(e)
	}
}
async function createInvoice(clientId,expenses,location,perdiemQuantiy,numofnight,dailybudget,accommodation) {
	//let tax = (expenses*3.5)/100;
	//tax = tax.toFixed(1)
	let numofhtnight = parseInt(numofnight);
	console.log('expenses=',expenses);
	//console.log('tax=',tax);
	expenses = expenses.toString();
	//tax = tax.toString();
	const line1 = {
		name: 'Travel Expense',
		description: location,
		qty: 1,
		taxAmount1: '3.5',
		taxName1: 'Service Fee',
		unitCost: {
			amount: expenses,
			code: 'USD',
		},
	}
	let line2;
	let lines = [];
	if(numofhtnight>0){
		let totperdiem;
		if(accommodation=='Yes'){
			totperdiem= perdiemQuantiy;
		} else {
			totperdiem= parseInt(dailybudget) + parseInt(perdiemQuantiy);
		}
		line2={
			name: 'Per Diem',
			qty: numofhtnight,
			taxAmount1: '3.5',
			taxName1: 'Service Fee',
			unitCost: {
				amount: totperdiem.toString(),
				code: 'USD',
			},
		}
		lines=[line1,line2]
	} else {
		lines=[line1]
	}
	const invoiceData = {
		customerId: clientId,
		createDate: new Date(),
		lines: lines,
	}

	try {
		const invoice = await fbClient.invoices.create(invoiceData, accountid)

		console.log(`Created invoice ${invoice.data.id}`)
		console.log(`Invoice total is ${invoice.data.amount.amount} ${invoice.data.amount.code}`)
		return invoice.data
	} catch (e) {
		console.log(e)
	}
}
async function createClient() {
	console.log('Creating client...')

	try {
		const clientData = { organization: 'NodeJS SDK Test Client1' }
		const client = await fbClient.clients.create(clientData, accountid)

		console.log(`Created client ${client.data.id}`)
		return client.data.id
	} catch (e) {
		console.log(e)
	}
}

function updateFBRefreshToken(tokenData){
	const refreshtoken = tokenData.refreshToken;
	PostData('updatefbrefreshtoken', {refresh_token:refreshtoken}).then((result) => {
		console.log('refresh token result=',result);
		if (result.statuscode === 200) {				
			console.log('refresh token updated');				
		}
	});
}

async function getFBRefreshToken(){
	let resultdata;
	await GetData('getfbrefreshtoken').then((result) => {
		console.log('refresh token result=',result);
		if (result.statuscode === 200) {				
			console.log('refresh token updated');
			resultdata= result;		
		}
	});
	return resultdata
}
	if (receiptUrl) {
		return (
		  <div className="success">
			<h2>Payment Successful!</h2>
			
		  </div>
		)
	  }
	  
	  console.log('setReplyTravelAuthorizationShow=',setReplyTravelAuthorizationShow);
	  return (
		
			  <Modal show={replyTAuthorizationShow}>		
				<Modal.Header class="SelectProducerHead">
					<Modal.Title> Travel Authorization Reply Form</Modal.Title>
				</Modal.Header>
				<Modal.Body className="SelectProducerModel addtasktime">
					<div className="successmsg">{state.savevideographsucsmsg}</div>
					<div className="errormsg">{state.tasktimeerrormsg}</div>
					
					<div className="row CreateProRadioBox">
						<div className="col-sm-12">
							<>Hello {username},</><br/><br/>

							<>{replyTravelAuthorizationdata.videographername} is available for your upcoming shoot in {replyTravelAuthorizationdata.location}, planning to arrive {dateFormat(replyTravelAuthorizationdata.schedarrivalDate, "mmmm dS, yyyy")} and departing {dateFormat(replyTravelAuthorizationdata.scheddepartureDate, "mmmm dS, yyyy")}.</><br/><br/>
							<>Association Studios</>
						</div>
						
					</div>
					
					<div className="row CreateProRadioBox">
						<div className="col-sm-12">
							<label for="projectname" class="ParentLab" >Please let us know if you will be providing a hotel room</label>
						</div>
						<div className="col-sm-6">					
							<label className="radio-inline">
								<input type="radio" onChange={onRadioChange} value="Yes" name="accommodation"/>Yes
							</label>
						</div>
						<div className="col-sm-6">
							<label className="radio-inline">
								<input type="radio" onChange={onRadioChange}  value="No" name="accommodation"/>No
							</label>	
						</div>
					</div>
					<div className="row CreateProRadioBox">
					{
						state.accommodation=="Yes" &&
						(
							<div className="col-sm-6">
								<label for="projectname" class="ParentLab" >Hotel Name</label>
								<input type="text" name="hotelname" onChange={onChange} className="PB_Title form-control" autoFocus />
							</div>
						)
					}
					{
						state.accommodation=="No" &&
						(
							<div className="col-sm-6 forprefixrowfull-width">
								<label for="projectname" class="ParentLab" >Daily Hotel Budget</label>
								<div class="input-group prefix">                           
							        <span class="input-group-addon">$</span>
							        <input type="text" name="dailybudget" onChange={onChange} className="PB_Title form-control" autoFocus />
							    </div>
							</div>
							
						)
					}
						<div className="col-sm-6">
							<label for="projectname" class="ParentLab" >Per Diem </label>
							<select name="hotelnight" className="form-control" value={state.perdiem} onChange={handlePerDiemChange} >
								<option value=" ">Select Per Diem</option>
								<option value="0">$0</option>
								<option value="25">$25</option>
								<option value="50">$50</option>
								<option value="75">$75</option>
								<option value="100">$100</option>
							</select>
						</div>
					</div>
					{/*<div className="row CreateProRadioBox">
						<div className="col-sm-12">
							<label for="projectname" class="ParentLab" > Charge Type</label>
						</div>
						<div className="col-sm-6">					
							<label className="radio-inline">
								<input type="radio" onChange={onRadioChargeTypeChange} value="Charge Card on File" name="chargetype"/>Charge Card on File
							</label>
						</div>
						<div className="col-sm-6">
							<label className="radio-inline">
								<input type="radio" onChange={onRadioChargeTypeChange}  value="Send Invoice" name="chargetype"/>Send Invoice
							</label>	
						</div>
						<div className="col-sm-6">
							<label className="radio-inline">
								<input type="radio" onChange={onRadioChargeTypeChange}  value="Pay Now" name="chargetype"/>Pay Now
							</label>	
						</div>
					</div>
				*/}
					{
						state.chargetype=="Pay Now" &&
						(
							<div className="row CreateProRadioBox">
								<div className="col-4 col-sm-4 col-md-4 col-lg-4">
									<label for="projectname">Card details<span>*</span></label>
									<CardNumberElement />
								</div>	
								<div className="col-4 col-sm-4 col-md-4 col-lg-4">
									<label for="projectname">Expiration date<span>*</span></label>
									<CardExpiryElement />
								</div>	
								<div className="col-4 col-sm-4 col-md-4 col-lg-4">
									<label for="projectname">CVC<span>*</span></label>
									<CardCVCElement />
								</div>	
							</div>
						)
					}
					
			<div className="row CreateProRadioBox">
				<div className="col-sm-12">
					<b>The estimated costs for travel is [travel expense + per diem] plus a 3.5% service fee. If you approve this expense please click the submit button below and we will email your invoice. Thank you!</b>
				</div>
			</div>	
			
			</Modal.Body>
				<Modal.Footer class="FullWidthModelFoot">
					<Button variant="secondary" onClick={handleReplyVideographyToClose}>
						X
					</Button>
					<Button variant="primary" onClick={(e) => {saveReplyTravelAuthorizationForm(e)}} >
						Submit
					</Button>
				</Modal.Footer>
			</Modal> 
	  )
	}

	export default injectStripe(CheckoutReplyTravelAuthorizationForm)