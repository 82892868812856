import React, { Component } from "react";
import axios from "axios";
import {PostData,GetData} from '../../services/PostData';
import {apiConfig} from "../../config/api"
import Loader from 'react-loader-spinner'
class EditTeam extends Component {
    constructor(props){
		super(props);
		this.state = {
			fields: {},
			errors: {},
			userdata:'',
			loading:false,
			usererrormsg:''
		};
		this.submit = this.submit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
	}
	onChangeHandler=event=>{
	    this.setState({
	      'profileimg': event.target.files[0],
	      loaded: 0,
	    })
	}
	onChange(e){
		this.setState({[e.target.name]:e.target.value});
	}
	submit(e) {	
		e.preventDefault();
		if (this.validateForm())
		{
			this.setState({ 
				loading: true,           
			});
			const data = new FormData();
			data.append('id', this.props.match.params.id)
   			data.append('firstname', this.state.f_name)
   			data.append('lastname', this.state.l_name)
   			//data.append('email', this.state.email)
   			data.append('phone', this.state.phone)
   			data.append('username', this.state.username)
   			data.append('hourlyrate', this.state.hourlyrate)
   			data.append('password', this.state.password)
   			data.append('jobtitle', this.state.jobtitle)
   			data.append('linkedin', this.state.linkedin)
   			data.append('twitter', this.state.twitter)
   			data.append('profilepic', this.state.profileimg)
   			data.append('notes', this.state.notes)
		    let session_key = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.token;
		    fetch(apiConfig.WEB_SERVICE_URL+'updateuserdetail', {
				method: 'POST',
				body: data,
				headers: {
					"session-key": session_key,
				}
		    })
		    .then((response) => response.json())
		    .then((res) => {
				if(res.statusCode===200)
				{
					this.setState({
						usersucsmsg:res.message,
						loading:false,
					});
					setTimeout(() => this.setState({ usersucsmsg: '' }), 3000);
				}
				else if(res.statusCode===401)
				{
					this.setState({
						usererrormsg:res.message,
						loading:false,
					});
				}
		    });
		}
 	}
 	validateForm() {
		let fields = this.state;
		let errors = {};
		let formIsValid = true;
		if (!fields["f_name"]) {
			formIsValid = false;
			errors["f_name"] = "*Please enter your First Name";
		}
		if (!fields["l_name"]) {
			formIsValid = false;
			errors["l_name"] = "*Please enter your Last Name";
		}
		if (!fields["email"]) {
			formIsValid = false;
			errors["email"] = "*Please enter your Email Address";
		}
		//if (!fields["org_name"]) {
		//	formIsValid = false;
			//errors["org_name"] = "*Please enter your Organization Name";
		//}
		if (!fields["username"]) {
			formIsValid = false;
			errors["username"] = "*Please enter User Name";
		}
		// if (!fields["password"]) {
		// 	formIsValid = false;
		// 	errors["password"] = "*Please enter Password";
		// }
		if (typeof fields["phone"] !== "undefined") {
			//regular expression for email validation
			//var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
			//if (!phoneNum.test(fields["phone"])) {
				//formIsValid = false;
				//errors["phone"] = "*Please enter valid Phone Number.";
			//}
		}  
		if (typeof fields["email"] !== "undefined") {
			//regular expression for email validation
			var email = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; 
			if (!email.test(fields["email"])) {
				formIsValid = false;
				errors["email"] = "*Please enter valid Email Address.";
			}
		}  
		this.setState({
			errors: errors
		});
		return formIsValid;
	}
	componentDidMount() {
		this.setState({ 
            loading: true,           
        });
		let data = {
			'userid': this.props.userid,			
		}
		GetData('user/'+this.props.match.params.id).then((result) => {     
		  console.log('check result in edit page===',result);        
			this.setState({ 
			  f_name: result.data.first_name,
			  l_name: result.data.last_name,
			  email: result.data.email,
			  getpassword: result.data.password,
			 // org_name: result.data.organization,
			  jobtitle: result.data.job_title,
			  twitter: result.data.twitter,
			  linkedin: result.data.linkedin,
			  phone: result.data.phone,
			  notes: result.data.notes,
			  username: result.data.username,
			  hourlyrate: result.data.hourlyrate,
			  loading: false,	
			  password:'',		 
			});

		});
	}
    render() {
    	const isshowing_subscription_hour=this.state.isshowing_subscription_hour;
		const isshowing_org_credit=this.state.isshowing_org_credit;
		let userdata=this.state.userdata;
        return (
        	<div className="container-fluid create-organization">
				{
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	}
				<div class="wrapper-box">
					<div className="panel panel-primary">
						<div className="panel-heading">
                            <div className="row">
                                <div className="col-md-7 col-lg-7">
                                    <h4 class="PageBarTitle">Edit Team</h4>
                                </div>
                                <div className="col-md-5 col-lg-5">
                                </div>
                            </div>
                        </div>
						
						<div className="panel-body">
							<form enctype="multipart/form-data">
								<div className="row " id="Body">
									<div className="col-md-12 FormDesign">
										<div className="row">
											<div className="col-6">
												<label htmlFor="f_name">First Name <span>*</span></label>			
												<input type="text" value={this.state.f_name} name="f_name" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.f_name}</div>			
											</div>
											<div className="col-6">
												<label htmlFor="l_name">Last Name <span>*</span></label>			
												<input type="text" value={this.state.l_name} name="l_name" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.l_name}</div>			
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="email">Email <span>*</span></label>				
												<input readOnly value={this.state.email} type="email" name="email" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.email}</div>			
											</div>
											<div className="col-6">
												<label htmlFor="phone">Phone</label>
												<input type="text" value={this.state.phone} name="phone" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.phone}</div>			
											</div>
										</div>
										<div className="row">
											<div className="col-6">
												<label htmlFor="username">Username <span>*</span></label>			
												<input type="text" value={this.state.username} name="username" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.username}</div>		
											</div>
											<div className="col-6">
												<label htmlFor="password">Password </label>			
												<input type="password" value={this.state.password} placeholder="********" name="password" onChange={this.onChange} className="form-control" autoFocus />
												<div className="errorMsg">{this.state.errors.password}</div>		
											</div>
										</div>
										<div className="row">
											
											<div className="col-6">
												<label htmlFor="jobtitle">Job Title</label>				
												<input type="text" value={this.state.jobtitle} name="jobtitle" onChange={this.onChange} className="form-control" autoFocus />
											</div>
											<div className="col-6">
												<label htmlFor="twitter">Twitter</label>
												<input type="text" value={this.state.twitter} name="twitter" onChange={this.onChange} className="form-control" autoFocus />
											</div>
										</div>
										<div className="row">
											
											<div className="col-6">
												<label htmlFor="linkedin">LinkedIn</label>
												<input type="text" value={this.state.linkedin} name="linkedin" onChange={this.onChange} className="form-control" autoFocus />				
											</div>
											<div className="col-6">
												<label htmlFor="logo">Upload Profile Pic</label>
												<input type="file" name="profileimg" onChange={this.onChangeHandler} className="form-control" autoFocus />
											</div>
										</div>
										<div className="row">
											
											<div className="col-6">
												<label htmlFor="notes">Notes</label>
												<input type="text" value={this.state.notes} name="notes" onChange={this.onChange} className="form-control" autoFocus />
											</div>
											<div className="col-6">
												<label htmlFor="linkedin">Hourly  Rate</label>
												<input type="number" value={this.state.hourlyrate} name="hourlyrate" onChange={this.onChange} className="form-control" autoFocus />				
											</div>
										</div>		
										<button type="submit" onClick={this.submit} className="UpdateBtn btn btn-primary">Update</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
    }
}
export default EditTeam;