import React, { Component } from "react";
import {PostData,GetData} from '../../../services/PostData';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Client } from '@freshbooks/api';
import Pagination from "react-js-pagination";
import { SearchQueryBuilder } from '@freshbooks/api/dist/models/builders/SearchQueryBuilder'
import { PaginationQueryBuilder } from '@freshbooks/api/dist/models/builders/PaginationQueryBuilder';
import dateFormat from 'dateformat';

class FreshbookInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            itemPerPage:50,
            successmsg:'',
            errormsg:'',
            voiceovertext:'',
            selectedvoice:'',
            fields: {},
            errors: {},
            invoiceList:[]
        };
    }
    componentDidMount() {     
        console.log('projectboarddata=',this.props.projectboarddata);
        this.setState({
            invoiceList: this.props.projectboarddata,
            originalinvoiceList: this.props.projectboarddata, 
        });
    }

    render() {
        let indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        let indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        let listItems
        if(this.state.invoiceList.length>0) {
        let renderedinvoiceList = this.state.invoiceList.slice(indexOfFirstTodo, indexOfLastTodo);
            listItems = renderedinvoiceList.map((item, index) => {
                const userdata = item.userdata;
                const filtereduserdata = userdata.filter((udata) => udata.ID == item.userid);
                const fname = filtereduserdata[0].first_name;
                const lname = filtereduserdata[0].last_name;
                const fullname = fname+" "+lname;
                console.log('filtereduserdata===',filtereduserdata);
                const expense_creare_date = dateFormat(item.create_date, "mm-dd-yyyy");
                let receiptfilelink = '';
                let userdataforcheck = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user;
                if (item.receiptfile && userdataforcheck.capabilities && userdataforcheck.capabilities.administrator) {
                    receiptfilelink="../../../../receiptdata/"+item.receiptfile;
                }
                else if(item.receiptfile) {
                    receiptfilelink="../../../../../receiptdata/"+item.receiptfile;                 
                }
                let expensedescription;
                if(item.type=='Reply Travel AuthorizationForm'){
                    expensedescription = 'Travel Authorization';
                } else {
                    expensedescription = item.description;
                }
                 return <tr key={index}>
                    <td class="tbl_date">                    
                        {expense_creare_date}
                    </td>
                    <td class="tbl_notes">                    
                        {expensedescription}
                    </td>
                    <td class="tbl_amount">                    
                        {item.amount}
                    </td>
                    <td class="tbl_amount">                    
                        {fullname}
                    </td>
                    <td class="tbl_amount">
                    {(item.receiptfile) &&
                        <a className="forcusror" target="_blank" href={receiptfilelink} download > Download
                        </a>
                    }
                    </td>
                </tr>
            });
        }
        return (
            <div className="container-fluid ProjectList"  ref="selfdiv">
                <div className="row">
                    <div className="col-12">
                       <h3>Expenses</h3>
                       <div className="msg">
                            <div className="errorsmsg">{this.state.errormsg}</div>
                            <div className="successmsg">{this.state.successmsg}</div>
                       </div>
                       <div className="row">
                            <div className="panel-body-expense">
                                <table className="table table-striped ListTable">
                                    <thead>
                                        <tr>
                                            <th class="tbl_org_name"><b>Date</b></th>
                                            <th class="tbl_org_city"><b>Description</b></th>
                                            <th class="tbl_org_street"><b>Amount</b></th>
                                            <th class="tbl_org_street"><b>Added By</b></th>
                                            <th class="tbl_org_street"><b>Receipt</b></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {listItems}
                                    </tbody>
                                </table>
                            </div>
                        </div><br/>
                    </div>
                </div> <br/><br/>
            </div>
        );
    }
}
export default FreshbookInvoice;
