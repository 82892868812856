import React, { Component } from "react";
import { Link  } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Loader from 'react-loader-spinner'
import AddTeam from './AddTeam'
import {GetData,PostData} from '../../services/PostData';
class ListTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
			itemPerPage:10,
            teamList: [],
            originalTeamList: [],
            loading: false,
        };
       this.onChange = this.onChange.bind(this);
       this.getProfileData=this.getProfileData.bind(this);
    }
    componentDidMount() {
        this.getProfileData();
        // this.setState({ 
        //     loading: true,           
        // });
        // GetData('orgprofile/'+this.props.orgid).then((result) => {             
        //     this.setState({ 
        //          loading: false,
        //          teamList: result.data.org_users,
        //          originalTeamList:result.data.org_users,
        //      });
        // });
        // this.setState({ 
        //     teamList: this.props.teamlist,
        //     originalTeamList:this.props.orgiteamlist,
        // });
   }
   getProfileData(){
        this.setState({ 
            loading: true,           
        });
        GetData('orgprofile/'+this.props.orgid).then((result) => {             
            this.setState({ 
                loading: false,
                teamList: result.data.org_users,
                originalTeamList:result.data.org_users,
            });
        });
   }
    handlePageChange(pageNumber) {
        this.setState({ activePage: pageNumber });        
    }
    onChange(e){
		this.setState({[e.target.name]:e.target.value});
    }
    changeView(displaytype,userid){
        this.props.view(displaytype,userid);
    }
    deleteUser(userid) {
        let data={userid:userid}
        PostData('deleteuser/',data).then((result) => {       
            if(result.statusCode===200)
            {
                this.getProfileData();
            }
        });
    }
    render() {       
		var indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
        var indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
        if( this.state.teamList.length>0 )
        {
            let userdata = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data.user
            var teamList = this.state.teamList.slice(indexOfFirstTodo, indexOfLastTodo);
            var listItems = teamList.map((item, index) => {
                if(item.status=='inactive'){
                    return false
                }
                let imageurl='';
                let localimageurl='';
                var imagepath = item.profile_image;
                let noimage =false; 
                let noimagename=''; 
                if(imagepath)
                {
                    var res = imagepath.charAt(0)
                    if(res==='"')
                    {
                        var imagepath = imagepath.substring(1);
                        imagepath = imagepath.slice(0, -1) //'abcde'
                    }  
                    console.log('imagepath==',imagepath);
                              
                    if(item.role)
                    {
                        if (item.role && item.role==='administrator')
                        {
                            localimageurl='../../../profile-image/'+imagepath;
                        }
                        else
                        {
                            localimageurl='../../../../../profile-image/'+imagepath;
                        }
                        var imgname = imagepath.split(".");
                        imgname = imgname[0];
                        imgname = imgname.split("_");
                        console.log('imgname.length',imgname.length,imgname);
                        if(imgname.length>1 && imgname[1]==='noimage')
                        {
                            noimage=true;
                            if(item.first_name)
                            var fn=item.first_name
                            if(item.last_name)
                            var ln=item.last_name
                            noimagename=fn.charAt(0)+ln.charAt(0);
                            noimagename = noimagename.toUpperCase();
                            console.log('imgname==',imgname);
                        }
                    }
                    else
                    {
                    	console.log('imgname.length in elseeeeeeee',item.profile_image);
                        if(item.profile_image)
                                imageurl=localimageurl='../../../../profile-image/'+imagepath;
                        else
                            imageurl='';
                    }
                }
                let jobtitle='-';
                if(item.job_title)
                    jobtitle=item.job_title;
                let phone=''
                if(item.phone)
                    phone=item.phone
                let fname=''
                if(item.first_name)
                fname=item.first_name
                let lname=''
                if(item.last_name)
                lname=item.last_name
                return <div key={index} className="col-12 col-sm-12 col-md-6 col-lg-4 AssetsListCol MobPaddHide">                       
                        {(() => {
                                return(
                                    <div class="AssetsListColIn user-list">  
                                        <div class="media">
                                            {
                                             (noimage)?<div className="noimage">{noimagename}</div>:   
                                             (localimageurl)?<img  className="mr-3" src={localimageurl}/>:
                                            (imageurl)?<img  className="mr-3" src={imageurl}/>:
                                            <img  className="mr-3" src={require('../../assets/images/admin.jpg')}/>
                                            
                                            }
                                            <div class="media-body">
                                                <h4>{fname} {lname}</h4>
                                                <h5 class="mt-0"><strong>Job Title:</strong> <span>{jobtitle}</span></h5>
                                                <h5 class="mt-0"><strong>Email:</strong> <span>{item.email}</span></h5>
                                            </div>                                            
                                        </div>   
                                        <h5 class="mt-0"><strong>Phone: </strong> <span>{phone}</span></h5>   
                                        <ul className="edtdl_links">
                                            <li>
                                                <a href="#"  onClick={this.changeView.bind(this,'editteam',item.ID)} >Edit</a>
                                            </li>
                                            <li>                                                
                                                <a  className="forcusror" aria-hidden="true" onClick={(e) => { if (window.confirm('Are you sure you want to delete?')) this.deleteUser(item.ID) } } >Delete</a>
                                            </li>                                    
                                        </ul>   
                                    </div> 
                                )
                        })()}
                   
                </div>
    		});
        }
		return (
			 <div className="container-fluid ProAssetsList"  ref="selfdiv">
                {
	        		this.state.loading ?
			         	<div className="loaderspinner">
				         	<Loader
						         type="TailSpin"
						         color="#D10000"
						         height={100}                         
						         width={100}						         
						    />
					    </div>
				 	:null 
			 	} 
                <div className="WrapBarTop panel-heading">
                    <div className="row">
                        <div className="col-6 col-md-4 col-lg-4 ProSubHeadLeft">
                            
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 mobileDisable">
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 ProSubHeadRight">
                            <a href="#"  onClick={this.changeView.bind(this,'addteam')} className="add_uset_btn_green btn btn-info">+ Add New User</a>
                        </div>
                    </div>
                </div>
                <div className="panel panel-primary">                 
                   <div className="team-list">               
                        <div className="row AssetsListGrid">
                            {listItems}
                        </div>
                        <div>
                            { (this.state.teamList) &&
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={10}
                                    totalItemsCount={this.state.teamList.length}
                                    pageRangeDisplayed={5}
                                    onChange={this.handlePageChange.bind(this)}
                                />
                            }
                        </div>  
                    </div>                       
                </div>
            </div>
		);
    }
}
export default ListTeam;
