import React, {Component} from 'react';
import { Link , Redirect } from 'react-router-dom';
export default class Footer extends Component {
    render(){
        let checkuser = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
        if(checkuser.user.ID==15888 || checkuser.user.ID==12612 || checkuser.user.ID==28 || checkuser.user.ID==202371015253)
        {
            localStorage.setItem('userData','');
            return (<Redirect to={'/'}/>)
        }
        return (
            <footer className="footer">
		      
		    </footer>
        )
    }
}