import React, { Component } from 'react';  
import { Route } from 'react-router-dom';  
import IHeader from '../components/IHeader'
import IFooter from '../components/IFooter'
import { Link, Redirect } from 'react-router-dom';
const LoginLayout = ({ children }) => (    
    <div>
        <IHeader />
        {children}
        <IFooter />
    </div>                     
  );  
  
  const InviteeLoginLayoutRoute = ({component: Component, ...rest}) => {  
     let checkuser1 = localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).data;
      if(checkuser1.user.ID==15888 || checkuser1.user.ID==12612 || checkuser1.user.ID==28 || checkuser1.user.ID==202371015253)
    {
      localStorage.setItem('userData','');
      return (<Redirect to={'/'}/>)
    }
    return (  
      <Route {...rest} render={matchProps => (  
        <LoginLayout>  
            <Component {...matchProps} />  
        </LoginLayout>  
      )} />  
    )  
  };  
  
export default InviteeLoginLayoutRoute;